<template>
    <div style="padding:20px">

            <h3>Select Croping Area</h3>
            <div class="img-container">
                <img ref="image" :src="Src" style="max-width:100%;"/>
            </div>
            <br>

            <br>
            <h3>Profile Picture Preview </h3>
            <div style="display: flex;;justify-content: center;">
                <img :src="destination" alt="img-preview" class="img-preview" style="margin:auto">

            </div>
        <br>
        <br>
        <a-button v-if="isLoadingUpdate" type="primary" size="large" block style="width:100%;height:50px" :loading="isLoadingUpdate"></a-button>
        <a-button v-else type="primary" size="large" block style="text-align:start;height:50px" @click="upload()">
              <a-row>
                 <a-col :sm="3" :xs="3" :md="3" :lg="3" style="text-align:start">
                    <!-- <ion-icon name="arrow-redo-outline"></ion-icon> -->
                  <ion-icon name="arrow-redo-outline" style="font-size: 26px"></ion-icon>
                </a-col>
                 <a-col :sm="20" :xs="20" :md="20" :lg="20">
                  <span style="font-size: 16px;"><b style="font-size: larger;">Update</b> </span>

                </a-col>
              </a-row>
            </a-button>

    </div>
  </template>

<script>
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'

import axios from 'axios'
import { notification } from 'ant-design-vue'

export default {
  name: 'updateProfilePicture',
  data () {
    return {
      cropper: null,
      destination: {},
      isLoadingUpdate: false
    }
  },
  props: {
    Src: Object,
    ratio: Object
  },
  mounted () {
    this.createCropper()
  },
  watch: {
    Src (newValue) {
      this.destroyCropper()
      this.createCropper(newValue)
    }
  },
  methods: {
    createCropper (src) {
      this.$nextTick(() => {
        const imageElement = this.$refs.image
        if (imageElement) {
          this.cropper = new Cropper(imageElement, {
            aspectRatio: this.ratio,
            zoomable: false,
            scalable: false,
            crop: () => {
              const canvas = this.cropper.getCroppedCanvas()
              this.destination = canvas.toDataURL('image/png')
            }
          })
        }
      })
    },
    destroyCropper () {
      if (this.cropper) {
        this.cropper.destroy()
        this.cropper = null
      }
    },
    handleCancel () {
      this.destroyCropper()
      // Your other cancel logic here
    },
    handleOk () {
      // Your ok logic here
    },

    // upload
    async upload () {
    // console.log(this.destination);
      const data = {
        image: this.destination
      }

      // this.$store.commit('setIsLoading', true)
      this.isLoadingUpdate = true

      try {
        await axios.post('update-user-profile-picture/', data)
        // console.log(response.data)
        notification.success({
          message: 'Profile picture updated successfully, please refresh !',
          placement: 'bottomRight'
        })
        this.$emit('profileUpdated', true)
      } catch (error) {
        console.error(JSON.stringify(error))
        notification.error({
          message: 'Unable to upload profile picture!',
          placement: 'bottomRight'
        })
      }

      // this.$store.commit('setIsLoading', false)
      this.isLoadingUpdate = false
    }

  }
}
</script>

  <style scoped>
  .img-container{
    /* max-width:640px;
    max-height:400px; */
    /* float:left; */

  }
  .img-preview{
    width:200px;
    border-radius:100%;
    height:200px;
    margin:auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    /* margin-bottom:20px */
    /* float:left; */
    /* margin-left: 10px; */
  }
</style>
