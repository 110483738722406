<template>
    <main>
  <section style="text-align: start;font-size: 22px;"><br><br>
    <div class="lottie-head-expanded flx-brakable">
      <div class="card-lottie">

        <lottie-player src="https://crekaa-2024.s3.amazonaws.com/static/lottiefiles/our%20Experties.json" background="transparent" speed="1" loop
          autoplay></lottie-player>
      </div>
      <div class="content-right-main">
        <ul class="content-non-card">
          <li>We are a team of skilled professionals with expertise in Python full-stack solutions. Our knowledge of Python and its libraries enables us to create robust and scalable applications tailored to your business needs. From web apps to analytical dashboards, we deliver cutting-edge solutions for your success.</li>
          <li>Our core strength lies in crafting AWS server architecture solutions. With a deep understanding of cloud computing and AWS services, we design fault-tolerant infrastructures that ensure optimal performance and scalability. Our cost-effective solutions adapt to your business growth and dynamic demands.</li>
<li>Android app development is our specialty. Our dedicated team of developers excels in native languages for Android. We create intuitive and user-friendly apps that seamlessly integrate with various devices, providing exceptional user experiences and boosting your brand presence in the competitive mobile market.</li>
<li>In addition to Android, we specialize in cross-platform apps using Flutter. This allows us to save time and costs while delivering high-quality performance and native-like experiences on both Android and iOS devices. Our cross-platform solutions ensure wider reach and faster time-to-market for your app idea.</li>
        </ul>
      </div>

    </div>

    <div class="right-pane-side">
      <div class="container-card-secondary flx-brakable justify-space-between">
        <div class="content-card">
          <h1 class="flx" style="color:var(--text-color)"><ion-icon name="earth-outline"></ion-icon>&nbsp;Full Stack Web</h1>
          <p>We Grant The Full Stack Platform Development For Varius Buisness Solutions</p>
        </div>
        <ul class="content-card">
          <li>SEO</li>
          <li>Ecommerce</li>
          <li>Stores</li>
          <li>NGO</li>
          <li>Hotel</li>
          <li>Data keeping Softwared</li>
          <li>Custom buisness websites</li>

        </ul>
      </div>
      <br>
      <div class="container-card-primary  flx-brakable justify-space-between">
        <div class="content-card ">
          <h1 class="flx" style="color:var(--text-color)"> <ion-icon name="logo-android"></ion-icon>&nbsp;Android Applications</h1>
          <p>We Build The Android Applications As Per Need of Customer Buisness.</p>
        </div>
        <ul class="content-card">
          <li>Ecommerce</li>
          <li>Stores</li>
          <li>NGO</li>
          <li>Hotel</li>
          <li>Hostel</li>
          <li>Custom buisness applications</li>
        </ul>
      </div>
      <br>
      <div class="container-card-third flx-brakable justify-space-between">
        <div class="content-card ">
          <h1 class="flx" style="color:var(--text-color)"><ion-icon name="logo-amazon"></ion-icon>&nbsp;Servers</h1>
          <p>We Develope The Server Architecture For Application Hosting  </p>
        </div>
        <ul class="content-card">
          <li>Architecture designing</li>
          <li>Maintainance</li>
          <li>Migrations</li>
          <li>Backups</li>
        </ul>
      </div>
      <br>
      <div class="container-card-fourth flx-brakable justify-space-between">
        <div class="content-card ">
          <h1 class="flx" style="color:var(--text-color)"><ion-icon name="bulb-sharp"></ion-icon>&nbsp;Startup Idea</h1>
          <p>We Help The Startups To Build And Acuire The Market.</p>
        </div>
        <ul class="content-card">
          <li>Discuss Idea With Us.</li>
          <li>We Take Iniciative For Your Planed Project.</li>
          <li>We Grant You More Services That Are Required.</li>
          <li>We Build Your Dream To Real And Become THe Part Of Your Dream Success.</li>
          <li>You Will Recive A Brand As Your Requirement.</li>
        </ul>
      </div>
    </div>

  </section>
</main>

</template>
<script>
export default{
    name:"our_experties",
    
created(){
    document.title = "Our Experties - Crekaa";
  },
  head() {
    return {
      title: "Our Experties - Crekaa",
      meta: [
        { hid: 'description', name: 'description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'author', name: 'author', content: "Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        
        // <!-- Facebook Open Graph tags -->
        { hid: 'og:url', property: 'og:url', content: "https://crekaa.com/" },
        { hid: 'og:type', property: 'og:type', content: "website" },
        { hid: 'og:title', property: 'og:title', content: "Crekaa, Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'og:description', property: 'og:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'og:image', property: 'og:image', content: "../../assets/logo.png" },
        // Add more meta tags as needed

        // <!-- Twitter Card tags -->
        { hid: 'twitter:card', name: 'twitter:card', content: "summary_large_image" },
        { hid: 'twitter:title', name: 'twitter:title', content: "Crekaa , Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'twitter:description', name: 'twitter:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'twitter:image', name: 'twitter:image', content: "../../assets/logo.png" },

      ]
    };
  },
}
</script>
<style scoped>


.flx{
display: flex;
}


.justify-space-between {
    justify-content: space-between;

  }

  /* card primary  */
  .container-card-primary {
    background: var(--card-color);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    cursor: pointer;
    border-radius: 15px;
    position: relative;
    padding: 25px 30px;
    color: var(--primary-color-card);
  }

  .container-card-primary::after {
    content: '';
    background: var(--primary-color-card);

    border-radius: 15px;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    clip-path: circle(10% at 0% 0%);
    transition: all .3s ease-in;
  }

  .container-card-primary:hover::after {
    clip-path: circle(100%);
  }

  .container-card-primary:hover .content-card {
    color: var(--text-color-white-card);
  }

  /* Card Secondary  */

  .container-card-secondary {
    background: var(--card-color);
    /* background: white; */
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    cursor: pointer;
    border-radius: 15px;
    position: relative;
    padding: 25px 30px;
    color: var(--secondary-color-card);
  }

  .container-card-secondary::after {
    content: '';
    background: var(--secondary-color-card);

    border-radius: 15px;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    clip-path: circle(10% at 0% 0%);
    transition: all .3s ease-in;
  }

  .container-card-secondary:hover::after {
    clip-path: circle(100%);
  }

  .container-card-secondary:hover .content-card {
    color: var(--text-color-white-card);
  }



  /* Card fourth  */

  .container-card-fourth {
    /* background: white; */
    background: var(--card-color);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    cursor: pointer;
    border-radius: 15px;
    position: relative;
    padding: 25px 30px;
    color: var(--fourth-color-card);
  }

  .container-card-fourth::after {
    content: '';
    background: var(--fourth-color-card);

    border-radius: 15px;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    clip-path: circle(10% at 0% 0%);
    transition: all .3s ease-in;
  }

  .container-card-fourth:hover::after {
    clip-path: circle(100%);
  }

  .container-card-fourth:hover .content-card {
    color: var(--text-color-white-card);
  }

  /* Card Third */

  .container-card-third {
    /* background: white; */
    background: var(--card-color);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    cursor: pointer;
    border-radius: 15px;
    position: relative;
    padding: 25px 30px;
    color: var(--third-color-card);
  }

  .container-card-third::after {
    content: '';
    background: var(--third-color-card);

    border-radius: 15px;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    clip-path: circle(10% at 0% 0%);
    transition: all .3s ease-in;
  }

  .container-card-third:hover::after {
    clip-path: circle(100%);
  }

  .container-card-third:hover .content-card {
    color: var(--text-color-white-card);
  }


  .content-card {
    position: relative;
    z-index: 1;
    transition: all .3s ease-in;
  }

  .right-pane-side {
    padding: 20px
  }

  .lottie-head-expanded {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin: 20px;
  }

  .card-lottie {
    max-width: 800px;
    width: 100%;
    border-radius: 40px;
    background-color: rgb(236, 197, 197, 0.5);
    box-shadow: 0px 0px 20px 0px rgba(221, 0, 0, 0.478);

  }

  .flx-brakable {
    display: flex;
  }

  @media (max-width: 778px) {
    .flx-brakable {
      flex-direction: column;
    }
    .content-non-card li{
    font-size:12px
  }
  }
  @media (max-width: 1100px) {
    .flx-brakable {
      flex-direction: column;
      justify-content: center;
    }
    .content-non-card li{
    font-size:14px
  }
  }

  .content-card li {
    font-size: 14px
  }

  .content-card h1 {
    font-size: 40px;
    font-weight: 700;
  }
  

</style>