<template>
    <div  v-if="user_details.length ? user_details[0].user.can_careers_hire : false ">

    <a-float-button-group trigger="hover" type="primary" :style="{ left: '94px' , }">
    <template #icon>
        <ion-icon style="font-size: 14px" name="ellipsis-horizontal-circle-outline"></ion-icon>
    </template>
    <a-float-button >
        <template #icon>
            <ion-icon style="font-size: 14px" name="pin-outline"></ion-icon>
        </template>
    </a-float-button>
    <a-float-button>
        <template #icon>
        <ion-icon style="font-size: 14px" name="mail-open-outline" @click="showMailModal_open()"></ion-icon>
      </template>
    </a-float-button>
  </a-float-button-group>

  <a-modal  centered v-model:open="showMailModal"  >
  <template #title>
    <h1>Mail Applicant</h1>
  </template>
  <template #footer>
        <a-button key="back" @click="showMailModalhandleCancel">Return</a-button>
        <a-button
      key="submit"
      type="primary"
      :loading="loading"
      @click="handleOk"
      :disabled="admin_signature !== 'ADMIN'"
    >
      Confirm
    </a-button>
      </template>
      <a-radio-group v-model:value="take_actionADMIN_HIRING"><br>
        <a-radio value="schedule" size="large" style="font-size: 16px;"> Schedule Interview</a-radio><br>
        <a-radio value="revoke" size="large" style="font-size: 16px;"> Revoke Candidate</a-radio><br>
        <a-radio value="hire" size="large" style="font-size: 16px;"> Hire Candidate </a-radio><br>
        <a-radio value="next_round" size="large" style="font-size: 16px;"> Hiring Next Round Candidate </a-radio><br>
      </a-radio-group>

      <!-- <a-checkbox size="large" style="font-size: 16px;"> Schedule Interview </a-checkbox>
      <br>
      <a-checkbox size="large" style="font-size: 16px;"> Revoke Candidate </a-checkbox>
      <br>
      <a-checkbox size="large" style="font-size: 16px;"> Hire Candidate  </a-checkbox>
      <br>
      <a-checkbox size="large" style="font-size: 16px;"> Hiring Next Round Candidate  </a-checkbox> -->
      <br>
      <br>
      <a-label style="font-size: 16px;"> Please Confirm Your Digital signiture Type <b>ADMIN</b> Here.</a-label>
      <br><a-input type="warning" style="font-size: 16px;"   v-model:value="admin_signature"></a-input>
    </a-modal>

    <div style="background-color: var(--notification-color-bg);padding:10px"  v-if="this.$store.state.userDetils.length">
        <br><br>
<div >
    
    <a-row>
        <a-col :sm="24" :xs="24" :md="12" :lg="12">
        <h1>Application Details</h1>
        <div v-for="ajco in application_job_career_offer" style="padding:20px"> 
          
    <a-card style="text-align:start">
    
        <h5> <b> Application Number : </b> {{ajco.fields.application_number}}</h5>
        <!-- <h5> <b> application_job : </b> {{ajco.fields.application_job}}</h5> -->
    <h5> <b> Resume : </b> &nbsp;&nbsp;&nbsp;<a :href="'https://crekaa-2024.s3.amazonaws.com/media/'+ajco.fields.applicant_resume"> <a-button type="dashed">
        Download Resume</a-button></a></h5>
      
        <h5> <b> Applicant Atatus : </b> <a-tag color="blue"> {{ajco.fields.applicant_status}} </a-tag> </h5>
        <h5> <b> First Name : </b> {{ajco.fields.applicant_first_name}}</h5>
        <h5> <b> Last Name : </b> {{ajco.fields.applicant_last_name}}</h5>
        <h5> <b> Country : </b> {{ajco.fields.applicant_country}}</h5>
    
        <a-row>
        <a-col :sm="24" :xs="24" :md="12" :lg="12">
        
            <h5> <b> Country_code : </b> {{ajco.fields.applicant_country_code}}</h5>
        <h5> <b> Phone Number : </b> {{ajco.fields.applicant_phone_number}}</h5>
        <h5> <b> Social Profile 1 : </b> {{ajco.fields.social_profile_1_linkedin}}</h5>
        <h5> <b> Social Profile 2 : </b> {{ajco.fields.social_profile_2}}</h5>
        <h5> <b> Social Profile 3 : </b> {{ajco.fields.social_profile_3}}</h5>
        </a-col>
        <a-col :sm="24" :xs="24" :md="12" :lg="12">
        
            <h5> <b> Checked Terms : </b> {{ajco.fields.checked_terms}}</h5>
            <h5> <b> Checked Privacy : </b> {{ajco.fields.checked_privacy}}</h5>
            <h5> <b> Checked Age : </b> {{ajco.fields.checked_age}}</h5>
            <h5> <b> Checked Info : </b> {{ajco.fields.checked_info}}</h5>
            <h5> <b> Digital Signiture : </b> {{ajco.fields.digital_signiture}}</h5>
        </a-col>
    </a-row>
    </a-card>
    </div>
    
    <div v-for="j in job" style="padding:20px"> 
    <a-card style="text-align:start">
        <h4> <b> Job Details : </b> {{j.fields.job_number }}</h4>
        <h4> <b> Job Title : </b> {{j.fields.job_title }}</h4>
        <h4> <b> Employement Type : </b> {{j.fields.employement_type }}</h4>
        <h4> <b> Experience Required : </b> {{j.fields.experience_required }}</h4>
        <h4> <b> Salary : </b> {{j.fields.job_salary_currency }} {{j.fields.salary_expected }}</h4>
        <h4> <b> Positions Opening : </b> {{j.fields.position_openings }}</h4>
        <h4> <b> Applicant Counts : </b> {{j.fields.applicant_counts }}</h4>
        <h4> <b> Active : </b> <span v-if="j.fields.is_active"> ✅</span> <span v-else>❌ </span> </h4>
        <span style="font-size: 12px;">( Active will show this posted job are currently accepting responces or not.{✅ means yes it is accepting}, {❌ means no it is not accepting}   )</span> <br>
    
        <h4> <b> Skills : </b> <a-tag v-for="skill in j.fields.skills.split(',')" :key="skill" :bordered="false" :color="generateRandomColor()">
                                                {{ skill.trim() }}
                                            </a-tag></h4>
    
        <h4> <b>Job Description : </b> </h4>
        <div v-html="j.fields.description_job"></div>
    
    </a-card>
    </div>
    
    </a-col>
        <a-col :sm="24" :xs="24" :md="12" :lg="12">
        <h1>Applicant Qualifications</h1>
        <div v-for="aq in applicant_qualification" style="text-align:start">
            <!-- {{aq}}  -->
                    <a-card style="margin-bottom:10px">
           <h4> <b>Qualification Title :</b> {{ aq.qualification_title }}<br></h4> 
            <h4> <b>Qualification Description :</b> {{ aq.qualification_description }}<br></h4>
            <h4> <b>From Year :</b> {{ aq.from_year }}<br></h4>
            <h4> <b>To Year :</b> {{ aq.to_year }}<br></h4>
            <h4> <b>Datetime :</b> {{ aq.datetime }}<br></h4>
                    </a-card>
        </div>
        <h1>Applicant Experiences</h1>
        <div v-for="exp in experiences"  style="text-align:start"> 
            <a-card style="margin-bottom:10px">
                <h4><b>Experience ID : </b>{{exp.experience_id}}</h4>
                <h4><b>Experience Title : </b>{{exp.experience_title}}</h4>
                <h4><b>Experience Description : </b>{{exp.experience_description}}</h4>
                <a-row>
                    <a-col :sm="24" :xs="24" :md="12" :lg="12">
                        <h4><b>From Year : </b>{{exp.from_year}}</h4>
                        <h4><b>To Year : </b>{{exp.to_year}}</h4>
                    </a-col>
                    <a-col :sm="24" :xs="24" :md="12" :lg="12">
                        <h4><b>Currently Working Here : </b>{{exp.im_currently_workingHere}}</h4>
                        <h4><b>Datetime : </b>{{exp.datetime}}</h4>
                    </a-col>
                </a-row>
    
            </a-card>
            
        </div>
        <h1>Answered Questions</h1>
        <div v-for="aaq in applicant_asked_queations" style="margin:5px"> 
            <!-- {{ aaq }} -->
            <a-card style="text-align: start;">
                <h4><b>Question Answer:  </b> {{aaq.question_answer}}</h4>
                <h4><b>Question Answered:  </b> <span v-if="aaq.question_answer"> ✅</span> <span v-else>❌ </span></h4>
                <h4><b>datetime:  </b> {{aaq.datetime}}</h4>
            </a-card>
            </div>
            <br>
            <div v-if="application_job_career_offer.length">
<a-card>
    <template #title>
        <h1> Resume</h1>
    </template>
    <iframe :src="'https://crekaa-2024.s3.amazonaws.com/media/'+application_job_career_offer[0].fields.applicant_resume" width="100%" height="900px" frameborder="0"></iframe> 

</a-card>
            </div>

            <!-- <h1> Take Action</h1>
      <a-card style="text-align: start;margin:10px">
        <p>Change Status of this Application</p>
        <a-select style="display: flex;justify-content: space-between;flex-wrap: wrap;">
            <a-option type="primary" value=" Schedule Interview " > Schedule Interview </a-option>
            <a-option type="primary" value=" Interviewed" > Interviewed</a-option>
            <a-option type="primary" value=" Assessment/Testing" > Assessment/Testing</a-option>
            <a-option type="primary" value=" Reference Check" > Reference Check</a-option>
            <a-option type="primary" value=" Offer Extended" > Offer Extended</a-option>
            <a-option type="primary" value=" Offer Accepted" > Offer Accepted</a-option>
            <a-option type="primary" value=" Onboarding" > Onboarding</a-option>
            <a-option type="primary" value=" Hired" > Hired</a-option>
            <a-option type="primary" value=" Rejected" > Rejected</a-option>
        </a-select>
        <br>
        <a-button block type="primary">Change status</a-button>
            </a-card>  -->
        </a-col>
    </a-row>
</div>


        <!-- <hr> -->
        <br>
        <!-- <a-button block type="primary"> Send Mail</a-button> -->
    </div>
</div>
</template>
<script>
import axios from 'axios'
import { notification } from 'ant-design-vue';

export default{
    name:"application_details_crekaa_careers",
    data(){
        return {
            application_number:'',
            application_job_career_offer:[],
            applicant_asked_queations:[],
            experiences:[],
            applicant_qualification:[],
            job:[],
            user_details:[],
            admin_signature:'',
            showMailModal:false,

            take_actionADMIN_HIRING:"schedule",
        }
    },
    mounted(){
        const application_number = this.$route.params.crekaa_application_id
        this.application_number = application_number
        this.fetchApplicationData()
        this.fetchUserProfileDetails()
    },
    methods:{
         async fetchUserProfileDetails() {
            this.$store.commit('setIsLoading', true)

            await axios
                .post("user-details/")
                .then((response) => {
                    this.user_details = response.data
                    this.$store.commit('addUserDetails', response.data);

                    // console.log("=============-------------============-====-=-===-=-=-=-=-")
                    // console.log(this.user_details)

                }).catch((error) => {
                    console.log("error Occured while fetching User Data ")
                })

            this.$store.commit('setIsLoading', false)
},
        showMailModalhandleCancel(){
            this.admin_signature=''
            this.showMailModal=false
        },
        showMailModal_open(){
            this.showMailModal=true
        },
        generateRandomColor() {
      const colors = ['magenta', 'warning', 'blue', 'processing', 'orange']; // Add more colors if needed
      const randomColor = colors[Math.floor(Math.random() * colors.length)];
      return randomColor;
    },
        async fetchApplicationData(){
        this.$store.commit('setIsLoading',true)

            await axios 
            .post("get-crekaa-career-application-details/"+this.application_number)
            .then(response => {
            // console.log("------------------------------------Got Response------------------------------------");
            // console.log(response.data);
            // this.skills_available=response.data['skill']
            if (response.data['error'] == false){
                // console.log(response.data)
                this.application_job_career_offer=response.data["ajc"]
                this.applicant_asked_queations=response.data["aaq"]
                this.experiences=response.data["exp"]
                this.applicant_qualification=response.data["aq"]
                this.job=response.data["job"]

                notification['success']({
                    message: "Application Number: " + this.application_number+" Has been All Details Fetched Successfully ",
                    // description:'Unable To Login With Provided Credentials...',
                    placement: 'bottomRight',
                });

            }else {

                notification['error']({
                        message: 'Unable to fetch the Applications Details',
                        // description:'Unable To Login With Provided Credentials...',
                        placement: 'bottomRight',
                    });
            }
            // console.log("------------------------------------Skills available------------------------------------");
            // console.log(this.skills_available)            
        })
        .catch(error => {
            console.log(error),
                notification['error']({
                    message: 'Unable to fetch Applications Details',
                    // description:'Unable To Login With Provided Credentials...',
                    placement: 'bottomRight',
                });
        })
        this.$store.commit('setIsLoading',false)

    }
    }
}
</script>
<style scoped>
h5 {
    font-size: 14px;
}
h4 {
    font-size: 14px;

}
</style>