
<template>
    <a-button type="primary" @click="showModal" style="border-radius:100%"><template #icon>
        <ion-icon name="add-circle-outline" style="font-size: 22px;"></ion-icon>
    </template></a-button>
        <a-modal v-model:open="open" title="Create Job Question" :confirm-loading="confirmLoading" @ok="handleOk">
            
            <label>Question English</label>
   <a-input v-model:value="question" placeholder="write something that you need to ask to jobseeker" /><br>
   <br>
   <br>
   <a-checkbox v-model:checked="question_required"><a-label>Question Required:</a-label></a-checkbox><br>
                   
                
        </a-modal>
    </template>
    <script>
    
    
import { notification } from 'ant-design-vue'
import axios from 'axios'
export default{
        name:"createQueationJobCareers",
        data(){
            return {
                open: false,
                confirmLoading: false,

                question:'',
                question_required:false,
                job_num:'',
            }
        }, 
          mounted(){
            this.job_num=this.job_number
        }, 
         props:{
            job_number:Object,
        },
        methods:{
                showModal() {
                this.open = true;
                },
                
        async handleOk () {
                this.confirmLoading = true;
                const formData={
                    question_required:this.question_required,
                    question:this.question,    
                    
                }
                await axios
        .post("update-job-details-career-crekaa-admin/"+"create_question/"+this.job_number,formData)
        .then(response => {
            notification['success']({
                    message: 'Careers Job Question has been successfully created ',
                    description:'Careers job employement type has been updated successfully, You just need to refresh update page.',
                    placement: 'bottomRight',
                });
        })
        .catch(error => {
            console.log(error)
                notification['error']({
                    message: 'Unable to update Job Question ',
                    // description:'Unable To Fetch User Details...',
                    placement: 'bottomRight',
                });

                console.log("error Found unable to update")                        
                console.log(JSON.stringify(error.response.data))
                    console.log(JSON.stringify(error))
        })

        this.confirmLoading = false;
            this.open = false;
            window.location.reload()
            // this.confirmLoading = true;
            // setTimeout(() => {
            //     this.open = false;
            //     this.confirmLoading = false;
            // }, 2000);
            },

    
        }
    }
    </script>
    <style scoped>
    
    </style>
    
    
    