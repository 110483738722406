<template>
  <!-- this is chat page -->
  <main style="height: 100vh;margin-top: 25px;">

    <a-row style="height: 100vh;">
      <a-col :sm="0" :xs="0" :md="6" :lg="6" style="; height:100%">

        <a-card v-for="u_card in user_followings" @click="showCorrentChat(u_card.follow_s.id, u_card.follow_s.email)"
          style="margin-left:5px;margin-right: 5px;margin-top: 3px;;border-radius:5px;text-align:start">
          <div style="display: flex;">
            <img :src="u_card.follow_s_user_profile.profile_picture" style="
                                                    width:50px;
                                                    margin-top:5px;
                                                    padding:0;
                                                    height:50px;
                                                    border:2px solid grey ;
                                                    border-radius:50px" alt="Avatar" />
            <div class="card-content">
              <h2> {{ u_card.follow_s.first_name + " " + u_card.follow_s.last_name }}</h2>
              <span style="display: flex;">

                <ion-icon
                  style=" height:20px;width:20px;font-size: 15px;vertical-align: middle; color: rgb(79, 195, 247);"
                  name="checkmark-done-outline"></ion-icon>
                &nbsp;
                <p
                  style="font-size: 12px; overflow: hidden; white-space: nowrap; width:100%;max-width: 200px; text-overflow: ellipsis;">
                  Card content content content content content content content </p>

              </span>

            </div>
          </div>
        </a-card>
        <!-- <card_connections v-for="u_card in user_followings" v-bind="u_card" /> -->
      </a-col>
      <a-col v-if="current_chat_showing_sender_mail" :sm="0" :xs="0" :md="18" :lg="18" 
        style=" background-repeat: no-repeat;width:100%; 
       background-image: url(https://img.freepik.com/free-vector/social-media-icons_53876-89125.jpg?w=1060&t=st=1701642101~exp=1701642701~hmac=7133bb051b97cbdceb6b104dabfd406245b7cb0b79b205e55d301338e03edfc0); 
       height:100%;overflow-y: auto;">
        <div style="display: flex;flex-direction: column;justify-content: end;">

          <div v-for="soc in socket_messages">

            <card_chat_sender v-if="soc.sender.id == self_ID" v-bind:chat_sender="soc" />
            <card_chat_reciver v-else v-bind:chat_reciver="soc" /><br>
          </div>
          <div style="position: fixed;bottom: 10px;width:80%">
            <a-row>
              <a-col :sm="0" :xs="0" :md="20" :lg="21">

                <a-input block v-model:value="input_message" @keyup.enter="sendMessage()"
                  style="height:40px;margin-left: 5px;;font-size: 14px;"></a-input>
              </a-col>
              <a-col :sm="0" :xs="0" :md="3" :lg="2">
                <a-button @click="sendMessage()" type="primary"
                  style="border-radius: 100%; margin-right: 20px;height:40px;width:40px;font-size: 14px;"> &gt;
                </a-button>
              </a-col>
            </a-row>
            <!-- <input_and_send_message/> -->
          </div>
          <br><br>
          <br><br>
          <br><br>
          <br><br>
          <br><br>
          <br><br>
          <br><br>
          <br><br>


        </div>


      </a-col>
      <a-col v-else :sm="0" :xs="0" :md="18" :lg="18"
        style="background-color: var(--card-color);display: flex;flex-direction: column;justify-content: center;">


        <lottie-player src="https://lottie.host/a2db6d49-4ce1-4fa3-bec9-87a62486d109/8QIwEfuga7.json"
          background="transparent" speed="0.5" style="width:100%;height:200px" loop="2" autoplay=""></lottie-player>
        <h1>Use Crekaa Buisness Solutions</h1>
        <h3>Build Your Own Online Buisness With Crekaa Technologies </h3>
        <p>we are Crekaa</p>

      </a-col>

    </a-row>


    <a-row>
      <a-col :sm="24" :xs="24" :md="0" :lg="0"></a-col>
      <a-col :sm="24" :xs="24" :md="0" :lg="0"></a-col>
    </a-row>
  </main>
</template>
<script>
import card_chat_reciver from '@/components/chat/card_chat_reciver'
import card_chat_sender from '@/components/chat/card_chat_sender'
import card_connections from '@/components/chat/card_connections'
import input_and_send_message from '@/components/chat/input_and_send_message'

import ReconnectingWebSocket from 'reconnecting-websocket';
import { notification } from 'ant-design-vue';

import axios from 'axios'

export default {
  name: "chat_realtime",
  data() {
    return {
      self_uname: '',
      self_ID: '',
      socket_messages: [],
      socket: null,
      current_chat_showing_reciver_id: null,
      current_chat_showing_reciver_mail: null,
      current_chat_showing_sender_mail: null,


      //   input_message
      input_message: '',

      // follwoing list 
      user_followings: [],

      // all messages
      all_messages: [],

    };
  },
  mounted() {

    this.fetchData()
  },

  beforeDestroy() {
    // Close the WebSocket connection when the component is destroyed
    if (this.socket) {

      this.socket.close();
    }
  },

  components: {
    card_chat_reciver,
    card_chat_sender,
    card_connections,
    input_and_send_message
  },

  methods: {
   async showCorrentChat(chat_reciver_ID, mail) {
      this.current_chat_showing_reciver_id = chat_reciver_ID,
        this.current_chat_showing_reciver_mail = mail
      this.current_chat_showing_sender_mail = this.$store.state.userDetils.length ? this.$store.state.userDetils[0].user.email : null
      // const chatContainer = document.getElementById('chat-container');
      // if (chatContainer) {
      // chatContainer.scrollTop = chatContainer.scrollHeight;
      // }
      
      this.fetchMessages(chat_reciver_ID)
      this.establishSocketConnection()
    },
    // write an method to send message on web socket
    sendMessage() {
      this.self_uname = this.$store.state.userDetils.length ? this.$store.state.userDetils[0].user.username : ''
      this.self_ID = this.$store.state.userDetils.length ? this.$store.state.userDetils[0].user.id.toString() : ''

      // console.log("=============-----------------------------===============================")
      // console.log(this.$store.state.userDetils.length ? this.$store.state.userDetils[0].user : '')
      if (this.input_message != '') {
        const data_send_to =
        {
          text: this.input_message,
          msg_type: 3,
          "random_id": -10,
          user_pk: this.current_chat_showing_reciver_id.toString(),
          sender_username: this.self_uname
        }
        console.log(data_send_to)

        this.socket.send(JSON.stringify(data_send_to));
        // this.socket_messages.push({'message':this.input_message,'username':this.self_uname})
        // Assuming you have a reference to the chat container
        // const chatContainer = document.getElementById('chat-container');
        // // Scroll down to the bottom after sending a message
        // chatContainer.scrollTop = chatContainer.scrollHeight;

        // setting input= ''
        this.input_message = ''
      }

    },


    async fetchData() {
      this.$store.commit('setIsLoading', true)

      await axios
        .post("user-follow-list/")
        .then(response => {
          // console.log(response);
          // console.log(response.data);
          this.user_followings = response.data

        })
        .catch(error => {
          // console.log("===========error at user details")                        
          console.log(JSON.stringify(error.response.data))
          console.log(JSON.stringify(error))
        })
      this.$store.commit('setIsLoading', false)
    },
   
    async fetchMessages(chat_reciver_ID){
      await axios
        .get("messages/"+chat_reciver_ID)
        .then(response => {
          // console.log(response);
          console.log(response.data);
          // this.user_followings = response.data
          // this.all_messages = response.data
          this.socket_messages = response.data

        })
        .catch(error => {
          // console.log("===========error at user details")                        
          console.log(JSON.stringify(error.response.data))
          console.log(JSON.stringify(error))
        })
      this.$store.commit('setIsLoading', false)
      
      this.establishSocketConnection()

    },
    async fetchLastMessage(){
      await axios
        .get("last-message")
        .then(response => {
          // console.log(response);
          console.log("new essage fetched");
          console.log(response.data);
          // this.user_followings = response.data
          // this.all_messages = response.data
          // this.socket_messages.push(response.data)

        })
        .catch(error => {
          // console.log("===========error at user details")                        
          console.log(JSON.stringify(error.response.data))
          console.log(JSON.stringify(error))
        })
      this.$store.commit('setIsLoading', false)
      
      // this.establishSocketConnection()

    },

    async establishSocketConnection() {

      // Connect to the WebSocket server
      // this.socket = new ReconnectingWebSocket('wss://api.crekaa.com:5000');
      // console.log(this.current_chat_showing_sender_mail)
      this.socket = new ReconnectingWebSocket('ws://127.0.0.1:8000/chat_ws?user_email=' + this.current_chat_showing_sender_mail + "&to_user=" + this.current_chat_showing_reciver_mail, []);

      // Event listener for when the connection is opened
      this.socket.addEventListener('open', (event) => {
        this.self_uname = this.$store.state.userDetils.length ? this.$store.state.userDetils[0].user.username : ''
        this.self_ID = this.$store.state.userDetils.length ? this.$store.state.userDetils[0].user.id.toString() : ''

        console.log('WebSocket connection opened:', event);
        this.socket.send(JSON.stringify(
          { msg_type: 1, user_pk: this.self_ID.toString() }
        ))
        notification['success']({
          message: 'WebSocket Connected',
          description: 'WebSocket connection has been established.',
          placement: 'bottomRight',
        });
      });
      // Event listener for incoming messages
      this.socket.addEventListener('message', (event) => {
        console.log('WebSocket message received:', event.data);
        const parsedData = JSON.parse(event.data);
        console.log("========--------------------------parsedData-------------------------======================")
        console.log(parsedData)
        this.fetchLastMessage()
        // this.socket_messages.push(parsedData);
        // this.fetchMessages(this.current_chat_showing_reciver_id)


        //     if (parsedData.msg_type === 3) {
        //   // Append messages with msg_type === 3 directly to socket_messages array
        //   this.socket_messages.push(parsedData);
        // } 
        //     else{
        //         // Find the last received message
        //       const lastMessageIndex = this.socket_messages.length - 1;

        //       // Check if there are any messages
        //       if (lastMessageIndex >= 0) {
        //         // Append the message inside the last received message
        //         this.socket_messages[lastMessageIndex].nestedMessages = this.socket_messages[lastMessageIndex].nestedMessages || [];
        //         this.socket_messages[lastMessageIndex].nestedMessages.push(parsedData);
        //       } else {
        //         // If there are no messages yet, append it directly
        //         this.socket_messages.push(parsedData);
        //       }
        //     }
        // Handle the incoming message
      });

      // Event listener for when the connection is closed
      this.socket.addEventListener('close', (event) => {
        console.log('WebSocket connection closed:', event);
        notification['warning']({
          message: 'WebSocket Disconnected',
          description: 'WebSocket connection has been closed.',
          placement: 'bottomRight',
        });
      });

      // Event listener for errors
      this.socket.addEventListener('error', (event) => {
        this.socket.send(JSON.stringify(
          { msg_type: 2, user_pk: this.self_ID.toString() }
        ))
        console.error('WebSocket error:', event);
        notification['error']({
          message: 'WebSocket Error',
          description: 'An error occurred in the WebSocket connection.',
          placement: 'bottomRight',
        });
      });
      // Event listener for when the connection is retrying
      this.socket.addEventListener('reconnect', (event) => {
        console.log('WebSocket reconnecting:', event);
        notification['info']({
          message: 'WebSocket Retrying',
          description: 'WebSocket connection is retrying.',
          placement: 'bottomRight',
        });
      });
    }

  }
}
</script>
<style scoped></style>