<template>
    <section style="background-color:black">

        <br><br><br> <a-row>

            <a-col :sm="24" :xs="24" :md="24" :lg="24" style="
  background: linear-gradient(to bottom, rgba(255, 0, 0, 0.2), rgba(0, 0, 255, 0.2));
  justify-content: center;
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 10px;">
                <br>
                <br>
                <a-row type="flex" justify="center">
                    <div style="margin-bottom:30px;justify-content:center;display:flex;">
                        <lottie-player
                            style="background-color:  rgba(255, 255, 255, 0.90);max-width:100px;height:100px;border-radius:20px"
                            src="https://crekaa-2024.s3.us-west-2.amazonaws.com/static/lottiefiles/meater-crekaa.json"
                            background="transparent" speed="1" loop="" autoplay=""></lottie-player>

                    </div>

                </a-row>
                <a-row type="flex" justify="center">
                    <br>
                    <br>
                    <div class="">
                        <h2 class="" style="color:white;"><span class="introducing-text-">Introducing <br></span><br>
                            Crekaa.UI Boost <span class="tech-label-beta">&nbsp;
                                Beta &nbsp;</span> </h2><br>
                        <p>
                            <span style="color:white;font-size:var(--fa-18)"> Up to 100× faster servers</span><br><br><span
                                style="font-size:16px;color: #71ffff;">with our groundbreaking browser caching
                                technology</span>
                        </p>
                    </div>

                </a-row>

                <br><br><br>
                <br><br>
                <a-row type="flex" justify="center">
                    <a-col :sm="24" :xs="24" :md="0" :lg="0" >
                        <br><br>
                        <div style=" border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.10);
  margin: auto;
  padding-top:20px;
  max-width: 380px;
  max-height: 379px;
  transform: perspective(49em) rotatey(0deg)rotatex(20deg);
  -webkit-mask-image: linear-gradient(to bottom, #fff, transparent);
  mask-image: linear-gradient(to bottom, #fff, transparent);">
                        <img alt="" src="../../assets/the_assets/Images/tuning-rds.gif"
                        decoding="async" data-nimg="1" class="rounded-xs" loading="lazy"
                            style="border-radius:10px; max-width: 90%; height: auto;">

                    </div>
                        <br><br>
                        
                    </a-col>
                   
                </a-row>


                <a-row type="flex" justify="center">
                    <a-col :sm="0" :xs="0" :md="24" :lg="24" >

                    <div style=" border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.10);
  margin: auto;
  padding-top:20px;
  max-width: 880px;
  max-height: 479px;
  transform: perspective(49em) rotatey(0deg)rotatex(10deg);
  -webkit-mask-image: linear-gradient(to bottom, #fff, transparent);
  mask-image: linear-gradient(to bottom, #fff, transparent);">
                        <img alt="" src="../../assets/the_assets/Images/tuning-rds.gif"
                        decoding="async" data-nimg="1" class="rounded-xs" loading="lazy"
                            style="border-radius:10px; max-width: 96%; height: auto;">

                    </div>
                    </a-col>
                </a-row>
                <br>
                <br>
                <br>
                <br>
                <a-row type="flex" justify="center">
                    <div
                        style="background-color:rgba(128, 128, 128, 0.319);width:1000px;padding:15px;border-radius:10px;margin:10px">

                        <span class="" style="font-size:14px;color: rgb(180, 180, 180);"><span> In just a few
                                clicks, Crekaa.UI Boost makes the queries you choose faster.</span></span>

                        <a-divider style="border-color: #50505081;width:80px" />


                        <span class="" style="font-size:14px;color: rgb(180, 180, 180);"><span> Find a slow query pattern
                                using Insights and activate Crekaa.UI&nbsp;Boost.</span></span>
                        <a-divider style="border-color: #50505081;width:80px" />

                        <span class="" style="font-size:14px;color: rgb(180, 180, 180);"><span> Using TTLs, no invalidation
                                logic, and We provide the caching
                                infrastructure to&nbsp;maintain &amp; manage the services.</span></span>
                        <a-divider style="border-color: #50505081;width:80px" />
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                       
                         <router-link to="/crekaa.ui"> <a-button size="large" style="font-size:14px;width:70%">Join the waitlist
                            →</a-button>
                            </router-link>
                    </div>
                </a-row>
                <br>
                <br>
                <br>

            </a-col>
            <a-col :sm="24" :xs="24" :md="24" :lg="24">
                <a-row type="flex" justify="center" style="padding: 10px;">
                    <a-col :sm="24" :xs="24" :md="12" :lg="6" >
                        <br><br>
                        <div class="" style="max-width:550px;display:flex;justify-content:start;text-align: start;">
                            <div
                                style="border-radius:10px; background-color: rgba(167, 167, 167, 0.8);padding:10px;width: 120px;height:115px;">

                                <div
                                    style="margin-bottom:30px;border-radius:10px;justify-content:center;padding:8px;display:flex;background-color:  rgba(95, 95, 95, 0.9);max-width:100px;height:95px;">
                                    <lottie-player style="border-radius:20px;width:90px;height:80px"
                                        src="https://crekaa-2024.s3.amazonaws.com/static/lottiefiles/performance-cloud.json"
                                        background="transparent" speed="1" loop="" autoplay=""></lottie-player>
                                </div>

                            </div>
                            <div>&nbsp;&nbsp;&nbsp;</div>
                            <div><br>
                                <span
                                    style="border-left:3px solid red;padding-left:10px;font-size:20px ;font-family: Alkatra; color:white">
                                    Get Solutions In Realtime
                                    <br><br><span style="font-size:14px;font-weight: 700; ;font-family: Alkatra;">Choose
                                        your server architecture as per your service need.</span>
                                </span>

                            </div>
                        </div>


                    </a-col>
                    

                    <br><br>

                   
                </a-row>
                <br>
                        <br>
                        <br>
                        <br>
                        <br>
                <a-row>
                    <a-col :sm="24" :xs="24" :md="0" :lg="0" >
                        <br><br>
                        <div class=""
                            style="border-radius:10px; background-color: rgba(255, 255, 255, 0.10);
                            margin: auto;
                            width: 90% ;
                            max-width:400px;
                            padding-top: 10px;
                            /* max-height:300px; */
                            transform: perspective(49em) rotatey(-30deg)rotatex(10deg);
                            -webkit-mask-image: linear-gradient(to bottom, #fff, transparent);
                            mask-image: linear-gradient(to bottom, #fff, transparent);">
                            <img alt="web hosting architecture"
                                src="../../assets/the_assets/Images/host-arch.png" 
                                 decoding="async" data-nimg="1" class="rounded-xs" loading="lazy"
                                style="border-radius:10px; max-width: 95%; height: auto;">
                        </div>
                        <br><br>
                        <br><br>
                        <br><br>
                        
                    </a-col>
                    
                    
                  
                    <a-col :sm="24" :xs="24" :md="12" :lg="12" style="padding:10px" >

<div class="text-bottom-intro-servers" style="padding:20px;border-radius:10px;background-color: rgba(255, 255, 255, 0.10);">
    <div class="">
        <div class="col-0-6">
            <p style="color:white;font-size:15px">Fully integrated and real-time insights into your
                database
                performance. No third-party application monitoring platform required.</p>
            <br><br><br>
            <router-link to="/crekaa.ui">
            <a-button size="large" style="font-size:14px;width:70%">Join the waitlist
    →</a-button> </router-link>
    <br>
    <br>
    <br>
    <br>
        </div>
        <div class="col-0-6">
            <span class="" style="font-size:14px;color: rgb(180, 180, 180);"><span> Deliver
                    consistent
                    performance for your users by finding poor performing modeules in
                    seconds.</span>
                <div style="width:100%;border-bottom:1px solid rgba(124, 122, 122,0.4);">&nbsp;</div>
                <br><br>

                <span>Better understand your billing usage through visibility into how many users
                    your platform
                    is serving.</span>
                <div style="width:100%;border-bottom:1px solid rgba(124, 122, 122,0.4)">&nbsp;</div>
                <br><br>

                <span>
                    Tag your problems inside your application and drill down to find solutions.
                </span>
                <div style="width:100%;border-bottom:1px solid rgba(124, 122, 122,0.4)">&nbsp;</div>

            </span>
        </div>
    </div>
</div>


</a-col>
<a-col :sm="0" :xs="0" :md="12" :lg="12" >
                        <br><br>
                        <div class=""
                            style="
                            border-radius:10px;
                             background-color: rgba(255, 255, 255, 0.10);
                            margin: auto;

                            width: 80%;
                            max-width: 550px;
                            padding-top:10px;
                            height:80%;
                            max-height:799px;
                            transform: perspective(49em) rotatey(-30deg)rotatex(10deg);
                            -webkit-mask-image: linear-gradient(to bottom, #fff, transparent);
                            mask-image: linear-gradient(to bottom, #fff, transparent);
                            ">
                            <img alt="web hosting architecture"
                                src="../../assets/the_assets/Images/host-arch.png" 
                                 decoding="async" data-nimg="1" class="rounded-xs" loading="lazy"
                                style="border-radius:10px; max-width: 98%; ">
                        </div>
                        
                    </a-col>
                </a-row>
                
            </a-col>
        </a-row>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
    </section>
    

</template>
<script>
export default {
    name: "tech_desc"
}
</script>
<style scoped>
.tech-label-beta {
    background: rgb(0, 75, 69);
    border: 1px solid #00e0e0;
    color: #00e0e0;
    border-radius: 10px;
    font-size: 15px
}

</style>