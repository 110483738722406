<template>
    <nav style=" z-index:10;">

        <a-row>
            <a-col :sm="0" :xs="0" :md="3" :lg="3" class="logo-menu">

                <router-link to="/">
                    <a-row>
                        <a-col :sm="6" :xs="6" :md="4" :lg="5">
                            <img style="width:100%;max-width: 25px;margin-top:-3px;margin-left:15px" src="../../assets/logo.png" alt="">
                        </a-col>
                        <a-col :sm="18" :xs="18" :md="20" :lg="12">
                            <span style="text-align:start;color:var(--text-color); font-size:var(--fa-18);font-weight:bold" class="main-logo-text">&nbsp;
                        &nbsp;CREKAA</span>
                    </a-col>

                    </a-row>
                    
                </router-link>



            </a-col>

            <a-col :sm="0" :xs="0" :md="15" :lg="16" class="logo-menu">
                <a-row>
                    <a-col :sm="0" :xs="0" :md="5" :lg="3">


                        <a-popover placement="bottomLeft">
                            <template #content :theme="dark">
                                <div class="content-popover">

                                    <a-row>
                                        <a-col :sm="0" :xs="0" :md="12" :lg="12">
                                            <div class="right-crekaa">
                                                <div style="padding-top:30px;justify-content:center;display:flex;">
                                                    <lottie-player
                                                        style="background-color:  rgba(255, 255, 255, 0.90);max-width:100px;height:100px;border-radius:20px"
                                                        src="https://crekaa-2024.s3.us-west-2.amazonaws.com/static/lottiefiles/meater-crekaa.json"
                                                        background="transparent" speed="1" loop=""
                                                        autoplay=""></lottie-player>

                                                </div>
                                                <h1 class="right-crekaa-title">Crekaa.UI Boost </h1>
                                                <h4 class="right-crekaa-desc">Upto 100X Faster Responses</h4>
                                            </div>
                                        </a-col>
                                        <a-col :sm="0" :xs="0" :md="11" :lg="11" :offset="1">
                                            <router-link to="/developement"> <a-row class="sel-menu">
                                                    <h3 class="title-sel-menu"> <b>Developement</b> </h3>
                                                    <p class="desc">We grant you lowest cost development for platforms. with
                                                        additional benifits of customisation as per your need.</p>

                                                </a-row> </router-link>
                                            <router-link to="/dashboards">
                                                <a-row class="sel-menu">


                                                    <h3 class="title-sel-menu"> <b>Dashboards</b> </h3>
                                                    <p class="desc">To monitor platform we provide you the dashboards.</p>

                                                </a-row>
                                            </router-link>
                                            <router-link to="/crekaa.ui">
                                                <a-row class="sel-menu">


                                                    <h3 class="title-sel-menu"> <b>Crekaa.UI Boost</b> </h3>
                                                    <p class="desc">We provide you the edge cut techonology for faster
                                                        responces.</p>

                                                </a-row>
                                            </router-link>
                                        </a-col>



                                    </a-row>
                                </div>
                            </template>
                            <!-- <template #title>
            <span>Title</span>
        </template> -->
                            <a-text-button>Developer <ion-icon class="ion-icon-menu" name="chevron-down-outline"></ion-icon></a-text-button>
                        </a-popover>
                    </a-col>
                    <a-col :sm="0" :xs="0" :md="5" :lg="3">


                        <a-popover placement="bottom">
                            <template #content>
                                <div class="content-product">
                                    <img src="../../assets/the_assets/Images/top-ui-nav (2).jpg" style="width:100%;border-radius:10px;height:auto" alt="">


                                    <a-row>
                                        <a-col :sm="0" :xs="0" :md="12" :lg="12">
                                            <h1 class="product-title-bold">Front End</h1>
                                        </a-col>
                                        <a-col :sm="0" :xs="0" :md="12" :lg="12">
                                            <h1 class="product-title-bold">Backend End</h1>

                                        </a-col>

                                    </a-row>
                                    <a-row>
                                        <a-col :sm="0" :xs="0" :md="12" :lg="12">
                                            <a href="#per_page_developement" v-if="this.$route.path === '/products/'">
                                                <h1 class="title-tech" style="font-size: 12px;"><ion-icon name="code-slash-outline"></ion-icon> PPD</h1>
                                            </a>
                                            <router-link to="/products/#per_page_developement" v-else>
                                                <h1 class="title-tech" style="font-size: 12px;"><ion-icon name="code-slash-outline"></ion-icon> PPD</h1>
                                            </router-link>
                                        </a-col>
                                        <a-col :sm="0" :xs="0" :md="12" :lg="12">
                                            <a href="#django" v-if="this.$route.path === '/products/'">
                                                <h1 class="title-tech" style="font-size: 12px;"> <ion-icon name="logo-python"></ion-icon> Python</h1>
                                            </a>
                                            <router-link to="/products/#django" v-else>
                                                <h1 class="title-tech" style="font-size: 12px;"> <ion-icon name="logo-python"></ion-icon>  Python</h1>
                                            </router-link>

                                        </a-col>

                                    </a-row>
                                    <a-row>
                                        <a-col :sm="0" :xs="0" :md="12" :lg="12">
                                            <a href="#clean_css" v-if="this.$route.path === '/products/'">
                                                <h1 class="title-tech" style="font-size: 12px;"> <ion-icon name="logo-css3"></ion-icon>  Clean CSS</h1>
                                            </a>
                                            <router-link to="/products/#clean_css" v-else>
                                                <h1 class="title-tech" style="font-size: 12px;"> <ion-icon name="logo-css3"></ion-icon>  Clean CSS</h1>
                                            </router-link>
                                        </a-col>
                                        <a-col :sm="0" :xs="0" :md="12" :lg="12">
                                            <a href="#aws" v-if="this.$route.path === '/products/'">
                                                <h1 class="title-tech" style="font-size: 12px;"><ion-icon name="cloud-done-outline"></ion-icon> AWS</h1>
                                            </a>
                                            <router-link to="/products/#aws" v-else>
                                                <h1 class="title-tech" style="font-size: 12px;"><ion-icon name="cloud-done-outline"></ion-icon> AWS</h1>
                                            </router-link>

                                        </a-col>

                                    </a-row>

                                    <a-row>
                                        <a-col :sm="0" :xs="0" :md="12" :lg="12">
                                            <a href="#crekaa_UI" v-if="this.$route.path === '/products/'">
                                                <h1 class="title-tech" style="font-size: 12px;"><ion-icon name="flash-outline"></ion-icon> Crekaa.UI</h1>
                                            </a>
                                            <router-link to="/products/#crekaa_UI" v-else>
                                                <h1 class="title-tech" style="font-size: 12px;"><ion-icon name="flash-outline"></ion-icon> Crekaa.UI</h1>
                                            </router-link>
                                        </a-col>
                                        <a-col :sm="0" :xs="0" :md="12" :lg="12">
                                            <a href="#crekaa_ui_technologies" v-if="this.$route.path === '/products/'">
                                                <h1 class="title-tech" style="font-size: 12px;"> <ion-icon name="trophy-outline"></ion-icon> Crekaa.UI Technologies</h1>
                                            </a>
                                            <router-link to="/products/#crekaa_ui_technologies" v-else>
                                                <h1 class="title-tech" style="font-size: 12px;"> <ion-icon name="trophy-outline"></ion-icon> Crekaa.UI Technologies</h1>
                                            </router-link>

                                        </a-col>

                                    </a-row>
                                    <a-row class="phara-sec">
                                        <a-col :sm="0" :xs="0" :md="24" :lg="24">
                                            <p>
                                                We Develope The Solutions Over Buisness Problems & We Are Commited To
                                                Develope the Edge Cut Solutions For Startups, Buisness, & Problems Via
                                                Screza Internet Solutions.
                                            </p>
                                        </a-col>

                                    </a-row>
                                </div>

                            </template>
                            <!-- <template #title>
            <span>Title</span>
        </template> -->
                            <a-text-button>Products <ion-icon class="ion-icon-menu" name="chevron-down-outline"></ion-icon></a-text-button>
                        </a-popover>
                    </a-col>

                    <a-col :sm="0" :xs="0" :md="5" :lg="3">


                        <router-link to="/our-customers">
                            <a-text-button>
                                Customer</a-text-button>
                        </router-link>
                    </a-col>

                    <a-col :sm="0" :xs="0" :md="4" :lg="3">

                        <router-link to="/our-experties">
                            <a-text-button>Our Experties</a-text-button>
                        </router-link>
                    </a-col>
                    <a-col :sm="0" :xs="0" :md="5" :lg="3">

                        <a-popover placement="bottomRight">
                            <template #content>
                                <div class="content-resources">
                                    <img src="../../assets/the_assets/Images/top-ui-nav (4).jpg" style="width:100%;border-radius:10px;height:auto" alt="">

                                    <a-row>
                                        <a-col :sm="0" :xs="0" :md="12" :lg="12">
                                            <h1 class="product-title-bold">
                                                
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 512 512"><path fill="#000000" d="M424.188 85.594c.33.05-.86.094-3.313.094-18.512 0-35.78 20.06-35.78 47.5 0 13.83 4.712 26.208 11.624 34.78l10.217 12.626-16.062 2.47c-10.56 1.617-17.663 6.432-23.625 14.342s-10.344 19.18-13.25 32.344c-5.186 23.492-5.59 52.425-5.625 78.156h30.438l.593 8.72 7.094 106.593h65.813l8.03-106.69.657-8.624h32c-.24-25.383-1.62-53.933-7.344-77.25-3.213-13.086-7.783-24.354-13.687-32.375-5.905-8.02-12.7-12.85-22.064-14.593l-15.72-2.937 10.283-12.25c7.228-8.623 12.186-21.138 12.186-35.313 0-25.74-15.5-45.406-32.28-47.562l-.188-.03zm-244.75 3.375c-45.9.006-99.844 5.373-161.625 17.905V126c268.018-37.648 341.545 38.15 226.25 79.344-117.327 41.92-210.503 252.258 159.406 270.594 4.362.438 8.85.687 13.436.687 22.28 0 42.34-5.452 56.125-13.594 13.787-8.14 20.595-18.14 20.595-27.905s-6.808-19.764-20.594-27.906c-.23-.138-.482-.272-.717-.408l-2 26.47-.657 8.656H369l-.563-8.75-3.28-49.5c-128.194 2.8-163.256-100.51-80.094-130.47 20.385-7.344 37.086-15.836 50.187-25.03.17-.822.32-1.655.5-2.47 3.262-14.774 8.256-28.498 16.594-39.562 4.946-6.563 11.24-12.07 18.72-15.97-3.124-42.547-68.335-81.236-191.627-81.217z"/></svg>
                                                
                                                Blog</h1>
                                        </a-col>


                                    </a-row>
                                    <a-row>

                                        <a-col :sm="0" :xs="0" :md="12" :lg="12">


                                            <!-- <router-link to="/documentation"> <h1 class="title-tech">Documentation</h1> </router-link>  -->
                                        </a-col>

                                    </a-row>
                                    <a-row>
                                        <a-col :sm="0" :xs="0" :md="12" :lg="12">
                                            <h1 class="product-title-bold">Guid</h1>
                                        </a-col>
                                        <a-col :sm="0" :xs="0" :md="12" :lg="12">
                                            <h1 class="product-title-bold">
                                                About Tutorials</h1>
                                        </a-col>

                                    </a-row>
                                    <a-row>
                                        <a-col :sm="0" :xs="0" :md="12" :lg="12">

                                            <a href="#crekaa_UI" v-if="this.$route.path === '/documentation'">
                                                <h1 class="title-tech">Documentation</h1>
                                            </a>
                                            <router-link to="/documentation" v-else>
                                                <h1 class="title-tech">Documentation</h1>
                                            </router-link>



                                        </a-col>
                                        <a-col :sm="0" :xs="0" :md="12" :lg="12">

                                            <a href="#how_to_checkout_billings"
                                                v-if="this.$route.path === '/documentation'">
                                                <h1 class="title-tech">Checkout Billings</h1>
                                            </a>
                                            <router-link to="/documentation#how_to_checkout_billings" v-else>
                                                <h1 class="title-tech">Checkout & Billings</h1>
                                            </router-link>



                                        </a-col>

                                    </a-row>
                                    <a-row>
                                        <a-col :sm="0" :xs="0" :md="12" :lg="12">
                                            <h1 class="title-tech">How We Build?</h1>
                                        </a-col>
                                        <a-col :sm="0" :xs="0" :md="12" :lg="12">
                                            <h1 class="title-tech">Deploy Idea</h1>
                                        </a-col>
                                    </a-row>
                                    <a-row>
                                        <a-col :sm="0" :xs="0" :md="12" :lg="12">
                                            <h1 class="title-tech">Website Plans</h1>
                                        </a-col>
                                        <a-col :sm="0" :xs="0" :md="12" :lg="12">
                                            <h1 class="title-tech">Develope App</h1>
                                        </a-col>

                                    </a-row>

                                    <hr>
                                    <a-row>
                                        <a-col :sm="0" :xs="0" :md="12" :lg="12">

                                            <router-link to="/my-settings" class="title-tech">
                                            <h1 class="product-title-bold">Settings</h1>
                                            </router-link>
                                        </a-col>
                                        <a-col :sm="0" :xs="0" :md="12" :lg="12">
                                            <router-link to="/credit-terms" class="title-tech">
                                            <h1 class="product-title-bold">Credit Terms</h1>
                                            </router-link>
                                        </a-col>

                                    </a-row>
                                    <a-row>
                                        <a-col :sm="0" :xs="0" :md="12" :lg="12">
                                            <router-link to="/contact-us" class="title-tech">
                                            <h1 class="product-title-bold">Contact Us</h1>
                                        </router-link>
                                        </a-col>
                                        <a-col :sm="0" :xs="0" :md="12" :lg="12">
                                            <router-link to="/redeedm-credit" class="title-tech">
                                                <h1 class="product-title-bold">Redeem Credits</h1>
                                            </router-link>
                                        </a-col>

                                    </a-row>
                                    <a-row>

                                        <!-- <a-col :sm="0" :xs="0" :md="12" :lg="12">
                                            
                                            
                                            </a-col> -->

                                        <a-col :sm="0" :xs="0" :md="12" :lg="12">
                                            
                                            
                                            <router-link to="/crekaa-careers">
                                                <a-text-button>
                                                    Careers</a-text-button>
                                                </router-link>
                                            </a-col>
                                        </a-row>
                                </div>
                            </template>
                            <!-- <template #title>
        <span>Title</span>
    </template> -->
                            <a-text-button>Resources <ion-icon class="ion-icon-menu" name="chevron-down-outline"></ion-icon></a-text-button>
                        </a-popover>
                    </a-col>
                   
                </a-row>
                
            </a-col>
            <a-col :sm="0" :xs="0" :md="6" :lg="5">

                <a-row>
                    <a-col :sm="0" :xs="0" :md="8" :lg="8" class="logo-menu" v-if="!this.$store.state.token">


                        <router-link to="/signin"> <a-text-button>SignIn</a-text-button></router-link>


                    </a-col>

                    <a-col :sm="0" :xs="0" :md="8" :lg="8" class="logo-menu" v-if="!this.$store.state.token">


                        <router-link to="/signup"> <a-text-button>Get Started</a-text-button></router-link>



                    </a-col>

                    <a-col :sm="0" :xs="0" :md="8" :lg="11" class="logo-menu" v-if="this.$store.state.token">

<div v-if="this.$store.state.token">

    <a-popover placement="bottomRight" >
        <template #content  >
            <div class="content-notifications">
<div v-for="noti in notificatios_nav" >

      <router-link :to="'/notification-details/' + noti.notification_id" style="color:var(--text-color)">
                <a-alert  style="margin-bottom:5px;background-color:var(--notification-color-bg)"
                type=""
                :message="clipText(noti.title, 20)" 
      :description="ellipsisText(noti.subject, 2) "
      closable
      @close="onClose"
    />
      </router-link>
    </div>
    
    <div v-if="notificatios_nav.length >= 5 ">
        <router-link to="/my-notifications">

            <a-button block  type="text"> Show All</a-button>
        </router-link>
    </div>
    <a-empty v-if="notificatios_nav.length <=0 " style="margin-top:100px">
        <template #description>
      <span>
        No Unseen Notifications Available
      </span>
    </template>
    

    </a-empty>
            </div>

        </template>
        <template #title>
            <b> My Notifications </b>
        </template>
        <!-- <a-text-button>welcome </a-text-button> -->
        <div v-if="this.$store.state.userDetils[0]" style="display:flex;justify-content: end;">

            <a-text-button style="font-size:12px;text-align:start">

                <span style="font-size:12px;text-align:start">
                    
                    <a-badge :count="notificatios_count" style="color:var(--white)">
                        <ion-icon  style="color:var(--text-color);border-radius:15px;font-size: 22px;" alt="Avatar" class="noti-nav" name="notifications-outline"></ion-icon>
                </a-badge>

                </span>
                
            </a-text-button>


        </div>


    </a-popover>

</div>
</a-col>
                    <a-col :sm="0" :xs="0" :md="5" :lg="5" class="logo-menu" v-if="this.$store.state.token">

                        <div v-if="this.$store.state.token">

                            <a-popover placement="bottomRight">
                                <template #content>
                                    <div class="ul-rec-mob">
                                        <a-row type="flex" justify="center" class="hover-a action-user" style="padding:5px;
                                                            border-radius:10px;
                                                            font-weight:bold;
                                                            color:var(--text-color);
                                                        background-color:rgba(128, 128, 128, 0.238)"
                                                        >
                                            
                                            <a-col :sm="0" :xs="0" :md="7" :lg="7" style="text-align: center;">
                                                <img :src="this.$store.state.userDetils[0].profile_picture" style="
                                                    width:40px;
                                                    margin-top:5px;
                                                    padding:0;
                                                    height:40px;
                                                    border:2px solid grey ;
                                                    border-radius:50px" alt="Avatar"
                                                    >
                                            </a-col>
                                            <a-col :sm="0" :xs="0" :md="17" :lg="14">

                                                <li>
                                                    <h1 style="font-size: 10px;font-weight: 500;margin:0;margin-top:5px">
                                                        Signed in as</h1>


                                                    <h1 style="font-size: 12px; margin-top:0"><b> {{
                                                        this.$store.state.userDetils[0].user.first_name }} {{
        this.$store.state.userDetils[0].user.last_name }}
                                                        </b> </h1>

                                                </li>

                                            </a-col>
                                        </a-row>

                                        <a-divider
                                            style="margin-top:5px;margin-bottom:5px;border-color: var(--devide-color);" />
                                        <router-link to="/my-profile">
                                            <a-row type="flex" justify="center" style="" class="hover-a action-user">
                                                <a-col :sm="0" :xs="0" :md="4" :lg="4" style="text-align: center;">
                                                    <ion-icon class="icon-user-options ion-icon-menu" name="person-outline"></ion-icon>

                                                </a-col>
                                                <a-col :sm="0" :xs="0" :md="20" :lg="20">
                                                    <span class="fa-14">My Profile</span>

                                                </a-col>
                                            </a-row>

                                        </router-link>

                                        <router-link to="/my-dashboard">
                                            <a-row type="flex" justify="center" style="" class="hover-a action-user">
                                                <a-col :sm="0" :xs="0" :md="4" :lg="4" style="text-align: center;">
                                                    <ion-icon class="icon-user-options ion-icon-menu" name="bar-chart-outline"></ion-icon>

                                                </a-col>
                                                <a-col :sm="0" :xs="0" :md="20" :lg="20">
                                                    <span class="fa-14">My Dashboard</span>


                                                </a-col>
                                            </a-row>
                                        </router-link>
                                        <!-- <a class="hover-a action-user"  href="">
                <div class=" flx gap-10 ">
                  <ion-icon name="code-slash-outline"></ion-icon>
                  <span class="fa-14">Your Projects</span>
                </div>
              </a> -->

                                        <router-link to="/my-patches">
                                            <a-row type="flex" justify="center" style="" class="hover-a action-user">
                                                <a-col :sm="0" :xs="0" :md="4" :lg="4" style="text-align: center;">
                                                    <ion-icon name="barcode-outline" role="img"
                                                        class="md hydrated ion-icon-menu"></ion-icon>

                                                </a-col>
                                                <a-col :sm="0" :xs="0" :md="20" :lg="20">
                                                    <span class="fa-14">My Patches</span>
                                                </a-col>
                                            </a-row>
                                        </router-link>
                                        <router-link to="/my-billings">
                                            <a-row type="flex" justify="center" style="" class="hover-a action-user">
                                                <a-col :sm="0" :xs="0" :md="4" :lg="4" style="text-align: center;">
                                                    <ion-icon name="card-outline" role="img" class="md hydrated ion-icon-menu"></ion-icon>

                                                </a-col>
                                                <a-col :sm="0" :xs="0" :md="20" :lg="20">
                                                    <span class="fa-14">My Billings</span>
                                                </a-col>
                                            </a-row>
                                        </router-link>

                                        <router-link to="/my-notifications">
                                            <a-row type="flex" justify="center" style="" class="hover-a action-user">
                                                <a-col :sm="0" :xs="0" :md="4" :lg="4" style="text-align: center;">
                                                    <ion-icon name="notifications-outline" role="img"
                                                        class="md hydrated ion-icon-menu"></ion-icon>

                                                </a-col>
                                                <a-col :sm="0" :xs="0" :md="20" :lg="20">
                                                    <span class="fa-14">Notifications</span>
                                                </a-col>
                                            </a-row>
                                        </router-link>


                                        <a-divider
                                            style="margin-top:5px;margin-bottom:5px;border-color: var(--devide-color);" />

                                        <router-link to="/redeedm-credit">
                                            <a-row type="flex" justify="center" style="" class="hover-a action-user">
                                                <a-col :sm="0" :xs="0" :md="4" :lg="4" style="text-align: center;">
                                                    <ion-icon name="invert-mode-outline" role="img"
                                                        class="md hydrated ion-icon-menu"></ion-icon>
                                                </a-col>
                                                <a-col :sm="0" :xs="0" :md="20" :lg="20">
                                                    <span class="fa-14">My Credit Wallet</span>
                                                </a-col>
                                            </a-row>
                                        </router-link>

                                        <router-link to="/my-settings">
                                            <a-row type="flex" justify="center" style="" class="hover-a action-user">
                                                <a-col :sm="0" :xs="0" :md="4" :lg="4" style="text-align: center;">
                                                    <ion-icon name="cog-outline" role="img" class="md hydrated ion-icon-menu"></ion-icon>

                                                </a-col>
                                                <a-col :sm="0" :xs="0" :md="20" :lg="20">
                                                    <span class="fa-14">Settings</span>
                                                </a-col>
                                            </a-row>
                                        </router-link> <br>

                                    </div>

                                    <a-button type="primary" size="large" style="height:40px;border-radius:10px" block
                                        @click="logout()">
                                        <a-row type="flex" justify="center">
                                            <a-col :sm="1" :xs="1">
                                                <ion-icon style="font-size: 18px;padding-top:3px" class="ion-icon-menu"
                                                    name="log-out-outline"></ion-icon>
                                            </a-col>
                                            <a-col :sm="23" :xs="23">
                                                <span style="font-size: 14px;">SignOut</span>

                                            </a-col>
                                        </a-row>
                                    </a-button>

                                </template>
                                <template #title>
                                    <span>{{ this.$store.state.userDetils[0].user.profile_picture }}</span>
                                </template>
                                <!-- <a-text-button>welcome </a-text-button> -->
                                <div v-if="this.$store.state.userDetils[0]" style="display:flex;justify-content: end;">

                                    <a-text-button style="font-size:12px;text-align:start">

                                        <span v-if="this.$store.state.userDetils[0].user.first_name"
                                            style="font-size:12px;text-align:start">
                                            <img :src="this.$store.state.userDetils[0].profile_picture" style="
        /* position:absolute; */
        width:40px;margin-top:-10px;padding:0;
        height:40px;
        border:2px solid grey ;
        border-radius:50px" alt="Avatar" />
                                            <!-- <span style="padding-top:-10px;font-size: 14px;">

                Welcome
                {{ this.$store.state.userDetils[0].user.first_name }}
        </span> -->
                                        </span>
                                        <span v-else>User</span>
                                    </a-text-button>


                                </div>


                            </a-popover>

                        </div>
                    </a-col>
                    <a-col :sm="0" :xs="0" :md="10" :lg="8" style="display:flex;justify-content:end">

                        <!-- The dark Mode Switch based on cache  -->
                        <!-- this switch will have caching capabilities of the entire app with method switchMode is thtogglin the dark mode and light mode and app.js will have caclhe checking fot the above methods  -->
                        <div class="light" style="margin-top:10px;margin-bottom:10px">
                            <input class="checkbox" type="checkbox" id="toggle" @change="switchMode()">
                            <label class="toggle" for="toggle">
                                <ion-icon class="icon icon--light md hydrated ion-icon-menu" name="sunny-outline" role="img"></ion-icon>
                                <ion-icon class="icon icon--dark md hydrated ion-icon-menu" name="moon-outline" role="img"></ion-icon>
                                <span class="ball"></span>
                            </label>
                        </div>
                        <!-- end dark mode switch -->

                    </a-col>
                </a-row>
            </a-col>

        </a-row>

        <a-row style="">
            <a-col :sm="21" :xs="20" :md="0" :lg="0" class="logo-menu">
                                <router-link to="/" style="color:var(--text-color)">
                                    <a-row>
                        <a-col :sm="2" :xs="2" :md="8" :lg="8">
                            <img style="width:100%;margin-top:-5px;margin-left:15px" src="../../assets/logo.png" alt="">
                        </a-col>
                        <a-col :sm="8" :xs="8" :md="16" :lg="16">
                            <span style="text-align:start;color:var(--text-color); font-size:var(--fa-19);font-weight:bold" class="main-logo-text">&nbsp;
                        &nbsp;CREKAA</span>
                    </a-col>

                    </a-row>

                <!-- <h1 style="text-align:start; font-size:18px;;font-weight:bold;z-index:10">&nbsp; &nbsp;CREKAA</h1> -->
                                </router-link>
            </a-col>
            <a-col :sm="3" :xs="4" :md="0" :lg="0">
                <a-text-button type="primary" @click="showDrawer"><ion-icon name="apps-outline" class="ion-icon-menu"
                        style="padding:20px;font-size:20px"></ion-icon></a-text-button>
                <a-drawer width="85%" :placement="placement" :open="open" @close="onClose">
                    <template #title>
                        <h1 style="text-align:center;font-size: var(--fa-18); font-weight: 800;" class="main-logo-text"> CREKAA</h1>
                    </template>
                    <template #extra>
                        <!-- <a-button style="margin-right: 8px" @click="onClose">Cancel</a-button> -->
                        <!-- <a-button type="primary" @click="onClose">Submit</a-button> -->
                        <!-- The dark Mode Switch based on cache  -->
                        <!-- this switch will have caching capabilities of the entire app with method switchMode is thtogglin the dark mode and light mode and app.js will have caclhe checking fot the above methods  -->
                        <div class="light" style="margin-top:10px;margin-bottom:10px">
                            <input class="checkbox" type="checkbox" id="toggle" @change="switchMode()">
                            <label class="toggle" for="toggle">
                                <ion-icon class="icon icon--light md hydrated ion-icon-menu" name="sunny-outline" role="img"></ion-icon>
                                <ion-icon class="icon icon--dark md hydrated ion-icon-menu" name="moon-outline" role="img"></ion-icon>
                                <span class="ball"></span>
                            </label>
                        </div>
                        <!-- end dark mode switch -->
                    </template>
                    <nav_slider />

                </a-drawer>
            </a-col>
        </a-row>

    </nav>

    

    <br>
    <br>
</template>
<script>
import nav_slider from '@/components/navigation/Navbar_Slider.vue'
import axios from 'axios'

export default {
    name: "nav_main",
    data() {
        return {
            placement: 'left',
            open: false,
            notificatios_nav:[],
            notificatios_count:0,

        }
    },
//     watch: {
//         '$route.fullPath': : 'fetchNotifications',
//   },
    components: {
        nav_slider
    },

    mounted() {
        this.$store.commit('initializeStore');

        this.fetchUserData();
        // Check for mode in cache on page load
        var element = document.body;
        var mode = localStorage.getItem("mode");
        if (mode === "dark") {
            element.classList.add("dark-mode");
            element.classList.add("dark");
        } else {
            element.classList.remove("dark-mode");
        }
        // smoothe scroll
        this.setupSmoothScroll();
        
        if(localStorage.getItem("token"))
        {
             this.fetchNotifications();
             
        }

    },
    methods: {
        clipText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  },
  ellipsisText(text, maxLines) {
    const lines = text.split('\n');
    if (lines.length > maxLines) {
      return lines.slice(0, maxLines).join('\n') + '...';
    }
    return text;
  },

async fetchNotifications() {
    this.$store.commit('setIsLoading', true)
    await axios
        .post("my-nav-notifications/")
        .then(response => {
            // console.log(response);
            // console.log(response.data);
            this.notificatios_nav = response.data["Notifications"]
            this.notificatios_count = response.data["count"]

        })
        .catch(error => {
            // console.log("===========error at user details")                        
            console.log(JSON.stringify(error.response.data))
            console.log(JSON.stringify(error))
        })
    this.$store.commit('setIsLoading', false)
},


        async fetchUserData() {

            this.$store.commit('setIsLoading', true)
            const token = this.$store.state.token;
            //   console.log(token)

            if (token) {

                axios.defaults.headers.common['Authorization'] = "token " + token;
                axios
                    .post("user-details/")
                    .then(response => {
                        // console.log("========---------------===============----------------=================");
                        // console.log(response);
                        // console.log(response.data);

                        const discription = response.data['0']['discription'];
                        const city = response.data['0']['city'];
                        const state = response.data['0']['state'];
                        const country = response.data['0']['country'];
                        const profile_picture = response.data['0']['profile_picture'];
                        const profile_background = response.data['0']['profile_background'];
                        const user = response.data['0']['user'];
                        const userData = {
                            discription: discription,
                            city: city,
                            state: state,
                            country: country,
                            profile_picture: profile_picture,
                            profile_background: profile_background,
                            user: user,
                        };

                        // console.log("------------------------------------userData------------------------------------", userData);
                        this.$store.commit('addUserDetails', userData);
                        // console.log("--------------------------------------Done---------------------------------");
                        // console.log("the stored data : ",this.$store.state.userDetils)
                        // console.log("the stored User data : ",this.$store.state.userDetils['0']['user'])
                        // console.log("the stored User data : ",this.$store.state.userDetils[0].user.first_name);

                    })
                    .catch(error => {
                        // console.log("===========error at user details")                        
                        console.log(JSON.stringify(error.response.data))
                        console.log(JSON.stringify(error))
                    })
                // this.$store.commit('setIsLoading',false)
            } else {
                axios.defaults.headers.common['Authorization'] = "";
            }
            this.$store.commit('setIsLoading', false)

        },
        // logout the user
        logout() {
            axios.defaults.headers.common["Authorization"] = ""

            localStorage.removeItem("token")
            localStorage.removeItem("username")
            localStorage.removeItem("userid")

            this.$store.commit('removeToken')

            this.$router.push('/')
            this.$store.commit('removeUserDetails')
            // console.log("removal of userDetails")
            // console.log(this.$store.state.userDetils)
        },
        // smooth scroll
        setupSmoothScroll() {
            document.querySelectorAll('a[href^="#"]').forEach(anchor => {
                anchor.addEventListener('click', function (e) {
                    e.preventDefault();

                    const targetId = this.getAttribute('href').substring(1);
                    const targetElement = document.getElementById(targetId);

                    if (targetElement) {
                        window.scrollTo({
                            behavior: 'smooth',
                            top: targetElement.offsetTop
                        });
                    }
                });
            });
        }
        ,

        //  Show Drawer 
        showDrawer() {
            this.open = true
        },
        onClose() {
            this.open = false;
        },
        // Dark mode
        switchMode() {
            var element = document.body;

            if (element.classList.contains("dark-mode")) {
                this.$store.commit('toggleDarkModeFalse');

                this.theme = 'light';
                // console.log(this.theme);

                element.classList.remove("dark-mode");
                element.classList.remove("dark");
                element.classList.add("light-mode");
                localStorage.setItem("mode", "light");
            } else {
                this.$store.commit('toggleDarkModeTrue');
                this.theme = 'dark';
                // console.log(this.theme);
                element.classList.remove("light-mode");
                element.classList.add("dark-mode");
                element.classList.add("dark");
                localStorage.setItem("mode", "dark");
            }
        },

    }

}
</script>
<style  scoped>

.main-logo-text{
    background: -webkit-linear-gradient(340deg, rgb(208, 0, 255) 24%, rgb(2, 194, 220) 80%);
    -webkit-background-clip: text;
    font-weight: 800;
    -webkit-text-fill-color: transparent;
    /* font-family: "Alkatra"; */
    /* display: flex; */
    /* font-size: 28px; */
    /* font-weight: 800; */
}
.noti-nav{
    /* border: 1px solid #9c8b8b; */
    padding:5px;
    background: #9c8b8b1f;

}
.noti-nav:hover{
    background: #9c8b8b3d;
    padding:5px;
    transition: background 0.4s ;
}
.ul-rec-mob {
    list-style-type: none;
    margin-top: 0px;
    margin-bottom: 0px
}

.ul-rec-mob li {
    font-size: 14px;
    margin-left: 0;
    margin-top: 2px;
}

.logo-menu {
    padding-top: 20px
}

.action-user {
    padding: 5px;
    border-radius: 10px;
    font-weight: bold;
    color: var(--text-color)
}

.action-user:hover {
    background: var(--hover-slide-button);

}

nav {
    /* padding-top:15px; */
    background-color: var(--navbar-color);
    position: fixed;
    width: 100%;
    /* height:70px */
}

a-text-button {
    font-size: var(--fa-14);
    font-weight: bold;
    color: var(--text-color)
}

.content-popover {
    width: 600px
}

.desc {
    font-size: var(--fa-10);
}

.sel-menu:hover {
    cursor: pointer
}

.sel-menu {
    /* background:rgb(255, 134, 154); */
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    color: var(--text-color);
}

.sel-menu:hover {
    background: var(--hover-slide-button)
}

.title-sel-menu {
    margin: 0
}

.right-crekaa {
    background-color: rgba(128, 128, 128, 0.511);
    border-radius: 20px;
    height: 100%;
    width: 100%;
    text-align: center;
}

.right-crekaa-title {
    color: white;
    margin: 0
}

.right-crekaa-desc {
    color: white
}

a-text-button {
    cursor: pointer
}

a-text-button:hover {
    color: var(--hover-color)
}

/*animating dropdown arrow  */
a-text-button:hover .ion-icon-menu {
    transform: rotate(180deg);
    transition: transform 500ms;
}

a-text-button:not(:hover) .ion-icon-menu {
    transform: rotate(0deg);
    transition: transform 500ms;
}

/* end animating droiopdown arrow  */
.content-product {
    width: 300px
}

.product-title-bold {
    font-size: var(--fa-14);
    font-weight: bold
}

.title-tech {
    font-size: var(--fa-14);
    color: var(--text-color)
        /* font-weight:bold */
}

.title-tech:hover {
    color: var(--hover-color);
    cursor: pointer
}

.phara-sec {
    background-color: rgba(128, 128, 128, 0.349);
    padding: 10px;
    border-radius: 10px;

}

.phara-sec p {
    font-size: var(--fa-10);
}

.content-resources {
    /* background-color:red; */
    width: 250px;

}

.content-notifications {
    /* background-color:red; */
    width: 250px;
    height:400px;
    overflow-y: scroll;
}
</style>
