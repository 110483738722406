<template>
    <div  class="body" style="text-align: start;">

        
  <section>
    
    <div class="section-header">
      <div class="container">
        <h2>Contact Us</h2>
        <p>
          Welcome to Crekaa! We specialize in crafting innovative software solutions that empower businesses to address their customers' needs. Our expertise extends to the development of websites, mobile applications, and customized business solutions. As a technology-focused startup, we invite you to share your ideas with us. Feel free to reach out, and we'll get in touch with you as soon as possible to bring your vision to life
        </p>
      </div>
    </div>
    
    <div class="container">
      <div class="row">
        
        <div class="contact-info">
          <div class="contact-info-item">
            <div class="contact-info-icon" >
                <ion-icon name="home-outline" style="font-size: 50px;color:var(--black);padding:10px;"></ion-icon>
            </div>
            
            <div class="contact-info-content">
              <h4>Address</h4>
              <p>We Are Totally Online Community & have Existance Only Virtual Platform.</p>
            </div>
          </div>
          
          <div class="contact-info-item">
            
            <div class="contact-info-icon" >

              <a style="color:cyan;text-decoration:none" href="https://www.linkedin.com/company/crekaatech/">
              <ion-icon name="logo-linkedin"  style="font-size: 50px;color:var(--black);padding:10px;"></ion-icon>
              </a>
                <!-- <ion-icon name="call-outline" style="font-size: 50px;color:var(--black);padding:10px;"></ion-icon> -->
            </div>
            
            
            <div class="contact-info-content">
              <h4>Linkedin</h4>
              <p>Linkedin  <a style="color:cyan;text-decoration:none" href="https://www.linkedin.com/company/crekaatech/"> Company Page </a></p>
            </div>
          </div>
          
          <div class="contact-info-item">
            <div class="contact-info-icon" >
                <ion-icon name="mail-unread-outline" style="font-size: 50px;color:var(--black);padding:10px;"></ion-icon>
            </div>
            
            <div class="contact-info-content">
              <h4>Email</h4>
             <p>crekaa.technologies@gmail.com</p>
            </div>
          </div>
        </div>
        
        <div class="contact-form">
          <form action="" id="contact-form">
            <h2>Send Message</h2>
            <div class="input-box" >
              
              <a-input type="email" style="padding-left:20px"  v-model:value="email" placeholder="Email"/>
            </div>

            <div class="input-box" >
              
              <a-input type="text"  style="padding-left:20px" v-model:value="first_name" placeholder="Full Name"/>
            </div>
            <div class="input-box" >
              
              <a-input type="text"  style="padding-left:20px" v-model:value="title" placeholder="Title"/>
            </div>
            
            <div class="input-box" >
              
              <a-input type="text"  style="padding-left:20px" v-model:value="subject" placeholder="Subject"/>
            </div>

            <div class="input-box">
              <a-textarea v-model:value="description"  placeholder="Type your Message..."></a-textarea>
              
            </div>
            
            <!-- <div class="input-box"> -->
              <a-button size="large" block type="primary" :loading="loding_submit_contact_us" @click="this.$store.state.userDetils[0] ? submitFormAuthenticatedUser() : submitForm()" style="border-radius:0;height:50px;font-size: 16px;font-weight: bold;">Send</a-button>
            <!-- </div> -->
          </form>
        </div>
        

            </div>
    </div>
  </section>
    </div>
</template>
<script>
import { notification } from 'ant-design-vue';
import axios from 'axios'
import { useHead } from '@unhead/vue'

export default{
    name:'contact_us',
created(){
    document.title = "Contacu Us - Crekaa";
  },
  head() {
    return {
      title: "Contact US - Crekaa",
      meta: [
        { hid: 'description', name: 'description', content: "CC Crekaa COntact US" },
        { hid: 'author', name: 'author', content: "Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        
        // <!-- Facebook Open Graph tags -->
        { hid: 'og:url', property: 'og:url', content: "https://crekaa.com/" },
        { hid: 'og:type', property: 'og:type', content: "website" },
        { hid: 'og:title', property: 'og:title', content: "Crekaa, Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'og:description', property: 'og:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'og:image', property: 'og:image', content: "../../assets/logo.png" },
        // Add more meta tags as needed

        // <!-- Twitter Card tags -->
        { hid: 'twitter:card', name: 'twitter:card', content: "summary_large_image" },
        { hid: 'twitter:title', name: 'twitter:title', content: "Crekaa , Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'twitter:description', name: 'twitter:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'twitter:image', name: 'twitter:image', content: "../../assets/logo.png" },

      ]
    };
  },

    data(){
      return {
        email:'',
        first_name:'',
        title:'',
        subject:'',
        description:'',
        loding_submit_contact_us:false
      }
    },
    methods:{
      async submitForm(){
      this.loding_submit_contact_us = true;

        // this.$store.commit('setIsLoading', true)
        
        if (this.first_name == '') {
                notification['error']({
                    message: 'Name Is Empty ',
                    placement: 'bottomRight',
                }); 
            this.loding_submit_contact_us=false

                return ;
              }
        if (this.email == '') {
                notification['error']({
                    message: 'Email Is Empty ',
                    placement: 'bottomRight',
                }); 
            this.loding_submit_contact_us=false

                return ;
            }
            if (this.title == '') {
                notification['error']({
                    message: 'Title Is Empty ',
                    placement: 'bottomRight',
                }); 
            this.loding_submit_contact_us=false

                return ;
            }
            if (this.subject == '') {
                notification['error']({
                    message: 'Subject Is Empty ',
                    placement: 'bottomRight',
                }); 
            this.loding_submit_contact_us=false

                return ;
            }

      if (this.description == ''){
        notification['error']({
            message: 'Description Is Empty ',
            description:"Please Describe Brif About Your Concern",
            placement: 'bottomRight',
        }); 
        this.loding_submit_contact_us=false

        return ;
      }
      
      
        const formData = {
          'name':this.first_name,
          'email':this.email,
          'title':this.title,
          'subject':this.subject,
          'description':this.description,
          'category':'Random'
           }
                // console.log(formData)
               await axios
                    .post("contact-us/", formData)
                .then(response => {
                  
                  notification['success']({
                    message: 'Sent Request Successful ',
                    description:response.data['msg'],
                    placement: 'bottomRight',
                  }); 
                  this.first_name='',
                  this.email='',
                  this.title='',
                  this.subject='',
                  this.description=''
        
                })
                .catch(error => {
                    // console.log("===========error at user details")                        
                    console.log(JSON.stringify(error.response.data))
                    console.log(JSON.stringify(error))
                })
            // this.$store.commit('setIsLoading', false)
            this.loding_submit_contact_us=false
      },
      async submitFormAuthenticatedUser(){
      this.loding_submit_contact_us = true;

        // this.$store.commit('setIsLoading', true)
        
        if (this.first_name == '') {
                notification['error']({
                    message: 'Name Is Empty ',
                    placement: 'bottomRight',
                }); 
            this.loding_submit_contact_us=false

                return ;
              }
        if (this.email == '') {
                notification['error']({
                    message: 'Email Is Empty ',
                    placement: 'bottomRight',
                }); 
            this.loding_submit_contact_us=false

                return ;
            }
            if (this.title == '') {
                notification['error']({
                    message: 'Title Is Empty ',
                    placement: 'bottomRight',
                }); 
            this.loding_submit_contact_us=false

                return ;
            }
            if (this.subject == '') {
                notification['error']({
                    message: 'Subject Is Empty ',
                    placement: 'bottomRight',
                }); 
            this.loding_submit_contact_us=false

                return ;
            }

      if (this.description == ''){
        notification['error']({
            message: 'Description Is Empty ',
            description:"Please Describe Brif About Your Concern",
            placement: 'bottomRight',
        }); 
        this.loding_submit_contact_us=false

        return ;
      }
      
      
        const formData = {
          'name':this.first_name,
          'email':this.email,
          'title':this.title,
          'subject':this.subject,
          'description':this.description,
          'category':'Random'
           }
                // console.log(formData)
               await axios
                    .post("contact-us-authenticated-user/", formData)
                .then(response => {
                  
                  notification['success']({
                    message: 'Sent Request Successful ',
                    description:response.data['msg'],
                    placement: 'bottomRight',
                  }); 
                  this.first_name='',
                  this.email='',
                  this.title='',
                  this.subject='',
                  this.description=''
        
                })
                .catch(error => {
                    // console.log("===========error at user details")                        
                    console.log(JSON.stringify(error.response.data))
                    console.log(JSON.stringify(error))
                })
            // this.$store.commit('setIsLoading', false)
            this.loding_submit_contact_us=false
      }
    }
}</script>
<style scoped>

.body {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(https://images.pexels.com/photos/325185/pexels-photo-325185.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940);
  background-size: cover;
  background-position: center;
  position: relative;
}

.body::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.6);
}
section {
  position: relative;
  z-index: 3;
  padding-top: 50px;
  padding-bottom: 50px;
}

.container {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.section-header {
  margin-bottom: 50px;
  text-align: center;
}

.section-header h2 {
  color: #FFF;
  font-weight: bold;
  font-size: 3em;
  margin-bottom: 20px;
}

.section-header p {
  color: #FFF;
}

.row  {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.contact-info {
  width: 50%;
}

.contact-info-item {
  display: flex;
  margin-bottom: 30px;
}

.contact-info-icon {
  height: 70px;
  width: 70px;
  background-color: #fff;
  text-align: center;
  border-radius: 50%;
}

.contact-info-icon i {
  font-size: 30px;
  line-height: 70px;
}

.contact-info-content {
  margin-left: 20px;
}

.contact-info-content h4 {
  color: #1da9c0;
  font-size: 1.4em;
  margin-bottom: 5px;
}

.contact-info-content p {
  color: #FFF;
  font-size: 1em;
}

.contact-form {
  background-color: #fff;
  padding: 40px;
  width: 45%;
  padding-bottom: 20px;
  padding-top: 20px;
}

.contact-form h2 {
  font-weight: bold;
  font-size: 2em;
  margin-bottom: 10px;
  color: #333;
}

.contact-form .input-box {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.contact-form .input-box input,
.contact-form .input-box textarea{
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;
}

.contact-form .input-box span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  pointer-events: none;
  transition: 0.5s;
  color: #666;
}

.contact-form .input-box input:focus ~ span,
.contact-form .input-box textarea:focus ~ span{
  color: var(--primary-color);
  font-size: 12px;
  transform: translateY(-20px);
}

.contact-form .input-box input[type="submit"]
{
  width: 100%;
  background: #00bcd4;
  color: #FFF;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
  border: 1px solid #00bcd4;
  transition: 0.5s;
}

.contact-form .input-box input[type="submit"]:hover
{
  background: #FFF;
  color: #00bcd4;
}

@media (max-width: 991px) {
  section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .row {
    flex-direction: column;
  }
  
  .contact-info {
    margin-bottom: 40px;
    width: 100%;
  }
  
  .contact-form {
    width: 100%;
  }
}
</style>