<template>
    <!-- Your Body Here -->
    <main>
        <section>

            <section class="hero">
                <div class="hero__background">
                    <div class="hero__text" style="text-align:start;color:black">
                        <h1>We Innovate,<br>Devlope & Revolutionize<br> To attain market dominance</h1>
                        <h2>Crekaa Business the ultimate and cost-effective answer for <br>
                            Crafting online ecosystems, Empowering mobile experiences & Fortifying server infrastructures
                            <br> We've already garnered the workforce's trust
                            <br> and the confidence of IT administrators...
                        </h2>
                        <br>
                        <div class="hero__trial" style="text-align:center">
                            <a-button type="primary" size="large" style="height:60px;font-weight: bold; width:300px">
                                <p>Free For - 30 days to 90 days <br><span>(Depends on plan )</span></p>
                            </a-button>
                        </div> <br>
                        <a-divider style="border-color:black;color:black" >or</a-divider><br>
                        <a-button block size="large" style="border-radius:5px">Buy Now</a-button>
                    </div><br>
                </div>

            </section>
            <br>
            <hr><br>

            <section class="section-intro-develop">
                <div class="headline-intro-develop">
                    <h1> How We grant the developent for your buisness. </h1>
                </div>
                <div class="flx-developement">
                    <div class="left-section" style="text-align:start;line-height: 1.5; ">
                        <h2>Before We Accept Any Contract &amp; Project, We Conduct a Thorough Business Mapping</h2>
                        <p style="">At our company, we believe in delivering tailored solutions that drive your business forward.
                            Before embarking on any project or contract, we follow a meticulous approach of conducting a
                            comprehensive business mapping process. This ensures that we deeply understand the intricacies
                            of your business and its unique challenges.</p>
                        <p>Our dedicated team of developers takes the time to thoroughly analyze various aspects that
                            directly influence your business growth. Through a combination of technical expertise and
                            business acumen, we seek to identify the most effective solutions that align with your specific
                            needs.</p>
                        <p>During the business mapping process, we focus on:</p>
                        <ul>
                            <li>Evaluating your current business processes and workflows.</li>
                            <li>Identifying pain points and bottlenecks that hinder efficiency.</li>
                            <li>Understanding your short-term and long-term business goals.</li>
                            <li>Assessing market trends and industry best practices.</li>
                            <li>Exploring opportunities for innovation and differentiation.</li>
                            <li>Identifying potential areas for automation and optimization.</li>
                        </ul>
                        <p>By gaining a deep understanding of your business landscape, we can develop a clear roadmap for
                            success. Our solutions are not just technically sound; they are strategically aligned with your
                            business objectives, ensuring maximum impact and a higher return on investment.</p>
                        <p>We pride ourselves on being your trusted partner, and our commitment to understanding your
                            business sets the foundation for a strong and enduring collaboration. Together, we'll navigate
                            the challenges and seize the opportunities that lie ahead, as we propel your business towards
                            greater heights of success.</p>



                    </div>
                    <div class="right-section">
                        <lottie-player
                            style="background-color:  rgba(255, 255, 255, 0.90);max-width:100%;;border-radius:20px"
                            src="https://crekaa-2024.s3.amazonaws.com/static/lottiefiles/buisness-growth.json"
                            background="transparent" speed="1" loop="" autoplay=""></lottie-player>

                    </div>
                </div>
                <br>
                <hr><br>

                <div class="flx-developement gap10">
                    <div class="right-section">
                        <lottie-player
                            style="background-color:  rgba(255, 255, 255, 0.90);max-width:100%;;border-radius:20px"
                            src="https://lottie.host/08906014-1519-4b31-8fdf-d19126c72834/h92Rj04bV8.json"
                            background="transparent" speed="1" loop="" autoplay=""></lottie-player>

                    </div>

                    <div class="left-section" style="text-align:start;line-height: 1.5; ">
                        <h2>After We Have a Proper Solution</h2>
                        <p>Once we have carefully crafted a comprehensive solution that perfectly aligns with your business
                            needs, we proceed towards implementing it with utmost dedication. This is a pivotal moment where
                            we transform the business problem analysis into actionable steps, commencing your contract as a
                            full-fledged project.</p>
                        <p>Our approach encompasses the following key steps:</p>
                        <ol>
                            <li><strong>SEO Target:</strong> We understand the significance of visibility in today's digital
                                landscape. Our team delves into the realm of Search Engine Optimization (SEO) to ensure that
                                your business receives the attention it deserves. By optimizing your online presence, we
                                increase the chances of reaching the right audience and attracting potential customers.</li>
                            <li><strong>Business Technical Solution Development:</strong> With the business mapping complete
                                and SEO targets in sight, we embark on the technical development phase. Our skilled
                                developers put their expertise to work, meticulously crafting solutions that cater to your
                                unique requirements. Our goal is to create a seamless technological foundation that empowers
                                your business operations and facilitates growth.</li>
                            <li><strong>Contract Completion:</strong> We take immense pride in our commitment to delivering
                                excellence. As we near the conclusion of your project, we ensure that every aspect of the
                                contract is meticulously fulfilled. Our team rigorously tests the solutions, making certain
                                they meet the highest standards of quality and functionality. We believe in leaving no loose
                                ends, providing you with a finished product that exceeds your expectations.</li>
                        </ol>
                        <p>Throughout this phase, we maintain open lines of communication, keeping you informed about the
                            progress and incorporating your valuable feedback. Your satisfaction is at the core of our
                            mission, and we aim to not only meet but surpass your project's goals.</p>
                        <p>With the completion of this vital phase, your business is equipped with a robust technical
                            solution, fine-tuned to conquer challenges and propel growth. We consider it a privilege to be
                            part of your journey towards success and eagerly anticipate witnessing your business thrive in
                            the ever-evolving digital landscape.</p>


                    </div>

                </div>
            </section>
            <br>
            <hr><br>

            <section class="tabbed-panes">

                <!--SVG GRADIENTS AND SPRITES PART-->
                <svg aria-hidden="true" focusable="false" style="width:0;height:0;position:absolute;">
                    <linearGradient id="icon-gradient">
                        <stop offset="0%" stop-color="var(--color-accent-light)"></stop>
                        <stop offset="100%" stop-color="var(--color-accent-dark)"></stop>
                    </linearGradient>
                    <defs>
                        <symbol id="icon-brain" viewBox="0 0 66 66">
                            <g>
                                <g>
                                    <g>
                                        <path d="m28.5 58c-.6 0-1-.4-1-1v-45.8c0-.6.4-1 1-1s1 .4 1 1v45.8c0 .6-.4 1-1 1z">
                                        </path>
                                    </g>
                                    <g>
                                        <path
                                            d="m11.9 41.8c-2.9 0-5.1-2.2-5.1-5.1s2.2-5.1 5.1-5.1c.6 0 1 .4 1 1s-.4 1-1 1c-1.7 0-3.1 1.4-3.1 3.1s1.4 3.1 3.1 3.1c.6 0 1 .4 1 1s-.4 1-1 1z">
                                        </path>
                                    </g>
                                    <g>
                                        <path
                                            d="m23.8 11.6c-.6 0-1-.4-1-1 0-1.7-1.4-3.1-3.1-3.1s-3.1 1.4-3.1 3.1c0 .6-.4 1-1 1s-1-.4-1-1c0-2.9 2.2-5.1 5.1-5.1s5.1 2.2 5.1 5.1c0 .6-.4 1-1 1z">
                                        </path>
                                    </g>
                                    <g>
                                        <path
                                            d="m21.7 61.2c-1.2 0-2.3-.5-3.1-1.3-.8-.9-1.2-2-1.2-3.2 0-.6.5-1 1.1-.9.6 0 1 .5.9 1.1 0 .6.2 1.3.6 1.7.4.5 1 .7 1.7.7 1.4 0 2.5-1 2.5-2.3 0-.6.4-1 1-1s1 .4 1 1c0 2.3-2 4.2-4.5 4.2z">
                                        </path>
                                    </g>
                                    <g>
                                        <path
                                            d="m18.4 32.8c-.1 0-.2 0-.3 0-1.9-.6-3-2.6-2.5-4.5.4-1.5 1.6-2.5 3.2-2.6h.5c.6 0 1 .4 1 1s-.4 1-1 1h-.4c-.6.1-1.2.5-1.3 1.2-.3.9.3 1.8 1.1 2.1.5.2.8.7.7 1.2-.2.3-.6.6-1 .6z">
                                        </path>
                                    </g>
                                    <g>
                                        <path
                                            d="m28.6 20.3c-.6 0-1-.4-1-1v-.4c-.1-.6-.5-1.2-1.2-1.3-.9-.3-1.8.3-2.1 1.1-.2.5-.7.8-1.2.7-.5-.2-.8-.7-.7-1.2.6-1.9 2.6-3 4.5-2.5 1.5.4 2.5 1.6 2.6 3.2v.5c.1.5-.3.9-.9.9z">
                                        </path>
                                    </g>
                                    <g>
                                        <path
                                            d="m28.6 28c-.6 0-1-.4-1-1v-.4c-.1-.6-.5-1.2-1.2-1.3-.9-.3-1.8.3-2.1 1.1-.2.5-.7.8-1.2.7-.5-.2-.8-.7-.7-1.2.6-1.9 2.6-3 4.5-2.5 1.5.4 2.5 1.6 2.6 3.2v.5c.1.5-.3.9-.9.9z">
                                        </path>
                                    </g>
                                    <g>
                                        <path
                                            d="m15.6 35.8c-.5 0-.9-.4-1-.9-.1-.9.1-1.9.7-2.6.6-.8 1.6-1.3 2.6-1.3.9-.1 1.8.1 2.5.7.8.6 1.3 1.6 1.3 2.6 0 .6-.4 1-.9 1.1-.6 0-1-.4-1.1-.9 0-.5-.2-.9-.6-1.2-.3-.3-.7-.4-1.1-.3-.5 0-1 .3-1.2.6-.3.3-.4.7-.3 1.1.1.5-.3 1.1-.8 1.1z">
                                        </path>
                                    </g>
                                    <g>
                                        <path
                                            d="m28.6 44.4c-.5 0-1-.4-1-.9-.1-1-.8-1.8-1.8-2.1-1.2-.4-2.6.4-3 1.7-.1.5-.7.8-1.2.7s-.8-.7-.7-1.2c.7-2.4 3.1-3.7 5.4-3.1 1.8.5 3.1 2.1 3.2 3.9.1.5-.3 1-.9 1z">
                                        </path>
                                    </g>
                                    <g>
                                        <path
                                            d="m18.6 46.8c-.5 0-1-.4-1-.9 0-.6.4-1 .9-1.1.6 0 1.2-.3 1.6-.8s.6-1.1.5-1.7c-.1-1.2-1.1-2.4-2.6-2.3-.5 0-1-.4-1.1-.9 0-.6.4-1 .9-1.1 2.3-.1 4.4 1.7 4.6 4.1.1 1.1-.2 2.3-1 3.2-.5.9-1.6 1.4-2.8 1.5z">
                                        </path>
                                    </g>
                                    <g>
                                        <path
                                            d="m15.9 19.7c-.1 0-.3 0-.4-.1-1.6-.8-3-2-3.9-3.5-1.9-2.8-2.2-6.4-.6-9.6 2.3-4.8 8.2-6.9 13-4.7 3.6 1.7 5.8 5.5 5.6 9.4 0 .6-.5 1-1.1.9-.6 0-1-.5-.9-1.1.2-3.1-1.6-6.1-4.4-7.4-3.9-1.8-8.5-.1-10.4 3.7-1.2 2.5-1 5.3.5 7.6.8 1.2 1.8 2.2 3.1 2.8.5.2.7.8.5 1.3-.3.5-.6.7-1 .7z">
                                        </path>
                                    </g>
                                    <g>
                                        <path
                                            d="m21.9 65c-.1 0-.2 0-.3 0-2.1-.1-4-1-5.4-2.5-2.9-3.1-2.7-8 .5-10.9.4-.4 1-.4 1.4.1.4.4.4 1-.1 1.4-2.3 2.1-2.4 5.8-.3 8.1 1 1.1 2.4 1.8 4 1.8 1.5.1 3-.5 4.1-1.5 1.3-1.1 2-2.9 1.8-4.7-.1-.5.3-1 .9-1.1.6 0 1 .3 1.1.9.2 2.4-.7 4.8-2.4 6.4-1.5 1.2-3.4 2-5.3 2z">
                                        </path>
                                    </g>
                                    <g>
                                        <path
                                            d="m8.4 28.7c-.3 0-.7-.2-.9-.5-1.6-2.7-1.8-6-.6-8.7 1-2.2 2.8-4 5.1-4.8.5-.2 1.1.1 1.3.6s-.1 1.1-.6 1.3c-1.7.6-3.2 2-3.9 3.8-1 2.2-.9 4.8.4 6.9.3.5.1 1.1-.3 1.4-.1 0-.3 0-.5 0z">
                                        </path>
                                    </g>
                                    <g>
                                        <path
                                            d="m13.7 49.9c-6.9 0-12.3-5.4-12.3-12.3 0-6.8 5.5-12.3 12.3-12.3.6 0 1 .4 1 1s-.4 1-1 1c-5.7 0-10.3 4.6-10.3 10.3 0 5.8 4.5 10.3 10.3 10.3.6 0 1 .4 1 1s-.4 1-1 1z">
                                        </path>
                                    </g>
                                    <g>
                                        <path
                                            d="m14.5 59h-.1c-3.1-.4-5.6-2.5-6.5-5.4-.7-2-.5-4.3.5-6.1.2-.5.8-.7 1.2-.5.5.2.7.8.4 1.3-.7 1.4-.8 3.2-.3 4.6.7 2.2 2.6 3.8 4.9 4 .5.1.9.6.9 1.1-.1.6-.5 1-1 1z">
                                        </path>
                                    </g>
                                    <g>
                                        <path d="m28.6 58c-.6 0-1-.4-1-1v-45.8c0-.6.4-1 1-1s1 .4 1 1v45.8c0 .6-.4 1-1 1z">
                                        </path>
                                    </g>
                                </g>
                                <g>
                                    <path
                                        d="m37.9 40h-9.3c-.6 0-1-.4-1-1v-12c0-.6.4-1 1-1h9.3c.6 0 1 .4 1 1v12c0 .6-.4 1-1 1zm-8.3-2h7.3v-10h-7.3z">
                                    </path>
                                </g>
                                <g>
                                    <path
                                        d="m39 44.9h-10.5c-.6 0-1-.4-1-1v-21.8c0-.6.4-1 1-1h10.5c2.5 0 4.7 2.2 4.7 4.7v14.4c0 2.6-2.1 4.7-4.7 4.7zm-9.5-2h9.5c1.5 0 2.7-1.2 2.7-2.7v-14.4c0-1.4-1.3-2.7-2.7-2.7h-9.5z">
                                    </path>
                                </g>
                                <g>
                                    <path d="m52.9 33.6h-9.9c-.6 0-1-.4-1-1s.4-1 1-1h9.9c.6 0 1 .4 1 1s-.4 1-1 1z"></path>
                                </g>
                                <g>
                                    <path fill="var(--text-color)"
                                        d="m56.8 36.7c-2.3 0-4.1-1.8-4.1-4.1s1.8-4.1 4.1-4.1 4.1 1.8 4.1 4.1-1.8 4.1-4.1 4.1zm0-6.2c-1.2 0-2.1.9-2.1 2.1s.9 2.1 2.1 2.1 2.1-.9 2.1-2.1-.9-2.1-2.1-2.1z">
                                    </path>
                                </g>
                                <g>
                                    <path fill="var(--text-color)" d="m35.6 22.9c-.6 0-1-.4-1-1v-9.9c0-.6.4-1 1-1s1 .4 1 1v9.9c0 .6-.4 1-1 1z">
                                    </path>
                                </g>
                                <g>
                                    <path fill="var(--text-color)"
                                        d="m35.6 12.2c-2.3 0-4.1-1.8-4.1-4.1s1.8-4.1 4.1-4.1 4.1 1.8 4.1 4.1-1.8 4.1-4.1 4.1zm0-6.2c-1.2 0-2.1.9-2.1 2.1s.9 2.1 2.1 2.1 2.1-.9 2.1-2.1-.9-2.1-2.1-2.1z">
                                    </path>
                                </g>
                                <g>
                                    <path  fill="var(--text-color)" d="m35.6 55c-.6 0-1-.4-1-1v-9.9c0-.6.4-1 1-1s1 .4 1 1v9.9c0 .6-.4 1-1 1z"></path>
                                </g>
                                <g>
                                    <path fill="var(--text-color)"
                                        d="m35.6 62c-2.3 0-4.1-1.8-4.1-4.1s1.8-4.1 4.1-4.1 4.1 1.8 4.1 4.1-1.8 4.1-4.1 4.1zm0-6.2c-1.2 0-2.1.9-2.1 2.1s.9 2.1 2.1 2.1 2.1-.9 2.1-2.1-.9-2.1-2.1-2.1z">
                                    </path>
                                </g>
                                <g>
                                    <path fill="var(--text-color)"
                                        d="m60.5 48.3c-2.3 0-4.1-1.8-4.1-4.1s1.8-4.1 4.1-4.1 4.1 1.8 4.1 4.1-1.8 4.1-4.1 4.1zm0-6.2c-1.2 0-2.1.9-2.1 2.1s.9 2.1 2.1 2.1 2.1-.9 2.1-2.1-.9-2.1-2.1-2.1z">
                                    </path>
                                </g>
                                <g>
                                    <path  fill="var(--text-color)"
                                        d="m56.7 45.2h-6.4c-.6 0-1-.4-1-1v-3.5h-5.8c-.6 0-1-.4-1-1s.4-1 1-1h6.8c.6 0 1 .4 1 1v3.5h5.4c.6 0 1 .4 1 1s-.4 1-1 1z">
                                    </path>
                                </g>
                                <g>
                                    <path  fill="var(--text-color)"
                                        d="m60.5 25c-2.3 0-4.1-1.8-4.1-4.1s1.8-4.1 4.1-4.1 4.1 1.8 4.1 4.1-1.8 4.1-4.1 4.1zm0-6.2c-1.2 0-2.1.9-2.1 2.1s.9 2.1 2.1 2.1 2.1-.9 2.1-2.1-.9-2.1-2.1-2.1z">
                                    </path>
                                </g>
                                <g>
                                    <path fill="var(--text-color)"
                                        d="m50.3 26.4h-7.3c-.6 0-1-.4-1-1s.4-1 1-1h6.3v-3.5c0-.6.4-1 1-1h6.4c.6 0 1 .4 1 1s-.4 1-1 1h-5.4v3.5c0 .6-.4 1-1 1z">
                                    </path>
                                </g>
                            </g>
                        </symbol>
                        <symbol id="icon-bulb" viewBox="0 0 512 512">
                            <path fill="var(--text-color)"
                                d="M256.011,16H256A160.035,160.035,0,0,0,117.132,255.516a161.029,161.029,0,0,0,55.644,57.149A23.857,23.857,0,0,1,184,332.98v4.4A23.977,23.977,0,0,0,174.131,376a23.943,23.943,0,0,0,0,32A23.977,23.977,0,0,0,184,446.624V456a40.045,40.045,0,0,0,40,40h64a40.045,40.045,0,0,0,40-40v-9.376A23.977,23.977,0,0,0,337.869,408a23.943,23.943,0,0,0,0-32A23.977,23.977,0,0,0,328,337.376v-4.4a23.955,23.955,0,0,1,11.568-20.523A159.891,159.891,0,0,0,416,176C416,87.782,344.229,16.006,256.011,16ZM192,352H320a8,8,0,0,1,0,16H192a8,8,0,0,1,0-16Zm40-96h-8a8,8,0,1,1,8-8Zm16,16h16v64H248Zm80,120a8.009,8.009,0,0,1-8,8H192a8,8,0,0,1,0-16H320A8.009,8.009,0,0,1,328,392Zm-40,88H224a24.027,24.027,0,0,1-24-24v-8H312v8A24.027,24.027,0,0,1,288,480Zm32-48H192a8,8,0,0,1,0-16H320a8,8,0,0,1,0,16Zm11.2-133.183a39.85,39.85,0,0,0-19.2,34.16V336H280V272h8a24,24,0,1,0-24-24v8H248v-8a24,24,0,1,0-24,24h8v64H200v-3.02a39.95,39.95,0,0,0-18.891-33.973A143.982,143.982,0,0,1,256,32h.011C335.406,32.006,400,96.6,400,176A143.156,143.156,0,0,1,331.2,298.817ZM280,256v-8a8,8,0,1,1,8,8Z">
                            </path>
                            <path 
                                d="M256,48h-8a8,8,0,0,0,0,16h8A112.127,112.127,0,0,1,368,176v8a8,8,0,0,0,16,0v-8A128.145,128.145,0,0,0,256,48Z">
                            </path>
                        </symbol>
                        <symbol id="icon-customers" viewBox="0 0 64 64">
                            <path 
                                d="m29.905 4.782-1.49 2.821c-.053.101-.151.172-.263.191l-3.144.546c-.865.15-1.576.767-1.848 1.602-.271.835-.058 1.752.553 2.382l2.223 2.29c.08.082.117.196.101.309l-.453 3.158c-.125.87.242 1.737.953 2.253.71.516 1.648.597 2.436.21l2.864-1.407c.103-.05.223-.05.326 0l2.864 1.407c.788.387 1.726.306 2.436-.21.711-.516 1.078-1.383.953-2.253l-.453-3.158c-.016-.113.021-.227.101-.309l2.223-2.29c.611-.63.824-1.547.553-2.382-.272-.835-.983-1.452-1.848-1.602l-3.144-.546c-.112-.019-.21-.09-.263-.191l-1.49-2.821c-.411-.777-1.217-1.262-2.095-1.262s-1.684.485-2.095 1.262zm1.769.934c.064-.121.189-.196.326-.196s.262.075.326.196l1.491 2.821c.342.649.966 1.103 1.689 1.228l3.144.546c.135.023.246.119.288.249s.009.273-.086.371l-2.223 2.289c-.511.527-.75 1.26-.645 1.987l.452 3.158c.02.136-.038.271-.148.351-.111.08-.257.093-.38.033l-2.864-1.407c-.658-.323-1.43-.323-2.088 0l-2.864 1.407c-.123.06-.269.047-.38-.033-.11-.08-.168-.215-.148-.351l.452-3.158c.105-.727-.134-1.46-.645-1.987l-2.223-2.289c-.095-.098-.128-.241-.086-.371s.153-.226.288-.249l3.144-.546c.723-.125 1.347-.579 1.689-1.228z">
                            </path>
                            <path 
                                d="m56.659 14.128-3.049.941c-.109.034-.228.015-.321-.05l-2.609-1.838c-.718-.505-1.657-.572-2.44-.174-.782.399-1.28 1.198-1.293 2.076l-.047 3.191c-.002.114-.056.221-.148.289l-2.553 1.914c-.703.526-1.057 1.399-.919 2.266.137.868.743 1.588 1.574 1.872l3.02 1.03c.108.037.193.122.23.23l1.031 3.02c.283.831 1.004 1.437 1.871 1.575.867.137 1.74-.217 2.266-.92l1.914-2.553c.068-.092.175-.146.29-.148l3.19-.047c.878-.013 1.677-.511 2.076-1.293.399-.783.332-1.722-.174-2.44l-1.837-2.608c-.066-.094-.085-.212-.051-.322l.941-3.048c.259-.84.033-1.753-.589-2.374-.621-.621-1.534-.848-2.373-.589zm.59 1.911c.13-.04.273-.005.369.092.097.097.132.239.092.37l-.941 3.048c-.217.702-.096 1.463.327 2.063l1.837 2.609c.078.112.089.258.027.38s-.187.199-.324.201l-3.19.047c-.734.011-1.421.361-1.861.949l-1.913 2.553c-.082.109-.218.165-.353.143-.135-.021-.247-.116-.292-.245l-1.03-3.02c-.237-.694-.783-1.24-1.477-1.477l-3.02-1.03c-.129-.044-.224-.157-.245-.292s.034-.271.143-.353l2.554-1.913c.587-.44.937-1.127.948-1.861l.047-3.19c.002-.137.079-.261.201-.324.122-.062.268-.051.38.028l2.609 1.837c.6.422 1.362.543 2.063.326z">
                            </path>
                            <path 
                                d="m4.379 17.091.941 3.048c.034.11.015.228-.051.322l-1.837 2.608c-.506.718-.573 1.657-.174 2.44.399.782 1.198 1.28 2.076 1.293l3.19.047c.115.002.222.056.29.148l1.914 2.553c.526.703 1.399 1.057 2.266.92.867-.138 1.588-.744 1.871-1.575l1.031-3.02c.037-.108.122-.193.23-.23l3.02-1.03c.831-.284 1.437-1.004 1.574-1.872.138-.867-.216-1.74-.919-2.266l-2.553-1.913c-.092-.069-.146-.176-.148-.29l-.047-3.191c-.013-.878-.511-1.677-1.293-2.076-.783-.398-1.722-.331-2.44.174l-2.609 1.837c-.093.066-.212.085-.321.051l-3.049-.941c-.839-.259-1.752-.032-2.373.589-.622.621-.848 1.534-.589 2.374zm1.911-.59c-.04-.131-.005-.273.092-.37.096-.097.239-.132.369-.092l3.049.941c.701.217 1.463.096 2.063-.326l2.609-1.837c.112-.079.258-.09.38-.028.122.063.199.187.201.324l.047 3.19c.011.734.361 1.421.948 1.861l2.554 1.913c.109.082.164.218.143.353s-.116.248-.245.292l-3.02 1.03c-.694.237-1.24.783-1.477 1.477l-1.03 3.02c-.045.129-.157.224-.292.245-.135.022-.271-.034-.353-.143l-1.913-2.553c-.44-.588-1.127-.938-1.861-.949l-3.19-.047c-.137-.002-.262-.079-.324-.201s-.051-.268.027-.38l1.837-2.609c.423-.6.544-1.361.327-2.063z">
                            </path>
                            <path fill="var(--text-color)"
                                d="m32 23.148c-10.302 0-18.666 8.364-18.666 18.666 0 10.303 8.364 18.666 18.666 18.666s18.666-8.363 18.666-18.666c0-10.302-8.364-18.666-18.666-18.666zm0 2c9.198 0 16.666 7.468 16.666 16.666 0 9.199-7.468 16.666-16.666 16.666s-16.666-7.467-16.666-16.666c0-9.198 7.468-16.666 16.666-16.666z">
                            </path>
                            <path 
                                d="m39.187 43.464c-3.715 0-10.666 0-14.38.026-.986 0-1.913.495-2.465 1.319-.552.825-.657 1.871-.279 2.788 1.586 3.924 5.44 6.7 9.937 6.7 4.494 0 8.347-2.773 9.961-6.689.382-.926.276-1.981-.281-2.813s-1.493-1.331-2.494-1.331zm0 2c.334 0 .646.167.831.444.186.277.221.629.094.937-1.315 3.191-4.451 5.452-8.112 5.452-3.658 0-6.793-2.258-8.085-5.455-.001-.002-.002-.004-.003-.006-.124-.301-.089-.644.092-.914s.484-.432.809-.432h.007c3.711-.026 10.655-.026 14.367-.026z">
                            </path>
                            <path fill="var(--text-color)"
                                d="m23.215 38.06c.065-.416.386-.703.75-.959.207-.146.455-.227.712-.317.353-.124.743-.177 1.14-.153.328-.02.651.011.954.095.217.06.43.112.62.211.508.264.951.604 1.028 1.123 0 .278.225.504.503.504s.504-.226.504-.504c.136-.923-.337-1.793-1.094-2.473-.457-.41-1.051-.694-1.693-.857-.266-.067-.543-.088-.822-.098-.373.015-.742.056-1.088.166-.54.171-1.041.424-1.434.778-.759.684-1.228 1.559-1.087 2.484 0 .278.226.504.504.504.277 0 .503-.226.503-.504z">
                            </path>
                            <path fill="var(--text-color)"
                                d="m35.581 38.06c.066-.416.386-.703.751-.959.206-.146.455-.227.711-.317.354-.124.744-.177 1.14-.153.328-.02.651.011.954.095.218.06.43.112.62.211.509.264.951.604 1.028 1.123 0 .278.226.504.503.504.278 0 .504-.226.504-.504.136-.923-.336-1.793-1.094-2.473-.457-.41-1.051-.694-1.693-.857-.265-.067-.543-.088-.822-.098-.372.015-.742.056-1.088.166-.54.171-1.041.424-1.434.778-.758.684-1.228 1.559-1.087 2.484 0 .278.226.504.504.504s.503-.226.503-.504z">
                            </path>
                            <path  fill="var(--text-color)"
                                d="m25.796 51.367c-.178.444-.017.951.384 1.211 1.677 1.088 3.675 1.719 5.82 1.719 2.144 0 4.142-.631 5.82-1.716.402-.26.563-.768.385-1.212-.889-2.22-3.318-3.86-6.205-3.86-2.886 0-5.314 1.639-6.204 3.858zm2.271-.007c.807-1.123 2.277-1.851 3.933-1.851 1.657 0 3.126.728 3.934 1.852-1.183.599-2.519.936-3.934.936s-2.751-.337-3.933-.937z">
                            </path>
                        </symbol>
                        <symbol id="icon-rocket" viewBox="0 0 494.929 494.929">
                            <g>
                                <g>
                                    <g>
                                        <path fill="var(--text-color)" d="M494.927,8.642c0.025-2.3-0.875-4.516-2.5-6.141s-3.683-2.508-6.142-2.5c-102.072,1.28-193.04,30.465-266.529,84.908
         c-55.458-12.753-114.236,3.824-154.363,43.967C30.56,163.704,13.21,211.854,17.785,260.971c0.308,3.267,2.458,6.071,5.533,7.212
         c0.967,0.354,1.975,0.529,2.967,0.529c2.175,0,4.317-0.833,5.933-2.4c19.211-18.582,42.257-32.123,67.378-39.957
         c-12.583,24.434-22.991,50.354-30.994,77.773c-0.875,2.992-0.05,6.221,2.158,8.425l14.29,14.289l-49.44,27.603
         c-2.333,1.304-3.925,3.625-4.292,6.275c-0.367,2.646,0.525,5.313,2.417,7.204l92.925,93.013c1.608,1.617,3.783,2.504,6.033,2.504
         c0.392,0,0.775-0.025,1.167-0.079c2.65-0.367,4.975-1.954,6.283-4.288l27.685-49.456l14.548,14.548
         c1.625,1.625,3.8,2.5,6.033,2.5c0.8,0,1.6-0.112,2.392-0.342c27.352-7.979,53.214-18.351,77.597-30.894
         c-7.823,25.1-21.31,48.116-39.789,67.294c-2.275,2.362-3,5.817-1.858,8.892c1.142,3.071,3.942,5.221,7.208,5.525
         c5.225,0.487,10.425,0.729,15.608,0.729c43.692,0,85.467-17.142,116.492-48.246c40.136-40.141,56.752-98.993,44.041-154.554
         C464.495,201.601,493.65,110.672,494.927,8.642z M34.177,241.917c0.908-37.829,16.2-73.892,43.283-100.975
         c32.633-32.626,79.011-47.904,124.435-41.974c-9.703,8.061-19.115,16.529-28.119,25.533
         c-24.447,24.445-45.43,51.605-62.888,81.236C82.823,211.742,56.65,224.058,34.177,241.917z M130.76,440.896l-76.983-77.05
         l43.815-24.461l57.694,57.692L130.76,440.896z M190.852,408.504L86.427,304.083c19.758-64.946,53.183-121.287,99.417-167.517
         C260.635,61.779,361.393,20.625,477.677,17.25c-3.367,116.292-44.525,217.054-119.317,291.838
         C312.118,355.329,255.785,388.758,190.852,408.504z M353.985,417.567c-27,27.067-63.092,42.333-101.025,43.196
         c17.77-22.432,30.043-48.572,36.066-76.613c29.693-17.477,56.906-38.5,81.401-62.995c9.029-9.028,17.52-18.466,25.602-28.197
         C401.929,338.496,386.617,384.934,353.985,417.567z"></path>
                                        <path  d="M88.843,455.413c-22.158,16.192-47.842,24.079-71.5,22.171c-1.917-23.633,5.992-49.333,22.183-71.492
         c2.775-3.804,1.95-9.142-1.858-11.921c-3.808-2.796-9.142-1.95-11.925,1.854C5.427,423.821-3.498,456.833,1.252,486.596
         c0.583,3.642,3.433,6.5,7.083,7.079c5.25,0.842,10.592,1.254,16,1.254c25.242-0.004,51.683-9.008,74.575-25.742
         c3.808-2.779,4.633-8.117,1.858-11.921C97.985,453.471,92.652,452.617,88.843,455.413z"></path>
                                        <path  d="M333.427,161.508c-16.125-16.117-37.55-24.996-60.342-24.996c-22.8,0-44.225,8.879-60.342,24.996
         c-33.267,33.267-33.267,87.404,0,120.679c16.117,16.117,37.542,24.996,60.342,24.996c22.792,0,44.217-8.879,60.342-24.996
         C366.693,248.913,366.693,194.775,333.427,161.508z M321.36,270.121c-12.9,12.892-30.042,19.996-48.275,19.996
         c-18.242,0-35.383-7.104-48.275-19.996c-26.617-26.621-26.617-69.929,0-96.546c12.892-12.892,30.033-19.996,48.275-19.996
         c18.233,0,35.375,7.104,48.275,19.996C347.977,200.192,347.977,243.5,321.36,270.121z"></path>
                                    </g>
                                </g>
                            </g>
                        </symbol>
                        <symbol id="icon-seo" viewBox="0 0 60 60">
                            <path fill="var(--text-color)"
                                d="m25 4a21 21 0 1 0 21 21 21.024 21.024 0 0 0 -21-21zm0 40a19 19 0 1 1 19-19 19.021 19.021 0 0 1 -19 19z">
                            </path>
                            <path fill="var(--text-color)"
                                d="m58.535 51.465-6.671-6.672a4.951 4.951 0 0 0 -3.164-1.429l-3.567-3.564a25.033 25.033 0 1 0 -5.333 5.333l3.564 3.567a4.946 4.946 0 0 0 1.429 3.165l6.671 6.672a5 5 0 1 0 7.071-7.07zm-56.535-26.465a23 23 0 1 1 23 23 23.025 23.025 0 0 1 -23-23zm41.873 16.367 2.409 2.41a4.858 4.858 0 0 0 -2.5 2.5l-2.41-2.409a25.12 25.12 0 0 0 2.501-2.501zm13.247 15.754a3.067 3.067 0 0 1 -4.241 0l-6.672-6.672a3 3 0 0 1 4.242-4.242l6.672 6.672a3.031 3.031 0 0 1 -.001 4.242z">
                            </path>
                            <path 
                                d="m18.8 18.4a1 1 0 0 0 -1.4-.2l-8 6a1 1 0 0 0 0 1.6l8 6a1 1 0 1 0 1.2-1.6l-6.933-5.2 6.933-5.2a1 1 0 0 0 .2-1.4z">
                            </path>
                            <path 
                                d="m40.6 24.2-8-6a1 1 0 0 0 -1.2 1.6l6.933 5.2-6.933 5.2a1 1 0 1 0 1.2 1.6l8-6a1 1 0 0 0 0-1.6z">
                            </path>
                            <path 
                                d="m27.242 16.03a1 1 0 0 0 -1.212.728l-4 16a1 1 0 0 0 .728 1.212 1.017 1.017 0 0 0 .242.03 1 1 0 0 0 .969-.758l4-16a1 1 0 0 0 -.727-1.212z">
                            </path>
                        </symbol>
                        <symbol id="icon-target" fill="var(--text-color)" viewBox="0 0 4335 4335">
                            <path 
                                d="m1789 3012c-413 0-623-502-330-795 167-167 432-183 616-39l230-230c-312-270-781-254-1075 40-497
                                 496-141 1348 559 1348 676 0 1038-798 598-1306l-230 230c237 303 21 752-368 752zm0 1321c-988
                                  0-1787-799-1787-1787 0-987 799-1787 1787-1787 458 0 889 172 1222 484l180-181-96-484c-4-19 2-38
                                   15-52l508-507c33-34 91-14 97 33l60 427 106-107c54-53 135 28 81 82l-106 106 427 59c47 7 67 65 33 98l-507
                                    508c-14 13-33 19-52 15l-485-96-180 180c659 702 643 1802-40 2486-337 337-786 523-1263 523zm0-3458c-924
                                     0-1672 748-1672 1671 0 924 748 1672 1672 1672 923 0 1671-748 1671-1672 0-427-159-830-449-1140l-230 230c485
                                      527 471 1351-40 1862-526 527-1378 527-1905 0-525-525-525-1379 0-1904 512-511 1333-527
                                       1863-40l230-230c-310-290-713-449-1140-449zm1425-289 75 378 383-383-55-399zm-1425 2018c-51
                                        0-77-62-41-99l245-244c-139-100-331-85-452 37-221 221-63 599 248 599 286 0 450-325 285-555l-245
                                         245c-11 11-26 17-40 17zm598-737 231-231c-485-443-1234-427-1700 40-480 480-480 1261 0 1741 481 481 1260 
                                         481 1741 0 467-467 480-1217 40-1700l-231 231c513 581 99 1503-679 1503-242 0-469-94-640-265-353-353-353-927
                                          0-1280 338-339 881-354 1238-39zm1367-1205-383 384 378 75 404-403z">
                            </path>
                            <path
                                d="">
                            </path>
                        </symbol>
                    </defs>
                </svg>
                <!--END SVG GRADIENT PART-->
                <div class="content">
                    <div class="container">
                        <h2 class="content__title">How We Devlop Web Apps, Websites &amp; Mobile Applications</h2>
                        <div class="timeline" style="height:550px">
                            <div class="timeline__stepper">
                                <div class="timeline__step">
                                    <svg class="timeline__icon timeline__icon--default">
                                        <use href="#icon-brain"></use>
                                    </svg>
                                    <svg class="timeline__icon timeline__icon--active">
                                        <use href="#icon-brain"></use>
                                    </svg>
                                    <p class="timeline__step-title">
                                        Brainstorm
                                    </p>
                                </div>
                                <div class="timeline__step">
                                    <svg class="timeline__icon timeline__icon--default">
                                        <use href="#icon-bulb"></use>
                                    </svg>
                                    <svg class="timeline__icon timeline__icon--active">
                                        <use href="#icon-bulb"></use>
                                    </svg>
                                    <p class="timeline__step-title">
                                        Discuss<br>Ideas
                                    </p>
                                </div>
                                <div class="timeline__step">
                                    <svg class="timeline__icon timeline__icon--default">
                                        <use href="#icon-rocket"></use>
                                    </svg>
                                    <svg class="timeline__icon timeline__icon--active">&gt;
                                        <use href="#icon-rocket"></use>
                                    </svg>
                                    <p class="timeline__step-title">
                                        Make<br>Project
                                    </p>
                                </div>
                                <div class="timeline__step">
                                    <svg class="timeline__icon timeline__icon--default">
                                        <use href="#icon-target"></use>
                                    </svg>
                                    <svg class="timeline__icon timeline__icon--active">
                                        <use href="#icon-target"></use>
                                    </svg>
                                    <p class="timeline__step-title">
                                        Select<br>Strategy
                                    </p>
                                </div>
                                <div class="timeline__step">
                                    <svg class="timeline__icon timeline__icon--default">
                                        <use href="#icon-seo"></use>
                                    </svg>
                                    <svg class="timeline__icon timeline__icon--active">
                                        <use href="#icon-seo"></use>
                                    </svg>
                                    <p class="timeline__step-title">
                                        SEO<br>Target
                                    </p>
                                </div>
                                <div class="timeline__step is-active">
                                    <svg class="timeline__icon timeline__icon--default">
                                        <use href="#icon-customers"></use>
                                    </svg>
                                    <svg class="timeline__icon timeline__icon--active">
                                        <use href="#icon-customers"></use>
                                    </svg>
                                    <p class="timeline__step-title">
                                        Happy<br>Customers
                                    </p>
                                </div>
                                <div class="timeline__step-active-marker"
                                    style="--slide-width: 150px; --slide-pos-x: 750.0000114440918px; --slide-pos-y: 90px;">
                                </div>
                            </div>

                            <div class="timeline__slides" style="--slides-container-height: 257px;" >
                                <div class="timeline__slide">
                                    <h3 class="timeline__slide-title">Brainstorm</h3>
                                    <div class="timeline__slide-content">
                                        <p>Brainstorming is a great way to generate new ideas or to solve problems. When you
                                            engage in brainstorming, you allow yourself to think freely without any
                                            restrictions or limitations.</p>
                                    </div>
                                </div>

                                <div class="timeline__slide">
                                    <h3 class="timeline__slide-title">Discuss Ideas</h3>
                                    <div class="timeline__slide-content">
                                        <p>In order to fully explore and develop ideas, it is essential to engage in
                                            thoughtful
                                            and meaningful discussion. This can involve exchanging viewpoints and
                                            perspectives
                                            with others, asking probing questions, and considering alternative approaches.
                                        </p>
                                    </div>
                                </div>

                                <div class="timeline__slide">
                                    <h3 class="timeline__slide-title">Make Project</h3>
                                    <div class="timeline__slide-content">
                                        <p>Creating a project involves many steps, such as identifying the problem or
                                            opportunity, defining the scope and objectives, determining the resources
                                            needed, planning the timeline and milestones, executing the plan, and monitoring
                                            and controlling the project progress. </p>
                                    </div>
                                </div>

                                <div class="timeline__slide">
                                    <h3 class="timeline__slide-title">Select Strategy</h3>
                                    <div class="timeline__slide-content">
                                        <p>When it comes to selecting a strategy, there are several factors to consider. For
                                            example, you should think about the company's goals, available resources, and
                                            industry trends. Additionally, you may want to do some market research to
                                            identify
                                            potential opportunities and areas of growth.</p>
                                    </div>
                                </div>

                                <div class="timeline__slide">
                                    <h3 class="timeline__slide-title">SEO Target</h3>
                                    <div class="timeline__slide-content">
                                        <p>SEO target is an essential component of digital marketing. It is a process of
                                            optimizing the website and its content to improve its ranking in search engine
                                            results pages. </p>
                                    </div>
                                </div>

                                <div class="timeline__slide is-active">
                                    <h3 class="timeline__slide-title">Happy Customers</h3>
                                    <div class="timeline__slide-content">
                                        <p>Happy customers are the backbone of any successful business. They are the ones
                                            who
                                            keep coming back, bringing their friends and family along, and spreading the
                                            word
                                            about your brand.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </section>
    </main>
</template>

<script>
export default {
    name: 'developements',
    
created(){
    document.title = "Developments- Crekaa";
  },
  head() {
    return {
      title: "Developments- Crekaa",
      meta: [
        { hid: 'description', name: 'description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'author', name: 'author', content: "Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        
        // <!-- Facebook Open Graph tags -->
        { hid: 'og:url', property: 'og:url', content: "https://crekaa.com/" },
        { hid: 'og:type', property: 'og:type', content: "website" },
        { hid: 'og:title', property: 'og:title', content: "Crekaa, Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'og:description', property: 'og:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'og:image', property: 'og:image', content: "../../assets/logo.png" },
        // Add more meta tags as needed

        // <!-- Twitter Card tags -->
        { hid: 'twitter:card', name: 'twitter:card', content: "summary_large_image" },
        { hid: 'twitter:title', name: 'twitter:title', content: "Crekaa , Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'twitter:description', name: 'twitter:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'twitter:image', name: 'twitter:image', content: "../../assets/logo.png" },

      ]
    };
  },
    mounted() {
        // Your JavaScript code goes here
        this.DOM = {
            timeline: "timeline",
            timelineStepper: "timeline__stepper",
            timelineStep: "timeline__step",
            timelineStepTitle: "timeline__step-title",
            timelineStepActive: "is-active",
            timelineStepActiveMarker: "timeline__step-active-marker",
            timelineSlidesContainer: "timeline__slides",
            timelineSlide: "timeline__slide",
            timelineSlideActive: "is-active",
        };

        this.STEP_ACTIVE_MARKER_CUSTOM_PROPS = {
            width: "--slide-width",
            posX: "--slide-pos-x",
            posY: "--slide-pos-y",
        };

        this.SLIDES_CONTAINER_CUSTOM_PROPS = {
            height: "--slides-container-height",
        };

        this.timeline = document.querySelector(`.${this.DOM.timeline}`);
        this.timelineStepper = this.timeline?.querySelector(`.${this.DOM.timelineStepper}`);
        this.timelineStepTitle = this.timeline?.querySelector(`.${this.DOM.timelineStepTitle}`);
        this.timelineSlidesContainer = this.timeline?.querySelector(`.${this.DOM.timelineSlidesContainer}`);
        this.timelineSlides = this.timeline && Array.from(this.timeline.querySelectorAll(`.${this.DOM.timelineSlide}`));

        window.addEventListener("load", () => {
            this.createStepActiveMarker();
            this.activateCurrentSlide();
        });

        window.addEventListener("resize", () => {
            this.recalcStepActiveMarkerProps();
        });

        this.timeline?.addEventListener("click", (event) => {
            const { target } = event;
            if (!target || !(target instanceof Element) || !target.closest(`.${this.DOM.timelineStep}`)) {
                return;
            }
            const currentStep = target.closest(`.${this.DOM.timelineStep}`);
            if (!currentStep) {
                return;
            }
            this.deactivateSteps();
            this.activateCurrentStep(currentStep);
            this.recalcStepActiveMarkerProps();
            this.deactivateSlides();
            this.activateCurrentSlide();
        });
    },
    methods: {
        deactivateSteps() {
            const steps = document.querySelectorAll(`.${this.DOM.timelineStep}`);
            steps?.forEach((elem) => elem.classList.remove(this.DOM.timelineStepActive));
        },
        activateCurrentStep(currentStep) {
            currentStep?.classList.add(this.DOM.timelineStepActive);
        },
        deactivateSlides() {
            this.timelineSlides?.forEach((elem) => elem.classList.remove(this.DOM.timelineSlideActive));
        },
        activateCurrentSlide() {
            const currentSlide = this.getCurrentSlide();
            if (!currentSlide) {
                return;
            }
            const currentSlideHeight = this.getCurrentSlideHeight(currentSlide);
            this.setSlideContainerHeight(currentSlideHeight);
            currentSlide.classList.add(this.DOM.timelineSlideActive);
        },
        createStepActiveMarker() {
            const stepActiveMarker = document.createElement("div");
            stepActiveMarker.classList.add(this.DOM.timelineStepActiveMarker);
            this.timelineStepper?.appendChild(stepActiveMarker);
            const positionProps = this.getStepActiveMarkerProps();
            if (!positionProps) {
                return;
            }
            this.setStepActiveMarkerProps(Object.assign({ stepActiveMarker }, positionProps));
        },
        recalcStepActiveMarkerProps() {
            const stepActiveMarker = this.timeline?.querySelector(`.${this.DOM.timelineStepActiveMarker}`);
            const stepActiveMarkerProps = this.getStepActiveMarkerProps();
            if (!stepActiveMarkerProps) {
                return;
            }
            this.setStepActiveMarkerProps(Object.assign({ stepActiveMarker }, stepActiveMarkerProps));
        },
        setStepActiveMarkerProps({ stepActiveMarker, posX, posY, width }) {
            stepActiveMarker.style.setProperty(this.STEP_ACTIVE_MARKER_CUSTOM_PROPS.width, `${width}px`);
            stepActiveMarker.style.setProperty(this.STEP_ACTIVE_MARKER_CUSTOM_PROPS.posX, `${posX}px`);
            if (typeof posY === "number") {
                stepActiveMarker.style.setProperty(this.STEP_ACTIVE_MARKER_CUSTOM_PROPS.posY, `${posY}px`);
            }
        },
        getStepActiveMarkerProps() {
            const { currentStep } = this.getCurrentStep();
            if (!currentStep) {
                return null;
            }
            const width = this.getElementWidth(currentStep);
            const posX = this.getStepActiveMarkerPosX(currentStep);
            const posY = this.getStepActiveMarkerPosY();
            if (typeof posX !== "number" || typeof posY !== "number") {
                return null;
            }
            return { posX, posY, width };
        },
        getCurrentStep() {
            const timelineSteps = Array.from(document.querySelectorAll(`.${this.DOM.timelineStep}`));
            const currentStep = timelineSteps.find((element) => element.classList.contains(this.DOM.timelineStepActive));
            const currentStepIndex = currentStep && timelineSteps.findIndex((element) => element.classList.contains(this.DOM.timelineStepActive));
            return { currentStep, currentStepIndex };
        },
        getCurrentSlide() {
            const { currentStepIndex } = this.getCurrentStep();
            if (typeof currentStepIndex !== "number" || !this.timelineSlides) {
                return null;
            }
            return this.timelineSlides[currentStepIndex];
        },
        setSlideContainerHeight(height) {
            this.timelineSlidesContainer?.style.setProperty(this.SLIDES_CONTAINER_CUSTOM_PROPS.height, `${height}px`);
        },
        getCurrentSlideHeight(currentSlide) {
            return currentSlide.clientHeight;
        },
        getStepActiveMarkerPosY() {
            const timelineTitlePosY = this.timelineStepTitle?.getBoundingClientRect().top;
            const timelineStepperPosY = this.timelineStepper?.getBoundingClientRect().top;
            if (!timelineTitlePosY || !timelineStepperPosY) {
                return null;
            }
            return timelineTitlePosY - timelineStepperPosY;
        },
        getStepActiveMarkerPosX(currentStep) {
            const timelineStepperPosX = this.timelineStepper?.getBoundingClientRect().left;
            const currentStepPosX = currentStep.getBoundingClientRect().left;
            if (!timelineStepperPosX) {
                return null;
            }
            return currentStepPosX - timelineStepperPosX;
        },
        getElementWidth(elem) {
            return elem.clientWidth;
        },
    },
};
</script>
<style scoped>
.hero__background {
    background: #E2EFFD;
    /* width: 100vw; */
    height: 660px;
    margin: 50px 0;
    border-top: 1px solid #D0D4D9;
    background-image: url(https://cfl.dropboxstatic.com/static/images/business/homepage/background--db-vfl4SbjZf.jpg);
    background-repeat: no-repeat;
    background-position: center right;
}

.hero__text {
    margin: 120px 120px;
    max-width: 600px;
}

.hero__text h1 {
    font-weight: 400;
    font-size: 2.0rem;
}

.hero__text h2 {
    margin-top: 20px;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 28px;
}

.btn-primary {
    background-color: #007bff;
    /* Blue color for the background */
    color: #fff;
    /* White color for the text */
    padding: 5px 20px;
    /*Padding around the text */
    border: none;
    /* Remove button border */
    border-radius: 4px;
    /* Rounded corners */
    cursor: pointer;
    font-size: 0.8rem;
    /* Show pointer cursor on hover */
}

/* Hover effect for the primary button */
.btn-primary:hover {
    background-color: #0056b3;
    /* Darker blue color on hover */
}

.buy-now {
    cursor: pointer;
    text-decoration: none;
    border: 1px solid black;
}

.buy-now:hover {
    cursor: pointer;
    text-decoration: none;
    border: 1px solid rgb(86, 86, 86);
    background-color: #d0d4d95a;
}

/* Media query for tablets and smaller screens */
@media (max-width: 992px) {
    .hero__background {
        height: auto;
        background-position: center;
    }

    .hero__text {
        margin: 50px 20px;
        text-align: center;
        background-color: rgb(85, 85, 85, 0.2);
        backdrop-filter: blur(5px);
        padding: 10px;
        border-radius: 5px;
        color: white
    }

    .hero__text h1 {
        font-size: 1.5rem;
    }

    .hero__text h2 {
        font-size: 1rem;
    }
}

/* Media query for mobile phones */
@media (max-width: 576px) {
    .hero__background {
        height: auto;
        background-position: center;
    }

    .hero__text {
        margin: 30px 10px 20px;
        text-align: center;
        background-color: rgb(85, 85, 85, 0.2);
        backdrop-filter: blur(5px);
        padding: 10px;
        border-radius: 5px;
        color: white
    }

    .hero__text h1 {
        font-size: 1.2rem;
        color: white
    }

    .hero__text h2 {
        font-size: 0.9rem;
        color: white
    }

    .btn-primary {
        padding: 5px 10px;
        font-size: 0.7rem;
    }

    .buy-now {
        font-size: 0.7rem;
    }
}



body {
    color: var(--color-);
}

/* EXAMPLE STYLES: YOU DON'T NEED THEM!!! */
.header {
    max-width: 500px;
    margin: auto;
    text-align: center;
}

.header__btns {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 30px;
}

body a {
    color: inherit;
    text-decoration: none;
}

.header__btn {
    transition: all var(--transition-duration) var(--transition-timing-function) var(--transition-delay);
    padding: 10px 20px;
    margin-right: 10px;
    background-color: var(--bg-color-default);
    border: 1px solid var(--border-color-default);
    border-radius: 3px;
    cursor: pointer;
    outline: none;
}

.header__btn:hover,
.header__btn.js-active {
    color: var(--text-color-light);
    background-color: var(--bg-color-dark);
}

.header__title {
    margin-bottom: 10px;
    font-weight: var(--font-weight-semibold);
}

.content {
    margin: 50px auto;
}

.content__title {
    margin-bottom: 80px;
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-default);
    text-align: center;
}

/* TIMELINE STYLES: YOU NEED THEM!!! */
.timeline {
    --wrapper-width: 900px;

    width: 95%;
    max-width: var(--wrapper-width);
    margin: auto;
}

.timeline__stepper {
    --step-border-width: 3px;
    --offset-to-slider-content: 30px;

    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-bottom: var(--offset-to-slider-content);
}

@media (min-width: 530px) {
    .timeline__stepper {
        display: flex;
        justify-content: space-around;
        gap: 0;
    }
}

@media (min-width: 700px) {
    .timeline__stepper {
        --offset-to-slider-content: 50px;
    }
}

.timeline__step {
    position: relative;
    width: 100%;
    cursor: pointer;
}

@media (min-width: 530px) {
    .timeline__step {
        width: 100%;
    }
}

.timeline__icon {
    --timeline-icon-dimensions: 45px;

    position: relative;
    display: block;
    width: var(--timeline-icon-dimensions);
    max-height: var(--timeline-icon-dimensions);
    height: auto;
    margin: 0 auto;
    transition: opacity var(--transition-duration) var(--transition-timing-function) var(--transition-delay);
}

@media (min-width: 700px) {
    .timeline__icon {
        --timeline-icon-dimensions: 60px;

        margin-bottom: 30px;
    }
}

.timeline__icon--active {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    fill: url(#icon-gradient);
    opacity: 0;
}

.timeline__step-title {
    --timeline-dot-dimensions: 11px;
    --extra-offset-fox-pixelperfect: -1px;

    position: relative;
    display: none;
    padding-top: 30px;
    margin-bottom: 0;
    text-align: center;
    line-height: 1.7;
    border-top: var(--step-border-width) solid var(--border-color-default);
}

@media (min-width: 700px) {
    .timeline__step-title {
        display: block;
    }
}

.timeline__step-title:before,
.timeline__step-title:after {
    position: absolute;
    top: var(--dot-top-position);
    left: 50%;
    display: block;
    width: var(--timeline-dot-dimensions);
    height: var(--timeline-dot-dimensions);
    content: "";
    border-radius: 50%;
}

.timeline__step-title:before {
    --dot-top-position: calc(var(--timeline-dot-dimensions) / 2 * var(--negative-multiplier) + var(--extra-offset-fox-pixelperfect));

    transform: translate(-50%);
    border: 2px solid var(--border-color-default);
    border-radius: 50%;
    background-color: #fff;
    z-index: 5;
}

.timeline__step-title:after {
    --timeline-dot-dimensions: 25px;
    --dot-top-position: calc(var(--timeline-dot-dimensions) / 2 * var(--negative-multiplier) + var(--extra-offset-fox-pixelperfect));

    transform: translate(-50%) scale(0);
    border-radius: 50%;
    background-color: #fff;
}

/* this elements generates in JS */
.timeline__step-active-marker {
    --slide-pos-y: none;
    /* calcs on the fly in js */
    --slide-pos-x: none;
    /* calcs on the fly in js */
    --slide-width: auto;
    /* calcs on the fly in js */

    --transition-timing-function: cubic-bezier(0, 0, 0, 1.2);

    position: absolute;
    top: var(--slide-pos-y);
    left: 0;
    display: none;
    width: var(--slide-width);
    height: var(--step-border-width);
    transform: translateX(var(--slide-pos-x));
    transition: transform var(--transition-duration) var(--transition-timing-function) var(--transition-delay);
    background-image: var(--gradient-default);
}

@media (min-width: 700px) {
    .timeline__step-active-marker {
        display: block;
    }
}

.timeline__step.is-active {
    cursor: default;
}

.timeline__step.is-active .timeline__step-title {
    font-weight: var(--font-weight-semibold);
}

.timeline__step.is-active .timeline__step-title:before {
    transition: background-color var(--transition-duration) var(--transition-timing-function) var(--transition-delay);
    background-color: var(--color-accent-dark);
    border-color: var(--border-color-accent);
}

.timeline__step.is-active .timeline__step-title:after {
    transform: translate(-50%) scale(1);
    transition: transform var(--transition-duration) var(--transition-timing-function) var(--transition-delay);
    background-image: var(--gradient-default);
    opacity: 0.35;
}

.timeline__step.is-active .timeline__icon--default,
.timeline__step:not(.is-active):hover .timeline__icon--default {
    opacity: 0;
}

.timeline__step.is-active .timeline__icon--active,
.timeline__step:not(.is-active):hover .timeline__icon--active {
    opacity: 1;
}

.timeline__slides {
    --slides-container-height: 0px;
    /* calcs on the fly in js */

    --content-offset-x: 20px;
    --content-offset-y: 30px;

    --decoration-blur-spread: 20px;
    --decoration-opacity: 0.7;
    --decoration-pos-x: 0;
    --decoration-pos-y: 20px;
    --decoration-scale: 1;

    position: relative;
    width: 600px;
    max-width: 100%;
    margin: auto;
    /* margin-bottom:200px; */
    height: var(--slides-container-height);

    border-radius: 20px;
    /* box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px; */
    background-color: var(--card-color);
    /* background: var(--gradient-default); */
    /* z-index: -1;  */
    box-shadow: var(--color-accent-light) 10px -10px 24px,  var(--color-accent-dark) -10px 10px 24px;

}

@media (min-width: 700px) {
    .timeline__slides {
        --content-offset-x: 30px;
        --content-offset-y: 40px;
    }
}

.timeline__slides:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    opacity: var(--decoration-opacity);
    background-image: var(--gradient-default);
    filter: blur(var(--decoration-blur-spread));
    transform: translate3d(var(--decoration-pos-x), var(--decoration-pos-y), 0) scale(var(--decoration-scale));
    z-index: -1;
}

.timeline__slide {
    position: absolute;
    top: 0;
    left: 0;
    padding: var(--content-offset-y) var(--content-offset-x);
    opacity: 0;
    visibility: hidden;
}

.timeline__slide.is-active {
    transition: opacity var(--transition-duration) var(--transition-timing-function) var(--transition-delay);
    opacity: 1;
    visibility: visible;
}

.timeline__slide-title {
    margin-top: 0;
}

.timeline__slide-content {
    line-height: 1.7;
}

.timeline__slide-content p:first-child {
    margin-top: 0;
}

.timeline__slide-content p:last-child {
    margin-bottom: 0;
}



.flx-developement {
    display: flex;
    flex-direction: row;
    /* Default: Layout in row */
}

.justify-content-justify {
    justify-content: space-between;
}

.gap10 {
    gap: 30px;
}

.headline-intro-develop h1 {
    font-size: 1.5rem;
    text-align: center;
}

.left-section h2 {
    font-size: 1.2rem;
}

.left-section p {
    font-size: 0.8rem;
}

.left-section li {
    font-size: 0.8rem;
}

.section-intro-develop {
    margin: 20px;
}

/* Media query for tablets and smaller screens */
@media (max-width: 992px) {
    .flx-developement {
        flex-direction: column;
        /* Stack elements vertically on smaller screens */
    }

    .right-section,
    .left-section {
        max-width: 100%;
        margin: 0 auto;
        /* Center elements when stacked vertically */
    }
}

/* Media query for mobile phones */
@media (max-width: 576px) {
    .headline-intro-develop h1 {
        font-size: 1.2rem;
    }

    .left-section h2 {
        font-size: 1rem;
    }

    .left-section p {
        font-size: 0.7rem;
    }

    .left-section li {
        font-size: 0.7rem;
    }
}</style>