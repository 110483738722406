<template>
    <div class="background">
  <div class="container">
    <div class="panel pricing-table">
      
      <div class="pricing-plan">
         <lottie-player src="https://lottie.host/ae47da08-3f02-40e1-b259-5b817193b38b/C9PyLQxljn.json"
                  background="transparent" speed="0.5" style="width:100%;height:200px" loop="2" autoplay=""></lottie-player>
                  
        <!-- <img src="https://s22.postimg.cc/8mv5gn7w1/paper-plane.png" alt="" class="pricing-img"> -->
        <h2 class="pricing-header">3 Months</h2>
        <ul class="pricing-features">
          <li class="pricing-features-item">Server Maintainance </li>
          <li class="pricing-features-item">Server Backup</li>
          <li class="pricing-features-item">Server routine checkup</li>
          <li class="pricing-features-item">Security checkup</li>
          <li class="pricing-features-item">Version Upgrades</li>
        </ul>
        <span class="pricing-price"><del style="font-size:var(--fa-12)">₹7,500</del><br>₹7,200</span>
        
      </div>
      
      
      <div class="pricing-plan">
         <lottie-player src="https://lottie.host/82b85452-3a4f-415f-a88c-b4bef24de7b0/HuhDnBVAfb.json"
                  background="transparent" speed="0.5" style="width:100%;height:200px;
                  -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);" loop="2" autoplay=""></lottie-player>
        
                  
        <!-- <img src="https://s28.postimg.cc/ju5bnc3x9/plane.png" alt="" class="pricing-img"> -->
        <h2 class="pricing-header">6 Months</h2>
        <ul class="pricing-features" >
          
          <li class="pricing-features-item">Server Maintainance </li>
          <li class="pricing-features-item">Server Backup</li>
          <li class="pricing-features-item">Server routine checkup</li>
          <li class="pricing-features-item">Security checkup</li>
          <li class="pricing-features-item">Version Upgrades</li>
        </ul>
        <span class="pricing-price"><del style="font-size:var(--fa-12)">₹15,000</del><br>₹14,300</span>
        
      </div>
      
      <div class="pricing-plan">
         <lottie-player src="https://lottie.host/a506bd44-e950-449f-8db3-60917072e156/mn6i45IMFd.json"
                  background="transparent" speed="0.5" style="width:100%;height:200px" loop="2" autoplay=""></lottie-player>
        
                  
        <!-- <img src="https://s21.postimg.cc/tpm0cge4n/space-ship.png" alt="" class="pricing-img"> -->
        <h2 class="pricing-header">12 Months</h2>
        <ul class="pricing-features">
          
          <li class="pricing-features-item">Server Maintainance </li>
          <li class="pricing-features-item">Server Backup</li>
          <li class="pricing-features-item">Server routine checkup</li>
          <li class="pricing-features-item">Security checkup</li>
          <li class="pricing-features-item">Version Upgrades</li>
        </ul>

        <span class="pricing-price"><del style="font-size:var(--fa-12)">₹30,000</del><br>₹28,000</span>
        
      </div>
      
    </div>
  </div>
  <p class="note">Note: this will exclude the initial 3 months trial period of the software. available with standard and custom plan. As well the custom pricing for new moduele are different that does not included in this plans </p>

</div>

</template>
<script>
    export default{
        name:"one_time_payment_plans"
    } 
</script>
<style scoped>
.note{
  font-size: 10px;
  margin-top:50px;
  text-align: center;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.background {
  padding: 0 25px 25px;
  position: relative;
  width: 100%;
  /* background-color: #fff9f9; */
  /* background-color:hsla(0,100%,88%,1); */
  background-image:
  radial-gradient(at 80% 0%, hsla(189,100%,56%,1) 0px, transparent 50%),
  /* radial-gradient(at 0% 50%, hsla(355,100%,93%,1) 0px, transparent 50%), */
  radial-gradient(at 80% 50%, hsla(340,100%,76%,1) 0px, transparent 50%),
  radial-gradient(at 0% 100%, hsla(269,100%,77%,1) 0px, transparent 50%);
  /* radial-gradient(at 0% 0%, hsla(343,100%,76%,1) 0px, transparent 50%); */
}

/* .background::after {
  content: '';
  background: #60a9ff;
  background: -moz-linear-gradient(top, #60a9ff 0%, #4394f4 100%);
  background: -webkit-linear-gradient(top, #60a9ff 0%,#4394f4 100%);
  background: linear-gradient(to bottom, #60a9ff 0%,#4394f4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#60a9ff', endColorstr='#4394f4',GradientType=0 );
  height: 350px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
} */

@media (min-width: 900px) {
  .background {
    padding: 0 0 25px;
  }
}

.container {
  margin: 0 auto;
  padding: 50px 0 0;
  max-width: 960px;
  width: 100%;
}

.panel {
  background-color: var(--card-color);
  border-radius: 10px;
  padding: 15px 25px;
  position: relative;
  width: 100%;
  /* z-index: 10; */
}

.pricing-table {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08), 0px 20px 31px 3px rgba(0, 0, 0, 0.09), 0px 8px 20px 7px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
}

@media (min-width: 900px) {
  .pricing-table {
    flex-direction: row;
  }
}

.pricing-table * {
  text-align: center;
  text-transform: uppercase;
}

.pricing-plan {
  border-bottom: 1px solid #e1f1ff;
  padding: 25px;
}

.pricing-plan:last-child {
  border-bottom: none;
}

@media (min-width: 900px) {
  .pricing-plan {
    border-bottom: none;
    border-right: 1px solid #e1f1ff;
    flex-basis: 100%;
    padding: 25px 50px;
  }

  .pricing-plan:last-child {
    border-right: none;
  }
}

.pricing-img {
  margin-bottom: 25px;
  max-width: 100%;
}

.pricing-header {
  /* color: #888; */
  font-weight: 600;
  letter-spacing: 1px;
}

.pricing-features {
  /* color: #016FF9; */
  font-weight: 600;
  letter-spacing: 1px;
  margin: 50px 0 15px;

}

.pricing-features-item {
  border-top: 1px solid #e1f1ff;
  font-size: 12px;
  text-align:start;
  line-height: 1.5;
  padding: 10px 0;
}

.pricing-features-item:last-child {
  border-bottom: 1px solid #e1f1ff;
}

.pricing-price {
  /* color: #016FF9; */
  display: block;
  font-size: 32px;
  font-weight: 700;
}

.pricing-button {
  border: 1px solid #9dd1ff;
  border-radius: 10px;
  /* color: #348EFE; */
  display: inline-block;
  margin: 25px 0;
  padding: 15px 35px;
  text-decoration: none;
  transition: all 150ms ease-in-out;
}

.pricing-button:hover,
.pricing-button:focus {
  background-color: #e1f1ff;
}

.pricing-button.is-featured {
  background-color: #48aaff;
  color: #fff;
}

.pricing-button.is-featured:hover,
.pricing-button.is-featured:active {
  background-color: #269aff;
}
</style>