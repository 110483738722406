<template>
    <a-button type="primary" @click="showModal" style="border-radius:100%"><template #icon>
        <ion-icon name="pencil-outline"></ion-icon>
    </template></a-button>
        <a-modal v-model:open="open" title="Update Job Title" :confirm-loading="confirmLoading" @ok="handleOk">
            <label>Position Title (English) </label>
            <a-input v-model:value="job_title"></a-input>
        </a-modal>
    </template>
    <script>
import { notification } from 'ant-design-vue';
import axios from 'axios' 
    export default{
        name:"updateTitleJobCareers",
        data(){
            return {
                open: false,
                confirmLoading: false,
                job_title:'',
                job_num:''
            }
        },
        mounted(){
            this.job_title=this.job_title_obj
            this.job_num=this.job_number
        }, 
        props:{
            job_number:Object,
            job_title_obj:Object,
        },
        methods:{
            
                showModal() {
                this.open = true;
                },

                async handleOk () {
                this.confirmLoading = true;
                const formData={
                    job_title:this.job_title,
                }
                await axios
                    .post("update-job-details-career-crekaa-admin/"+"update_title/"+this.job_number,formData)
                    .then(response => {
                        // console.log(response);
                        // console.log("------------------------------------Got Response------------------------------------");
                        // console.log(response.data);

                        notification['success']({
                                message: 'Careers Job title has been updated ',
                                description:'Careers job title has been updated successfully, You just need to refresh update page.',
                                placement: 'bottomRight',
                            });
                    })
                    .catch(error => {
                        console.log(error)
                            notification['error']({
                                message: 'Unable to update title',
                                // description:'Unable To Fetch User Details...',
                                placement: 'bottomRight',
                            });

                            console.log("error Found unable to update")                        
                            console.log(JSON.stringify(error.response.data))
                                console.log(JSON.stringify(error))
                    })

                    this.confirmLoading = false;
                        this.open = false;
                        window.location.reload()
                // setTimeout(() => {
                // }, 2000);
                },
    
        }
    }
    </script>
    <style scoped>
    
    </style>
    
    
    