<template>
    <div class="apply-job">
        <br>
        <br>
        <div style="max-width:1300px;margin: auto;min-height: 100vh;">
            <a-row>
                <a-col :sm="24" :xs="24" :md="24" :lg="24">
                    <a-row>
                        <a-col :sm="24" :xs="24" :md="4" :lg="4" style="padding-left:20px"  class="sticky-header is-sticky">

                            <a-steps :current="current" :items="items" direction="vertical" size="small"  class="sticky-header is-sticky"></a-steps>
                        </a-col>
                        <a-col :sm="24" :xs="24" :md="20" :lg="20" style="padding:20px">
                            <div class="steps-content">
                                <!-- Use v-if or v-show to conditionally render content for each step -->
                                <div v-if="current === 0">
                                    <!-- Content for the first step -->
                                    <a-form>
                                        <!-- Your form fields and input components for the "My information" step -->
                                        <h1>My information</h1>
                                        <div class="information_applicant" style="padding:20px;text-align: start;">

                                            <a-row>

                                                <a-col :sm="24" :xs="24" :md="24" :lg="24">
                                                    <!-- <label for="resume" >Resume</label>
              
      <a-input type="file" name="resume" id="resume" accept=".pdf " @change="readURL($event, 'resume');" /> -->


                                                    <div>
                                                        <label for="resume" class="custom-file-upload">
                                                            <span>Select Resume <span class="important-tag">*</span></span>
                                                            <a-input type="file" name="resume" id="resume" accept=".pdf"
                                                                @change="readPDF($event, 'resume')" />
                                                        </label>
                                                        <div v-if="resume" style="margin-top:20px">
                                                            <iframe :src="resume" width="100%" height="500px"
                                                                frameborder="0"></iframe>
                                                        </div>
                                                    </div>
                                                    <br />
                                                </a-col>
                                                <a-col :sm="24" :xs="24" :md="11" :lg="11">
                                                    <label>First Name<span class="important-tag">*</span></label>
                                                    <a-input v-model:value="applicant_first_name"> </a-input>
                                                </a-col>
                                                <a-col :sm="0" :xs="0" :md="2" :lg="2">

                                                </a-col>
                                                <a-col :sm="24" :xs="24" :md="11" :lg="11">
                                                    <label>Last Name<span class="important-tag">*</span></label>
                                                    <a-input v-model:value="applicant_last_name"> </a-input>
                                                </a-col>
                                                <a-col :sm="24" :xs="24" :md="24" :lg="24">
                                                    <label>Country<span class="important-tag">*</span></label>
                                                    <a-input v-model:value="applicant_country"> </a-input>
                                                </a-col>
                                                <a-col :sm="24" :xs="24" :md="11" :lg="11">
                                                    <label>Country Code<span class="important-tag">*</span> </label>
                                                    <a-input v-model:value="applicant_country_code"> </a-input>
                                                </a-col>
                                                <a-col :sm="0" :xs="0" :md="2" :lg="2">

                                                </a-col>
                                                <a-col :sm="24" :xs="24" :md="11" :lg="11">
                                                    <label>Phone Number<span class="important-tag">*</span></label>
                                                    <a-input v-model:value="applicant_phone_number"> </a-input>
                                                </a-col>
                                                <a-col :sm="24" :xs="24" :md="12" :lg="24">
                                                    <label>Social Profile 1 ( linkedin )<span class="important-tag">*</span></label>
                                                    <a-input v-model:value="social_profile_1_linkedin"> </a-input>
                                                </a-col>
                                                <a-col :sm="24" :xs="24" :md="12" :lg="24">
                                                    <label>Social Profile 2</label>
                                                    <a-input v-model:value="social_profile_2"> </a-input>
                                                </a-col>
                                                <a-col :sm="24" :xs="24" :md="24" :lg="24">
                                                    <label>Social Profile 3</label>
                                                    <a-input v-model:value="social_profile_3"> </a-input>
                                                </a-col>


                                            </a-row>
                                            <br>
                                            <a-row>
                                                <a-col :sm="24" :xs="24" :md="12" :lg="12">
                                                    <h1 style="text-align:start">My Qualifications</h1>
                                                </a-col>

                                                <a-col :sm="24" :xs="24" :md="12" :lg="12"
                                                    style=" display: flex;flex-wrap:wrap; justify-content:end;margin-top: 10px;">
                                                    <a-button type="primary" @click="incrementQualificationsCardCount"
                                                        style="margin-bottom:20px"><ion-icon
                                                            name="add-outline"></ion-icon>Add Qualification</a-button>
                                                    &nbsp;
                                                    <a-button type="primary" danger
                                                        @click="decrementQualificationsCardCount"><ion-icon
                                                            name="trash-outline"></ion-icon>Remove Qualification</a-button>
                                                </a-col>
                                            </a-row>
                                            <br>

                                            <div v-for="(qual, index) in myQualifications" :key="index">
                                                <a-card>
                                                    <h3>Qualification : {{ index + 1 }}</h3>

                                                    <a-row>
                                                        <a-col :sm="24" :xs="24" :md="24" :lg="24">
                                                            <a-label>Qualification Title<span class="important-tag">*</span></a-label>
                                                            <a-input v-model:value="qual.qualification_title"
                                                                placeholder="B.tech, M.tech, Phd..."> </a-input>
                                                        </a-col>
                                                        <a-col :sm="24" :xs="24" :md="24" :lg="24">
                                                            <a-label>Qualification Description<span class="important-tag">*</span></a-label>

                                                            <a-textarea v-model:value="qual.qualification_description"
                                                                :rows="10"
                                                                placeholder="Describe your qualification in terms of whatyou have learned from that & your branch... ">
                                                            </a-textarea>
                                                        </a-col>
                                                        <a-col :sm="24" :xs="24" :md="24" :lg="24">
                                                            <br>
                                                            <a-row>

                                                                <a-col :sm="24" :xs="24" :md="8" :lg="8">
                                                                    <a-label> From Year<span class="important-tag">*</span></a-label>&nbsp;
                                                                    <a-date-picker style="width:99%"
                                                                        v-model:value="qual.from_year" picker="year" />
                                                                </a-col>
                                                                <a-col :sm="24" :xs="24" :md="8" :lg="8">
                                                                    <a-label> To Year<span class="important-tag">*</span></a-label>&nbsp;
                                                                    <a-date-picker style="width:99%"
                                                                        v-model:value="qual.to_year" picker="year" />

                                                                </a-col>
                                                                <a-col :sm="24" :xs="24" :md="8" :lg="8"
                                                                    style="margin-top:10px;text-align: center;">


                                                                    <a-checkbox
                                                                        v-model:checked="qual.currently_persueing">Currently
                                                                        Pursueing </a-checkbox>
                                                                </a-col>
                                                            </a-row>
                                                        </a-col>



                                                    </a-row>


                                                </a-card>
                                                <br>

                                            </div>


                                        </div>

                                        <!-- </a-space> -->
                                        <!-- <a-upload-dragger v-model:fileList="fileList" name="file" :multiple="true" 
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76" @change="handleChange"
                            @drop="handleDrop" style="margin:20px auto;width:90%">
                            <p class="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p class="ant-upload-text">Click or drag file to this area to upload</p>
                            <p class="ant-upload-hint">
                                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                                band files
                            </p>
                        </a-upload-dragger> -->


                                    </a-form>
                                </div>
                                <div v-else-if="current === 1">
                                    <!-- Content for the second step -->
                                    <form>
                                        <!-- Your form fields and input components for the "My experience" step -->
                                        <h1>My experience</h1>
                                        <a-row>
                                            <a-col :sm="24" :xs="24" :md="24" :lg="24"
                                                style="padding:10px;text-align: start;"
                                                v-for="(card, index) in myExperiences" :key="index">

                                                <a-card>

                                                    <label> <b> Experience {{ index + 1
                                                    }}:</b></label><br><br><label>Experience Title<span class="important-tag">*</span></label>
                                                    <a-input v-model:value="card.experience_title"
                                                        placeholder="Experience Postion Here " /><br>
                                                    <br>
                                                    <label>Experience Description<span class="important-tag">*</span></label>
                                                    <a-textarea :maxlength="500" showCount :rows="4"
                                                        v-model:value="card.experience_description"
                                                        placeholder="Describe Here Your Experience , what technolofgies you have used Durin this experience and all details..." /><br>

                                                    <label>Select Your Experience Span<span class="important-tag">*</span> </label>
                                                    <a-range-picker v-model:value="card.experience_span_date" />

                                                    <br>
                                                    <br>
                                                    <a-checkbox v-model:checked="card.im_currently_workingHere">Current
                                                        Employer ? </a-checkbox><br>

                                                    <a-space style="float: right;">

                                                        <a-button type="primary"
                                                            @click="incrementExperiencesCardCount"><ion-icon
                                                                name="add-outline"></ion-icon></a-button>
                                                        <a-button type="primary"
                                                            @click="decrementExperiencesCardCount"><ion-icon
                                                                name="trash-outline"></ion-icon></a-button>
                                                    </a-space>
                                                </a-card>

                                            </a-col>

                                        </a-row>
                                    </form>
                                </div>
                                <div v-else-if="current === 2">
                                    <!-- Content for the third step -->
                                    <form>
                                        <!-- Your form fields and input components for the "Application questions" step -->
                                        <h1>Application questions</h1>
                                        <!-- {{questions_for_jobseeker}} -->
                                        <div v-for="que in question_answer" style="text-align :start;padding:10px"
                                            :key="que.id">


                                            <a-label>
                                                <span class="important-tag" v-if="que.is_required">*</span> <b>{{ que.title }}</b>
                                            </a-label>

                                            <br>
                                            <br>
                                            <!-- application_answered -->
                                            <a-textarea :rows="7" :maxlength="500" :showCount="true"
                                                v-model:value="que.user_answer"
                                                placeholder="Please Answer Here..."></a-textarea>

                                            <!-- <a-input></a-input> -->
                                            <br>

                                        </div>
                                    </form>
                                </div>
                                <div v-else-if="current === 3">
                                    <!-- Content for the fifth step -->
                                    <form style="padding:10px">
                                        <!-- Your form fields and input components for the "Review" step -->
                                        <h1>Acknoledgement</h1>
                                        <div style="text-align:start;">
                                            <div>
                                                <a-checkbox v-model:checked="acknowledgment.checkedTerms"
                                                    @click="checkedTerms">
                                                    I acknowledge that I have read and agree to the terms and conditions of
                                                    Crekaa.<span class="important-tag">*</span>
                                                </a-checkbox>
                                            </div>
                                            <br>
                                            <br>
                                            <div>
                                                <a-checkbox v-model:checked="acknowledgment.checkedPrivacy"
                                                    @click="checkedPrivacy">
                                                    I acknowledge that I have read and agree to the privacy policy of
                                                    Crekaa.<span class="important-tag">*</span>
                                                </a-checkbox>
                                            </div>
                                            <br>
                                            <br>

                                            <div>
                                                <a-checkbox v-model:checked="acknowledgment.checkedAge" @click="checkedAge">
                                                    I acknowledge that I am at least 18 years old.<span class="important-tag">*</span>
                                                </a-checkbox>
                                            </div>
                                            <br>
                                            <br>

                                            <div>
                                                <a-checkbox v-model:checked="acknowledgment.checkedInfo"
                                                    @click="checkedInfo">
                                                    I acknowledge that the information provided in my application is
                                                    accurate to the best of my knowledge.<span class="important-tag">*</span>
                                                </a-checkbox>
                                            </div>
                                            <br>
                                            <br>


                                            <div>
                                                <a-label>Type Your Full Name here As Digital Signiture<span class="important-tag">*</span></a-label>
                                                <br>
                                                <br>
                                                <a-input status="warning" v-model:value="acknowledgment.digital_signiture"
                                                    placeholder="John Doe"></a-input>
                                            </div>
                                        </div>
                                        <br>
                                        <br>
                                        <span style="text-align:start">

                                            <p ><span class="important-tag"> Note:</span> Please acknoledge all terms & type your full name to submit your job
                                                application.</p>
                                                
                                                <p ><span class="important-tag"> Note:</span> ( <span class="important-tag"> *</span> ) Will represent the mendatory field.</p>
                                                <!-- Add more acknowledgment questions as needed -->
                                            </span>

                                    </form>
                                </div>
                            </div>

                        </a-col>
                    </a-row>
                    <a-row style="display: flex;justify-content: end;">
                        <div class="steps-action" style="padding-right:20px">
                            <a-button v-if="current < steps.length - 1" type="primary" @click="next">Next</a-button>
                            <a-button v-if="current == steps.length - 1" type="primary" :disabled="disabledDone()"
                                @click="submitApplication()">
                                Done
                            </a-button>
                            <a-button v-if="current > 0" style="margin-left: 8px" @click="prev">Previous</a-button>
                        </div>
                    </a-row><br><br>
                </a-col>

                <!-- <a-col  :sm="24" :xs="24" :md="4" :lg="4" style="background-color: rgba(127, 255, 212, 0.1234);padding-top:50px">

                    
                    <a-button> Apply Manually</a-button><br>
                    <br>
                    <a-popconfirm
                    placement="bottom"
                    ok-text="Yes"
                    cancel-text="No"
                    @confirm="confirm"
                    @cancel="cancel"
                    >
                    <template #title>
                            <p>One Click Apply will use your <br> profile details automatically <br> that you have fieled at <br> your profile section ! </p>

                            </template>
                    <a-button> One Click Apply</a-button>
                    </a-popconfirm>
                </a-col> -->
            </a-row>




        </div>
    </div>
</template>
  

  
<script>

import { message } from 'ant-design-vue';
import { ConsoleSqlOutlined, InboxOutlined } from '@ant-design/icons-vue'
import { CloudUploadOutlined } from '@ant-design/icons-vue'
import axios from 'axios';
import { notification } from 'ant-design-vue';
export default {
    name: "job_apply_crekaa_career",
    components: [
        InboxOutlined
    ],
    components: {
        CloudUploadOutlined
    },
    data() {
        return {
            current: 0,
            job_number: '',
            steps: [
                {
                    title: 'My information',
                    content: 'My information',
                },
                {
                    title: 'My experience',
                    content: 'Second-content',
                },
                {
                    title: 'Application questions',
                    content: 'Application questions-content',
                },
                {
                    title: 'Acknoledgement',
                    content: 'Acknoledgement-content',
                },
            ],
            items: [], // Initialize items as an empty array
            fileList: [],

            resume: null,
            applicant_first_name: '',
            applicant_last_name: '',
            applicant_country: '',
            applicant_country_code: '',
            applicant_phone_number: '',
            social_profile_1_linkedin: '',
            social_profile_2: '',
            social_profile_3: '',
            acknowledgment: {
                checkedTerms: false,
                checkedPrivacy: false,
                checkedAge: false,
                checkedInfo: false,
                digital_signiture: ''
            },
            cardCount: 1,
            qualificationsCardCount: 1,

            myExperiences: [
                {
                    experience_title: '',
                    experience_description: '',
                    experience_span_date: '',
                    im_currently_workingHere: false
                },
            ],
            myQualifications: [
                {
                    qualification_title: '',
                    qualification_description: '',
                    from_year: '',
                    to_year: '',
                    currently_persueing: false
                },
            ],


            questions_for_jobseeker: [],
            question_answer: []
        };
    },
    mounted() {
        const job_number = this.$route.params.crekaa_job_id
        this.job_number = job_number
        this.getJobQuestions(this.job_number)
    },
    created() {
        // Initialize items here in the created lifecycle hook
        this.items = this.steps.map(item => ({
            key: item.title,
            title: item.title,
        }));
    },
    methods: {
        incrementExperiencesCardCount() {
            this.cardCount += 1;

            this.myExperiences.push({
                experience_title: '',
                experience_description: '',
                experience_span_date: '',
                im_currently_workingHere: false
            });
        },
        decrementExperiencesCardCount() {
            if (this.cardCount > 1) {
                this.cardCount -= 1;
                this.myExperiences.pop();
            }
        },


        incrementQualificationsCardCount() {
            this.qualificationsCardCount += 1;

            this.myQualifications.push({
                qualification_title: '',
                qualification_description: '',
                from_year: '',
                to_year: '',
                currently_persueing: false
            });
        },
        decrementQualificationsCardCount() {
            if (this.qualificationsCardCount > 1) {
                this.qualificationsCardCount -= 1;
                this.myQualifications.pop();
            }
        },

        checkedTerms() {
            this.acknowledgment.checkedTerms = !this.acknowledgment.checkedTerms;
        },
        checkedPrivacy() {
            this.acknowledgment.checkedPrivacy = !this.acknowledgment.checkedPrivacy;
        },
        checkedAge() {
            this.acknowledgment.checkedAge = !this.acknowledgment.checkedAge;
        },
        checkedInfo() {
            this.acknowledgment.checkedInfo = !this.acknowledgment.checkedInfo;
        },

        disabledDone() {
            if (this.acknowledgment.checkedTerms &&
                this.acknowledgment.checkedPrivacy &&
                this.acknowledgment.checkedAge &&
                this.acknowledgment.checkedInfo &&
                this.acknowledgment.digital_signiture) {

                return false;
            }
            else {
                return true
            }
        },
        async getJobQuestions(job_number) {
            this.$store.commit('setIsLoading', true)
            // console.log(job_number)
            await axios
                .get('get-crekaa-career-job-questions/' + job_number)
                .then(response => {
                    // const token = response.data['token']
                    // console.log("questions --------------- ")
                    // console.log(token)
                    this.questions_for_jobseeker = response.data,
                        // console.log("==================================")
                        // console.log(this.questions_for_jobseeker)
                        // Initialize question_answer array
                        this.question_answer = this.questions_for_jobseeker['questions'].map(que => ({
                            id: que.id,
                            is_required: que.question_required,
                            title: que.question,
                            user_answer: '',
                        }));

                    console.log(this.question_answer)
                })
                .catch(error => {
                    // console.log("==================================")
                    console.log(error)

                })
            // this.$store.commit('setIsLoading',false)
            this.$store.commit('setIsLoading', false)
        },

        next() {
            this.current++;
        },
        prev() {
            this.current--;

        },

        readPDF(event, previewId) {
            const input = event.target;
            if (input.files && input.files[0]) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.resume = e.target.result;
                    // Uncomment the line below if you want to display the PDF using pdfjs-dist
                    // this.displayPDF(this.resume);
                };
                reader.readAsDataURL(input.files[0]);
            }
        },
        displayPDF(pdfData) {
            // Using pdfjs-dist to display PDF
            pdfjsLib.getDocument({ data: atob(pdfData.split(',')[1]) }).promise
                .then((pdf) => {
                    // Assuming there is only one page in the PDF
                    return pdf.getPage(1);
                })
                .then((page) => {
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    const viewport = page.getViewport({ scale: 1.5 });

                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport,
                    };

                    page.render(renderContext).promise.then(() => {
                        // Display the canvas or append it to the DOM as needed
                        // For now, we're just logging the canvas to the console
                        console.log(canvas);
                    });
                })
                .catch((error) => {
                    console.error('Error displaying PDF:', error);
                });
        },


        async submitApplication() {

            this.$store.commit('setIsLoading', true)

            if (!this.resume) {
                this.$store.commit('setIsLoading', false)
                return notification['error']({
                    message: 'Resume Are Empty !',
                    description:
                        `Please attach the PDF resume to apply at this job !`,
                    placement: 'bottomRight',
                });
            }
            if (!this.applicant_first_name) {
                this.$store.commit('setIsLoading', false)
                return notification['error']({
                    message: 'First Name is empty!',
                    description:
                        `Please Enter Your first name to apply this position !`,
                    placement: 'bottomRight',
                });
            }
            if (!this.applicant_last_name) {
                this.$store.commit('setIsLoading', false)
                return notification['error']({
                    message: 'Last Name is empty!',
                    description:
                        `Please Enter Your last name to apply this position !`,
                    placement: 'bottomRight',
                });
            }
            if (!this.applicant_country) {
                this.$store.commit('setIsLoading', false)
                return notification['error']({
                    message: 'Applicant Country is empty',
                    description:
                        `Please enter your country name to apply this position`,
                    placement: 'bottomRight',
                });
            }
            if (!this.applicant_country_code) {
                this.$store.commit('setIsLoading', false)
                return notification['error']({
                    message: 'Country code is empty!',
                    description:
                        `Please enter your country code to apply at  this position`,
                    placement: 'bottomRight',
                });
            }
            if (!this.applicant_phone_number) {
                this.$store.commit('setIsLoading', false)
                return notification['error']({
                    message: 'Phone Number is Empty!',
                    description:
                        `Please enter your phone number to apply this position!`,
                    placement: 'bottomRight',
                });
            }
            if (!this.social_profile_1_linkedin) {
                this.$store.commit('setIsLoading', false)
                return notification['error']({
                    message: 'Social profile is empty!',
                    description:
                        `Please enter your social professional profile (likedin is prefered)`,
                    placement: 'bottomRight',
                });
            }

            for (let qual in this.myQualifications) {
                // console.log(exp)
                if (
                    this.myQualifications[qual].qualification_title == '' ||
                    this.myQualifications[qual].qualification_description == '' ||
                    this.myQualifications[qual].from_year == '' ||
                    this.myQualifications[qual].to_year == ''
                ) {
                    this.$store.commit('setIsLoading', false);
                    return notification['error']({
                        message: 'Qualifications is not filled !',
                        description: `Please enter the your qualifications for where it important to fill the all fields!`,
                        placement: 'bottomRight',
                    });
                }
                // console.log("Experiences Validated ");
            }

            // console.log("++++++++++++++++++++++++++++++++++++")
            for (let question in this.question_answer) {
                // console.log(question)
                if (this.question_answer[question].is_required == true && this.question_answer[question].user_answer === '') {
                    this.$store.commit('setIsLoading', false);
                    return notification['error']({
                        message: 'Question Is required that you have not answered!',
                        description: `Please enter the appropriate answer for asked question!`,
                        placement: 'bottomRight',
                    });
                }
                // console.log("questions Validated ");

            }
            // console.log("++++++++++++++++++++++++++++++++++++")

            for (let exp in this.myExperiences) {
                // console.log(exp)
                if (
                    this.myExperiences[exp].experience_title === '' || this.myExperiences[exp].experience_description === '' || this.myExperiences[exp].experience_span_date === ''
                ) {
                    this.$store.commit('setIsLoading', false);
                    return notification['error']({
                        message: 'Experience is not filled !',
                        description: `Please enter the appropriate experience for where it important to fill the all fields! , if you dont have any experience please fill your student experience`,
                        placement: 'bottomRight',
                    });
                }
                // console.log("Experiences Validated ");
            }






            // console.log(this.myExperiences);
            const formData = {
                job_number: this.job_number,
                resume: this.resume,
                applicant_first_name: this.applicant_first_name,
                applicant_last_name: this.applicant_last_name,
                applicant_country: this.applicant_country,
                applicant_country_code: this.applicant_country_code,
                applicant_phone_number: this.applicant_phone_number,
                social_profile_1_linkedin: this.social_profile_1_linkedin,
                social_profile_2: this.social_profile_2,
                social_profile_3: this.social_profile_3,


                acknoledgement__checkedTerms: this.acknowledgment.checkedTerms,
                acknoledgement__checkedPrivacy: this.acknowledgment.checkedPrivacy,
                acknoledgement__checkedAge: this.acknowledgment.checkedAge,
                acknoledgement__checkedInfo: this.acknowledgment.checkedInfo,
                acknoledgement__digital_signiture: this.acknowledgment.digital_signiture,

                myExperiences: this.myExperiences,
                question_answer: this.question_answer,
                myQualifications: this.myQualifications
            }
            // console.log(this.myQualifications)
            await axios
                .post("post-resume-apply-crekaa-career-job/", formData, {
                    headers: {
                        'Content-Type': 'application/json',

                    },
                })
                .then((response) => {

                    // console.log("------------response-------------")
                    // console.log(response.data)
                    if (!response.data['error']) {
                        notification['success']({
                            message: 'Application Recived!',
                            description:
                                `Thank You we have recived your application & maild you details please find your application at your dashboard!`,
                            placement: 'bottomRight',
                        });

                        const toPath = this.$route.query.to || '/my-dashboard' // dashboard path
                        this.$router.push(toPath)
                    } else {
                        notification['error']({
                            message: 'Something Wents Wrong!',
                            description:
                                `Please check Your all details that you have field while feling the job application Every field is mendatory for us!`,
                            placement: 'bottomRight',
                        });
                    }

                }).catch((error) => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)

        },
        handleChange(info) {
            const status = info.file.status;
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        handleDrop(e) {
            // console.log(e);
        },



        // confirm onclick apply 
        confirm() {
            message.success('Click on Yes');
        },
        cancel() {
            //   console.log();
            message.error('Click on No');
        }
    },
};
</script>



<style scoped>.steps-content {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: var(--card-color);
    min-height: 200px;
    text-align: center;
    padding-top: 80px;
}
.important-tag{
    color:red
} 
.steps-action {
    margin-top: 24px;
}

[data-theme='dark'] .steps-content {
    background-color: #2f2f2f;
    border: 1px dashed #404040;
}</style>