<template>
    <a-input block></a-input>
    <a-button> </a-button>
</template>
<script>
export default {
    name:"input_and_send_message"
}
</script>
<style scoped>
</style>