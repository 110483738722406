<template>
    <a-button type="primary" @click="showModal" style="border-radius:100%"><template #icon>
        <ion-icon name="add-circle-outline" style="font-size: 22px;"></ion-icon>
    </template></a-button>
        <a-modal v-model:open="open" title="Create Job Team" :confirm-loading="confirmLoading" @ok="handleOk">
           
            <a-select  v-model:value="team_name" style="width:100%" >
    <a-select-option :value="team" v-for="team in job_selection_array">{{team}}</a-select-option>
</a-select>

        </a-modal>
    </template>
    <script>
    
    
    
import { notification } from 'ant-design-vue'
import axios from 'axios'

    export default{
        name:"createTeamJobCareers",
        data(){
            return {
                modalText: 'Content of the modal',
                open: false,
                confirmLoading: false,
                job_selection_array:[
                "Advanced Analytics Group (AAG)",
                "Crekaa Marketing",
                "Executive/Manager Assistant",
                "Expert Consulting",
                "Finance",
                "General Consulting",
                "Global & Local Operations",
                "Innovation & Design",
                "Legal/Risk",
                "Marketing Consulting by FRWD",
                "New Ventures",
                "NextGen Software Solutions",
                "Product, Practice, and Knowledge (PPK)",
                "Research & Data Services (RDS)",
                "Talent Acquisition",
                "Talent Management",
                "Technology Solutions Group (TSG)"
                ],
                team_name:'',
                job_num:'',
            }
        }, 
          mounted(){
            this.job_num=this.job_number
        }, 
         props:{
            job_number:Object,
           
        },
        methods:{
            
                showModal() {
                this.open = true;
                },
               async handleOk () {
                this.confirmLoading = true;
                const formData={
                    team_name:this.team_name
                }
                await axios
        .post("update-job-details-career-crekaa-admin/"+"create_team/"+this.job_number,formData)
        .then(response => {
            notification['success']({
                    message: 'Careers Job Team has been successfully created ',
                    description:'Careers job employement type has been updated successfully, You just need to refresh update page.',
                    placement: 'bottomRight',
                });
        })
        .catch(error => {
            console.log(error)
                notification['error']({
                    message: 'Unable to update Job Team ',
                    // description:'Unable To Fetch User Details...',
                    placement: 'bottomRight',
                });

                console.log("error Found unable to update")                        
                console.log(JSON.stringify(error.response.data))
                    console.log(JSON.stringify(error))
        })

        this.confirmLoading = false;
            this.open = false;
            window.location.reload()
            // this.confirmLoading = true;
            // setTimeout(() => {
            //     this.open = false;
            //     this.confirmLoading = false;
            // }, 2000);
            },

    
    
        }
    }
    </script>
    <style scoped>
    
    </style>
    
    
    