<template>
    <a-card style="width: 100%; text-align: start; margin-bottom: 15px;"  size="large" >
        <template #title style="font-size: 18px; font-weight: 700">
            {{ jd.job_title }}
        </template>
        <a-row style="font-size: 18px; font-weight: 700">
            <a-col :sm="12" :xs="12" :md="12" :lg="6">
                <AimOutlined />&nbsp;<a-tag v-for="loc in jd.locations">{{ loc.country_name }}</a-tag>
            </a-col>
            <a-col :sm="12" :xs="12" :md="12" :lg="6" class="te">
                <RiseOutlined /> &nbsp;{{ jd.job_salary_currency }} {{ jd.salary_expected }}
            </a-col>
            <a-col :sm="12" :xs="12" :md="12" :lg="6" class="mt-20">
                <FileTextOutlined />&nbsp;{{ jd.employement_type }}
            </a-col>
            <a-col :sm="12" :xs="12" :md="12" :lg="6" class="mt-20-mob">
                <router-link :to="'/job-position-crekaa-careers/'+jd.job_number" v-if="jd.is_active">
                    <a-button block type="primary" size="large" style="border-radius: 5px; background-color:blueviolet;" >Apply Now</a-button>
                </router-link>
                <div v-else>
                  <a-button block type="primary" size="large" style="border-radius: 5px; background-color:blueviolet;" disabled>Apply Now</a-button>
                </div>
            </a-col>
        </a-row>
        <br>
        
    
        <div v-html="jd.description_job" style="text-align:auto;height:200px;overflow: hidden;background: var(--background-color);padding:10px;border-radius:10px"></div>
        <!-- {{ quillDelta }} -->
        <!-- <QuillEditor v-if="jd.description_job" /> -->
    
        <!-- <div v-html="convertedHtmladdingDelta(jd.description_job)" style="text-align: auto" v-if="jd.description_job"></div> -->
    </a-card>
</template>
  
<script>
import { RightOutlined, AimOutlined, CodeOutlined, FileTextOutlined, FileUnknownOutlined, PartitionOutlined, ScheduleOutlined, RiseOutlined } from '@ant-design/icons-vue'
export default {
  name: 'card_crekaa_career',
       components: {
        RightOutlined,
        AimOutlined,
        CodeOutlined,
        FileTextOutlined,
        FileUnknownOutlined,
        PartitionOutlined,
        ScheduleOutlined,
        RiseOutlined
    },
    props: {
        jd: Object,
    },
};
</script>
  <style scoped>
  .mt-20-mob{
    margin-top:0
  }
  .te{
    text-align: center;
  }
  .mt-20{
    margin-top:0
  }
  @media only screen and (min-width: 0) and (max-width: 1024px) {
    .mt-20-mob{
    text-align: end;
    margin-top:20px
  }
  
  .mt-20{
    margin-top:20px
  }
  .te{
    text-align: end;
  }}

</style>