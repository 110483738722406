<template>
  <!-- <a-alert :message="noti.title" :description="noti.description" :type="type_msg(noti.notifi_type)"
                show-icon> -->
  <!-- <template #icon><ion-icon name="happy-outline"></ion-icon></template> -->
  <router-link :to="'/notification-details/' + noti.notification_id" style="color:var(--text-color)">
    <div class="card" :class="type_msg(noti.notifi_type)">

      <div class="patches-header flx justify-content-space-between" style="font-size:14px;margin:0px 10px 10px 0px">
        Notification ID : {{ noti.notification_id }}
      </div>
      <div class="patches-body">

        <div class="left-section-">
          <img style="width:50px;height:50px;border-radius:50px"
            :src="this.$store.state.userDetils.length ? this.$store.state.userDetils[0].profile_picture : ''"
            alt="user profile">
        </div>
        <div class="middle-section-" style='text-align:start'>
          <h4 style="font-size: 16px;font-weight: 700;margin: 0px 5px 0px 10px;">Title:
            {{ noti.title }}</h4>
          <p class="truncate-text" style="font-size: 12px;margin: 10px 15px 0px 10px;">
            Subject: {{ noti.subject }}</p>
        </div>

      </div>
      <!-- <div class="footer-bottom"> -->
      <a-row>
        <a-col :sm="12" :xs="12" :md="12" :lg="12" style="text-align: start;">
          <span style="font-size: 14px;">From : System </span>
        </a-col>
        <a-col :sm="12" :xs="12" :md="12" :lg="12" style="text-align: end;">

          <ion-icon v-if="noti.status === 'Viewed'" style="font-size: 20px;" class="icon icon-color-seen"
            name="checkmark-done-outline"></ion-icon>
          <ion-icon v-else class="icon" style="font-size: 20px;" name="checkmark-done-outline"></ion-icon>
        </a-col>
      </a-row>
      <!-- <span > -->
      <!-- Status : -->
      <!-- <div class=""> -->
      <!-- Status: -->
      <!-- </div> -->
      <!-- </span> -->

      <!-- </div> -->
    </div>
  </router-link>
  <!-- </a-alert> -->

  <br>
</template>
<script>
export default {
  name: "my_notification_card",
  props: {
    noti: Object
  },
  methods: {
    type_msg(type__st) {
      if (type__st == "success") {
        return "bg-Completed"
      }
      else if (type__st == "info") {
        return "bg-Cancelled"
      }

      else if (type__st == "warning") {
        return "bg-Pending"
      }

      else if (type__st == "danger") {
        return "bg-Due"
      }
    },
  }
}
</script>
<style scoped>
.main-page {
  margin-top: 0px;
  width: 80%;
}

.left-pane {
  width: 20%;
  background-color: white;
  height: 100%;
  position: absolute;
}

.right-pane {
  margin-left: 20%;
  width: 80%;
  height: 100%;
  position: absolute;
  background: white;
}

.text-contract-details {
  font-size: 16px;
}

.container-entire-contract-details {
  box-shadow: 0px 8px 16px 0px rgba(47, 47, 47, 0.2);
  padding: 20px;
  border-radius: 20px;
  margin: 20px
}

/* contract type labels */
.label-contract-type-standard {
  padding: 10px;
  color: white;
  background: rgba(135, 0, 137, 0.44);
  border: 3px rgb(135, 0, 137);
  border-radius: 10px;
}

.label-contract-type-startup {
  padding: 10px;
  color: white;
  background: rgba(25, 2, 228, 0.44);
  border: 3px rgb(25, 2, 228);
  border-radius: 10px;
}

.label-contract-type-custom {
  padding: 10px;
  color: white;
  background: rgba(228, 2, 2, 0.44);
  border: 3px rgb(228, 2, 2);
  border-radius: 10px;
}

.label-project-type-website {
  padding: 10px;
  color: white;
  background: rgba(2, 141, 228, 0.44);
  border: 3px rgb(32, 0, 173);
  border-radius: 10px;
}

.label-project-type-mobile {
  padding: 10px;
  color: white;
  background: rgba(17, 255, 0, 0.44);
  border: 3px rgb(7, 104, 0);
  border-radius: 10px;
}

.label-project-status-under-confirmation {
  padding: 10px;
  color: white;
  background: rgba(255, 0, 0, 0.405);
  border: 3px rgb(255, 0, 0, );
  border-radius: 10px;
}

.label-project-status-confirmed {
  padding: 10px;
  color: white;
  background: rgba(255, 128, 0, 0.44);
  border: 3px rgb(255, 128, 0);
  border-radius: 10px;
}

.label-project-status-completed {
  padding: 10px;
  color: white;
  background: rgba(246, 255, 0, 0.44);
  border: 3px rgb(246, 255, 0);
  border-radius: 10px;
}

.label-project-status-pending {
  padding: 10px;
  color: white;
  background: rgba(255, 0, 119, 0.44);
  border: 3px rgb(255, 0, 119, );
  border-radius: 10px;
}

.label-type-project-website-frontend-technologies-custom-css-and-clean-css {
  font-size: 14px;
  color: rgb(183, 0, 0);
}

.label-type-project-website-frontend-technologies-crekaa.ui {
  font-size: 14px;
  color: rgb(183, 70, 0);
}

.label-type-project-website-frontend-technologies-standard-css-with-frameworks {
  font-size: 14px;
  color: rgb(163, 141, 0);
}

.label-type-project-mobile-frontend-technologies-standard-frameworks-and-patches {
  font-size: 14px;
  color: rgb(0, 163, 8);
}

.label-type-project-mobile-frontend-technologies-custom-ui-and-clean-ui {
  font-size: 14px;
  color: rgb(0, 147, 163);
}

.label-type-project-mobile-frontend-technologies-crekaa.ui-and-official-patches {
  font-size: 14px;
  color: rgb(16, 0, 163);
}








.container-entire-contract-details .header-space-between {
  display: flex;
  background-color: azure;
  color: black;
  justify-content: space-between;
  padding: 20px;
}

.container-entire-contract-details .header-text-center {
  background-color: rgba(122, 122, 122, 0.616);
  color: black;
  text-align: center;
  padding: 10px;
}

.container-entire-contract-details .dates-contract-related {
  display: flex;
  background-color: azure;
  color: black;
  justify-content: space-between;
  padding: 20px;
}

.section-contract-details-head {
  text-align: center;
  background-color: blanchedalmond;
  border-radius: 10px;
  color: black;
  width: 200px;
  padding: 10px
}

/* project desc */
.proj-desc {
  font-size: 12px
}

.flx {
  display: flex;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.pd-10 {
  padding: 10px
}

.gallery-project-mob {
  background-color: rgba(239, 238, 238, 0.733);
  box-shadow: 0px 8px 16px 0px rgba(47, 47, 47, 0.2);
  max-width: 100%;
  max-height: 1020px;
  padding: 20px;
  overflow-y: scroll;
  border-radius: 10px;
  /* overflow: auto; */
  /* white-space: nowrap; */
}

.gallery-project-mob img {
  background-color: rgb(255, 255, 255);
  max-width: 100%;
  padding: 10px;
  overflow-y: scroll;
  border-radius: 10px;
  /* overflow: auto; */
  /* white-space: nowrap; */
}

.img-project-gallery {
  width: 200px;
  height: 130px;
  object-fit: contain;
}



/* financial section */
.financila-section {
  display: flex;
  gap: 10px;
}

.billing-section {
  margin: 10px;
  padding: 10px;
  background-color: rgb(250, 244, 255);
  border-radius: 20px;
}

.patches-section {
  margin: 10px;
  padding: 10px;
  background-color: rgb(250, 244, 255);
  border-radius: 20px;
}

.patches-list {
  height: 700px;
  overflow-y: scroll;
}

.billings-list {
  height: 700px;
  overflow-y: scroll;
}

.patches- {
  margin: 5px;
  display: flex;
  flex-direction: column;
  border-radius: 10px
}

.patches-:hover {
  margin: 5px;
  display: flex;
  flex-direction: column;
}

.patches-body {
  margin: 5px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
}

.footer- {
  margin: 0px
}

.footer-bottom {
  margin: 0px;
  /* display: flex; */
  font-size: 14px;
  color: grey;
  justify-content: space-between;
}

.right-section--button {
  border-radius: 20px;
  background-color: rgba(255, 231, 231, 0.574);
  height: 35px;
  cursor: pointer;
  padding: 5px;
}

.right-section--button:hover {
  transition: all 0.5s;
  border-radius: 20px;
  background-color: rgba(255, 231, 231, 0.97);
  height: 35px;
  padding: 5px;
}

.main-head--title {
  font-size: 22px;
  font-weight: 650;
}


/* bg- colors */
.bg-Completed {
  border-left: 3px solid rgba(47, 219, 0);
  background: rgba(47, 219, 0, 0.100);

}

.bg-Pending {
  border-left: 3px solid rgb(255, 242, 0);
  background: rgba(255, 242, 0, 0.100);

}

.bg-Due {
  border-left: 3px solid rgba(255, 0, 0);
  background: rgba(255, 0, 0, 0.100);
}

.bg-Un-Paid {
  border-left: 3px solid rgba(255, 153, 0);
  background: rgba(255, 153, 0, 0.100);

}

.bg-Cancelled {
  border-left: 3px solid rgb(144, 0, 255);
  background: rgba(144, 0, 255, 0.100);

}

.bg-Uncompleted {
  border-left: 3px solid rgba(255, 0, 0);
  background: rgba(255, 0, 0, 0.100);

}

.icon {
  font-size: 20px;
}

.icon-color-seen {
  color: blue
}

.text-color-Completed {
  color: rgb(28, 130, 0);
  background: white;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.text-color-Pending {
  color: rgb(184, 187, 0);
  background: white;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.text-color-Due {
  color: rgba(255, 0, 0);
  background: white;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.text-color-Un-Paid {
  color: rgb(255, 0, 85);
  background: white;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}


/* grid for notification */
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Show 3 cards on PC */
  /* gap: 10px; */

}

.card {
  /* background-color: #f0f0f0; */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Adjust grid for tablet view */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(2, 50%);
    /* Show 2 cards on tablet */
  }
}

/* Adjust grid for mobile view */
@media (max-width: 480px) {
  .grid-container {
    grid-template-columns: 100%;
    /* Show 1  on mobile */
  }
}

.truncate-text {
  max-width: 100px;
  /* Set the desired width for the container */
  min-width: 100px;
  /* Set the desired width for the container */
  min-width: 100%;
  /* Set the desired width for the container */
  white-space: nowrap;
  /* Prevent line breaks */
  overflow: hidden;
  /* Hide overflowing content */
  text-overflow: ellipsis;
  /* Show an ellipsis (...) for hidden text */
}

.body-bills {
  width: 100%;
  height: 100vh
}

.column-row-bills {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.row-row-bills {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.row-row-bills img {
  width: 100%;
  padding: 20px;
  max-width: 400px;
}

.explore_button {
  /* color:black; */
  font-weight: 700;
  background-color: rgba(255, 89, 0, 0.585);
  ;
  border-radius: 10px;
  max-width: 300px;
  height: 40px;
  padding: 10px;
}

.explore_button:hover {
  background-color: rgba(255, 89, 0, 0.885);
}

.row-row-bills a {
  margin: 0
}</style>