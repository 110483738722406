<template>
    <div class="card-1" >

        <h4 class="title-card">
            {{web_plan.title}} </h4>
        <h6 class="price-card" v-if="web_plan.title === 'Custom'" style="font-size:14px;width:210px;text-align :center;margin:auto" >
            * {{web_plan.price}} 
            <br>
        </h6>
        <div v-if="web_plan.title === 'Custom'" > <br><br><br><br></div>
        <h6 class="price-card" v-else >
            <sup>₹ </sup >  {{web_plan.price}} <small>/-</small>
        <p class="save-upto">Save Upto {{ web_plan.save_upto}}%&nbsp;<s>₹{{ web_plan.save_upto_price}}k</s></p>
        </h6>
        <br>
        <br>
        <ul class="checklist">
            <li>{{web_plan.li1}}</li>
            <li>{{web_plan.li2}}</li>
            <li>{{web_plan.li3}}</li>
            <li>{{web_plan.li4}}</li>
            <li>{{web_plan.li5}}</li>
            <li>{{web_plan.li6}}</li>
            <li>{{web_plan.li7}}</li>
            <li>{{web_plan.li8}}</li>
              </ul>
              <router-link :to="web_plan.link_url">

              <a-button type="primary" style="border-radius:40px;font-weight:bold" size="large"><span v-if="web_plan.title === 'Standard'" name="sparkles-outline">🔥</span> Explore {{ web_plan.title }} plan </a-button>
            </router-link>
    </div>
</template>

<script>
export default {
    name: "web_pricing_plan_card",
    props: {
        web_plan: Object
  }
}
</script>
<style scoped>

.title-card{
    font-size: 21px;
    font-weight:bold ;
    font-family:Alkatra;
}
.price-card {
    font-size:42px;
    font-weight:bold ;
    font-family:Alkatra;
} 
.price-card sup{
    font-size:21px;
    font-weight:bold ;
    font-family:Alkatra;
} 
.save-upto{
    
    font-size:12px; font-family:Alkatra;
    font-weight:bold
}
.checklist li {
 font-family:Alkatra;
    font-size:16px;
    font-weight:normal;
    padding-bottom: 16px;
}
.card-1 {
    /* height: 550px; */
    min-width: 90%;
    max-width: 370px;
    margin: 10px;
    background: transparent ;
    background-color: var(--card-color);
    border-radius: 40px;
    padding: 20px;
    box-shadow: rgba(193, 47, 255, 0.1) 0px 2px 8px 0px;
    /* flexing */
    /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
    align-items: center;
    /* Centering Text */
}
.checklist{
    text-align:start;
    
  list-style: none;
  padding-left: 1em;
}
.checklist li::before {
  content: '✔️';
  /* Use the ✅ symbol as a bullet */
  margin-right: 0.5rem;
  /* Add some spacing between the symbol and the list item text */

}
</Style>