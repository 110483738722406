<template>
    <div v-if="user_details.length ? user_details[0].user.can_careers_post_job : false ">

<div style="background-color: var(--notification-color-bg);"  v-if="this.$store.state.userDetils.length">
    <div >

        <br><br>
        <br><br>
        
        <h1>Create Job Page</h1>
        <create_job_crekaa_careers style="height:100%;width:100%; background-color: var(--notification-color-bg);"/>
    </div>
    </div>
    </div>
    </template>
<script>
import axios from 'axios'
import create_job_crekaa_careers from '@/components/careers/create_job_crekaa_careers.vue'


export default {
    name:"createCareersJob",
    data(){
        return{
         user_details:[],
        }
    },
    mounted(){
         this.fetchUserProfileDetails()
    },
    created(){
        document.head.title="Admin Crekaa Create Job Opportunity "
    },
    components:{
        create_job_crekaa_careers
    },
    methods: {
        async fetchUserProfileDetails() {
            this.$store.commit('setIsLoading', true)

            await axios
                .post("user-details/")
                .then((response) => {
                    // console.log( response.data)
                    this.user_details = response.data
                }).catch((error) => {
                    console.log("error Occured while fetching User Data ")
                })

            this.$store.commit('setIsLoading', false)
},
            // async fetchUserProfileDetails(){
            //     await axios
            //     .post("URL_here",data)
            //     .then((response)=>{
            //         console.log(response.data)
            //     }).catch((error)=>{
            //         console.log("error Occured ")
            //     })
            // }
    }
} 

</script>
<style scoped>
</style>