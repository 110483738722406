<template>
    <a-button type="primary" @click="showModal" style="border-radius:100%"><template #icon>
        <ion-icon name="pencil-outline"></ion-icon>
    </template></a-button>
        <a-modal v-model:open="open" title="Update Job Positions Required" :confirm-loading="confirmLoading" @ok="handleOk">
            <!-- <label>Positions Required </label> -->
            <a-input v-model:value="positions_req"></a-input>
            
        </a-modal>
    </template>
    <script>
    

import { notification } from 'ant-design-vue'
import axios from 'axios'

    
    export default{
        name:"updatePositionsOpeningJobCareers",
        data(){
            return {
                modalText: 'Content of the modal',
                open: false,
                confirmLoading: false,
                job_num:'',
                positions_req:0
            }
        },
        
        mounted(){
            this.positions_req=this.positions_required
            this.job_num=this.job_number
        }, props:{
            job_number:Object,
             positions_required:Object
        },
        methods:{
                showModal() {
                this.open = true;
                },
                async handleOk () {
                this.confirmLoading = true;
                const formData={
                    position_open:this.positions_req,
                }
                await axios
        .post("update-job-details-career-crekaa-admin/"+"update_position_open/"+this.job_number,formData)
        .then(response => {
            // console.log(response);
            // console.log("------------------------------------Got Response------------------------------------");
            // console.log(response.data);

            notification['success']({
                    message: 'Careers Job Positions Available has been updated ',
                    description:'Careers job Positions Available has been updated successfully, You just need to refresh update page.',
                    placement: 'bottomRight',
                });
        })
        .catch(error => {
            console.log(error)
                notification['error']({
                    message: 'Unable to update Positions Available',
                    // description:'Unable To Fetch User Details...',
                    placement: 'bottomRight',
                });

                console.log("error Found unable to update")                        
                console.log(JSON.stringify(error.response.data))
                    console.log(JSON.stringify(error))
        })

        this.confirmLoading = false;
            this.open = false;
            window.location.reload()
            // this.confirmLoading = true;
            // setTimeout(() => {
            //     this.open = false;
            //     this.confirmLoading = false;
            // }, 2000);
            },

    
        },
    
        }
    
    </script>
    <style scoped>
    
    </style>
    
    
    