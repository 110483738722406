<template>
  <br><br>
      <div  v-if="user_details.length ? user_details[0].user.can_careers_update_job : false  ">
  <div  v-if="this.$store.state.userDetils.length">
  <div style="padding:10px;height:100%; background-color: var(--notification-color-bg);">
    <!-- {{ content ? content : "none" }} -->
    
    <div v-if="job_data">
      <h1> Update Job Crekaa Careers <b>{{ job_data.job_career_offer[0].fields.job_number }}</b></h1>
      <!-- {{job_data}} -->
      <a-row>
        

        <a-col :sm="24" :xs="24" :md="12" :lg="12">
          <a-card style="text-align:start ; margin:10px">
            <a-row>

              <a-col :sm="22" :xs="22" :md="22" :lg="22">

                <h4> <b> Job ID : </b> {{ job_data.job_career_offer[0].fields.job_number }}</h4>
                <h4> <b> Job Title (en) : </b> {{ job_data.job_career_offer[0].fields.job_title ?
                  job_data.job_career_offer[0].fields.job_title : "Unavailable" }}</h4>
              </a-col>
              <a-col :sm="2" :xs="2" :md="2" :lg="2">
                <updateTitleJobCareers v-bind:job_number="job_data.job_career_offer[0].fields.job_number"
                v-bind:job_title_obj="job_data.job_career_offer[0].fields.job_title"
             
                />
              </a-col>
            </a-row>
          </a-card>
        </a-col>
        <a-col :sm="24" :xs="24" :md="12" :lg="12">
                    <a-card style="text-align:start ; margin:10px">
                           
              <a-row>

              <a-col :sm="22" :xs="22" :md="22" :lg="22">

          <h4> <b> Employement Type : </b> {{ job_data.job_career_offer[0].fields.employement_type }}</h4>
           </a-col>
              <a-col :sm="2" :xs="2" :md="2" :lg="2">
                <updateEmployementTypeJobCareers v-bind:job_number="job_data.job_career_offer[0].fields.job_number"
                 v-bind:job_employement_type="job_data.job_career_offer[0].fields.employement_type" />

              </a-col>
            </a-row>
                    </a-card>
        </a-col>

        <a-col :sm="24" :xs="24" :md="12" :lg="12">
          <a-card style="text-align:start ; margin:10px">
            <a-row>

              <a-col :sm="22" :xs="22" :md="22" :lg="22">

          <h4> <b> Experience Required : </b> {{ job_data.job_career_offer[0].fields.experience_required }}</h4>
           </a-col>
              <a-col :sm="2" :xs="2" :md="2" :lg="2">
                <updateExperienceRequiredJobCareers v-bind:job_number="job_data.job_career_offer[0].fields.job_number" v-bind:job_experience_required="job_data.job_career_offer[0].fields.experience_required" />

              </a-col>
            </a-row>
          </a-card>
        </a-col>
        <a-col :sm="24" :xs="24" :md="12" :lg="12">
                    <a-card style="text-align:start ; margin:10px">
                       <a-row>

              <a-col :sm="22" :xs="22" :md="22" :lg="22">

          <h4> <b> Salary : </b> {{ job_data.job_career_offer[0].fields.job_salary_currency }}
            {{ job_data.job_career_offer[0].fields.salary_expected }}</h4>
            </a-col>
              <a-col :sm="2" :xs="2" :md="2" :lg="2">
                <updateSalaryJobCareers v-bind:job_number="job_data.job_career_offer[0].fields.job_number" 
                v-bind:salary_expected="job_data.job_career_offer[0].fields.salary_expected"
                v-bind:salary_currency="job_data.job_career_offer[0].fields.job_salary_currency"/>

              </a-col>
            </a-row>
                      </a-card>
        </a-col>
        <a-col :sm="24" :xs="24" :md="12" :lg="12">
                    <a-card style="text-align:start ; margin:10px">
                       <a-row>

              <a-col :sm="22" :xs="22" :md="22" :lg="22">

          <h4> <b> Positions Opening : </b> {{ job_data.job_career_offer[0].fields.position_openings }}</h4>
          </a-col>
              <a-col :sm="2" :xs="2" :md="2" :lg="2">
                <updatePositionsOpeningJobCareers v-bind:job_number="job_data.job_career_offer[0].fields.job_number" v-bind:positions_required="job_data.job_career_offer[0].fields.position_openings" />

              </a-col>
            </a-row>
                    </a-card>
        </a-col>
        <a-col :sm="24" :xs="24" :md="12" :lg="12">
                    <a-card style="text-align:start ; margin:10px">
                      
              <a-row>

              <a-col :sm="22" :xs="22" :md="22" :lg="22">

          <h4> <b> Active : </b> <span v-if="job_data.job_career_offer[0].fields.is_active"> ✅</span> <span v-else>❌
            </span> </h4><span style="font-size: 12px;">( Active will show this posted job are currently accepting responces or not.{✅
            means yes it is accepting}, {❌ means no it is not accepting} )</span> <br>
             </a-col>
              <a-col :sm="2" :xs="2" :md="2" :lg="2">
                <updateActiveJobCareers v-bind:job_number="job_data.job_career_offer[0].fields.job_number" v-bind:job_active="job_data.job_career_offer[0].fields.is_active" />

              </a-col>
            </a-row>
                      </a-card>
        </a-col>
        <a-col :sm="24" :xs="24" :md="12" :lg="12">
                    <a-card style="text-align:start ; margin:10px">
          <h4> <b> Skills : </b> <a-tag v-for="skill in job_data.job_career_offer[0].fields.skills.split(',')"
              :key="skill" :bordered="false" :color="generateRandomColor()">
              {{ skill.trim() }}
            </a-tag></h4>
          
                      </a-card>
        </a-col>




        <a-col :sm="24" :xs="24" :md="12" :lg="12">

          <a-card style="margin:10px;text-align:start">

              <a-row>

              <a-col :sm="22" :xs="22" :md="22" :lg="22">

            <h4> <b>Job Description English : </b> </h4>
            <div
              v-html="job_data.job_career_offer[0].fields.description_job ? job_data.job_career_offer[0].fields.description_job : 'Unavailable'">
            </div>
             </a-col>
              <a-col :sm="2" :xs="2" :md="2" :lg="2">
                <updateJobDescriptionCareers_en v-bind:job_number="job_data.job_career_offer[0].fields.job_number" />

              </a-col>
            </a-row>
          </a-card>
        </a-col>

      </a-row>

      <br>
      <br>
      <a-row style="text-align:start">
        <a-col :sm="24" :xs="24" :md="12" :lg="12">
          <a-card style="margin:10px">
            <a-row>
              
              <a-col :sm="22" :xs="22" :md="22" :lg="22">
                
                <h1>Locations</h1>
              </a-col>
              <a-col :sm="2" :xs="2" :md="2" :lg="2">
                        
          <createLocationJobCareers v-bind:job_number="job_data.job_career_offer[0].fields.job_number" /> 
      </a-col>
    </a-row>
        <div v-for="loc in job_data.locations">
          <a-card style="background-color: rgba(128, 128, 128, 0.236);">
  <a-row>

              <a-col :sm="22" :xs="22" :md="22" :lg="22">

            <h4> <b> Location ID : </b> {{ loc.location_id }}</h4>
            <h4> <b> Country Name : </b> {{ loc.country_name }}</h4>  </a-col>
              <a-col :sm="2" :xs="2" :md="2" :lg="2">
                <a-button type="primary" danger style="border-radius:100%;" @click="showConfirm_deleteLocation(loc.id,loc.country_name,job_data.job_career_offer[0].fields.job_number)">
                          <template #icon>
                            <ion-icon name="trash"></ion-icon>
                          </template>
                        </a-button>

              </a-col>
            </a-row>
          </a-card>
          <br>

        </div>
      </a-card>


        </a-col>
        <a-col :sm="24" :xs="24" :md="12" :lg="12">
          <a-card style="margin:10px">
            <a-row>
              
              <a-col :sm="22" :xs="22" :md="22" :lg="22">
                
                <h1>Teams</h1>
              </a-col>
              <a-col :sm="2" :xs="2" :md="2" :lg="2">
                        
          <createTeamJobCareers v-bind:job_number="job_data.job_career_offer[0].fields.job_number" />
      </a-col>
    </a-row>
        <div v-for="tem in job_data.team">
          <a-card style="background-color: rgba(128, 128, 128, 0.236);">

             <a-row>
              
              <a-col :sm="22" :xs="22" :md="22" :lg="22">
                
            <h4> <b> Team ID : </b> {{ tem.team_id }}</h4>
            <h4> <b> Team Name : </b> {{ tem.team_name }}</h4>
            </a-col>
              <a-col :sm="2" :xs="2" :md="2" :lg="2">
                        <a-button type="primary" danger style="border-radius:100%;"  @click="showConfirm_deleteTeam(tem.id ,tem.team_name,job_data.job_career_offer[0].fields.job_number)">
                          <template #icon>
                            <ion-icon name="trash"></ion-icon>
                          </template>
                        </a-button>
      </a-col>
    </a-row>
          </a-card>
          
          <br>
        </div>
      </a-card>
<br>

        </a-col>
        <a-col :sm="24" :xs="24" :md="12" :lg="12">
          <a-card style="margin:10px">
            <a-row>
              
              <a-col :sm="22" :xs="22" :md="22" :lg="22">
                
                <h1>Queations</h1>
              </a-col>
              <a-col :sm="2" :xs="2" :md="2" :lg="2">
                        
          <createQueationJobCareers v-bind:job_number="job_data.job_career_offer[0].fields.job_number" />
      </a-col>
    </a-row>
        <div v-for="que in job_data.job_career_offer_question">
          <a-card style="background-color: rgba(128, 128, 128, 0.236);">

              <a-row>

              <a-col :sm="22" :xs="22" :md="22" :lg="22">

            <h4> <b> Question ID : </b> {{ que.question_id ? que.question_id: "Unavailable" }}</h4>
            <h4> <b> Question (EN): </b> {{ que.question ? que.question: "Unavailable" }}</h4>
            <h4><b> Required : </b> {{ que.question_required }}</h4>
              </a-col>
              <a-col :sm="2" :xs="2" :md="2" :lg="2" style="display: flex;flex-direction: column;justify-content: space-between;">
  
                <updateQueationJobCareers   v-bind:job_number="job_data.job_career_offer[0].fields.job_number"
                                            v-bind:question_obj="que.question"
                                            v-bind:question_required_obj="que.question_required"
                                            v-bind:qid="que.id"
                                            v-bind:question_id="que.question_id"
                />

<a-button type="primary" danger style="border-radius:100%;" @click="showConfirm_deleteQueation( que.id, que.question,job_data.job_career_offer[0].fields.job_number)">
                          <template #icon>
                            <ion-icon name="trash"></ion-icon>
                          </template>
                        </a-button>

              </a-col>
            </a-row>
          </a-card>
          <br>

        </div>
      </a-card>
<br>

        </a-col>
        
        <a-col :sm="24" :xs="24" :md="12" :lg="12">
          <a-card style="margin:10px">
            <a-row>

              <a-col :sm="22" :xs="22" :md="22" :lg="22">

        <h1>Work Areas </h1>
</a-col>
              <a-col :sm="2" :xs="2" :md="2" :lg="2">
                
        <createWorkAreaJobCareers v-bind:job_number="job_data.job_career_offer[0].fields.job_number"   />
</a-col>
            </a-row>

        <div v-for="wa in job_data.work_areas">
          <a-card style="background-color: rgba(128, 128, 128, 0.236);">

              <a-row>

              <a-col :sm="22" :xs="22" :md="22" :lg="22">

            <h4> <b> Work Area ID : </b> {{ wa.work_areas_id }}</h4>
            <h4> <b> Work Area Name : </b> {{ wa.name_work_area }}</h4>
              </a-col>
              <a-col :sm="2" :xs="2" :md="2" :lg="2">
                
                <a-button type="primary" danger style="border-radius:100%;" @click="showConfirm_deleteWorkArea(wa.id , wa.name_work_area,job_data.job_career_offer[0].fields.job_number)">
                          <template #icon>
                            <ion-icon name="trash"></ion-icon>
                          </template>
                        </a-button>
              </a-col>
            </a-row>
          </a-card>
          <br>

        </div>
      </a-card>
        </a-col>

      </a-row>
      

    </div>
    <div v-else>
      loading Entire Job Data Components
    </div>
    <!-- <updateTitleJobCareers  /> -->


  </div>
  <!-- <a-button @click="CreatePosition()" block type="primary" style="width:90%">
Post Job
      </a-button> -->
  <br><br>
  </div>    </div>
</template>
  
<script>
import { notification } from 'ant-design-vue';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.bubble.css'
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

import updateTitleJobCareers from '@/components/careers/admin_update_careers_job/update_title.vue'
import updateJobDescriptionCareers_en from '@/components/careers/admin_update_careers_job/update_description_en.vue'
import updateActiveJobCareers from '@/components/careers/admin_update_careers_job/update_active.vue'
import updateSalaryJobCareers from '@/components/careers/admin_update_careers_job/update_salary.vue'
import updateEmployementTypeJobCareers from '@/components/careers/admin_update_careers_job/update_employement_type.vue'
import updateExperienceRequiredJobCareers from '@/components/careers/admin_update_careers_job/update_experience_required.vue'

import updatePositionsOpeningJobCareers from '@/components/careers/admin_update_careers_job/update_positions_opening.vue'
import createTeamJobCareers from '@/components/careers/admin_update_careers_job/create_team.vue'
import createWorkAreaJobCareers from '@/components/careers/admin_update_careers_job/create_work_area.vue'
import updateQueationJobCareers from '@/components/careers/admin_update_careers_job/update_question.vue'
import createLocationJobCareers from '@/components/careers/admin_update_careers_job/create_location.vue'
import createQueationJobCareers from '@/components/careers/admin_update_careers_job/create_question.vue'
import axios from 'axios'

// delete popconfirm
import { Modal } from 'ant-design-vue';
import { createVNode } from 'vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
export default {

  data() {
    return {
      application_number: '',
      displayedJd: '', // Add for decoding purpose 

      job_data: null,
      job_career_offer_question: null,
      locations: null,
      work_areas: null,
      teams: null,
       user_details:[],

    };
  },
  components: {
    QuillEditor,
    updateTitleJobCareers,
    updateSalaryJobCareers,
    updateEmployementTypeJobCareers,
    updateExperienceRequiredJobCareers,
    updatePositionsOpeningJobCareers,

    createTeamJobCareers,
    createWorkAreaJobCareers,
    updateQueationJobCareers,
    createLocationJobCareers,
    createQueationJobCareers,
    
    updateJobDescriptionCareers_en,
    
    updateActiveJobCareers,
  },

  mounted() {
    const application_number = this.$route.params.crekaa_job_id
    this.application_number = application_number
    this.fetchUserProfileDetails()
    this.fetch_Job()
  },
  computed: {
    convertedHTML() {
      const converter = new QuillDeltaToHtmlConverter(this.delta.ops, {});
      this.htmlContents = converter.convert();
      return converter.convert();
    },
  },
  methods: {
     async fetchUserProfileDetails() {
            this.$store.commit('setIsLoading', true)
            await axios
                .post("user-details/")
                .then((response) => {
                    // console.log( response.data)
                    this.user_details = response.data
                }).catch((error) => {
                    console.log("error Occured while fetching User Data ")
                })

            this.$store.commit('setIsLoading', false)
},
    showConfirm_deleteQueation(id, question,job_number) {
  Modal.confirm({
    title: 'Do you Want to delete these Question : " ' +question + ' " ' ,
    icon: createVNode(ExclamationCircleOutlined),
    content: createVNode(
      'div',
      {
        style: 'color:red;',
      },
      'Once deleted question will not get recovered in any sitution as well they will also delete all occurances of answers which we have recived from job seeker while they are feeling the job application from Crekaa careers section.',
    ),
    onOk() {

                const formData={
                  title: question,
                  content:id
                }
                 axios
        .post("update-job-details-career-crekaa-admin/"+"delete_question/"+job_number,formData)
        .then(response => {
            notification['success']({
                    message: 'Careers Job Question has been successfully Deleted ',
                    description:'Careers job employement type has been updated successfully, You just need to refresh update page.',
                    placement: 'bottomRight',
                });
        })
        .catch(error => {
            console.log(error)
                notification['error']({
                    message: 'Unable to delete Job Team ',
                    // description:'Unable To Fetch User Details...',
                    placement: 'bottomRight',
                });

                console.log("error Found unable to delete")                        
                console.log(JSON.stringify(error.response.data))
                    console.log(JSON.stringify(error))
        })

        // this.confirmLoading = false;
        //     this.open = false;
            window.location.reload()
            
    },
    onCancel() {
      console.log('Cancel');
    },
    class: 'test',
  });
},
showConfirm_deleteLocation(id, location,job_number) {
  Modal.confirm({
    title: 'Do you Want to delete these Location : " '+location +' " ',
    icon: createVNode(ExclamationCircleOutlined),
    content: createVNode(
      'div',
      {
        style: 'color:red;',
      },
      'Deleting this location will affect related job application where this location is un fetchable via search & filters.',
    ),
    onOk() {
      const formData={
                  title: location,
                  content:id
                }
                 axios
        .post("update-job-details-career-crekaa-admin/"+"delete_location/"+job_number,formData)
        .then(response => {
            notification['success']({
                    message: 'Careers Job Location has been successfully Deleted ',
                    description:'Careers job employement type has been updated successfully, You just need to refresh update page.',
                    placement: 'bottomRight',
                });
        })
        .catch(error => {
            console.log(error)
                notification['error']({
                    message: 'Unable to delete Job Team ',
                    // description:'Unable To Fetch User Details...',
                    placement: 'bottomRight',
                });

                console.log("error Found unable to delete")                        
                console.log(JSON.stringify(error.response.data))
                    console.log(JSON.stringify(error))
        })

        // this.confirmLoading = false;
        //     this.open = false;
            window.location.reload()
    },
    onCancel() {
      console.log('Cancel');
    },
    class: 'test',
  });
},
showConfirm_deleteTeam(id, team,job_number) {
  Modal.confirm({
    title: 'Do you Want to delete these Team : " '+team+' "',
    icon: createVNode(ExclamationCircleOutlined),
    content: createVNode(
      'div',
      {
        style: 'color:red;',
      },
      'Deleting this team will affect related job application where this team is un-fetchable via search & filters.'
    ),
    onOk() {
      
      const formData={
                  title: team,
                  content:id
                }
                 axios
        .post("update-job-details-career-crekaa-admin/"+"delete_team/"+job_number,formData)
        .then(response => {
            notification['success']({
                    message: 'Careers Job Location has been successfully Deleted ',
                    description:'Careers job employement type has been updated successfully, You just need to refresh update page.',
                    placement: 'bottomRight',
                });
        })
        .catch(error => {
            console.log(error)
                notification['error']({
                    message: 'Unable to delete Job Team ',
                    // description:'Unable To Fetch User Details...',
                    placement: 'bottomRight',
                });

                console.log("error Found unable to delete")                        
                console.log(JSON.stringify(error.response.data))
                    console.log(JSON.stringify(error))
        })

        // this.confirmLoading = false;
        //     this.open = false;
            window.location.reload()
    },
    onCancel() {
      console.log('Cancel');
    },
    class: 'test',
  });
},
showConfirm_deleteWorkArea(id, wa,job_number) {
  Modal.confirm({
    title: 'Do you Want to delete these work area : " '+wa+' " ',
    icon: createVNode(ExclamationCircleOutlined),
    content: createVNode(
      'div',
      {
        style: 'color:red;',
      },
      'Deleting this work area will affect related job application where this workarea is un fetchable via search & filters.',
    ),
    onOk() {
      
      const formData={
                  title: wa,
                  content:id
                }
                 axios
        .post("update-job-details-career-crekaa-admin/"+"delete_work_area/"+job_number,formData)
        .then(response => {
            notification['success']({
                    message: 'Careers Job Work Area has been successfully Deleted ',
                    description:'Careers job employement type has been updated successfully, You just need to refresh update page.',
                    placement: 'bottomRight',
                });
        })
        .catch(error => {
            console.log(error)
                notification['error']({
                    message: 'Unable to delete Job Work Area ',
                    // description:'Unable To Fetch User Details...',
                    placement: 'bottomRight',
                });

                console.log("error Found unable to delete")                        
                console.log(JSON.stringify(error.response.data))
                    console.log(JSON.stringify(error))
        })

        // this.confirmLoading = false;
        //     this.open = false;
            window.location.reload()
    },
    onCancel() {
      console.log('Cancel');
    },
    class: 'test',
  });
},
    generateRandomColor() {
      const colors = ['magenta', 'warning', 'blue', 'processing', 'orange']; // Add more colors if needed
      const randomColor = colors[Math.floor(Math.random() * colors.length)];
      return randomColor;
    },

    async fetch_Job() {
      this.$store.commit('setIsLoading', true)

      await axios
        .get("job-details-career-update-job-crekaa/" + this.application_number)
        .then((response) => {

          //     console.log("------------response-------------")
          // console.log(response.data)
          this.job_data = response.data

        }).catch((error) => {
          console.log(error)
        })

      this.$store.commit('setIsLoading', false)

    },

    async create_skill_submit() {
      this.$store.commit('setIsLoading', false)
      const formData = {
        skill: this.create_skill
      }
      await axios
        .post("create-skill-post-job-admin/", formData)
        .then(response => {
          // console.log("------------------------------------Got Response------------------------------------");
          // console.log(response.data);
          // this.skills_available=response.data['skill']
          if (response.data['error'] == false) {
            notification['success']({
              message: 'Skill Added Successfully',
              // description:'Unable To Login With Provided Credentials...',
              placement: 'bottomRight',
            });
            this.fetchAvailableSkills()
          } else {

            notification['error']({
              message: 'Unable to fetch the skills',
              // description:'Unable To Login With Provided Credentials...',
              placement: 'bottomRight',
            });
          }
          // console.log("------------------------------------Skills available------------------------------------");
          // console.log(this.skills_available)            
        })
        .catch(error => {
          console.log(error),
            notification['error']({
              message: 'Unable to fetch the skills',
              // description:'Unable To Login With Provided Credentials...',
              placement: 'bottomRight',
            });
        },)
      this.$store.commit('setIsLoading', false)

    },
    async fetchAvailableSkills() {
      this.$store.commit('setIsLoading', false)

      await axios
        .get("create-skill-post-job-admin/")
        .then(response => {
          // console.log("------------------------------------Got Response------------------------------------");
          // console.log(response.data);
          // this.skills_available=response.data['skill']

          // this.skills_available = response.data['skill'].map(skill => skill.skill_name);
          const skillsData = response.data['skill'];
          // Map the skills data to create an array of objects with a 'value' property
          this.skills_available = skillsData.map(skill => ({
            value: skill.skill_name
          }));
          // console.log("------------------------------------Skills available------------------------------------");
          // console.log(this.skills_available)            
        })
        .catch(error => {
          console.log(error),
            notification['error']({
              message: 'Unable to fetch the skills',
              // description:'Unable To Login With Provided Credentials...',
              placement: 'bottomRight',
            });
        },)
      this.$store.commit('setIsLoading', false)

    },


    show_html() {
      this.delta = this.content
    },
    async UpdatePosition() {
      this.$store.commit('setIsLoading', true)
      this.delta = this.content

      // console.log("+++++++++++++++++++_")
      // console.log(" countries : ", this.countries)
      // console.log("skills : ",this.skills)
      // console.log("employement_types : ",this.employement_types)
      // console.log("checked : ",this.checked)
      // console.log("is_active : ",this.is_active)
      // console.log("employement_type : ",this.employement_type)
      // console.log("job_title : ",this.job_title)
      // console.log("content : ",this.content.ops)
      // console.log("experience_required : ",this.experience_required)
      // console.log("salary_currency : ",this.salary_currency)
      // console.log("salary_expected : ",this.salary_expected)
      // console.log("position_openings : ",this.position_openings)
      // console.log("work_areas : ",this.work_areas)
      // console.log("teams : ",this.teams)
      // console.log("askQuestions : ",this.askQuestions) //job_career_offer_question
      // this.delta=this.content
      // console.log(this.countries) // here you will get the selected countries 
      const converter = new QuillDeltaToHtmlConverter(this.delta.ops, {});
      this.htmlContents = converter.convert();
      // console.log("htmlContents : ",this.htmlContents)



      const formData = {
        employement_type: this.employement_type,
        content: this.htmlContents,
        salary_currency: this.salary_currency,
        experience_required: this.experience_required,
        salary_expected: this.salary_expected,
        position_openings: this.position_openings,
        is_active: this.is_active,
        job_career_offer_question: this.askQuestions,
        locations: this.locations,
        work_areas: this.work_areas,
        teams: this.teams,
        skills: this.skills,
        locations: this.countries,

        job_title: this.job_title,
        job_title_fr: this.job_title_fr,
        job_title_kr: this.job_title_kr,
        job_title_sw: this.job_title_sw,
        job_title_li: this.job_title_li
      }


      await axios
        .post("job-cerekaa-career/", formData)
        .then(response => {
          // console.log(response);
          // console.log("------------------------------------Got Response------------------------------------");
          // console.log(response.data);
          if (response.data['error'] == false) {
            notification['success']({
              message: 'Successsfully posted ',
              description: 'thet new job has been posted on Careers page...',
              placement: 'bottomRight',
            });
          }
          else {
            {
              notification['info']({
                message: 'someththing wents wrong ',
                description: 'Please convey developer this message for something wents wrong here...',
                placement: 'bottomRight',
              });
            }
          }
          // this.$store.commit('addUserDetails', userData);
          // console.log("--------------------------------------Done---------------------------------");
          // console.log("the stored data : ",this.$store.state.userDetils)
          // console.log("the stored User data : ",this.$store.state.userDetils['0']['user'])
          // console.log("the stored User data : ",this.$store.state.userDetils[0].user.first_name);

        })
        .catch(error => {
          notification['error']({
            message: 'Signup Failed',
            description: 'Unable To Login With Provided Credentials...',
            placement: 'bottomRight',
          });

          // console.log("===========error at user details")                        
          // console.log(JSON.stringify(error.response.data))
          //     console.log(JSON.stringify(error))
        })


      this.$store.commit('setIsLoading', false)

    },
  },

}
</script>