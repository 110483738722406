<template>
  <!-- <div :class="contract.contr[0].is_contract_terminated ?  'tabbed-pane-project-terminated-contract' : 'tabbed-pane-project'" >
        <h1 v-show="project.Contract.is_contract_terminated" >This Contract Project Has Been Terminated</h1>

</div> -->
  <br>
  <br>
  <a-row>
    <a-col :sm="24" :xs="24" :md="12" :lg="12">
      <a-alert message="Contract Details" type="warning" style="max-width:200px;margin:auto" />
      <a-row>
        <a-col :md="24" :lg="24">
          <div class="financila-section ">
            <div class="container-entire-contract-details" style="width:100%;text-align:start;padding:10px">
              <br>

              <!-- <span class="text-contract-details">Client Email : {{contract.contr.user.email}}</span><br> -->

              <div class="header-space-between">
                <span class="text-contract-details"><b>Contract Number : {{ contract.contr ?
                  contract.contr[0].fields.Contract_number : "undeffined" }}</b></span><br>
                <span class="text-contract-details">Contract Type : <span class="label-contract-type-standard">{{
                  contract.contr ? contract.contr[0].fields.Contract_type :
                  "undeffined" }}</span></span><br><br>
              </div>
              <span class="text-contract-details">Project Type : <span class="label-project-type-website">
                  {{ contract.contr ? contract.contr[0].fields.Project_type : "undeffined" }}</span></span><br>
              <hr>
              <div class="header-text-center">
                <b> Project Details</b>
              </div>
              <br>
              <span class="header-space-between">
                <span class="text-contract-details">
                  <b>Project Name : </b> {{ contract.contr ?
                    contract.contr[0].fields.Project_name : "undeffined" }}
                </span>
                <span class="text-contract-details"><b>Project URL : </b> {{ contract.contr ?
                  contract.contr[0].fields.url : "undeffined" }}</span></span><br>

              <span class="text-contract-details"><b>Project File url :
                </b> {{ contract.contr ?
                  contract.contr[0].fields.Project_file : "undeffined" }}</span><br>

              <span class="text-contract-details"><b>Project Description :</b><br> <span class="proj-desc">
                  {{ contract.contr ? contract.contr[0].fields.Project_description : "undeffined" }}</span></span><br>
              <br>
              <hr>
              <div class="header-text-center">
                <b> About Project </b>
              </div>
              <br>
              <div class="flx justify-content-space-between pd-10">
                <span class="text-contract-details"><b>Project used Frontend Technologies :</b> <span
                    class="label-type-project-website-frontend-technologies-custom-css-and-clean-css">{{ contract.contr ?
                      contract.contr[0].fields.Used_frontend_technologies : "undeffined" }}</span></span><br>
                <span class="text-contract-details"><b>Contract Project Status :</b> <span
                    class="label-project-status-under-confirmation">{{ contract.contr ?
                      contract.contr[0].fields.Project_status : "undeffined" }}</span></span><br><br>
              </div>
              <div class="dates-contract-related">
                <span class="header-space-between">
                  <span class="text-contract-details"><b>Contract date Created : </b> {{ contract.contr ?
                    formatDate(contract.contr[0].fields.date_created) : "undeffined" }}</span><br>
                  <span class="text-contract-details"><b>Contract date Modified : </b> {{ contract.contr ?
                    formatDate(contract.contr[0].fields.date_modified) : "undeffined" }}</span><br></span>
                <span class="text-contract-details"><b>Contract Project Complition date : </b> {{ contract.contr ?
                  formatDate(contract.contr[0].fields.Complition_date) : "undeffined" }}</span><br>
              </div>
              <br>
              <span class="text-contract-details"> <b>Contract Pending Reason : </b> <span class="proj-desc">{{
                contract.contr ?
                (contract.contr[0].fields.pending_reason == "" ? contract.contr[0].fields.pending_reason : "NA") :
                "undeffined" }}</span></span><br>


              <hr>
              <div class="header-text-center">
                <b>Order State</b>
              </div><br>
              <span class="text-contract-details"> <b>Order Created </b>: {{ contract.contr ?
                contract.contr[0].fields.is_order_created : "undeffined" }}</span><br>

              <div class="flx justify-content-space-between pd-10">
                <span class="text-contract-details"><b>Under Confirmation :</b> {{ contract.contr ?
                  contract.contr[0].fields.is_under_confirmation : "undeffined" }}</span><br>
                <span class="text-contract-details"><b>Completed</b> : {{ contract.contr ?
                  contract.contr[0].fields.is_completed
                  :
                  "undeffined" }}</span><br>
              </div>
              <div class="flx justify-content-space-between pd-10">
                <span class="text-contract-details"><b>Confirmed</b> : {{ contract.contr ?
                  contract.contr[0].fields.is_confirmed
                  :
                  "undeffined" }}</span><br>
                <span class="text-contract-details"><b>Pending</b> : {{ contract.contr ?
                  contract.contr[0].fields.is_pending :
                  "undeffined" }}</span><br>
              </div>
              <hr>
              <div class="header-text-center">
                <b>Order Plan Type</b>
              </div><br>
              <div class="flx justify-content-space-between pd-10">
                <span class="header-space-between">
                  <span class="text-contract-details"><b>Startup :</b> {{ contract.contr ?
                    contract.contr[0].fields.is_startup :
                    "undeffined" }}</span>
                  <span class="text-contract-details"><b>Standard :</b> {{ contract.contr ?
                    contract.contr[0].fields.is_standard :
                    "undeffined" }}</span>
                  <span class="text-contract-details"><b>Custom :</b> {{ contract.contr ?
                    contract.contr[0].fields.is_custom :
                    "undeffined" }}</span></span>
              </div>
              <hr>
              <div class="header-text-center">

                <b>Using CreKaa.UI</b>
              </div><br>
              <span class="text-contract-details">Using Crekaa UI : {{ contract.contr ?
                contract.contr[0].fields.is_using_crekaa_ui : "undeffined" }}</span><br>
            </div>


          </div>
        </a-col>
        <a-col :md="12" :lg="8"> </a-col>
      </a-row>
    </a-col>



    <a-col :sm="24" :xs="24" :md="12" :lg="12" style="padding:10px">

      <a-tabs v-model:activeKey="activeKey"
        style="height:600px;overflow-y: scroll; text-align:start;background:var(--card-color);padding:20px;border-radius:20px;width:100%;box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;">
        <a-tab-pane key="1">
          <template #tab>
            Billings
          </template>
          <div v-if="contract.bills ? contract.bills[0] : false ">


            <p class="text-head-recent-patches">Here you can see the recent 4 Bills</p>
            <br>
            <div v-for="bill in contract.bills">
                <router-link :to="'/bill-details/' + bill.bill_number" style="color:var(--text-color)">
              <a-alert type="success" show-icon style="padding:20px">
                <template #message>
                  <span class="patch-head">
                    <span class="patch-id"><b>Bill ID: {{ bill.bill_number }}</b></span><br>
                    <span class="patch-created" style="font-size:12px">Created Date: {{ formatDate(bill.date_created)
                    }}</span><br>
                  </span>
                  <span class="patch-body">
                    <span class="card-patch-left-section">
                      <span class="patch-type">Type:{{ bill.bill_description }}</span><br>

                      <span class="patch-status">Status: {{ bill.Billing_status }}</span><br>
                    </span>
                    <span class="card-patch-middle-section">
                      <!-- <span class="title-card-patch"> {{bill.bill_description}}</span><br> -->
                      <span class="description-card-patch">Used Services: {{ bill.Used_Services }} </span><br>
                    </span>
                    <span class="card-patch-right-section">
                      <span class="patch-created flx " style="justify-content:end">Complition Date:
                        {{ bill.Choose_Month }}</span><br>

                    </span>
                  </span>
                </template>

              </a-alert>
                </router-link>
              <br>
            </div>

          </div>
          <div v-else>
            <br><br>
            <a-empty description="No Billings available." />
          </div>
        </a-tab-pane>
        <a-tab-pane key="2">
          <template #tab>
            Contract Images
          </template>
          <div v-if="contract.images" class="image-grid">
            <a-image v-for="image in contract.images" :src="image.image_project"
              style="max-height:200px;object-fit:cover;border-radius:10px" class="image-item" :key="image.id" />

          </div>
          <div v-else>
            <br><br>
            <a-empty description="No Patches available." />
          </div>
        </a-tab-pane>
        <a-tab-pane key="3">
          <template #tab>
            Billings As Per Monthly
          </template>

          <div v-if="contract.bill_with_patches">
            <!-- <p class="text-head-recent-patches">Here you can see the recent 4 Bills</p> -->
            <br>
            <div v-for="i in contract.patches_by_month"
              style="text-align:start;background:rgba(237, 196, 255,0.2);padding:10px;border-radius:10px;margin-bottom:10px">
              <div class="billing-month-card">
                <div style="display: flex; justify-content: space-between;">
                  <h3>Year : {{ i.year }}</h3>
                  <h3>Month : {{ i.month }} </h3>
                </div>
                <div class="body-billing-month-card">
                  <ul>
                    <li> Monthly Backup, Retention & Maintainance Cost : ₹2500</li>

                    <li v-for="j in i.patches">
                      Patch Number : {{ j.patch_number }} ,&nbsp;
                      Patch Contract : {{ j.patch_contract }} , &nbsp;
                      Patch payment : ₹{{ j.patch_payment }}
                    </li>

                  </ul>
                </div>
                <div class="footer-billing-month-card">
                  <b>Total Ammount : ₹{{ i.ammount }} </b><br>
                </div>
              </div>


            </div>
          </div>
          <div v-else>
            <br><br>
            <a-empty description="No Monthly Record Found." />
          </div>

        </a-tab-pane>
        <a-tab-pane key="4">
          <template #tab>
            Patches
          </template>


          <div v-if="contract.patch">

            <p class="text-head-recent-patches">Here you can see the your recent 4 Bills</p>
            <br>
            <div v-for="patch in contract.patch ">
              <a-alert type="success" show-icon style="padding:20px">
                <template #message>
                  <span class="patch-head">
                    <span class="patch-id"><b>Patch ID: {{ patch.patch_number }}</b></span><br>
                    <span class="patch-created" style="font-size:12px">Created Date: {{ formatDate(patch.date_created)
                    }}</span><br>
                  </span>
                  <span class="patch-body">
                    <span class="card-patch-left-section">
                      <span class="patch-type">Type:{{ patch.patch_type }}</span><br>

                      <span class="patch-status">Status: {{ patch.patch_status }}</span><br>
                    </span>
                    <span class="card-patch-middle-section">
                      <span class="title-card-patch"> Title: {{ patch.Patch_title }}</span><br>
                      <span class="description-card-patch">Description: {{ patch.Patch_description }} </span>
                    </span>
                    <span class="card-patch-right-section">
                      <span class="patch-created flx " style="justify-content:end">Complition Date:
                        {{ patch.Choose_date }}</span><br>

                    </span>
                  </span>
                </template>

              </a-alert>
              <br>
            </div>
          </div>
          <div v-else>
            <br><br>
            <a-empty description="No Patches available." />
          </div>


        </a-tab-pane>
        <a-tab-pane key="5">
          <template #tab>
            Contact Us
          </template>
          Tab Content 5
        </a-tab-pane>
      </a-tabs>
    </a-col>
  </a-row>
</template>
<script>
import axios from 'axios';

export default {
  name: "project_details",
  data() {
    return {
      contract: {},
      activeKey: "1"
    }
  },
  
created(){
    document.title = "Project Details - Crekaa";
  },
  head() {
    return {
      title: "Project Details - Crekaa",
      meta: [
        { hid: 'description', name: 'description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'author', name: 'author', content: "Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        
        // <!-- Facebook Open Graph tags -->
        { hid: 'og:url', property: 'og:url', content: "https://crekaa.com/" },
        { hid: 'og:type', property: 'og:type', content: "website" },
        { hid: 'og:title', property: 'og:title', content: "Crekaa, Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'og:description', property: 'og:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'og:image', property: 'og:image', content: "../../assets/logo.png" },
        // Add more meta tags as needed

        // <!-- Twitter Card tags -->
        { hid: 'twitter:card', name: 'twitter:card', content: "summary_large_image" },
        { hid: 'twitter:title', name: 'twitter:title', content: "Crekaa , Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'twitter:description', name: 'twitter:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'twitter:image', name: 'twitter:image', content: "../../assets/logo.png" },

      ]
    };
  },
  mounted() {
    this.projectDetails()

  },
  methods: {
    async projectDetails() {
      this.$store.commit('setIsLoading', true)

      const contract_id = this.$route.params.contract_id
      await axios
        .post("user-contract-details/" + contract_id)
        .then(response => {

          // console.log(response.data)
          this.contract = response.data
          // console.log(this.contract)
        })
        .catch(error => {
          console.log("something wents wrong")
        })
      this.$store.commit('setIsLoading', false)
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      const date = new Date(dateString);
      const formattedDate = date.toLocaleDateString('en-US', options);

      // Add suffix to day
      // const dayWithSuffix = this.addSuffixToDay(date.getDate());

      return ` ${formattedDate}`;
    },

  }
}
</script>
<style scoped>
.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  gap: 10px;
  /* margin: 16px; */
}

.image-item {
  width: 100%;
  height: auto;
  max-width: 100%;

  border-radius: 10px;
  display: block;
  object-fit: cover;
}


.header-space-between {
  display: flex;
  justify-content: space-between;
}

.text-contract-details {
  font-size: 14px;
  font-family: "Alkatra";
}</style>