<template>
    <br>
    <!-- <br><br> -->
    <div v-if="user_details.length">
        <div style="background: rgba(0, 255, 255, 0.162);min-height:100vh"
            v-if="user_details[0].user.is_superadmin && user_details[0].user.is_admin">
            <br>

            <!-- {{clientDetails.user_profile ? clientDetails.user_profile[0].background_picture : ''}} -->

            <div style="padding:10px " v-if="clientDetails.user_profile">
                <a-row>
                    <a-col :sm="24" :xs="24" :md="12" :lg="12">
                        <br><a-row>
                            <a-col :sm="24" :xs="24" :md="24" :lg="24">
                                <div style="display: flex;justify-content: center;">
                                    <img :src="'https://crekaa-2024.s3.amazonaws.com/media/' + clientDetails.user_profile[0].profile_background"
                                        style="width:100%;border-radius:10px;max-height:350px;height:auto;object-fit: cover;"
                                        alt="">
                                    <img :src="'https://crekaa-2024.s3.amazonaws.com/media/' + clientDetails.user_profile[0].profile_picture"
                                        class="card-img-top-mobile" alt="...">

                                </div>
                            </a-col>
                            <a-col :sm="24" :xs="24" :md="24" :lg="24" style="padding:0px;">
                                <br><a-card style="text-align: start;">

                                    <a-row>
                                        <a-col :sm="24" :xs="24" :md="12" :lg="12">
                                            <h3 style="font-size:14px;"><b>Name :</b><br> {{
                                                clientDetails.user_profile[0].user__first_name }} {{
        clientDetails.user_profile[0].user__last_name }}</h3>
                                            <h3 style="font-size:14px;"><b>Date Of Birth :</b><br> {{
                                                clientDetails.user_profile[0].user__dob }} </h3>
                                        </a-col>
                                        <a-col :sm="24" :xs="24" :md="12" :lg="12">
                                            <h3 style="font-size:14px;"><b>Country :</b> {{
                                                clientDetails.user_profile[0].country ?
                                                clientDetails.user_profile[0].country :
                                                "Unavailable" }} </h3>
                                            <h3 style="font-size:14px;"><b>State :</b> {{
                                                clientDetails.user_profile[0].state ? clientDetails.user_profile[0].state :
                                                "Unavailable"
                                            }}</h3>
                                            <h3 style="font-size:14px;"><b>City :</b> {{ clientDetails.user_profile[0].city
                                                ? clientDetails.user_profile[0].city : "Unavailable" }}
                                            </h3>
                                        </a-col>
                                    </a-row>

                                    <h3 style="font-size:14px;overflow-x: hidden;text-overflow: ellipsis;"><b>Email :</b>
                                        {{ clientDetails.user_profile[0].user__email }}</h3>
                                    <h3 style="font-size:14px;"><b>Address Line 1 :</b><br> {{
                                        clientDetails.user_profile[0].address_line_1 ?
                                        clientDetails.user_profile[0].address_line_1 : "Unavailable" }} </h3>
                                    <h3 style="font-size:14px;"><b>Address Line 2 :</b><br> {{
                                        clientDetails.user_profile[0].address_line_2 ?
                                        clientDetails.user_profile[0].address_line_2 : "Unavailable" }} </h3>
                                </a-card>
                            </a-col>
                        </a-row>
                    </a-col>
                    <a-col :sm="24" :xs="24" :md="12" :lg="12" style="padding:10px">
                        <a-card>
                            <template #title>
                                Permissions Granted
                            </template>
                            <div style="text-align: start;">


                                <br><b>date_joined</b> {{ clientDetails.user_profile[0].user__date_joined }}
                                <br><span style="font-size: 10px;">(this section is depends on for Server timings which will
                                    depends on hosting region of server deployement so always its not realtime)</span>
                                <br><b>last_login</b> {{ clientDetails.user_profile[0].user__last_login }}
                                <br><span style="font-size: 10px;">(this section is depends on for Server timings which will
                                    depends on hosting region of server deployement so always its not realtime)</span>

                                <br><b>is_admin</b> {{ clientDetails.user_profile[0].user__is_admin }}
                                <br><b>is_staff</b> {{ clientDetails.user_profile[0].user__is_staff }}
                                <br><b>is_active</b> {{ clientDetails.user_profile[0].user__is_active }}
                                <br><b>is_superadmin</b> {{ clientDetails.user_profile[0].user__is_superadmin }}
                                <!-- <br><b>user__is_blocked</b> {{clientDetails.user_profile[0].user__is_blocked}} -->
                                <a-row style="background-color: rgba(0, 255, 255, 0.125);margin-bottom:10px;padding:10px">
                                    <a-col :sm="12" :xs="12" :md="12" :lg="12">
                                        <b>User is blocked</b> {{ clientDetails.user_profile[0].user__is_blocked }} 
                                    </a-col>
                                    <a-col :sm="12" :xs="12" :md="12" :lg="12">
                                        <a-switch v-model:checked="user_is_blocked"
                                            @click="user_is_blocked ? grant_is_blocked() : is_unblock_blocked()" />
                                    </a-col>
                                </a-row>
                                <hr>
                              

                                <h1> Companey registration section </h1>
                                <b>register_as_companey</b> {{ clientDetails.user_profile[0].user__register_as_companey }}
                                <br><b>company_name</b> {{ clientDetails.user_profile[0].user__company_name ?
                                    clientDetails.user_profile[0].user__company_name : "Unavailable" }}
                                <br><b>company_description</b> {{ clientDetails.user_profile[0].user__company_description ?
                                    clientDetails.user_profile[0].user__company_description : "Unavailable" }}
                                <br><b>company_sector</b> {{ clientDetails.user_profile[0].user__company_sector ?
                                    clientDetails.user_profile[0].user__company_sector : "Unavailable" }}
                                <br><b>company_country</b> {{ clientDetails.user_profile[0].user__company_country ?
                                    clientDetails.user_profile[0].user__company_country : "Unavailable" }}
                                <hr>

                                <h1> Human Resources Section </h1>

                                <a-row style="background-color: rgba(0, 255, 255, 0.125);margin-bottom:10px;padding:10px">
                                    <a-col :sm="12" :xs="12" :md="12" :lg="12">
                                        <b>can_post_job</b> {{ clientDetails.user_profile[0].user__can_post_job }}                                     </a-col>
                                    <a-col :sm="12" :xs="12" :md="12" :lg="12">
                                        <a-switch v-model:checked="can_post_job"
                                            @click="can_post_job ? grant_can_post_job() : revoke_can_post_job()" />
                                    </a-col>
                                </a-row>
                                <a-row style="background-color:  rgba(0, 255, 255, 0.125);margin-bottom:10px;padding:10px;">
                                    <a-col :sm="12" :xs="12" :md="12" :lg="12">
                                        <b>can_update_job</b> {{ clientDetails.user_profile[0].user__can_update_job }}
                                    </a-col>
                                    <a-col :sm="12" :xs="12" :md="12" :lg="12">
                                        <a-switch v-model:checked="user__can_update_job"
                                            @click="user__can_update_job ? grant_can_update_job() : revoke_can_update_job()" />
                                    </a-col>
                                </a-row>
                                <a-row style="background-color:  rgba(0, 255, 255, 0.125);margin-bottom:10px;padding:10px;">
                                    <a-col :sm="12" :xs="12" :md="12" :lg="12">
                                        <b>can_hire</b> {{ clientDetails.user_profile[0].user__can_hire }}
                                    </a-col>
                                    <a-col :sm="12" :xs="12" :md="12" :lg="12">
                                        <a-switch v-model:checked="user__can_hire"
                                            @click="user__can_hire ? grant_can_hire() : revoke_can_hire()" />
                                    </a-col>
                                </a-row>
                                <hr>
                                <h1> Access to see the recived contact us requests via contact us page  </h1>
                                <a-row style="background-color:  rgba(0, 255, 255, 0.125);margin-bottom:10px;padding:10px;">
                                    <a-col :sm="12" :xs="12" :md="12" :lg="12">
                                        <b>can_see_contact_us_recived_Requests</b> {{ clientDetails.user_profile[0].user__can_see_contact_us_recived_Requests }}
                                    </a-col>
                                    <a-col :sm="12" :xs="12" :md="12" :lg="12">
                                        <a-switch v-model:checked="user__can_see_contact_us_recived_Requests"
                                            @click="user__can_see_contact_us_recived_Requests ? grant_user__can_see_contact_us_recived_Requests() : revoke_user__can_see_contact_us_recived_Requests()" />
                                    </a-col>
                                </a-row>




                            </div>
                        </a-card>
                    </a-col>
                </a-row>



            </div>
            <!-- {{ clientDetails }} -->
        </div>
        <div v-else>
            <h1>un-authenticated Access</h1>
        </div>
    </div>
</template>
<script>
import axios from 'axios'


export default {
    name: "userAccountDetailsAdminControll",
    data() {
        return {
            user_details: [],
            userEmail: '',
            isAuthenticated: false,
            clientDetails: [],

            // blocked User 
            user_is_blocked: false,
            // granting & revoking access purpose 
            can_post_job: false,
            user__can_update_job: false,
            user__can_hire: false,
            user__can_see_contact_us_recived_Requests:false,
        }
    },
    mounted() {
        const useremail = this.$route.params.crekaa_user_email
        this.userEmail = useremail

        this.$store.commit('initializeStore');

        if (localStorage.getItem("token")) {
            this.fetchUserProfileDetails()
        }
        this.isAuthenticated = localStorage.getItem("token") ? true : false;
        if (localStorage.getItem("token")) {
            this.isAuthenticated = true
        }
    },
    methods: {
        async grant_user__can_see_contact_us_recived_Requests(){
            this.$store.commit('setIsLoading', true)
            axios.defaults.headers.common['Authorization'] = "token " + localStorage.getItem("token");

            const formData = {
                email: this.userEmail
            }
            await axios
                .post("admin-people-list-grant-access/grant_can_see_contact_us_recived_Requests/", formData)

                .then((response) => {
                    this.fetchClientProfileDetails()
                }).catch((error) => {
                    console.log("error Occured while fetching User Data ")
                })
            this.$store.commit('setIsLoading', false)
        },
        async revoke_user__can_see_contact_us_recived_Requests(){
            this.$store.commit('setIsLoading', true)
            axios.defaults.headers.common['Authorization'] = "token " + localStorage.getItem("token");

            const formData = {
                email: this.userEmail
            }
            await axios
                .post("admin-people-list-grant-access/revoke_can_see_contact_us_recived_Requests/", formData)
                .then((response) => {
                    this.fetchClientProfileDetails()
                }).catch((error) => {
                    console.log("error Occured while fetching User Data ")
                })
            this.$store.commit('setIsLoading', false)
        },
        async grant_can_update_job() {
            this.$store.commit('setIsLoading', true)
            axios.defaults.headers.common['Authorization'] = "token " + localStorage.getItem("token");

            const formData = {
                email: this.userEmail
            }
            await axios
                .post("admin-people-list-grant-access/grant_can_update_job/", formData)

                .then((response) => {
                    // console.log("=============-------------============-====-=-===-=-=-=-=-")
                    // this.user_details = response.data
                    // console.log("grant_can_update_job")

                    this.fetchClientProfileDetails()
                }).catch((error) => {
                    console.log("error Occured while fetching User Data ")
                })
            this.$store.commit('setIsLoading', false)
        },
        async revoke_can_update_job() {
            this.$store.commit('setIsLoading', true)
            axios.defaults.headers.common['Authorization'] = "token " + localStorage.getItem("token");

            const formData = {
                email: this.userEmail
            }
            await axios
                .post("admin-people-list-grant-access/revoke_can_update_job/", formData)

                .then((response) => {
                    // console.log("=============-------------============-====-=-===-=-=-=-=-")
                    // this.user_details = response.data
                    console.log()

                    this.fetchClientProfileDetails("revoke_can_update_job")
                }).catch((error) => {
                    console.log("error Occured while fetching User Data ")
                })
            this.$store.commit('setIsLoading', false)
        },
        async grant_can_post_job() {
            this.$store.commit('setIsLoading', true)
            axios.defaults.headers.common['Authorization'] = "token " + localStorage.getItem("token");

            const formData = {
                email: this.userEmail
            }
            await axios
                .post("admin-people-list-grant-access/grant_can_post_job/", formData)

                .then((response) => {
                    // console.log("=============-------------============-====-=-===-=-=-=-=-")
                    // this.user_details = response.data
                    // console.log("grant_can_post_job")
                    // this.can_post_job=true

                    this.fetchClientProfileDetails()
                }).catch((error) => {
                    console.log("error Occured while fetching User Data ")
                })
            this.$store.commit('setIsLoading', false)
        },
        async revoke_can_post_job() {
            this.$store.commit('setIsLoading', true)
            axios.defaults.headers.common['Authorization'] = "token " + localStorage.getItem("token");

            const formData = {
                email: this.userEmail
            }
            await axios
                .post("admin-people-list-grant-access/revoke_can_post_job/", formData)

                .then((response) => {
                    // console.log("=============-------------============-====-=-===-=-=-=-=-")
                    // this.user_details = response.data
                    // console.log("revoke_can_post_job")
                    // this.can_post_job=true
                    this.fetchClientProfileDetails()
                }).catch((error) => {
                    console.log("error Occured while fetching User Data ")
                })
            this.$store.commit('setIsLoading', false)
        },
        async grant_can_hire() {
            this.$store.commit('setIsLoading', true)
            axios.defaults.headers.common['Authorization'] = "token " + localStorage.getItem("token");

            const formData = {
                email: this.userEmail
            }
            await axios
                .post("admin-people-list-grant-access/grant_can_hire/", formData)

                .then((response) => {
                    // console.log("=============-------------============-====-=-===-=-=-=-=-")
                    // this.user_details = response.data
                    // console.log("grant_can_hire")

                    this.fetchClientProfileDetails()
                }).catch((error) => {
                    console.log("error Occured while fetching User Data ")
                })
            this.$store.commit('setIsLoading', false)
        },
        async revoke_can_hire() {
            this.$store.commit('setIsLoading', true)
            axios.defaults.headers.common['Authorization'] = "token " + localStorage.getItem("token");

            const formData = {
                email: this.userEmail
            }
            await axios
                .post("admin-people-list-grant-access/revoke_can_hire/", formData)

                .then((response) => {
                    // console.log("=============-------------============-====-=-===-=-=-=-=-")
                    // this.user_details = response.data
                    // console.log("revoke_can_hire")

                    this.fetchClientProfileDetails()
                }).catch((error) => {
                    console.log("error Occured while fetching User Data ")
                })
            this.$store.commit('setIsLoading', false)
        },
    
        async grant_is_blocked() {
            this.$store.commit('setIsLoading', true)
            axios.defaults.headers.common['Authorization'] = "token " + localStorage.getItem("token");

            const formData = {
                email: this.userEmail
            }
            await axios
                .post("admin-people-list-grant-access/grant_is_blocked/", formData)

                .then((response) => {
                    // console.log(response.data)
                    // console.log("grant_is_blocked")
                    // this.user_is_blocked=false
                    this.fetchClientProfileDetails()
                  
                }).catch((error) => {
                    console.log("error Occured while fetching User Data ")
                })
            this.$store.commit('setIsLoading', false)
        },
        async is_unblock_blocked() {
            this.$store.commit('setIsLoading', true)
            axios.defaults.headers.common['Authorization'] = "token " + localStorage.getItem("token");

            const formData = {
                email: this.userEmail
            }
            await axios
                .post("admin-people-list-grant-access/is_unblock_blocked/", formData)

                .then((response) => {
                    // console.log("=============-------------============-====-=-===-=-=-=-=-")
                    // this.user_details = response.data
                    console.log(response.data)
                    // this.user_is_blocked=true
                    // console.log("is_unblock_blocked")

                    this.fetchClientProfileDetails()
                }).catch((error) => {
                    console.log("error Occured while fetching User Data ")
                })
            this.$store.commit('setIsLoading', false)
        },

        async fetchUserProfileDetails() {
            this.$store.commit('setIsLoading', true)
            axios.defaults.headers.common['Authorization'] = "token " + localStorage.getItem("token");

            await axios
                .post("user-details/")
                .then((response) => {
                    // console.log("=============-------------============-====-=-===-=-=-=-=-")
                    this.user_details = response.data

                   this.fetchClientProfileDetails()
                }).catch((error) => {
                    console.log("error Occured while fetching User Data ")
                })
            this.$store.commit('setIsLoading', false)

        },


        async fetchClientProfileDetails() {
            this.$store.commit('setIsLoading', true)
            axios.defaults.headers.common['Authorization'] = "token " + localStorage.getItem("token");

            const formData = {
                email: this.userEmail
            }
            await axios
                .post("admin-people-list-grant-access/post_get_selected_user_details/", formData)

                // .get("user-details/")
                .then((response) => {
                    // console.log("=============-------------============-====-=-===-=-=-=-=-")
                    // console.log( response.data)
                    this.clientDetails = response.data

                    this.can_post_job = this.clientDetails.user_profile[0].user__can_careers_post_job
                    this.user__can_update_job = this.clientDetails.user_profile[0].user__can_careers_update_job
                    this.user__can_hire = this.clientDetails.user_profile[0].user__can_careers_hire
                    this.user_is_blocked = this.clientDetails.user_profile[0].user__is_blocked
                    this.user__can_see_contact_us_recived_Requests = this.clientDetails.user_profile[0].user__can_see_contact_us_recived_Requests

                }).catch((error) => {
                    console.log("error Occured while fetching User Data ")
                })
            this.$store.commit('setIsLoading', false)

        }
    }
}
</script>
<style scoped>.card-img-top-mobile {
    width: 80px;
    height: 80px;
    position: absolute;
    /* left: 110%; */
    align-self: center;
    bottom: -10px;

    border-radius: 100%;
    /* margin: auto; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

}</style>