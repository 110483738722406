<template>
    <br><br>
    <section div style="min-height:100vh;padding:10px;line-height: 30px;font-size: 14px;">
        <h1 style="font-size:52px;line-height: 40px;">Privacy Policy</h1>
            <p style="font-size:22px;width:100%;max-width:800px;margin:auto">Welcome to CREKAA! We are committed to protecting your privacy. This Privacy Policy outlines the types of information we collect, how we use it, and the steps we take to ensure your information is kept secure.</p>
            <div class="sectional-text">
            <h1 class="title">Career Growth and Learning Opportunities at Crekaa Technologies</h1>
            <p class="description">At Crekaa Technologies, we believe that the growth of our employees is fundamental to the success of our company. We are committed to fostering an environment that not only recognizes talent but also nurtures it, ensuring that every team member has the opportunity to reach their full potential. Our comprehensive career growth and learning programs are designed to empower our staff with the skills and knowledge needed to excel in their roles and prepare for future challenges.</p>
        </div>

        <div class="sectional-text">
            <h2 class="title">Professional Development Programs</h2>
            <h3>1. Continuous Learning:</h3>
            <ul>
                <li>We offer access to a wide range of training programs, workshops, and seminars both in-house and through external providers. These resources cover various areas including technology, management, soft skills, and industry-specific knowledge.</li>
                <li>Employees have the opportunity to receive certifications and qualifications that can enhance their professional profiles and career prospects.</li>
            </ul>
            <h3>2. Leadership Development:</h3>
            <ul>
                <li>Our leadership development initiative is tailored to identify and groom potential leaders within the organization. This program includes mentorship with senior leaders, leadership courses, and hands-on projects that prepare employees for management roles.</li>
                <li>We encourage our staff to lead cross-departmental projects to gain diverse experience and showcase their leadership capabilities.</li>
            </ul>
            <h3>3. Technical Skills Enhancement:</h3>
            <ul>
                <li>Crekaa Technologies stays at the forefront of technological advancements. We provide specialized training in the latest software, tools, and programming languages relevant to our field.</li>
                <li>Employees are encouraged to participate in hackathons, coding challenges, and technology boot camps to sharpen their technical skills.</li>
            </ul>
        </div>

        <div class="sectional-text">
            <h2 class="title">Career Advancement</h2>
            <h3>1. Clear Career Pathways:</h3>
            <ul>
                <li>We provide clear career paths for all roles within Crekaa Technologies. Each path outlines the potential progressions and what is required at each stage, helping employees plan and strive for their next career move.</li>
                <li>Regular performance reviews and planning sessions help align individual career goals with the needs and opportunities within the company.</li>
            </ul>
            <h3>2. Internal Mobility:</h3>
            <ul>
                <li>Our internal job posting system encourages employees to apply for new positions and challenges within the company. This system supports career development by facilitating transfers between departments or geographical locations.</li>
                <li>We prioritize internal candidates for any open positions, believing that our existing talent deserves the first opportunity to grow.</li>
            </ul>
            <h3>3. Succession Planning:</h3>
            <ul>
                <li>We engage in active succession planning to ensure that all critical roles within the organization have identified backups and that employees are prepared to step into these roles when the time comes.</li>
                <li>This planning includes targeted development efforts to prepare high-potential employees for future leadership roles.</li>
            </ul>
        </div>

        <div class="sectional-text">
            <h2 class="title">Supportive Learning Environment</h2>
            <h3>1. Mentoring and Coaching:</h3>
            <ul>
                <li>We match employees with mentors who provide guidance, feedback, and support in their professional development journey.</li>
                <li>Coaching sessions are available to help employees develop specific competencies, overcome challenges, and make significant contributions to the company.</li>
            </ul>
            <h3>2. Collaborative Culture:</h3>
            <ul>
                <li>Our work environment encourages collaboration and knowledge sharing among peers. We believe that a lot can be learned from the diverse experiences and skills of our colleagues.</li>
                <li>Regular team meetings, project debriefs, and post-mortem analyses provide opportunities for learning and improvement.</li>
            </ul>
            <h3>3. Recognition and Rewards:</h3>
            <ul>
                <li>We recognize and reward employees who show a commitment to their personal development and make significant contributions to their teams.</li>
                <li>Recognition comes in various forms, including promotions, bonuses, and public acknowledgments during company meetings.</li>
            </ul>
        </div>

        <!-- <div class="footer"> -->
            <p>At Crekaa Technologies, your career growth and learning are integral parts of your journey with us. We are committed to providing you with the tools and opportunities to advance professionally and achieve your career goals, ensuring that as the company grows, you grow with us.</p>
        <!-- </div> -->
    </section>
</template>
<script>
export default {
    name:'privacyPolicy',
    created(){
    document.title = "Privacy Policy - Crekaa";
  },
  head() {
    return {
      title: "Privacy Policy - Crekaa",
      meta: [
        { hid: 'description', name: 'description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'author', name: 'author', content: "Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        
        // <!-- Facebook Open Graph tags -->
        { hid: 'og:url', property: 'og:url', content: "https://crekaa.com/" },
        { hid: 'og:type', property: 'og:type', content: "website" },
        { hid: 'og:title', property: 'og:title', content: "Crekaa, Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'og:description', property: 'og:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'og:image', property: 'og:image', content: "../../assets/logo.png" },
        // Add more meta tags as needed

        // <!-- Twitter Card tags -->
        { hid: 'twitter:card', name: 'twitter:card', content: "summary_large_image" },
        { hid: 'twitter:title', name: 'twitter:title', content: "Crekaa , Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'twitter:description', name: 'twitter:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'twitter:image', name: 'twitter:image', content: "../../assets/logo.png" },

      ]
    };
  },
}
</script>
<style scoped>
.sectional-text{
    text-align:start;
    
}
.title{
    font-size:20px;
    font-weight: bold;
    margin-bottom:10px;
}
.description{
    font-size:18px;
}
</style>