<template>
    <div class="book-order-startup">
        <br>
        <br>
        <div style="max-width:1300px;margin: auto;height: 100%;">

            <a-row>
                

                <a-col :sm="24" :xs="24" :md="6" :lg="6"
                    style="background-color: rgba(0, 255, 51, 0.1234);padding-top:50px;border-radius:5px">
<br>
                    <!-- <lottie-player src="https://assets3.lottiefiles.com/packages/lf20_fkhbopzv.json" background="transparent" speed="1" style="width:100%; height: 300px;" loop="" autoplay=""></lottie-player> -->
                    <lottie-player src="https://lottie.host/5f66148e-0f6b-42c6-b93e-f0cb928eda67/n2GqpdIaI5.json" background="transparent" speed="1" style="width:100%; height: 300px;" loop="" autoplay=""></lottie-player>
                    
                    <h1>
                        Create Custom Order
                    </h1>
                    <a-divider></a-divider>
                    <span> Create Custom Order </span>

                </a-col>
                <a-col :sm="24" :xs="24" :md="18" :lg="18">
                    <a-row>

                        <a-col :sm="24" :xs="24" :md="24" :lg="24" style="padding:20px">
                            <br>
                            <a-steps :current="current" :items="items" size="large"></a-steps>
                            <div class="steps-content">
                                <!-- Use v-if or v-show to conditionally render content for each step -->
                                <div v-if="current === 0">
                                    <!-- Content for the first step -->
                                    <div style="display:flex;justify-content:center;">
                                        <div style="text-align:start;width:80%">
                                            <!-- Your form fields and input components for the "My information" step -->
                                            <h1>Project Details </h1>

                                            <a-label style="text-align:start;font-size:14px;font-weight:bold"
                                                for="project_name">Project title </a-label>
                                            <br>
                                            <br>
                                            <a-input v-model:value="project_name"  style="width:100%;border-radius:10px;height:30px"
                                                name="project_name"></a-input>
                                            <br>
                                            <br>
                                            <a-label for="project_desc" style="font-size:14px;font-weight:bold">Project
                                                description</a-label>
                                            <br>
                                            <br>
                                            <a-textarea v-model:value="project_desc"  style="width:100%; height:200px;font-size:16px"
                                                name="project_desc"></a-textarea>
                                            <br> <br>
                                            <a-label for="file" style="font-size:14px;font-weight:bold">Project File
                                                ( Attachments Optional )</a-label>
                                            <br>
                                            <br>
                                            <div>
                                                <label for="file" ref="labelFileinput" class="label-fileinput"
                                                    style="font-weight:bold;font-size:12px;cursor:pointer">
                                                    <svg style="width: 15px; height: 15px;"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                        <path
                                                            d="M364.2 83.8c-24.4-24.4-64-24.4-88.4 0l-184 184c-42.1 42.1-42.1 110.3 0 152.4s110.3 42.1 152.4 0l152-152c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-152 152c-64 64-167.6 64-231.6 0s-64-167.6 0-231.6l184-184c46.3-46.3 121.3-46.3 167.6 0s46.3 121.3 0 167.6l-176 176c-28.6 28.6-75 28.6-103.6 0s-28.6-75 0-103.6l144-144c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-144 144c-6.7 6.7-6.7 17.7 0 24.4s17.7 6.7 24.4 0l176-176c24.4-24.4 24.4-64 0-88.4z" />
                                                    </svg>
                                                    &nbsp;Choose File
                                                </label>
                                                <input  type="file" name="project_file" id="file" @change="handleFileChange"
                                                    ref="fileInput" style="display: none" accept=".txt, .pdf" />

                                                <div id="info" v-show="display_information">
                                                    <p id="name"><b>File Name:</b> <span ref="nameSpan"></span></p>
                                                    <p id="size"><b>File Size:</b> <span ref="sizeSpan"></span></p>
                                                    <p id="type"><b>MIME Type:</b> <span ref="typeSpan"></span></p>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <br>
                                    <br>


                                </div>
                                <div v-else-if="current === 1">
                                    <!-- Content for the second step -->
                                    <div style="display:flex;justify-content:center;">
                                        <div style="text-align:start;width:80%">
                                            <!-- Your form fields and input components for the "My experience" step -->
                                            <h1>Client Details</h1>


                                            <br><br>
                                            <a-label for="client_name"
                                                style="font-size:14px;font-weight:bold;border-radius:10px;height:30px"
                                                class="label-title">Client Name</a-label> <br><br>
                                            <a-input v-model:value="client_name"  type="text" class='effect-input' name="client_name"
                                                style="font-size:14px;font-weight:bold;border-radius:10px;height:30px"
                                                required /><br><br>
                                            <a-label for="company name"
                                                style="font-size:14px;font-weight:bold;border-radius:10px;height:30px"
                                                class="label-title">Company Name</a-label><br><br>
                                            <a-input  v-model:value="company_name" type="text" required class='effect-input'
                                                style="font-size:14px;font-weight:bold;border-radius:10px;height:30px"
                                                name="company_name" /><br><br>
                                            <a-label for="" class="label-title"
                                                style="font-size:14px;font-weight:bold;border-radius:10px;height:30px">Address</a-label><br><br>

                                            <a-textarea required  v-model:value="address" type="text" class='effect-input' name="address"
                                                style="width:100%;height:100px;margin-bottom:0;"></a-textarea>
                                            <br>
                                            <br>
                                            <a-row>
                                                <a-col :sm="24" :xs="24" :md="12" :lg="12">
                                                    <a-label for="phone"
                                                        style="font-size:14px;font-weight:bold;border-radius:10px;height:30px"
                                                        class="label-title">Phone Number</a-label> <br><br>
                                                    <a-input  v-model:value="phone" type="number" class='effect-input' name="phone"
                                                        style="font-size:14px;font-weight:bold;border-radius:10px;height:30px"
                                                        required /><br><br>
                                                </a-col>

                                                <a-col :sm="24" :xs="24" :md="12" :lg="12">
                                                    <a-label for="email"
                                                        style="font-size:14px;font-weight:bold;border-radius:10px;height:30px"
                                                        class="label-title">Email</a-label> <br><br>
                                                    <a-input  v-model:value="email" type="mail" class='effect-input' name="email"
                                                        style="font-size:14px;font-weight:bold;border-radius:10px;height:30px"
                                                        required /><br><br>
                                                </a-col>
                                            </a-row>


                                        </div>
                                    </div>
                                    <br><br>
                                </div>
                                <div v-else-if="current === 2">
                                    <!-- Content for the fifth step -->
                                    
                                        <!-- Your form fields and input components for the "Review" step -->
                                        <h1>Review</h1>
       <div style="width:80%; text-align:start; margin:auto;padding:20px">
        <br><br>
           
           <h2>Project Details  </h2>
           <br>
           <span class="review-title">Project Name : </span><span class="review-content">   {{ project_name }}</span><br>
           <span class="review-title">Project Description : </span><span class="review-content">  {{ project_desc }}</span><br>
           <span class="review-title">Selected File : </span><span class="review-content">  {{ selectedFile ? selectedFile.name.substr(0, 30) + ".." : " Not Available" }}</span><br>
           <span class="review-title">Selected File Size: </span><span class="review-content">  {{ selectedFile ?  Math.round(selectedFile.size / 1024) + "KB" :" Not Available"  }}</span><br>
           <br><br>
           <h2>Client Details </h2><br>
           <span class="review-title">Client Name: </span><span class="review-content"> {{ client_name }}</span><br>
           <span class="review-title">Company Name: </span><span class="review-content"> {{ company_name }}</span><br>
           <span class="review-title">Address: </span><span class="review-content"> {{ address }}</span><br>
           <span class="review-title">Phone: </span> <span class="review-content">{{ phone }}</span><br>
           <span class="review-title">Email: </span> <span class="review-content">{{ email }}</span><br>
        </div>
                                        
        <br><br>
        <br><br>

                                    
                                </div>
                            </div>

                        </a-col>
                    </a-row>
                    <a-row style="display: flex;justify-content: end;">
                        <div class="steps-action" style="padding-right:20px">
                            <a-button v-if="current < steps.length - 1" type="primary" @click="next">Next</a-button>
                            <a-button v-if="current == steps.length - 1" type="primary"
                                @click="postbookrequest()">
                                Done
                            </a-button>
                            <a-button v-if="current > 0" style="margin-left: 8px" @click="prev">Previous</a-button>
                        </div>
                    </a-row><br><br>
                </a-col>
            </a-row>
<br>



        </div>
    </div>
</template>
  

  
<script>

import { message } from 'ant-design-vue';
import axios from 'axios'
import { InboxOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue';
export default {
    name: "book_plan_custom_mobile",
    components: [
        InboxOutlined
    ],
    data() {
        return {

            // form data 
            project_name:'',
            project_desc:'',
            selectedFile: null,

            client_name:'',
            company_name:'',
            address:'',
            phone:'',
            email:'',

            current: 0,
            display_information: false,
            steps: [
                {
                    title: 'Project Details',
                    content: 'Project Details',
                },
                {
                    title: 'Client Details',
                    content: 'Client Details',
                },
                {
                    title: 'Review',
                    content: 'Review Content',
                },
            ],
            items: [], // Initialize items as an empty array
            fileList: [],
        };
    },
    created() {
        // Initialize items here in the created lifecycle hook
        this.items = this.steps.map(item => ({
            key: item.title,
            title: item.title,
        }));

    },
    methods: {
        next() {
            if (this.current === 0) {
    if (this.project_name === '') {
        this.showErrorNotification('Project Name Is Empty!', 'The Project Name is missing! Please add the project name for your project or online business.');
    } else if (this.project_desc === '') {
        this.showErrorNotification('Project Description Is Empty!', 'The Project Description is missing! Please provide a brief description of your project.');
    } else {
        this.current++;
        return;
    }
}

if (this.current === 1) {
    if (this.client_name === '') {
        this.showErrorNotification('Client Name Is Empty!', 'The Client Name is missing! Please add the client name for your project or online business.');
    } else if (this.company_name === '') {
        this.showErrorNotification('Company Name Is Empty!', 'The Company Name is missing! Please add your company name for effective communication.');
    } else if (this.address === '') {
        this.showErrorNotification('Address Is Empty!', 'The Address is missing! Please add your address for effective communication.');
    } else if (this.phone === '') {
        this.showErrorNotification('Phone Number Is Empty!', 'The Phone Number is missing! Please add your phone number for effective communication.');
    } else if (this.email === '') {
        this.showErrorNotification('Email Is Empty!', 'The Email is missing! Please add your email for effective communication.');
    } else {
        this.current++;
        return;
    }
}
            if (this.current === 2){
                
this.current++;
return
            }
        },

        showErrorNotification(title, description) {
        notification['error']({
            message: title,
            description: description,
            placement: 'bottomRight',
        });
        this.current = (this.current === 0) ? 0 : 1;
    },
        prev() {
            this.current--;
        },

        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        handleFileChange(event) {

            const file = event.target.files[0];
            this.selectedFile = event.target.files[0];

            const formdata = new FormData();
            formdata.append("file", file);
            //   this.$refs.info.style.display = "block";
            this.display_information = true;
            if (file.name.length >= 30) {
                this.$refs.nameSpan.innerText = file.name.substr(0, 30) + "..";
            } else {
                this.$refs.nameSpan.innerText = file.name;
            }

            if (file.size >= 1073741824) {
                this.$refs.sizeSpan.innerText = Math.round(file.size / 1073741824) + "GB";
            } else if (file.size >= 1048576) {
                this.$refs.sizeSpan.innerText = Math.round(file.size / 1048576) + "MB";
            } else if (file.size >= 1024) {
                this.$refs.sizeSpan.innerText = Math.round(file.size / 1024) + "KB";
            } else {
                this.$refs.sizeSpan.innerText = Math.round(file.size) + "B";
            }

            this.$refs.typeSpan.innerText = file.type !== "" ? file.type : "Unknown";

            if (file.name.length >= 30) {
                this.$refs.labelFileinput.innerText = "Chosen : " + file.name.substr(0, 30) + "..";
            } else {
                this.$refs.labelFileinput.innerText = "Chosen : " + file.name;
            }

            const ext = event.target.value.split(".").pop().toLowerCase();
            if (["php", "html"].includes(ext)) {
                this.$refs.info.style.display = "none";
                this.$refs.label.innerText = "Choose File";
                event.target.value = "";
                alert("This file extension is not allowed!");
            }
        },


        async postbookrequest(){
            this.$store.commit('setIsLoading',true)
            const formData = {
                project_name:this.project_name,
                project_description:this.project_desc,
                project_file:this.selectedFile,
                client_name:this.client_name,
                company_name:this.company_name,
                address:this.address,
                phone:this.phone,
                mail:this.email,
            }
            await axios .post("order-custom-mobile/", formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
})
                    .then(response => {
                       
                        // console.log("=======================log========================");
                        // console.log(response.data);
                        //    const  toPath =this.$route.query.to || '/' // home path
                        notification['success']({
                            message: "Custom Website Order Placed Successfully",
                            description: "Thank you for choosing our services! Your custom mobile app order has been confirmed. We appreciate your business. Stay tuned with us, and we will reach out to you shortly using the details you provided. Feel free to contact us if you have any questions. We look forward to working with you!",
                            placement: 'bottomRight',
                        });
                        this.$router.push('/my-dashboard')
                    })
                    .catch(error => {
                       console.log(JSON.stringify(error.response.data))
                        console.log(JSON.stringify(error))
                    })

this.$store.commit('setIsLoading',false)
            
            
        },

        // confirm onclick apply 
        confirm() {
            message.success('Click on Yes');
        },
        cancel() {
            console.log();
            message.error('Click on No');
        }
    },
};
</script>



<style scoped>
.steps-content {
    margin-top: 16px;
    border: 1px dashed #686868;
    border-radius: 6px;
    background-color: var(--card-color);
    min-height: 200px;
    text-align: center;
    padding-top: 80px;
}

.steps-action {
    /* margin-top: 10px; */
}

[data-theme='dark'] .steps-content {
    background-color: #2f2f2f;
    border: 1px dashed #404040;
}


.review-title{
    font-size:16px;
    font-weight:bold;
    margin-bottom:20px
}
.review-content{
    font-size:16px;
    margin-bottom:20px
}
.label-fileinput {
    background: rgba(128, 128, 128, 0.532);

    padding: 5px;
    border: 2px solid rgb(0, 255, 51, 0.3);
    border-radius: 10px;
}

.label-fileinput:hover {
    background: rgba(128, 128, 128, 0.432);

    border: 2px solid rgb(0, 255, 51, 0.6);
    border-radius: 10px;
}

input[type=file] {
    display: none;
}

#info {
    /* display:none; */
    margin: 10px;
    padding: 5px;
    width: 400px;
    height: auto;
    overflow: hidden;
    background: var(--card-color);
    border: 1px solid var(--devide-color);
    border-radius: 5px;
}

#size span,
#type span,
#name span {
    /* color:rgb(0, 255, 51);; */
}

#size,
#type,
#name {
    font-size: 16px;
    margin-bottom: 0px;
    margin-top: 0px;
}

.image-startup {
    width: 400px;
}

.row-odd {
    display: flex;
    gap: 20px;
}

@media screen and (min-width: 0px) and (max-width: 1023px) {


    .form-card {
        display: block;
        position: absolute;
        width: 95%;
        height: 500px;
        transform: translate(-50%, -50%);
        background: rgba(0, 255, 51, 0.15);
        border-radius: 4px;
        border: 1px solid #ddd;
        backdrop-filter: blur(5px);
    }

    .form-card-left {
        width: 95%;
        margin-bottom: 20px;
        background: transparent;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .form-body {
        padding: 25px;
        flex-grow: 1;
        height: 620px;
        background: rgba(0, 255, 51, 0.5);

    }

    #info {
        display: none;
        margin: 10px;
        padding: 5px;
        max-width: 300px;
        height: 100px;
        overflow: hidden;
        background: #FFFFFF;
        border-radius: 5px;
    }

    .row-odd {
        flex-direction: column
    }

    .row-odd .label-title {
        font-size: 14px
    }

    .row-odd .label-fileinput {
        font-size: 14px;
        max-width: 200px;

    }

    .col--0 {
        flex: 100%;
    }


    .tab-nav {
        margin-bottom: -700px;
        padding-right: 0px;
    }

    .tb-next,
    .tb-prev {
        color: white;
        background: rgba(0, 255, 51, 0.44);
        border: 1px rgb(0, 255, 51);

    }

    .tb-next:hover,
    .tb-prev:hover {
        border-color: rgb(0, 255, 51);
        background: rgb(0, 255, 51, 0.44);
        color: white;
    }

    .submitbtn {
        padding: 6px 15px;
        background: rgba(0, 255, 51, 0.7);


    }

    .submitbtn:hover {
        padding: 6px 15px;
        background: rgb(0, 255, 51, .8);
        border: 1px solid white;
        border-radius: 4px;
        color: white;
        cursor: pointer;

    }


    .row-offstr {
        display: flex;
        flex: 45%;
        gap: 10%
    }
}</style>