<template>
    <main>
        <section>
            <div class="containter-22">
                <br>
                <br>

                <h4 style="text-align: center;">How & What UI We Grant You For Custom plans Of Platform With Crekaa.UI And
                    Technologies.</h4>
                <div class="grid-container">
                    
                    <img_dashboard v-for="image in images" :key="image.key" v-bind:image="image" />

                </div>
            </div>

            <div class="home blog mega-menu-primary">
                <div class="main_inner">
                    <div id="primary" class="site-content">
                        <div id="content" role="main">
                            <div class="title-dashboards-section">
                                <h5 style="text-align: center;">We Grant the Interactive Dashboards as per buisness needs
                                    and requirements </h5>
                            </div>
                            <div class="home_service_single_wrapper">
                                <div class="service_single_wrap col-md-3 col-xs-12">
                                    <div class="service_new_single fs_block">
                                        <div class="col-xs-12 padding_0">
                                            <div class="col-sm-3 col-md-4 col-xs-4 padding_0">
                                                <div class="service_new_left">

                                                    <ion-icon name="layers-outline"></ion-icon>
                                                </div>
                                            </div>
                                            <div class="col-sm-9 col-md-8 col-xs-8 padding_0">
                                                <div class="service_new_right">
                                                    <h3 itemprop="headline">
                                                        Respresentative Dashboards Will Be Always A Part Of Success
                                                    </h3>
                                                    <div class="service_new_content" itemprop="text">
                                                        <p>
                                                            In today's fast-paced business world, staying on top of critical
                                                            data and insights is crucial for success. That's where
                                                            representative dashboards come into play, providing a
                                                            comprehensive overview of your business's performance and
                                                            enabling you to make informed decisions that drive growth and
                                                            efficiency.

                                                        </p>
                                                    </div>
                                                    <!-- <a class="service_new_link" href="https://hackrepair.com/web-designing/"> <span>READ MORE</span> </a> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="service_single_wrap col-md-3 col-xs-12">
                                    <div class="service_new_single sc_block">
                                        <div class="col-xs-12 padding_0">
                                            <div class="col-sm-3 col-md-4 col-xs-4 padding_0">
                                                <div class="service_new_left">
                                                    <ion-icon name="trending-up-outline"></ion-icon>
                                                </div>
                                            </div>
                                            <div class="col-sm-9 col-md-8 col-xs-8 padding_0">
                                                <div class="service_new_right">
                                                    <h3 itemprop="headline">
                                                        Tracking Product Performance & Improving Business Efficiency:
                                                    </h3>
                                                    <div class="service_new_content" itemprop="text">
                                                        <p>
                                                            Our representative dashboards offer a powerful dual benefit,
                                                            enhancing both your product performance tracking and overall
                                                            business efficiency. By centralizing and visualizing data from
                                                            various departments and processes, you gain a holistic view of
                                                            your operations. This enables you to identify bottlenecks,
                                                            streamline workflows, and optimize resource allocation, leading
                                                            to cost savings and enhanced productivity.
                                                        </p>
                                                    </div>
                                                    <!-- <a class="service_new_link" href=""> <span>READ MORE</span> </a> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="service_single_wrap col-md-3 col-xs-12">
                                    <div class="service_new_single th_block">
                                        <div class="col-xs-12 padding_0">
                                            <div class="col-sm-3 col-md-4 col-xs-4 padding_0">
                                                <div class="service_new_left">
                                                    <ion-icon name="checkmark-done-outline"></ion-icon>
                                                </div>
                                            </div>
                                            <div class="col-sm-9 col-md-8 col-xs-8 padding_0">
                                                <div class="service_new_right">
                                                    <h3 itemprop="headline">
                                                        Responsive, Personalized & Secured Service
                                                    </h3>
                                                    <div class="service_new_content" itemprop="text">
                                                        <p>
                                                            Security is of utmost importance to us, and we go the extra mile
                                                            to safeguard your valuable data. Our dashboard development
                                                            includes four layers of security services, with a "pay as per
                                                            use" model. This means you have the flexibility to choose the
                                                            best level of security that suits your requirements and budget.
                                                            With our robust security measures in place, you can have peace
                                                            of mind knowing your data is protected from potential threats.
                                                        </p>
                                                    </div>
                                                    <!-- <a class="service_new_link" href="https://hackrepair.com/seo/"> <span>READ MORE</span> </a> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="service_single_wrap col-md-3 col-xs-12">
                                    <div class="service_new_single">
                                        <div class="col-xs-12 padding_0">
                                            <div class="col-sm-3 col-md-4 col-xs-4 padding_0">
                                                <div class="service_new_left">
                                                    <img alt="5 Star Awards"
                                                        src="https://hackrepair.com/wp-content/uploads/2017/05/5-star-awards-2.png">
                                                </div>
                                            </div>
                                            <div class="col-sm-9 col-md-8 col-xs-8 padding_0">
                                                <div class="service_new_right">
                                                    <h3 itemprop="headline">
                                                        We Offers Even More Services!
                                                    </h3>
                                                    <div class="service_new_content" itemprop="text">
                                                        <p>
                                                            • We upgrade your platforms in terms of performance.<br />
                                                            • We have our own designed technologies that will help you to
                                                            reachout the customer as per your need and convineance.<br />
                                                            • Most important as we are going with pay as per need model you
                                                            had to pay for the modules that you are need.<br />
                                                            • We will take backup, maintainance and secur system.<br />
                                                            • We grant you the full fledged development for your needs and
                                                            your buisness requirements.<br />
                                                            • And much more!
                                                        </p>
                                                    </div>
                                                </div>
                                                <a class="service_new_link" href="{% url 'home' %}"> <span>READ MORE</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </section>

    </main>
</template>
<script>

import img_dashboard from '@/components/dashboard/img_dashboard.vue'

export default {
    data() {
        return {
            images: [
                { 'key': 1, 'src': "https://crekaa-2024.s3.amazonaws.com/static/mobile%20project/%20(1).webp" },
                { 'key': 2, 'src': "https://crekaa-2024.s3.amazonaws.com/static/mobile%20project/%20(2).webp" },
                { 'key': 3, 'src': "https://crekaa-2024.s3.amazonaws.com/static/mobile%20project/%20(3).webp" },
                { 'key': 4, 'src': "https://crekaa-2024.s3.amazonaws.com/static/mobile%20project/%20(4).webp" },
                { 'key': 5, 'src': "https://crekaa-2024.s3.amazonaws.com/static/mobile%20project/%20(5).webp" },
                { 'key': 6, 'src': "https://crekaa-2024.s3.amazonaws.com/static/mobile%20project/%20(6).webp" },
                { 'key': 7, 'src': "https://crekaa-2024.s3.amazonaws.com/static/mobile%20project/%20(7).webp" },
                { 'key': 8, 'src': "https://crekaa-2024.s3.amazonaws.com/static/mobile%20project/%20(8).webp" },
                { 'key': 9, 'src': "https://crekaa-2024.s3.amazonaws.com/static/mobile%20project/%20(9).webp" },
                { 'key': 10, 'src': "https://crekaa-2024.s3.amazonaws.com/static/mobile%20project/%20(10).webp" },
                { 'key': 11, 'src': "https://crekaa-2024.s3.amazonaws.com/static/mobile%20project/%20(11).webp" },
                { 'key': 12, 'src': "https://crekaa-2024.s3.amazonaws.com/static/mobile%20project/%20(12).webp" },
                { 'key': 13, 'src': "https://crekaa-2024.s3.amazonaws.com/static/mobile%20project/%20(13).webp" },
                { 'key': 14, 'src': "https://crekaa-2024.s3.amazonaws.com/static/mobile%20project/%20(14).webp" },
                { 'key': 15, 'src': "https://crekaa-2024.s3.amazonaws.com/static/mobile%20project/%20(15).webp" },
                { 'key': 16, 'src': "https://crekaa-2024.s3.amazonaws.com/static/mobile%20project/%20(16).webp" },
                { 'key': 17, 'src': "https://crekaa-2024.s3.amazonaws.com/static/mobile%20project/%20(17).webp" },
                { 'key': 18, 'src': "https://crekaa-2024.s3.amazonaws.com/static/mobile%20project/%20(18).webp" },
                { 'key': 19, 'src': "https://crekaa-2024.s3.amazonaws.com/static/mobile%20project/%20(19).webp" },
                { 'key': 20, 'src': "https://crekaa-2024.s3.amazonaws.com/static/mobile%20project/%20(20).webp" },
                { 'key': 21, 'src': "https://crekaa-2024.s3.amazonaws.com/static/mobile%20project/%20(21).webp" },
                { 'key': 22, 'src': "https://crekaa-2024.s3.amazonaws.com/static/mobile%20project/%20(22).webp" },
                { 'key': 23, 'src': "https://crekaa-2024.s3.amazonaws.com/static/mobile%20project/%20(23).webp" },
                { 'key': 24, 'src': "https://crekaa-2024.s3.amazonaws.com/static/mobile%20project/(24)%20.png" },
            ]
        }
    },
    name: "dashboards",
    
created(){
    document.title = "Dashboards - Crekaa";
  },
  head() {
    return {
      title: "Dashboards - Crekaa",
      meta: [
        { hid: 'description', name: 'description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'author', name: 'author', content: "Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        
        // <!-- Facebook Open Graph tags -->
        { hid: 'og:url', property: 'og:url', content: "https://crekaa.com/" },
        { hid: 'og:type', property: 'og:type', content: "website" },
        { hid: 'og:title', property: 'og:title', content: "Crekaa, Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'og:description', property: 'og:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'og:image', property: 'og:image', content: "../../assets/logo.png" },
        // Add more meta tags as needed

        // <!-- Twitter Card tags -->
        { hid: 'twitter:card', name: 'twitter:card', content: "summary_large_image" },
        { hid: 'twitter:title', name: 'twitter:title', content: "Crekaa , Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'twitter:description', name: 'twitter:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'twitter:image', name: 'twitter:image', content: "../../assets/logo.png" },

      ]
    };
  },
    components: {
        img_dashboard
    },

}
</script>
<style scoped>
/* Clearing floats */

.site-content {
    margin: 24px 0 0;
    background: #fff;
}

.service_single_wrap {
    box-sizing: border-box;
    float: left;
    padding: 0 5px;
    width: 25%;
}

.service_new_single::after {
    background: #c2c2c2 none repeat scroll 0 0;
    content: close-quote;
    height: 100%;
    left: 38px;
    position: absolute;
    transition: all 0.3s ease 0s;
    width: 2px;
}

.service_new_single {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    display: inline;
    float: left;
    margin-bottom: -1px;
    position: relative;
    transition: all 0.3s ease 0s;
    width: 100%;
}

.service_new_left {
    background: #ffffff none repeat scroll 0 0;
    border: 4px solid;
    border-radius: 50%;
    box-sizing: border-box;
    color: #AAAAAA;
    display: inline;
    float: left;
    font-size: 40px;
    margin-left: 1%;
    margin-right: 1%;
    position: relative;
    transition: all 0.3s ease 0s;
    width: 75px;
    z-index: 1;
    text-align: center;
    height: 75px;
}

.home_service_single_wrapper .service_new_left i {
    line-height: 67px;
}

.service_new_right {
    display: inline;
    float: left;
    padding: 20px 0 35px 1px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.service_new_right h3 {
    font-size: 18px;
    font-weight: normal;
    line-height: 1.5;
    padding-bottom: 15px;
    margin-bottom: 0 !important;
}

.service_new_content p {
    color: #666666;
    float: left;
    font-size: 15px;
    line-height: 22px;
    text-align: left;
    width: 100%;
}

.service_new_single.fs_block .service_new_link {
    border: 1px solid #46adfa;
    color: #46adfa;
}

.service_new_single.fs_block:hover::after {
    background: #46adfa none repeat scroll 0 0;
}

.service_new_single.fs_block:hover,
.service_new_single.fs_block:hover .service_new_left {
    color: #46AE01;
}

.service_new_single.fs_block:hover {
    border-bottom: 1px solid #46adfa;
}

.service_new_single.fs_block:hover .service_new_right h3 {
    color: #46adfa;
}

.service_new_single.sc_block .service_new_link {
    border: 1px solid #65c653;
    color: #65c653;
}

.service_new_single.sc_block:hover::after {
    background: #65c653 none repeat scroll 0 0;
}

.service_new_single.sc_block:hover,
.service_new_single.sc_block:hover .service_new_left {
    color: #65c653;
}

.service_new_single.sc_block:hover {
    border-bottom: 1px solid #65c653;
}

.service_new_single.sc_block:hover .service_new_right h3 {
    color: #65c653;
}

.service_new_single.th_block .service_new_link {
    border: 1px solid #ff5d60;
    color: #ff5d60;
}

.service_new_single.th_block:hover::after {
    background: #ff5d60 none repeat scroll 0 0;
}

.service_new_single.th_block:hover,
.service_new_single.th_block:hover .service_new_left {
    color: #ff5d60;
}

.service_new_single.th_block:hover {
    border-bottom: 1px solid #ff5d60;
}

.service_new_single.th_block:hover .service_new_right h3 {
    color: #ff5d60;
}

.service_new_single:hover .service_new_right h3 {
    color: #31b3ae;
}

.service_new_single .service_new_link {
    border: 1px solid #31b3ae;
    bottom: 0;
    color: #31b3ae;
    opacity: 0;
    padding: 5px;
    position: absolute;
    right: 10px;
    transition: all 0.3s ease 0s;
    line-height: 16px;
}

.service_new_single .service_new_link>span {
    font-size: 13px;
}

.service_new_single:hover::after {
    background: #31b3ae none repeat scroll 0 0;
}

.service_new_single:hover .service_new_left {
    color: #31b3ae;
}

.service_new_single:hover .service_new_link {
    opacity: 1;
}

.service_new_single:hover {
    border-bottom: 1px solid #31b3ae;
    margin-top: -20px;
    opacity: 1;
    padding-bottom: 19px;
}

.service_new_single:hover {
    animation: 1s ease 0s normal none 1 running expandOpen;
    color: #31b3ae;
    margin-top: -30px;
}

/* End of home page style */

#primary {
    width: 100%;
}

#content h1 {
    margin-bottom: 20px;
    margin-top: 10px;
}

#content h2 {
    margin-bottom: 35px;
    margin-top: 10px;
}

#content h3 {
    margin-bottom: 20px;
}

.padding_0 {
    padding: 0 !important;
}

.col-sm-3,
.col-md-3,
.col-xs-4,
.col-md-4,
.col-xs-8,
.col-md-8,
.col-sm-9,
.col-xs-12 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}

.col-xs-4,
.col-xs-8,
.col-xs-12 {
    float: left;
}

.col-xs-12 {
    width: 100%;
}

.col-xs-8 {
    width: 66.66666667%;
}

.col-xs-4 {
    width: 33.33333333%;
}

/* Single Product social icons Gaurang */
/* End of social icon design */

.fa {
    font-family: FontAwesome !important;
}


/* --------- mega menu start ------------- */
.mega-menu-primary {
    background: #FFFFFF !important;
}

@media screen and (min-width: 600px) {
    .site-content {
        float: left;
        width: auto;
    }
}

/* Minimum width of 960 pixels. */
@media screen and (min-width: 960px) {
    body {
        background-color: #e6e6e6;
    }
}

/* =Print ----------------------------------------------- */
@media print {
    body {
        background: none !important;
        color: #000;
        font-size: 10pt;
    }

    a {
        text-decoration: none;
    }

    .wrapper {
        border-top: none;
        box-shadow: none;
    }

    .site-content {
        margin: 0;
        width: auto;
    }

    /* Comments */
}

/*------------------------------------------------------- Header Style sheet ----------------------------------------------*/
/*-------------------------------------------------------- Menu Style sheet ---------------------------------*/
/*---------------------------------------- slider_setting Created -------------------------------------------*/
/*------------------------------- Home Page Welcome stylesheet --------------------------------------------------------------*/
.main_inner {
    max-width: 1140px;
    width: 100%;
    margin: 0px auto;
    float: left;
}


#content {
    float: left;
    max-width: 1140px;
    width: 100%;
}

.home .wrapper,
.home .main_inner {
    max-width: 100%;
}

.home #content {
    float: none;
    margin: 0 auto;
}

.home_service_single_wrapper {
    float: left;
    width: 100%;
    text-align: center;
}

@media screen and (max-width: 480px) {
    /* feed_table */
}

@media screen and (max-width: 360px) {
    #content {
        margin-top: 10px;
    }
}



.home_service_single_wrapper a {
    color: #FFFFFF;
    text-decoration: none;
    font-size: 20px;
}

.home_service_single_wrapper {
    padding-bottom: 10px;
    padding-top: 10px;
}

@media screen and (max-width: 1140px) {
    .home_service_single_wrapper {
        padding-bottom: 30px;
    }
}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 800px) {
    .main_inner {
        width: 100%;
        padding: 1%;
    }

    .wrapper {
        width: auto;
    }

    .main_inner {
        padding: 0;
    }

    .site-content {
        float: none;
        margin: 0 auto;
        width: 96%;
    }
}

@media screen and (max-width: 768px) {
    .home_head_text {
        font-size: 24px;
    }
}

@media screen and (max-width: 480px) {
    #content {
        margin-top: 15px;
    }

    .home_service_single_wrapper {
        padding-top: 10px;
    }
}

@media screen and (max-width: 360px) {
    .main_inner {
        width: 100%;
    }

    #content {
        width: 100%;
        margin: 0 auto;
        float: none !important;
    }

    .home_head_text {
        font-size: 20px;
        line-height: normal;
        float: left;
        width: 100%;
    }
}

@media screen and (max-width: 320px) {
    .main_inner {
        width: 100%;
    }

    #content {
        margin-top: 10px;
    }
}

@media (min-width: 768px) {

    .col-sm-3,
    .col-sm-9 {
        float: left;
    }

    .col-sm-9 {
        width: 75%;
    }

    .col-sm-3 {
        width: 25%;
    }
}

@media (min-width: 992px) {

    .col-md-3,
    .col-md-4,
    .col-md-8 {
        float: left;
    }

    .col-md-8 {
        width: 66.66666667%;
    }

    .col-md-4 {
        width: 33.33333333%;
    }

    .col-md-3 {
        width: 25%;
    }
}

@media (max-width: 992px) {
    .service_single_wrap {
        margin-bottom: 50px;
    }

    .home_service_single_wrapper .service_single_wrap:last-child {
        margin-bottom: 0;
    }
}

@media (min-width: 801px) and (max-width: 1140px) {
    .service_new_right {
        padding-left: 5px;
    }
}

@media (min-width: 481px) and (max-width: 990px) {
    .service_single_wrap {
        width: 50% !important;
    }

    .service_new_right h3 {
        min-height: 54px;
    }

    .home_service_single_wrapper .service_single_wrap:nth-child(3) {
        clear: both;
    }
}

@media only screen and (max-width: 767px) {

    figure.alignleft,
    img.alignleft,
    figure.alignright,
    img.alignright {
        float: none;
    }
}



.grid-container {
    /* background-color: white; */
    display: grid;
    grid-gap: 20px;
    padding: 20px;
}

.containter-22 {
    --wp--preset--color--gradient-container-1-one: #4c00ff3b;
    --wp--preset--color--gradient-container-1-two: #00c1ff47;
    --wp--preset--color--gradient-container-1-three: #00e4ff3b;
    --wp--preset--color--gradient-container-1-four: #0039FF3D;
    --wp--preset--color--gradient-container-1-five: #5800ff4a;
    --wp--preset--color--gradient-container-1-six: #ff00004a;
    --wp--preset--gradient--primary-container-1:
        radial-gradient(0% 0% at 0% 50%, var(--wp--preset--color--gradient-container-1-one) 1%, #FF000000 99%),
        radial-gradient(33% 33% at 41% 80%, var(--wp--preset--color--gradient-container-1-two) 1%, #FF000000 99%),
        radial-gradient(15% 15% at 71% 43%, var(--wp--preset--color--gradient-container-1-three) 1%, #FF000000 99%),
        radial-gradient(15% 15% at 71% 90%, var(--wp--preset--color--gradient-container-1-six) 1%, #FF000000 99%),
        radial-gradient(0% 20% at 0% 0%, var(--wp--preset--color--gradient-container-1-four) 1%, #FF000000 99%),
        radial-gradient(40% 40% at 89% 30%, var(--wp--preset--color--gradient-container-1-five) 1%, #FF000000 99%);


    background: var(--wp--preset--gradient--primary-container-1);
}

.img-db {
    width: 100%
}

/* For screens up to 480px */
@media screen and (max-width: 480px) {
    .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }
}

/* For screens between 481px and 768px */
@media screen and (min-width: 481px) and (max-width: 768px) {
    .grid-container {
        grid-template-columns: repeat(2, 2fr);
    }
}

/* For screens between 769px and 1024px */
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .grid-container {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}

/* For screens larger than 1024px */
@media screen and (min-width: 1025px) {
    .grid-container {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}</style>