<template>
    
    <a-card style="margin-left:5px;margin-right: 5px;margin-top: 3px;;border-radius:5px;text-align:start">
        <div style="display: flex;">
            <img :src="this.$store.state.userDetils.length ? this.$store.state.userDetils[0].profile_picture :'' " style="
                                                    width:50px;
                                                    margin-top:5px;
                                                    padding:0;
                                                    height:50px;
                                                    border:2px solid grey ;
                                                    border-radius:50px" alt="Avatar"
                                                    />
            <div class="card-content">
                <h2> {{this.$store.state.userDetils.length ? (this.$store.state.userDetils[0].user.first_name + " " +this.$store.state.userDetils[0].user.last_name) :''}}</h2>
                <span style="display: flex;">
                
                    <ion-icon  style=" height:20px;width:20px;font-size: 15px;vertical-align: middle; color: rgb(79, 195, 247);" name="checkmark-done-outline"></ion-icon>
                    &nbsp;
                    <p style="font-size: 12px; overflow: hidden; white-space: nowrap; width:100%;max-width: 200px; text-overflow: ellipsis;">Card content content content content content content content </p>

                </span>

            </div>
        </div> 
    </a-card>

</template>
<script>
export default {
    name: "card_connections"
}
</script>
<style scoped>

</style>