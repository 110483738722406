<template>
    <a-button type="primary" @click="showModal" style="border-radius:100%"><template #icon>
        <ion-icon name="add-circle-outline" style="font-size: 22px;"></ion-icon>
    </template></a-button>
        <a-modal v-model:open="open" title="Create Job Work Area" :confirm-loading="confirmLoading" @ok="handleOk">
            
                
    <!-- <h3> Select The Work Areas</h3> -->
<!-- {{ work_area_name }} -->
<a-select  v-model:value="work_area_name" style="width:100%" >
    <a-select-option :value="wa" v-for="wa in wa_array">{{wa}}</a-select-option>
</a-select>

        </a-modal>
    </template>
    <script>
    
    
import { notification } from 'ant-design-vue'
import axios from 'axios'

    export default{
        name:"createWorkAreaJobCareers",
        data(){
            return {
                modalText: 'Content of the modal',
                open: false,
                confirmLoading: false,

                wa_array:[
                "Analytics, Data, & Research", "Business Operations",
                    "Administrative",
                    "Finance",
                    "Legal/Risk",
                    "UI Design",
                    "Graphic Design",
                    "Management Consulting",
                    "Marketing",
                    "Product Management & Innovation",
                    "Talent & Human Resources",
                    "Technology & Engineering",
                    "Civil Engineer (What is your expertise)",
                    "Computer Engineer (What is your major)",
                    "Human Resource",
                    "Front Desk Assistant",
                    "Secretary",
                    "Accounting Secretary",
                    "Communication Manager",
                ],
                work_area_id:'',
                work_area_name:'',
                job_num:'',
            }
        }, 
          mounted(){
            this.job_num=this.job_number
        }, 
         props:{
            job_number:Object,
        },
        methods:{
            
                showModal() {
                this.open = true;
                },
                
                async handleOk () {
                this.confirmLoading = true;
                const formData={
                    name_wa:this.work_area_name
                }
                await axios
        .post("update-job-details-career-crekaa-admin/"+"create_work_area/"+this.job_number,formData)
        .then(response => {
            notification['success']({
                    message: 'Careers Job Work Area has been successfull ',
                    description:'Careers job employement type has been updated successfully, You just need to refresh update page.',
                    placement: 'bottomRight',
                });
        })
        .catch(error => {
            console.log(error)
                notification['error']({
                    message: 'Unable to update Job Work Area status Type',
                    // description:'Unable To Fetch User Details...',
                    placement: 'bottomRight',
                });

                console.log("error Found unable to update")                        
                console.log(JSON.stringify(error.response.data))
                    console.log(JSON.stringify(error))
        })

        this.confirmLoading = false;
            this.open = false;
            window.location.reload()
            // this.confirmLoading = true;
            // setTimeout(() => {
            //     this.open = false;
            //     this.confirmLoading = false;
            // }, 2000);
            },

    
        }
    }
    </script>
    <style scoped>
    
    </style>
    
    
    