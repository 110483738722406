<template>
  <!-- <nav> -->
  <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> -->
  <!-- <router-link to="/">Home</router-link>  -->

  <a-config-provider :theme="{
    algorithm: $store.state.isDarkModeActivated ? dark : light,
    token: {
      colorPrimary: primary_color,
      // colorSuccess: '#58ce1d',
      // colorWarning: '#fbb01a',
      // colorError: '#ff4447',
      // colorInfo: '#227eff', 

      // Avatar, Badge, Breadcrumb, Button, Calendar, Cascader, Checkbox, DatePicker, Dropdown, FloatButton, InputNumber, Input, Mentions, Menu, Modal, Notification, Pagination, Radio, Segmented, Select, Skeleton, Steps, Tag, Tour, TreeSelect, Tree
      borderRadiusSM: 16,
      // Avatar, Button, Calendar, DatePicker, InputNumber, Input, Layout, Mentions, Menu, Pagination, Radio, Segmented, Select, Table, Tabs, Tooltip, Tour, TreeSelect, Tree
      borderRadius: 12,
      // Alert, Avatar, Button, Calendar, Card, Collapse, DatePicker, Descriptions, Dropdown, FloatButton, InputNumber, Input, List, Mentions, Menu, Message, Modal, Notification, Pagination, Popover, QRCode, Radio, Segmented, Select, Table, Tabs, Tour, Transfer, Upload
      borderRadiusLG: 20,
      // DatePicker, Dropdown, Popover, Segmented, Select, Slider, Tooltip, Tour
      // borderRadiusXS: 16,
      // default font-size
      fontSize: 12

    },
  }">
    <!-- <div class="is-loading-bar has-text-centered" v-bind:class='{ "is-loading": true }'> -->
    <div class="is-loading-bar has-text-centered" v-bind:class='{ "is-loading": $store.state.isLoading }'>

      <a-spin size="large" class="spin">
        <template #tip>
          &nbsp;&nbsp;&nbsp;
          <h1 class="tw">Loading... </h1>
          <p class="tw">Wait we are processing your request...</p>
        </template>

      </a-spin>


    </div>
    <nav_main style="z-index:10" />
    
 
    
    <a-modal v-model:open="open_password_modal" centered title="Complete Your Profile" >
      <p>  As You Have Logged In With Google You Have To Setup Password, Please Enter the details above. </p>
      <label for="phone_number">Phone Number</label>
      <a-input
  v-model:value="phone_number"
  type="number"
/>
<br>

                                    <br>

                                <label for="dob">Date Of Birth</label>
                                <a-date-picker
    autofocus="autofocus"
    type="date"
    name="dob"
    id="user_email"
    format="YYYY/MM/DD"
    v-model:value="dob"
    style="z-index:0;border:1px solid grey;height:35px;width: 100%;font-size:var(--fa-12);border-radius: 7px;"
  />
    <br>
  <br>

      <label > New Password</label>
      <a-input v-model:value="password_new" placeholder="input password" />
      <br>
    <br>
    <label > Confirm Password</label>
      <a-input v-model:value="password_confirm" placeholder="input password" />
   
      <template #footer>
        
        <a-button key="submit" type="primary" :loading="loding_submit_pass" @click="handleSavePassword">Submit</a-button>
      </template>

    </a-modal>

  
    <!-- <HomeView/> -->
    <!-- </nav> -->
    <router-view />
    <a-row>
      <a-col :sm="24" :xs="24" :md="0" :lg="0" v-show="bottom_navbar">
        <bottom_navbar/>
      </a-col>
    </a-row>
  </a-config-provider>
</template>

<script>

import nav_main from '@/components/navigation/Navbar.vue'
import bottom_navbar from '@/components/navigation/bottomNavbar.vue'
import { theme } from 'ant-design-vue';
import axios from 'axios'
import { notification } from 'ant-design-vue';
import dayjs from 'dayjs';
import HomeView from './views/HomeView.vue'
// import store from './store/' 

export default {
  data() {
    return {
      dark: theme.darkAlgorithm,
      light: theme.compactAlgorithm,
      bgColor: 'var(--background-color)',

        open_password_modal:false,
        loding_submit_pass:false,
        password_new:'',
        password_confirm:'',
        phone_number: '',
        dob: '',
        
        // toggle this menu-bar with local storage
        bottom_navbar:false,
    }
  },
  computed: {
    primary_color: {
      get() {
        if(localStorage.getItem('primary_color')){
          document.documentElement.style.setProperty('--background-color', localStorage.getItem('primary_color')+"09" );
          document.documentElement.style.setProperty('--hover-color', localStorage.getItem('primary_color')+"ff" );
          document.documentElement.style.setProperty('--hover-slide-button', localStorage.getItem('primary_color')+"22" );
          document.documentElement.style.setProperty('--notification-color-bg', localStorage.getItem('primary_color')+"10" );

          return localStorage.getItem('primary_color') ;
        }else{
          document.documentElement.style.setProperty('--background-color', this.$store.state.primary_color);
          return this.$store.state.primary_color;
          }
      },
      set(value) {
        this.$store.commit('changeMainThemeColor', value);
      }
    }
  },
  watch: {
    primary_color(newValue) {
      this.updateTheme(newValue);
    }
  },
  mounted() {
    // console.log("Gt The User Details : ",this.$store.state.userDetils[0])

    // Check for mode in cache on page load
    var mode = localStorage.getItem("mode");
    if (mode === "dark") {
      this.$store.commit('toggleDarkModeTrue');
    } else {
      this.$store.commit('toggleDarkModeFalse');
    }

    // toggling the bottom_navbar with respect to local storage 
    var stateBottomNav = localStorage.getItem("bottom_navbar");
        if (stateBottomNav=='true') {
            this.bottom_navbar=true
        } else {
          // localStorage.setItem("bottom_navbar", false);

            this.bottom_navbar=false
        }


    if(localStorage.getItem("token"))
    {
      this.password_status();
    }

  },
  components: {
    nav_main,
    HomeView,
    bottom_navbar
  },
  methods: {


    // this methods empoyes request of those userwho has already registered by google & have not saved the dob and password for account 
   async handleSavePassword(){
    // console.log("the data ")
    // console.log(this.password_new)
    // console.log(this.password_confirm)
    // console.log(this.phone_number)
    // console.log(this.dob)
      this.loding_submit_pass = true;

      if (this.phone_number == '') {
                notification['error']({
                    message: 'Phone Number Is Empty ',
                    placement: 'bottomRight',
                });
              }
        if (this.dob == '') {
                notification['error']({
                    message: 'Date Of Birth Is Empty ',
                    placement: 'bottomRight',
                });
            }

      if (this.password_new == ''){
        notification['error']({
            message: 'Please Enter Password ',
            placement: 'bottomRight',
        });
      }
      if (this.password_confirm == ''){
        notification['error']({
            message: 'Please Enter Confirm Password ',
            placement: 'bottomRight',
        });
      }
       if ( this.password_new !== '' && this.password_confirm !== '' && this.password_new != this.password_confirm) {
        notification['error']({
            message: 'New Password & Confirm Password Is Not Matching ',
            
            placement: 'bottomRight',
        });
      }
      if (this.phone_number !== '' && this.dob !== '' && this.password_new !== '' && this.password_new === this.password_confirm) {
        const formData = {
                    password: this.password_confirm,
                    phone_number: this.phone_number,
                    dob: dayjs(this.dob).format("YYYY-MM-DD").toString(), // convert the date to needed format as "YYYY-MM-DD"
                }
                // console.log(formData)
               await axios
                    .post("setup-profile-signup-google/", formData)
                    .then(response => {
                        // console.log(response)
                        if (response.data['error'] == false) {
                            // console.log("Registration success ")
                            notification['success']({
                                message: 'Profile Setup Successfull ',
                                description:
                                    `Thankkk Your for choosing Us Your Profile Setup Has Been Done.`,
                                placement: 'bottomRight',
                            });
                            this.showModal(false);
                            window.location.reload();

                            // this.$router.push('/my-dashboard')
                        } else {
                            notification['error']({
                                message: 'Profile Setup Un-Successfull ',
                                description:
                                    response.data['statement'],
                                placement: 'bottomRight',
                            });
                            
                            // console.log(response.data['statement'])
                            // console.log("error encountered for registration")
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            for (const property in error.response.data) {
                                this.errors.push(`${property}:${error.response.data[property]}`)
                            }
                            console.log(JSON.stringify(error.response.data))

                        } else if (error.message) {
                            this.errors.push('Something went wrong. Please Try Again ')
                            console.log(JSON.stringify(error))

                        }
                    })
                    
      }
      
      this.loding_submit_pass = false;

      // console.log("save password");
    },
    showModal(status){
      this.open_password_modal = status;
    },
// check the password does exist or not 
    async password_status() {
    this.$store.commit('setIsLoading', true)
    await axios
        .post("password-seteled/")
        .then(response => {

            // console.log("==============================Pass Status =====================");
            // console.log(response);
            // console.log(response.data['status']);
            // this.open_password_modal=response.data['status']
            if(!response.data['status']){

              this.showModal(true)
            }
        })
        .catch(error => {
            // console.log("===========error at user details")                        
            console.log(JSON.stringify(error.response.data))
            console.log(JSON.stringify(error))
        })
    this.$store.commit('setIsLoading', false)
},




    updateTheme(primaryColor) {
      this.$store.commit('setIsLoading', true);

      // Assuming you have a mutation named 'changeMainThemeColor' in your store
      this.$store.commit('changeMainThemeColor', primaryColor);

      this.$store.commit('setIsLoading', false);
    }
  },
  watch: {
    primary_color(newValue) {
      this.updateTheme(newValue);
    }
  },
  name: 'home',

}
</script>

<style lang="scss">
.sticky-header {
  // background-color: #e9f5ff;
  // color: #fff;
  padding: 1em;
  text-align: center;
}

@supports (position: sticky) {
  .is-sticky {
    position: sticky;
    top: 0; // Threshold is required
  }
}

@import url('https://fonts.googleapis.com/css2?family=Alkatra:wght@400;500;600;700&display=swap');

.is-loading-bar {
  display: none;
  z-index: 11;
  background: var(--spin-bg);
  position: fixed;
  width: 100%;
  height: 100%;
  padding-top: 250px
}

.is-loading {
  display: block
}

.spin {
  background-color: var(--spin-bg);
  border-radius: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 80px;
  padding-right: 80px
}

.tw {
  font-family: 'Alkatra';
  color: var(--inverse-text-color);
}


::-webkit-scrollbar {
  width: 2px;
  /* Adjust the width to your preference */
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color of the thumb (drag handle) */
  border-radius: 5px;
  /* Rounded corners for the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Color of the thumb on hover */
}


:root {
  --wp--preset--color--gradient-one: #4c00ff3b;
  --wp--preset--color--gradient-two: #00c1ff47;
  --wp--preset--color--gradient-three: #00e4ff3b;
  --wp--preset--color--gradient-four: #0039FF3D;
  --wp--preset--color--gradient-five: #5800ff4a;
  --wp--preset--gradient--primary: radial-gradient(15% 15% at 8% 50%, var(--wp--preset--color--gradient-one) 1%, #FF000000 99%), radial-gradient(33% 33% at -1% 43%, var(--wp--preset--color--gradient-two) 1%, #FF000000 99%), radial-gradient(15% 15% at 71% 43%, var(--wp--preset--color--gradient-three) 1%, #FF000000 99%), radial-gradient(20% 20% at 82% 77%, var(--wp--preset--color--gradient-four) 1%, #FF000000 99%), radial-gradient(40% 40% at 89% 61%, var(--wp--preset--color--gradient-five) 1%, #FF000000 99%);


  --bg-android-plans:
    radial-gradient(15% 35% at 8% 50%, rgba(255, 0, 0, 0.3) 1%, #FF000000 99%),
    radial-gradient(15% 35% at 71% 43%, var(--wp--preset--color--gradient-three) 1%, #FF000000 99%),
    radial-gradient(20% 35% at 82% 77%, rgba(0, 255, 187, 0.2) 1%, #FF000000 99%);

  --primary: #006bd5;
  --secondary: #248800;
  --white: #ffffff;
  --notification-color-bg:#9000ff1d;
  --black: #000000;
  --text-color: #2e263dbe;
;
  --devide-color: rgba(128, 128, 128, 0.462);
  --hover-color: red;
  --sidebar-color: #ffffff;
  --navbar-color: #ffffffc1;
  --card-color: rgba(255, 255, 255, 0.5);
  --sub-card-color: rgba(71, 71, 71, 0.166);
  --background-color: #fcf6ff;
  --hover-slide-button: #9000ff2d;
  --spin-bg: #07070785;
  --bg-my-profile: rgba(255, 255, 255, 0.6);
  --bg-my-profile-label: rgba(255, 255, 255, 0.483);
  --bottom-navbar-color:rgb(255, 255, 255);

  --fa-10: 10px;
  --fa-11: 11px;
  --fa-12: 12px;
  --fa-13: 13px;
  --fa-14: 14px;
  --fa-15: 15px;
  --fa-16: 16px;
  --fa-17: 17px;
  --fa-18: 18px;
  --fa-19: 19px;
  --fa-20: 20px;
  --fa-22: 22px;

  // excessses

  --color-accent-light: #ff6262;
  --color-accent-dark: #ae00ff;
  --color-secondary: #ccc;

  --text-color-default: #2c2c2c;
  --text-color-light: #fff;
  --secondary-card-color:#f3f2f7;


  --font-size-default: 15px;
  --font-size-lg: 25px;

  --font-weight-regular: 400;
  --font-weight-semibold: 600;
  --font-weight-default: var(--font-weight-regular);

  --border-color-default: var(--color-secondary);
  --border-color-accent: var(--color-accent-dark);

  // --bg-color-default: #fff;
  --bg-color-dark: #2c2c2c;
  --bg-color-accent--lignt: var(--color-accent-light);
  --bg-color-accent--dark: var(--color-accent-dark);

  --transition-duration: 0.2s;
  --transition-timing-function: linear;
  --transition-delay: 0s;

  --negative-multiplier: -1;

  --gradient-default: linear-gradient(122deg,
      var(--color-accent-light) 0%,
      var(--color-accent-dark) 100%);


//   required by page our experties
--primary-color-card: #00cecb71;
  --secondary-color-card: #0026ce;
  --third-color-card: #9a5fda;
  --fourth-color-card: #e59d00;

  --bg-color-card: #dfe6e9;
  --text-color-white-card: white;

}

@media (max-width: 768px) {
  :root {
    --fa-10: 8px;
    --fa-11: 9px;
    --fa-12: 10px;
    --fa-13: 11px;
    --fa-14: 12px;
    --fa-15: 13px;
    --fa-16: 14px;
    --fa-17: 15px;
    --fa-18: 16px;
    --fa-19: 17px;
    --fa-20: 18px;
    --fa-22: 19px;
  }
}

/* deffine changes of colors in theas mode class */
.light-mode {
  --primary: #006bd5;
  --secondary: #248800;
  --text-color: #2e263dbe;
  --inverse-text-color: #ffffff;
  --navbar-color: #ffffffa2;
  --background-color: #fcf6ff;
  --card-color: rgba(255, 255, 255, 0.5);
  --sub-card-color: rgba(71, 71, 71, 0.166);
  --sidebar-color: #ffffff;
  --devide-color: rgba(128, 128, 128, 0.462);
  --spin-bg: #07070785;
  --secondary-card-color:#f3f2f7;
  --bg-my-profile: rgba(255, 255, 255, 0.6);
  --bg-my-profile-label: rgba(255, 255, 255, 0.483);
  --bottom-navbar-color:rgb(255, 255, 255);

}

/* deffine changes of colors in theas mode class */
.dark-mode {
  --primary: #3934db;
  --secondary: #b92929;
  --text-color: #ffffff;
  --inverse-text-color: #000000;
  --navbar-color: #171717d4;
  --secondary-card-color:#f3f2f757;

  --card-color: rgb(50, 50, 50);
  --sub-card-color: rgb(38, 38, 38);
  --background-color: #000000;
  --sidebar-color: #464646;
  --devide-color: rgba(128, 128, 128, 0.462);
  --spin-bg: #ffffff85;
  --bg-my-profile: rgba(50, 50, 50, 0.6);
  --bg-my-profile-label: rgba(47, 47, 47, 0.483);
  --bottom-navbar-color:rgb(45, 45, 45);


}

#app {
  background-color: var(--background-color);
  color: var(--text-color);

  font-family: 'Alkatra';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

}












router-link {
  color: var(--text-color);
}

/* DARK LIGHT TOGGLE BUTTON  */

/* toggle theme dark or white */
.show-mode svg path {
  fill: var(--text-color);
  /* Set the initial fill color */
  transition: fill 0.3s cubic-bezier(0.6, -0.28, 0.74, 0.05);
  ;
  /* Add a transition effect */
}

.dark-mode .show-mode svg path {
  fill: #ffffff;
  /* Set the fill color for dark mode */
}

.toggle {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70px;
  height: 40px;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.2s;
}

.checkbox {
  display: none;
}

.icon {
  width: 50%;
  font-size: 20px;
  line-height: 60px;
  text-align: center;
  z-index: 1;
}

.ball {
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin: 7px;
  transition: 0.2s;
}

/* ---- ---- Light Mode ---- ---- */
/* .light {
    background-color: ;
}
*/ 

.light .toggle {
  background: linear-gradient(145deg, #bababa, #fff);
}

.light .icon--light {
  color: #ffffff;
}

.light .icon--dark {
  color: #151d2a;
}

.light .ball {
  background-color: #343434;
  transition: 0.6s;

}

/* ---- ---- Dark Mode ---- ---- */
/* .dark {
    background-color: #151d2a;
  } */
.dark .toggle {
  background: #343434;
  /* box-shadow: 3px 3px 3px #0e131b, -3px -3px 3px #1c2739; */
}

.dark .icon--light {
  color: #fafafa;
}

.dark .icon--dark {
  color: #0e131b;
}

.dark .ball {
  background-color: #fafafa;
  transition: 0.6s;
  transform: translateX(32px);
}


/* toggle theme dark or white end */





// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
