<template >
<!-- <div style="background: black;"> -->
<div style=" background:
   radial-gradient(45% 45% at 8% 50%, rgba(255, 0, 111, 0.277) 1%, #FF000000 99%),
   radial-gradient(33% 33% at 89% 43%, rgba(255, 255, 0, 0.401) 1%, #FF000000 99%),
   radial-gradient(33% 33% at 89% 93%, rgba(0, 255, 119, 0.201) 1%, #FF000000 99%),
    radial-gradient(15% 15% at 71% 43%, var(--wp--preset--color--gradient-three) 1%, #FF000000 99%),
     radial-gradient(20% 20% at 82% 77%, var(--wp--preset--color--gradient-four) 1%, #FF000000 99%),
      radial-gradient(40% 40% at 89% 61%, var(--wp--preset--color--gradient-five) 1%, #FF000000 99%); " >
    <!-- <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
<br> -->

    <!-- Your Body Here -->
<div class="" style="
/* background: black; */
display:flex;justify-content:center;margin-top:100px">
    <br>

<div class="meater-speed-crekaa-ui" style="display:block;background-color: rgb(57, 57, 57);">

  <center>
  <canvas id="canvas" height="175" width="350" class="m-auto" >

  </canvas>
  <p class="text" style="color:white">0 %</p>
  </center>
</div>
</div>

<div>

<main class="container">
  <section>
    <div class="" style="padding:20px">
    <!-- <center> -->


   <div class="boost-discription">
     <span style="font-size:14px;
     /* color:var(--white); */
     ">Introducing
     </span><br>
     <h1 class="boost-title">Crekaa.UI Boost
<span class="beta-label">Beta</span>
     </h1>
<span class="pp" style="font-size:16px;"><span style="font-size:12px;">Crekaa stands for Cross-Platform Responsive Element Kit for Advanced Applications</span><br> Up to 1,000× faster page load in just a few seconds with our groundbreaking caching <br>technology, now in limited beta.</span><br><br>

<a-button size="large" style="font-size:14px;width:70%;max-width:400px;font-weight: bold;height:50px;border-radius:50px">Join the waitlist
                            →</a-button>
   </div>
 <!-- </center> -->
</div>

<br>
<div class="marquee-container">
        <div class="marquee-content">
            <img src="../../assets/Images/cards.png" alt="Marquee Image"/>
            <img src="../../assets/Images/cards.png" alt="Marquee Image"/> <!-- Duplicate the image for continuous scrolling -->
        </div>
    </div>
      <br>
<div class="video-container-base-bg">
<div class="video-container-base">
<div class="video-container">
    <video autoplay="" muted="" loop="">
  <!-- <source src="https://crekaa-2024.s3.amazonaws.com/static/videos/website-boost.mp4" type="video/mp4"> -->
  <source src="https://crekaa-2024.s3.us-west-2.amazonaws.com/static/graphics/project+crekaa+promotional+project.mp4" type="video/mp4">

Your browser does not support the video tag.
</video>
</div>
</div>
</div>
<br>
   


  </section>
</main>
</div>

<a-row>
    <a-col :sm="24" :xs="24" :md="12" :lg="12" >
      <lottie-player src="https://lottie.host/e5f17690-4c73-4528-be90-78b212c78bbf/73SSeU7NLU.json" background="##FFFFFF" speed="1" style="width: 100%; max-height:500px" loop autoplay direction="1" mode="normal"></lottie-player>

    </a-col>
    <a-col :sm="24" :xs="24" :md="12" :lg="12">
        <br>
<br>
<br><br>
<br>
<br><br>
<br>
<br>        <div class="desc-boost space-y-2 py-4 px-4 lg:pl-0 xl:pb-0 xl:pt-0 xl:pl-0 xl:pr-6"><p class="leading-[1.625]" style="font-size:18px;"><strong>Crekaa.UI Boost</strong> is a real-time Web cache that automatically handles cache invalidation for you, without requiring substantial changes to your application in browser. <a href="#">Read the docs</a> and <!-- --> <a href="#">learn how it works under the hood</a>&nbspfor more details.</p>



<br>
<br>
<h6 class="text-desc-boost">In just a few clicks, CreKaa.UI Boost makes the queries you choose&nbsp;faster.</h6>
<a-divider  style="border-color: var(--devide-color)"/>
<h6 class="text-desc-boost">Find a slow query pattern using Insights and activate CreKaa.UI&nbsp;Boost.</h6>
<a-divider  style="border-color: var(--devide-color)"/>
<h6 class="text-desc-boost">No TTLs, no invalidation logic, and no caching infrastructure to&nbsp;maintain.</h6>
<a-divider  style="border-color: var(--devide-color)"/>

<br>
<br>
<br>

<a-button size="large" style="font-size:14px;width:70%;font-weight: bold;height:50px;border-radius:50px">Join the waitlist
                            →</a-button>
</div>
    </a-col>
</a-row>
</div>



</template>
<script> 

// import "@/node_modules/vue3-marquee-slider/dist/style.css"
export default {
  name: "crekaa_ui",
  created(){
    document.title = "Crekaa.UI Technology - Crekaa";
  },
  head() {
    return {
      title: "Crekaa.UI Technology - Crekaa",
      meta: [
        { hid: 'description', name: 'description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'author', name: 'author', content: "Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        
        // <!-- Facebook Open Graph tags -->
        { hid: 'og:url', property: 'og:url', content: "https://crekaa.com/" },
        { hid: 'og:type', property: 'og:type', content: "website" },
        { hid: 'og:title', property: 'og:title', content: "Crekaa, Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'og:description', property: 'og:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'og:image', property: 'og:image', content: "../../assets/logo.png" },
        // Add more meta tags as needed

        // <!-- Twitter Card tags -->
        { hid: 'twitter:card', name: 'twitter:card', content: "summary_large_image" },
        { hid: 'twitter:title', name: 'twitter:title', content: "Crekaa , Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'twitter:description', name: 'twitter:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'twitter:image', name: 'twitter:image', content: "../../assets/logo.png" },

      ]
    };
  },
    mounted(){
        
  //Change this Value to set the percentage
let totalRot = ((97 / 100) * 180 * Math.PI) / 180;

let rotation = 0;
let doAnim = true;
let canvas = null;
let ctx = null;
let text = document.querySelector(".text");
canvas = document.getElementById("canvas");
ctx = canvas.getContext("2d");
setTimeout(requestAnimationFrame(animate), 1500);

function calcPointsCirc(cx, cy, rad, dashLength) {
var n = rad / dashLength,
  alpha = (Math.PI * 2) / n,
  pointObj = {},
  points = [],
  i = -1;

while (i < n) {
  var theta = alpha * i,
    theta2 = alpha * (i + 1);

  points.push({
    x: Math.cos(theta) * rad + cx,
    y: Math.sin(theta) * rad + cy,
    ex: Math.cos(theta2) * rad + cx,
    ey: Math.sin(theta2) * rad + cy
  });
  i += 2;
}
return points;
}
function animate() {
//Clearing animation on every iteration
ctx.clearRect(0, 0, canvas.width, canvas.height);
const center = {
  x: 175,
  y: 175
};

//main arc
ctx.beginPath();
ctx.strokeStyle = rotation >= 0.75 * Math.PI ? "#FF9421" : "#35FFFF";
ctx.lineWidth = "3";
let radius = 174;
ctx.arc(center.x, center.y, radius, Math.PI, Math.PI + rotation);
ctx.stroke();

//Red Arc
if (rotation <= 0.75 * Math.PI) {
  ctx.beginPath();
  ctx.strokeStyle = "#FF9421";
  ctx.lineWidth = "3";
  ctx.arc(center.x, center.y, radius, 1.75 * Math.PI, 0);
  ctx.stroke();
}

//functions to draw dotted lines
const DrawDottedLine = (x1, y1, x2, y2, dotRadius, dotCount, dotColor) => {
  var dx = x2 - x1;
  var dy = y2 - y1;
  let slopeOfLine = dy / dx;
  var degOfLine =
    Math.atan(slopeOfLine) * (180 / Math.PI) > 0
      ? Math.atan(slopeOfLine) * (180 / Math.PI)
      : 180 + Math.atan(slopeOfLine) * (180 / Math.PI);
  var degOfNeedle = rotation * (180 / Math.PI);

  if (rotation >= 0.75 * Math.PI) {
    dotColor = degOfLine <= degOfNeedle ? "#FF9421" : "#f97272";
  } else {
    dotColor = degOfLine <= degOfNeedle ? dotColor : "#aad4d4";
  }
  var spaceX = dx / (dotCount - 1);
  var spaceY = dy / (dotCount - 1);
  var newX = x1;
  var newY = y1;
  for (var i = 0; i < dotCount; i++) {
    dotRadius = dotRadius >= 0.75 ? dotRadius - i * (0.5 / 15) : dotRadius;
    drawDot(newX, newY, dotRadius, `${dotColor}${100 - (i + 1)}`);
    newX += spaceX;
    newY += spaceY;
  }
};
const drawDot = (x, y, dotRadius, dotColor) => {
  ctx.beginPath();
  ctx.arc(x, y, dotRadius, 0, 2 * Math.PI, false);
  ctx.fillStyle = dotColor;
  ctx.fill();
};
let firstDottedLineDots = calcPointsCirc(center.x, center.y, 165, 1);
for (let k = 0; k < firstDottedLineDots.length; k++) {
  let x = firstDottedLineDots[k].x;
  let y = firstDottedLineDots[k].y;
  DrawDottedLine(x, y, 175, 175, 1.75, 30, "#35FFFF");
}

//dummy circle to hide the line connecting to center
ctx.beginPath();
ctx.arc(center.x, center.y, 80, 2 * Math.PI, 0);
ctx.fillStyle = "black";
ctx.fill();

//Speedometer triangle
var x = -75,
  y = 0;
ctx.save();
ctx.beginPath();
ctx.translate(175, 175);
ctx.rotate(rotation);
ctx.moveTo(x, y);
ctx.lineTo(x + 10, y - 10);
ctx.lineTo(x + 10, y + 10);
ctx.closePath();
ctx.fillStyle = rotation >= 0.75 * Math.PI ? "#FF9421" : "#35FFFF";
ctx.fill();
ctx.restore();
if (rotation < totalRot) {
  rotation += (1 * Math.PI) / 180;
  if (rotation > totalRot) {
    rotation -= (1 * Math.PI) / 180;
  }
}

text.innerHTML = Math.round((rotation / Math.PI) * 100) + 0 + "%";
requestAnimationFrame(animate);
}

    }
}
</script>
<style scoped>
 .marquee-container {
            width: 100%;
            overflow: hidden;
            position: relative;
            /* height: 200px; */
             /* Adjust the height according to your image */
        }

        .marquee-content {
            display: flex;
            width: fit-content;
            animation: marquee 20s linear infinite;
        }

        .marquee-content img {
            height: 100%;
            /* Ensure images do not have space between them */
            margin: 0;
            padding: 0;
        }

        @keyframes marquee {
            from {
                transform: translateX(0%);
            }
            to {
                transform: translateX(-50%);
            }
        }

[class*="col-0-"] {
  float: left;
  padding: 15px;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

.col-0-1 {
  width: 8.33%;
}

.col-0-2 {
  width: 16.66%;
}

.col-0-3 {
  width: 25%;
}

.col-0-4 {
  width: 33.33%;
}

.col-0-5 {
  width: 41.66%;
}

.col-0-6 {
  width: 50%;
}

.col-0-7 {
  width: 58.33%;
}

.col-0-8 {
  width: 66.66%;
}

.col-0-9 {
  width: 75%;
}

.col-0-10 {
  width: 83.33%;
}

.col-0-11 {
  width: 91.66%;
}

.col-0-12 {
  width: 100%;
}

/*  ui meater  */
.meater-speed-crekaa-ui {
  width: 400px;
  height: 250px;
  padding: 30px;
  border-radius: 20px;
  box-shadow: #FF9421 6px 2px 16px 0px, #35FFFF -6px -2px 16px 0px;
}

.text {
  /* color: white; */
  font-weight: 600;
  font-size: 24px;
  margin-top: -40px;
}

.boost-title {
  margin-top: -5px;
  font-size: 48px;
  /* color: var(--white); */
  font-weight: 700
}
.boost-title-mob {
  margin-top: -5px;
  font-size: 28px;
  /* color: var(--white); */
  font-weight: 700
}


.beta-label {
  background: rgb(0, 75, 69);
  border: 1px solid #00e0e0;
  color: #00e0e0;
  border-radius: 15px;
  font-size: 12px;
  padding: 5px;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.pp{
  font-size: 17px;
  margin-top:-20px;
}
.image-boost{
  filter: invert(90%)
  /* filter:hue-rotate(180deg); */
}


.bottom-join-waitinglist {

  border: 2px solid rgb(204, 37, 37);
  border-radius: 30px;
  padding: 10px;
   cursor: pointer;
  text-decoration: none;
  font-size:16px;

}

.bottom-join-waitinglist:hover {
  color: rgb(204, 37, 37);
  border: 2px solid rgb(204, 37, 37);
  font-size:16px;
  padding: 10px;
  text-decoration: none;
}
.desc-boost {
  margin-top: 0.5rem;
  padding-top: 1rem; /* Adjust the desired padding value here */
 padding-bottom: 1rem;
 font-size:18px;
 margin-bottom:20px
}
.lg\:pl-0 {
  padding-left: 0; /* Padding-left value for large screens */
}

.xl\:pb-0 {
  padding-bottom: 0; /* Padding-bottom value for extra-large screens */
}

.xl\:pt-0 {
  padding-top: 0; /* Padding-top value for extra-large screens */
}

.xl\:pl-0 {
  padding-left: 0; /* Padding-left value for extra-large screens */
}

.xl\:pr-6 {
  padding-right: 1.5rem; /* Padding-right value for extra-large screens */
}

@media (min-width: 1024px) {
  .lg\:pt-4 {
    padding-top: 4rem; /* Adjust the unit and value as needed */
  }
}

@media (min-width: 1024px) {
  .lg\:text-sm {
    font-size: 0.875rem; /* Adjust the value as needed */
  }
}
.border-bottom{
  margin-top:-20px;
  border-bottom:1px solid rgba(115, 115, 115,0.5)
}
.text-desc-boost{
  /* color:white; */
  font-size:16px
}

/* video  */
.video-container-base-bg{
  background:rgba(115, 115, 115,0.2) ;
  margin:20px;
  border-radius: 10px;

}
.video-container-base{

  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 220px;
  /* max-height: 100vh; */

}
.video-container-base-mob{

  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:30px

}
.video-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  /* max-width: 1200px; */
   /* Optional: Set a maximum width for the video container */
}

  .video-container video {
    width: 100%;
    border-radius: 10px;
  }

  .image-boost-mob{
    filter: invert(90%);
    width:80%
    /* filter:hue-rotate(180deg); */
  }
.icon-crekaa-ui-boost-mob{
  width:90%
}

.content-crekaa-ui-boost-mob{
  padding:10px;

}

</style>