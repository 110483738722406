<template>

<main style="padding:10px;text-align: START;">


    <a-row>
        <a-col :sm="24" :xs="24" :md="12" :lg="12">
        <div class="lottie-left-section head-pc">
  
  <lottie-player src="https://crekaa-2024.s3.amazonaws.com/static/lottiefiles/web%20devloper.json"
  background="transparent" speed="1" loop autoplay></lottie-player>
</div>
</a-col>
        <a-col :sm="24" :xs="24" :md="12" :lg="12">
        <div class="content-section">
  <section class="t-bq-section" id="paul">
    <div class="t-bq-wrapper t-bq-wrapper-boxed">
        <div class="t-bq-quote t-bq-quote-paul">
            <div class="t-bq-quote-paul-userpic"></div>
            <div class="t-bq-quote-paul-qmark">
                &#10077;
            </div>
            <div class="t-bq-quote-paul-pattern">
            </div>
            <div class="t-bq-quote-paul-base">
                <blockquote class="t-bq-quote-paul-text" cite="Strugatsky Brothers">
                  With Crekaa, managing and keeping track of our donations has become remarkably easy. In the past, it was a challenging task to stay on top of all the data related to our contributions. Thanks to Crekaa's innovative solutions, we now have access to real-time information on ongoing donations for our NGO. This has allowed us to focus more on delivering essential services to those in need, knowing that our donation management is taken care of efficiently. 
                </blockquote>
                <div class="t-bq-quote-paul-meta">
                    <div class="t-bq-quote-paul-meta-info">
                        <div class="t-bq-quote-paul-author"><cite>MARSK</cite></div>
                        <!-- <div class="t-bq-quote-paul-author"><cite>Maharashtra Rajya Annadata Seva Kendra</cite></div> -->
                        <div class="t-bq-quote-paul-source"><span>Hard to Be a God</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</div>
</a-col>
    </a-row>
    
    <a-row>
        <a-col :sm="24" :xs="24" :md="12" :lg="12"><section class="t-bq-section" id="jasper">
    <div class="t-bq-wrapper t-bq-wrapper-boxed">
        <div class="t-bq-quote t-bq-quote-jasper">
            <div class="t-bq-quote-jasper-pattern">
                <div class="t-bq-quote-jasper-qmark">
                    &#10077;
                </div>
            </div>

            <div class="t-bq-quote-jasper-userpic"></div>

            <div class="t-bq-quote-jasper-base">
                <blockquote class="t-bq-quote-jasper-text" cite="Strugatsky Brothers">

                  Crekaa has proven to be a highly effective platform for our business. With its user-friendly interface, we can effortlessly track and manage essential aspects of our operations, including delivery, transportation, and order tracking. The real-time services provided by Crekaa have been instrumental in streamlining our business processes, ultimately leading to a remarkable 10X improvement in growth within the online market. Thanks to Crekaa, we are now better equipped to thrive in the competitive business landscape and deliver exceptional services to our customers.
                </blockquote>
                <div class="t-bq-quote-jasper-meta">
                    <div class="t-bq-quote-jasper-meta-info">
                        <div class="t-bq-quote-jasper-author"><cite>Screza Ecommerce</cite></div>
                        <div class="t-bq-quote-jasper-source"><span>Internet is A Future </span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section></a-col>
        <a-col :sm="24" :xs="24" :md="12" :lg="12"><div class="lottie-right-section head-pc">
    
    <lottie-player src="https://crekaa-2024.s3.amazonaws.com/static/lottiefiles/startup-rocket.json"
    background="transparent" speed="1" loop autoplay></lottie-player>
  </div></a-col>
    </a-row>
    
    <a-row>
        <a-col :sm="24" :xs="24" :md="24" :lg="24">
            <div class="t-bq-wrapper t-bq-wrapper-boxed">
          <div class="t-bq-quote t-bq-quote-horace">
  
              <div class="t-bq-quote-horace-qmark" style="overflow-y: hide;font-size: 150px;">
                  &#10078;
              </div>
  
  
              <div class="t-bq-quote-horace-base">
                  <blockquote class="t-bq-quote-horace-text" cite="Strugatsky Brothers">
  
                    At Crekaa, our primary goal is to help your business grow and succeed. When you're ready for your next customer, simply place an order, and we'll promptly get in touch with you. Our dedicated team is always here to support you throughout your journey. With Crekaa by your side, success is within reach.
                  </blockquote>
                  <div class="t-bq-quote-horace-meta">
                      <div class="t-bq-quote-horace-userpic"></div>
                      <div class="t-bq-quote-horace-meta-info">
                          <div class="t-bq-quote-horace-author"><cite>Crekaa Buisness Solutions</cite></div>
                          <div class="t-bq-quote-horace-source"><span>It's Not Until It's Now</span></div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </a-col>
        
    </a-row>

        
<br><br>
</main>

</template>
<script>
export default{
    name:"our_customers",
    
created(){
    document.title = "Our Customers - Crekaa";
  },
  head() {
    return {
      title: "Our Customers - Crekaa",
      meta: [
        { hid: 'description', name: 'description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'author', name: 'author', content: "Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        
        // <!-- Facebook Open Graph tags -->
        { hid: 'og:url', property: 'og:url', content: "https://crekaa.com/" },
        { hid: 'og:type', property: 'og:type', content: "website" },
        { hid: 'og:title', property: 'og:title', content: "Crekaa, Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'og:description', property: 'og:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'og:image', property: 'og:image', content: "../../assets/logo.png" },
        // Add more meta tags as needed

        // <!-- Twitter Card tags -->
        { hid: 'twitter:card', name: 'twitter:card', content: "summary_large_image" },
        { hid: 'twitter:title', name: 'twitter:title', content: "Crekaa , Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'twitter:description', name: 'twitter:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'twitter:image', name: 'twitter:image', content: "../../assets/logo.png" },

      ]
    };
  },
}
</script>
<style scoped>

blockquote {
    padding: 0;
    margin: 0;
}

section.t-bq-section {
    padding: 30px;
    margin-bottom: 60px;
}

.t-bq-wrapper.t-bq-wrapper-boxed {
    max-width: 576px;
    margin: 0 auto;
}

.t-bq-wrapper.t-bq-wrapper-fullwidth {
    max-width: 100%;
}








/* ========== 05. Paul ========== */

.t-bq-quote-paul {
    position: relative;
    display: flex;
    flex-direction: row;
    min-height: 250px;
    box-shadow: 2px 2px 25px #cecece;
    border-radius: 10px;
}

.t-bq-quote-paul .t-bq-quote-paul-pattern {
    flex-basis: 80px;
    background: url("https://crekaa-2024.s3.amazonaws.com/static/images/hexabump.png") repeat;
    border-radius: 10px 0 0 10px;
}

.t-bq-quote-paul .t-bq-quote-paul-base {
    flex-basis: calc(100% - 80px);
    background: var(--card-color);
    padding: 40px 30px 50px 80px;
    font-size: 11pt;
    line-height: 1.62em;
    border-radius: 0 10px 10px 0;
}

.t-bq-quote-paul .t-bq-quote-paul-qmark {
    position: absolute;
    top: 50px;
    left: 105px;
    font-family: Garamond, Georgia, "Times New Roman", serif;
    font-size: 42pt;
    color: #999999;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.t-bq-quote-paul .t-bq-quote-paul-userpic {
    position: absolute;
    top: 80px;
    left: 45px;
    width: 70px;
    height: 70px;
    background: url("https://cms-org-official.s3.amazonaws.com/static/UI/posters/logo.png") center center no-repeat;
    background-size: cover;
    border-radius: 50%;
}

.t-bq-quote-paul .t-bq-quote-paul-meta {
    margin-top: 30px;
    padding-top: 10px;
    border-top: 2px dotted #777777;
}

.t-bq-quote-paul .t-bq-quote-paul-meta .t-bq-quote-paul-author,
.t-bq-quote-paul .t-bq-quote-paul-meta .t-bq-quote-paul-source {
    color: #777777;
}

.t-bq-quote-paul .t-bq-quote-paul-meta .t-bq-quote-paul-author {
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-size: 10pt;
    font-weight: bold;
}

.t-bq-quote-paul .t-bq-quote-paul-meta .t-bq-quote-paul-author cite {
    font-style: normal;
}

.t-bq-quote-paul .t-bq-quote-paul-meta .t-bq-quote-paul-source {
    font-size: 9pt;
}

@media screen and (max-width: 768px) {

    .t-bq-quote-paul .t-bq-quote-paul-pattern {
        flex-basis: 20px;
    }

    .t-bq-quote-paul .t-bq-quote-paul-base {
        flex-basis: calc(100% - 20px);
        padding: 100px 30px 50px 30px;
    }

    .t-bq-quote-paul .t-bq-quote-paul-userpic {
        width: 50px;
        height: 50px;
        left: 40px;
        top: 20px;
    }

    .t-bq-quote-paul .t-bq-quote-paul-qmark {
        left: 100px;
        top: 45px;
    }
}









/* ========== 06. Jasper ========== */

.t-bq-quote-jasper {
    position: relative;
    box-shadow: 2px 2px 25px #cecece;
    border-radius: 10px;
}

.t-bq-quote-jasper .t-bq-quote-jasper-pattern {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 80px;
    align-items: center;
    justify-content: flex-start;
    background: url("https://crekaa-2024.s3.amazonaws.com/static/images/hexabump.png") repeat;
    border-radius: 10px 10px 0 0;
}

.t-bq-quote-jasper .t-bq-quote-jasper-pattern .t-bq-quote-jasper-qmark {
    flex-basis: 100px;
    font-family: Garamond, Georgia, "Times New Roman", serif;
    font-size: 60pt;
    color: #999999;
    text-align: center;
    height: 80px;
    line-height: 90pt;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.t-bq-quote-jasper .t-bq-quote-jasper-userpic {
    position: absolute;
    top: 45px;
    left: calc(50% - 35px);
    width: 70px;
    height: 70px;
    background: url("https://firebasestorage.googleapis.com/v0/b/withosis.appspot.com/o/uploads%2Fapp_ico.png?alt=media&token=12d708a8-a9ce-4ed6-aef7-986ed927e8bb") center center no-repeat;
    background-size: cover;
    border-radius: 50%;
}

.t-bq-quote-jasper .t-bq-quote-jasper-base {
    flex-basis: calc(100% - 80px);
    background: var(--card-color);
    padding: 60px 30px 50px 100px;
    font-size: 11pt;
    line-height: 1.62em;
    border-radius: 0 0 10px 10px;
}

.t-bq-quote-jasper .t-bq-quote-jasper-meta {
    margin-top: 30px;
    padding-top: 10px;
    border-top: 2px dotted #777777;
    text-align: center;
}

.t-bq-quote-jasper .t-bq-quote-jasper-meta .t-bq-quote-jasper-author,
.t-bq-quote-jasper .t-bq-quote-jasper-meta .t-bq-quote-jasper-source {
    color: #777777;
}

.t-bq-quote-jasper .t-bq-quote-jasper-meta .t-bq-quote-jasper-author {
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-size: 10pt;
    font-weight: bold;
}

.t-bq-quote-jasper .t-bq-quote-jasper-meta .t-bq-quote-jasper-author cite {
    font-style: normal;
}

.t-bq-quote-jasper .t-bq-quote-jasper-meta .t-bq-quote-jasper-source {
    font-size: 9pt;
}

@media screen and (max-width: 768px) {

    .t-bq-quote-jasper .t-bq-quote-jasper-base {
        padding-left: 30px;
    }
}








/* ========== 07. Horace ========== */

.t-bq-quote-horace {
    position: relative;
    min-height: 250px;
    border-radius: 10px;
    box-shadow: 2px 2px 25px #cecece;
}

.t-bq-quote-horace .t-bq-quote-horace-base {
    background: url("https://crekaa-2024.s3.amazonaws.com/static/images/hexabump.png") repeat;
    color: #ffffff;
    font-weight: bold;
    padding: 60px;
    border-radius: 10px;
}

.t-bq-quote-horace .t-bq-quote-horace-meta {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    padding-top: 20px;
    border-top: 2px dotted #ffffff;
}

.t-bq-quote-horace .t-bq-quote-horace-meta .t-bq-quote-horace-author {
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-size: 10pt;
    font-weight: bold;
}

.t-bq-quote-horace .t-bq-quote-horace-meta .t-bq-quote-horace-author cite {
    font-style: normal;
}

.t-bq-quote-horace .t-bq-quote-horace-meta .t-bq-quote-horace-source {
    font-size: 10pt;
}

.t-bq-quote-horace .t-bq-quote-horace-qmark {
    position: absolute;
    top: 140px;
    right: 5px;
    font-size: 280pt;
    color: #ffffff;
    opacity: 0.17;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.t-bq-quote-horace .t-bq-quote-horace-userpic {
    width: 60px;
    height: 60px;
    background: url("../../assets/logo.png") center center no-repeat;
    background-size: cover;
    border-radius: 50%;
    margin-right: 20px;
}

@media screen and (max-width: 768px) {

    .t-bq-quote-horace .t-bq-quote-horace-base {
        padding-left: 40px;
        padding-right: 40px;
    }

    .t-bq-quote-horace .t-bq-quote-horace-meta {
        flex-direction: column;
        text-align: center;
    }

    .t-bq-quote-horace .t-bq-quote-horace-meta .t-bq-quote-horace-userpic {
        margin: 0 auto;
        margin-bottom: 10px;
    }

}

.flx-brakable{
  display: flex;
}
@media (max-width: 778px) {
  .flx-brakable{
  flex-direction: column;
} 
}


</style>