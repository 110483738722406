<template>
    <br><br>
    <section div style="min-height:100vh;padding:10px;line-height: 30px;font-size: 14px;">
        <h1 style="font-size:52px;line-height: 40px;">Company Report</h1>
            <p style="font-size:22px;width:100%;max-width:800px;margin:auto">This Company Report provides an overview of CREKAA's performance and achievements over the past year. It highlights key milestones, financial performance, and strategic initiatives that have driven the company's growth and success.</p>


    <div class="container">
        <div class="sectional-text">
            <h1 class="title">Introduction</h1>
            <p class="description">At CREKAA, we recognize that our strength lies in our diversity and our commitment to fostering an inclusive and equitable workplace. We believe that embracing a wide range of perspectives and experiences drives innovation and success. Our DEI pledge reflects our dedication to creating an environment where every individual feels valued, respected, and empowered to contribute to their fullest potential.</p>
        </div>

        <div class="sectional-text">
            <h2 class="title">Our Commitment</h2>
            <h3>Diversity:</h3>
            <p class="description">We pledge to actively seek out and welcome diverse perspectives, backgrounds, and talents to build a workforce that reflects the richness of the communities we serve.</p>
            <h3>Equity:</h3>
            <p class="description">We are dedicated to ensuring fair treatment, access, opportunity, and advancement for all employees. We commit to identifying and eliminating barriers that have prevented the full participation of some groups.</p>
            <h3>Inclusion:</h3>
            <p class="description">We strive to create an inclusive environment where all individuals feel respected, heard, and valued, and where their contributions are recognized and appreciated.</p>
        </div>

        <div class="sectional-text">
            <h2 class="title">Goals and Objectives</h2>
            <h3>1. Workforce Diversity:</h3>
            <ul>
                <li>Increase the representation of underrepresented groups in our workforce by 25% by 2025.</li>
                <li>Ensure that diverse candidates are considered for all leadership positions.</li>
            </ul>
            <h3>2. Pay Equity:</h3>
            <ul>
                <li>Conduct annual pay audits to ensure equitable compensation practices.</li>
                <li>Address any identified disparities to achieve pay equity across all roles and levels by 2023.</li>
            </ul>
            <h3>3. Inclusive Culture:</h3>
            <ul>
                <li>Achieve 100% participation in DEI training programs for all employees by 2024.</li>
                <li>Establish and support Employee Resource Groups (ERGs) to provide a platform for diverse communities within the company.</li>
            </ul>
        </div>

        <div class="sectional-text">
            <h2 class="title">Implementation Strategies</h2>
            <h3>1. Recruitment and Hiring:</h3>
            <ul>
                <li>Develop inclusive job descriptions and remove biased language.</li>
                <li>Partner with diverse talent pools and organizations supporting underrepresented groups.</li>
                <li>Train hiring managers to recognize and mitigate unconscious bias.</li>
            </ul>
            <h3>2. Employee Development and Retention:</h3>
            <ul>
                <li>Provide equitable access to professional development, mentorship, and career advancement opportunities.</li>
                <li>Offer regular diversity, equity, and inclusion training and workshops.</li>
                <li>Foster a supportive environment through Employee Resource Groups.</li>
            </ul>
            <h3>3. Creating an Inclusive Culture:</h3>
            <ul>
                <li>Review and update policies to promote inclusion and equity, including flexible work arrangements and accommodations.</li>
                <li>Recognize and celebrate diverse cultures and perspectives through events and storytelling.</li>
                <li>Encourage open and honest communication about DEI issues.</li>
            </ul>
            <h3>4. Accountability and Measurement:</h3>
            <ul>
                <li>Conduct regular assessments of DEI initiatives to measure progress.</li>
                <li>Share DEI goals, progress, and outcomes with all employees.</li>
                <li>Hold leadership accountable for advancing DEI goals through performance evaluations.</li>
            </ul>
        </div>

        <div class="sectional-text">
            <h2 class="title">Employee Participation</h2>
            <p class="description">We believe every employee has a role to play in advancing our DEI goals. We encourage employees to:</p>
            <ul>
                <li>Engage in DEI Training: Participate in training sessions and workshops to build awareness and understanding.</li>
                <li>Join ERGs: Get involved in Employee Resource Groups to connect with colleagues and contribute to DEI initiatives.</li>
                <li>Provide Feedback: Share ideas, concerns, and feedback about DEI efforts.</li>
                <li>Be Allies: Support and advocate for colleagues from underrepresented groups.</li>
            </ul>
        </div>

        <div class="sectional-text">
            <h2 class="title">Leadership Commitment</h2>
            <p class="description">Our leadership team is fully committed to advancing DEI within CREKAA. They will:</p>
            <ul>
                <li>Lead by Example: Demonstrate inclusive behaviors and practices.</li>
                <li>Champion DEI Initiatives: Actively support and promote DEI programs.</li>
                <li>Allocate Resources: Ensure sufficient resources are dedicated to DEI goals.</li>
                <li>Engage in Continuous Learning: Participate in ongoing DEI education and training.</li>
            </ul>
        </div>

        <div class="sectional-text">
            <h2 class="title">Community Engagement</h2>
            <p class="description">We recognize the importance of extending our DEI efforts beyond our organization. We will:</p>
            <ul>
                <li>Partner with Community Organizations: Collaborate with groups that support diversity, equity, and inclusion.</li>
                <li>Support Diverse Suppliers: Promote diversity in our supply chain by engaging with diverse suppliers and vendors.</li>
                <li>Participate in DEI Events: Take part in and sponsor events and initiatives that promote DEI in our industry and community.</li>
            </ul>
        </div>

        <div class="sectional-text">
            <h2 class="title">Conclusion</h2>
            <p class="description">At CREKAA, our commitment to diversity, equity, and inclusion is integral to who we are and how we operate. We believe that by embracing DEI, we can create a more innovative, dynamic, and successful organization. We are dedicated to building a workplace where everyone feels valued, empowered, and able to contribute to their fullest potential. Together, we can achieve our DEI goals and create a brighter future for all.</p>
            <p class="description">We invite all employees to join us on this journey towards a more diverse, equitable, and inclusive workplace.</p>
            <p class="description">CREKAA</p>
            
        </div>
    </div>


</section>
</template>
<script>
export default {
    name:'report',created(){
    document.title = "Our Report - Crekaa";
  },
  head() {
    return {
      title: "Our Report - Crekaa",
      meta: [
        { hid: 'description', name: 'description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'author', name: 'author', content: "Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        
        // <!-- Facebook Open Graph tags -->
        { hid: 'og:url', property: 'og:url', content: "https://crekaa.com/" },
        { hid: 'og:type', property: 'og:type', content: "website" },
        { hid: 'og:title', property: 'og:title', content: "Crekaa, Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'og:description', property: 'og:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'og:image', property: 'og:image', content: "../../assets/logo.png" },
        // Add more meta tags as needed

        // <!-- Twitter Card tags -->
        { hid: 'twitter:card', name: 'twitter:card', content: "summary_large_image" },
        { hid: 'twitter:title', name: 'twitter:title', content: "Crekaa , Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'twitter:description', name: 'twitter:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'twitter:image', name: 'twitter:image', content: "../../assets/logo.png" },

      ]
    };
  },

}
</script>
<style scoped>
.sectional-text{
    text-align:start;
    
}
.title{
    font-size:20px;
    font-weight: bold;
    margin-bottom:10px;
}
.description{
    font-size:18px;
}
</style>