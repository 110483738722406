<template>
    <main style="min-height: 100vh;">
        
            <br><br>
            <div id="headerimage"></div>
            <div class="innovice" style="z-index: 2;text-align: start;">
                <div class="company-section flx-inovice-inverse justify-content-space-between">
                    <div class="info flx-inovice gap10 mt10">
                        <div class="logo"></div>
                        <div class="content">
                            <h2>Crekaa Technologies</h2>
                            <p> crekaa.technologies@gmail.com <br/>
                                <span>Notification ID.: </span>{{notification ? notification.fields.notification_id : ""}}
                            </p>
                        </div>
                    </div><!--End Info-->

                    <div class="title">
                        <h1>Notification </h1>
                        <p>Issued: {{ notification ?  notification.fields.datetime : '' }} <br>
                        Status: {{ notification ? notification.fields.status : '' }}<br>
                        </p>
                    </div>
                    <!-- End Title -->
                    
<!-- crekaa -->
                </div>
                <div class="individual-section flx-inovice-clin justify-content-space-between">
                    <div class="info flx-inovice gap10 mt10">
                        <div class="logo-user">
                            <a-image :preview="false" :src="this.$store.state.userDetils.length ?  this.$store.state.userDetils[0].profile_picture : '' " width="60px" height="60px"
                                style="border-radius: 60px;" alt=""/>
                        </div>
                        <div class="content">
                            <h2>{{this.$store.state.userDetils.length ?  this.$store.state.userDetils[0].user.first_name+" "+ this.$store.state.userDetils[0].user.last_name : ''}}</h2>
                            <p>{{this.$store.state.userDetils.length ?  this.$store.state.userDetils[0].user.email : ''}}<br> 
                                <br/>
                            </p>
                        </div>
                    </div><!--End Info-->

                    <div class="title">
                        <h2 class="proj-title">Notification Title</h2>
                        <p class="proj-desc">{{ notification ? notification.fields.title : '' }}.</p>
                    </div><!--End Title-->
                </div>


                <p class="desc-bill">
                    <b>Subject:</b> {{   notification ? notification.fields.subject : ''  }} <br><br>
                    <b>Description:</b> {{  notification ? notification.fields.description :''}}
                </p>
                <p class="legal"><strong>Thank you for your business!</strong> Have A great day :)
                </p>
            </div>
            <br>

           </main>
</template>
<script>
import axios from 'axios';

export default {
  name: "notification_details",
  data() {
    return {
      notification: null,
    };
  },
  watch: {
    '$route.params.notification_id': 'fetchData',
  },
  mounted() {
    this.fetchData();
  },
  created(){
    document.title = "Notification Details - Crekaa";
  },
  head() {
    return {
    //   title: "Crekaa",
      meta: [
        { hid: 'description', name: 'description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'author', name: 'author', content: "Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        
        // <!-- Facebook Open Graph tags -->
        { hid: 'og:url', property: 'og:url', content: "https://crekaa.com/" },
        { hid: 'og:type', property: 'og:type', content: "website" },
        { hid: 'og:title', property: 'og:title', content: "Crekaa, Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'og:description', property: 'og:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'og:image', property: 'og:image', content: "../../assets/logo.png" },
        // Add more meta tags as needed

        // <!-- Twitter Card tags -->
        { hid: 'twitter:card', name: 'twitter:card', content: "summary_large_image" },
        { hid: 'twitter:title', name: 'twitter:title', content: "Crekaa , Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'twitter:description', name: 'twitter:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'twitter:image', name: 'twitter:image', content: "../../assets/logo.png" },

      ]
    };
  },

  methods: {
    formattedDate(date_time) {
      const date = new Date(date_time);
      const options = { year: "numeric", month: "short", day: "numeric" };
      return date.toLocaleString("en-US", options);
    },
    async fetchData() {
      this.$store.commit('setIsLoading', true);

      const noti_id = this.$route.params.notification_id;
      await axios
        .post("notification-details/" + noti_id)
        .then(response => {
          this.notification = response.data['Notification'][0];
        })
        .catch(error => {
          console.error(JSON.stringify(error.response.data));
          console.error(JSON.stringify(error));
        });

      this.$store.commit('setIsLoading', false);
    },
  },
};
</script>
<style scoped>
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,900,700,500,300,100);



h1 {
    font-size: 1.5em;
    /* color: #222; */
}

h2 {
    font-size: .9em;
}

h3 {
    font-size: 1.2em;
    font-weight: 300;
    line-height: 2em;
}

p {
    font-size: .7em;
    /* color: #666; */
    line-height: 1.2em;
}

#headerimage {
    z-index: 1;
    position: relative;
    /* top: -10px; */
    height: 400px;
    background-image: url('http://michaeltruong.ca/images/invoicebg.jpg');
    background-repeat: no-repeat;

    /* -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, .15), inset 0 -2px 4px rgba(0, 0, 0, .15);
    -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, .15), inset 0 -2px 4px rgba(0, 0, 0, .15);
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, .15), inset 0 -2px 4px rgba(0, 0, 0, .15); */
    overflow: hidden;
    background-attachment: fixed;
    background-size: 1920px 80%;
    background-position: 50% -90%;
}

.innovice {
    position: relative;
    margin-top: -290px;
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
    backdrop-filter: blur(10px);
    background: var(--card-color);
    padding: 20px
}

.logo {
    float: left;
    height: 60px;
    width: 60px;
    background: url(../../assets/logo.png) no-repeat;
    background-size: 60px 60px;
    border-radius: 60px;
}

.logo-user {
    float: left;
    height: 60px;
    width: 60px;
    /* background: url({{userprofile.profile_picture.url}}) no-repeat; */
    background-size: 60px 60px;
    border-radius: 60px;
}

.flx-inovice {
    display: flex;
}

.flx-inovice-inverse {
    display: flex;
}

.flx-inovice-clin {
    display: flex;
}

.justify-content-space-between {
    justify-content: space-between;
}

.justify-content-space-arround {
    justify-content: space-around;
}

.justify-content-space-ceevenly {
    justify-content: space-evenly;
}

.gap10 {
    gap: 10px
}

.mt10 {
    margin-top: 10px;
}

.company-section {
    border-bottom: 1px solid  var(--devide-color);
    margin-bottom:10px
}

.proj-desc {
    max-width: 400px;
    font-size: 12px;
}

.desc-bill {
    margin-top:10px;
    border: 1px solid var(--devide-color);
    padding: 10px;
    border-radius: 5px
}

table {
    width: 100%;
    border-collapse: collapse;
}

td {
    padding: 5px 0 5px 15px;
    border: 1px solid #EEE
}

.tabletitle {
    padding: 5px;
    background: #EEE;
}

@media screen and (max-width: 720px) {
    /* Add your CSS styles for devices with 720p resolution or higher here */
    /* For example: */

    .flx-inovice-clin {
        flex-direction: column;
        display: flex;
    }

    .flx-inovice-inverse {
        flex-direction: column-reverse;
        display: flex;
    }

    .proj-desc {
        max-width: 100%;
        font-size: 12px;
    }

    .proj-title {
        font-size: 15px;
        text-align: center;
    }
}


.text-color-Completed {
    color: rgb(28, 130, 0);
    background: rgba(210, 255, 198, 0.441);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: 4px;
}

.text-color-Pending {
    color: rgb(184, 187, 0);
    background: rgba(184, 187, 0, 0.441);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: 4px;
}

.text-color-Due {
    color: rgba(255, 0, 0);
    background: rgba(255, 0, 0, 0.141);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: 4px;
}

.text-color-Un-Paid {
    color: rgb(255, 0, 85);
    background: rgba(255, 0, 85, 0.141);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    margin-top: 4px;
    padding-bottom: 2px;
}</style>