<template>
    <br><br>
    <section div style="min-height:100vh;padding:10px;line-height: 30px;font-size: 14px;">
        <h1 style="font-size:52px;line-height: 40px;">Vaccination Policy</h1>
            <p style="font-size:22px;width:100%;max-width:800px;margin:auto">Our vaccination policy outlines the guidelines and expectations for employees regarding vaccination requirements. This policy ensures the health and safety of all employees while maintaining a productive and collaborative work environment.</p>

<div class="sectional-text">
    <h1 class="title">
        1. Introduction
    </h1>
    <p class="description">
        Crekaa technologies is committed to ensuring the health and safety of all employees while respecting individual choices regarding COVID-19 vaccination. This policy outlines the company’s stance on COVID-19 vaccinations and the guidelines for employees.
    </p>
</div>

<div class="sectional-text">
    <h1 class="title">
        2. Vaccination Requirement
    </h1>
    <p class="description">
        Vaccination against COVID-19 is not mandatory for employees at [Your Company Name]. We respect the personal decisions of our employees regarding vaccinations and do not require proof of vaccination as a condition of employment.
    </p>
</div>
<div class="sectional-text">
    <h1 class="title">
        3. Encouragement of Vaccination
    </h1>
    <p class="description">
        While vaccination is not required, [Your Company Name] encourages all employees to consider getting vaccinated to protect themselves, their families, and their colleagues. We believe vaccination is a key measure in preventing the spread of COVID-19.
    </p>
</div>
<div class="sectional-text">
    <h1 class="title">
        4. Safety Measures
    </h1>
    <p class="description">
        To maintain a safe working environment, the following safety measures will be in place regardless of vaccination status:

Remote Work: Employees are encouraged to work remotely where possible to reduce the risk of exposure.
Health Monitoring: Employees should monitor their health and stay home if they feel unwell or exhibit any symptoms of COVID-19.
Hygiene Practices: Employees are encouraged to practice good hygiene, including regular handwashing and using hand sanitizers.
Mask Wearing: Masks are recommended in shared office spaces and where social distancing cannot be maintained.
    </p>
</div>
<div class="sectional-text">
    <h1 class="title">
        5. Reporting Illness
    </h1>
    <p class="description">
       Employees who test positive for COVID-19 or have been in close contact with someone who has tested positive should notify their supervisor and HR immediately. The company will follow appropriate protocols to ensure the safety of all employees.
    </p>
</div>
<div class="sectional-text">
    <h1 class="title">
6. Support for Vaccinated Employees
    </h1>
    <p class="description">
For employees who choose to get vaccinated, [Your Company Name] offers the following support:

Flexible Scheduling: Employees can take time off for vaccination appointments without penalty. Flexible scheduling can be arranged with the supervisor.
Paid Leave: In case of side effects from the vaccine, employees are eligible for paid sick leave to recover.
    </p>
</div>
<div class="sectional-text">
    <h1 class="title">
        7. Privacy and Confidentiality
    </h1>
    <p class="description">
        Employees’ vaccination status and any related medical information will be kept confidential in accordance with applicable privacy laws and company policies.
    </p>
</div>
<div class="sectional-text">
    <h1 class="title">
       8. Workplace Adjustments
    </h1>
    <p class="description">
        For employees who are not vaccinated, the company will make reasonable accommodations to ensure their safety and the safety of others, which may include:
        Allowing continued remote work.
Providing additional PPE if needed.
Adjusting workspace to maintain social distancing.</p>
</div>










<div class="sectional-text">
    <h1 class="title">
        9. Communication
    </h1>
    <p class="description">
        The company will provide regular updates and information about COVID-19, vaccination opportunities, and health and safety guidelines. Employees are encouraged to stay informed and reach out to HR with any questions or concerns.
    </p>
</div>

<div class="sectional-text">
    <h1 class="title">
        10. Review and Updates
    </h1>
    <p class="description">
        This Vaccination Policy will be reviewed periodically and updated as necessary to reflect changes in public health guidelines and business needs. Employees will be notified of any significant changes to the policy.
    </p>
</div>

<p>
    For any questions or additional information regarding this policy, please contact the HR department.
</p>

    </section>
</template>
<script>
export default {
    name:'vaccinationPolicy',
    created(){
    document.title = "Vaccination Policy - Crekaa";
  },
  head() {
    return {
      title: "Vaccination Policy - Crekaa",
      meta: [
        { hid: 'description', name: 'description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'author', name: 'author', content: "Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        
        // <!-- Facebook Open Graph tags -->
        { hid: 'og:url', property: 'og:url', content: "https://crekaa.com/" },
        { hid: 'og:type', property: 'og:type', content: "website" },
        { hid: 'og:title', property: 'og:title', content: "Crekaa, Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'og:description', property: 'og:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'og:image', property: 'og:image', content: "../../assets/logo.png" },
        // Add more meta tags as needed

        // <!-- Twitter Card tags -->
        { hid: 'twitter:card', name: 'twitter:card', content: "summary_large_image" },
        { hid: 'twitter:title', name: 'twitter:title', content: "Crekaa , Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'twitter:description', name: 'twitter:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'twitter:image', name: 'twitter:image', content: "../../assets/logo.png" },

      ]
    };
  },
}
</script>
<style scoped>
.sectional-text{
    text-align:start;
    
}
.title{
    font-size:20px;
    font-weight: bold;
    margin-bottom:10px;
}
.description{
    font-size:18px;
}
</style>