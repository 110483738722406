<template>
    <div class="nav-slider">

        <div v-if="this.$store.state.token">
            <!-- <ul class=""> -->
            <a-row type="flex" justify="center" class="hover-a action-user" style="padding:5px;
                                                            border-radius:10px;
                                                            font-weight:bold;
                                                            color:var(--text-color);
                                                        background-color:rgba(128, 128, 128, 0.238)">

                <a-col :sm="9" :xs="9" :md="0" :lg="0" style="text-align: center;">
                    <img :src="this.$store.state.userDetils[0] ? this.$store.state.userDetils[0].profile_picture : 'unavailable'"
                        style="
                                                    width:50px;
                                                    margin-top:5px;
                                                    padding:0;
                                                    height:50px;
                                                    border:2px solid grey ;
                                                    border-radius:50px" alt="Avatar">
                </a-col>
                <a-col :sm="15" :xs="15" :md="0" :lg="0">


                    <h1 style="font-size: 14px;font-weight: 500;margin:0;margin-top:10px">
                        Signed in as</h1>


                    <h1 style="font-size: 16px; margin-top:0"><b> {{ this.$store.state.userDetils[0] ?
                        this.$store.state.userDetils[0].user.first_name + " "
                        + this.$store.state.userDetils[0].user.last_name : 'unavailable' }}</b> </h1>



                </a-col>
            </a-row>


            <hr>

            <router-link to="/my-profile" class="menu__item">
                <a-row>
                    <a-col :sm="1"> <ion-icon style="font-size: 20px;" class="icon-user-options"
                            name="person-outline"></ion-icon> &nbsp;</a-col>
                    <a-col :sm="23"> <span class="fa-14" style="font-size: 14px; font-weight: bold;">My
                            Profile</span></a-col>
                </a-row>
            </router-link>

            <router-link to="/my-dashboard" class="menu__item">
                <a-row>
                    <a-col :sm="1"> <ion-icon style="font-size: 20px;" name="bar-chart-outline"></ion-icon> &nbsp;</a-col>
                    <a-col :sm="23"> <span class="fa-14" style="font-size: 14px; font-weight: bold;">My
                            Dashboard</span></a-col>
                </a-row>
            </router-link>
            <router-link to="/my-patches" class="menu__item">
                <a-row>
                    <a-col :sm="1"> <ion-icon style="font-size: 20px;" name="barcode-outline"></ion-icon> &nbsp;</a-col>
                    <a-col :sm="23"> <span class="fa-14" style="font-size: 14px; font-weight: bold;">My
                            Patches</span></a-col>
                </a-row>
            </router-link>
            <router-link to="/my-billings" class="menu__item">
                <a-row>
                    <a-col :sm="1"> <ion-icon style="font-size: 20px;" name="card-outline"></ion-icon> &nbsp;</a-col>
                    <a-col :sm="23"> <span class="fa-14" style="font-size: 14px; font-weight: bold;">My
                            Billings</span></a-col>
                </a-row>
            </router-link>
            <router-link to="/my-settings" class="menu__item">
                <a-row>
                    <a-col :sm="1"> <ion-icon style="font-size: 20px;" name="cog-outline"></ion-icon> &nbsp;</a-col>
                    <a-col :sm="23"> <span class="fa-14" style="font-size: 14px; font-weight: bold;">Settings</span></a-col>
                </a-row>
            </router-link>
            <router-link to="/my-notifications" class="menu__item">
                <a-row>

                    <a-col :sm="1"> <ion-icon style="font-size: 20px;" name="notifications-outline"> </ion-icon> &nbsp;
                    </a-col>

                    <a-col :sm="23"> <span class="fa-14" style="font-size: 14px; font-weight: bold;">My Notifications</span>
                        <span class="noti" style="">{{ notificatios_count }}</span></a-col>
                </a-row>
            </router-link>


            <hr>

            <!-- </ul> -->
        </div>
        <br>

        <li class="menu__item " data-sub="developer"><router-link to="/developement" class="menu__item">
            <img src="https://www.svgrepo.com/show/131582/browser.svg" style="width:30px" alt="Developer Icon">
  Developer</router-link>
        </li>
        <li class="menu__item " data-sub="developer"><router-link to="/crekaa.ui" class="menu__item">
            <img src="https://www.svgrepo.com/show/530455/cloud-acceleration.svg" style="width:30px" alt="Developer Icon">
  Crekaa.UI</router-link>

        </li>
        <li class="menu__item " data-sub="developer"><router-link to="/crekaa-careers" class="menu__item">
            <img src="https://www.svgrepo.com/show/49088/looking-for-job.svg" style="width:30px" alt="Developer Icon">
  Crekaa Careers</router-link>
  
        </li>
        <li class="menu__item" data-sub="product"><router-link to="/products" class="menu__item">
            <img src="https://www.svgrepo.com/show/755/ranking.svg" style="width:30px" alt="Developer Icon">
                Products</router-link></li>
        <li class="menu__item " data-sub="customers"><router-link to="/our-customers" class="menu__item">
            <img src="https://www.svgrepo.com/show/120892/customer.svg" style="width:30px" alt="Developer Icon">
                Customers</router-link></li>
        <li class="menu__item " data-sub="our-experties"><router-link to="/our-experties" class="menu__item">
            <img src="https://www.svgrepo.com/show/4051/speedometer.svg" style="width:30px" alt="Developer Icon">
                Our Experties</router-link></li>
        <li class="menu__item " data-sub="about"><router-link to="/documentation" class="menu__item">
            <img src="https://www.svgrepo.com/show/86499/newspaper.svg" style="width:30px" alt="Developer Icon">

                Documentations</router-link></li>

                <li class="menu__item" data-sub="about"><router-link to="/dashboards" class="menu__item">
                    <img src="https://www.svgrepo.com/show/70897/analytics.svg" style="width:30px" alt="Developer Icon">
                    Dashboards</router-link></li>
<hr>
        <li class="menu__item credits_details_terms" data-sub="about"><router-link to="/credit-terms" class="menu__item">
                Credits</router-link></li>
        <li class="menu__item reedm_credits" data-sub="about"><router-link to="/redeedm-credit" class="menu__item">
                Reedem Credits</router-link></li>

        <!-- <li class="menu__item about-nav" data-sub="about"><router-link to="/developement" class="menu__item"  >
About</router-link></li> -->

        <br>
        <hr>

        <div v-if="!this.$store.state.token">

            <h6 style="margin-top: 20px;margin-bottom: 20px;font-size: 12px;color: var(--text-color);">Manual
                Signin/Register
            </h6>
            <li class="signin-lst "> <router-link to="/signin">
                    <a-text-button>
                        <img src="https://www.svgrepo.com/show/227633/login.svg" style="width:30px" alt="Developer Icon">

                        SignIn</a-text-button></router-link></li>
            <li class=""> <router-link to="/signup"> <a-text-button>
                <img src="https://www.svgrepo.com/show/276252/magazine.svg" style="width:30px" alt="Developer Icon">
                Get Started</a-text-button></router-link>
            </li>
            <hr>
            <h6 style="margin-top: 20px;margin-bottom: 20px;font-size: 12px;color: var(--text-color);">One Click
                Sign/Register
            </h6>

            <!-- need the google login  -->
            <!-- <form method="post" action="#"> -->

            <!-- <a-button type="submit" class="btn" block style="
            height:55px;border-radius:10px;background-color: rgb(104, 115, 237);"> -->
            <GoogleLogin :callback="callback" auto-login />
            <!-- </a-button> -->


        </div>
        <div v-else>
            <!-- welcome User -->
            <br>
            <br>
            <br>

            <a-button type="primary" size="large" style="height:40px;border-radius:10px" block @click="logout()">
                <a-row type="flex" justify="center">
                    <a-col :sm="1" :xs="1">
                        <ion-icon style="font-size: 18px;padding-top:3px" name="log-out-outline"></ion-icon>
                    </a-col>
                    <a-col :sm="23" :xs="23">
                        <span style="font-size: 14px;">SignOut</span>

                    </a-col>
                </a-row>
            </a-button>
        </div>

        <!-- </form> -->


    </div><br><br><br><br><br>

</template>
<script>
import axios from 'axios'



import { notification } from 'ant-design-vue';
import { decodeCredential } from 'vue3-google-login'
export default {
    name: "nav_slider",
    data() {
        return {
            notificatios_count: 0,

            callback: (response) => {
                // console.log("Logged In ", response)
                this.credentials = decodeCredential(response.credential)
                this.loginUser(this.credentials)
            },
        }
    },
    mounted() {
        if (localStorage.getItem("token")) {
            this.fetchNotifications();
        }

    },
    methods: {

        async loginUser(data) {
            this.$store.commit('setIsLoading', true)
            await axios
                .post("google-login-register-crekaa-one-API/", data)
                .then(response => {
                    const token = response.data['token']
                    // console.log("login success ")
                    // console.log(response.data)
                    // console.log("Token Are "+token)


                    this.$store.commit('setToken', token)
                    axios.defaults.headers.common["Authorization"] = "Token " + token
                    localStorage.setItem("token", token)
                    window.location.reload();
                    const toPath = this.$route.query.to || '/' // home path
                    this.$router.push(toPath)
                    this.fetchNotifications();

                    // console.log("done ")
                    // console.log(response.data)

                })
                .catch(error => {
                    console.log("Error Occured")
                    console.log(JSON.stringify(error.response.data))
                })

            await axios
                .post("user-details/")
                .then(response => {
                    // console.log(response);
                    // console.log(response.data);

                    const discription = response.data['0']['discription'];
                    const city = response.data['0']['city'];
                    const state = response.data['0']['state'];
                    const country = response.data['0']['country'];
                    const profile_picture = response.data['0']['profile_picture'];
                    const profile_background = response.data['0']['profile_background'];
                    const user = response.data['0']['user'];
                    const userData = {
                        discription: discription,
                        city: city,
                        state: state,
                        country: country,
                        profile_picture: profile_picture,
                        profile_background: profile_background,
                        user: user,
                    };

                    this.$store.commit('addUserDetails', userData);

                })
                .catch(error => {
                    console.log("===========error at user details")
                    console.log(JSON.stringify(error.response.data))
                    console.log(JSON.stringify(error))
                })
            this.$store.commit('setIsLoading', false)



        },
        async fetchNotifications() {
            this.$store.commit('setIsLoading', true)
            await axios
                .post("my-nav-notifications/")
                .then(response => {
                    // console.log(response);
                    // console.log(response.data);
                    // this.notificatios_nav = response.data["Notifications"]
                    this.notificatios_count = response.data["count"]
                })
                .catch(error => {
                    // console.log("===========error at user details")                        
                    console.log(JSON.stringify(error.response.data))
                    console.log(JSON.stringify(error))
                })
            this.$store.commit('setIsLoading', false)
        },
        logout() {
            axios.defaults.headers.common["Authorization"] = ""

            localStorage.removeItem("token")
            localStorage.removeItem("username")
            localStorage.removeItem("userid")

            this.$store.commit('removeToken')

            this.$router.push('/')
            this.$store.commit('removeUserDetails')
            // console.log("removal of userDetails")
            // console.log(this.$store.state.userDetils)
        },
    }
}
</script>
<style scoped>
.noti {
    background-color: red;
    border-radius: 20px;
    padding: 5px;
    color: var(--white)
}

li {
    /* font-size:20px; */
    list-style: none;
    font-weight: 700
}

.menu__item:hover {
    background-color: var(--hover-slide-button);
    border-radius: 10px;
}

.dev-nav {
    background: url(https://img.icons8.com/fluency/25/source-code.png) no-repeat left center;
    padding: 5px 10px 5px 30px;
}

.product-nav {
    background: url(https://img.icons8.com/fluency/25/overview-pages-3.png) no-repeat left center;
    padding: 5px 10px 5px 30px;
}

.customers-nav {
    background: url(https://img.icons8.com/color-glass/30/safety-collection-place.png) no-repeat left center;
    padding: 5px 10px 5px 30px;
}

.experties-nav {
    background: url(https://img.icons8.com/3d-fluency/30/bookmark.png) no-repeat left center;
    padding: 5px 10px 5px 30px;
}

.documentations {
    background: url(https://img.icons8.com/external-flaticons-lineal-color-flat-icons/30/external-documentation-productivity-flaticons-lineal-color-flat-icons-4.png) no-repeat left center;
    padding: 5px 10px 5px 30px;
}

.credits_details_terms {
    background: url(https://img.icons8.com/arcade/30/coins--v1.png) no-repeat left center;
    padding: 5px 10px 5px 30px;
}

.dashboards {
    background: url(https://img.icons8.com/external-flaticons-lineal-color-flat-icons/30/external-dashboard-computer-programming-icons-flaticons-lineal-color-flat-icons.png) no-repeat left center;
    padding: 5px 10px 5px 30px;
}

.reedm_credits {
    background: url(https://img.icons8.com/external-ddara-flat-ddara/30/external-target-digital-marketing-ddara-flat-ddara.png) no-repeat left center;
    padding: 5px 10px 5px 30px;
}

.about-nav {
    background: url(https://img.icons8.com/fluency/30/about.png) no-repeat left center;
    padding: 5px 10px 5px 30px;
}

.singin-nav {
    background: url(https://img.icons8.com/cute-clipart/35/login-rounded-right.png) no-repeat left center;
    padding: 5px 10px 5px 30px;
}

.singup-nav {
    background: url(https://img.icons8.com/cute-clipart/30/check.png) no-repeat left center;
    padding: 5px 10px 5px 30px;
}

a {
    padding: 15px 15px;
    font-size: 16px;
    display: block;
    text-decoration: none;
    color: var(--text-color);
}</style>
