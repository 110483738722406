
<template>
    <div class="job-details">
<br>

        <div class="head-page-scrolled-card sticky-header is-sticky "
            :class="{ 'invisible': !isScrolled, 'visible': isScrolled, }" style="z-index:21">
            <div class="content-head-page " style="max-width:1200px;margin:auto">

                <!-- <h1 style="color:white">{{job_data.job_career_offer }}</h1> -->
                <h1 style="color:rgb(0, 0, 0);">{{ job_data ?  job_data.job_career_offer[0].fields.job_title : " " }}</h1>
                <a-row>
                    <a-col :sm="24" :xs="24" :md="15" :lg="15">
                        <a-row style="display: flex;gap:20px">

                            <div class="content-sectioned">
                        <AimOutlined />
                        <!-- Location, India, XXXXXX -->
                        <span v-if="job_data" v-for=" loc in job_data.locations ">
                            {{loc.country_name}}&nbsp;
                            </span>
                    </div>
                    <div class="content-sectioned">
                        <AppstoreAddOutlined /> 
                        <span v-if="job_data" v-for=" tem in job_data.team ">
                            {{tem.team_name}}&nbsp;,
                            </span>
                        <!-- Software Engineering -->
                    </div>
                    <div class="content-sectioned">
                        <FieldTimeOutlined /> 
                        <span >{{ job_data ?  job_data.job_career_offer[0].fields.employement_type : " " }}
                            </span>
                        <!-- Full Time -->
                    </div>
                    <div class="content-sectioned">
                        <FileDoneOutlined /> Ref. - {{ job_data ?  job_data.job_career_offer[0].fields.job_number : " " }}
                    </div>

                        </a-row>
                    </a-col>
                    <a-col :sm="24" :xs="24" :md="9" :lg="9" style="padding-top:20px">
                        <a-row type="flex" justify="center">

                            <a-col :sm="12" :xs="12" :md="11" :lg="11">
                                <div class="content-sectioned">
                                    <router-link :to="'/apply-job-crekaa-careers/'+job_number "  v-if=" job_data ? job_data.job_career_offer[0].fields.is_active :false ">
                                <a-button style="width:90%;margin:auto"> Apply Now</a-button>
                            </router-link>
                           
                <div v-else>
                    <a-button style="width:90%;margin:auto;background-color: grey;">Not Available</a-button>
                </div>
                                </div>
                            </a-col>

                            <a-col :sm="12" :xs="12" :md="11" :lg="11">
                                <div class="content-sectioned">
                                    <a-button style="width: 90%; margin: auto;">Save Job</a-button>
                                </div>
                            </a-col>

                        </a-row>
                    </a-col>
                </a-row>

                <br>



            </div>

        </div>

        <div class="head-page" :class="{ 'visible': !isScrolled, 'invisible': isScrolled, }">
            <div class="content-head-page ">

                <h1 class="title-job" style="padding-left:10px;font-size: 2.0em;">{{ job_data ?  job_data.job_career_offer[0].fields.job_title : " " }}</h1>
                <!-- <h1 class="title-job" style="padding-left:10px">Senior Software Engineer</h1> -->
                
                <a-row style="display: flex; gap:20px;padding:20px">

                    <div class="content-sectioned">
                        <AimOutlined />
                        <!-- Location, India, XXXXXX -->
                        <span v-if="job_data" v-for=" loc in job_data.locations ">
                            {{loc.country_name}}&nbsp;
                            </span>
                    </div>
                    <div class="content-sectioned">
                        <AppstoreAddOutlined /> 
                        <span v-if="job_data" v-for=" tem in job_data.team ">
                            {{tem.team_name}}&nbsp;,
                            </span>
                        <!-- Software Engineering -->
                    </div>
                    <div class="content-sectioned">
                        <FieldTimeOutlined /> 
                        <span >{{ job_data ?  job_data.job_career_offer[0].fields.employement_type : " " }}
                            </span>
                        <!-- Full Time -->
                    </div>
                    <div class="content-sectioned">
                        <FileDoneOutlined /> Ref. - {{ job_data ?  job_data.job_career_offer[0].fields.job_number : " " }}
                    </div>

                </a-row>
                <br>
                <a-row style="padding: 10px;">

                    <a-col :sm="12" :xs="12" :md="12" :lg="12">
                        <div class="content-sectioned">
                            <router-link :to="'/apply-job-crekaa-careers/'+job_number "  v-if=" job_data ? job_data.job_career_offer[0].fields.is_active :false ">
                                <a-button style="width:90%;margin:auto"> Apply Now</a-button>
                            </router-link>
                           
                <div v-else>
                    <a-button style="width:90%;margin:auto;background-color: grey;">Not Available</a-button>
                </div>
                        </div>
                    </a-col>

                    <a-col :sm="12" :xs="12" :md="12" :lg="12">
                        <div class="content-sectioned">
                            <a-button style="width: 90%; margin: auto;">Save Job</a-button>
                        </div>
                    </a-col>

                </a-row>
            </div>
        </div>



        <div class="main-content-job-description">
            <a-row>
                <a-col :sm="24" :xs="24" :md="18" :lg="18" style="padding: 10px;">
                    <a-tabs v-model:activeKey="activeKey">
                        <a-tab-pane key="1" >
                             <template #tab>
                                <FileProtectOutlined /> Job Description
                            </template>
                            <div class="jd-info au-target" v-html="job_data ?  job_data.job_career_offer[0].fields.description_job : ''">
                                
                            </div>
                            <a-divider height="2px" style="background-color: black;"></a-divider>
                            <a-row style="padding: 10px;">

                                <a-col :sm="12" :xs="12" :md="12" :lg="12">
                                    <div class="content-sectioned">
                                        <router-link :to="'/apply-job-crekaa-careers/'+job_number "  v-if=" job_data ? job_data.job_career_offer[0].fields.is_active :false ">
                                <a-button style="width:90%;margin:auto"> Apply Now</a-button>
                            </router-link>
                                    <div v-else>
                                        <a-button style="width:90%;margin:auto;background-color: grey;">Not Available</a-button>
                                    </div>

                                    </div>
                                </a-col>

                                <a-col :sm="12" :xs="12" :md="12" :lg="12">
                                    <div class="content-sectioned">
                                        <a-button type="primary" style="width: 90%; margin: auto;">Save Job</a-button>
                                    </div>
                                </a-col>

                            </a-row>

                        </a-tab-pane>
                        <a-tab-pane key="2"  force-render> 
                                <template #tab>
                                    <ContactsOutlined /> Skills & requirements
                            </template>
                            <a-row>
                                <a-col :sm="24" :xs="24" :md="12" :lg="12"><CalendarOutlined /> {{job_data ?  job_data.job_career_offer[0].fields.experience_required : " "}} </a-col>
                                <a-col :sm="24" :xs="24" :md="12" :lg="12">{{ job_data ?  job_data.job_career_offer[0].fields.job_salary_currency : " " }}{{ job_data ?  job_data.job_career_offer[0].fields.salary_expected : " " }}</a-col>
                            </a-row>
                            <a-row>
                                <a-col :sm="24" :xs="24" :md="12" :lg="12"> <PushpinOutlined />Positions: {{ job_data ?  job_data.job_career_offer[0].fields.position_openings : "" }} </a-col>
                                <a-col :sm="24" :xs="24" :md="12" :lg="12"> <UserSwitchOutlined />Applicantions: {{ job_data ?  job_data.job_career_offer[0].fields.applicant_counts : "" }}  </a-col>
                            </a-row>
                            <br>
                            <h1>Key Skills:</h1>
                            <!-- {{ job_data ?    job_data.job_career_offer[0].fields.skills :'' }}  -->
                            <br>
                            <a-row>
                                <a-col :sm="24" :xs="24" :md="24" :lg="24">  </a-col>
                                <a-col :sm="24" :xs="24" :md="12" :lg="12">
                                    <span v-if="job_data">
                                        <a-tag v-for="skill in job_data.job_career_offer[0].fields.skills.split(',')" :key="skill" :bordered="false" :color="generateRandomColor()">
                                            {{ skill.trim() }}
                                        </a-tag>
                                        </span>
                                    <!-- <span v-if="job_data" v-for=" skill in job_data.job_career_offer[0].fields.skills ">
                                        <a-tag :bordered="false" :color="generateRandomColor()">{{skill.skill_name}}</a-tag> 
                            </span> -->
                            
                                </a-col>
                            </a-row>
 
                            <br>
                            <br>

                            <h1>Beware of imposters! </h1>
                            <a-row>
                            
                                <a-col :sm="24" :xs="24" :md="24" :lg="24">crekaa.com does not promise a job or an interview in exchange of money. Fraudsters may ask you to pay in the pretext of registration fee, Refundable Fee… <a style="color:blue">Read more</a></a-col>
                            </a-row>
                            <br>
                            <br>

                            <!-- <h1>Report This Job : </h1> -->
                            <a-row type="flex" justify="end">
                                <a-modal v-model:open="open"  @ok="handleOk">
                                    <template #title>
                                        <h1> <b>Report This Job</b></h1>
                                    </template>
                                   
                                     <a-checkbox v-model:checked="chk_report_1">Inappropriate Content</a-checkbox> <br>
 <a-checkbox v-model:checked="chk_report_2">Incomplete information about job / company</a-checkbox> <br>
 <a-checkbox v-model:checked="chk_report_3">Fake job / Non-recruitment related job / Scam</a-checkbox> <br>
 <a-checkbox v-model:checked="chk_report_4">Duplicate of another job on the site</a-checkbox> <br>
 <a-checkbox v-model:checked="chk_report_5">Incorrect Email ID</a-checkbox> <br>
 <a-checkbox v-model:checked="chk_report_6">Phone number not contactable</a-checkbox> <br>
 <a-checkbox v-model:checked="chk_report_7">Other</a-checkbox> <br>

    </a-modal>
                                <a-col :sm="24" :xs="24" :md="12" :lg="12" style="display:flex;justify-content:end;margin-right:20px;color:blue"><a-button @click="showModal" type="text">Report This Job</a-button> </a-col>
                            </a-row>
                    <a-divider height="2px" style="background-color: black;"></a-divider>
                            
                        </a-tab-pane>
                    </a-tabs>





                </a-col>
                <a-col :sm="24" :xs="24" :md="6" :lg="6" style="padding: 10px;">
                    <div class="subscription">
                        <p>
                            <b>Get notified for similar jobs</b>
                            <br>
                            We use your email address with your consent to send you personalized job alerts. We will process
                            your personal information in accordance with the crekaa Talent Community Privacy Notice. You
                            can
                            withdraw your consent at any time by following the instructions in any of our messages or by
                            contacting us at support@crekaa.com.


                        </p>
                        <h2>Enter Email address (Required)</h2>
                        <a-input-group compact>
                            <a-input placeholder="johndoe123@gmail.com" style="width: 70%" />
                            <a-button type="primary">Submit</a-button>
                        </a-input-group>
                    </div>
                    <br>
                    <br>
                    <!-- <a-divider height="2px" style="background-color: black;"></a-divider>

                    <br>
                    <div class="simillar-jobs-section">

                        <h1>Similar Jobs</h1>
                        <a-list class="demo-loadmore-list" :loading="initLoading" item-layout="horizontal"
                            :data-source="list">
                            <template #loadMore>
                                <div v-if="!initLoading && !loading"
                                    :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }">
                                    <a-button @click="onLoadMore">loading more</a-button>
                                </div>
                            </template>
                            <template #renderItem="{ item }">
                                <router-link to='/job-details/name_last'>
                                    <a-list-item class="list-card-similar-job">
                                        

                                        <a-skeleton avatar :title="false" :loading="!!item.loading" active>
                                            <a-list-item-meta description="some content description">
                                                <template #title>
                                                    <a href="">{{ item.name.last }}</a>
                                                </template>
                                                <template #avatar>
                                                    <a-avatar :src="item.picture.large" />
                                                </template>
                                            </a-list-item-meta>

                                        </a-skeleton>
                                    </a-list-item>
                                </router-link>
                            </template>
                        </a-list>
                    </div> -->
                    <a-divider height="2px" style="background-color: black;"></a-divider>
                    <div class="sharethis">
                        <h1>Share This Opportunity</h1><br>
                        <a-row>
                            <a-col :sm="6" :xs="6" :md="6" :lg="6">
                                <div class="share-icon">
                                    <LinkedinOutlined />
                                </div>
                            </a-col>
                            <a-col :sm="6" :xs="6" :md="6" :lg="6">
                                <div class="share-icon">
                                    <FacebookOutlined />
                                </div>
                            </a-col>
                            <a-col :sm="6" :xs="6" :md="6" :lg="6">
                                <div class="share-icon">
                                    <MailOutlined />
                                </div>
                            </a-col>
                            <a-col :sm="6" :xs="6" :md="6" :lg="6">
                                <div class="share-icon">
                                    <TwitterOutlined />
                                </div>
                            </a-col>
                        </a-row>
                    </div>

                    <br>
                    <a-divider height="2px" style="background-color: black;"></a-divider>

                    <br>
                    <div class="explore-services">
                        <h1>Explore our services </h1><br>
                        <div class="card-services">
                            Computer Science & Engineering
                        </div>
                        <div class="card-services">
                            Full Stack Platform Developement
                        </div>
                        <div class="card-services">
                            Information Technology
                        </div>

                    </div>
                    <br>
                    <a-divider height="2px" style="background-color: black;"></a-divider>
                    <br>
                    <div class="explore-courses">
                        <h1>Explore our courses </h1><br>
                        <div class="card-services">
                            Engineering
                        </div>

                        <div class="card-services">
                            Information Technology
                        </div>

                    </div>
                </a-col>
            </a-row>
            <br>
            <!--
            <a-divider height="2px" style="border-color: black">
                <p> Jobs based on your browsing history </p>
            </a-divider>
            <br>

            <div>
                <a-row>
                    <a-col :sm="24" :xs="24" :md="8" :lg="8" style="padding:10px">
                        <div class="card-browsed-history-jobs">
                            <h1>Senior Software Engineer</h1>
                            <a-row>
                                <a-col :sm="12" :xs="12" :md="12" :lg="12"> Pune, India, 411006</a-col>
                                <a-col :sm="12" :xs="12" :md="12" :lg="12"> Software Engineering </a-col>
                            </a-row>
                            <h1>R-XXX XXX</h1>
                            <p class="description">

                                Our PurposeWe work to connect and power an inclusive, digital economy that benefits
                                everyone, everywhere by making transactions safe, simple, smart and accessible. Using secure
                                data and networks, part
                            </p>

                        </div>
                    </a-col>
                    <a-col :sm="24" :xs="24" :md="8" :lg="8" style="padding:10px">
                        <div class="card-browsed-history-jobs">
                            <h1>Senior Software Engineer</h1>
                            <a-row>
                                <a-col :sm="12" :xs="12" :md="12" :lg="12">
                                    <AimOutlined /> Pune, India, 411006
                                </a-col>
                                <a-col :sm="12" :xs="12" :md="12" :lg="12">
                                    <AppstoreAddOutlined /> Software Engineering
                                </a-col>
                            </a-row>
                            <h1>R-XXX XXX</h1>
                            <p class="description">

                                Our PurposeWe work to connect and power an inclusive, digital economy that benefits
                                everyone, everywhere by making transactions safe, simple, smart and accessible. Using secure
                                data and networks, part
                            </p>

                        </div>
                    </a-col>
                    <a-col :sm="24" :xs="24" :md="8" :lg="8" style="padding:10px">
                        <div class="card-browsed-history-jobs">
                            <h1>Senior Software Engineer</h1>
                            <a-row>
                                <a-col :sm="12" :xs="12" :md="12" :lg="12">
                                    <AimOutlined /> Pune, India, 411006
                                </a-col>
                                <a-col :sm="12" :xs="12" :md="12" :lg="12">
                                    <AppstoreAddOutlined /> Software Engineering
                                </a-col>
                            </a-row>
                            <h1>R-XXX XXX</h1>
                            <p class="description">

                                Our PurposeWe work to connect and power an inclusive, digital economy that benefits
                                everyone, everywhere by making transactions safe, simple, smart and accessible. Using secure
                                data and networks, part
                            </p>

                        </div>
                    </a-col>
                    <a-col :sm="24" :xs="24" :md="8" :lg="8" style="padding:10px">
                        <div class="card-browsed-history-jobs">
                            <h1>Senior Software Engineer</h1>
                            <a-row>
                                <a-col :sm="12" :xs="12" :md="12" :lg="12">
                                    <AimOutlined /> Pune, India, 411006
                                </a-col>
                                <a-col :sm="12" :xs="12" :md="12" :lg="12">
                                    <AppstoreAddOutlined /> Software Engineering
                                </a-col>
                            </a-row>
                            <h1>R-XXX XXX</h1>
                            <p class="description">

                                Our PurposeWe work to connect and power an inclusive, digital economy that benefits
                                everyone, everywhere by making transactions safe, simple, smart and accessible. Using secure
                                data and networks, part
                            </p>

                        </div>
                    </a-col>
                    <a-col :sm="24" :xs="24" :md="8" :lg="8" style="padding:10px">
                        <div class="card-browsed-history-jobs">
                            <h1>Senior Software Engineer</h1>
                            <a-row>
                                <a-col :sm="12" :xs="12" :md="12" :lg="12">
                                    <AimOutlined /> Pune, India, 411006
                                </a-col>
                                <a-col :sm="12" :xs="12" :md="12" :lg="12">
                                    <AppstoreAddOutlined /> Software Engineering
                                </a-col>
                            </a-row>
                            <h1>R-XXX XXX</h1>
                            <p class="description">

                                Our PurposeWe work to connect and power an inclusive, digital economy that benefits
                                everyone, everywhere by making transactions safe, simple, smart and accessible. Using secure
                                data and networks, part
                            </p>

                        </div>
                    </a-col>
                    <a-col :sm="24" :xs="24" :md="8" :lg="8" style="padding:10px">
                        <div class="card-browsed-history-jobs">
                            <h1>Senior Software Engineer</h1>
                            <a-row>
                                <a-col :sm="12" :xs="12" :md="12" :lg="12">
                                    <AimOutlined /> Pune, India, 411006
                                </a-col>
                                <a-col :sm="12" :xs="12" :md="12" :lg="12">
                                    <AppstoreAddOutlined /> Software Engineering
                                </a-col>
                            </a-row>
                            <h1>R-XXX XXX</h1>
                            <p class="description">

                                Our PurposeWe work to connect and power an inclusive, digital economy that benefits
                                everyone, everywhere by making transactions safe, simple, smart and accessible. Using secure
                                data and networks, part
                            </p>

                        </div>
                    </a-col>


                </a-row> 



            </div>-->
            <br>
        </div>

    </div>
</template>
<script>

import { nextTick } from 'vue';
import {
    AimOutlined, AppstoreAddOutlined, FieldTimeOutlined, FileDoneOutlined, LinkedinOutlined, FacebookOutlined, MailOutlined, TwitterOutlined,

    FileProtectOutlined,
    CommentOutlined,
    ContactsOutlined,
    CalendarOutlined,
PushpinOutlined,
UserSwitchOutlined,
StarFilled,
StarOutlined,
UserOutlined,
} from '@ant-design/icons-vue'

import axios from 'axios'
export default {
    name:"crekaa_career_job_details",

    components: {
        AimOutlined, AppstoreAddOutlined, FieldTimeOutlined, FileDoneOutlined, LinkedinOutlined,
        FacebookOutlined,
        MailOutlined,
        TwitterOutlined,

        FileProtectOutlined,
        CommentOutlined,
        ContactsOutlined,

        CalendarOutlined,
        PushpinOutlined,
        UserSwitchOutlined,

        StarFilled,
        StarOutlined,

        UserOutlined,
    },
    data() {
        return {
            open :false,
            chk_report_1:false,
            chk_report_2:false,
            chk_report_3:false,
            chk_report_4:false,
            chk_report_5:false,
            chk_report_6:false,
            chk_report_7:false,


            isScrolled: false,
            count: 6,
            fakeDataUrl: '',
            initLoading: true,
            loading: false,
            data: [],
            list: [],

            job_number:'',
            job_data:null,
            activeKey:'1',

 likes:0,
 dislikes:0,
 action:''


        };
    },
    mounted() {
        
        window.scrollTo(0, 0);
        this.fakeDataUrl = `https://randomuser.me/api/?results=${this.count}&inc=name,gender,email,nat,picture&noinfo`

        // fetch(this.fakeDataUrl)
        //     .then((res) => res.json())
        //     .then((res) => {
        //         this.initLoading = false;
        //         this.data = res.results;
        //         this.list = res.results;
        //     });
        window.addEventListener("scroll", this.checkIfScrolled);


        const job_number = this.$route.params.crekaa_job_id
        this.job_number = job_number
        this.fetch_Job()

    },
    beforeUnmount() {
        // Remove the scroll event listener when the component is about to be destroyed
        window.removeEventListener("scroll", this.checkIfScrolled);
    },
    methods: { generateRandomColor() {
      const colors = ['magenta', 'warning', 'blue', 'processing', 'orange']; // Add more colors if needed
      const randomColor = colors[Math.floor(Math.random() * colors.length)];
      return randomColor;
    },
  
async fetch_Job(){
                         this.$store.commit('setIsLoading', true)  

    await axios
    .get("job-details-career-page/"+this.job_number)
    .then((response) => {
        
        // console.log("------------response-------------")
    
// const api ={
//    "job_career_offer":[
//       {
//          "model":"boulotman_careers.job_career_offer",
//          "pk":47,
//          "fields":{
//             "job_number":"2023121847",
//             "employement_type":"Permanent Full-Time",
//             "job_title":"Senior SDE ",
//             "job_salary_currency":"USD $",
//             "description_job":"<p>we need SDE For Our Senior Workloads we need SDE For Our Senior Workloads we need SDE For Our Senior Workloads we need SDE For Our Senior Workloads we need SDE For Our Senior Workloads we need SDE For Our Senior Workloads we need SDE For Our Senior Workloads we need SDE For Our Senior Workloads we need SDE For Our Senior Workloads we need SDE For Our Senior Workloads we need SDE For Our Senior Workloads we need SDE For Our Senior Workloads we need SDE For Our Senior Workloads we need SDE For Our Senior Workloads we need SDE For Our Senior Workloads we need SDE For Our Senior Workloads we need SDE For Our Senior Workloads we need SDE For Our Senior Workloads we need SDE For Our Senior Workloads we need SDE For Our Senior Workloads we need SDE For Our Senior Workloads we need SDE For Our Senior Workloads we need SDE For Our Senior Workloads we need SDE For Our Senior Workloads <br/>we need SDE For Our Senior Workloads <br/>we need SDE For Our Senior Workloads <br/><br/><br/>we need SDE For Our Senior Workloads we need SDE For Our Senior Workloads we need SDE For Our Senior Workloads </p>",
//             "skills":"CPP , Machine Learnig , ",
//             "experience_required":"0 - 1 Year",
//             "salary_expected":"1200",
//             "position_openings":"1",
//             "applicant_counts":0,
//             "is_active":true,
//             "is_available":false,
//             "datetime":"2023-12-18T05:05:58.569Z"
//          }
//       }
//    ],
//    "job_career_offer_question":[
//       {
//          "id":69,
//          "question_id":"2023121847",
//          "question":"why are you best fit for this position?",
//          "question_required":false,
//          "related_job_career_offer_id":47,
//          "datetime":"2023-12-18T05:05:59.137Z"
//       },
//       {
//          "id":70,
//          "question_id":"2023121847",
//          "question":"why do you wanted to join us ?",
//          "question_required":true,
//          "related_job_career_offer_id":47,
//          "datetime":"2023-12-18T05:05:59.202Z"
//       }
//    ],
//    "locations":[
//       {
//          "id":27,
//          "location_id":"2023121847",
//          "country_name":"Australia",
//          "related_job_career_offer_id":47,
//          "datetime":"2023-12-18T05:05:59.266Z"
//       },
//       {
//          "id":28,
//          "location_id":"2023121847",
//          "country_name":"Canada",
//          "related_job_career_offer_id":47,
//          "datetime":"2023-12-18T05:05:59.338Z"
//       },
//       {
//          "id":29,
//          "location_id":"2023121847",
//          "country_name":"Russia",
//          "related_job_career_offer_id":47,
//          "datetime":"2023-12-18T05:05:59.412Z"
//       }
//    ],
//    "work_areas":[
//       {
//          "id":23,
//          "work_areas_id":"2023121847",
//          "name_work_area":"On Site",
//          "related_job_career_offer_id":47,
//          "datetime":"2023-12-18T05:05:59.475Z"
//       }
//    ],
//    "team":[
//       {
//          "id":19,
//          "team_id":"2023121847",
//          "team_name":"Anylatics",
//          "related_job_career_offer_id":47,
//          "datetime":"2023-12-18T05:05:59.549Z"
//       },
//       {
//          "id":20,
//          "team_id":"2023121847",
//          "team_name":"Problem Solving",
//          "related_job_career_offer_id":47,
//          "datetime":"2023-12-18T05:05:59.622Z"
//       }
//    ],
//    "skill":[
//       {
//          "id":30,
//          "skill_id":"2023121847",
//          "skill_name":"CPP",
//          "related_job_career_offer_id":47,
//          "datetime":"2023-12-18T05:05:59.750Z"
//       },
//       {
//          "id":31,
//          "skill_id":"2023121847",
//          "skill_name":"Machine Learnig",
//          "related_job_career_offer_id":47,
//          "datetime":"2023-12-18T05:05:59.878Z"
//       }
//    ],
//    "message":"Done To Post Job...",
//    "status":"ok",
//    "error":false
// }
        this.job_data=response.data

    }).catch((error)=>{
        console.log(error)
    })

                         this.$store.commit('setIsLoading', false)  

},


        // show Model
        showModal  ()  {
  this.open= true;
},
handleOk () {
//   console.log(e);
  this.open = false;
},
// comment section
        like ()  {
  likes.value = 1;
  dislikes.value = 0;
  action.value = 'liked';
},
dislike ()  {
  likes.value = 0;
  dislikes.value = 1;
  action.value = 'disliked';
},

// main JD 
        checkIfScrolled() {

            this.isScrolled = window.scrollY > 310;
            //   console.log(this.isScrolled)
        },
        onLoadMore() {
            this.loading = true;
            this.list = this.data.concat(
                [...new Array(this.count)].map(() => ({
                    loading: true,
                    name: {},
                    picture: {},
                }))
            );
            fetch(this.fakeDataUrl)
                .then((res) => res.json())
                .then((res) => {

                    const newData = this.data.concat(res.results);
                    this.loading = false;
                    this.data = newData;
                    this.list = newData;
                    nextTick(() => {
                        // Resetting window's offsetTop so as to display react-virtualized demo underfloor.
                        // In a real scene, you can use a public method of react-virtualized:
                        // https://stackoverflow.com/questions/46700726/how-to-use-public-method-updateposition-of-react-virtualized
                        window.dispatchEvent(new Event('resize'));
                    });
                });
        },
    },
};
</script>
<style scoped>
.card-browsed-history-jobs {
    max-width: 400px;
    text-align: start;
    /* border:1px solid black; */
    padding: 20px;
    background-color: rgb(238, 238, 238);
}

.visible {
    display: block;
    transition: all 2000ms
}

.invisible {
    display: none;
    transition: all 2000ms
}

.head-page-scrolled-card {
    justify-content: center;
    /* height: 150px; */
    /* background: rgb(16, 0, 60);
    background: linear-gradient(80deg, rgb(0, 63, 77) 0%, rgb(43, 226, 223) 16%, rgb(0, 9, 142) 72%, rgb(0, 0, 0) 100%); */
    color: rgb(0, 0, 0);
    background-image: url('../../assets/caeers_crekaa/image_bg_career.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.card-services {
    width: 100%;
    background-color: rgb(255, 246, 246);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 5px;
    /* pointer: */
}

.share-icon {
    width: 50px;
    height: 50px;
    /* border: 1px solid black; */
    border-radius: 100%;
    background-color: rgba(250, 235, 215, 0.47);
    font-size: 30px;
    display: flex;
    justify-content: center;
    padding-top: 10px
}

.demo-loadmore-list {
    /* border:1px solid black; */
    padding: 5px;
    border-radius: 5px;
    background-color: rgb(255, 252, 248)
}

.list-card-similar-job:hover {
    background-color: rgb(227, 227, 227);
}

p {
    font-size: medium;
    color: rgb(98, 98, 98)
}

.main-content-job-description {
    max-width: 1200px;
    margin: auto;
    /* margin-top: 10px; */
    padding: 20px;
    text-align: start;

}

.head-page {
    /* margin-top:10px; */
    display: flex;
    justify-content: center;
    /* height: 300px; */
    padding-bottom: 20px;
    /* background: rgb(16, 0, 60);
    background: linear-gradient(80deg, rgb(0, 63, 77) 0%, rgb(43, 226, 223) 16%, rgb(0, 9, 142) 72%, rgb(0, 0, 0) 100%); */
    background-image: url('../../assets/caeers_crekaa/image_bg_career.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.title-job {
    padding-top: 50px;
    font-size: 45px;
    color: rgb(0, 0, 0)
}

.content-head-page {
    max-width: 1000px;
    text-align: start
}

.content-sectioned {
    color: rgb(0, 0, 0)
}</style>