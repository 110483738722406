<template>
    <div v-if="user_details.length ? user_details[0].user.can_careers_hire : false" >

    <div style="background-color: var(--notification-color-bg);min-height:100vh"  v-if="this.$store.state.userDetils.length">
        <div >

            <br>
            <br>
            <h3>Welcome Admin.</h3>
            <div v-if="all_jobs.length" >
                <a-row>

                <a-col :sm="24" :xs="24" :lg="12" :md="12" v-for="(myappli, index) in all_jobs" :key="index" style="padding:5px">
            <a-card  >
            
                            <div style="text-align:start">
                                <a-row>
                                    <a-col :sm="24" :xs="24" :md="12" :lg="12" style="padding:10px">
                                        <h2> Application Index : {{index+1}}</h2>
                                        <h3 style="font-size: 12px;"><b>Name:</b> {{myappli.applicant_first_name}} {{myappli.applicant_last_name}}</h3>
                                        <h3 style="font-size: 12px;"><b>Applied For:</b> {{ myappli.application_job__job_title }}</h3>
                                        <br>
                                        <a-tag >Application ID. : {{myappli.application_number}}</a-tag>
                                        <a-tag >Job ID. : {{myappli.application_job__job_number}}</a-tag>
                                        <a-tag color="processing">Application Status: {{myappli.applicant_status}}</a-tag>
                                        <br><br>
                                        <router-link :to="'/application-details-crekaa-careers/'+myappli.application_number">
                                        <a-button type="primary" block> View Application </a-button>
                                        </router-link>
                                    
                                    </a-col>
                                    <a-col :sm="24" :xs="24" :md="12" :lg="12" style="padding:10px">
                                        <h3>Job Type: {{ myappli.application_job__employement_type }}</h3>
                                        <p style="font-size: 12px;"><b>Skills: </b> {{ myappli.application_job__skills }}</p>
                                        <p style="font-size: 12px;"><b>Phone: </b> {{ myappli.applicant_phone_number }}</p>
                                        <p style="font-size: 12px;"><b>Country: </b> {{ myappli.applicant_country }}</p>
                                        <p style="font-size: 12px;"><b>Salary: </b>{{myappli.application_job__job_salary_currency}} {{ myappli.application_job__salary_expected }}</p>
                                    </a-col>
                                    </a-row>
                            </div>
            
                        </a-card >
            </a-col>
        </a-row>

            <a-pagination v-model:current="current" simple :total="total_pages" @change="onChange" :pageSize="10" >
            
            </a-pagination>
                </div>
                            <div  v-else style="height:100%;display: flex;flex-direction: column;justify-content: center;">
                                <a-empty description="No Applications Found" />
                            </div>
        </div>

        <!-- <button @click="loadMore">Load More</button> -->

       
    </div>
    <div v-else>
        <br>
        <br>
        <br>
        <h1>Un Authenticated </h1>
    </div>
</div>

</template>
<script>
import axios from 'axios'
import { notification } from 'ant-design-vue';
import { RightSquareFilled } from '@ant-design/icons-vue';

export default {
    name:"admin_crekaa_career_all_recived_applications",
    data(){
        return{
            all_jobs:[],
            nextUrl: null,
            user_details:[],
            prevUrl:null,
            current:1,
            total_pages:0,
            loadmore_link:'',
            recivedData:null,
        }
    },
    mounted(){
        this.fetchAllJobApplicationsFromCareers()
this.fetchUserProfileDetails()
        
    },
    methods:{
        async fetchUserProfileDetails() {
            this.$store.commit('setIsLoading', true)

            await axios
                .post("user-details/")
                .then((response) => {
                    this.user_details = response.data
                    this.$store.commit('addUserDetails', response.data);

                    // console.log("=============-------------============-====-=-===-=-=-=-=-")
                    // console.log(this.user_details)

                }).catch((error) => {
                    console.log("error Occured while fetching User Data ")
                })

            this.$store.commit('setIsLoading', false)
},
        onChange(pageNumber) {
        //     console.log(this.nextUrl);
        // console.log('Page: ', pageNumber);
        axios.get(this.nextUrl.slice(0, -1) + String(pageNumber))
          .then(response => {
            this.all_jobs = response.data['results']['applications'];
            // this.nextUrl = response.data['next'];
            // this.prevUrl = response.data['previous'];

          })
          .catch(error => {
            console.error('Error loading more applications:', error);
          });
        },

        async fetchAllJobApplicationsFromCareers(){
           
        this.$store.commit('setIsLoading',false)

            await axios
            .get("fetch-all-applied-jobs-from-crekaa-careers/")
            .then(response => {
            // console.log("------------------------------------Got Response------------------------------------");
            // console.log(response.data);      
            this.recivedData=response.data;
            this.total_pages=response.data['count'];
            this.all_jobs= response.data['results']['applications'];
            this.nextUrl = response.data['next'];
            this.prevUrl = response.data['previous'];

                
            })
            .catch(error => {
            console.log(error),
                notification['error']({
                    message: 'Unable to fetch the all applications',
                    // description:'Unable To Login With Provided Credentials...',
                    placement: 'bottomRight',
                });
            },)
            this.$store.commit('setIsLoading',false)

        },

        loadMore() {
      if (this.nextUrl) {
        axios.get(this.nextUrl)
          .then(response => {
            console.log("2nd respose")
            console.log(response.data['results'])
            this.all_jobs = this.all_jobs.concat(response.data['results']['applications']);
            this.nextUrl = response.data['next'];
          })
          .catch(error => {
            console.error('Error loading more applications:', error);
          });
      }
    },

    }
}
</script>
<style scoped>
h5 {
    font-size: 14px;
}
h4 {
    font-size: 14px;

}
</style>