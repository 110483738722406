<template>
    <br><br>
    <section div style="min-height:100vh;padding:10px;line-height: 30px;font-size: 14px;">
        <h1 style="font-size:52px;line-height: 40px;">Remote Work Policy</h1>
        <h4 style="font-size:22px;width:100%;max-width:800px;margin:auto">
            Our remote work policy outlines the guidelines and expectations for employees who work remotely. This policy ensures a productive and collaborative work environment while maintaining work-life balance.
        </h4>

<div class="sectional-text">
    <h1 class="title">
        1. Introduction
    </h1>
    <p class="description">
        This Remote Work Policy outlines the guidelines and expectations for employees who work remotely for [Your Company Name], which specializes in IT services including web development and web designing. The policy aims to ensure productivity, maintain clear communication, and protect company data while offering the flexibility of remote work.
    </p>
</div>

<div class="sectional-text">
    <h1 class="title">
        2. Eligibility
    </h1>
    <p class="description">
        Remote work is available to full-time and part-time employees who have demonstrated a high level of self-motivation, reliability, and performance. Eligibility is determined by management based on the nature of the job and the employee's ability to meet performance expectations remotely.
    </p>
</div>
<div class="sectional-text">
    <h1 class="title">
        3. Remote Work Agreement
    </h1>
    <p class="description">
        Employees who wish to work remotely must complete a Remote Work Agreement, which outlines the terms and conditions of their remote work arrangement. This agreement must be approved by the employee's supervisor and HR.
    </p>
</div>
<div class="sectional-text">
    <h1 class="title">
        4. Work Schedule
    </h1>
    <p class="description">
        Remote employees are expected to work their regular hours unless otherwise agreed upon. Any changes to the work schedule should be discussed and approved by the supervisor in advance. Employees must be available during core hours for meetings and communication.
    </p>
</div>
<div class="sectional-text">
    <h1 class="title">
        5. Communication
    </h1>
    <p class="description">
        Effective communication is crucial for remote work. Employees are required to:
        <ul>
            <li>
                Use company-approved communication tools (e.g., email, chat, video conferencing).
            </li>
            <li>
                Be reachable during working hours via phone, email, or messaging apps.
            </li>
            <li>
                Attend scheduled meetings and conference calls punctually.
            </li>
        </ul>
    </p>
</div>
<div class="sectional-text">
    <h1 class="title">
6. Performance and Productivity
    </h1>
    <p class="description">
Employees are expected to maintain the same level of productivity and quality of work as when working on-site. Performance will be monitored through regular check-ins, project tracking tools, and performance reviews.
    </p>
</div>
<div class="sectional-text">
    <h1 class="title">
        7. Equipment and Workspace
    </h1>
    <p class="description">
        The company will provide the necessary equipment for remote work, such as laptops and software. Employees are responsible for setting up a suitable workspace that is free from distractions and conducive to productivity. The workspace should have a reliable internet connection.
    </p>
</div>
<div class="sectional-text">
    <h1 class="title">
        8. Data Security and Confidentiality
    </h1>
    <p class="description">
        Employees must adhere to the company’s data security policies, including:
        <ul>
            <li>
                Using company-provided VPNs for secure access to company systems.
            </li>
            <li>
                Keeping all company data confidential and secure.
            </li>
            <li>
                Reporting any security breaches or concerns immediately.
            </li>
        </ul>
    </p>
</div>
<div class="sectional-text">
    <h1 class="title">
        9. Expenses
    </h1>
    <p class="description">
        The company will reimburse pre-approved expenses related to remote work, such as internet costs and office supplies. Employees must submit receipts and an expense report for reimbursement.
    </p>
</div>

<div class="sectional-text">
    <h1 class="title">
        10. Health and Safety
    </h1>
    <p class="description">
        Employees are responsible for maintaining a safe and ergonomic workspace. The company will provide guidelines on ergonomic practices to prevent injury. Employees should report any work-related injuries to HR immediately.
    </p>
</div>
<div class="sectional-text">
    <h1 class="title">
        11. Termination of Remote Work
    </h1>
    <p class="description">
        The company reserves the right to terminate a remote work arrangement at any time based on business needs or if the employee fails to meet performance expectations. Employees may also request to end their remote work arrangement and return to on-site work.
    </p>
</div>
<div class="sectional-text">
    <h1 class="title">
        12. Compliance with Laws and Company Policies
    </h1>
    <p class="description">
        Remote employees must comply with all applicable laws and company policies, including those related to data protection, confidentiality, and workplace conduct.
    </p>
</div>
<div class="sectional-text">
    <h1 class="title">
        13. Review and Updates
    </h1>
    <p class="description">
        This Remote Work Policy will be reviewed periodically and updated as necessary to reflect changes in business needs or legal requirements. Employees will be notified of any significant changes to the policy.
    </p>
</div>
<p>
    For any questions or additional information regarding this policy, please contact the HR department.
</p>




    </section>
</template>
<script>
export default {
    name:'workplacemodulePolicy',
    created(){
    document.title = "Work Place Policy - Crekaa";
  },
  head() {
    return {
      title: "Work Place Policy - Crekaa",
      meta: [
        { hid: 'description', name: 'description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'author', name: 'author', content: "Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        
        // <!-- Facebook Open Graph tags -->
        { hid: 'og:url', property: 'og:url', content: "https://crekaa.com/" },
        { hid: 'og:type', property: 'og:type', content: "website" },
        { hid: 'og:title', property: 'og:title', content: "Crekaa, Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'og:description', property: 'og:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'og:image', property: 'og:image', content: "../../assets/logo.png" },
        // Add more meta tags as needed

        // <!-- Twitter Card tags -->
        { hid: 'twitter:card', name: 'twitter:card', content: "summary_large_image" },
        { hid: 'twitter:title', name: 'twitter:title', content: "Crekaa , Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'twitter:description', name: 'twitter:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'twitter:image', name: 'twitter:image', content: "../../assets/logo.png" },

      ]
    };
  },
}
</script>
<style scoped>
.sectional-text{
    text-align:start;
    
}
.title{
    font-size:20px;
    font-weight: bold;
    margin-bottom:10px;
}
.description{
    font-size:18px;
}
</style>