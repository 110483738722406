import { createRouter, createWebHistory } from 'vue-router'

import store from '../store'

// on route change show progressbar 
import NProgress from 'nprogress';
import 'nprogress/nprogress.css'

import HomeView from '../views/HomeView.vue'
import sign from '../views/authentication/signin'
import signup from '../views/authentication/signup'
import crekaa_ui from '../views/pages/crekaa_ui'
import dashboards from '../views/pages/dashboards'
import developements from '../views/pages/developements'
import products_page from '../views/pages/products'
import documentation from '../views/pages/documentation'
import contact_us from '../views/pages/contact_us'
import project_details from '../views/pages/project_details'
import workplacemodulePolicy from  '../views/pages/workplacemodulePolicy'
import report from  '../views/pages/report'
import privacyPolicy from  '../views/pages/privacyPolicy'
import vaccinationPolicy from  '../views/pages/vaccinationPolicy'
import my_dashboard from  '../views/user/dashboard'
import my_profile from  '../views/user/my_profile'

import my_billings from  '../views/user/my_billings'
import my_patches from  '../views/user/my_patches'
import bill_details from  '../views/user/bill_details'
import edit_profile from  '../views/user/edit_profile'
import my_notifications from  '../views/user/my_notifications'
import notification_details from  '../views/user/notification_details'
import my_settings from  '../views/user/settings'
import chat_realtime from  '../views/chat/chat_realtime'



import nav_main from '@/components/navigation/Navbar.vue'

import one_time_payment_plans from   '../views/pages/one_time_payment_plans'


import our_experties from '../views/pages/our_experties'
import our_customers from '../views/pages/our_customers'
import credit_terms from '../views/pages/credit_terms'
import reedm_credits from '../views/pages/reedm_credits'
import terms_and_conditions from '../views/pages/terms_and_conditions'

import plans_web  from '../views/plan_pages/plans_web.vue'
import standard_web from '../views/plan_pages/standard_web.vue'
import startup_web from '../views/plan_pages/startup_web.vue'
import custom_web from '../views/plan_pages/custom_web.vue'


import book_plan_startup_web from '@/components/plans/book_plan/book_plan_startup_web.vue' 
import book_plan_standard_web from '@/components/plans/book_plan/book_plan_standard_web.vue' 
import book_plan_custom_web from '@/components/plans/book_plan/book_plan_custom_web.vue' 

import standard_mobile from '../views/plan_pages/standard_mobile.vue'
import startup_mobile from '../views/plan_pages/startup_mobile.vue'
import custom_mobile from '../views/plan_pages/custom_mobile.vue'

import book_plan_startup_mobile from '@/components/plans/plans_mobile/book_plan/book_plan_startup_mobile.vue' 
import book_plan_standard_mobile from '@/components/plans/plans_mobile/book_plan/book_plan_standard_mobile.vue' 
import book_plan_custom_mobile from '@/components/plans/plans_mobile/book_plan/book_plan_custom_mobile.vue' 


import  crekaa_careers from "../views/careers/careers_crekaa"
import crekaa_career_job_details from "../views/careers/crekaa_career_job_details"
import job_apply_crekaa_career from "../views/careers/job_apply_crekaa_career"
import application_details_crekaa_careers from "../views/user/admin/application_details_crekaa_careers"
import createCareersJob from "../views/user/admin/createCareersJob"
import updateCareersJob from '../views/user/admin/updateJob.vue'
import update_job_content from "../views/user/admin/jd_update"
import userAdminProfile from "../views/user/admin/admin_crekaa" 
import admin_crekaa_career_all_recived_applications from "../views/user/admin/allRecivedApplications"
import userAccountDetailsAdminControll from "../views/user/admin/UserAccountDetails"
import AllUserProfiles from "../views/user/admin/AdminAllUserProfiles"
import allContactUsRequests from "../views/user/admin/allContactUsRequests"


const routes = [
  {
    path: '',
    name: 'home',
    component: HomeView
  },
  

  
// One time payment plans 
{
  path:'/chat-realtime',
  name:"chat_realtime",
  component:chat_realtime,
  meta: {
    requireLogin: true
}
},


// careers_section_crekaa crekaa_careers
{
  path: '/crekaa-careers',
  name: 'crekaa_careers',
  component: crekaa_careers
},

{
  path: '/admin-all-user-profiles',
  name: 'AllUserProfiles',
  component: AllUserProfiles,
  meta: {
    requireLogin: true
}
},

{
  path: '/crekaa-admin-user-account-details-access-controll/:crekaa_user_email',
  name: 'userAccountDetailsAdminControll',
  component: userAccountDetailsAdminControll
},

{
  path: '/crekaa-admin-all-contact-us-requests',
  name: 'allContactUsRequests',
  component: allContactUsRequests
},


{
  path: '/job-position-crekaa-careers/:crekaa_job_id',
  name: 'crekaa_career_job_details',
  component: crekaa_career_job_details
},

{
  path: '/apply-job-crekaa-careers/:crekaa_job_id',
  name: 'job_apply_crekaa_career',
  component: job_apply_crekaa_career,
  meta: {
    requireLogin: true
}
},

{
  path: '/application-details-crekaa-careers/:crekaa_application_id',
  name: 'application_details_crekaa_careers',
  component: application_details_crekaa_careers,
  meta: {
    requireLogin: true
}
},
{
  path: '/admin-crekaa-careers-recived-all-applications',
  name: 'admin_crekaa_career_all_recived_applications',
  component: admin_crekaa_career_all_recived_applications,
  meta: {
    requireLogin: true
  }
},

{
  path: '/update-job-content-crekaa-careers/:crekaa_job_id',
  name: 'update_job_content',
  component: update_job_content,
  meta: {
    requireLogin: true
}
},
{
  path: '/admin-crekaa-careers-update-jobs',
  name: 'updateCareersJob',
  component: updateCareersJob,
  meta: {
    requireLogin: true
  }
},
{
  path: '/admin-crekaa',
  name: 'admin_crekaa',
  component: userAdminProfile,
  meta: {
    requireLogin: true
  }
},

{
  path: '/admin-crekaa-create-job',
  name: 'admin_crekaa_create_job',
  component:createCareersJob,
  meta: {
    requireLogin: true
  }
},

// end careers_crekaa

  // One time payment plans 
  {
    path:'/maintainnce-payment-plans',
    name:"one_time_payment_plans",
    component:one_time_payment_plans,

  },
  // plans web 
  {
    path:'/plans-web',
    name:"plans_web",
    component:plans_web,

  },
  {
    path:'/plans-web/startup-web',
    name:"startup_web",
    component:startup_web,

  },
  {
    path:'/plans-web/standard-web',
    name:"standard_web",
    component:standard_web,

  },
  {
    path:'/plans-web/custom-web',
    name:"custom_web",
    component:custom_web,

  },
  // Book plans web 
  
  {
    path:'/plans-web/startup-web/book-plan-startup-web',
    name:"book_plan_startup_web",
    component:book_plan_startup_web,
    meta: {
      requireLogin: true
  }
  },
  {
    path:'/plans-web/standard-web/book-plan-standard-web',
    name:"book_plan_standard_web",
    component:book_plan_standard_web,
    meta: {
      requireLogin: true
  }
  },
  {
    path:'/plans-web/custom-web/book-plan-custom-web',
    name:"book_plan_custom_web",
    component:book_plan_custom_web,
    meta: {
      requireLogin: true
  }
  },
  // plans mobile

  {
    path:'/plans-mobile/standard-mobile',
    name:"standard_mobile",
    component:standard_mobile,

  },
  {
    path:'/plans-mobile/startup-mobile',
    name:"startup_mobile",
    component:startup_mobile,

  },
  {
    path:'/plans-mobile/custom-mobile',
    name:"custom_mobile",
    component:custom_mobile,

  },

  // Book plans mobile 
  
  {
    path:'/plans-mobile/startup-mobile/book-plan-startup-mobile',
    name:"book_plan_startup_mobile",
    component:book_plan_startup_mobile,
    meta: {
      requireLogin: true
  }
  },
  {
    path:'/plans-mobile/standard-mobile/book-plan-standard-mobile',
    name:"book_plan_standard_mobile",
    component:book_plan_standard_mobile,
    meta: {
      requireLogin: true
  }
  },
  {
    path:'/plans-mobile/custom-mobile/book-plan-custom-mobile',
    name:"book_plan_custom_mobile",
    component:book_plan_custom_mobile,
    meta: {
      requireLogin: true
  }
  },




  {
    path:'/user-order-details/:contract_id/',
    name:"project_details",
    component:project_details,
    meta: {
      requireLogin: true
  }
  },

  {
    path: '/my-profile',
    name: 'my_profile',
    component: my_profile,
    meta: {
      requireLogin: true
  }
  },
  {
    path: '/my-dashboard',
    name: 'my_dashboard',
    component: my_dashboard,
    meta: {
      requireLogin: true
  }
  },
  {
    path: '/documentation',
    name: 'documentation',
    component: documentation
  },
  {
    path: '/products',
    name: 'products_page',
    component: products_page
  },
  {
    path: '/developement',
    name: 'developements',
    component: developements
  },
  {
    path: '/dashboards',
    name: 'dashboards',
    component: dashboards
  },
  {
    path: '/crekaa.ui',
    name: 'crekaa_ui',
    component: crekaa_ui
  },
  {
    path: '/signin',
    name: 'signin',
    component: sign
  },
  {
    path: '/signup',
    name: 'signup',
    component: signup
  },

  {
    path: '/our-experties',
    name: 'our_experties',
    component: our_experties
  },

  {
    path: '/our-customers',
    name: 'our_customers',
    component: our_customers
  },

  {
    path: '/credit-terms',
    name: 'credit_terms',
    component: credit_terms
  },

  {
    path: '/redeedm-credit',
    name: 'reedm_credit',
  
    component: reedm_credits,
    meta: {
      requireLogin: true
  }
  },
  {
    path: '/terms-and-conditions',
    name: 'terms_and_conditions',
    component: terms_and_conditions
  },

  
  {
    path: '/contact-us',
    name: 'contact_us',
  
    component: contact_us,
  },
  
  
{
  path: '/workplacemodule-policy',
  name: 'workplacemodulePolicy',
  component: workplacemodulePolicy
},
{
  path: '/vaccination-policy',
  name: 'vaccinationPolicy',
  component: vaccinationPolicy
},
{
  path: '/report',
  name: 'report',
  component: report
},
{
  path: '/privacy-policy',
  name: 'privacyPolicy',
  component: privacyPolicy
},
  

  {
    path: '/edit-profile',
    name: 'edit_profile',
    component: edit_profile,
    meta: {
      requireLogin: true
  }
  },
  {
    path: '/bill-details/:bill_id',
    name: 'bill_details',
    component: bill_details,
    meta: {
      requireLogin: true
  }
  },
  {
    path: '/my-notifications',
    name: 'my_notifications',
    component: my_notifications,
    meta: {
      requireLogin: true
  }
  },
  {
    path: '/my-billings',
    name: 'my_billings',
    component: my_billings,
    meta: {
      requireLogin: true
  }
  },
  
  {
    path: '/my-patches',
    name: 'my_patches',
    component: my_patches,
    meta: {
      requireLogin: true
  }
  },
  
  {
    path: '/my-settings',
    name: 'my_settings',
    component: my_settings,
    meta: {
      requireLogin: true
  }},
  {
    path: '/notification-details/:notification_id',
    name: 'notification_details',
    component: notification_details,
    meta: {
      requireLogin: true
  }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

// show top progressbar on route change 
router.beforeResolve((to, from, next) => {
  if (to.name) {
      NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  NProgress.done()
})
// redirect the signin & signup url if iser is logged in already to my-dashboard
router.beforeEach((to, from, next) => {
  
  // closing the drawer if clicked on any link
  if (nav_main.open) {
    nav_main.open = false; // Close the drawer
  }//done

  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next({ name: 'signin', query: { to: to.path } });
  } else if ((to.name === 'signin' || to.name === 'signup') && store.state.isAuthenticated) {
    next({ name: 'my_dashboard' });
  } else {
    next();
  }
});
export default router
