<template>
    <br>
    <div style="min-height:100vh;background: rgba(0, 255, 255, 0.164);" v-if="user_details.length ? user_details[0].user.is_admin : false ">

        <!-- {{ userProfiles.results }} -->
        <a-row v-if="userProfiles.length" style="">
            <a-col v-for="user in userProfiles" style="padding:10px;" :sm="24" :xs="24" :md="8" :lg="8">
                <div style="padding:10px ">
                    <a-row>
                        <!-- <a-col :sm="0" :xs="0" :md="12" :lg="12">
                    <div style="display: flex;justify-content: center;">
                        <img :src="'https://boulotman.s3.amazonaws.com/media/'+user.background_picture" style="width:80%;border-radius:10px;max-height:250px;height:auto;object-fit: cover;" alt="">
                        <img :src="'https://boulotman.s3.amazonaws.com/media/'+user.profile_picture" class="card-img-top" alt="...">
                    </div>
                </a-col> -->
                        <a-col :sm="24" :xs="24" :md="24" :lg="24">
                            <div style="display: flex;justify-content: center;">
                                <img :src="'https://crekaa-2024.s3.amazonaws.com/media/' + user.profile_background"
                                    style="width:100%;border-radius:10px;max-height:150px;height:auto;object-fit: cover;"
                                    alt="">
                                <img :src="'https://crekaa-2024.s3.amazonaws.com/media/' + user.profile_picture"
                                    class="card-img-top-mobile" alt="...">
                                    
                            </div>
                        </a-col>
                        <a-col :sm="24" :xs="24" :md="24" :lg="24" style="padding:0px;">
                            <br><a-card style="text-align: start;">

                                <a-row>
                                    <a-col :sm="24" :xs="24" :md="12" :lg="12">
                                        <h3 style="font-size:14px;"><b>Name :</b><br> {{ user.user__first_name }} {{
                                            user.user__last_name }}</h3>
                                        <h3 style="font-size:14px;"><b>Date Of Birth :</b><br> {{ user.user__dob }} </h3>
                                    </a-col>
                                    <a-col :sm="24" :xs="24" :md="12" :lg="12">
                                        <h3 style="font-size:14px;"><b>Country :</b> {{ user.country ? user.country :
                                            "Unavailable" }} </h3>
                                        <h3 style="font-size:14px;"><b>State :</b> {{ user.state ? user.state : "Unavailable"
                                        }}</h3>
                                        <h3 style="font-size:14px;"><b>City :</b> {{ user.city ? user.city : "Unavailable" }}
                                        </h3>
                                    </a-col>
                                </a-row>

                                <h3 style="font-size:14px;overflow-x: hidden;text-overflow: ellipsis;"><b>Email :</b>
                                    {{ user.user__email }}</h3>
                                <h3 style="font-size:14px;"><b>Address Line 1 :</b><br> {{ user.address_line_1 ?
                                    user.address_line_1 : "Unavailable" }} </h3>
                                <h3 style="font-size:14px;"><b>Address Line 2 :</b><br> {{ user.address_line_2 ?
                                    user.address_line_2 : "Unavailable" }} </h3>
                                <router-link :to="'/crekaa-admin-user-account-details-access-controll/'+ user.user__email">
                                    <a-button block> Account Details</a-button>

                                </router-link>
                            </a-card>
                        </a-col>
                    </a-row>


                </div>
                <br>
            </a-col>
            <!-- </div> -->
            <a-divider color="black"></a-divider>
        </a-row>
        <!-- </div> -->
        <br>
        <a-pagination v-model:current="current" simple :total="total_pages" @change="onChange" :pageSize="50">
        </a-pagination>
        <br>
    </div>
</template>
<script>
import axios from 'axios'
import { notification } from 'ant-design-vue';

export default {
    name: "AllUserProfiles",
    data() {
        return {
            userProfiles: [],
            nextUrl: null,
            prevUrl: null,
            current: 1,
            total_pages: 0,
        user_details: [],
        }
    },
    mounted() {
        this.fetchAllUsers()
    this.fetchUserProfileDetails()
    },
    methods: {
        onChange(pageNumber) {
            // console.log(this.nextUrl);
            // console.log('Page: ', pageNumber);
            axios.get(this.nextUrl.slice(0, -1) + String(pageNumber))
                .then(response => {
                    this.userProfiles = response.data['results']['applications'];
                    // this.nextUrl = response.data['next'];
                    // this.prevUrl = response.data['previous'];

                })
                .catch(error => {
                    console.error('Error loading more applications:', error);
                });
        },

        async fetchAllUsers() {
            this.$store.commit('setIsLoading', true)

            await axios
                .get("admin-people-list-grant-access/get_all_accounts/")
                .then(response => {
                    // console.log(response);
                    // console.log(response.data);
                    // console.log("------------------------------------userData------------------------------------");
                    // console.log(response.data)

                    this.total_pages = response.data['count'];
                    this.userProfiles = response.data['results']['applications'];
                    this.nextUrl = response.data['next'];
                    this.prevUrl = response.data['previous'];

                    // this.$store.commit('addUserDetails', response.data);
                    // console.log("--------------------------------------Done---------------------------------");

                })
                .catch(error => {
                    console.log(error)
                    notification['error']({
                        message: 'Failed to fetch All User Profiles ',
                        description: 'Failed to fetch All User Profiles...',
                        placement: 'bottomRight',
                    });

                    console.log("===========error at user fetch")
                    console.log(JSON.stringify(error.response.data))
                    console.log(JSON.stringify(error))
                })
            this.$store.commit('setIsLoading', false)

        },
        async fetchUserProfileDetails() {
            this.$store.commit('setIsLoading', true)

            await axios
                .post("user-details/")
                .then((response) => {
                    this.user_details = response.data
                    this.$store.commit('addUserDetails', response.data);

                    // console.log("=============-------------============-====-=-===-=-=-=-=-")
                    // console.log(this.user_details)

                }).catch((error) => {
                    console.log("error Occured while fetching User Data ")
                })

            this.$store.commit('setIsLoading', false)
},
    }
}
</script>
<style scoped>
.card-img-top-mobile {
    width: 80px;
    height: 80px;
    position: absolute;
    /* left: 110%; */
    align-self: center;
    bottom: -10px;

    border-radius: 100%;
    /* margin: auto; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

}

.card-img-top {
    width: 80px;
    height: 80px;
    position: absolute;
    /* left: 110%; */
    align-self: center;
    right: 40px;

    border-radius: 100%;
    /* margin: auto; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}</style>