<template>
    <a-button type="primary" @click="showModal" style="border-radius:100%"><template #icon>
        <ion-icon name="pencil-outline"></ion-icon>
    </template></a-button>
        <a-modal v-model:open="open" title="Update Job Employement Type" :confirm-loading="confirmLoading" @ok="handleOk">
          
    <h3> Select The Experience Required</h3>
    <a-select v-model:value="experience_req" style="width: 100%">
        <a-select-option value="0 - 1 Year">0 - 1 Year</a-select-option>
        <a-select-option value="2 - 3 Year">2 - 3 Year</a-select-option>
        <a-select-option value="4 -5 Year">4 -5 Year</a-select-option>
        <a-select-option value="5 - 6 Year">5 - 6 Year</a-select-option>
        <a-select-option value="6+ Year">6+ Year</a-select-option>
    </a-select>

        </a-modal>
    </template>
    <script>
    
    
import { notification } from 'ant-design-vue'
import axios from 'axios'

    export default{
        name:"updateExperienceRequiredJobCareers",
        data(){
            return {
                modalText: 'Content of the modal',
                open: false,
                confirmLoading: false,
                experience_req:'',
                job_num:'',
            }
        }, 
        mounted(){
            this.experience_req=this.job_experience_required
            this.job_num=this.job_number
        },
        props:{
            job_number:Object,
            job_experience_required:Object
        },
        methods:{
            
                showModal() {
                this.open = true;
                },
                async handleOk () {
                this.confirmLoading = true;
                const formData={
                    experience_required:this.experience_req
                }
                await axios
        .post("update-job-details-career-crekaa-admin/"+"update_experience_required/"+this.job_number,formData)
        .then(response => {
            // console.log(response);
            // console.log("------------------------------------Got Response------------------------------------");
            // console.log(response.data);

            notification['success']({
                    message: 'Careers Job Experience required has been updated ',
                    description:'Careers job Experience required has been updated successfully, You just need to refresh update page.',
                    placement: 'bottomRight',
                });
        })
        .catch(error => {
            console.log(error)
                notification['error']({
                    message: 'Unable to update Experience required',
                    // description:'Unable To Fetch User Details...',
                    placement: 'bottomRight',
                });

                console.log("error Found unable to update")                        
                console.log(JSON.stringify(error.response.data))
                    console.log(JSON.stringify(error))
        })

        this.confirmLoading = false;
            this.open = false;
            window.location.reload()
            // this.confirmLoading = true;
            // setTimeout(() => {
            //     this.open = false;
            //     this.confirmLoading = false;
            // }, 2000);
            },


    
    
        }
    }
    </script>
    <style scoped>
    
    </style>
    
    
    