<template>
          <div  v-if="user_details.length ? user_details[0].user.can_careers_update_job : false  ">
    <div  v-if="this.$store.state.userDetils.length" >

        <div  style="background-color: var(--notification-color-bg);; min-height:100vh;padding:10px">
    
        <br>
        <br>
        <h4><b> Welcome Admin.</b> </h4>
        <card_job_career_at_crekaa_update_admin v-for="job in cards_all_careers" v-bind:jd="job"/>
    
    
        <a-row>
            <a-col :sm=24 :xs="24" :md="8" :lg="6">
            <a-card>
                <!-- add here card componant -->
            </a-card>
            </a-col>
            <a-col :sm=24 :xs="24" :md="16" :lg="18">
            <!-- get the use job details here -->
            </a-col>
        </a-row>
    </div>
    </div>

 </div>
</template>
<script>
import axios from 'axios'
import { notification } from 'ant-design-vue';

import card_job_career_at_crekaa_update_admin from  '@/components/careers/card_career_at_crekaa_admin.vue'
export default {
    name:"updateCareersJob",
    components:{
        card_job_career_at_crekaa_update_admin
    },
    data(){
        return{
            applied_filtered_results:null,
            cards_all_careers:null,
                user_details:[],
        }
    },
    // created(){
    //     document.head.title="Admin crekaa Create Job Opportunity "
    // },
    mounted(){
        this.fetchOpportinities()
         this.fetchUserProfileDetails()
    },
    methods: {
           
         async fetchUserProfileDetails() {
            this.$store.commit('setIsLoading', true)
            await axios
                .post("user-details/")
                .then((response) => {
                    // console.log( response.data)
                    this.user_details = response.data
                }).catch((error) => {
                    console.log("error Occured while fetching User Data ")
                })

            this.$store.commit('setIsLoading', false)
},
        async fetchOpportinities() {
            this.$store.commit('setIsLoading', true)

            await axios
                .get("all-jobs-crekaa-career-admin/")
                .then(response => {
                    // console.log(response);
                    // console.log("------------------------------------Got Response------------------------------------");
                    // console.log(response.data);

                    this.applied_filtered_results = response.data['all_jobs']
                    this.cards_all_careers =  this.applied_filtered_results

                })
                .catch(error => {
                    notification['error']({
                        message: 'Fetch all jobs Failed',
                        description: 'Unable To Fetch all Jobs With Provided Credentials...',
                        placement: 'bottomRight',
                    });

                    // console.log("===========error at user details")                        
                    // console.log(JSON.stringify(error.response.data))
                    //     console.log(JSON.stringify(error))
                })


            this.$store.commit('setIsLoading', false)
        }
    },
    // props:{
    //     job_Avail:Object
    // }
} 

</script>
<style scoped>
</style>