<template>
  <main>
    <div class="main-page">

      <div class="left-pane">
        <div class="main-pane-left-pane">
          Documentations Title <br><br><br>
          <a href="#book_website_project" class="card-left-pane">
            <h1> How to book website project ?</h1>
          </a>
          <a href="#book_android_app_project" class="card-left-pane">
            <h1> How to book android app project ?</h1>
          </a>
          <a href="#track_project" class="card-left-pane">
            <h1> How to track project ? </h1>
          </a>
          <a href="#check_project_status" class="card-left-pane">
            <h1> How to check project status</h1>
          </a>

          <a href="#request_for_new_module" class="card-left-pane">
            <h1> How to request for new module/functionality</h1>
          </a>

          <a href="#request_for_any_required_thing" class="card-left-pane">
            <h1> How to request for any required thing</h1>
          </a>

          <a href="#report_bugs" class="card-left-pane">
            <h1> How to report bugs?</h1>
          </a>
          <a href="#check_bugs_fixings" class="card-left-pane">
            <h1> How to check bugs fixings</h1>
          </a>

          <a href="#what_is_patches" class="card-left-pane">
            <h1> What is patches?</h1>
          </a>

          <a href="#check_patches" class="card-left-pane">
            <h1> How to check patches for your project?</h1>
          </a>

          <a href="#report_patches" class="card-left-pane">
            <h1> How to request patch ? </h1>
          </a>

          <a href="#how_to_report_patch" class="card-left-pane">
            <h1> How to report patch ? </h1>
          </a>
          <a href="#how_to_checkout_billings" class="card-left-pane">
            <h1> How to checkout billings ?</h1>
          </a>
          <a href="#contract_termination" class="card-left-pane">
            <h1> How to terminate contract?</h1>
          </a>
          <a href="#request_for_contract_termination" class="card-left-pane">
            <h1> How to request for contract termination</h1>
          </a>

          <a href="#how_to_upgrade_your_plan" class="card-left-pane">
            <h1> How to upgrade your plan ? </h1>
          </a>

          <a href="#what_to_improove_next_after_everything_is_settled" class="card-left-pane">
            <h1> What to improove next after everything is settled ? </h1>
          </a>

        </div>

      </div>

      <div class="right-pane">
        <div class="top-pane">

          <div class="dropdown">

            <a-button type="primary" @click="showModal" class="dropbtn" style="padding:0px;text-align:center">Go to Documentation Title <br></a-button>
            <a-modal v-model:open="open" title=" Documentations Title " @ok="handleOk">
              <a href="#book_website_project" class="card-left-pane-mob">
                <h1> How to book website project ?</h1>
              </a>
              <a href="#book_android_app_project" class="card-left-pane-mob">
                <h1> How to book android app project ?</h1>
              </a>
              <a href="#track_project" class="card-left-pane-mob">
                <h1> How to track project ? </h1>
              </a>
              <a href="#check_project_status" class="card-left-pane-mob">
                <h1> How to check project status</h1>
              </a>

              <a href="#request_for_new_module" class="card-left-pane-mob">
                <h1> How to request for new module/functionality</h1>
              </a>

              <a href="#request_for_any_required_thing" class="card-left-pane-mob">
                <h1> How to request for any required thing</h1>
              </a>

              <a href="#report_bugs" class="card-left-pane-mob">
                <h1> How to report bugs?</h1>
              </a>
              <a href="#check_bugs_fixings" class="card-left-pane-mob">
                <h1> How to check bugs fixings</h1>
              </a>

              <a href="#what_is_patches" class="card-left-pane-mob">
                <h1> What is patches?</h1>
              </a>

              <a href="#check_patches" class="card-left-pane-mob">
                <h1> How to check patches for your project?</h1>
              </a>

              <a href="#report_patches" class="card-left-pane-mob">
                <h1> How to request patch ? </h1>
              </a>

              <a href="#how_to_report_patch" class="card-left-pane-mob">
                <h1> How to report patch ? </h1>
              </a>
              <a href="#how_to_checkout_billings" class="card-left-pane-mob">
                <h1> How to checkout billings ?</h1>
              </a>
              <a href="#contract_termination" class="card-left-pane-mob">
                <h1> How to terminate contract?</h1>
              </a>
              <a href="#request_for_contract_termination" class="card-left-pane-mob">
                <h1> How to request for contract termination</h1>
              </a>

              <a href="#how_to_upgrade_your_plan" class="card-left-pane-mob">
                <h1> How to upgrade your plan ? </h1>
              </a>

              <a href="#what_to_improove_next_after_everything_is_settled" class="card-left-pane-mob">
                <h1> What to improove next after everything is settled ? </h1>
              </a>
              <!-- <template #title>
                <div ref="modalTitleRef" style="width: 100%; cursor: move">Draggable Modal</div>
              </template> -->
              <!-- <template #modalRender="{ originVNode }">
                <div :style="transformStyle">
                  <component :is="originVNode" />
                </div>
              </template> -->
            </a-modal>



          </div>
        </div>
        <div id="book_website_project">
          <div class="card-3">
            <div class="card-title">
              <h1 style="font-size:16px"> How to book website project ?</h1>
              <img style="width:100%;height:auto;border-radius:10px"
                src="https://crekaa-2024.s3.amazonaws.com/static/guids/images/website%20plans.png" alt="">
            </div>
            <div class="card-body">
              Go and choose your plan

              <div class="flx-resp">
                <div class="book-btn-startup" style="margin-top:10px;margin-bottom:10px">
                  <a style="padding:0px" href="/product/pricing-plan-website-startup">Startup</a>
                </div>
                <div class="book-btn-standard" style="margin-top:10px;margin-bottom:10px">
                  <a style="padding:0px" href="/product/pricing-plan-website-standard">Standard</a>
                </div>
                <div class="book-btn-custom" style="margin-top:10px;margin-bottom:10px">
                  <a style="padding:0px" href="/product/pricing-plan-website-custom">Custom</a>
                </div>
              </div>

              <div>
                Read all document what services we are offering according to plan.
              </div>
              <div>
                Read All
                <div style="margin-top:10px;margin-bottom:10px;" class="label-standard-mob bg-gl">
                  <a href="/product/web-guidlines"
                    style="margin-top: -28px;padding: none;text-decoration: none;">Guidlines</a>
                </div>
              </div>

              and then according to your requirements choose plan and Click on: <br>
              <div class="flx-resp">
                <a class="book-btn-startup " style="margin-top:10px;margin-bottom:10px;" href="/contract/order-startup/">+
                  Book Startup Project</a>
                <a class="book-btn-standard " style="margin-top:10px;margin-bottom:10px;"
                  href="/contract/order-standard/">+ Book Standard Project</a>
                <a class="book-btn-custom " style="margin-top:10px;margin-bottom:10px;" href="/contract/order-custom/">+
                  Book Custom Project</a>
              </div><br>
              After every you filled your projects requirements we will contact you shorltly within 24 Hrs.
            </div>

            <br>
            <div class="card-footer">
              <h4 style="margin:0;padding:0">Note:</h4>
              <p style="font-size: 14px;text-align: left;"> As we consider useh are does know all guidlines, it is
                mendatory for user to read all guidlines &amp; services before create the contract!</p>
            </div>
          </div>

        </div>
        <div id="book_android_app_project">
          <div class="card-3">
            <div class="card-title">
              <h1 style="font-size:16px"> How to book android app project ?</h1>
              <img style="width:100%;height:auto;border-radius:10px"
                src="https://crekaa-2024.s3.amazonaws.com/static/guids/images/mobile%20plans.png" alt="">

            </div>
            <div class="card-body">
              Go and choose your plan

              <div class="flx-resp">
                <div class="book-btn-startup" style="margin-top:10px;margin-bottom:10px">
                  <a href="/product/pricing-plan-mobile-startup" style="padding:0px;">Startup</a>
                </div>
                <div class="book-btn-standard" style="margin-top:10px;margin-bottom:10px">
                  <a href="/product/pricing-plan-mobile-standard" style="padding:0px;">Standard</a>
                </div>
                <div class="book-btn-custom" style="margin-top:10px;margin-bottom:10px">
                  <a href="/product/pricing-plan-mobile-custom" style="padding:0px;">Custom</a>
                </div>
              </div>

              <div>
                Read all document what services we are offering according to plan.
              </div>
              <div>
                Read All
                <div style="margin-top:10px;margin-bottom:10px;" class="label-standard-mob bg-gl">
                  <a href="/product/mobile-guidlines"
                    style="margin-top: -28px;padding: none;text-decoration: none;">Guidlines</a>
                </div>
              </div>

              and then according to your requirements choose plan and Click on: <br>
              <div style="margin-top:10px;margin-bottom:10px;" class="flx-resp">

                <a class="book-btn-startup " href="/contract/order-startup-mobile/">+ Book Startup Project</a>
                <a class="book-btn-standard " href="/contract/order-standard-mobile/">+ Book Standard Project</a>
                <a class="book-btn-custom " href="/contract/order-custom-mobile/">+ Book Custom Project</a>
              </div><br>
              After every you filled your projects requirements we will contact you shorltly within 24 Hrs.

            </div>
            <br>
            <div class="card-footer">
              <h4 style="margin:0;padding:0">Note:</h4>
              <p style="font-size: 14px;text-align: left;"> As we consider useh are does know all guidlines, it is
                mendatory for user to read all guidlines &amp; services before create the contract!</p>
            </div>
          </div>

        </div>
        <div id="track_project">
          <div class="card-3">
            <div class="card-title">
              <h1 style="font-size:16px">How to track project ?</h1>
              <img style="width:100%;height:auto"
                src="https://crekaa-2024.s3.amazonaws.com/static/guids/images/track-project.png" alt="">
            </div>
            <div class="card-body">
              Inside your dashboard you can see your orders as mention above image will show your project , % all details
              related to that you have filled while booking the project
              <ul>
                <li>you can track your project on both mobile &amp; pc devices</li>
              </ul>
              <!-- <p style="font-size: 12px;text-align: left;">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolores ex saepe fugit quas ducimus nihil a vitae nemo sed? Eius sunt architecto nulla vel similique, quaerat saepe perferendis minima consectetur!</p> -->

            </div>
            <div class="card-footer">
              <h4 style="margin:0;padding:0">Note</h4>
              <p style="font-size: 12px;text-align: left;">on the same screen you can see the recent billings &amp;
                pathces
                listings.</p>

            </div>
          </div>

        </div>
        <div id="check_project_status">
          <div class="card-3">
            <div class="card-title">
              <h1 style="font-size:16px">How to check project status</h1>
              <img style="width:100%;height:auto"
                src="https://crekaa-2024.s3.amazonaws.com/static/guids/images/track-project.png" alt="">
            </div>
            <div class="card-body">
              <li>

                Inside your dashboard, you can see your orders as mentioned above. The image will show your project and
                all details related to it that you have filled while booking the project.
              </li>
              <li>
                You can track your project on both mobile and PC devices.
              </li>
              <!-- <p style="font-size: 12px;text-align: left;">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolores ex saepe fugit quas ducimus nihil a vitae nemo sed? Eius sunt architecto nulla vel similique, quaerat saepe perferendis minima consectetur!</p> -->

            </div>
            <div class="card-footer">
              <h4 style="margin:0;padding:0">Note</h4>
              <p style="font-size: 12px;text-align: left;">"On the same screen, you can also see the recent billings and
                patches listings."</p>

            </div>
          </div>

        </div>
        <div id="request_for_new_module">
          <div class="card-3">
            <div class="card-title">
              <h1 style="font-size:16px"> How to request for new module/functionality</h1>
              <img style="width:100%;height:auto"
                src="https://crekaa-2024.s3.amazonaws.com/static/guids/images/dashboard%20request%20module%20or%20functionality%20.png"
                alt="">
            </div>
            <div class="card-body">
              <li> On your dashboard page, click on 'Create Request,' and in the contact us form, add your "Order Id" in
                "Subtitle."</li>
              <li> Describe your module or functionality request.</li>
              <li> It takes time for us to explore your request, so please stay tuned, and we will respond to you within
                74 hours.</li>

            </div>
            <div class="card-footer">
              <h4 style="margin:0;padding:0">Note</h4>
              <p style="font-size: 12px;text-align: left;">To complete your request, it may require some development cost,
                depending on the complexity of the requested module..</p>

            </div>
          </div>

        </div>
        <div id="request_for_any_required_thing">
          <div class="card-3">
            <div class="card-title">
              <h1 style="font-size:16px">How to request for any required thing ?</h1>
              <img style="width:100%;height:auto"
                src="https://crekaa-2024.s3.amazonaws.com/static/guids/images/dashboard%20request%20module%20or%20functionality%20.png"
                alt="">
            </div>
            <div class="card-body">
              <li> On your dashboard page, click on 'Create Request,' and in the contact us form, add your "Order Id" in
                "Subtitle."</li>
              <li>Describe your request for any required thing.</li>
              <li>It takes time for us to explore your request, so please stay tuned, and we will respond to you within 74
                hours.</li>


            </div>
            <div class="card-footer">
              <h4 style="margin:0;padding:0">Note</h4>
              <p style="font-size: 12px;text-align: left;">To complete your request, it may require some development cost,
                depending on the nature of the requested item or service.</p>

            </div>
          </div>

        </div>
        <div id="report_bugs">
          <div class="card-3">
            <div class="card-title">
              <h1 style="font-size:16px"> How to report bugs?</h1>
              <!-- <img style="width:100%;height:auto" src="https://crekaa-2024.s3.amazonaws.com/static/guids/images/dashboard%20request%20module%20or%20functionality%20.png" alt="">         -->
            </div>
            <div class="card-body">
              <li>On your dashboard page, click on 'Create Request,' and in the contact us form, add your "Order Id" in
                "Subtitle."</li>
              <li>Describe the bugs you encountered and provide relevant details for our team to investigate.</li>
              <li>It takes time for us to explore your report, so please stay tuned, and we will respond to you within 74
                hours.</li>


            </div>
            <div class="card-footer">
              <h4 style="margin:0;padding:0">Note</h4>
              <p style="font-size: 12px;text-align: left;">To resolve the reported bugs, our team may take up to one week,
                depending on the complexity of the issues.</p>

            </div>
          </div>

        </div>
        <div id="check_bugs_fixings">
          <div class="card-3">
            <div class="card-title">
              <h1 style="font-size:16px"> How to check bugs fixings ?</h1>
              <!-- <img style="width:100px;height:auto" src="../../assets/logo.png" alt=""> -->
            </div>
            <div class="card-body">
              <li> After reviewing your bug report, our team will try to replicate the bug to address it properly.</li>
              <li>Once fixed, you can track the status of the bug in the patches section of your dashboard.</li>

            </div>
            <div class="card-footer">
              <h4 style="margin:0;padding:0">Note</h4>
              <p style="font-size: 12px;text-align: left;">Please allow us up to one week to thoroughly resolve the
                reported bugs and release the necessary patches.</p>

            </div>
          </div>

        </div>
        <div id="what_is_patches">
          <div class="card-3">
            <div class="card-title">
              <h1 style="font-size:16px"> What is patches?</h1>
              <!-- <img style="width:100px;height:auto" src="../../assets/logo.png" alt=""> -->
            </div>
            <div class="card-body">
              <li> Patches refer to additional functionalities, modules, or bug resolutions requested by the client
                according to their business needs.</li>
              <li>Your entire project is distributed into small partitions, and each partition is known as a patch.</li>
              <p style="font-size: 12px;text-align: left;">Note: "Please keep in mind that patches may incur additional
                costs, depending on the complexity of the requested updates."
                As pay as per developement model patches does carry its own
                price!</p>

            </div>
            <div class="card-footer">
              <h4 style="margin:0;padding:0">Note</h4>
              <p style="font-size: 12px;text-align: left;">You can track every patch's status on your dashboard for
                transparency and convenience.</p>

            </div>
          </div>

        </div>
        <div id="check_patches">
          <div class="card-3">
            <div class="card-title">
              <h1 style="font-size:16px"> How to check patches for your project?</h1>
              <img style="width:100%;height:auto"
                src="https://crekaa-2024.s3.amazonaws.com/static/guids/images/your%20patches.png" alt="">

            </div>
            <div class="card-body">
              <li> In the patches section of your dashboard, you can view all the patches released for your project.</li>
              <li> Each patch will include details about the updates, enhancements, or bug fixes introduced.</li>


            </div>
            <div class="card-footer">
              <h4 style="margin:0;padding:0">Note</h4>
              <p style="font-size: 12px;text-align: left;">Stay up-to-date with the latest patches to ensure your project
                is running efficiently and securely</p>

            </div>
          </div>

        </div>

        <div id="report_patches">
          <div class="card-3">
            <div class="card-title">
              <h1 style="font-size:16px">How to request patch ?</h1>
              <img style="width:100%;height:auto"
                src="https://crekaa-2024.s3.amazonaws.com/static/guids/images/request%20patch.png" alt="">

            </div>
            <div class="card-body">
              <li>If you require a new patch or specific updates, click on 'Create Request' in your dashboard section.
              </li>
              <li>Include your "Order Id" and a detailed description of the patch you need.</li>
              <li>We will review your request and get back to you within one week.</li>

            </div>
            <div class="card-footer">
              <h4 style="margin:0;padding:0">Note</h4>
              <p style="font-size: 12px;text-align: left;">Patch development may involve additional costs depending on the
                complexity of the requested updates.
                &amp; it takes time to us to reach out at you max upto 1 week</p>

            </div>
          </div>

        </div>
        <div id="how_to_report_patch">
          <div class="card-3">
            <div class="card-title">
              <h1 style="font-size:16px"> How to report patch ?</h1>
              <img style="width:100%;height:auto"
                src="https://crekaa-2024.s3.amazonaws.com/static/guids/images/request%20patch.png" alt="">

            </div>
            <div class="card-body">
              <li> If you encounter any issues with a previously implemented patch, click on 'Create Request' in your
                project details contact us section.</li>
              <li>Include your "Patch Id" and describe the problem you're facing.</li>
              <li>We will promptly address and resolve the reported issues.</li>

            </div>
            <div class="card-footer">
              <h4 style="margin:0;padding:0">Note</h4>
              <p style="font-size: 12px;text-align: left;">Please report patches related issues as soon as possible, so we
                can provide timely solutions.</p>


            </div>
          </div>

        </div>
        <div id="how_to_checkout_billings">
          <div class="card-3">
            <div class="card-title">
              <h1 style="font-size:16px"> How to checkout billings ?</h1>
              <img style="width:100%;height:auto"
                src="https://crekaa-2024.s3.amazonaws.com/static/guids/images/billings.png" alt="">

            </div>
            <div class="card-body">
              <li>Visit the "My Billings" section in your dashboard to find all your billings.</li>
              <li>Click on a specific bill to view the detailed invoice.</li>
            </div>
            <div class="card-footer">
              <h4 style="margin:0;padding:0">Note:</h4>
              <p style="font-size: 12px;text-align: left;">We Conclude you further process of pay bill via online UPI !
              </p>

            </div>
          </div>

        </div>
        <div id="contract_termination">
          <div class="card-3">
            <div class="card-title">
              <h1 style="font-size:16px"> How to terminate contract?</h1>
              <!-- <img style="width:100px;height:auto" src="../../assets/logo.png" alt=""> -->
            </div>
            <div class="card-body">

              <li>Should you ever decide to terminate your contract, our team is here to assist you. </li>
              <li>Simply click on 'Create Request' and provide the necessary details for contract termination or mail us.
              </li>
              <li>We will guide you through the process and ensure a smooth and hassle-free transition</li>

            </div>
            <div class="card-footer">
              <h4 style="margin:0;padding:0">Note:</h4>
              <p style="font-size: 12px;text-align: left;">It takes time to terminate all resources of your project,
                Please notice this.</p>

            </div>
          </div>
        </div>
        <div id="request_for_contract_termination">
          <div class="card-3">
            <div class="card-title">
              <h1 style="font-size:16px"> How to request to terminate contract?</h1>
              <!-- <img style="width:100px;height:auto" src="../../assets/logo.png" alt=""> -->
            </div>
            <div class="card-body">
              <li>For requesting contract termination, reach out to us via the contact us section in your dashboard's
                order details.</li>
              <li>Share your reasons for contract termination, and we will acknowledge your request and contact you
                shortly.</li>

            </div>
            <div class="card-footer">
              <h4 style="margin:0;padding:0">Note:</h4>
              <p style="font-size: 12px;text-align: left;">It takes time to terminate all resources of your project,
                Please note this and create request before 1 week of ending of month to avoid extra charges!</p>

            </div>
          </div>
        </div>
        <div id="how_to_upgrade_your_plan">
          <div class="card-3">
            <div class="card-title">
              <h1 style="font-size:16px"> How to upgrade your plan ?</h1>
              <!-- <img style="width:100px;height:auto" src="../../assets/logo.png"
              alt=""> -->
            </div>
            <div class="card-body">
              <li> If you wish to upgrade your current plan to a higher one, discuss the details with us via the contact
                us section in your dashboard or project details.</li>
              <li>We will guide you through the process of plan upgrade and provide information about the new features and
                pricing.</li>


            </div>
            <div class="card-footer">
              <h4 style="margin:0;padding:0">Note:</h4>
              <p style="font-size: 12px;text-align: left;">Upgrading your plan will come with additional features and
                functionalities and may result in a change in pricing.
                The upgraded contract will contain excess technologies and Crekaa.UI products!</p>

            </div>
          </div>

        </div>
        <div id="what_to_improove_next_after_everything_is_settled">
          <div class="card-3">
            <div class="card-title">
              <h1 style="font-size:16px"> What to improove next after everything is settled ?</h1>
              <!-- <img style="width:100px;height:auto" src="../../assets/logo.png"
              alt=""> -->
            </div>
            <div class="card-body">
              <li>We encourage continuous improvement and innovation.</li>
              <li>Feel free to modify, improve, and embed your innovative ideas to enhance your platform.</li>
              <li>We are here to support and assist you in any further enhancements.</li>


            </div>
            <div class="card-footer">
              <h4 style="margin:0;padding:0">Note:</h4>
              <p style="font-size: 12px;text-align: left;">Modify , improve and embed your innovative ideas weill be
                considered as patches for your platform this may results you in higher performance, functionalities and
                higher quality for your platform and our product.</p>
            </div>
          </div>

        </div>
      </div>
    </div>

  </main>
</template>
<script>
import { ref } from 'vue';
const open = ref(false);




export default {
  name: 'documentation',
  data() {
    return {
      open: false,
    }
  },
  
created(){
    document.title = "Documentation- Crekaa";
  },
  head() {
    return {
      title: "Documentation- Crekaa",
      meta: [
        { hid: 'description', name: 'description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'author', name: 'author', content: "Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        
        // <!-- Facebook Open Graph tags -->
        { hid: 'og:url', property: 'og:url', content: "https://crekaa.com/" },
        { hid: 'og:type', property: 'og:type', content: "website" },
        { hid: 'og:title', property: 'og:title', content: "Crekaa, Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'og:description', property: 'og:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'og:image', property: 'og:image', content: "../../assets/logo.png" },
        // Add more meta tags as needed

        // <!-- Twitter Card tags -->
        { hid: 'twitter:card', name: 'twitter:card', content: "summary_large_image" },
        { hid: 'twitter:title', name: 'twitter:title', content: "Crekaa , Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'twitter:description', name: 'twitter:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'twitter:image', name: 'twitter:image', content: "../../assets/logo.png" },

      ]
    };
  },
  methods: {
    showModal() {
      this.open = true;
    },
    handleOk() {
      // console.log(e);
      this.open = false;
    },
  }

};
</script>
<style scoped>
a {
  text-decoration: none;

}

.main-page {
  margin-top: 30px;
  width: 100%;
  height: 100vh;
}

.left-pane {
  width: 20%;
  background-color: rgba(255, 226, 226, 0.5);
  height: 100%;
  padding: 20px;
  overflow-y: scroll;
  position: absolute;


}

.top-pane {
  display: none;
  top: 0px;
  width: 100%;
  height: 80px;
  padding: 20px;
  /* background-color: blueviolet; */
}

.right-pane {
  margin-left: 20%;
  width: 80%;
  height: 100%;
  position: absolute;
  /* background: rgb(20, 42, 202); */
  overflow-y: scroll;
  padding: 20px;
  scroll-behavior: smooth;


  background-color: #fff9f9;
  background-color: hsla(0, 100%, 88%, 1);
  backdrop-filter: blur(75px);

  background-image:
    radial-gradient(at 80% 0%, hsla(189, 100%, 56%, 0.6) 0px, transparent 50%),
    radial-gradient(at 0% 50%, hsla(355, 100%, 93%, 0.4) 0px, transparent 50%),
    radial-gradient(at 80% 50%, hsla(340, 100%, 76%, 0.4) 0px, transparent 50%),
    radial-gradient(at 0% 100%, hsla(269, 100%, 77%, 0.4) 0px, transparent 50%),
    radial-gradient(at 0% 0%, hsla(343, 100%, 76%, 0.4) 0px, transparent 50%);
}

.flx-resp {
  display: flex;
  justify-content: space-between;
  gap: 20px
}

@media screen and (max-width: 720px) {

  /* Add your CSS styles for devices with 720p resolution or higher here */
  /* For example: */
  .left-pane {
    display: none;
    width: 0px;
  }

  .book-btn-custom {
    margin-bottom: 30px;
  }

  .book-btn-standard {
    margin-bottom: 30px;
  }

  .book-btn-startup {
    margin-bottom: 30px;
  }

  .flx-resp {
    display: block;

  }

  .top-pane {
    display: block
  }

  .right-pane {
    margin-left: 0;
    margin-right: 0;

    width: 100%;
    position: relative;
    overflow-y: none;

    height: 100%;
  }
}

.card-left-pane {
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 5px;
  color: var(--text-color);
  backdrop-filter: blur(5px);
  cursor: pointer;
}

.card-left-pane h1 {
  font-size: 14px;
  border-radius: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: var(--card-color);
  margin: 5px;
}

.card-left-pane-mob :hover{
  background-color: rgba(128, 128, 128, 0.192);
  padding-left:20px;
  border-radius:5px
}
.card-left-pane-mob {
  /* border-radius: 10px; */
  padding: 0px;
  
  width: 100%;
  margin-bottom: 5px;
  color:var(--text-color);
  background-color: rgba(33, 33, 33, 0.344);
  backdrop-filter: blur(5px);
  cursor: pointer;
}

.card-left-pane-mob h1 {
  font-size: 14px;
  margin: 5px;
}


/* card-3 */
.card-3 {
  width: 100%;
  margin-bottom: 10px;
  background-color: #ffffff66;
  border-radius: 10px;
  padding: 20px;
  color: black;
  /* flexing */
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* Centering Text */
  text-align: start;

  /* handling overflow */
  overflow: hidden;
}

.card-footer {
  background-color: #c0bfbf4d;
  padding: 10px;
  border-radius: 5px
}

.card-footer h4 {

  font-size: 14px
}

.list-hover {
  transition: 0.9s;
}

.list-hover:hover {
  transition: 0.5s;
  font-weight: 700;
  cursor: pointer;
  background-color: #5e5e5e4d;
  ;
  padding: 5px;
  border-radius: 5px;
}

.card-body {
  font-size: 14px;
}

.book-btn-custom {
  background: rgba(228, 2, 2, 0.24);
  color: rgb(137, 0, 0);
  cursor: pointer;
  width: 100%;
  text-align: center;
  border-radius: 30px;
  font-size: 12px;
  /* height: 50px; */
  padding: 12px;
  box-shadow: rgba(228, 2, 2, 0.5) 0px 8px 24px, rgba(228, 2, 2, 0.1) 0px 16px 56px, rgba(228, 2, 2, 0.1) 0px 24px 80px;
  border: 2px solid rgb(228, 2, 2)
}

.book-btn-custom:hover {
  background: rgb(228, 2, 2, 0.34);
  box-shadow: rgba(228, 2, 2, 0.4) 0px 8px 24px, rgba(228, 2, 2, 0.2) 0px 16px 56px, rgba(228, 2, 2, 0.2) 0px 24px 80px;

  color: rgb(228, 2, 2, );

}

.book-btn-standard {

  background: rgba(224, 2, 228, 0.24);
  color: rgb(135, 0, 137);
  cursor: pointer;
  width: 100%;
  text-align: center;
  border-radius: 30px;
  font-size: 12px;
  padding: 12px;
  box-shadow: rgba(225, 2, 228, 0.5) 0px 8px 24px, rgba(225, 2, 228, 0.1) 0px 16px 56px, rgba(225, 2, 228, 0.1) 0px 24px 80px;
  border: 2px solid rgb(172, 0, 230)
}

.book-btn-standard:hover {
  background: rgb(225, 2, 228, 0.34);
  box-shadow: rgba(225, 2, 228, 0.4) 0px 8px 24px, rgba(225, 2, 228, 0.2) 0px 16px 56px, rgba(225, 2, 228, 0.2) 0px 24px 80px;

  color: rgb(135, 0, 137);

}

.book-btn-startup {
  background: rgba(25, 2, 228, 0.24);
  color: rgb(25, 2, 228);
  cursor: pointer;
  width: 100%;
  text-align: center;
  border-radius: 30px;
  font-size: 12px;
  padding: 12px;
  box-shadow: rgba(25, 2, 228, 0.5) 0px 8px 24px, rgba(25, 2, 228, 0.1) 0px 16px 56px, rgba(25, 2, 228, 0.1) 0px 24px 80px;
  border: 2px solid rgb(25, 2, 228)
}

.book-btn-startup:hover {
  background: rgb(25, 2, 228, 0.34);
  box-shadow: rgba(25, 2, 228, 0.4) 0px 8px 24px, rgba(25, 2, 228, 0.2) 0px 16px 56px, rgba(25, 2, 228, 0.2) 0px 24px 80px;

  color: rgb(25, 2, 228);

}

.label-standard-mob {
  width: 100%;
  height: 30px;
  padding: 10px;
  border-radius: 50px;

  margin-right: 10px;
  display: flex;
  float: left;


  border-radius: 20px;

  display: flex;

  justify-content: center;
  /* margin-bottom:-20px */
}

.label-standard-mob h1 {
  /* margin-top: -5px; */

  font-size: 14px;

  font-weight: 700;

}

.bg-gl {
  background-color: rgb(64, 255, 0, 0.3);
  border: 1px solid rgb(0, 186, 37)
}

.bg-gl h1 {
  color: rgb(26, 103, 0)
}

/* dropdown */

.dropdown {
  position: relative;
  width: 100%;
}

.dropbtn {
  background-color: rgb(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  color: rgb(0, 0, 0);
  font-weight: 700;
  border-radius: 10px;
  padding: 16px;
  height:50px;
  text-align: center;
  font-size: 16px;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: rgba(108, 108, 108, 0.184);
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #ddd;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}</style>