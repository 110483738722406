<template>
<a-button type="primary" @click="showModal" style="border-radius:100%"><template #icon>
        <ion-icon name="pencil-outline"></ion-icon>
    </template></a-button>
    <a-modal v-model:open="open" title="Update Job Description (English)" :confirm-loading="confirmLoading" @ok="handleOk" style="width:80%">
        <!-- <h3>Job Description English</h3> -->
      <quill-editor theme="snow" toolbar="full"  v-model:content="content" style="background-color: rgb(210, 210, 210);height:500px"></quill-editor>
     
    </a-modal>
</template>
<script>


import { notification } from 'ant-design-vue'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.bubble.css'
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
import axios from 'axios'

export default{
    name:"updateJobDescriptionCareers_en",
    data(){
        return {
            open: false,
            confirmLoading: false,
            htmlContents:null,
            content: '',
            delta: '',
        }
    },
    components:{
      QuillEditor,
    }, 
    props:{
        job_number:Object
    },    
    methods:{
        
            showModal() {
            this.open = true;
            },
            async handleOk () {
                this.confirmLoading = true;

                this.delta=this.content
                const converter = new QuillDeltaToHtmlConverter(this.delta.ops, {});
                this.htmlContents=converter.convert();
                const formData={
                    content:this.htmlContents
                }
                await axios
        .post("update-job-details-career-crekaa-admin/"+"update_jd_en/"+this.job_number,formData)
        .then(response => {
            // console.log(response);
            // console.log("------------------------------------Got Response------------------------------------");
            // console.log(response.data);

            notification['success']({
                    message: 'Careers Job Description has been updated ',
                    description:'Careers job description has been updated successfully, You just need to refresh update page.',
                    placement: 'bottomRight',
                });
        })
        .catch(error => {
            console.log(error)
                notification['error']({
                    message: 'Unable to update title',
                    // description:'Unable To Fetch User Details...',
                    placement: 'bottomRight',
                });

                console.log("error Found unable to update")                        
                console.log(JSON.stringify(error.response.data))
                    console.log(JSON.stringify(error))
        })

        this.confirmLoading = false;
            this.open = false;
            window.location.reload()
            // this.confirmLoading = true;
            // setTimeout(() => {
            //     this.open = false;
            //     this.confirmLoading = false;
            // }, 2000);
            },

    }
}
</script>
<style scoped>

</style>
