<template>
  <a-row>
    <a-col :sm="0" :xs="0" :md="24" :lg="24">
      <div class="sticky-header is-sticky">
        <!-- <br><br><br>
            <br><br><br> -->
        <div class="left-pane">

          <router-link class="button-left-pane-web" to="/plans-mobile/startup-mobile">

            <h1 class="button-left-pane-plan-type-startup "
              :class="this.$route.path === '/plans-mobile/startup-mobile' ? '' : 'active-startup'">
              <lord-icon src="https://cdn.lordicon.com/ilpmnyul.json" trigger="hover"
                colors="primary:#121331,secondary:#ebe6ef" style="width:60px;height:60px">
              </lord-icon>
              Startups Mobile App
            </h1>
          </router-link>
<br>
          <router-link class="button-left-pane-web" to="/plans-mobile/standard-mobile">
            <h1 class="button-left-pane-plan-type-standard "
              :class="this.$route.path === '/plans-mobile/standard-mobile' ? '' : 'active-standard'">
              <lord-icon src="https://cdn.lordicon.com/hbwqfgcf.json" trigger="hover"
                colors="outline:#121331,primary:#646e78,secondary:#ffc738,tertiary:#4bb3fd"
                style="width:60px;height:60px">
              </lord-icon>
              Standard Mobile App
            </h1>
          </router-link>
<br>
          <router-link class="button-left-pane-web" to="/plans-mobile/custom-mobile">

            <h1 class="button-left-pane-plan-type-custom "
              :class="this.$route.path === '/plans-mobile/custom-mobile' ? '' : 'active-custom'"><lord-icon
                src="https://lottie.host/5e4a9d32-0155-404d-be89-37f5478a0499/nVbwsGqQ27.json" trigger="hover"
                colors="outline:#121331,primary:#92140c,secondary:#4bb3fd,tertiary:#ebe6ef"
                style="width:60px;height:60px">
              </lord-icon>Custom Mobile App</h1>
          </router-link>


        </div>

      </div>
    </a-col>
    <a-col :sm="24" :xs="24" :md="0" :lg="0">
      <br>
      <br>
      <br>
      <br>
      <a-row>

        <!-- <a-col  :sm="24" :xs="24" :md="0" :lg="0">
                                        add here the slidshow or promotional video for desired page 
                                      <video style="width:100%" controls="" muted="" autoplay="" src="https://crekaa-2024.s3.amazonaws.com/static/graphics/project%20crekaa%20promotional%20project.mp4"></video>
</a-col> -->
      </a-row>
      <a-row>

        <a-col :sm="8" :xs="8" :md="0" :lg="0">

          <!-- <a-tag color="blue"> -->
          <router-link to="/plans-mobile/startup-mobile"  >

            <h1 class="button-left-pane-plan-type-startup "
              :class="this.$route.path === '/plans-mobile/startup-mobile' ? '' : 'active-startup'">
              <lord-icon src="https://cdn.lordicon.com/ilpmnyul.json" trigger="hover"
                colors="primary:#121331,secondary:#ebe6ef" style="width:60px;height:60px">
              </lord-icon>
              Startups Android App
            </h1>
          </router-link>

          <!-- </a-tag> -->
        </a-col>
        <a-col :sm="8" :xs="8" :md="0" :lg="0">

          <!-- <a-tag color="blue"> -->

          <router-link to="/plans-mobile/standard-mobile">
            <h1 class="button-left-pane-plan-type-standard "
              :class="this.$route.path === '/plans-mobile/standard-mobile' ? '' : 'active-standard'">
              <lord-icon src="https://cdn.lordicon.com/hbwqfgcf.json" trigger="hover"
                colors="outline:#121331,primary:#646e78,secondary:#ffc738,tertiary:#4bb3fd"
                style="width:60px;height:60px">
              </lord-icon>
              Standard Android App
            </h1>
          </router-link>

          <!-- </a-tag> -->
        </a-col>
        <a-col :sm="8" :xs="8" :md="0" :lg="0">

          <!-- <a-tag color="blue"> -->

          <router-link to="/plans-mobile/custom-mobile">

            <h1 class="button-left-pane-plan-type-custom "
              :class="this.$route.path === '/plans-mobile/custom-mobile' ? '' : 'active-custom'"><lord-icon
                src="https://lottie.host/5e4a9d32-0155-404d-be89-37f5478a0499/nVbwsGqQ27.json" trigger="hover"
                colors="outline:#121331,primary:#92140c,secondary:#4bb3fd,tertiary:#ebe6ef"
                style="width:60px;height:60px">
              </lord-icon>Custom Android App</h1>
          </router-link>

          <!-- </a-tag> -->
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>
<script>
export default {
  name: "right_pane_mobile"
}
</script>
<style scoped>
a {
  text-decoration: none
}

.main-page {
  margin-top: 0px;
  width: 80%;
}

.left-pane {
  margin-top: 40px;

  /* width:22%; */
  /* background-color: white; */
  height: 100%;
  position: absolute;
}

.right-pane {
  margin-top: 20px;

  margin-left: 22%;
  width: 78%;
  height: 100%;
  overflow-y: scroll;
  position: absolute;
  background: rgba(243, 230, 255, 0.4);
  padding-top: 30px;
  padding-left: 50px;
  border-top-left-radius: 50px;
}

/*  */
.button-left-pane-plan-type-startup {
  background-color: rgba(0,255,242, 0.1);
  border-radius: 20px;
  width: 90%;

  padding: 10px;
  font-size: 16px;
  /* font-weight: bold; */
  margin: 5px;
  color: var(--text-color);
  display: flex;
  justify-content: space-evenly;
  flex-direction: row-reverse;
  align-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.button-left-pane-plan-type-standard {
  background-color: rgba(255, 170,0, 0.1);
  border-radius: 20px;
  width: 90%;

  padding: 10px;
  font-size: 16px;
  /* font-weight: bold; */
  margin: 5px;
  color: var(--text-color);
  display: flex;
  justify-content: space-evenly;
  flex-direction: row-reverse;
  align-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.button-left-pane-plan-type-custom {
  background-color: rgba(0, 255, 51, 0.1);
  border-radius: 20px;
  width: 90%;

  padding: 10px;
  font-size: 16px;
  /* font-weight: bold; */
  margin: 5px;
  color: var(--text-color);
  display: flex;
  justify-content: space-evenly;
  flex-direction: row-reverse;
  align-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.label-standard {
  width: 70px;
  height: 30px;
  padding: 10px;
  border-radius: 50px;
  margin-top: -20px;
  margin-right: 10px;
  display: flex;
  float: left;

  background-color: rgba(255, 170,0, 0.3);
  border: 1px solid rgb(255, 170,0);
  border-radius: 20px;

  display: flex;

  justify-content: center;
  /* margin-bottom:-20px */
}

.label-standard h1 {
  margin-top: 0px;

  font-size: 10px;
  color: rgb(255, 170,0);
  font-weight: 700;

}

.label-custom {
  width: 70px;
  height: 30px;
  padding: 10px;
  border-radius: 50px;
  margin-top: 0;
  margin-right: 10px;
  display: flex;
  float: left;

  background-color: rgba(0, 255, 51, 0.3);
  border: 1px solid rgb(0, 255, 51);
  border-radius: 20px;

  display: flex;

  justify-content: center;
  /* margin-bottom:-20px */
}

.button-left-pane-web {
  margin-top: -50px
}

.label-custom h1 {
  margin-top: 0px;

  font-size: 10px;
  color: rgb(0, 255, 51);
  font-weight: 700;

}

.button-left-pane-plan-type-startup:hover {
  background-color: rgba(0,255,242, 0.3);
  border: 3px solid rgba(0,255,242, 0.7);

  /* border-radius: 20px;
  padding: 20px;
  font-size: 16px;

  margin: 20px; */
  /* color:black; */
  display: flex;
  justify-content: space-evenly;
  flex-direction: row-reverse;
  align-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.button-left-pane-plan-type-standard:hover {
  background-color: rgba(255, 170,0, 0.3);
  border: 3px solid rgba(255, 170,0, 0.7);

  /* border-radius: 20px;
  padding: 20px;
  font-size: 16px;

  margin: 20px; */
  /* color:black; */
  display: flex;
  justify-content: space-evenly;
  flex-direction: row-reverse;
  align-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.button-left-pane-plan-type-custom:hover {
  background-color: rgba(0, 255, 51, 0.3);
  border: 3px solid rgba(0, 255, 51, 0.7);

  /* border-radius: 20px;
  padding: 20px;
  font-size: 16px;

  margin: 20px; */
  /* color:black; */
  display: flex;
  justify-content: space-evenly;
  flex-direction: row-reverse;
  align-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.active-startup {
  border: 3px solid rgba(0,255,242, 0.9)
}

.active-standard {
  border: 3px solid rgba(255, 170,0, 0.9)
}

.active-custom {
  border: 3px solid rgba(0, 255, 51, 0.9)
}


/* mobile view management  */
.container-mob {
  display: flex;
  background: rgba(243, 230, 255, 0.4);
  margin-top: 50px;
}

.main-page-mob {
  background: rgba(243, 230, 255, 0.4);
  width: 95%;
  margin: auto;
}

.card-full-need {
  margin-top: 50px;

}



.label-standard-mob {
  width: 100%;
  height: 30px;
  padding: 10px;
  border-radius: 50px;

  margin-right: 10px;
  display: flex;
  float: left;


  border-radius: 20px;

  display: flex;

  justify-content: center;
  /* margin-bottom:-20px */
}

.label-standard-mob h1 {
  margin-top: -2px;
  font-size: 12px;
  font-weight: 700
}

.bg-su {
  background-color: rgb(0,255,242, 0.3);
  border: 1px solid rgb(0,255,242)
}

.bg-su h1 {
  color: rgb(0,255,242)
}

.bg-st {
  background-color: rgba(255, 170,0, 0.3);
  border: 1px solid rgb(255, 170,0);
  color: rgb(255, 170,0);
}

.bg-st h1 {
  color: rgb(255, 170,0);
}

.bg-cu {

  background-color: rgba(0, 255, 51, 0.3);
  border: 1px solid rgb(0, 255, 51);

  color: rgb(0, 255, 51);
}

.bg-cu h1 {
  color: rgb(0, 255, 51);
}

.ul-guidlines li {
  font-size: 14px;
}

.head-pane {
  display: flex;
  justify-content: space-between;
}

.right-side-head-pane {
  margin-right: 30px
}

.book-btn-custom {

  background: rgba(228, 2, 2, 0.24);
  color: rgb(137, 0, 0);
  cursor: pointer;
  border-radius: 30px;
  height: 50px;
  padding: 12px;
  box-shadow: rgba(228, 2, 2, 0.5) 0px 8px 24px, rgba(228, 2, 2, 0.1) 0px 16px 56px, rgba(228, 2, 2, 0.1) 0px 24px 80px;
  border: 2px solid rgb(228, 2, 2)
}

.book-btn-custom:hover {
  background: rgb(228, 2, 2, 0.34);
  box-shadow: rgba(228, 2, 2, 0.4) 0px 8px 24px, rgba(228, 2, 2, 0.2) 0px 16px 56px, rgba(228, 2, 2, 0.2) 0px 24px 80px;

  color: rgb(228, 2, 2, );

}

.book-btn-standard {

  background: rgba(224, 2, 228, 0.24);
  color: rgb(135, 0, 137);
  cursor: pointer;
  border-radius: 30px;
  height: 50px;
  padding: 12px;
  box-shadow: rgba(0,2255,242, 0.5) 0px 8px 24px, rgba(0,2255,242, 0.1) 0px 16px 56px, rgba(0,2255,242, 0.1) 0px 24px 80px;
  border: 2px solid rgb(255, 170,0)
}

.book-btn-standard:hover {
  background: rgb(0,2255,242, 0.34);
  box-shadow: rgba(0,2255,242, 0.4) 0px 8px 24px, rgba(0,2255,242, 0.2) 0px 16px 56px, rgba(0,2255,242, 0.2) 0px 24px 80px;

  color: rgb(135, 0, 137);

}

.book-btn-startup {
  background: rgba(0,255,242, 0.24);
  color: rgb(0,255,242);
  cursor: pointer;
  border-radius: 30px;
  height: 50px;
  padding: 12px;
  box-shadow: rgba(0,255,242, 0.5) 0px 8px 24px, rgba(0,255,242, 0.1) 0px 16px 56px, rgba(0,255,242, 0.1) 0px 24px 80px;
  border: 2px solid rgb(0,255,242)
}

.book-btn-startup:hover {
  background: rgb(0,255,242, 0.34);
  box-shadow: rgba(0,255,242, 0.4) 0px 8px 24px, rgba(0,255,242, 0.2) 0px 16px 56px, rgba(0,255,242, 0.2) 0px 24px 80px;

  color: rgb(0,255,242);

}








.bg-gl {
  background-color: rgb(64, 0, 255, 51.3);
  border: 1px solid rgb(0, 186, 37)
}

.bg-gl h1 {
  color: rgb(26, 103, 0)
}</style>