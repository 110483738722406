<template>

<div class="rce-container-mbox">
                                <div class="rce-mbox">
                                    <div class="rce-mbox-body">
                                        <div class="rce-mbox-title rce-mbox-title--clear">
                                            <div class="rce-avatar-container default default"><img style="border-radius: 10px;" alt="" 
                                                
                                                :src="this.$store.state.userDetils.length ? this.$store.state.userDetils[0].profile_picture :'' " 
                                                
                                                 class="rce-avatar"></div><span>{{this.$store.state.userDetils.length ? (this.$store.state.userDetils[0].user.first_name + " " +this.$store.state.userDetils[0].user.last_name) :''}}</span>
                                        </div>
                                        <div class="rce-mbox-text">{{chat_sender.text}} </div>
                                        <br>
                                        
                                        <div class="rce-mbox-time non-copiable" >
                                            <span>{{ calculateDaysOld(chat_sender.created) }}</span>
                                            <span v-if="chat_sender.read">
                                                <ion-icon name="checkmark-done-outline"  style="vertical-align: middle; color: rgb(79, 195, 247);height:1em;width:1em"></ion-icon>
                                            </span>
                                            <span v-else>
                                                <ion-icon name="checkmark-done-outline"  style="vertical-align: middle;height:1.2em;width:1.2em"></ion-icon>
                                            </span>
                                            <!-- <span class="rce-mbox-status"><svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 40 40" style="vertical-align: middle; color: rgb(79, 195, 247);">
                                                    <g>
                                                        <path d="m30.3 10.9l-10.9 10.9-2.4-2.4 10.9-10.9z m7.3-2.4l2.4 2.4-20.6 20.6-9.6-9.6 2.4-2.4 7.2 7.1z m-37.6 13.4l2.5-2.4 9.5 9.6-2.4 2.4z">
                                                        </path>
                                                    </g>
                                                </svg></span> -->
                                            
                                            </div>
                                    </div>
                                    <div><svg class="rce-mbox-left-notch" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <defs>
                                                <filter id="filter1" x="0" y="0">
                                                    <feOffset result="offOut" in="SourceAlpha" dx="-2" dy="-5">
                                                    </feOffset>
                                                    <feGaussianBlur result="blurOut" in="offOut" stdDeviation="3">
                                                    </feGaussianBlur>
                                                    <feBlend in="SourceGraphic" in2="blurOut" mode="normal"></feBlend>
                                                </filter>
                                            </defs>
                                            <path d="M20 0v20L0 0" filter="url(#filter1)"></path>
                                        </svg></div>
                                </div>
                            </div>
</template>
<script>
export default {
    name:"card_chat_sender",
    methods:{
         calculateDaysOld(dateString) {
        // Convert the input date string to a Date object
        const messageDate = new Date(dateString);
        // Format the date and time in a human-readable format
        const formattedDateTime = messageDate.toLocaleString();
        return formattedDateTime;
    },
    },
    props:{
        chat_sender:Object
    }
}
</script>
<style scoped>

.rce-mbox-video {
    margin-top: -3px;
    margin-right: -6px;
    margin-left: -6px
}

.rce-mbox-video.padding-time {
    padding-bottom: 12px
}

.rce-mbox-video .rce-mbox-text {
    padding: 5px 0;
    max-width: 300px;
    margin: auto
}

.rce-mbox-video--video {
    position: relative;
    display: flex;
    overflow: hidden;
    justify-content: center;
    border-radius: 5px;
    max-height: 500px
}

.rce-mbox-video--video__block {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    border-radius: 5px;
    display: flex
}

.rce-mbox-video--video img {
    height: 100%;
    min-height: 100px;
    user-select: none
}

.rce-mbox-video--video video {
    width: 100%;
    user-select: none
}

.rce-mbox-video--video__block-item {
    margin: auto;
    cursor: pointer;
    width: 100px;
    height: 100px
}

.rce-mbox-video--download {
    color: #efe;
    display: flex;
    justify-content: center;
    background: none;
    font-size: 3.2em;
    outline: none;
    border: 1px solid #eee;
    border-radius: 100%;
    height: 100px;
    width: 100px
}

.rce-mbox-video--download:hover {
    opacity: .7
}

.rce-mbox-video--download:active {
    opacity: .3
}

.rce-mbox-video--error {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    font-size: 70px;
    color: #eaeaea
}

.rce-container-mbox {
    flex-direction: column;
    display: block;
    overflow-x: hidden;
    min-width: 200px
}

.rce-mbox-forward {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    background: #fff;
    position: absolute;
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px 0 #a4a4a4;
    cursor: pointer;
    transition: all .3s ease;
    top: 0;
    bottom: 0;
    margin: auto
}

.rce-mbox-forward-left {
    display: flex;
    opacity: 0;
    visibility: hidden;
    left: -50px
}

.rce-mbox-forward-right {
    display: flex;
    opacity: 0;
    visibility: hidden;
    right: -50px
}

.rce-mbox-reply-btn-left {
    display: flex;
    opacity: 0;
    visibility: hidden;
    left: -85px
}

.rce-mbox-reply-btn-right {
    display: flex;
    opacity: 0;
    visibility: hidden;
    right: -85px
}

.rce-container-mbox:hover .rce-mbox-forward-left,
.rce-container-mbox:hover .rce-mbox-forward-right,
.rce-container-mbox:hover .rce-mbox-reply-btn-left,
.rce-container-mbox:hover .rce-mbox-reply-btn-right {
    opacity: 1;
    visibility: visible
}

.rce-mbox {
    position: relative;
    background: #fff;
    border-radius: 5px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .2);
    border-top-left-radius: 0;
    flex-direction: column;
    margin: 3px 5px 3px 20px;
    padding: 6px 9px 8px;
    float: left;
    min-width: 140px
}

.rce-mbox.message-focus {
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-duration: 1s;
    animation-name: message-box-default-focus;
    animation-duration: 1s
}

@-webkit-keyframes message-box-default-focus {
    0% {
        background-color: #fff
    }

    to {
        background-color: #dfdfdf
    }
}

.rce-mbox-body {
    margin: 0;
    padding: 0;
    position: relative
}

.rce-mbox.rce-mbox-right {
    float: right;
    margin-left: 5px;
    margin-right: 20px;
    border-top-right-radius: 0;
    border-top-left-radius: 5px
}

.rce-mbox.rce-mbox-right.message-focus {
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-duration: 1s;
    animation-name: message-box-right-focus;
    animation-duration: 1s
}

@-webkit-keyframes message-box-right-focus {
    0% {
        background-color: #d4f1fb
    }

    to {
        background-color: #b8dae6
    }
}

.rce-mbox-text {
    font-size: 13.6px;
    word-break: break-word
}

.rce-mbox-text:after {
    content: "\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0\A0"
}

.rce-mbox-time {
    text-align: right;
    color: rgba(0, 0, 0, .45);
    font-size: 12px;
    /* position: absolute; */
    right: -4px;
    bottom: -5px
}

.rce-mbox-time.non-copiable:before {
    content: attr(data-text)
}

.rce-mbox-time-block {
    right: 0;
    bottom: 0;
    left: 0;
    margin-right: -6px;
    margin-left: -6px;
    padding-top: 5px;
    padding-right: 3px;
    padding-bottom: 2px;
    background: linear-gradient(0deg, rgba(0, 0, 0, .33), transparent);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #fff
}

.rce-mbox--clear-padding {
    padding-bottom: 3px
}

.rce-mbox.rce-mbox--clear-notch {
    border-radius: 5px 5px 5px 5px !important
}

.rce-mbox-right-notch {
    position: absolute;
    right: -14px;
    top: 0;
    width: 15px;
    height: 15px;
    fill: #fff;
    filter: drop-shadow(2px 0 1px rgba(0, 0, 0, .2))
}

.rce-mbox-right-notch.message-focus {
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-duration: 1s;
    animation-name: message-right-notch-focus;
    animation-duration: 1s
}

@-webkit-keyframes message-right-notch-focus {
    0% {
        fill: #d4f1fb
    }

    to {
        fill: #b8dae6
    }
}

.rce-mbox-left-notch {
    position: absolute;
    left: -14px;
    top: 0;
    width: 15px;
    height: 15px;
    fill: #fff
}

.rce-mbox-left-notch.message-focus {
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-duration: 1s;
    animation-name: message-left-notch-focus;
    animation-duration: 1s
}

@-webkit-keyframes message-left-notch-focus {
    0% {
        fill: #fff
    }

    to {
        fill: #dfdfdf
    }
}

.rce-mbox-title {
    margin: 0 0 8px;
    font-weight: 500;
    font-size: 13px;
    color: #4f81a1;
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center
}

.rce-mbox-title:hover {
    text-decoration: underline
}

.rce-mbox-title--clear {
    margin-bottom: 5px
}

.rce-mbox-status {
    margin-left: 3px;
    font-size: 15px
}

.rce-mbox-title>.rce-avatar-container {
    margin-right: 5px
}

.rce-mbox-file {
    padding-bottom: 13px
}

.rce-avatar-container {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center
}

.rce-avatar-container .rce-avatar {
    width: 100%;
    height: 100%
}

.rce-avatar-container.flexible .rce-avatar {
    height: auto !important;
    width: 100% !important;
    border-radius: unset !important;
    overflow: unset !important
}

.rce-avatar-container.default {
    width: 25px;
    height: 25px
}

.rce-avatar-container.rounded {
    border-radius: 5px
}

.rce-avatar-container.circle {
    border-radius: 100%
}

.rce-avatar-container.xsmall {
    width: 30px;
    height: 30px
}

.rce-avatar-container.small {
    width: 35px;
    height: 35px
}

.rce-avatar-container.medium {
    width: 40px;
    height: 40px
}

.rce-avatar-container.large {
    width: 45px;
    height: 45px
}

.rce-avatar-container.xlarge {
    width: 55px;
    height: 55px
}

@keyframes avatarLazy {
    0% {
        opacity: 1
    }

    50% {
        opacity: .5
    }

    to {
        opacity: 1
    }
}


.rce-avatar-container.rce-citem-avatar-encircle-status {
    box-sizing: border-box;
    position: relative
}

.rce-avatar-letter {
    margin-top: 5px;
    font-size: 20px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center
}



</style>