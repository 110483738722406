<template>
    <a-button type="primary" @click="showModal" style="border-radius:100%"><template #icon>
        <ion-icon name="add-circle-outline" style="font-size: 22px;"></ion-icon>
    </template></a-button>
        <a-modal v-model:open="open" title="Create Job Location" :confirm-loading="confirmLoading" @ok="handleOk">
     
                Available Location : <b>{{ location_name }}</b>
    <a-select  v-model:value="location_name" style="text-align: start;width:100%">
    
<a-option :value="loc" v-for="loc in array_locations"  :disabled="loc === location_name "/>
</a-select>
        </a-modal>
    </template>
    <script>
    
    
    
import { notification } from 'ant-design-vue'
import axios from 'axios'

    export default{
        name:"createLocationJobCareers",
        data(){
            return {
                modalText: 'Content of the modal',
                open: false,
                confirmLoading: false,

                location_id:'',
                location_name:'',
                array_locations:[
                    "Remote",
                    "Central Africa",
                    "Cameroon",
                    "Gabon",
                    "Togo",
                    "Ivory Coast",
                    "Tchad",
                    "Burkina Faso",
                    "Central African Republic",
                    "Equatorial Guinea",
                    "Republic of Congo",
                    "Angola",
                    "Sao Tome and Principe",
                    "East Africa",
                    "Rwanda",
                    "Burundi",
                    "Tanzania",
                    "Kenya",
                    "Ethiopia",
                    "Democratic republic of Congo",
                    "Burundi",
                    ],

                job_num:'',
            }
        }, 
          mounted(){
            this.location_id=this.location_id_obj
            this.location_name=this.location_name_obj
            this.job_num=this.job_number
        }, 
         props:{
            job_number:Object,
            location_id_obj:Object,
            location_name_obj:Object
        },
        methods:{
            
                showModal() {
                this.open = true;
                },
                
                async handleOk () {
                this.confirmLoading = true;
                const formData={
                    location:this.location_name
                }
                await axios
        .post("update-job-details-career-crekaa-admin/"+"create_location/"+this.job_number,formData)
        .then(response => {
            // console.log(response);
            // console.log("------------------------------------Got Response------------------------------------");
            // console.log(response.data);

            notification['success']({
                    message: 'Careers Job Experience required has been updated ',
                    description:'Careers job Experience required has been updated successfully, You just need to refresh update page.',
                    placement: 'bottomRight',
                });
        })
        .catch(error => {
            console.log(error)
                notification['error']({
                    message: 'Unable to update Experience required',
                    // description:'Unable To Fetch User Details...',
                    placement: 'bottomRight',
                });

                console.log("error Found unable to update")                        
                console.log(JSON.stringify(error.response.data))
                    console.log(JSON.stringify(error))
        })

        this.confirmLoading = false;
            this.open = false;
            window.location.reload()
            // this.confirmLoading = true;
            // setTimeout(() => {
            //     this.open = false;
            //     this.confirmLoading = false;
            // }, 2000);
            },


    
        }
    }
    </script>
    <style scoped>
    
    </style>
    
    
    