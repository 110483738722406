<template>
<br><br>

<section class="container-credit-terms" style="text-align:start" >
        <br><br><br>
        <h1>Redeem Your Crekaa Promotional Credit</h1>
        <h4> I already have an Crekaa Account</h4>                <router-link to="/redeedm-credit"  >
        <a-button type="primary" style="height:40px;font-size: 14px;" block>Redeedm Credit</a-button>
                      </router-link > 
        <br>
        <div if v-if="!this.$store.state.isAuthenticated">
        <h4>I do not have an Crekaa Account</h4>


            <p>Sign-up for an Crekaa account, and return to this page to redeem your promotional credit.</p>
            
                           <router-link to="/signin"  > <a-button class="btn-reedm" block>Sign-Up For An Crekaa Account</a-button>              </router-link > 
        </div>

        <br><hr>
        <h4>Save on Billing: 1 Credit = 7 INR</h4>
        <p>Introducing our Billing System where 1 Credit is equivalent to a 7 INR discount on your bill. For each credit applied, you'll enjoy a relief of 7 INR from your total amount due.</p>
        <p>Use Credits Wisely: The minimum limit to apply the credit is 1 credit, and you can apply up to 100 credits for each bill, giving you the flexibility to save even more.</p>
        <p>Act Now, Limited Time: Each Credit comes with an expiration date, so make sure to utilize them before they expire to make the most of your savings!</p>
        <hr>
        <h1>Crekaa Promotional Credit Terms & Conditions</h1>
        <h4> Last Updated: August 1, 2023</h4>
        <div>
            <p>These Crekaa Promotional Credit Terms and Conditions (these “<b>Terms</b>”) are an agreement between you
                and Crekaa Technologies Services, (or its affiliate as noted in Section 14 below) (“<b>Crekaa</b>,”
                “<b>your Crekaa contracting entity</b>,” “<b>us</b>,” “<b>our</b>,” or “<b>we</b>”) and govern your use
                of any Crekaa promotional credit (“<b>Promotional Credit</b>”). You must have a valid Crekaa account to
                redeem a Promotional Credit code. Capitalized terms used but not defined in these Terms will have the
                meanings set forth in the agreement between you and your Crekaa contracting entity governing your use of
                the Services (the “<b>Agreement</b>”).</p>
            <p><b>1. </b>Promotional Credit will be applied only to offset eligible fees and charges incurred during or
                following the billing cycle in which you apply the applicable Promotional Credit code to your Crekaa
                account.&nbsp; Promotional Credit will be applied only to the specific Services designated by your Crekaa
                contracting entity (collectively, “<b>Eligible Services</b>”). Promotional Credit will not be applied to
                any fees or charges for Crekaa Managed Services, Ineligible Crekaa Support, Crekaa                
                Marketplace, Crekaa Professional Services or any other Services as may be
                designated by your Crekaa contracting entity, or any upfront fee for any Services such as Savings Plans 
                (collectively, “<b>Ineligible Services</b>”). For purposes of this Section 1,
                “<b>Ineligible Crekaa Support</b>” means Crekaa Support (as described on the Crekaa Site) that is at the
                Buisness Enterprise Support level.</p>
            <p><b>2. </b>Promotional Credit will not be applied to your Crekaa account until you redeem your Promotional
                Credit code through the Crekaa Site or as otherwise directed by us. Each Promotional Credit code can only
                be redeemed once and only on one AWS account. Your AWS account will be billed for all fees and charges
                for use of any Eligible Services in excess of the amount of available Promotional Credit.</p>
            <p><b>3.</b> You may not sell, license, rent, or otherwise transfer Promotional Credit. Promotional Credit
                may be applied only to your own AWS account. Promotional Credit has no intrinsic value, is not
                redeemable for cash, has no cash value, is nonrefundable, and serves merely as a means to provide an
                incentive to use our Services. Promotional Credit may not be purchased for cash, and we and our
                affiliates do not sell Promotional Credit.</p>
            <p><b>4.</b> Promotional Credit is void in the event of fraud, misuse, or violation of any terms of the
                Agreement, these Terms, or the terms of the agreement, promotion, or program pursuant to which you
                received the Promotional Credit. Promotional Credit is void if sold for cash or other
                consideration.&nbsp; Any Promotional Credit applied to Crekaa accounts that are determined by Crekaa to have
                been created solely for the purpose of obtaining Promotional Credit is void. In addition to any other
                legal relief available to us, you will reimburse us for the amount of any Promotional Credit you use in
                violation of these Terms.</p>
            <p><b>5.</b> By accepting Promotional Credit, you represent and warrant that your receipt and use of
                Promotional Credit is not prohibited or inconsistent with any applicable laws, regulations, or binding
                orders (including applicable ethics or procurement rules) and will not create a conflict of interest for
                your Crekaa contracting entity.</p>
            <p><b>6.</b> If you are a federal government entity or other entity that is subject to special rules
                governing your receipt of Promotional Credit, you may not use or receive Promotional Credit directly
                from your Crekaa contracting entity unless agreed by us in writing.</p>
            <p><b>7.</b> Unless authorized by us, Promotional Credit may not be used in conjunction with any other
                promotional or incentive offer from us or our affiliates.</p>
            <p><b>8.</b> Promotional Credit will not be applied against any sales, use, gross receipts, or similar
                transaction based taxes that may be applicable to you.</p>
            <p><b>9.</b> You may not issue any press release or make any other public disclosure regarding Promotional
                Credit without our prior written consent. In addition, you agree not to misrepresent or embellish the
                relationship between us and you. For example, you agree not to imply that we support, sponsor, or
                endorse you unless you have our prior written permission to do so.</p>
            <p><b>10. </b>Promotional Credit is valid for a limited time only and cannot be redeemed after the
                expiration date indicated when you receive the applicable Promotional Credit code (or such other date
                designated by us or in these Terms). Redeemed Promotional Credit will be applied to offset eligible fees
                and charges incurred during the entire billing cycle in which it expires. Failure to redeem Promotional
                Credit before its expiration date will result in its forfeiture. We reserve the right to cancel
                Promotional Credit at any time. No refunds will be granted for any expired or cancelled Promotional
                Credit.</p>
            <p><b>11.</b></p>
            <p style="margin-left: 40.0px;">(a) Promotional Credit provided under the Cloud Credits for Nonprofits
                Program expires one year from the earlier of the date you receive the Promotional Credit code or when we
                deposit the Promotional Credit in your AWS account. Promotional Credit provided under the Cloud Credits
                for Nonprofits Program can only be provided to Crekaa accounts registered with the nonprofit institution’s
                domain name. Your Crekaa contracting entity is permitted to use your name and likeness in furtherance of
                the Cloud Credits for Nonprofits Program and to identify you as a Promotional Credit recipient and Crekaa
                customer.</p>
            <p style="margin-left: 40.0px;">(b) Promotional Credit provided under the Crekaa Cloud Credits for Research
                Program expires one year from the earlier of the date you receive the Promotional Credit code or when we
                deposit the Promotional Credit in your Crekaa account. to identify you as a Promotional Credit
                recipient and AWS customer.</p>
            <p><b>12. </b>You agree to maintain in strict confidence any data or information relating to the Service
                Offerings that you discover, derive, develop, or otherwise learn about through your use of the Service
                Offerings. This includes, but is not limited to, data or information relating to the Service Offering’s
                security and any suggested improvements to the Service Offerings.</p>
            <p><b>13.</b> We may modify these Terms at any time by posting a revised version on the Crekaa Site or by
                sending you an email using the email address provided in your application for Promotional Credit. The
                modified Terms will become effective upon posting or, if we notify you by email, as stated in the email
                message. By receiving or using Promotional Credit after the effective date of any modification to these
                Terms, you agree to be bound by the modified Terms. We last modified these Terms on the date listed at
                the top of this page.</p>
            <p><b>14.</b>&nbsp;Not withstanding anything to the contrary in these Terms, if you redeem a Promotional
                Credit code or otherwise use Promotional Credit on an Crekaa account with an affiliate of Crekaa Technologies, 
                as your AWS contracting entity under your Agreement, the Promotional Credit will be
                deemed to be offered by that affiliate and references to “Crekaa,” “your Crekaa contracting entity,” “us,”
                “our,” and “we” will be references to that affiliate.</p>
        </div>
    </section>
</template>
<script>
export default{
    name:"credit_terms",
    
created(){
    document.title = "Crekaa Credits Terms & Conditions - Crekaa";
  },
  head() {
    return {
      title: "Crekaa Credits Terms & Conditions - Crekaa",
      meta: [
        { hid: 'description', name: 'description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'author', name: 'author', content: "Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        
        // <!-- Facebook Open Graph tags -->
        { hid: 'og:url', property: 'og:url', content: "https://crekaa.com/" },
        { hid: 'og:type', property: 'og:type', content: "website" },
        { hid: 'og:title', property: 'og:title', content: "Crekaa, Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'og:description', property: 'og:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'og:image', property: 'og:image', content: "../../assets/logo.png" },
        // Add more meta tags as needed

        // <!-- Twitter Card tags -->
        { hid: 'twitter:card', name: 'twitter:card', content: "summary_large_image" },
        { hid: 'twitter:title', name: 'twitter:title', content: "Crekaa , Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'twitter:description', name: 'twitter:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'twitter:image', name: 'twitter:image', content: "../../assets/logo.png" },

      ]
    };
  },
}
</script>
<style scoped>



    .container-credit-terms {
        width: 90%;
        max-width: 1280px;
        margin: auto
    }

    h1 {
        font-size: 30px;
        margin-bottom:30px
        /* font-family: Arial, Helvetica, sans-serif; */
    }

    h4 {
        font-size: 24px;
        margin-bottom:30px
        /* font-family: Arial, Helvetica, sans-serif; */
    }

    p {
        font-size: 14px;
        margin-bottom:30px
        /* font-family: Arial, Helvetica, sans-serif; */
    }
    .btn-reedm{
        height:100%;
        /* background:rgb(227, 127, 5); */
        font-weight:bold;
        text-align: center;
        font-size:16px;
        /* border-radius:10px;color: white; */
    }
    .btn-reedm:hover{
        /* background:rgb(255, 140, 0); */
        cursor: pointer;
            }
</style>