<template>
    <div class="right-pane">

<h1 class="heading-budget-page">Guidlines </h1>
Certain guidlines we follow for your <b>Buisness Website</b> & our <b>Website Products</b>
for you & product security !
<hr>
<!-- <video style="width:100%" controls muted autoplay
    src="{% static 'graphics/project crekaa promotional project.mp4' %}"></video> -->
<br>
<ul class="ul-guidlines">
    <li>As we are providing software as service we had to grant your project security for
        that purpose we do not share any confidential containts to any person not even you
        as customer. <ul>
            <li>Confidencial Containts</li>
            <li>Platform Code </li>
            <li>Sensitive Informations</li>
        </ul>
    </li>
    <li>Project will be delivered after order max 30 days of time span for developent min 20
        days.</li>
    <li> You had to pay <b>₹2500</b> as initial monthly payment bill for Platform
        Maintainence, Routine Backups, & Servers Costings.<br>( Monthly Payment Varies On
        Usage Of Platform, Storage Usage, Server Usages & Trafic On Platform )</li>
    <li>After platform is ready if you have custom module need or addon developent need by
        your buisness we charge you accondingly to developent cost of each extra module.
    </li>
    <li>As per changes in requirements in already developed platforms, costing of
        developement will varies according changes level. </li>
    <li>As per demand to show the yearly platform progress we fulfill it with according
        standard charges. </li>
    <li>Data Security: We ensure the highest level of data security for your platform. We
        implement robust encryption techniques and follow industry best practices to protect
        your confidential information and sensitive data.</li>
    <li>Regular Security Audits: To maintain the security of the platform, we conduct
        regular security audits to identify any vulnerabilities or potential risks. This
        helps us proactively address security concerns and ensure a safe environment for
        your business.</li>
    <li>Scalability and Performance: Our platform is designed to be scalable, allowing it to
        handle increasing user demands and growing traffic. We optimize performance to
        deliver a seamless experience for your users, even during peak usage periods.</li>
    <li>We provide ongoing updates and maintenance for the platform to ensure it remains
        up-to-date with the latest technologies, security patches, and feature enhancements.
        This helps in keeping your platform secure and compatible with evolving industry
        standards.</li>
    <li>Technical Support: Our team of experts is available to provide technical support and
        assistance whenever you need it. Whether you have questions, encounter issues, or
        require guidance, we are here to help you promptly and efficiently.</li>
    <li>Customization and Integration: We offer the flexibility to customize the platform
        according to your specific business requirements. If you need to integrate
        third-party services or add custom features, our development team will work closely
        with you to meet your needs.</li>
    <li>Transparent Communication: We believe in maintaining transparent communication
        throughout the project lifecycle. You will have access to regular progress updates,
        and we encourage open dialogue to address any concerns or suggestions you may have.
    </li>
    <li>Training and Documentation: Once the project is completed, we provide comprehensive
        training sessions to ensure you and your team are proficient in using the platform
        effectively. Additionally, detailed documentation is provided for reference,
        covering various aspects of the platform's functionality.</li>
    <li>You can keep track of project for every minimal patch from your dashboard here on
        <b>Crekaa</b>.</li>
    <li>
        We aim to establish a long-term partnership with our clients. Beyond the development
        phase, we offer continued support, periodic reviews, and assistance in enhancing and
        expanding the platform to meet your evolving business needs.
    </li>
    <li>After developent if you wanted to stop & leave the project you can do it as per your
        need.</li>
    
        <li>All rights of your project code & endpoints are reserved by crekaa.
    </li>
    <li>Upon full payment, the client owns the intellectual property rights to the delivered website. Crekaa retains the right to showcase the project in its portfolio.</li>
    <li>Crekaa is not liable for any loss, damage, or additional costs incurred due to unforeseen events or third-party actions.</li>
    <li>Upon termination, the client is responsible for payment for services provided up to the termination date.</li>
    <li>Crekaa reserves the right to update or modify these terms and cond
        </li>
    <li>We are happy to serve you as per our experties.</li>
    <li> Your satisfaction is our top priority. We strive to deliver high-quality solutions
        that meet your expectations and contribute to the success of your business. We work
        closely with you to understand your requirements and ensure that the final product
        aligns with your vision</li>
    <li>What plan to choose for me?
        <ul>
            <li>If you have plan to startup & need funding you need to choose <b>Startup</b>
                planas its cost efficient. </li>
            <li>If you already have buisness & want to take it online or grow it online you
                need to choose <b>Standard</b> plan that will help you to grow fastly. </li>
            <li>If you plans & idea about what functionality you need you can choose
                <b>Custom</b> plan & get the platform redy as you your need. </li>
        </ul>

    </li>
    <li>What we do for your platfom ?
        <ul>
            <li>Search Engine Optimisation (SEO) </li>
            <li>Full Stack developent </li>
            <li>Applications developent</li>
            <!-- <li>We have own frameworks to BooM in the market </li> -->
            <li>We have own technologies to BooM in the market </li>
            <li>Logo Designing</li>
            <li>Servers Archtecture Designing</li>
            <li>Servers Management</li>
        </ul>
    </li>

</ul>


</div>
</template>
<script>
export default {
    name:"guidlines_web"
}
</script>
<style scoped>

</style>