<template>
    <section class="main-screen" ref="mainScreen">
        <div style="height: 200px; text-align: center">
        <h1
          style="
            font-size: 2rem;
            font-weight: bold;
         margin-top:50px;   /* line-height: 45px; */
            color: rgb(166, 0, 255);
          "
        >
          EduTech Application <br />For Educational Institution
        </h1>
        <!-- <p style=""> -->
            <div style="  color: grey;width:100%;max-width:750px;text-align:center;margin:auto;font-size: 14px;">
                Elevate your organization with a full technology solution.<br> Manage course enrollments, fees, tests, and create engaging online classrooms.
            </div>
        <!-- </p> -->
        <br />
        <br />
        <a href="http://edutech-crekaa.s3-website-us-west-2.amazonaws.com" class="button-primary">
            Get Started 
                </a>
                <br />

      </div>
      <div class="card" ref="card">
        <img src="../../assets/the_assets/Images/XYZ-Acadmy.png" style="border-radius: 10px;" alt="Dashboard Image"  />
        <div class="content">
          <img src="../../assets/the_assets/Images/hero-element.png" style  />
        </div>
      </div>
    </section>
</template>
<script>
export default {
    name: "projectMouseTrackerCard",
    props: {
        mainImage: Object,
        secondaryImage: Object,
        hyperLink: Object
    },
    mounted() {
        document.addEventListener("mousemove", (e) => {
    // 창의 중심을 기준으로 마우스 위치 계산
    let x = (window.innerWidth / 2 - e.pageX) / 20; // 민감도 조정
    let y = (window.innerHeight / 2 - e.pageY) / 20; // 민감도 조정

    // 최대 변경 각도를 10도로 제한하고, 음수 각도도 허용
    x = Math.min(Math.max(x, -10), 10); 
    y = Math.min(Math.max(y, -7), 7);

    // .card 요소에 변형 적용
    if (this.$refs.card) {
        // translateZ 계산 시 분모가 0이 되지 않도록 보호 (예: x가 0일 때)
        let translateZ = x !== 0 ? y / x * 10 : 0; // x가 0일 경우 분모가 0이 되지 않도록 조정
        this.$refs.card.style.transform = `rotateY(${x}deg) rotateX(${y}deg) translateX(${x}px) translateY(${y}px) translateZ(${translateZ}px)`;
    }
});



    }
}
</script>

<style scoped>
.main-screen{
    background-image: url('../../assets/the_assets/Images/hero-bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding:10px;
    border-radius: 10px;
}
:root {
        --card-width: 850px;
        --text-translateZ: 50px;
        --v-theme-primary-overlay-multiplier: 1;
      }
      body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: "Raleway", Arial, sans-serif;
        /* color: white; */
        background-size: 100%;
        min-height: 95vh;
        cursor: default;
      }

      .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        transform-style: preserve-3d;
        perspective: 1000px; 
      }

      .card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: var(--card-width);
        height: calc(var(--card-width) / 1.81);
        transform-style: preserve-3d;
        position: relative;
        transform-style: preserve-3d;
        transition: transform 0.5s ease; /* Add this line */
      }

      .card img {
        width: 100%;
        max-width: 1000px;
        object-fit: fill;
        user-select: none; /* Prevent image selection */
  pointer-events: none; /* Disable pointer events */
      }

      .card .content {
        transform-style: preserve-3d;
        text-align: center;
        position: absolute;
      }

      .card .content img {
        /* width:100%; */
        margin-top:40px;
        /* max-width:1400px; */
        transform: translateZ(50px);
        width: 100%;
        user-select: none; /* Prevent image selection */
  pointer-events: none; /* Disable pointer events */
        max-width: 1100px;

        /* max-width: 1200px; */
        height: auto;
      }
      .button-primary {
        --v-theme-overlay-multiplier: var(--v-theme-primary-overlay-multiplier);
        background-color: rgb(166, 0, 255);
        color: white;
        text-decoration: none;

        cursor: pointer !important;
        padding: 10px 40px 10px 40px;
        border: none;
        font-size:16px;
        /* margin-top: 20px;; */
        font-weight:bold;
        border-radius: 5px;
      }
</style>