<template>
    <section class="main">
        <br>
        <br>
        <br>
        <a-row>
            <a-col v-for="request in allrequests" :key="request.datetime" :sm="24" :xs="24" :md="12" :lg="12" style="padding:5px;">
                <div class="card">
                    <a-row>
                        <a-col :span="12" ><h3>  <b>Status:   </b> {{request.status}}</h3></a-col>
                        <a-col :span="12" ><h3> <b> Category: </b> {{request.category}}</h3></a-col>

                        <a-col :sm="24" :xs="24" :md="12" :lg="12" ><h3 :style="request.user__email ? 'color:var(--text-color)' : 'color:red'"> <b> User Account: </b> {{request.user__email ? request.user__email : "Not Authenticated/ SignedIn User"}}</h3></a-col>
                        <a-col :sm="24" :xs="24" :md="12" :lg="12" ><h3> <b> Email  : </b> {{request.email}}</h3></a-col>
                        <a-col :sm="24" :xs="24" :md="12" :lg="12" ><h3> <b> Name  : </b> {{request.name}}</h3></a-col>
                        
                        <a-col :span="24" ><h3> <b> title   </b>: {{request.title}}</h3></a-col>
                        <a-col :span="24" ><h3> <b> subject   </b>: {{request.subject}}</h3></a-col>
                        <a-col :span="24" ><h3>  <b>description:   </b> {{request.description}}</h3></a-col>
                        <a-col :span="24" ><h4> <b> datetime   </b>: {{request.datetime}}</h4> </a-col>
                    </a-row>
                    
                    
                
                    
                    
                    
                    
                    
                </div>
            </a-col>
        </a-row>
            

            <a-pagination v-model:current="current" simple :total="total_pages" @change="onChange" :pageSize="20">
        </a-pagination>
        
    </section>
</template>
<script>
import axios from 'axios'
export default {
    name:"allContactUsRequests",
    data(){ return {
        allrequests:[],
        nextUrl: null,
        prevUrl: null,
        current: 1,
        total_pages: 0,
    }},
    mounted() {
        this.fetchallContactUsRequests()
    },
    methods: {
        onChange(pageNumber) {
            // console.log(this.nextUrl);
            // console.log('Page: ', pageNumber);
            axios.get(this.nextUrl.slice(0, -1) + String(pageNumber))
                .then(response => {
                    this.allrequests = response.data['results']['applications'];
                    // this.nextUrl = response.data['next'];
                    // this.prevUrl = response.data['previous'];

                })
                .catch(error => {
                    console.error('Error loading more applications:', error);
                });
        },

        async fetchallContactUsRequests () {
            this.$store.commit('setIsLoading', true)

            await axios
                .get("admin-all-contact-usrequests-recived/")
                .then((response)=>{
                    console.log(response)
                    // this.allrequests=response['data']

                    this.total_pages = response.data['count'];
                    this.allrequests = response.data['results']['applications'];
                    this.nextUrl = response.data['next'];
                    this.prevUrl = response.data['previous'];

                }).catch((error)=>{
                    console.log("error Occured while fetching all Contact-usrequests ")
                })
            this.$store.commit('setIsLoading', false)

        }
    }
}
</script>
<style scoped>
.main{
    min-height: 100vh;
}
.card{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: var(--card-color);
    padding:10px;
    text-align:start;
    margin-bottom:10px;
    border-radius:10px;

}
</style>