<template>
    <main>
        <section>


            <br><br><br>
            <div class="main-section-containter-creedit-reedm" style="min-height:100vh">
                <div class="flex-responsive">
                    <a-row>
                        <a-col :sm="24" :xs="24" :md="12" :lg="12">
                            <div class="wallet-credit">
                                <div class="card-wallet-credit">
                                    <div class="all-about-wallet-credits">
                                        <h2>
                                            Total Reived Credits :
                                        </h2>
                                        <div class="credit-amount">
                                            <img src="https://img.icons8.com/arcade/50/coins--v1.png"
                                                style="width:60px;height:60px" alt="">
                                            <span><b>{{ user_credit_amount }}</b> </span>
                                        </div>

                                    </div>
                                    <div class="about-wallet-credits">
                                        <div class="active-credits-1">
                                            <div class="title-credit-wallet">Active Credits: </div>
                                            <div class="credit-amount">
                                                <img src="https://img.icons8.com/arcade/50/coins--v1.png"
                                                    style="width:60px;height:60px" alt="">
                                                <span><b>{{ active_credits_ammount }}</b> </span>
                                            </div>
                                        </div>

                                        <div class="active-credits-2">
                                            <div class="title-credit-wallet">Expired Credits:</div>
                                            <div class="credit-amount">
                                                <img src="https://img.icons8.com/arcade/50/coins--v1.png"
                                                    style="width:60px;height:60px" alt="">
                                                <span><b>{{ expired_credits_ammount }}</b> </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a-col>
                        <a-col :sm="24" :xs="24" :md="12" :lg="12">
                            <div class="form">
                                <br>
                                <!-- <form action="{% url 'reedm_credits' %}" method="post"> -->
                                <div class="card-reedm-credits"
                                    style="border-radius:20px;height:160px;margin-left:10px;margin-right:10px">
                                    <label style="font-size: 15PX;font-weight: bold;margin-bottom: 10px;">Redeem
                                        Credit</label>
                                    <br>

                                    <a-input v-model:value="credit_tr_id" style="border-radius:5px"
                                        placeholder="XXXX-XXXX-XXXX-XXXX" show-count :maxlength="16" />
                                    <a-button type="submit" size="large" @click="reedmCredits()" class="bitton-reedm">Redeem</a-button>
                                </div>
                                <!-- </form> -->
                            </div>
                        </a-col>
                    </a-row>




                </div>

                <br><br>
                <div class="card">

                    <div class="history-credit-reedmmptin" style="text-align: start;padding:20px">
                        <br><br>
                        <h2>Your Credit Details : </h2>
                        <a-tabs v-model:activeKey="activeKey" centered size="large">
                            <a-tab-pane key="1">
                                <template #tab>
                                    <h4 style="font-size: 12px;font-weight: bold;font-family: 'Alkatra';">History</h4>
                                </template>
                                <legend>All Credits History: </legend>

                                <div v-if="!history">

                                    <h1> No Credits History Found. </h1>
                                    <br>

                                </div>
                                <div v-else>
                                    <div class="credit-details-card" v-for="his in history" :key="his.id">
                                        <div class="flx-responsive">

                                            <span class=""> Credit ID : {{ his.credit_tr_id }}</span>
                                            <span> Expires On : {{ his.expires_datetime }}</span>
                                        </div>
                                        <div class="flx">
                                            <h1> Credit Ammount :</h1>

                                            <div class="credit-amount">
                                                <img src="https://img.icons8.com/arcade/50/coins--v1.png"
                                                    style="width:60px;height:60px" alt="">
                                                <span><b>{{ his.amount }}</b> </span>
                                            </div>
                                        </div>

                                        <span class="title-credit"> Title : {{ his.title }}</span>
                                        <div class="flexed-right">
                                            <div>

                                                <span> Issued On : {{ his.datetime }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </a-tab-pane>
                            <a-tab-pane key="2" force-render>
                                <template #tab>
                                    <h4 style="font-size: 12px;font-weight: bold;font-family: 'Alkatra';">Active Credits
                                    </h4>
                                </template>
                                <legend>Active Credits: </legend>
                                <div v-if="!active_credits">

                                    <h1> No Active Credits Found. </h1>
                                    <br>

                                </div>
                                <div v-else>
                                    <div class="credit-details-card" v-for="his in active_credits" :key="his.id">
                                        <div class="flx-responsive">

                                            <span class=""> Credit ID : {{ his.credit_tr_id }}</span>
                                            <span> Expires On : {{ his.expires_datetime }}</span>
                                        </div>
                                        <div class="flx">
                                            <h1> Credit Ammount :</h1>

                                            <div class="credit-amount">
                                                <img src="https://img.icons8.com/arcade/50/coins--v1.png"
                                                    style="width:60px;height:60px" alt="">
                                                <span><b>{{ his.amount }}</b> </span>
                                            </div>
                                        </div>

                                        <span class="title-credit"> Title : {{ his.title }}</span>
                                        <div class="flexed-right">
                                            <div>

                                                <span> Issued On : {{ his.datetime }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </a-tab-pane>
                            <a-tab-pane key="3">
                                <template #tab>
                                    <h4 style="font-size: 12px;font-weight: bold;font-family: 'Alkatra';">Expired Credit
                                    </h4>
                                </template>
                                <legend>Expired Credits: </legend>
                                <div v-if="!expired_ones">

                                    <h1> No Expired Credits Found. </h1>
                                    <br>

                                </div>
                                <div v-else>
                                    <div class="credit-details-card" v-for="his in expired_ones" :key="his.id">
                                        <div class="flx-responsive">

                                            <span class=""> Credit ID : {{ his.credit_tr_id }} </span>
                                            <span> Expires On : {{ his.expires_datetime }} </span>
                                        </div>
                                        <div class="flx">
                                            <h1> Credit Ammount :</h1>

                                            <div class="credit-amount">
                                                <img src="https://img.icons8.com/arcade/50/coins--v1.png"
                                                    style="width:60px;height:60px" alt="">
                                                <span><b>{{ his.amount }}</b> </span>
                                            </div>
                                        </div>

                                        <span class="title-credit"> Title : {{ his.title }}</span>
                                        <div class="flexed-right">
                                            <div>

                                                <span> Issued On : {{ his.datetime }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a-tab-pane>
                            <a-tab-pane key="4">
                                <template #tab>
                                    <h4 style="font-size: 12px;font-weight: bold;font-family: 'Alkatra';">Credits Usage</h4>
                                </template>
                                <legend>Credits Usage: </legend>
                                <div v-if="!used_credits">

                                    <h1> No Expired Credits Found. </h1>
                                    <br>
                                    <!-- datetime
credit__credit_tr_id
credit__expires_datetime
credit__amount
credit__title
credit__datetime -->
                                </div>
                                <div v-else>
                                    <div class="credit-details-card" v-for="his in used_credits" :key="his.id">
                                        <div class="flx-responsive">

                                            <span class=""> Credit ID : {{ his.credit__credit_tr_id }}</span>
                                            <span> Expires On : {{ his.credit__expires_datetime }}</span>
                                        </div>
                                        <div class="flx">
                                            <h1> Credit Ammount :</h1>

                                            <div class="credit-amount">
                                                <img src="https://img.icons8.com/arcade/50/coins--v1.png"
                                                    style="width:60px;height:60px" alt="">
                                                <span><b>{{ his.credit__amount }}</b> </span>

                                            </div>
                                        </div>

                                        <div class="flx">
                                            <h1> Base Credit Ammount :</h1>

                                            <div class="credit-amount">
                                                <img src="https://img.icons8.com/arcade/50/coins--v1.png"
                                                    style="width:60px;height:60px" alt="">
                                                <span><b>{{ his.credit__base_amount }}</b> </span>

                                            </div>
                                        </div>

                                        <span class="title-credit"> Title : {{ his.credit__title }}</span>
                                        <div class="flexed-right">
                                            <div>

                                                <span> Issued On : {{ his.credit__datetime }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </a-tab-pane>
                            <a-tab-pane key="5">
                                <template #tab>
                                    <h4 style="font-size: 12px;font-weight: bold;font-family: 'Alkatra';">Credit Events</h4>
                                </template>
                                <legend>Credit Events: </legend>
                                <div>
                                    <!-- if no credit events is available -->
                                    <h1> No Recent Credit Event Found. </h1>
                                    <br>
                                </div>
                            </a-tab-pane>
                            <a-tab-pane key="6">
                                <template #tab>
                                    <h4 style="font-size: 12px;font-weight: bold;font-family: 'Alkatra';">About</h4>
                                </template>
                                <legend>Credit About: </legend><br>
                                <div style="border:1px  solid var(--devide-color);padding:20px">

                                    <h4 style="margin:0;padding:0"> Please Read All Terms And Conditions Here: </h4>
                                    <br>
                                    <router-link class="t_a_c" to="credit-terms">
                                        <a-button block type="primary">Terms & conditions</a-button>
                                    </router-link>
                                </div>
                                <br>
                            </a-tab-pane>
                        </a-tabs>

                        <br>
                        <br>
                        <br>

                    </div>

                </div>

            </div>
        </section>
    </main>
</template>


<script>
import axios from 'axios';

import { notification } from 'ant-design-vue';

export default {
    name: "reedm_credits",
    data() {
        return {
            activeKey: '1',
            credit_tr_id: '',

            // fetchcredit_details
            user_credit_amount: null,
            active_credits: null,
            used_credits: null,
            expired_ones: null,
            history: null,
            active_credits_ammount: 0,
            expired_credits_ammount: 0,


        }
    },
    
created(){
    document.title = "Reedm Crekaa Credits - Crekaa";
  },
    mounted() {
        this.fetchCreditDetails();
    },
    methods: {
        async fetchCreditDetails() {
            // fetch credit details api here 
            await axios
                .get("credit-details/")
                .then(response => {

                    // console.log("================================p=====================")
                    // console.log(response.data)
                    this.user_credit_amount = response.data["credit_amount"]
                    this.active_credits = response.data["active_credits"]
                    this.used_credits = response.data["used_credits"]
                    this.expired_ones = response.data["expired_ones"]
                    this.history = response.data["history"]
                    this.active_credits_ammount = response.data["active_credits_ammount"]
                    this.expired_credits_ammount = response.data["expired_credits_ammount"]


                })
                .catch(error => {

                    // this.errors.push('Something went wrong. Please Try Again ')
                    console.log(JSON.stringify(error))
                })
            this.$store.commit('setIsLoading', false)
        },
        async reedmCredits() {
            if( this.credit_tr_id == ''){
                notification['error']({
                            message: "Please input your credit code first! ",
                            // description:"",
                            placement: 'bottomRight',
                        });
                return
            }
            // code here to reedm credits
            this.$store.commit('setIsLoading', true)

            await axios
                .post("credit-details/", { credit_tr_id: this.credit_tr_id })
                .then(response => {

                    // console.log("================================p=====================")
                    // console.log(response.data['status'])
                    if (response.data['status'] == 'success') {
                        notification['success']({
                            message: response.data['message'],
                            description: "Congratulations! Your credit redemption was successful, and the credited amount has been added to your account. Feel free to utilize your credits before they expire. Please note that the credits will be automatically applied to your upcoming bills.",
                            placement: 'bottomRight',
                        });
                        this.$router.push('/my-dashboard')

                    }
                    else if (response.data['status'] == 'error') {
                        notification['error']({
                            message: response.data['message'],
                            // description:"",
                            placement: 'bottomRight',
                        });
                    }

                })
                .catch(error => {
                    // this.errors.push('Something went wrong. Please Try Again ')
                    console.log(JSON.stringify(error))
                })
            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>


<style scoped>
.flx {
    display: flex;
}

.main-section-containter-creedit-reedm {
    width: 100%;
    max-width: 1280px;
    margin: auto
}

.card-reedm-credits {
    border: 1px solid var(--devide-color);

    /* width: 80%; */
    max-width: 100%;
    padding: 20px;
    margin: auto
}

input {
    outline: none;
    padding: 5px;
    background-color: var(--card-color);
    border-radius: 5px;
    border: 1px solid var(--devide-color);
    width: 100%;
    font-size: 14px
}

.bitton-reedm {
    background-color: red;
    border-radius: 10px;
    width: 100%;
    margin-top: 20px;
    padding: 5px;
    text-align: center;
    outline: none;
    border: none;
    /* color:white; */
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
}

.bitton-reedm:hover {
    background-color: rgba(255, 0, 0, 0.763);

}

h1 {
    font-size: 30px;
    font-family: Arial, Helvetica, sans-serif;
}

h4 {
    font-size: 24px;
    font-family: Arial, Helvetica, sans-serif;
}

p {
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
}

/* navtabs  */
.nav-tabs {
    display: inline-flex;
    width: 100%;
    overflow-x: auto;
    border-bottom: 2px solid #DDD;
    -ms-overflow-style: none;
    /* IE 10+ */
    overflow: -moz-scrollbars-none;
    /* Firefox*/
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
    border-width: 0;
}

.nav-tabs>li>a {
    border: none;
    color: #666;
}

.nav-tabs>li.active>a,
.nav-tabs>li>a:hover {
    border: none;
    color: #4285F4 !important;
    background: transparent;
}

.nav-tabs>li>a::after {
    content: "";
    background: #4285F4;
    height: 2px;
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 1px;
    transition: all 250ms ease 0s;
    transform: scale(0);
}

.nav-tabs>li.active>a::after,
.nav-tabs>li:hover>a::after {
    transform: scale(1);
}

.tab-nav>li>a::after {
    background: #21527d none repeat scroll 0% 0%;
    color: #fff;
}

.tab-content {
    padding-left: 10px;
    padding-right: 10px
}

.nav-tabs::-webkit-scrollbar {
    display: none;
    /*Safari and Chrome*/
}

.card {
    background: var(--card-color) none repeat scroll 0% 0%;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    margin-bottom: 30px;
}

.wd-100 {
    width: 100%;
}


/* ow */
ol,
ul {
    margin-top: 0;
    margin-bottom: 10px
}

.row {
    margin-right: -15px;
    margin-left: -15px
}

.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav>li {
    position: relative;
    display: block
}

.nav>li>a {
    position: relative;
    display: block;
    padding: 10px 15px
}

.nav-tabs {
    border-bottom: 1px solid #ddd
}

.nav-tabs>li {
    float: left;
    margin-bottom: -1px
}

.nav-tabs>li>a {
    width: 100%;
    max-width: 130px;
    word-wrap: break-word;
    /* margin-right: 2px; */
    line-height: 1.42857143;
    /* border: 1px solid transparent; */
    border-radius: 4px 4px 0 0;
    font-size: 12px;
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
    color: #555;
    cursor: default;
    background-color: #fff;
    /* border: 1px solid #ddd; */
    border-bottom-color: transparent;
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    position: relative;
    border: 1px solid transparent
}

.container-mob {
    padding: 10px
}

.lis {
    font-size: 12px
}

/* deff */

.credit-details-card {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--devide-color);
    margin-bottom: 5px;

}

.flx-responsive {
    display: flex;
    justify-content: space-between;
}

.flx-responsive span {
    font-size: 12px;
    font-weight: bold
}

.credit-amount {
    display: flex;
}

.credit-amount span {
    font-size: 40px;
}

.title-credit {
    font-size: 14px
}

.flexed-right {
    text-align: end;
}

.flexed-right span {
    font-size: 12px;
    text-align: end;
}

.t_a_c {
    padding: none;
    margin: none;
    /* border: 1px solid black; */
    /* margin: 10px; */
    font-weight: 24px
}

.t_a_c:hover {
    background-color: #ddd;
}


/* <!-- wallet --> */




.card-wallet-credit {
    margin: 10px;
    border-radius: 20px;
    /* box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px; */
    border: 1px solid var(--devide-color);

    height: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
}

.about-wallet-credits {
    display: flex;
    flex-direction: column;
}

.all-about-wallet-credits {
    width: 50%;
    border-right: 1px solid var(--devide-color);
}

.active-credits-1 {
    height: 50%;
    width: 100%;
    margin-left: 10px;

    display: flex;
    flex-direction: row;

    @media screen and (max-width: 720px) {
        flex-direction: column;
    }

    border-bottom: 1px solid var(--devide-color);
}

.active-credits-2 {
    margin-left: 10px;
    height: 50%;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 720px) {
        flex-direction: column;
    }
}

.title-credit-wallet {
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px
}</style>