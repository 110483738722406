<template>
    <h1>Bottom Navbar </h1>
    <!-- <GrowBottomNavigation :options="options" v-model="selected" style="font-size: 17px;"/> -->
    <GrowBottomNavigation :options="options" v-model="selected"
        style="font-size: 14px; background-color: var(--bottom-navbar-color);bottom:0;">
        <template #icon="{ props }">
            <ion-icon :name="getIconName(props.id)" style="font-size: 16px;" :style="{color:getColor(props.id)}">{{ selected }}</ion-icon>
        </template>
        <template #title="{ props }"> <b>{{ props.title }}</b> </template>
    </GrowBottomNavigation>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css"
        integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA=="
        crossorigin="anonymous" referrerpolicy="no-referrer" />
</template>
<script>
import bottomNavigationVue from "bottom-navigation-vue";
import "bottom-navigation-vue/dist/style.css";
import { GrowBottomNavigation } from "bottom-navigation-vue";

export default {
    name: "bottom_navbar",
    data() {
        return {
            selected: 1,
            options: [
                {
                    id: 1,
                    icon: "home-outline",
                    title: "Home",
                    color: '#8a2be2',

                    path: { name: "home",  },
                },
                {
                    id: 2,
                    icon: 'wallet-outline',
                    title: 'Credits',
                    color: '#e2a93a',
                    path: { name: "reedm_credit",  },

                    // path: { name: "bookmarks", query: { bookmark: "important" } },
                },
                {
                    id: 3,
                    icon: 'chatbubble-ellipses-outline',
                    title: 'Notifications',
                    color: '#ac4793',
                    // path: { name: "bookmarks", query: { bookmark: "important" } },
                    path: { name: "my_notifications",  },

                },
                {
                    id: 4,
                    icon: 'person-outline',
                    title: 'Dashboard',
                    color: '#4493a7',
                    // path: { name: "bookmarks", query: { bookmark: "important" } },
                    path: { name: "my_dashboard",  },

                }
            ]
        }
    },

    methods: {
        getColor(id){
            if (id == 1) {
                return "#8a2be2";
            }
            if (id == 2) {
                return "#e2a93a";
            }
            if (id == 3) {
                return "#ac4793";
            }
            if (id == 4) {
                return "#4493a7";
            }
            return "none";
            
        },

        getIconName(id) {
            // Map each id to its corresponding icon name
            //   console.log(id)
            if (id == 1) {
                return "home-outline";
            }
            if (id == 2) {
                return "wallet-outline";
            }
            if (id == 3) {
                return "notifications-outline";
            }
            if (id == 4) {
                return "person-outline";
            }
            return "none";
        }
    },
    components: {
        bottomNavigationVue,
        GrowBottomNavigation
    }

}
</script>