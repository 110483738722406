<template>
  <a-modal
    v-model:open="open_profile_bg"
    title="Edit Profile Background"
    @ok="handleOk_profile_bg"
    style="width: 95%; max-width: 700px"
  >
    <p>Choose a new image to update your profile background.</p>
    <!-- Use a label as a custom button -->
    <label
      for="fileInput_bg"
      style="
        cursor: pointer;
        background-color: #f2f2f2;
        width: 100%;
        padding: 20px;
        border-radius: 10px;
        display: block;
      "
    >
      <a-row>
        <a-col :sm="3" :xs="3" :md="3" :lg="3" style="text-align: start">
          <ion-icon
            name="cloud-upload-outline"
            style="font-size: 30px"
          ></ion-icon>
        </a-col>
        <a-col :sm="20" :xs="20" :md="20" :lg="20">
          <span style="font-size: 16px"
            ><b style="font-size: larger">Click to Select image</b></span
          >
        </a-col>
      </a-row>
    </label>

    <!-- Hidden file input with opacity set to zero -->
    <input
      type="file"
      id="fileInput_bg"
      ref="fileInput_bg"
      accept="image/*"
      @change="handleFileChange_profile_bg"
      style="position: absolute; width: 0; height: 0; opacity: 0"
    />
    <br />
    <br />
    <div v-if="profile_bg_image_selected ? true : null">
      <updateBackground
        @profileUpdated="handleprofileUpdated"
        v-if="
          profile_bg_image_selected ? profile_bg_image_selected[0].url : null
        "
        v-bind:Src="
          profile_bg_image_selected ? profile_bg_image_selected[0].url : 'none'
        "
        v-bind:ratio="12 / 5"
        style="max-width: 100%; display: block"
      />
    </div>
    <template #footer></template>
  </a-modal>

  <a-modal
    v-model:open="open_profile_picture"
    title="Edit Profile Picture"
    @ok="handleOk_profile_picture"
    style="width: 95%; max-width: 700px"
  >
    <p>Select a new image to update your profile picture.</p>
    <!-- Use a label as a custom button -->
    <label
      for="fileInput_picture"
      style="
        cursor: pointer;
        background-color: #f2f2f2;
        width: 100%;
        padding: 20px;
        border-radius: 10px;
        display: block;
      "
    >
      <a-row>
        <a-col :sm="3" :xs="3" :md="3" :lg="3" style="text-align: start">
          <ion-icon
            name="cloud-upload-outline"
            style="font-size: 30px"
          ></ion-icon>
        </a-col>
        <a-col :sm="20" :xs="20" :md="20" :lg="20">
          <span style="font-size: 16px"
            ><b style="font-size: larger">Click to Select image</b></span
          >
        </a-col>
      </a-row>
    </label>

    <!-- Hidden file input with opacity set to zero -->
    <input
      type="file"
      id="fileInput_picture"
      ref="fileInput_picture"
      accept="image/*"
      @change="handleFileChange_profile_picture"
      style="position: absolute; width: 0; height: 0; opacity: 0"
    />
    <br />
    <br />
    <div v-if="profile_image_selected ? true : null">
      <updateProfilePicture
        @profileUpdated="handleprofileUpdated"
        v-if="profile_image_selected ? profile_image_selected[0].url : null"
        v-bind:Src="
          profile_image_selected ? profile_image_selected[0].url : 'none'
        "
        v-bind:ratio="1"
        style="max-width: 100%; display: block"
      />
    </div>
    <template #footer></template>
  </a-modal>
  <a-modal
    v-model:open="open_edit_profile_Details_Modal"
    @ok="handleOk_profile_picture"
    style="width: 95%; max-width: 700px"
  >
    <h1 style="font-size: 22px; text-align: center" class="mp-0">
      Edit User Information
    </h1>
    <h2 class="mp-0" style="text-align: center">
      Updating user details will receive a privacy audit.
    </h2>
    <br />
    <a-row>
      <a-col :sm="24" :xs="24" :md="12" :lg="12" style="padding: 10px">
        <h1 class="mp-0">First Name :</h1>
        <a-input
          required
          class="input"
          name="first_name"
          type="text"
          v-model:value="first_name"
        />
      </a-col>
      <a-col :sm="24" :xs="24" :md="12" :lg="12" style="padding: 10px">
        <h1 class="mp-0">Last Name :</h1>
        <a-input
          required
          class="input"
          type="text"
          name="last_name"
          v-model:value="last_name"
        />
      </a-col>
      <a-col :sm="24" :xs="24" :md="12" :lg="12" style="padding: 10px">
        <h1 class="mp-0">Phone Number :</h1>
        <a-input
          required
          class="input"
          type="text"
          inputmode="numeric"
          pattern="[0-9]+"
          maxlength="10"
          v-model:value="phone_number"
          name="phone_number"
        />
      </a-col>
      <a-col :sm="24" :xs="24" :md="12" :lg="12" style="padding: 10px">
        <h1 class="mp-0">DOB :</h1>
        <a-input required class="input" name="dob" type="date" v-model:value="dob" />
      </a-col>
      <a-col :sm="24" :xs="24" :md="24" :lg="24" style="padding: 10px">
        <h1 class="mp-0">Description :</h1>

        <a-textarea
          required
          name="description"
          class="textarea-description"
          rows="4"
          v-model:value="description"
        ></a-textarea>
      </a-col>
      <a-col :sm="24" :xs="24" :md="12" :lg="12" style="padding: 10px">
        <h1 class="mp-0">Language :</h1>
        <select
          class="input"
          value="English"
          style="border: 1px solid rgba(0, 0, 0, 0.7); height: 50px"
        >
          <option value="English" selected>English</option>
        </select>
      </a-col>
      <a-col :sm="24" :xs="24" :md="12" :lg="12" style="padding: 10px">
        <h1 class="mp-0">City :</h1>
        <a-input
          required
          class="input"
          type="text"
          name="city"
          v-model:value="city"
        />
      </a-col>
      <a-col :sm="24" :xs="24" :md="12" :lg="12" style="padding: 10px">
        <h1 class="mp-0">State :</h1>
        <a-input
          required
          class="input"
          type="text"
          name="state"
          v-model:value="state"
        />
      </a-col>
      <a-col :sm="24" :xs="24" :md="12" :lg="12" style="padding: 10px">
        <h1 class="mp-0">Country :</h1>
        <a-input
          class="input"
          type="text"
          name="country"
          v-model:value="country"
          required
        />
      </a-col>
    </a-row>
    <template #footer>
      <a-row>
        <a-col :span="12" style="padding: 10px">
          <a-button
            type="primary"
            @click="editProfile()"
            :loading="iconLoading"
            block
            style="
              height: 50px;
              border-radius: 5px;
              max-width: 400px;
              width: 100%;
              font-size: 18px;
            "
          >
            Submit
          </a-button>
        </a-col>
        <a-col :span="12" style="padding: 10px">
          <a-button
            style="
              height: 50px;
              border-radius: 5px;
              max-width: 400px;
              width: 100%;
              font-size: 18px;"
              @click="handleCancel_edit_profile()"
              >
            Cancel
          </a-button>
        </a-col>
      </a-row>
    </template>
  </a-modal>
  <section style="background-color: var(--card-color);padding:10px;border-radius:20px;margin:5px;margin-top:10px">
    <div >
    <div class="profile_pict avatar-bg-upload">
                      <!-- Avatar Background Upload with Preview -->
                      <div class="avatar-bg-edit">
                        <a-button
                          style="
                            border-radius: 100px;
                            height: 30px;
                            width: 30px;
                            top: 5px;
                            right: 5px;
                            position: absolute;
                          "
                          type="primary"
                          @click="showModal_profile_bg()"
                        >
                          <template #icon>
                            <ion-icon name="pencil-outline"></ion-icon>
                          </template>
                        </a-button>
                      </div>
                      <div class="avatar-bg-preview">
                        <img
                          id="imagePreview_bg"
                          style="width: 100%"
                          :src="
                            imagePreview_bg
                              ? imagePreview_bg
                              : this.$store.state.userDetils.length
                              ? this.$store.state.userDetils[0]
                                  .profile_background
                              : ''
                          "
                          alt="Background Preview"
                        />
                      </div>
                    </div>
                    <div class="profile_pict avatar-upload">
                      <!-- Avatar Upload with Preview -->
                      <div class="avatar-edit">
                        <a-button
                          style="
                            border-radius: 100px;
                            height: 30px;
                            width: 30px;
                            top: 3px;
                            right: 70px;
                            position: absolute;
                          "
                          type="primary"
                          @click="showModal_profile_picture()"
                        >
                          <template #icon>
                            <ion-icon name="pencil-outline"></ion-icon>
                          </template>
                        </a-button>
                        <!-- <input
                      type="file"
                      name="profile_picture_image"
                      id="imageUpload"
                      accept=".png, .jpg, .jpeg"
                      @change="readURL($event, 'imagePreview')"
                    />
                    <label for="imageUpload"
                      ><ion-icon
                        name="pencil"
                        style="margin-top: 5px; font-size: 25px"
                      ></ion-icon
                    ></label> -->
                      </div>
                      <div class="avatar-preview">
                        <img
                          id="imagePreview"
                          style="width: 100%"
                          :src="
                            imagePreview
                              ? imagePreview
                              : this.$store.state.userDetils.length
                              ? this.$store.state.userDetils[0].profile_picture
                              : ''
                          "
                          alt="Avatar Preview"
                        />
                      </div>
                    </div>
    <div style="padding:10px">

                 <h1>Details</h1>
                        <hr>
                        
<h2> <b > Username:  </b><span style="font-weight:400"> {{
                              this.$store.state.userDetils.length
                                ? this.$store.state.userDetils[0].user.username
                                : ""
                            }} </span>   </h2>
<h2> <b > Email:  </b> <span style="font-weight:400"> {{
                                this.$store.state.userDetils.length
                                  ? this.$store.state.userDetils[0].user.email
                                  : ""
                              }} </span>  </h2>
                              <h2><b> Name: </b> <span style="font-weight:400">{{first_name}} {{last_name}} </span></h2>
<h2><b > Phone Number:    </b> <span style="font-weight:400"> {{phone_number}}</span></h2>
<h2><b > Date Of Birth:    </b><span style="font-weight:400">{{dob}} </span>  </h2>
<h2><b > Description:    </b> <span style="font-weight:400"> {{description ? description : "Unknown" }}</span>  </h2>
<h2><b > City:    </b> <span style="font-weight:400"> {{city}} </span> </h2>
<h2><b > State:    </b> <span style="font-weight:400">{{state}}  </span> </h2>
<h2><b > Country:    </b> <span style="font-weight:400">{{country}}</span>   </h2>

<a-row>
    <a-col :sm="24" :xs="24" :md="24" :lg="24" style="padding:10px">
        <a-button
                block
                type="primary"
                @click="showModal_edit_profile_Details_Modal()"
                style="font-size: 16px; height: 40px"
              >
               <a-space>
                 <ion-icon name="file-tray-full-outline" style="font-size: 18px;margin-top:5px"></ion-icon>&nbsp;
                 <span style="font-size: 16px;">Edit</span>

               </a-space>
              </a-button>
</a-col>

<!-- <a-col :sm="24" :xs="24" :md="12" :lg="12" style="padding:10px">
    <a-button
                block
                @click="showModal_edit_profile_Details_Modal()"
                style="font-size: 16px; height: 40px"
              >
                <template #icon>
                  <ion-icon name="refresh-outline"></ion-icon>&nbsp;
                </template>
                Reset
              </a-button>
</a-col> -->
</a-row>

</div>

</div>
  </section>
</template>
<script>
import axios from "axios";
import main_left_pane_menu_user from "@/components/cards/main_left_pane_menu_user.vue";
// import main_left_pane_menu_user from "@/components/cards/main_left_pane_menu_user.vue";
import { notification } from "ant-design-vue";
// import userProfileDashboard from '@/components/user/updateProfile/updateProfileDashboard.vue'

import updateProfilePicture from "@/components/user/updateProfile/updateProfilePicture.vue";
import updateBackground from "@/components/user/updateProfile/updateBackground.vue";
export default {
  name:"editProfileCard",
  data() {
    return {

      // sectionalised SOlutions:
      // modal Profile Picture & background
      open_profile_bg: false,
      open_profile_picture: false,
      open_edit_profile_Details_Modal: false,
      profile_image_selected: null,
      profile_bg_image_selected: null,

      open_ContactInfo: false,
      loading_Profilewebsites: false,

      url_main_media: this.$store.state.ROOT_BASE_URL,
      // main modifications
      open_EditProfile: false,
      loading_editProfile: false,

      // data
      imagePreview_bg: null,
      imagePreview: null,
      first_name: null,
      last_name: null,
      phone_number: null,
      dob: null,
      description: null,
      city: null,
      state: null,
      country: null,
    };
  },
  components: {
    main_left_pane_menu_user,
    // userProfileDashboard

    updateProfilePicture,
    // flashingBadge,
    updateBackground,
  },
  mounted() {
    this.fetchUserData()
  },

  methods: {
    async fetchUserData() {
      this.$store.commit("setIsLoading", true);
      axios
        .post("user-details/")
        .then((response) => {
          // console.log(response);
          // console.log(response.data);

          const discription = response.data["0"]["discription"];
          const city = response.data["0"]["city"];
          const state = response.data["0"]["state"];
          const country = response.data["0"]["country"];
          const profile_picture = response.data["0"]["profile_picture"];
          const profile_background = response.data["0"]["profile_background"];
          const user = response.data["0"]["user"];
          const userData = {
            discription: discription,
            city: city,
            state: state,
            country: country,
            profile_picture: profile_picture,
            profile_background: profile_background,
            user: user,
          };

          // console.log("------------------------------------userData------------------------------------", userData);
          this.$store.commit("addUserDetails", userData);
          this.getStoredData();

          // console.log("--------------------------------------Done---------------------------------");
          // console.log("the stored data : ",this.$store.state.userDetils)
          // console.log("the stored User data : ",this.$store.state.userDetils['0']['user'])
          // console.log("the stored User data : ",this.$store.state.userDetils[0].user.first_name);
        })
        .catch((error) => {
          // console.log("===========error at user details")
          console.log(JSON.stringify(error.response.data));
          console.log(JSON.stringify(error));
        });
      // this.$store.commit('setIsLoading',false)

      this.$store.commit("setIsLoading", false);
    },
    handleprofileUpdated() {
      this.open_profile_bg = false;
      this.open_profile_picture = false;
      this.$emit("profileUpdated", true);
    },
    showModal_profile_bg() {
      this.open_profile_bg = true;
    },
    handleFileChange_profile_bg(event) {
      if (this.profile_bg_image_selected) {
        this.profile_bg_image_selected = null;
      }
      console.log(event);
      const file = event.target.files[0];
      if (file) {
        // Create a URL for the selected file
        const url = URL.createObjectURL(file);
        this.profile_bg_image_selected = [
          {
            uid: file.uid,
            name: file.name,
            status: "done",
            url: url,
            originFileObj: file,
          },
        ];
      } else {
        this.profile_bg_image_selected = null;
      }
    },
    handleOk_profile_bg() {
      //   console.log(e)
      this.open_profile_bg = false;
    },
    handleCancel_edit_profile() {
      //   console.log(e)
      this.open_edit_profile_Details_Modal = false;
    },
    showModal_profile_picture() {
      this.open_profile_picture = true;
    },

    handleFileChange_profile_picture(event) {
      if (this.profile_image_selected) {
        this.profile_image_selected = null;
      }
      console.log(event);
      const file = event.target.files[0];
      if (file) {
        // Create a URL for the selected file
        const url = URL.createObjectURL(file);
        this.profile_image_selected = [
          {
            uid: file.uid,
            name: file.name,
            status: "done",
            url: url,
            originFileObj: file,
          },
        ];
      } else {
        this.profile_image_selected = null;
      }
    },
    handleOk_profile_picture() {
      //   console.log(e)
      this.open_profile_picture = false;
    },
    getStoredData() {
      this.first_name = this.$store.state.userDetils.length
        ? this.$store.state.userDetils[0].user.first_name
        : "";
      this.last_name = this.$store.state.userDetils.length
        ? this.$store.state.userDetils[0].user.last_name
        : "";
      this.phone_number = this.$store.state.userDetils.length
        ? this.$store.state.userDetils[0].user.phone_number
        : "";
      this.dob = this.$store.state.userDetils.length
        ? this.$store.state.userDetils[0].user.dob
        : "";
      this.description = this.$store.state.userDetils.length
        ? this.$store.state.userDetils[0].discription != null
          ? this.$store.state.userDetils[0].discription
          : "Unavailable"
        : "No Description";
      this.city = this.$store.state.userDetils.length
        ? this.$store.state.userDetils[0].city !== null
          ? this.$store.state.userDetils[0].city
          : "Unknown"
        : "Unavailable";
      this.state = this.$store.state.userDetils.length
        ? this.$store.state.userDetils[0].state !== null
          ? this.$store.state.userDetils[0].state
          : "Unknown"
        : "Unavailable";
      this.country = this.$store.state.userDetils.length
        ? this.$store.state.userDetils[0].country !== null
          ? this.$store.state.userDetils[0].country
          : "Unknown"
        : "Unavailable";
    },

    showModal_edit_profile_Details_Modal() {
      this.open_edit_profile_Details_Modal = true;
    },

    // handle here the files uploading for profile picture and profile image
    readURL(event, previewId) {
      const input = event.target;
      if (input.files && input.files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this[previewId] = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    async editProfile() {
      //   if (this.imagePreview_bg) {
      //     return notification["info"]({
      //       message: "Profile background image is not selected !",
      //       description: `Your New Profile Modifications Have Not Been Saved, Please fill all the empty fields ...`,
      //       placement: "bottomRight",
      //     });
      //   }
      //   if (this.imagePreview) {
      //     return notification["info"]({
      //       message: "Profile image is not selected !",
      //       description: `Your New Profile Modifications Have Not Been Saved, Please fill all the empty fields ...`,
      //       placement: "bottomRight",
      //     });
      //   }
      if (
        this.first_name === null ||
        this.first_name === "Unavailable" ||
        this.first_name === "Unknown"
      ) {
        return notification["info"]({
          message: "Please enter your first name !",
          description: `Your New Profile Modifications Have Not Been Saved, Please fill all the empty fields ...`,
          placement: "bottomRight",
        });
      }
      if (
        this.last_name === null ||
        this.last_name === "Unavailable" ||
        this.last_name === "Unknown"
      ) {
        return notification["info"]({
          message: "Please enter your last name !",
          description: `Your New Profile Modifications Have Not Been Saved, Please fill all the empty fields ...`,
          placement: "bottomRight",
        });
      }
      if (
        this.phone_number === null ||
        this.phone_number === "Unavailable" ||
        this.phone_number === "Unknown"
      ) {
        return notification["info"]({
          message: "Please enter your phone number !",
          description: `Your New Profile Modifications Have Not Been Saved, Please fill all the empty fields ...`,
          placement: "bottomRight",
        });
      }
      if (
        this.dob === null ||
        this.dob === "Unavailable" ||
        this.dob === "Unknown"
      ) {
        return notification["info"]({
          message: "Please enter your Date Of Birth !",
          description: `Your New Profile Modifications Have Not Been Saved, Please fill all the empty fields ...`,
          placement: "bottomRight",
        });
      }
      if (
        this.description === null ||
        this.description === "Unavailable" ||
        this.description === "Unknown"
      ) {
        return notification["info"]({
          message: "Please enter your profile description !",
          description: `Your New Profile Modifications Have Not Been Saved, Please fill all the empty fields ...`,
          placement: "bottomRight",
        });
      }
      if (
        this.city === null ||
        this.city === "Unavailable" ||
        this.city === "Unknown"
      ) {
        return notification["info"]({
          message: "Please enter your city name !",
          description: `Your New Profile Modifications Have Not Been Saved, Please fill all the empty fields ...`,
          placement: "bottomRight",
        });
      }
      if (
        this.state === null ||
        this.state === "Unavailable" ||
        this.state === "Unknown"
      ) {
        return notification["info"]({
          message: "Please enter your state name !",
          description: `Your New Profile Modifications Have Not Been Saved, Please fill all the empty fields ...`,
          placement: "bottomRight",
        });
      }
      if (
        this.country === null ||
        this.country === "Unavailable" ||
        this.country === "Unknown"
      ) {
        return notification["info"]({
          message: "Please enter your country name !",
          description: `Your New Profile Modifications Have Not Been Saved, Please fill all the empty fields ...`,
          placement: "bottomRight",
        });
      }
      const formData = {
        // imagePreview_bg: this.imagePreview_bg,
        // imagePreview: this.imagePreview,
        first_name: this.first_name,
        last_name: this.last_name,
        phone_number: this.phone_number,
        dob: this.dob,
        description: this.description,
        city: this.city,
        state: this.state,
        country: this.country,
      };

      this.$store.commit("setIsLoading", true);
      await axios
        .post("edit-my-profile/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response);
          // console.log(response.data);
          notification["success"]({
            message: "Profile Saved !",
            description: `Your New Profile Modifications Have Been Saved ...`,
            placement: "bottomRight",
          });
        })
        .catch((error) => {
          // console.log("===========error at user details")
          console.log(JSON.stringify(error.response.data));
          console.log(JSON.stringify(error));
          notification["info"]({
            message: "Profile Not Saved !",
            description: `Your New Profile Modifications Have Not Been Saved, Please fill all the empty fields ...`,
            placement: "bottomRight",
          });
        });
      this.$store.commit("setIsLoading", false);
    },
  },
}
</script>

<style scoped>
.mp-0 {
  margin: 0;
  padding: 0;
  text-align: start;
  font-size: 14px;
  /* color:#2e263dbe */
}
main {
  display: flex;
  justify-content: center;
  padding-top: 50px;

  /* background-color: #fff9f9;
        background-color: hsla(0, 100%, 88%, 1);
        backdrop-filter: blur(75px);

        background-image:
            radial-gradient(at 80% 0%, hsla(189, 100%, 56%) 0px, transparent 50%),
            radial-gradient(at 0% 50%, hsla(355, 100%, 93%) 0px, transparent 50%),
            radial-gradient(at 80% 50%, hsla(340, 100%, 76%) 0px, transparent 50%),
            radial-gradient(at 0% 100%, hsla(269, 100%, 77%) 0px, transparent 50%),
            radial-gradient(at 0% 0%, hsla(343, 100%, 76%) 0px, transparent 50%); */
}

.my-profile-card {
  background-color: var(--card-color);
  backdrop-filter: blur(75px);

  max-width: 90%;
  width: 90%;
  /* height: 600px; */
  border-radius: 20px;
  padding: 30px;
}

.flx-resp {
  display: flex;
  gap: 20px;
}

.flx-column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.flx-row {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
}

/* profile-user cardend */

.profile_pict,
.profile-details {
  width: 50%;
}
.profile_pict {
  text-align: center;
}
.image-userprofile {
  width: 200px;
  border-radius: 100%;
  margin-top: -80px;
}
.image-userprofile-background {
  width: 100%;
  border-radius: 10px;
}
/* profile-user cardend */

h1 {
  font-size: 16px;
}
/* h2{
  color:#2e263dbe
} */
.input {
  width: 100%;

  /* border: 1px solid grey; */
  border-radius: 5px;
  padding: 10px;
  outline: none;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.583);
  transition: all 0.5s;
}
/* .input:hover {
  width: 99%;
  height: -90%;

  margin-top: 1%;
  margin-left: 1%;
} */
.textarea-description {
  width: 100%;
  height: 200px;
  /* border: 1px solid; */
  /* border-radius: 10px; */
  padding: 20px;
  /* outline: none; */
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.583);
  transition: all 0.5s;
}
.textarea-description:hover {
  width: 99%;
  margin-top: 1%;
  margin-left: 1%;
  height: 180px;
  /* border: none; */
  /* border-radius: 10px; */
  background-color: rgba(255, 255, 255, 0.583);
}
.data-user {
  outline: none;
  border: none;
  background-color: rgba(240, 255, 255, 0.74);
  padding: 10px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.5s;
}
.data-user:hover {
  /* margin:5px; */
  padding: 8px;
}
/* image */

.avatar-bg-upload {
  position: relative;
  width: 100%;
  border-radius: 10px;
}

.avatar-bg-upload .avatar-bg-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
}

.avatar-bg-upload .avatar-bg-edit input {
  display: none;
}

.avatar-bg-upload .avatar-bg-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.avatar-bg-upload .avatar-bg-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.avatar-bg-upload .avatar-bg-edit input + label:after {
  content: "";
  /* font-family: "FontAwesome"; */
  color: #757575;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.avatar-bg-upload .avatar-bg-preview {
  width: 100%;
  /* height: 200px; */
  position: relative;

  /* border-radius: 100%; */
  /* border: 6px solid #f8f8f8; */
  /* box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); */
}

.avatar-bg-upload .avatar-bg-preview > img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  /* object-fit: cover; */
}

/* profile picture */
.avatar-upload {
  position: relative;
  max-width: 178px;
  /* margin:-50px  10px; */
  margin-top: -70px;
  margin-bottom: 50px;
  margin-left: 10px;
  /* margin-right: auto; */
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.avatar-upload .avatar-edit input + label:after {
  content: "";
  /* font-family: "FontAwesome"; */
  color: #757575;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.avatar-upload .avatar-preview {
  width: 80px;
  height: 80px;
  object-fit: cover;
  position: relative;
  border-radius: 100%;
  /* border: 6px solid #f8f8f8; */
}

.avatar-upload .avatar-preview > img {
  width: 80px;
  height: 80px;
  /* object-fit: cover; */

  border-radius: 100%;
  object-fit: cover;
}
</style>
