<template >
  <div class="profile-card" style="margin:auto">
    <div class="">


      <img class="image-userprofile-background"
        :src="this.$store.state.userDetils.length ? this.$store.state.userDetils[0].profile_background : '' " alt="">
        <div style="display: flex;justify-content: center;">
          <img class="image-userprofile" :src="this.$store.state.userDetils.length ? this.$store.state.userDetils[0].profile_picture : '' "
          alt="user profile picture">
        </div>
      

    </div>




      <div class="card-content" v-if="userDetailsAvailable">
      <p><b><ion-icon name="newspaper-outline"></ion-icon>&nbsp;</b>{{ userFullName }}</p>
      <p><b><ion-icon name="snow-outline"></ion-icon>&nbsp;</b> {{ userDob ? userDob  : "Not Available" }}</p>
      <p style="width:90%;overflow:hidden;text-overflow: ellipsis;display:flex;gap:10px"><b><ion-icon
          name="mail-outline"></ion-icon></b>{{ userEmail }}</p>
      <p><b><ion-icon name="call-outline"></ion-icon>&nbsp; </b> {{ userPhone ? userPhone  : "Not Available" }}</p>
   

    </div>
    <div style="text-align:end;">

     <router-link to="edit-profile">
      <a-button class="" style="
  background-color: rgba(240, 255, 255, 0.74);
  padding: 10px;
  width: 100%;
  height:40px;
  font-size:16px;
  font-weight: bold;
  /* color: rgb(80, 80, 80); */
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.5s;"> 
  
  <a-row>
    <a-col :sm="4" :xs="4" :md="4" :lg="4" ><ion-icon style="font-size:20px" name="create" role="img"
          class="md hydrated"></ion-icon></a-col>
    <a-col :sm="20" :xs="20" :md="20" :lg="20" >My Profile</a-col>
  </a-row>
  </a-button>
</router-link>

  </div>
  <div v-if="this.$store.state.userDetils.length">
    <router-link to="/admin-crekaa" v-if="this.$store.state.userDetils[0].user.is_admin" >
      <br>
      <a-button type="primary" block size="large"> Admin Dashboard</a-button>
    </router-link>
  </div>

  </div>
  <br><br>
</template>
<script>
export default {
  name: "user_card_my_dashboard",
  data() {
    return {
      userDetailsAvailable: false,
      userFullName: '',
      userDob: '',
      userEmail: '',
      userPhone:'Not Available',
    };
  },
  computed: {
    user() {
      return this.$store.state.userDetils[0]?.user;
    },
  },
  watch: {
    user: {
      handler(newValue) {
        if (newValue) {
          this.userFullName = `${newValue.first_name} ${newValue.last_name}`;
          this.userDob = newValue.dob;
          this.userEmail = newValue.email;
          this.userPhone = newValue.phone_number;
          this.userDetailsAvailable = true;
        }
      },
      immediate: true, // Trigger the handler immediately
    },
  },
};
</script>
<style scoped>
/* bg colors and others  */

.image-userprofile-background {
  width: 100%;
    height: 120px;
    object-fit: cover   ;
    /* position: relative; */
  border-radius: 10px;
}

.image-userprofile {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 100%;
  margin-top: -80px;
}

.data-user {
  background-color: rgba(240, 255, 255, 0.74);
  padding: 10px;
  width: 100%;
  color: rgb(80, 80, 80);
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.5s;
}

.data-user:hover {
  padding: 8px;
}

.profile-card {
  padding: 20px;
  margin: 20px;
  /* border: 1px solid black; */
  /* background: cyan; */
  background: #009688;
  box-shadow: #009688 0px 5px 15px 0px;
  text-align: start;
  width: 90%;
  /* max-width: 300px; */
  color: white;
  border-radius: 12px;
}

.profile-card .profile-picture img {
  padding: 20px;
  justify-content: center;
  /* border: 1px solid black; */
  /* background: cyan; */

  width: 90%;
  border-radius: 200px;
}

.profile-card .card-content p {
  font-size: 16px
}

.profile-card .card-content p {
  font-size: 16px
}

.head-one {
  display: flex;
  gap: 10px
}

/* the side of main card  */
.managed-contents {
  background-color: red;
  width: 75%;
  border-radius: 20px;
  margin: 20px;
  max-height: 440px;
}
</style>