<template >
    
    <br>
    <div class="body">

        <br>
    <br>
    <br>
     <section class="my-profile-card" style="margin:auto;text-align:start">
        
        <!-- <div class=""> -->
            <div class="flx-resp">
                <div class="profile_pict">
                    <br>
                    <img class="image-userprofile-background" style="width:100%;height: 200px;object-fit: cover;" :src="data ? data.profile_background : null" alt="">
                    <img class="image-userprofile" :src="data ? data.profile_picture : null"
                        alt="user profile picture">


                </div>
                <div class="flx-resp profile-details">
                    <div class="flx-column">
<br>


                        <div class="flx-row  " style="margin-bottom:30px">
                            <div class="">

                                <h1> Username : <span class="data-user">{{  data ? data.user.username  : "unknown"  }}</span></h1>
                            </div>
                            <div class="">
                                <h1> Email : <span class="data-user">{{  data ? data.user.email : "unknown" }}</span></h1>
                            </div>

                        </div>
                        
                        <div class="flx-row " style="margin-bottom:30px">
                            <div class="">
                                <h1> First Name : <span class="data-user">{{  data ? data.user.first_name : "unknown" }} </span></h1>
                            </div>
                            <div class="">
                                <h1> Last Name : <span class="data-user">{{  data ? data.user.last_name : "unknown" }}</span></h1>
                            </div>

                        </div>
                        
                        <div class="flx-row " style="margin-bottom:30px">
                            <div class="">
                                <h1> Phone Number : <span class="data-user"> {{  data ? data.user.phone_number : "unknown" }}</span></h1>
                            </div>
                            <div class="">
                                <h1> DOB : <span class="data-user">{{  data ? data.user.dob : "unknown" }} </span></h1>
                            </div>

                        </div>
                        

                        <div class="flx-row ">
                            <div class="flx-column">

                                <h1> Description :</h1>
                                <textarea class="textarea-description" disabled>{{  data ? data.discription ? data.discription : "Unknown" : "unknown" }} </textarea>
                            </div>


                        </div>
                        <div class="flx-row ">
                            <div class="">
                                <h1> City : <span class="data-user"> {{  data ? data.city ? data.city : "Unknown" : "unknown" }} </span></h1>

                            </div>
                            <div class="">
                                <h1> State : <span class="data-user">{{  data ? data.state ? data.state : "Unknown" : "unknown" }} </span></h1>
                            </div>

                            <div class="">
                                <h1> Country : <span class="data-user">{{  data ? data.country ? data.country : "Unknown" : "unknown" }} </span></h1>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        <!-- </div> -->
        <router-link to="/edit-profile">

        <a-button block style="font-size:14px;height:40px;float: right;"  > <ion-icon name="create" style="font-size:18px;"></ion-icon>&nbsp;Edit Profile</a-button>
        </router-link>
        <br>
<br>
    </section>
    
<br>
<br>
</div>
</template>
<script>
import axios from 'axios';

export default{
    name:"my_profile",
    data(){return {
        data:null
    }},
    created(){
    document.title = "My Profile - Crekaa";
  },
  head() {
    return {
      title: "Crekaa",
      meta: [
        { hid: 'description', name: 'description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'author', name: 'author', content: "Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        
        // <!-- Facebook Open Graph tags -->
        { hid: 'og:url', property: 'og:url', content: "https://crekaa.com/" },
        { hid: 'og:type', property: 'og:type', content: "website" },
        { hid: 'og:title', property: 'og:title', content: "Crekaa, Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'og:description', property: 'og:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'og:image', property: 'og:image', content: "../../assets/logo.png" },
        // Add more meta tags as needed

        // <!-- Twitter Card tags -->
        { hid: 'twitter:card', name: 'twitter:card', content: "summary_large_image" },
        { hid: 'twitter:title', name: 'twitter:title', content: "Crekaa , Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'twitter:description', name: 'twitter:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'twitter:image', name: 'twitter:image', content: "../../assets/logo.png" },

      ]
    };
  },
    mounted(){
        this.userDetails()
    },
    methods: {
        async userDetails(){
            this.$store.commit('setIsLoading',true)
            await axios
                    .post("user-details/")
                    .then(response => {
                        // console.log(response);
                        // console.log(response.data);

                        const discription = response.data['0']['discription'];
                        const city = response.data['0']['city'];
                        const state = response.data['0']['state'];
                        const country = response.data['0']['country'];
                        const profile_picture = response.data['0']['profile_picture'];
                        const profile_background = response.data['0']['profile_background'];
                        const user = response.data['0']['user'];
                        this.data = {
                            discription:discription,
                            city:city,
                            state:state,
                            country:country,
                            profile_picture:profile_picture,
                            profile_background:profile_background,
                            user:user,
                        };

                        // this.data=userData
                        // console.log("------------------------------------userData------------------------------------", this.data);
                        // console.log(userData)
                        // this.$store.commit('addUserDetails', userData);
                        // console.log("--------------------------------------Done---------------------------------");
                        // console.log("the stored User data : ",this.$store.state.userDetils['0']['user'])
                        // console.log("the stored User data : ",this.$store.state.userDetils[0].user.first_name);
                        
                    })
                    .catch(error => {
                            // console.log("===========error at user details")                        
                            console.log(JSON.stringify(error.response.data))
                                console.log(JSON.stringify(error))
                    })
              this.$store.commit('setIsLoading',false)
        },
        logout() {
            axios.defaults.headers.common["Authorization"] = ""

            localStorage.removeItem("token")
            localStorage.removeItem("username")
            localStorage.removeItem("userid")

            this.$store.commit('removeToken')

            this.$router.push('/')
            this.$store.commit('removeUserDetails')
            // console.log("removal of userDetails")
            // console.log(this.$store.state.userDetils)
        },
    }

}</script>
<style scoped>


    .body {

        /* background-color: #fff9f9;
        background-color: hsla(0, 100%, 88%, 1); */
        backdrop-filter: blur(75px);
min-height:100vh;
        background-image: 
             radial-gradient(at 80% 0%, hsla(189, 100%, 56%, 0.499) 0px, transparent 50%),
            radial-gradient(at 0% 50%, hsla(62, 100%, 50%, 0.377) 0px, transparent 50%),
            radial-gradient(at 80% 50%, hsla(284, 100%, 23%, 0.8) 0px, transparent 50%),
            radial-gradient(at 0% 100%, hsla(269, 100%, 63%, 0.3) 0px, transparent 50%),
            radial-gradient(at 0% 0%, hsla(343, 100%, 76%,0.9) 0px, transparent 50%),
            radial-gradient(at 100% 100%, hsla(113, 100%, 76%, 0.4) 0px, transparent 50%);
            /* background-image:
   radial-gradient(45% 45% at 8% 50%, rgba(255, 0, 111, 0.277) 1%, #FF000000 99%),
   radial-gradient(33% 33% at -1% 43%, var(--wp--preset--color--gradient-two) 1%, #FF000000 99%),
    radial-gradient(15% 15% at 71% 43%, var(--wp--preset--color--gradient-three) 1%, #FF000000 99%),
     radial-gradient(20% 20% at 82% 77%, var(--wp--preset--color--gradient-four) 1%, #FF000000 99%),
      radial-gradient(40% 40% at 89% 61%, var(--wp--preset--color--gradient-five) 1%, #FF000000 99%);  */
    }

    main {
        display: flex;
        justify-content: center;
        padding-top: 50px;
    }

    .my-profile-card {
        background-color: var(--bg-my-profile);
        backdrop-filter: blur(75px);

        max-width: 90%;
        width: 90%;
        /* height: 600px; */
        border-radius: 20px;
        padding: 30px;
    }

    .flx-resp {
        display: flex;
        gap: 20px
    }

    .flx-column {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .flx-row {
        width: 100%;
        display: flex;
        margin-bottom:20px;
        flex-direction: row;
        justify-content: space-between;
    }

    
/* profile-user cardend */

    .profile_pict,
    .profile-details {
        width: 50%;
    }
    .profile_pict{
        text-align: center;
    }
    .image-userprofile {
        width: 150px;
        height: 150px;
        object-fit: cover;
        border-radius: 100%;
        margin-top: -80px;
    }
    .image-userprofile-background{
        width: 100%;
        border-radius: 10px;
       
    }
/* profile-user cardend */

    h1 {
        font-size: 14px
    }

    .textarea-description {
        width: 100%;
        height: 200px;
        border: none;
        border-radius: 10px;
        padding:20px;
        outline: none;
        font-size:16px;
        background-color: var(--bg-my-profile-label);
        transition: all 0.5s;
    }
    .textarea-description:hover {
        width: 99%;
        margin-top:1%;
        margin-left:1%;
        height: 180px;
        border: none;
        border-radius: 10px;
                background-color: var(--bg-my-profile-label);

    }
.data-user{
            background-color: var(--bg-my-profile-label);

    padding:10px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    border-radius:10px;
    transition: all 0.5s;
}
.data-user:hover{
/* margin:5px; */
padding:8px;
}
@media screen and (max-width: 450px) {
    h1 {
        font-size:12px;
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    }
    @media screen and (max-width: 720px) {
        .flx-row {
        flex-direction: column;
    }.flx-row h1{
        margin-bottom: 20px;
    }
        main {
        padding-top: 100px;
        padding-bottom: 100px;
    }
        .profile_pict,
        .profile-details {
            width: 100%
        }
        .image-userprofile {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        margin-top: -50px;  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;


    }
        .flx-resp {
            display: block;
            flex-direction: column;
        }
    }

    
</style>