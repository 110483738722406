<template>
  <div class="main-home">
    <!-- The dark Mode Switch based on cache  -->
    <!-- this switch will have caching capabilities of the entire app with method switchMode is thtogglin the dark mode and light mode and app.js will have caclhe checking fot the above methods  -->
    <!-- <div class="light ">
      <input class="checkbox" type="checkbox" id="toggle" @change="switchMode()">
      <label class="toggle" for="toggle">
        <ion-icon class="icon icon--light md hydrated" name="sunny-outline" role="img"></ion-icon>
        <ion-icon class="icon icon--dark md hydrated" name="moon-outline" role="img"></ion-icon>
        <span class="ball"></span>
      </label>
    </div> -->
    <!-- end dark mode switch -->

    <!-- page- home content -->
    <div
      class="has-primary-gradient-background"
      style="
        padding-top: var(--wp--preset--spacing--xxl);
        padding-bottom: var(--wp--preset--spacing--md);
      "
    >
      <div style="height: 3em" aria-hidden="true" class=""></div>
      <a-row>
        <a-col :sm="24" :xs="24" :md="0" :lg="0">
          <div style="display: flex; justify-content: end">
            <img
              style="width: 90%; padding: 10px; margin-top: 30px"
              src="../assets/svgs/ipad.svg"
              draggable="false"
              alt="iPad"
            />
          </div>
        </a-col>

        <a-col
          :sm="24"
          :xs="24"
          :md="12"
          :lg="12"
          style="font-family: 'Alkatra'"
        >
          <div style="max-width: 500px; margin: auto; padding: 5px">
            <a-col :sm="0" :xs="0" :md="24" :lg="24">
              <div style="margin-top: 130px"></div>
            </a-col>
            <h1
              class=""
              style="
                margin-right: 0;
                margin-bottom: 0;
                margin-left: 0;
                font-size: 50px;
                color: var(--text-color);
              "
            >
              Next generation tools for your <br />
              <span class="type"></span>
            </h1>
            <div
              class="wp-container-10"
              style="font-size: 12px; margin-top: 20px"
            >
              <p class="has-text-align-center">
                Crekaa is a pioneering development platform that offers
                comprehensive support for businesses and startups seeking
                cost-effective software solutions. We're revolutionizing the way
                businesses operate by helping them create innovative software
                solutions to address their customers' needs.
              </p>
              <br /><br />
              <br /><br />
              <a-row>
                <a-col :sm="12" :xs="12" :md="12" :lg="12"> </a-col>

                <a-col
                  :sm="12"
                  :xs="12"
                  :md="12"
                  :lg="12"
                  style="display: flex; justify-content: end"
                >
                  <router-link to="signup">
                    <a-button type="text" size="large">
                      <span style="font-weight: 650">SignIn</span>
                    </a-button></router-link
                  >
                  &nbsp;&nbsp;&nbsp;
                  <router-link to="signin">
                    <a-button type="dashed" size="large">
                      <span style="font-weight: 650"
                        >Get Started
                        <ion-icon
                          style=""
                          name="arrow-forward-outline"
                        ></ion-icon>
                      </span> </a-button
                  ></router-link>
                  &nbsp;&nbsp;&nbsp;</a-col
                >
              </a-row>
            </div>
          </div>
        </a-col>
        <a-col :sm="0" :xs="0" :md="12" :lg="12">
          <div style="display: flex; justify-content: end">
            <!-- <a-row> <a-col :sm="24" :xs="24" :md="0" :lg="0" ><br><br><br><br><br></a-col></a-row> -->

            <img
              style="width: 90%; padding: 10px; margin-top: 30px"
              draggable="false"
              src="../assets/svgs/ipad.svg"
              alt="Web Platform"
            />
          </div>
        </a-col> </a-row
      ><br />
    </div>
    <!-- end page home content -->

    <!-- start wave animation  -->
    <a-row>
      <a-col :sm="24" :xs="24" :md="12" :lg="12">
        <div class="col-0-6 card-bottom-wave">
          <a-row>
            <a-col :sm="18" :xs="18" :md="18" :lg="18">
              <span
                style="
                  background: rgb(0, 75, 7);
                  border: 1px solid #00ff55;
                  color: #15ff00;
                  border-radius: 10px;
                  font-size: 15px;
                "
              >
                &nbsp; New &nbsp;</span
              >
              <h4 class="ppp-title">
                Crekaa provides you serverless software as a service
              </h4>
              <p class="ppp">
                We develope a fast networking platform with instant server
                performance to get you'r buisness instantly online.
              </p>
            </a-col>
            <a-col :sm="6" :xs="6" :md="6" :lg="6">
              <div class="card-bottom-wave-svg">
                <lottie-player
                  src="https://crekaa-2024.s3.amazonaws.com/static/lottiefiles/rounding_nw.json"
                  background="transparent"
                  speed="1"
                  style="width: 100%"
                  loop=""
                  autoplay=""
                ></lottie-player>
              </div>
            </a-col>
          </a-row>
        </div>
      </a-col>
      <a-col :sm="24" :xs="24" :md="12" :lg="12">
        <div class="col-0-6 card-bottom-wave">
          <a-row>
            <a-col :sm="18" :xs="18" :md="18" :lg="18">
              <span
                style="
                  background: rgb(0, 75, 7);
                  border: 1px solid #00ff55;
                  color: #15ff00;
                  border-radius: 10px;
                  font-size: 15px;
                "
              >
                &nbsp; New &nbsp;</span
              >

              <h4 class="ppp-title">
                We provide an robust services as per Startups And New
                Businesses.
              </h4>
              <p class="ppp">
                With Software as a Service you just focus on expanding your
                business. We provide you a fast and secure platforms.
              </p>
            </a-col>
            <a-col :sm="6" :xs="6" :md="6" :lg="6">
              <div class="card-bottom-wave-svg">
                <lottie-player
                  src="https://crekaa-2024.s3.amazonaws.com/static/lottiefiles/startup-rocket.json"
                  background="transparent"
                  speed="1"
                  style="width: 100px; height: 100px"
                  loop=""
                  autoplay=""
                ></lottie-player>
              </div>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
    <!-- community section -->
    <home_community_section />
    <!-- end community section -->

    <div
      style="
        min-height: 500px;
        text-align: start;
        padding: 10px;
        background: radial-gradient(
            45% 45% at 8% 50%,
            rgba(255, 0, 111, 0.277) 1%,
            #ff000000 99%
          ),
          /* radial-gradient(33% 33% at -1% 43%, var(--wp--preset--color--gradient-two) 1%, #FF000000 99%), */
            /* radial-gradient(15% 15% at 71% 43%, var(--wp--preset--color--gradient-three) 1%, #FF000000 99%), */
            radial-gradient(
              20% 20% at 82% 77%,
              var(--wp--preset--color--gradient-four) 1%,
              #ff000000 99%
            ),
          radial-gradient(
            40% 40% at 89% 61%,
            var(--wp--preset--color--gradient-five) 1%,
            #ff000000 99%
          );
      "
    >
      <a-row>
        <a-col :sm="24" :xs="24" :md="14" :lg="14">
          <a-row type="flex" justify="center">
            <div style="margin-top: 80px">
              <h3
                class="wp-block-heading wp-block-heading"
                style="font-size: 43px; font-weight: bold; font-family: Alkatra"
              >
                We are helping you to focus on what really matters in your
                buisness.
              </h3>
              <p
                class="wp-block-paragraph"
                style="
                  font-size: var(--fa-18);
                  font-weight: normal;
                  font-family: Alkatra;
                "
              >
                By starting and managing all software and infrastructure on our
                platform, you can save time and resources that would otherwise
                be spent on maintenance and support or developent of platform.
              </p>

              <a-row>
                <a-col :sm="12" :xs="12" :md="12" :lg="12">
                  <ul class="wp-block-list is-style-checklist">
                    <li>Realtime Servers</li>
                    <li>Interactive Dashboards</li>
                    <!-- <li>Google My Business (GMB) Chat</li> -->
                    <!-- <li>Facebook Messenger Chat</li> -->
                    <li>Reputation Management</li>
                    <li>Customer Feedback</li>
                    <li>
                      Multiple Users Support <br />
                      ( Example: For e-commerce shop there is manager,
                      delivery-boy, transporter, customer are multiple users )
                    </li>
                    <li>Routine Backups</li>
                  </ul>
                </a-col>
                <a-col :sm="12" :xs="12" :md="12" :lg="12">
                  <ul class="wp-block-list is-style-checklist">
                    <li>Smart &amp; Advananced features</li>
                    <li>Secured Platform</li>
                    <li>Mobile Applications</li>
                    <li>Websites</li>
                    <li>Standard User Interfaces</li>
                    <li>and a lot more is there...</li>
                  </ul>
                </a-col>
              </a-row>
            </div>
          </a-row>
        </a-col>
        <a-col :sm="24" :xs="24" :md="10" :lg="10">
          <home_how_we_help />
        </a-col>
      </a-row>
    </div>
    <br /><br /><br />
    <a-divider> </a-divider>
    <br /><br /><br />
    <!-- Connect section  -->
    <a-row type="flex" justify="center" style="padding: 10px">
      <a-col>
        <br /><br />
        <div
          class=""
          style="
            max-width: 550px;
            display: flex;
            justify-content: start;
            text-align: start;
          "
        >
          <div
            style="
              border-radius: 10px;
              background-color: rgba(167, 167, 167, 0.8);
              padding: 10px;
              width: 120px;
              height: 115px;
            "
          >
            <div
              style="
                margin-bottom: 30px;
                border-radius: 10px;
                justify-content: center;
                padding: 8px;
                display: flex;
                background-color: rgba(95, 95, 95, 0.9);
                max-width: 100px;
                height: 95px;
              "
            >
              <lottie-player
                style="border-radius: 20px; width: 90px; height: 80px"
                src="https://crekaa-2024.s3.amazonaws.com/static/lottiefiles/cloud-connect.json"
                background="transparent"
                speed="1"
                loop=""
                autoplay=""
              ></lottie-player>
            </div>
          </div>
          <div>&nbsp;&nbsp;&nbsp;</div>
          <div>
            <br />
            <span
              style="
                border-left: 3px solid red;
                padding-left: 10px;
                font-size: 20px;
                font-family: Alkatra;
              "
            >
              Connect
              <br /><br /><span
                style="font-size: 23px; font-weight: 700; font-family: Alkatra"
                >Your data, wherever you need it</span
              >
            </span>
          </div>
        </div>
      </a-col> </a-row
    ><br /><br />
    <!-- Connect section end -->

    <!-- Designed to Scale Your Buisness  -->
    <a-row>
      <a-col :sm="24" :xs="24" :md="12" :lg="12">
        <img
          style="max-width: 400px; width: 100%; min-width: 300px"
          src="https://crekaa-2024.s3.amazonaws.com/static/images/communication-tools.png"
          alt="Mobile-developents"
        />
      </a-col>
      <a-col :sm="24" :xs="24" :md="12" :lg="12">
        <div style="text-align: start; margin-top: 80px; padding: 10px">
          <h3 style="font-size: 25px; font-family: Alkatra">
            Designed to scale when your business grows
          </h3>
          <p style="font-size: var(--fa-16); font-family: Alkatra">
            Crekaa's platform is designed to scale with your business with
            realtime servers, so you can easily accommodate more users and
            customers as your business grows.
          </p>
        </div>
      </a-col>
    </a-row>
    <!-- end Designed to Scale Your Buisness  -->

    <!-- get Demo Section -->
    <br /><br /><br />
    <a-divider> </a-divider>
    <br /><br /><br />
    <!-- <section class="row-new-designes-section"> -->
    <section >
      <!-- <h2>Take Your Live One Page Demo</h2> -->
      <div
      class="headers d-flex justify-center flex-column align-center mb-6"
    >
      <div class="" style="display:flex;justify-content:center;gap:20px">
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAYCAYAAADkgu3FAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAITSURBVHgBlZW9cdtQDMf/IOUiXUbQCEqR5NwpE0S+RDp3ljcwJ1A0gZQJwrS270RvwCaXS1wkI2gE1+YHjCfK5/dJPqOhCAH84Q8Cj4RI2y7aHZhnAJVc0VlW0IMvbv4LEzrBTn6+BWF7/R5r5U9iIN8XvOogyniKUX0Vij1CxgcQ49vsn1xjQJtzHjO3V4iwxT1WR4hjgyBq2tWhuhfbo65zO+78twBEge5jaUbxDg+DIKVGLkszmddZ8WZvx3J6UKPbvmqwfb7pBVHDOzs5uxnldpxSw05BWBenon4ItJnXksgTI5nbS19sk+KHXdDNB+S6IwgikN2KPLs9Ke24+V8sCZjqvraBU5AX1Kkxp4erxzW8BZnvRlqW356ijAJ51XgGQKmxC5IB8BbkgF6jRuzCiJOC9AHoBRGSC8vlVaMmzX43ITUOqNsbNpJDauy9ETVlSI0DQmWdYcylT83xwVPL9RM9ZoAoST4bDyN/8pc/UAfsWHM5exMEbeaPE9gHYpUWvqQRwSwI7jgHQWhpaSbzXeibIw+eGfdNf9sMkN02Ma+ar/eHd2Oc5r4F9YKOp/TY+CfQtpSd3SkRYZ2iujJa0U1bsG1T/Z4Yd4gFEY2ipk2OHGdgrj/6W+wF2UuKqiq90Q3Mz0Zk2zQQ/mu+fc+S2v7BaTNAnCZnzKyScjlyPoWC1XTJKF8qJdQiG1pS3Z4AXNTbGacYpGEAAAAASUVORK5CYII="
          alt="section title icon"
          height="24"
          width="25"
        />
        <div
         
          class="text-body-1 text-high-emphasis font-weight-medium"
          style="letter-spacing: 0.15px !important;font-size:18px;color: var(--text-color)"
        >
       LIVE 
        </div>
      </div>
      <br>
      <div style="line-height: 2rem;font-size:18px;color:grey">
        <span class="text-h5 d-inline-block"
          > Take Your</span>
        <span
         
          class="text-h4 d-inline-block font-weight-bold"
          style="color:var(--text-color);font-size:24px;font-weight:bold"
        >
        Live One Page Demo
        </span>
        
      </div>
      <p
       
        class="text-body-1 font-weight-medium text-center"
        style="letter-spacing: 0.15px !important;font-size:14px;color:grey"
      >
      Browse through these demo according your ideal buisness need.
      </p>
    </div>
      
      <!-- <div class="row-new-designes">
          <div class="sectional-card">
            <p>XYZ Acadmy</p>
            <a href="http://xyz-acadmy.s3-website.ap-south-1.amazonaws.com">
              <img src="https://crekaa-2024.s3.amazonaws.com/static/graphics/XYZ-Acadmy.png" alt="">
            </a>
          </div>
          </div> -->
          <br>
          
        </section>
          <a-row>
      <!-- <a-col :sm="24" :xs="24" :md="12" :lg="12"  v-for="faq in faqs_1" v-bind:key="faq.key" style="padding:10px"> 
        <projectMouseTrackerCard/>
      </a-col>
      <br/>
      <br/>
      <br/>
      <br/> -->
      <a-col :sm="24" :xs="24" :md="24" :lg="24">
        <a href="http://edutech-crekaa.s3-website-us-west-2.amazonaws.com">
          <projectMouseTrackerCard />
        </a>
      </a-col>
    </a-row>
    <div class="row-new-designes">
        <div class="sectional-card">
          <p>Screza</p>
          <a
            href="https://screza-env.eba-t3fiy8ii.us-west-2.elasticbeanstalk.com"
          >
            <img
              src="https://crekaa-2024.s3.amazonaws.com/static/graphics/screza.png"
              alt=""
            />
          </a>
        </div>
        <div class="sectional-card">
          <p>Qtrill</p>
          <a href="https://api.crekaa.com/Qtrill/">
            <img
              src="https://crekaa-2024.s3.amazonaws.com/static/graphics/Qtrill.png"
              alt=""
            />
          </a>
        </div>
        <div class="sectional-card">
          <p>crekaa-onepageUI</p>
          <a href="https://api.crekaa.com/crekaa-onepageUI/">
            <img
              src="https://crekaa-2024.s3.amazonaws.com/static/graphics/crekaa-onepageUI.png"
              alt=""
            />
          </a>
        </div>
      </div>
    <br />
    <br />
    <br />

<!-- started the sliding marquee image  -->
<br>
<div class="" style="display:flex;justify-content:center;gap:20px">
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAYCAYAAADkgu3FAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAITSURBVHgBlZW9cdtQDMf/IOUiXUbQCEqR5NwpE0S+RDp3ljcwJ1A0gZQJwrS270RvwCaXS1wkI2gE1+YHjCfK5/dJPqOhCAH84Q8Cj4RI2y7aHZhnAJVc0VlW0IMvbv4LEzrBTn6+BWF7/R5r5U9iIN8XvOogyniKUX0Vij1CxgcQ49vsn1xjQJtzHjO3V4iwxT1WR4hjgyBq2tWhuhfbo65zO+78twBEge5jaUbxDg+DIKVGLkszmddZ8WZvx3J6UKPbvmqwfb7pBVHDOzs5uxnldpxSw05BWBenon4ItJnXksgTI5nbS19sk+KHXdDNB+S6IwgikN2KPLs9Ke24+V8sCZjqvraBU5AX1Kkxp4erxzW8BZnvRlqW356ijAJ51XgGQKmxC5IB8BbkgF6jRuzCiJOC9AHoBRGSC8vlVaMmzX43ITUOqNsbNpJDauy9ETVlSI0DQmWdYcylT83xwVPL9RM9ZoAoST4bDyN/8pc/UAfsWHM5exMEbeaPE9gHYpUWvqQRwSwI7jgHQWhpaSbzXeibIw+eGfdNf9sMkN02Ma+ar/eHd2Oc5r4F9YKOp/TY+CfQtpSd3SkRYZ2iujJa0U1bsG1T/Z4Yd4gFEY2ipk2OHGdgrj/6W+wF2UuKqiq90Q3Mz0Zk2zQQ/mu+fc+S2v7BaTNAnCZnzKyScjlyPoWC1XTJKF8qJdQiG1pS3Z4AXNTbGacYpGEAAAAASUVORK5CYII="
          alt="section title icon"
          height="24"
          width="25"
        />
        <div
         
          class="text-body-1 text-high-emphasis font-weight-medium"
          style="letter-spacing: 0.15px !important;font-size:18px;color: var(--text-color)"
        >
        OUR COMPONENTS
        </div>
      </div>
      <br>
      <div style="line-height: 2rem;color:grey">
        <span class="text-h5 d-inline-block"
        >Explore Our Components Libreries with </span
        >
        <span
         
          class="text-h4 d-inline-block font-weight-bold"
          style="color:var(--text-color);font-size:24px;font-weight:bold"
        >
        CREKAA.UI
        </span>
      </div>
<div class="marquee-container">
        <div class="marquee-content">
            <img src="../assets/Images/cards.png" alt="Marquee Image"/>
            <img src="../assets/Images/cards.png" alt="Marquee Image"/> <!-- Duplicate the image for continuous scrolling -->
        </div>
    </div>
      <br>
    <!-- ending the sliding marqueee image  -->



    <!-- end get Demo Section -->
      <!-- Start reviews Section  -->
      <div>

    <br>    
    <br>    
    <br>    
    <br>    
      <div style="background-color: var(--secondary-card-color);margin:auto;padding-top:100px">
    <div
      class="headers d-flex justify-center flex-column align-center mb-6"
    >
      <div class="" style="display:flex;justify-content:center;gap:20px">
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAYCAYAAADkgu3FAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAITSURBVHgBlZW9cdtQDMf/IOUiXUbQCEqR5NwpE0S+RDp3ljcwJ1A0gZQJwrS270RvwCaXS1wkI2gE1+YHjCfK5/dJPqOhCAH84Q8Cj4RI2y7aHZhnAJVc0VlW0IMvbv4LEzrBTn6+BWF7/R5r5U9iIN8XvOogyniKUX0Vij1CxgcQ49vsn1xjQJtzHjO3V4iwxT1WR4hjgyBq2tWhuhfbo65zO+78twBEge5jaUbxDg+DIKVGLkszmddZ8WZvx3J6UKPbvmqwfb7pBVHDOzs5uxnldpxSw05BWBenon4ItJnXksgTI5nbS19sk+KHXdDNB+S6IwgikN2KPLs9Ke24+V8sCZjqvraBU5AX1Kkxp4erxzW8BZnvRlqW356ijAJ51XgGQKmxC5IB8BbkgF6jRuzCiJOC9AHoBRGSC8vlVaMmzX43ITUOqNsbNpJDauy9ETVlSI0DQmWdYcylT83xwVPL9RM9ZoAoST4bDyN/8pc/UAfsWHM5exMEbeaPE9gHYpUWvqQRwSwI7jgHQWhpaSbzXeibIw+eGfdNf9sMkN02Ma+ar/eHd2Oc5r4F9YKOp/TY+CfQtpSd3SkRYZ2iujJa0U1bsG1T/Z4Yd4gFEY2ipk2OHGdgrj/6W+wF2UuKqiq90Q3Mz0Zk2zQQ/mu+fc+S2v7BaTNAnCZnzKyScjlyPoWC1XTJKF8qJdQiG1pS3Z4AXNTbGacYpGEAAAAASUVORK5CYII="
          alt="section title icon"
          height="24"
          width="25"
        />
        <div
         
          class="text-body-1 text-high-emphasis font-weight-medium"
          style="letter-spacing: 0.15px !important;font-size:18px;color: var(--text-color)"
        >
        REAL CUSTOMERS REVIEWS
        </div>
      </div>
      <br>
      <br>
      <div style="line-height: 2rem;color:grey">
        <span
         
          class="text-h4 d-inline-block font-weight-bold"
          style="color:var(--text-color);font-size:24px;font-weight:bold"
        >
        Success stories
        </span>
        <span class="text-h5 d-inline-block"
          >from clients</span
        >
      </div>
      <p
       
        class="text-body-1 font-weight-medium text-center"
        style="letter-spacing: 0.15px !important;color:grey"
      >
      See what our customers have to say about their experience.
      </p>
    </div>
    <!-- End reviews Section  -->
    <swiper
      :autoplay="{
        delay: 3000,
        disableOnInteraction: false,
      }"
      :pagination="{
        dynamicBullets: true,
      }"
      
      :breakpoints="{
                        '640': {
                          slidesPerView: 2,
                          spaceBetween: 5,
                        },
                        '768': {
                          slidesPerView: 2.5,
                          spaceBetween: 5,
                        },
                        '900': {
                          slidesPerView: 3.5,
                          spaceBetween: 5,
                        }
                      }"

      :modules="modules"
      :spaceBetween="5"
      :navigation="false"
      :virtual="true"
      class="mySwiper"
    >
      <swiper-slide v-for="rev in reviews" v-bind:key="rev" style="width: 435px; margin-right: 20px" role="group"
            aria-label="8 / 10">
            
            <div style="padding:34px;border-radius:10px;">
              <div style="background:var(--card-color);border-radius:10px;padding:10px;">
                <span style="float:left;position:absolute;left:-15px;top:-10px;color:blue"><svg style="width:80px;height:80px;" xmlns="http://www.w3.org/2000/svg" width="80px" height="80px" viewBox="0 0 24 24" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.62231 6.78278C10.0546 6.43909 10.1265 5.81001 9.78277 5.3777C9.43908 4.94539 8.81001 4.87354 8.37769 5.21724C4.73471 8.11342 4 11.8784 4 16C4 17.6569 5.34315 19 7 19C8.65685 19 10 17.6569 10 16C10 14.3432 8.65685 13 7 13C6.71233 13 6.43412 13.0405 6.17076 13.1161C6.5162 10.5872 7.45738 8.50391 9.62231 6.78278ZM20 16C20 17.6569 18.6569 19 17 19C15.3431 19 14 17.6569 14 16C14 11.8784 14.7347 8.11342 18.3777 5.21724C18.81 4.87354 19.4391 4.94539 19.7828 5.3777C20.1265 5.81001 20.0546 6.43909 19.6223 6.78278C17.4574 8.50391 16.5162 10.5872 16.1708 13.1161C16.4341 13.0405 16.7123 13 17 13C18.6569 13 20 14.3432 20 16Z" fill="#d9d9d9"/>
</svg></span>
            <h1 style="font-size:.9375rem; line-height: 1.375rem;text-wrap:wrap;color:var(--text-color)">{{ rev.review }}</h1>
            <div style="display:flex;justify-content:center;gap:20px;font-size: 18px;padding-top:5px">
              <span style="width:20px;margin-top:5px;">{{ rev.rating }}</span>
            <a-rate :value="rev.rating > 4.5 ? 5 : rev.rating " style="height:38px" allowHalf disabled />
          </div>
            <p style="font-size: 16px;color: var(--text-color);">{{ rev.review_from }}</p>
            <p style="font-size: 12px;color: grey;">{{ rev.designation }}</p>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <br>    
    <br>    
    <br>    
  </div>
</div>
<!-- End Reviews Section -->

<!-- Website Pricing Plans Section -->
<br /><br /><br />
    <a-divider> </a-divider>
    <br /><br /><br />
    <h1>Simple pricing plans for every budget <span style="font-weight: bold;
  background: -webkit-linear-gradient(217deg, rgba(0,213,218,1) 26%, rgba(0,12,255,1) 62%, rgba(0,13,148,1) 85%);;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
"> Website Platform <ion-icon style="color:blue;padding:5px" name="earth-outline"></ion-icon> </span></h1>
    <span
      >Straight forward and up front pricing plans designed to suit buisness of
      any size.</span
    >
    <br />
    <br />
    <div class="webb-pricing-plans">
      <div style="max-width: 1200px; margin: auto">
        <a-row type="flex" justify="center">
          <a-col :sm="24" :xs="24" :md="8" :lg="8" class="dfjc">
            <web_pricing_plan_card v-bind:web_plan="startup_web" />
          </a-col>
          <a-col :sm="24" :xs="24" :md="8" :lg="8" class="dfjc">
            <web_pricing_plan_card v-bind:web_plan="standard_web" />
          </a-col>
          <a-col :sm="24" :xs="24" :md="8" :lg="8" class="dfjc">
            <web_pricing_plan_card v-bind:web_plan="custom_web" />
          </a-col>
        </a-row>
      </div>
    </div>
    <!-- website  End Pricing Plans Section -->

    <!-- Mobile App Pricing Plans Section -->
    <br /><br /><br />
    <a-divider> </a-divider>
    <br /><br /><br />
    <h1>Simple pricing plans for every budget <span style="font-weight: bold;
  background: -webkit-linear-gradient(217deg, rgba(0,213,218,1) 26%, rgba(0,12,255,1) 62%, rgba(0,13,148,1) 85%);;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
"> Android Apps <ion-icon style="color:#1bff00;padding:5px" name="logo-android"></ion-icon></span></h1>
    <span
      >Straight forward and up front pricing plans designed to suit buisness of
      any size.</span
    >
    <br />
    <br />
    <div class="android-pricing-plans">
      <div style="max-width: 1200px; margin: auto">
        <a-row type="flex" justify="center">
          <a-col :sm="24" :xs="24" :md="8" :lg="8" class="dfjc">
            <web_pricing_plan_card v-bind:web_plan="startup_android" />
          </a-col>
          <a-col :sm="24" :xs="24" :md="8" :lg="8" class="dfjc">
            <web_pricing_plan_card v-bind:web_plan="standard_android" />
          </a-col>
          <a-col :sm="24" :xs="24" :md="8" :lg="8" class="dfjc">
            <web_pricing_plan_card v-bind:web_plan="custom_android" />
          </a-col>
        </a-row>
      </div>
    </div>
    <!-- Mobile App  End Pricing Plans Section -->


    
    <!-- crekaa.ui section technologies -->
    <tech_desc />
    <!-- end crekaa.ui section technologies -->

    <!-- FAQ's Section -->
    <div style="text-align: center; font-size: 30px; margin-top: 50px">
      <div
      class="headers d-flex justify-center flex-column align-center mb-6"
    >
      <div class="" style="display:flex;justify-content:center;gap:20px">
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAYCAYAAADkgu3FAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAITSURBVHgBlZW9cdtQDMf/IOUiXUbQCEqR5NwpE0S+RDp3ljcwJ1A0gZQJwrS270RvwCaXS1wkI2gE1+YHjCfK5/dJPqOhCAH84Q8Cj4RI2y7aHZhnAJVc0VlW0IMvbv4LEzrBTn6+BWF7/R5r5U9iIN8XvOogyniKUX0Vij1CxgcQ49vsn1xjQJtzHjO3V4iwxT1WR4hjgyBq2tWhuhfbo65zO+78twBEge5jaUbxDg+DIKVGLkszmddZ8WZvx3J6UKPbvmqwfb7pBVHDOzs5uxnldpxSw05BWBenon4ItJnXksgTI5nbS19sk+KHXdDNB+S6IwgikN2KPLs9Ke24+V8sCZjqvraBU5AX1Kkxp4erxzW8BZnvRlqW356ijAJ51XgGQKmxC5IB8BbkgF6jRuzCiJOC9AHoBRGSC8vlVaMmzX43ITUOqNsbNpJDauy9ETVlSI0DQmWdYcylT83xwVPL9RM9ZoAoST4bDyN/8pc/UAfsWHM5exMEbeaPE9gHYpUWvqQRwSwI7jgHQWhpaSbzXeibIw+eGfdNf9sMkN02Ma+ar/eHd2Oc5r4F9YKOp/TY+CfQtpSd3SkRYZ2iujJa0U1bsG1T/Z4Yd4gFEY2ipk2OHGdgrj/6W+wF2UuKqiq90Q3Mz0Zk2zQQ/mu+fc+S2v7BaTNAnCZnzKyScjlyPoWC1XTJKF8qJdQiG1pS3Z4AXNTbGacYpGEAAAAASUVORK5CYII="
          alt="section title icon"
          height="24"
          width="25"
        />
        <div
         
          class="text-body-1 text-high-emphasis font-weight-medium"
          style="letter-spacing: 0.15px !important;font-size:18px;color: var(--text-color)"
        >
       FAQ
        </div>
      </div>
      <br>
      <div style="line-height: 2rem;font-size:18px;color:grey">
        <span class="text-h5 d-inline-block"
          > Frequently Asked</span>
        <span
         
          class="text-h4 d-inline-block font-weight-bold"
          style="color:var(--text-color);font-size:24px;font-weight:bold"
        >
        Questions
        </span>
        
      </div>
      <p
       
        class="text-body-1 font-weight-medium text-center"
        style="letter-spacing: 0.15px !important;font-size:14px;color:grey"
      >
      Browse through these FAQs to find answers to commonly asked questions.
      </p>
    </div>
    </div>
    <a-row
      type="flex"
      justify="center"
      style="
        max-width: 1200px;
        margin: auto;
        padding-left: 20px;
        padding-right: 20px;
      "
    >
      <a-col :sm="24" :xs="24" :md="12" :lg="12">
        <faqs_section
          v-for="faq in faqs_1"
          v-bind:faq="faq"
          v-bind:key="faq.key"
        />
      </a-col>

      <a-col :sm="24" :xs="24" :md="12" :lg="12">
        <faqs_section
          v-for="faq in faqs_2"
          v-bind:faq="faq"
          v-bind:key="faq.key"
        />
      </a-col> </a-row
    ><br /><br /><br />
    <!-- ENd FAQ's Section -->
    <!-- redy section -->
    <a-row type="flex" justify="center">
      <a-col
        :sm="22"
        :xs="22"
        :md="20"
        :lg="20"
        style="
          padding: 20px;
          max-width: 1200px;
          background-color: rgba(128, 128, 128, 0.188);
          border-radius: 30px;
          min-height: 500px;
        "
      >
        <h1
          style="
            max-width: 600px;
            margin: auto;
            margin-top: 100px;
            text-align: center;
            font-size: 50px;
            font-family: Alkatra;
          "
        >
          Ready to take your buisness the next level?
        </h1>
        <br />

        <h2
          style="
            margin: auto;
            margin-top: 20px;
            font-size: 16px;
            font-family: Alkatra;
          "
        >
          There has never been a better time than right now.
        </h2>
        <br />
        <br />
        <br />

        <router-link to="/plans-web/standard-web">
          <a-button
            type="primary"
            style="
              margin-top: 20px;
              width: 150px;
              height: 50px;
              border-radius: 100px;
              font-size: 16px;
              font-weight: bold;
            "
          >
            Get Started</a-button
          > </router-link
        >&nbsp;&nbsp;&nbsp;

        <router-link to="/plans-mobile/standard-mobile">
          <a-button
            dashed
            size="large"
            style="
              margin-top: 20px;
              width: 150px;
              height: 50px;
              border-radius: 100px;
              font-size: 16px;
              font-weight: bold;
            "
          >
            Learn More</a-button
          >
        </router-link>
      </a-col> </a-row
    ><br /><br /><br />

    <!-- ENd redy section -->

    <!-- footer  main page   -->
    <a-row style="max-width: 1200px; margin: auto">
      <a-col :sm="0" :xs="0" :md="12" :lg="12">
        <div style="display: flex; justify-content: start">
          <h1 style="font-size: 16px">
            <br />
            © Copyright [2024]
            <b>
              <router-link to="/" style="color: var(--text-color)">
                Crekaa</router-link
              ></b
            >
            All rights reserved.
          </h1>
        </div>
      </a-col>
      <a-col :sm="0" :xs="0" :md="12" :lg="12">
        <div style="display: flex; justify-content: end">
          <a-button
            type="primary"
            style="font-size: 14px; height: 40px; border-radius: 60px"
            >Subscribe Newsletter</a-button
          >
        </div>
      </a-col>

      <a-col :sm="24" :xs="24" :md="0" :lg="0">
        <div style="display: flex; justify-content: center">
          <h1 style="font-size: 16px">
            <br />
            © Copyright [2024]
            <b>
              <router-link to="/" style="color: var(--text-color)">
                Crekaa</router-link
              ></b
            >
            All rights reserved.
          </h1>
        </div>
      </a-col>

      <a-col :sm="24" :xs="24" :md="0" :lg="0">
        <br />
        <div style="display: flex; justify-content: center">
          <a-button
            type="primary"
            style="font-size: 14px; height: 40px; border-radius: 60px"
            >Subscribe Newsletter</a-button
          >
        </div>
      </a-col>
    </a-row>
    <br /><br />
    <a-tag color="red">Website Builder</a-tag>
    <a-tag color="red">Free website maker</a-tag>
    <a-tag color="red">Free website builder</a-tag>
    <a-tag color="red">best website maker</a-tag>
    <a-tag color="red">best website builder</a-tag>
    <a-tag color="red">how to make our own website</a-tag>
    <a-tag color="red">how to build our own website</a-tag>
    <a-tag color="red">how to build own website</a-tag>
    <a-tag color="red">how to build website</a-tag>
    <!-- end footer main page   -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import home_community_section from "@/components/home/home001.vue";
import projectMouseTrackerCard from "@/components/home/projectMouseTrackerCard.vue";
import home_how_we_help from "@/components/home/home002.vue";
import web_pricing_plan_card from "@/components/home/web_card.vue";
import tech_desc from "@/components/home/home003.vue";
import faqs_section from "@/components/home/home004.vue";

// import required modules Swiper styles
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'

import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/virtual'

import { Autoplay,Pagination, Navigation, Virtual } from 'swiper/modules'

export default {
  name: "HomeView",
  data() {
    return {
      checked: false,
      modules: [Pagination, Navigation, Virtual,Autoplay],
      reviews:[
  {
    "logo": "crekaa_logo.png",
    "review": "CREKAA's full-stack business solutions exceeded our expectations! Their highly trained global developers team seamlessly designed, developed, managed, and maintained our internet technology solutions. We couldn't be happier with the results.",
    "rating": 5,
    "review_from": "Emily Johnson",
    "designation": "CEO, Tech Innovations Inc."
  },
  {
    "logo": "crekaa_logo.png",
    "review": "Impressive work by CREKAA services! They provided us with tailored business solutions that perfectly aligned with our needs. Their team's expertise in designing and developing internet technology solutions is unmatched. Highly recommended!",
    "rating": 4.8,
    "review_from": "Michael Smith",
    "designation": "CTO, Digital Dynamics"
  },
  {
    "logo": "crekaa_logo.png",
    "review": "Working with CREKAA was a game-changer for our company. Their comprehensive approach to full-stack business solutions ensured that every aspect of our project was handled efficiently. The result? A seamless and highly functional internet technology solution.",
    "rating": 4.7,
    "review_from": "Sophia Lee",
    "designation": "COO, Global Enterprises Ltd."
  },
  {
    "logo": "crekaa_logo.png",
    "review": "Kudos to CREKAA for their outstanding services! From start to finish, they demonstrated professionalism, expertise, and a commitment to delivering top-notch solutions. Our experience with their global developers team was nothing short of excellent.",
    "rating": 4.9,
    "review_from": "David Clark",
    "designation": "Founder, InnovateX"
  },
  {
    "logo": "crekaa_logo.png",
    "review": "We are thoroughly impressed with CREKAA's full-stack business solutions. Their team's attention to detail and dedication to meeting our specific needs were commendable. Thanks to them, we now have a robust internet technology solution in place.",
    "rating": 4.8,
    "review_from": "Jennifer Brown",
    "designation": "Director of Operations, TechSavvy Solutions"
  },
  {
    "logo": "crekaa_logo.png",
    "review": "Choosing CREKAA for our business solutions was one of the best decisions we made. Their team of global developers understood our requirements perfectly and delivered a top-notch internet technology solution within our timeframe.",
    "rating": 4.6,
    "review_from": "Daniel White",
    "designation": "VP of Technology, Nexus Enterprises"
  },
  {
    "logo": "crekaa_logo.png",
    "review": "Exceptional service from CREKAA! Their expertise in full-stack solutions and dedication to client satisfaction is truly commendable. We are thrilled with the results they delivered for our company.",
    "rating": 4.9,
    "review_from": "Sarah Thompson",
    "designation": "Head of IT, Digital Innovations Ltd."
  },
  {
    "logo": "crekaa_logo.png",
    "review": "CREKAA exceeded our expectations at every step of the project. Their team's professionalism and ability to tackle complex challenges made the entire process smooth and efficient. Highly recommend their services!",
    "rating": 4.7,
    "review_from": "Ryan Wilson",
    "designation": "Director of Strategy, FutureTech Solutions"
  },
  {
    "logo": "crekaa_logo.png",
    "review": "We were impressed by CREKAA's deep understanding of our industry and business needs. Their tailored solutions were exactly what we were looking for, and their support throughout the project was exceptional.",
    "rating": 4.8,
    "review_from": "Emma Davis",
    "designation": "CEO, InnovateTech Inc."
  },
  {
    "logo": "crekaa_logo.png",
    "review": "CREKAA's services are second to none! Their team's expertise and attention to detail ensured that our project was completed on time and within budget. We are incredibly satisfied with the outcome.",
    "rating": 4.9,
    "review_from": "Alex Martinez",
    "designation": "CTO, TechGenius Solutions"
  },
  {
    "logo": "crekaa_logo.png",
    "review": "Working with CREKAA was a pleasure from start to finish. Their team's professionalism and dedication to delivering high-quality solutions were evident throughout the project. We look forward to collaborating with them again in the future.",
    "rating": 4.7,
    "review_from": "Jessica Carter",
    "designation": "VP of Operations, Digital Ventures Ltd."
  },
  {
    "logo": "crekaa_logo.png",
    "review": "CREKAA's full-stack solutions have transformed our business operations. Their team's expertise and innovative approach helped us achieve our goals effectively. We highly recommend their services to any company looking for comprehensive business solutions.",
    "rating": 4.8,
    "review_from": "Matthew Harris",
    "designation": "CEO, Dynamic Enterprises"
  },
  {
    "logo": "crekaa_logo.png",
    "review": "We are extremely impressed with CREKAA's services. Their team's professionalism, expertise, and dedication to client satisfaction set them apart. Thanks to their full-stack solutions, we now have a competitive edge in our industry.",
    "rating": 4.9,
    "review_from": "Sophie Evans",
    "designation": "Director of Marketing, TechVision Inc."
  },
  {
    "logo": "crekaa_logo.png",
    "review": "CREKAA delivered beyond our expectations! Their team's attention to detail and ability to understand our unique requirements made the entire process seamless. We are thrilled with the outcome and highly recommend their services.",
    "rating": 4.7,
    "review_from": "Adam Johnson",
    "designation": "COO, GlobalTech Solutions"
  },
  {
    "logo": "crekaa_logo.png",
    "review": "We couldn't be happier with CREKAA's services. From the initial consultation to the final delivery, their team demonstrated professionalism and expertise. Thanks to them, we now have a cutting-edge internet technology solution that meets our needs perfectly.",
    "rating": 4.8,
    "review_from": "Rachel Smith",
    "designation": "Director of Finance, Digital Innovations Corp."
  }
],
      startup_web: {
        title: "Startups",
        price: "7,499",
        save_upto: "50",
        save_upto_price: "15",
        li1: "2 Types of User Accounts",
        li8: "High Security",
        li2: "All Prominent Functionalities",
        li3: "Modern UI",
        li4: "Flexible to Move",
        li5: "1 Interactive Dashboard",
        li6: "1 Month of Free Server Service",
        li7: "Real-time Support",
        link_url: "/plans-web/startup-web",
      },
      standard_web: {
        title: "Standard",
        price: "19,999",
        save_upto: "25",
        save_upto_price: "25",
        li1: "4 Types of User Accounts",
        li2: "All Prominent Functionalities",
        li3: "Modern UI with Brand Interface",
        li4: "Flexible to Upgrade",
        li5: "2 Interactive Dashboards",
        li6: "3 Months of Free Server Service",
        li7: "Prioritized & Real-time Support",
        li8: "Advanced Security",
        link_url: "/plans-web/standard-web",
      },
      custom_web: {
        title: "Custom",
        price: "Pricing Depends on Custom Functionalities Needed by Business",
        save_upto: "Up to 50%",
        save_upto_price: "Save ₹15k%",
        li1: "Customized Types of Accounts",
        li2: "All Prominent Functionalities",
        li3: "Modern UI",
        li4: "Flexible to Move & Upgrade",
        li5: "3 Interactive Dashboards",
        li6: "3 Months of Free Server Service",
        li7: "Prioritized & Real-time Support",
        li8: "Customized Advanced Security",
        link_url: "/plans-web/custom-web",
      },
      startup_android: {
        title: "Startup",
        price: "14,999",
        save_upto: "50",
        save_upto_price: "15",
        li1: "2 Types of User Accounts",
        li8: "High Security",
        li2: "All Prominent Functionalities",
        li3: "Modern UI",
        li4: "Native Fast",
        li5: "Functional UI Technologies",
        li6: "1 Month of Free Service",
        li7: "Real-time Support",
        link_url: "/plans-mobile/startup-mobile",
      },
      standard_android: {
        title: "Standard",
        price: "19,999",
        save_upto: "25",
        save_upto_price: "25",
        li1: "Multiple of User Accounts (upto 4)",
        li2: "All Prominent Functionalities",
        li3: "Modern UI with Brand Interface",
        li4: "Native Fast",
        li5: "Advanced UI Technologies",
        li6: "3 Months of Free Service",
        li7: "Prioritized & Real-time Support",
        li8: "Advanced Security",
        link_url: "/plans-mobile/standard-mobile",
      },
      custom_android: {
        title: "Custom",
        price: "Pricing Depends on Custom Functionalities Needed by Business",
        save_upto: "Up to 50%",
        save_upto_price: "Save ₹15k%",
        li1: "Customized Types of Accounts",
        li2: "All Prominent Functionalities",
        li3: "Modern UI",
        li4: "Flexible to Move & Upgrade",
        li5: "Crekaa.UI Technologies",
        li6: "3 Months of Free Service",
        li7: "Prioritized & Real-time Support",
        li8: "Customized Advanced Security",
        link_url: "/plans-mobile/custom-mobile",
      },

      faqs_1: [
        {
          key: 1,
          que: "Should I create a mobile or a web app?",
          answer:
            "Both have their benefits and flaws. Mobile apps are surely more expensive but can provide you with many more data collecting, monetisation capabilities than web applications. Progressive Web Apps are a good compromise between a mobile and web app — you can learn more about PWA on our blog . But remember that sooner or later you may need both mobile and web app and when that moment comes we are here for you :)",
        },
        {
          key: 2,
          que: "What do I need to know before contacting you?",
          answer:
            "Well, the most important thing to know is what do you want to accomplish. Why do I need this software? What for? What should it do? Having a clear vision in mind is crucial when ordering a software application. You don't want to spend many months developing it with us without being sure what you need. If you have no experience in app development feel free to read our blog entry on that topic . We'll also be glad to help you get started, building projects from scratch is nothing new for us.",
        },
        {
          key: 3,
          que: "How much time will it take for you to make my app?",
          answer:
            "To give you a precise answer we must prepare a project scope and create a budget. We shall tell you how much of that scope can be completed within the budget and we'll be able to estimate how long will it take after at least a month of work. Don't worry, if you're not happy with our work after two weeks you can retract from the project with no penalties, this is our trial period and we believe that any reliable software house should give you one.",
        },
        {
          key: 4,
          que: "How do you guarantee product quality?",
          answer:
            "We evaluate the result after every two weeks, we test our work (we conduct both development and acceptance tests), we present it to you, we apply your feedback so you know you get what you are paying for.",
        },
      ],
      faqs_2: [
        {
          key: 5,
          que: "I want to be involved in the app development process, do you have a problem with that?",
          answer:
            "Not at all! We want you to be sure you'll receive the product you desire. From the planning stage to each finished version, we invite you to evaluate and improve our work. Our philosophy is to work with the client, not merely finish given tasks. We are always ready to listen and communicate.",
        },
        {
          key: 6,
          que: " What happens after you finish my app?",
          answer:
            "Hopefully the app is exactly what you dreamed of :) But apart from delivering a finished product, we are happy to provide you with technical support and app maintenance should you need it. After all, we know our work inside out. Of course if you want to maintain the app by yourself the source code and all technical data is at your disposal, but even in that case, feel free to contact us if you need any help.",
        },
        {
          key: 7,
          que: "What if I come to you with a product that I want to improve?",
          answer:
            "We’ll be more than happy to help you with that. Beginning with a UX Audit (if applicable) through improving your system architecture and adding new features with latest advanced technologies.",
        },
        {
          key: 8,
          que: "Why should I choose you and not hire my own software development team?",
          answer:
            "Hiring your own software developers can be a really smart choice, however, recruitment process is time-consuming and the costs of keeping an in-house programming team are very high. You'll probably need to employ your own  software engineers sooner or later but hiring us can help you save a lot of money and time (which is basically the same in a business project)",
        },
      ],
    };
  },
  components: {
    // swiper
    Swiper,
    SwiperSlide,
    // HelloWorld,
    home_community_section,
    home_how_we_help,
    web_pricing_plan_card,
    tech_desc,
    faqs_section,
    projectMouseTrackerCard,

  },
  created() {
    document.title =
      "CREKAA The Cross-Platform Responsive Element Kit for Advanced Applications For Buisness. ( crekaa ) Website Builder, Free website maker, Free website builder, best website maker, best website builder, how to make our own website ";
  },
  head() {
    return {
      title: "Crekaa",
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development",
        },
        {
          hid: "author",
          name: "author",
          content:
            "Cross-Platform Responsive Element Kit for Advanced Applications For Buisness",
        },

        // <!-- Facebook Open Graph tags -->
        { hid: "og:url", property: "og:url", content: "https://crekaa.com/" },
        { hid: "og:type", property: "og:type", content: "website" },
        {
          hid: "og:title",
          property: "og:title",
          content:
            "Crekaa, Cross-Platform Responsive Element Kit for Advanced Applications For Buisness",
        },
        {
          hid: "og:description",
          property: "og:description",
          content:
            "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development",
        },
        {
          hid: "og:image",
          property: "og:image",
          content:
            "../../assets/logo.png",
        },
        // Add more meta tags as needed

        // <!-- Twitter Card tags -->
        {
          hid: "twitter:card",
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          hid: "twitter:title",
          name: "twitter:title",
          content:
            "Crekaa , Cross-Platform Responsive Element Kit for Advanced Applications For Buisness",
        },
        {
          hid: "twitter:description",
          name: "twitter:description",
          content:
            "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development",
        },
        {
          hid: "twitter:image",
          name: "twitter:image",
          content:
            "../../assets/logo.png",
        },
      ],
    };
  },
  mounted() {
    Typed.new(".type", {
      strings: ["Business", "Startup", "Shop", "Services"],
      stringsElement: null,
      // typing speed
      typeSpeed: 60,
      // time before typing starts
      startDelay: 800,
      // backspacing speed
      backSpeed: 20,
      // time before backspacing
      backDelay: 600,
      // loop
      loop: true,
      // false = infinite
      // loopCount: 25,
      // show cursor
      showCursor: false,
      // character for cursor
      cursorChar: "|",
      // attribute to type (null == text)
      attr: null,
      // either html or text
      contentType: "html",
    });
  },
  methods: {
    // switchMode() {
    //   var element = document.body;
    //   if (element.classList.contains("dark-mode")) {
    //     element.classList.remove("dark-mode");
    //     element.classList.remove("dark");
    //     element.classList.add("light-mode");
    //     localStorage.setItem("mode", "light");
    //   } else {
    //     element.classList.remove("light-mode");
    //     element.classList.add("dark-mode");
    //     element.classList.add("dark");
    //     localStorage.setItem("mode", "dark");
    //   }
    // },
  },
};
</script>
<style scoped>
/* marquee tage working  */
.marquee-container {
            width: 100%;
            overflow: hidden;
            position: relative;
            /* height: 200px; */
             /* Adjust the height according to your image */
        }

        .marquee-content {
            display: flex;
            width: fit-content;
            animation: marquee 20s linear infinite;
        }

        .marquee-content img {
            height: 100%;
            /* Ensure images do not have space between them */
            margin: 0;
            padding: 0;
        }

        @keyframes marquee {
            from {
                transform: translateX(0%);
            }
            to {
                transform: translateX(-50%);
            }
        }

        /* gradient color focus  */
.grad-background-focus {
  background: radial-gradient(
      45% 45% at 8% 50%,
      rgba(255, 0, 111, 0.277) 1%,
      #ff000000 99%
    ),
    /* radial-gradient(33% 33% at -1% 43%, var(--wp--preset--color--gradient-two) 1%, #FF000000 99%), */
      radial-gradient(
        15% 15% at 71% 43%,
        var(--wp--preset--color--gradient-three) 1%,
        #ff000000 99%
      ),
    radial-gradient(
      20% 20% at 82% 77%,
      var(--wp--preset--color--gradient-four) 1%,
      #ff000000 99%
    ),
    radial-gradient(
      40% 40% at 89% 61%,
      var(--wp--preset--color--gradient-five) 1%,
      #ff000000 99%
    );
}

.has-primary-gradient-background {
  /* height: 900px; */
  background: var(--wp--preset--gradient--primary) !important;
}

/* typing animation start */
span.type {
  margin: 0 auto;
  width: 200px;
  margin-top: 100px;
}

span.type::after {
  content: "|";
  display: inline;
  color: blueviolet;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* typing animation end*/

/* start wave animation  */
.card-bottom-wave {
  background: linear-gradient(15deg, #3c3c3c, rgb(15, 13, 22));
  border: 2px solid orange;
  background-image: url(https://media.istockphoto.com/id/501655522/photo/star-field-at-night.jpg?b=1&s=170667a&w=0&k=20&c=nOIdRNElZtKxCxADiYoMXcATBMOYRtQy7P3V_txHjss=);
  border-radius: 10px;
  padding: 20px;
  text-align: start;
  margin-bottom: 30px;
  margin: 10px;
}

/* community section */

/* for cards bottom main screen */
.card-bottom-wave-svg {
  background-color: white;
  width: 100px;
  height: 100px;
  border-radius: 10px;
}

.ppp {
  color: white;
  font-size: 13px;
}

.ppp-title {
  color: white;
  margin-top: 20px;
  font-size: 15px;
}

/* listings  */
.is-style-checklist {
  list-style: none;
  padding-left: 1em;
}

.is-style-checklist li::before {
  content: "✔️";
  /* Use the ✅ symbol as a bullet */
  margin-right: 0.5rem;
  /* Add some spacing between the symbol and the list item text */
}

.wp-block-list {
  display: flex;
  flex-direction: column;
  font-size: var(--fa-13);
  gap: 0.5em;
  font-family: Alkatra;
}

/* web pricing plans */
.webb-pricing-plans {
  /* height:600px; */
  --wp--preset--color--gradient-container-23-one: #4c00ff3b;
  --wp--preset--color--gradient-container-23-two: #00c1ff47;
  --wp--preset--color--gradient-container-23-three: #00e4ff3b;
  --wp--preset--color--gradient-container-23-four: #0039ff3d;
  --wp--preset--color--gradient-container-23-five: #5800ff4a;

  background-image:
    /* radial-gradient(15% 15% at 8% 50%, var(--wp--preset--color--gradient-container-23-one) 1%, #FF000000 99%), */ radial-gradient(
      20% 40% at -1% 33%,
      var(--wp--preset--color--gradient-container-23-two) 1%,
      #ff000000 99%
    ),
    /* radial-gradient(15% 15% at 71% 43%, var(--wp--preset--color--gradient-container-23-three) 1%, #FF000000 99%), */
      /* radial-gradient(20% 20% at 82% 77%, yellow 1%, #FF000000 99%), */
      radial-gradient(
        40% 40% at 49% 61%,
        var(--wp--preset--color--gradient-container-23-five) 1%,
        #ff000000 99%
      );
  background-size: 100% 100%;
  animation: gradientAnimation 5s linear infinite;
  transition: background 5s ease;
}

/*  container 23 */
@keyframes gradientAnimation {
  0% {
    --wp--preset--color--gradient-container-23-one: #4c00ff3b;
    --wp--preset--color--gradient-container-23-two: #00c1ff47;
    --wp--preset--color--gradient-container-23-three: #00e4ff3b;
    --wp--preset--color--gradient-container-23-four: #0039ff3d;
    --wp--preset--color--gradient-container-23-five: #5800ff4a;
  }

  25% {
    --wp--preset--color--gradient-container-23-one: #ff00003b;
    --wp--preset--color--gradient-container-23-two: #fbff0047;
    --wp--preset--color--gradient-container-23-three: #00ff113b;
    --wp--preset--color--gradient-container-23-four: #0039ff3d;
    --wp--preset--color--gradient-container-23-five: #ae00ff4a;
  }

  50% {
    --wp--preset--color--gradient-container-23-one: #a700003b;
    --wp--preset--color--gradient-container-23-two: #ff730047;
    --wp--preset--color--gradient-container-23-three: #5500ff3b;
    --wp--preset--color--gradient-container-23-four: #c300ff3d;
    --wp--preset--color--gradient-container-23-five: #ff00aa4a;
  }

  75% {
    --wp--preset--color--gradient-container-23-one: #ff00003b;
    --wp--preset--color--gradient-container-23-two: #fbff0047;
    --wp--preset--color--gradient-container-23-three: #00ff113b;
    --wp--preset--color--gradient-container-23-four: #0039ff3d;
    --wp--preset--color--gradient-container-23-five: #ae00ff4a;
  }

  100% {
    --wp--preset--color--gradient-container-23-one: #4c00ff3b;
    --wp--preset--color--gradient-container-23-two: #00c1ff47;
    --wp--preset--color--gradient-container-23-three: #00e4ff3b;
    --wp--preset--color--gradient-container-23-four: #0039ff3d;
    --wp--preset--color--gradient-container-23-five: #5800ff4a;
  }
}

.dfjc {
  display: flex;
  justify-content: center;
}

.android-pricing-plans {
  background: var(--bg-android-plans) !important;
  /* position: absolute; */
}

/* get procudts demo section */
.row-new-designes-section {
  background-color: #ebebeb64;
  padding-top: 20px;
  margin: auto;
}

.row-new-designes {
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.row-new-designes .sectional-card {
  padding: 20px;
  margin: auto;
}

.row-new-designes img {
  width: 95%;
  border-radius: 10px;
  margin: auto;
}

.row-new-designes img:hover {
  width: 100%;
  border-radius: 10px;
  transition: width 500ms;
  filter: brightness(0.8);
}
</style>