<template>
    <a-row>
        <a-col :sm="24" :xs="24" :md="4" :lg="4">
        <div class="sticky-header is-sticky">
            <!-- <br><br><br>
            <br><br><br> -->
            <div class="left-pane">

<a class="button-left-pane-web" href="{% url 'pricing_plan_website_startup' %}">

<h1 class="button-left-pane-plan-type {% if '/pricing-plan-website-startup' in request.path %}active{% endif %}">
<lord-icon
src="https://cdn.lordicon.com/ilpmnyul.json"
trigger="hover"
colors="primary:#121331,secondary:#ebe6ef"
style="width:60px;height:60px">
</lord-icon>
Startups Website</h1>
</a>

<a class="button-left-pane-web" href="{% url 'pricing_plan_website_standard' %}">
  <h1 class="button-left-pane-plan-type {% if '/pricing-plan-website-standard' in request.path %}active{% endif %}">
  <lord-icon
  src="https://cdn.lordicon.com/hbwqfgcf.json"
  trigger="hover"
  colors="outline:#121331,primary:#646e78,secondary:#ffc738,tertiary:#4bb3fd"
  style="width:60px;height:60px">
</lord-icon>
Standard Website</h1>
</a>

<a class="button-left-pane-web" href="{% url 'pricing_plan_website_custom' %}">

<h1 class="button-left-pane-plan-type {% if '/pricing-plan-website-custom' in request.path %}active{% endif %}"><lord-icon
src="https://cdn.lordicon.com/ccwgfhfg.json"
trigger="hover"
colors="outline:#121331,primary:#92140c,secondary:#4bb3fd,tertiary:#ebe6ef"
style="width:60px;height:60px">
</lord-icon>Custom Website</h1>
</a>


</div>

        </div>
        </a-col>
        <a-col :sm="24" :xs="24" :md="20" :lg="20">
            <!-- <startup_card/> -->
        
        </a-col>
    </a-row>

</template>
<script>
// import startup_card from '@/components/plans/startup_card.vue'
export default {
    name:"plans_web",
    data(){ return{
        cards:[{

        }]
    } },
    
    created(){
    document.title = "All Plans Website - Crekaa";
  },
  head() {
    return {
      title: "Crekaa",
      meta: [
        { hid: 'description', name: 'description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'author', name: 'author', content: "Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        
        // <!-- Facebook Open Graph tags -->
        { hid: 'og:url', property: 'og:url', content: "https://crekaa.com/" },
        { hid: 'og:type', property: 'og:type', content: "website" },
        { hid: 'og:title', property: 'og:title', content: "Crekaa, Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'og:description', property: 'og:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'og:image', property: 'og:image', content: "../../assets/logo.png" },
        // Add more meta tags as needed

        // <!-- Twitter Card tags -->
        { hid: 'twitter:card', name: 'twitter:card', content: "summary_large_image" },
        { hid: 'twitter:title', name: 'twitter:title', content: "Crekaa , Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'twitter:description', name: 'twitter:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'twitter:image', name: 'twitter:image', content: "../../assets/logo.png" },

      ]
    };
  },
    components:{
        // startup_card
    }
}
</script>
<style scoped>
a{text-decoration:none}
.main-page{
margin-top:0px;
width:80%;
}
.left-pane{
  margin-top:40px;

  /* width:22%; */
  /* background-color: white; */
  height: 100%;
 position: absolute;
}
.right-pane{
  margin-top:20px;

margin-left:22%;
  width:78%;
  height: 100%;
  overflow-y: scroll;
  position: absolute;
  background: rgba(243, 230, 255,0.4);
  padding-top:30px;
  padding-left:50px;
  border-top-left-radius: 50px;
}

/*  */
.heading-budget-page{
  font-size:30px;
  margin-bottom:10px;
  color: black;
}
.button-left-pane-plan-type{
  background-color: rgba(230, 204, 255,0.5);
  border-radius:20px;
  padding: 20px;
  font-size:18px;
  margin:20px;
  color:black;
  display: flex;
  justify-content: space-evenly;
    flex-direction: row-reverse;
    align-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.label-standard{
  width: 70px;
  height:30px;
  padding: 10px;
  border-radius: 50px;
  margin-top: -20px;
  margin-right: 10px;
  display: flex;
  float: left;

  background-color: rgba(172, 0, 230,0.3);
  border:1px solid rgb(172, 0, 230);
  border-radius: 20px;

  display: flex;

  justify-content: center;
  /* margin-bottom:-20px */
}
.label-standard h1{
  margin-top: 0px;

  font-size:10px;
  color:rgb(172, 0, 230);
  font-weight: 700;

}

.label-custom{
  width: 70px;
  height:30px;
  padding: 10px;
  border-radius: 50px;
  margin-top: 0;
  margin-right: 10px;
  display: flex;
  float: left;

  background-color: rgba(255, 0, 0,0.3);
  border:1px solid rgb(255, 0, 0);
  border-radius: 20px;

  display: flex;

  justify-content: center;
  /* margin-bottom:-20px */
}
.button-left-pane-web{
  margin-top:-50px
}
.label-custom h1{
  margin-top: 0px;

  font-size:10px;
  color:rgb(255, 0, 0);
  font-weight: 700;

}
.button-left-pane-plan-type:hover{
  background-color: rgba(230, 204, 255,0.5);
  border:3px solid rgba(230, 204, 255,0.9);

  border-radius:20px;
  padding: 20px;
  font-size:18px;
  margin:20px;
  color:black;
  display: flex;
  justify-content: space-evenly;
    flex-direction: row-reverse;
    align-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.active{
  border:3px solid rgba(230, 204, 255,0.9)
}

/* mobile view management  */
.container-mob{
  display: flex;
  background: rgba(243, 230, 255,0.4);
  margin-top:50px;
}
.main-page-mob{
  background: rgba(243, 230, 255,0.4);
  width:95%;
  margin:auto;
}
.card-full-need{
margin-top: 50px;

}



.label-standard-mob{
  width: 100%;
  height:30px;
  padding: 10px;
  border-radius: 50px;

  margin-right: 10px;
  display: flex;
  float: left;


  border-radius: 20px;

  display: flex;

  justify-content: center;
  /* margin-bottom:-20px */
}
.label-standard-mob h1{
  margin-top: -2px;
  font-size:12px;
  font-weight: 700
}

.bg-su{
  background-color:rgb(25, 2, 228,0.3);
  border:1px solid rgb(25, 2, 228)


}
.bg-su h1{
    color:rgb(25, 2, 228)

}
.bg-st{
  background-color: rgba(172, 0, 230,0.3);
  border:1px solid rgb(172, 0, 230);
  color:rgb(172, 0, 230);
}
.bg-st h1{
    color:rgb(172, 0, 230);
}
.bg-cu{

    background-color: rgba(255, 0, 0,0.3);
    border:1px solid rgb(255, 0, 0);

    color:rgb(255, 0, 0);
}

.bg-cu h1{
    color:rgb(255, 0, 0);
}
.ul-guidlines li{
  font-size:14px;
  }
.head-pane{
  display: flex;
  justify-content: space-between;
}
.right-side-head-pane{
  margin-right:30px
}
.book-btn-custom{

  background:rgba(228, 2, 2, 0.24);
  color:rgb(137, 0, 0);
  cursor: pointer;
  border-radius:30px;
  height: 50px;
  padding:12px;
  box-shadow: rgba(228, 2, 2, 0.5) 0px 8px 24px, rgba(228, 2, 2, 0.1) 0px 16px 56px, rgba(228, 2, 2, 0.1) 0px 24px 80px;
  border:2px solid rgb(228, 2, 2)
}
.book-btn-custom:hover{
  background:rgb(228, 2, 2,0.34);
  box-shadow: rgba(228, 2, 2, 0.4) 0px 8px 24px, rgba(228, 2, 2, 0.2) 0px 16px 56px, rgba(228, 2, 2, 0.2) 0px 24px 80px;

  color:rgb(228, 2, 2,);

}
.book-btn-standard{

  background:rgba(224, 2, 228, 0.24);
  color:rgb(135, 0, 137);
  cursor: pointer;
  border-radius:30px;
  height: 50px;
  padding:12px;
  box-shadow: rgba(225, 2, 228, 0.5) 0px 8px 24px, rgba(225, 2, 228, 0.1) 0px 16px 56px, rgba(225, 2, 228, 0.1) 0px 24px 80px;
  border:2px solid rgb(172, 0, 230)
}
.book-btn-standard:hover{
  background:rgb(225, 2, 228,0.34);
  box-shadow: rgba(225, 2, 228, 0.4) 0px 8px 24px, rgba(225, 2, 228, 0.2) 0px 16px 56px, rgba(225, 2, 228, 0.2) 0px 24px 80px;

  color:rgb(135, 0, 137);

}
.book-btn-startup{
  background:rgba(25, 2, 228, 0.24);
  color:rgb(25, 2, 228);
  cursor: pointer;
  border-radius:30px;
  height: 50px;
  padding:12px;
  box-shadow: rgba(25, 2, 228, 0.5) 0px 8px 24px, rgba(25, 2, 228, 0.1) 0px 16px 56px, rgba(25, 2, 228, 0.1) 0px 24px 80px;
  border:2px solid rgb(25, 2, 228)
}
.book-btn-startup:hover{
  background:rgb(25, 2, 228,0.34);
  box-shadow: rgba(25, 2, 228, 0.4) 0px 8px 24px, rgba(25, 2, 228, 0.2) 0px 16px 56px, rgba(25, 2, 228, 0.2) 0px 24px 80px;

  color:rgb(25, 2, 228);

}








.bg-gl{
  background-color:rgb(64, 255, 0,0.3);
  border:1px solid rgb(0 ,186 ,37)


}
.bg-gl h1{
    color:rgb(26, 103 ,0)

}

</style>