<template>
    <div style="margin-top:40px" >
        <a-row>
            <a-col :sm="12" :xs="12" :md="12" :lg="12" style="padding:5px">
             <router-link to="/edit-profile">
            <div class="button-ancs">

                <lord-icon src="https://cdn.lordicon.com/mebvgwrs.json" trigger="hover" class="option-lottie">
                </lord-icon>
                <h6 style="font-size: 14px;">Edit Profile</h6>
            </div>

        </router-link>
            </a-col>
            <a-col :sm="12" :xs="12" :md="12" :lg="12" style="padding:5px">
            <router-link to="/my-notifications">
            <div class="button-ancs">
                <lord-icon src="https://cdn.lordicon.com/kiynvdns.json" trigger="hover" class="option-lottie">
                </lord-icon>
                <h6  style="font-size: 14px;" block>My Notifications</h6>
            </div>
        </router-link>
        </a-col>
        </a-row>
        
        <a-row>
            <a-col :sm="12" :xs="12" :md="12" :lg="12" style="padding:5px">
             <router-link to="/my-billings">
            <div class="button-ancs">
                <lord-icon src="https://cdn.lordicon.com/jtiihjyw.json" trigger="hover" class="option-lottie">
                </lord-icon>
                <h6 style="font-size: 14px;" block>My Billings</h6>
            </div>
        </router-link>
            </a-col>
            <a-col :sm="12" :xs="12" :md="12" :lg="12" style="padding:5px">
             <router-link to="/my-patches">
            <div class="button-ancs">
                <lord-icon src="https://cdn.lordicon.com/pcllgpqm.json" trigger="hover" class="option-lottie">
                </lord-icon>
                <h6 style="font-size: 14px;" block>My Patches</h6>
            </div>
        </router-link>
        </a-col>
        </a-row>
        <a-row>
        <a-col :sm="12" :xs="12" :md="12" :lg="12" style="padding:5px">
             <router-link to="/my-settings">
            <div class="button-ancs">
                <lord-icon src="https://cdn.lordicon.com/yvrtgyqf.json" trigger="hover" class="option-lottie">
                </lord-icon>
                <h6 style="font-size: 14px;" block>Crekaa Settings</h6>
            </div>
        </router-link>
        </a-col>
        </a-row>

        
       
       
    </div>
</template>
<script>
// import my_settings from '../../views/user/settings'
// import notification_details from '../../views/user/notification_details'
// import my_patches from '../../views/user/my_patches'
// import my_notifications from '../../views/user/my_notifications'
// import my_billings from '../../views/user/my_billings'
// import edit_profile from '../../views/user/edit_profile'
// import bill_details from '../../views/user/bill_details'

export default {
    name: "main_left_pane_menu_user",
    // components:{
    //     my_settings,
    //     notification_details,
    //     my_patches,
    //     my_notifications,
    //     my_billings,
    //     edit_profile,
    //     bill_details,
    // }
}
</script>
<style scoped>
.button-ancs {
    border-radius: 5px;
    background-color: var(--notification-color-bg);
    padding: 5px;
    width: 100%;

    color: var(--text-color)
}

.button-ancs:hover {
    /* border-radius:5px; */
    background-color: var(--hover-slide-button);
    /* padding:5px;width:50% */
}

.option-lottie {
    width:80px;
    height:80px

}
</style>