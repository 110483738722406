<template>
    <a-button type="primary" @click="showModal" style="border-radius:100%"><template #icon>
        <ion-icon name="pencil-outline"></ion-icon>
    </template></a-button>
        <a-modal v-model:open="open" title="Disable/Enable Job" :confirm-loading="confirmLoading" @ok="handleOk">
            <label>Job Active </label>
            <a-switch v-model:checked="job_actv" size="large" :loading="loading_active" />
            <br>
            <span style="font-size: 12px;">( Active will show this posted job are currently accepting responces or not.<br>{✅
            means yes it is accepting}, <br>{❌ means no it is not accepting} )</span> 

        </a-modal>
    </template>
    <script>
    
    

import { notification } from 'ant-design-vue'
import axios from 'axios'

    export default{
        name:"updateActiveJobCareers",
        data(){
            return {
                modalText: 'Content of the modal',
                open: false,
                confirmLoading: false,
                
                job_num:'',
                job_actv:true,
                loading_active:false
            }
        },
        mounted(){
            this.job_num=this.job_number
            this.job_actv=this.job_active
        } ,
        props:{
            job_number:Object,
            job_active:Object
        },
        methods:{
            
                showModal() {
                this.open = true;
                },
                async handleOk () {
                this.confirmLoading = true;
                const formData={
                    active_status:this.job_actv
                }
                await axios
        .post("update-job-details-career-crekaa-admin/"+"update_active_status/"+this.job_number,formData)
        .then(response => {
            notification['success']({
                    message: 'Careers Job Active Status has been updated ',
                    description:'Careers job employement type has been updated successfully, You just need to refresh update page.',
                    placement: 'bottomRight',
                });
        })
        .catch(error => {
            console.log(error)
                notification['error']({
                    message: 'Unable to update Job Active status Type',
                    // description:'Unable To Fetch User Details...',
                    placement: 'bottomRight',
                });

                console.log("error Found unable to update")                        
                console.log(JSON.stringify(error.response.data))
                    console.log(JSON.stringify(error))
        })

        this.confirmLoading = false;
            this.open = false;
            window.location.reload()
            // this.confirmLoading = true;
            // setTimeout(() => {
            //     this.open = false;
            //     this.confirmLoading = false;
            // }, 2000);
            },


    
        }
    }
    </script>
    <style scoped>
    
    </style>
    
    
    