<template>
            <!-- {{applied_filtered_results }} -->
            <!-- <br> -->
            <!-- <a-row>
            <a-col :sm="24" :xs="24" :md="24" :lg="24">
                <video style="width:100%" :controls="false" muted autoplay>
    <source src="https://crekaa-2024.s3.ap-south-1.amazonaws.com/static/graphics/project+crekaa+promotional+project.mp4" type="video/mp4">
    Your browser does not support the video tag.
</video>

            </a-col>
        </a-row> -->
        <div style="min-height:100vh">

            <a-row >
        <a-col :sm="24" :xs="24" :md="24" :lg="0">
<!-- <br>
    
<br>     -->
<a-modal v-model:open="open_filterModal"  @ok="handleFilterOk">
        <template #title>
            Filter Your apearances
        </template>  
        
        <div class="sticky-header is-sticky" style="padding-top:30px;background-color:var(--card-color);z-index:2">
                       

<div v-if="filter.locations.length">
<h3>Locations Filtered :</h3>
<span v-for="loc in filter.locations">
                <span ><a-tag color="purple">{{loc}}</a-tag></span>
            </span>
        </div>
            <div v-if="filter.employmentTypes.length">
<h3>Employement Type Filtered :</h3>
<span v-for="et in filter.employmentTypes">
                <span ><a-tag color="pink">{{et}}</a-tag></span>
            </span>
        </div>
            
<div v-if="filter.workAreas.length">
<h3>Work Areas :</h3>
<span v-for="wa in filter.workAreas">
                <span ><a-tag color="orange">{{wa}}</a-tag></span>
            </span>
        </div>
            
            <div v-if="filter.teams.length">
<h3>Teams Filtered :</h3>
<span v-for="tea in filter.teams">
                <span ><a-tag color="cyan">{{tea}}</a-tag></span>
            </span>
        </div>
            

                        <a-collapse :bordered="false" style="padding:20px ">
                            <template #expandIcon="{ isActive }">
                                <RightOutlined :rotate="isActive ? 90 : 0" />
                            </template>
                            <a-collapse-panel key="1" header="Locations"
                                style="border-radius: 4px;padding-bottom: 24px;border: 0;overflow: hidden;text-align:start">

                                <a-checkbox v-model="filter.locations"  @change="filterLocations(loc)"
                                    style="width:100%;height:30px" v-for="loc in locations_AVAILABLE">{{loc}}</a-checkbox>
                              
                            </a-collapse-panel>
                            <a-collapse-panel key="2" header="Employement Type"
                                style="border-radius: 4px;padding-bottom: 24px;border: 0;overflow: hidden;text-align:start">
                                <a-checkbox v-model="filter.employmentTypes"  @change="filterEmployementType('Permanent Full-Time')" style="width:100%;height:30px">Permanent Full-Time
                                </a-checkbox><br>
                                <a-checkbox v-model="filter.employmentTypes"  @change="filterEmployementType('Intern (Full Time)')" style="width:100%;height:30px">Intern (Full Time)
                                </a-checkbox><br>
                                <a-checkbox v-model="filter.employmentTypes"  @change="filterEmployementType('Temporary Full-Time')" style="width:100%;height:30px">Temporary Full-Time
                                </a-checkbox><br>
                            </a-collapse-panel>
                            <a-collapse-panel key="3" header="Work Areas"
                                style="border-radius: 4px;padding-bottom: 24px;border: 0;overflow: hidden;text-align:start">
                                
                                
                                <a-checkbox v-model="filter.workAreas" @change="filterWorkAreas(wa)" style="width:100%;height:30px;margin-bottom: 14px;" v-for="wa in work_areas_available">{{ wa }}
                                 </a-checkbox>
                            </a-collapse-panel>
                            <a-collapse-panel key="4" header="Teams"
                                style="border-radius: 4px;padding-bottom: 24px;border: 0;overflow: hidden;text-align:start">
                                <a-checkbox v-model="filter.teams" @change="filterTeams(job)" style="width:100%;height:30px;margin-bottom: 14px;" v-for="job in job_teams_available">{{ job }}
                                 </a-checkbox>
                                

                            </a-collapse-panel>



                            <!-- <a-collapse-panel key="4" header="Keywords" style="background: #f7f7f7;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden">
      <p>{{ text }}</p>
    </a-collapse-panel> -->
                        </a-collapse>
                        <a-button  @click="clear_allFilters()" style="width:90%"> Clear Filters</a-button>
                    </div>
    </a-modal>
            <!--<div class="card-main-search-mobile">
                 <h1 class="title-search-mobile"> Search Jobs & Task at Boulotman</h1>
                <a-input-search class='input-search-mobile' size="large" placeholder="Search Jobs  & Tasks Here..."
                    style="border-radius:10px;"> </a-input-search>
                </div> -->
                <a-float-button @click="showFilterModal" >
                    <template #icon >
                        <ion-icon name="filter-outline"></ion-icon>
                    </template>
                
                </a-float-button>
               <br>
               <br>
                <a-row class="sticky-header is-sticky card-main-search-mobile" style="z-index:2 ;
    /* background-image: url('https://images.unsplash.com/photo-1507679799987-c73779587ccf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2071&q=80');
    background-size: 100% auto;height:130px; */
    ">
                        <a-col :sm="24" :xs="24" :md="24" :lg="0">
                            <h1 style="color:rgb(0, 0, 0);padding-top:70px ; font-family: 'Alkatra';"> Search Careers & skills at Crekaa</h1>
                        </a-col>
                        <a-col :sm="24" :xs="24" :md="24" :lg="0"> <a-input-search size="medium" style="width:95%"
                                placeholder="Search skills here..."></a-input-search></a-col>
                    </a-row>
<div v-if="filter.locations.length">
<h3>Locations Filtered :</h3>
<span v-for="loc in filter.locations">
                <span ><a-tag color="purple">{{loc}}</a-tag></span>
            </span>
        </div>
            
            <div v-if="filter.employmentTypes.length">
<h3>Employement Type Filtered :</h3>
<span v-for="et in filter.employmentTypes">
                <span ><a-tag color="pink">{{et}}</a-tag></span>
            </span>
        </div>
            
<div v-if="filter.workAreas.length">
<h3>Work Areas :</h3>
<span v-for="wa in filter.workAreas">
                <span ><a-tag color="orange">{{wa}}</a-tag></span>
            </span>
        </div>
            
            <div v-if="filter.teams.length">
<h3>Teams Filtered :</h3>
<span v-for="tea in filter.teams">
                <span ><a-tag color="cyan">{{tea}}</a-tag></span>
            </span>
        </div>
            
          
        
            <div v-if="cards_all_careers.length" >

                <div style="padding:5px">
                    <card_crekaa_career v-for="job in cards_all_careers" v-bind:jd="job"/>
                </div>
                    </div>
                    <div v-else style="display:flex;justify-content: center;">
                        <div>
                            <a-empty   image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
    :image-style="{
      height: '100px',
      marginTop:'100px'
    }" > <template #description>
      <span>
       No Positions Available... 
      </span>
    </template></a-empty>
                        <!-- <lottie-player src="https://lottie.host/155cd823-15ac-4e17-9ebf-e4a196bd5037/2ROlLcUU2w.json"
                         background="#FFFFFF" speed="1" style="width: 300px; height: 300px" 
                         loop  autoplay direction="1" mode="normal"></lottie-player>
                        <h2> No results found</h2> -->
                        </div>
                        </div>


        </a-col>
    </a-row>

    <a-row>
        
        <a-col :sm="0" :xs="0" :md="0" :lg="24">
            <br>
            <br>
            <div class="card-main-search" >
                <a-row>
                    <a-col :sm="24" :xs="24" :md="8" :lg="8" style="text-align:start;padding-left:20px">
                        <a-card style="margin-top:80px;">
                        
                            <h1 class="title-search"> Careers at Crekaa</h1>
                            
                            <p style="font-size: 15px;max-width:800px;font-weight: bold; text-align:start;">
                                What are you striving towards? Whether you plan to work in technology or developement we'll bet there's a way you can do it
                                here. Apply your skills alongside uniquely talented people in an environment where you’ll be supported along the way. 
                                
                            </p>
                            </a-card>
            </a-col>
            <a-col :sm="24" :xs="24" :md="16" :lg="16">
                <h1 class="title-search" style="margin-top: 80px;"> Search Skills...</h1>

                <a-input-search class='input-search' size="large" placeholder="Search career at here..."
                                   style="border-radius:10px;"> </a-input-search>
            </a-col>
        </a-row>
                
            </div>
            <br>

            <a-row style="max-width:1400px;margin:auto">
                <a-col :sm="0" :xs="0" :md="0" :lg="6">
                    <div class="sticky-header is-sticky" style="padding-top:70px;z-index:2">
                        <h1>Refine your search </h1>

                        <a-collapse :bordered="false" style="background: var(--card-color);padding:20px " >
                            <template #expandIcon="{ isActive }">
                                <RightOutlined :rotate="isActive ? 90 : 0" />
                            </template>
                            <a-collapse-panel key="1" header="Locations" 
                                style="background-color:var(--hover-slide-button);border-radius: 4px;margin-bottom: 15px;border: 0;overflow: hidden;text-align:start">

                                
                                        <a-checkbox v-model="filter.locations"  @change="filterLocations(loc)" v-for="loc in locations_AVAILABLE"
                                            style="width:100%;height:30px">{{loc}}</a-checkbox>
                                
                            </a-collapse-panel>
                            <a-collapse-panel key="2" header="Employement Type"
                                style="background-color:var(--hover-slide-button);border-radius: 4px;margin-bottom: 15px;border: 0;overflow: hidden;text-align:start">
                                <a-checkbox v-model="filter.employmentTypes"  @change="filterEmployementType('Permanent Full-Time')" style="width:100%;height:30px">Permanent Full-Time
                                </a-checkbox><br>
                                <a-checkbox v-model="filter.employmentTypes"  @change="filterEmployementType('Intern (Full Time)')" style="width:100%;height:30px">Intern (Full Time)
                                </a-checkbox><br>
                                <a-checkbox v-model="filter.employmentTypes"  @change="filterEmployementType('Temporary Full-Time')" style="width:100%;height:30px">Temporary Full-Time
                                </a-checkbox><br>
                            </a-collapse-panel>
                            <a-collapse-panel key="3" header="Work Areas"
                                style="background-color:var(--hover-slide-button);border-radius: 4px;margin-bottom: 15px;border: 0;overflow: hidden;text-align:start">
                                
                                
                                <a-checkbox v-model="filter.workAreas" @change="filterWorkAreas(wa)" style="width:100%;height:30px;margin-bottom: 14px;" v-for="wa in work_areas_available">{{ wa }}
                                 </a-checkbox>
                            </a-collapse-panel>
                            <a-collapse-panel key="4" header="Teams"
                                style="background-color:var(--hover-slide-button);border-radius: 4px;margin-bottom: 15px;border: 0;overflow: hidden;text-align:start">
                                <a-checkbox v-model="filter.teams" @change="filterTeams(job)" style="width:100%;height:30px;margin-bottom: 14px;" v-for="job in job_teams_available">{{ job }}
                                 </a-checkbox>
                                

                            </a-collapse-panel>



                            <!-- <a-collapse-panel key="4" header="Keywords" style="background: #f7f7f7;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden">
      <p>{{ text }}</p>
    </a-collapse-panel> -->
                        </a-collapse>
                        <br>
                        <a-button  @click="clear_allFilters()" style="width:90%"> Clear Filters</a-button>
                    </div>
                </a-col>
                <a-col :sm="0" :xs="0" :md="0" :lg="18">
                    <a-row class="sticky-header is-sticky" style="padding-top:70px;background-color:var(--card-color);z-index:2;border-radius:10px">
                        <a-col :sm="0" :xs="0" :md="0" :lg="8">
                            <h1> Search Skill </h1>
                        </a-col>
                        <a-col :sm="0" :xs="0" :md="0" :lg="12"> <a-input-search size="medium" style="width:100%"
                                placeholder="Search skill here..."></a-input-search></a-col>
                    </a-row>
                    <br>
                    <br>
<div v-if="filter.locations.length">
<h3>Locations Filtered :</h3>
<span v-for="loc in filter.locations">
                <span ><a-tag color="purple">{{loc}}</a-tag></span>
            </span>
            <br>
        </div>
            <div v-if="filter.employmentTypes.length">
<h3>Employement Type Filtered :</h3>
<span v-for="et in filter.employmentTypes">
                <span ><a-tag color="pink">{{et}}</a-tag></span>
            </span>
            <br>
        </div>
<div v-if="filter.workAreas.length">
<h3>Work Areas :</h3>
<span v-for="wa in filter.workAreas">
                <span ><a-tag color="orange">{{wa}}</a-tag></span>
            </span>
            <br>
        </div>
            <div v-if="filter.teams.length">
<h3>Teams Filtered :</h3>
<span v-for="tea in filter.teams">
                <span ><a-tag color="cyan">{{tea}}</a-tag></span>
            </span>
            <br>
        </div>

                    <div v-if="cards_all_careers.length">
                        <div style="padding:10px">
                            <card_crekaa_career v-for="job in cards_all_careers" v-bind:jd="job"/>
                        </div>
                    </div>
                    <div v-else style="display:flex;justify-content: center;">
                        <div>
                            <a-empty   image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
    :image-style="{
      height: '100px',
      marginTop:'100px'
    }" > <template #description>
      <span>
       No Positions Available... 
      </span>
    </template></a-empty>
                        <!-- <lottie-player src="https://lottie.host/155cd823-15ac-4e17-9ebf-e4a196bd5037/2ROlLcUU2w.json"
                         background="#FFFFFF" speed="1" style="width: 300px; height: 300px" 
                         loop  autoplay direction="1" mode="normal"></lottie-player>
                        <h2> No results found</h2> -->
                        </div>
                        </div>



<!-- 
                    <a-pagination v-model:pagination_current="pagination_current" :total="500">
                        <template #itemRender="{ type, originalElement }">
                            <a v-if="type === 'prev'">Previous</a>
                            <a v-else-if="type === 'next'">Next</a>
                            <component :is="originalElement" v-else></component>
                        </template>
                    </a-pagination> -->




                </a-col>

            </a-row>

        </a-col>
    </a-row>


    <br>
    <br>
    <br>
</div>

</template>
<script>
import { RightOutlined, AimOutlined, CodeOutlined, FileTextOutlined, FileUnknownOutlined, PartitionOutlined, ScheduleOutlined, RiseOutlined } from '@ant-design/icons-vue'
import axios from 'axios'
import { notification } from 'ant-design-vue'
import { Empty } from 'ant-design-vue';
  import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
  import card_crekaa_career from '@/components/careers/card_crekaa_career.vue'
export default {
    name: "crekaa_careers",
    created(){
    document.title = "Careers - Crekaa";
  },
  head() {
    return {
      title: "Careers - Crekaa",
      meta: [
        { hid: 'description', name: 'description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'author', name: 'author', content: "Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        
        // <!-- Facebook Open Graph tags -->
        { hid: 'og:url', property: 'og:url', content: "https://crekaa.com/" },
        { hid: 'og:type', property: 'og:type', content: "website" },
        { hid: 'og:title', property: 'og:title', content: "Crekaa, Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'og:description', property: 'og:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'og:image', property: 'og:image', content: "../../assets/logo.png" },
        // Add more meta tags as needed

        // <!-- Twitter Card tags -->
        { hid: 'twitter:card', name: 'twitter:card', content: "summary_large_image" },
        { hid: 'twitter:title', name: 'twitter:title', content: "Crekaa , Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'twitter:description', name: 'twitter:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'twitter:image', name: 'twitter:image', content: "../../assets/logo.png" },

      ]
    };
  },
    data() {
        return {
            pagination_current: 1,
            cards_all_careers: [],
            applied_filtered_results:[],
            delta: '',
            content: '',
            simpleImage :Empty.PRESENTED_IMAGE_SIMPLE,
            filter: {
                locations: [],
                employmentTypes: [],
                workAreas: [],
                teams: [],
            },

            // use  && filter.locations = [] && filter.employmentTypes= []  &&  filter.workAreas= [] && filter.teams= [] to renove the available filters 

            // centeral and east afrika countries 
            locations_AVAILABLE:["Remote","India" ],
          
            // teams available 
            job_teams_available:["Advanced Analytics Group (AAG)","Crekaa Marketing","Executive/Manager Assistant","Expert Consulting","Finance","General Consulting","Global & Local Operations","Innovation & Design","Legal/Risk","Marketing Consulting by FRWD","New Ventures","NextGen Software Solutions","Product, Practice, and Knowledge (PPK)","Research & Data Services (RDS)","Talent Acquisition","Talent Management","Technology Solutions Group (TSG)"
            ],
            work_areas_available:[
                "Analytics, Data, & Research", "Business Operations","Administrative","Finance","Legal/Risk","UI Design","Graphic Design","Management Consulting","Marketing","Product Management & Innovation","Talent & Human Resources","Technology & Engineering","Civil Engineer (What is your expertise)","Computer Engineer (What is your major)","Human Resource","Front Desk Assistant","Secretary","Accounting Secretary","Communication Manager",
            ],
            // modal settings open and close 
            open_filterModal:false,
            showFilterModal : () => {
                this.open_filterModal = true;
            },
            handleFilterOk : () => {
                this.open_filterModal = false;
            }
        }
    },

    components: {
        card_crekaa_career,
        RightOutlined,
        AimOutlined,
        CodeOutlined,
        FileTextOutlined,
        FileUnknownOutlined,
        PartitionOutlined,
        ScheduleOutlined,
        RiseOutlined
    },
    mounted() {
        this.fetchOpportinities()
    },
  
    computed: {
    convertedHTML() {
      const converter = new QuillDeltaToHtmlConverter(this.delta.ops, {});
      return converter.convert();
    },
  },
    methods: {
        clear_allFilters(){
            
            this.filter.locations= [],
            this.filter.employmentTypes= []
            this.filter.workAreas= []
            this.filter.teams= []
            this.checked=false
            this.cards_all_careers = this.applied_filtered_results
        },

        filterLocations(loccation){ 
            const index = this.filter.locations.indexOf(loccation);

            if (index === -1) {
            // Location not found, add it
            this.filter.locations.push(loccation);
            } else {
            // Location found, remove it
            this.filter.locations.splice(index, 1);
            }

            this.cards_all_careers = this.applied_filtered_results.filter(item =>
                item.locations.some(location => this.filter.locations.includes(location.country_name))
            );
                if (!this.cards_all_careers.length || this.cards_all_careers == []){
                    this.cards_all_careers = []
                }
            // console.log(loccation)
            // console.log(this.filter.locations)
            // console.log(this.cards_all_careers)
        },
        filterEmployementType(employmentTypes){ 
            const index = this.filter.employmentTypes.indexOf(employmentTypes);

            if (index === -1) {
            // Location not found, add it
            this.filter.employmentTypes.push(employmentTypes);
            } else {
            // Location found, remove it
            this.filter.employmentTypes.splice(index, 1);
        }

//         this.cards_all_careers = this.applied_filtered_results.filter(item =>
//   item.work_areas.some(work_areas =>
//     this.filter.work_areas.includes(work_areas.name_work_area)
//   )
// );


this.cards_all_careers = this.applied_filtered_results.filter(item =>
  this.filter.employmentTypes.includes(item.employement_type)
);

                if (!this.cards_all_careers.length || this.cards_all_careers == []){
                    this.cards_all_careers = []
                }
        },
        filterWorkAreas(wa){ 
            const index = this.filter.workAreas.indexOf(wa);

            if (index === -1) {
            // Location not found, add it
            this.filter.workAreas.push(wa);
            } else {
            // Location found, remove it
            this.filter.workAreas.splice(index, 1);
            }

            this.cards_all_careers = this.applied_filtered_results.filter(item =>
                item.work_areas.some(wa => this.filter.workAreas.includes(wa.name_work_area))
            );
                if (!this.cards_all_careers.length || this.cards_all_careers == []){
                    this.cards_all_careers = []
                }
            // console.log(loccation)
            // console.log(this.filter.locations)
            // console.log(this.cards_all_careers)
        
        },
        filterTeams(team){ 
            const index = this.filter.teams.indexOf(team);

            if (index === -1) {
            // Location not found, add it
            this.filter.teams.push(team);
            } else {
            // Location found, remove it
            this.filter.teams.splice(index, 1);
            }

            this.cards_all_careers = this.applied_filtered_results.filter(item =>
                item.teams.some(team => this.filter.teams.includes(team.team_name))
            );
                if (!this.cards_all_careers.length || this.cards_all_careers == []){
                    this.cards_all_careers = []
                }
            // console.log(loccation)
            // console.log(this.filter.locations)
            // console.log(this.cards_all_careers)
        
        },
        async fetchOpportinities() {
            this.$store.commit('setIsLoading', true)

            await axios
                .get("all-jobs-crekaa-career/")
                .then(response => {
                    // console.log(response);
                    // console.log("------------------------------------Got Response------------------------------------");
                    // console.log(response.data);

                    this.applied_filtered_results = response.data['all_jobs']
                    this.cards_all_careers =  this.applied_filtered_results
                    // this.$store.commit('addUserDetails', userData);
                    // console.log("--------------------------------------Done---------------------------------");
                    // console.log("the stored data : ",this.$store.state.userDetils)
                    // console.log("the stored User data : ",this.$store.state.userDetils['0']['user'])
                    // console.log("the stored User data : ",this.$store.state.userDetils[0].user.first_name);
                })
                .catch(error => {
                    notification['error']({
                        message: 'Fetch all jobs Failed',
                        description: 'Unable To Fetch all Jobs With Provided Credentials...',
                        placement: 'bottomRight',
                    });

                    // console.log("===========error at user details")                        
                    // console.log(JSON.stringify(error.response.data))
                    //     console.log(JSON.stringify(error))
                })


            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>
<style scoped>.title-search {
    font-size: 40px;
    
    /* color: rgb(0, 0, 0); */
    font-family: 'Alkatra';
}

.input-search {
    width: 600px;


}

.card-main-search {
    height: 400px;
    width: 100%;
    background-image: url('../../assets/caeers_crekaa/image_bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.title-search-mobile {
    font-size: 3em;
    padding-top: 100px;
    color: white;
    
}

.input-search-mobile {
    width: 90%;
}

.card-main-search-mobile {
    margin: 0;
    height:150px;
    padding: 0;
    background-image: url('../../assets/caeers_crekaa/image_bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100% auto; /* Set background-size to 100% width and auto height */
    background-repeat: no-repeat;
    background-position: center center;

}</style>