<template>

    <div :class="project.Contract.is_contract_terminated ?  'tabbed-pane-project-terminated-contract' : 'tabbed-pane-project'" >
      <!-- <div :class="true ?  'tabbed-pane-project-terminated-contract' : 'tabbed-pane-project'" > -->
        <h1 v-show="project.Contract.is_contract_terminated" >This Contract Has Been Terminated</h1>
        <!-- <h1 v-show="true" >This Contract Has Been Terminated</h1> -->
      <br>
      <a-row>
        <a-col :sm="16" :xs="16" :md="20" :lg="20" style="text-align:start">
        
      <h3><b>Order Number : {{ project.Contract.Contract_number }}</b></h3>
      <h3><b>Order Product Type : {{ project.Contract.Project_type }}</b></h3>
</a-col>
        <a-col :sm="8" :xs="8" :md="4" :lg="4">
          <router-link :to="'/user-order-details/' + project.Contract.Contract_number">
            <a-button size="large"> Project Details </a-button>
</router-link>

        </a-col>
      </a-row>
      <a-divider style="border-color:var(--devide-color)"></a-divider>
 <div class="card-container" >
    <a-tabs v-model:activeKey="activeKey" type="card" style="height:350px">
      <a-tab-pane key="1" >
        <template #tab>
            Project Details
        </template>
     
        <div style="text-align:start;background:rgba(128, 128, 128, 0.207);height:270px;border-radius:10px;padding:20px">
                          <span class="cb">Project Name: <b>{{project.Contract.Project_name}}</b></span><br>
                          <span class="cb">Project URL: <b>{{project.Contract.url}}</b></span><br>
                          <span class="cb">Project Type: <b>{{project.Contract.Contract_type}}</b></span><br>
                          <span class="cb">Complition Date : <b>{{project.Contract.Complition_date}}</b></span><br>
                          <span class="cb">Project Status : <b>{{project.Contract.Project_status}} </b></span><br>
                          <span class="cb">Used Technologies : <b>{{project.Contract.Used_frontend_technologies}} </b></span><br>
                        </div>
      </a-tab-pane>
      <a-tab-pane key="2" >
        <template #tab>
            Patches
        </template>
        <div  style="text-align:start;background:rgba(128, 128, 128, 0.207);height:270px;border-radius:10px;padding:20px;overflow-y:scroll">
          
         <div v-if="project.patches.length">

<p class="text-head-recent-patches">Here you can see the your recent 4 Bills</p> 
  <br>
  <div v-for="patch in project.patches" >
    <a-alert type="success"  show-icon style="padding:20px">
        <template #message>
      <span class="patch-head">
        <span class="patch-id"><b>Patch ID: {{patch.patch_number}}</b></span><br>
        <span class="patch-created" style="font-size:12px">Created Date: {{patch.date_created}}</span><br>
      </span>
      <span class="patch-body">
        <span class="card-patch-left-section">
           <span class="patch-type">Type:{{patch.patch_type}}</span><br>

           <span class="patch-status">Status: {{patch.patch_status}}</span><br>
        </span>
        <span class="card-patch-middle-section">
          <span class="title-card-patch"> Title: {{patch.Patch_title}}</span><br>
          <span class="description-card-patch">Description: {{patch.Patch_description}} </span>
        </span>
        <span class="card-patch-right-section">
          <span class="patch-created flx " style="justify-content:end">Complition Date: {{patch.Choose_date}}</span><br>
       
        </span>
      </span>
    </template >

    </a-alert>
    <br>
  </div>
  </div>
  <div v-else>
    <br><br>
    <a-empty   description="No Patches available." />
   </div>
</div>
    
      </a-tab-pane>
      <a-tab-pane key="3" >
        <template #tab>
            Order Details
        </template>
        <div  style="text-align:start;background:rgba(128, 128, 128, 0.207);height:270px;border-radius:10px;padding:20px">


<span class="cb">Order Number : <b>{{project.Contract.Contract_number}}</b></span><br>
<span class="cb">Order Date: <b>{{project.Contract.date_created}}</b></span><br>
<span class="cb">Order Plan : <b>{{project.Contract.Project_type}} </b></span><br>
</div>
      </a-tab-pane>
      <a-tab-pane key="4" >
        <template #tab>
            Client Details
        </template>
        <div  style="text-align:start;background:rgba(128, 128, 128, 0.207);height:270px;border-radius:10px;padding:20px">

<span class="cb">Name : <b>{{project.client_details[0].Client_name}} </b></span><br>
<span class="cb">Company Name : <b>{{project.client_details[0].Company_name}}</b></span><br>
<span class="cb">Address : <b> {{project.client_details[0].Address}} </b></span><br>
<span class="cb">Phone : <b>{{project.client_details[0].Phone}} </b></span><br>
<span class="cb">Mail : <b>{{project.client_details[0].Mail}} </b></span><br>
<span class="cb">Date : <b>{{project.client_details[0].date_created}} </b></span><br>
</div>
      </a-tab-pane>
      <a-tab-pane key="5" >
        <template #tab>
            Billing &amp; Payment
        </template>
        <div style="text-align:start;background:rgba(128, 128, 128, 0.207);height:270px;border-radius:10px;padding:20px;overflow-y:scroll ;">

          <div v-if="project.billing.length">
 
                        
<p class="text-head-recent-patches">Here you can see the recent 4 Bills</p> 
  <br>
  <div v-for="bill in project.billing">
  <router-link :to="'/bill-details/' + bill.bill_number" style="color:var(--text-color)">

    <a-alert type="success" show-icon style="padding:20px">
        <template #message>
      <span class="patch-head">
        <span class="patch-id"><b>Bill ID: {{bill.bill_number}}</b></span><br>
        <span class="patch-created" style="font-size:12px">Created Date: {{bill.date_created}}</span><br>
      </span>
      <span class="patch-body">
        <span class="card-patch-left-section">
           <span class="patch-type">Type:{{bill. bill_description}}</span><br>

           <span class="patch-status">Status: {{bill.Billing_status}}</span><br>
        </span>
        <span class="card-patch-middle-section">
          <!-- <span class="title-card-patch"> {{bill.bill_description}}</span><br> -->
          <span class="description-card-patch">Used Services: {{bill.Used_Services}}   </span><br>
        </span>
        <span class="card-patch-right-section">
          <span class="patch-created flx " style="justify-content:end">Complition Date: {{bill.Choose_Month}}</span><br>
       
        </span>
      </span>
    </template >

    </a-alert>
  </router-link>

  <br>
</div>
   
</div>
<div v-else>
    <br><br>
    <a-empty   description="No Billings available." />
   </div>

  </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</div>
<br>
</template>
<script>
export default{
    name:"user_project_details_tabbed_pane",
    data(){
        return{
            activeKey:"1"
        }
    },
    props:{
        project:Object
    }
}
</script>
<style scoped>
.tabbed-pane-project{
    padding:10px;
    background-color: var(--card-color);
    border-radius:20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

}
.tabbed-pane-project-terminated-contract{
    padding:10px;
    background-color: rgb(255, 135, 135);
    border-radius:20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

}
.card-container p {
  margin: 0;
}
.card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -16px;
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  background: var(--card-color);
}
.card-container > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #fff;
  border-color: #fff;
}
#components-tabs-demo-card-top .code-box-demo {
  padding: 24px;
  overflow: hidden;
  background: #f5f5f5;
}
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -8px;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
[data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
  background: #000;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  background: #141414;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #141414;
  border-color: #141414;
}
</style>