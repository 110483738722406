<template>
  <section style="">

      <a-row style="text-align: start">
    <a-col :sm="24" :xs="24" :md="24" :lg="5" >
      <main_left_pane_menu_user class="sticky-header is-sticky" />
    </a-col>
    <a-col :sm="24" :xs="24" :md="24" :lg="19">
      
      <div style="
          background-color: var(--notification-color-bg);
          /* padding-bottom: 40px; */
          /* height:100vh; */
          /* overflow-y: auto; */
        " >
        <br />
      <br /><br />
      <a-row >
                <a-col :sm="24" :xs="24" :md="24" :lg="8">
                  <div class="">
                    <editProfileCard/>
                  </div>
                </a-col>
                <a-col :sm="24" :xs="24" :md="24" :lg="16">
        <br>
        <div style="padding:5px">

        <section class="my-profile-card" >
          <a-tabs v-model:activeKey="activeTabKey" size="large">
            
            <a-tab-pane key="1">
              <template #tab>
                    <div style="display:flex;gap:10px">

                        <ion-icon style="font-size: 16px;margin-top:5px;color:green" name="lock-closed-outline"></ion-icon>
                        <span style="font-size: 14px;">Security</span>
                    </div>
              </template>
              <a-alert type="success" style="border-radius:5px;margin:5px">
                <template #message>
                    Note: For reliable account security, it is recommended to change your password every 2 to 3 months.
                </template>
              </a-alert>
              <a-card>
                <template #title>
                    <a-space >
                        <ion-icon style="font-size: 20px;color:green" name="lock-closed-outline"></ion-icon> <span style="font-size: 14px;">Change Password</span>
                    </a-space>
                </template>
                 <h1 class="mp-0">Current Password :</h1>
                <a-input-password v-model:value="current_password" class="input"/>
                <br>
                 <h1 class="mp-0">New Password :</h1>
                <a-input-password v-model:value="new_password" class="input"/>
                <br> 
                <br>
                 <h1 class="mp-0">Confirm New Password :</h1>
                <a-input-password v-model:value="confirm_password" class="input"/>
                <br> 
                <br>    <a-button
                type="primary"
            style="
              height: 40px;
              border-radius: 5px;
              width: 100%;
              max-width: 300px;
              font-size: 16px;"
              :loading="passwordIsChanging"
              @click="changePassword()"
              >
            Change Password
          </a-button>
              </a-card>
            </a-tab-pane>

            <a-tab-pane key="2">
              <template #tab>
                <span>
                 <div style="display:flex;gap:10px">

                        <ion-icon style="font-size: 16px;margin-top:5px;color:blue" name="notifications-outline"></ion-icon>
                        <span style="font-size: 14px;"> Notifications</span>
                    </div>
                </span>
              </template>
              <a-card>
                <template #title>
                    <a-space >
                        <ion-icon style="font-size: 20px;margin-top:5px;color:blue" name="notifications-outline"></ion-icon> <span style="font-size: 14px;">Notifications</span>
                    </a-space>
                </template>
                <div>
                    <h2>You will receive notification for the below selected items.</h2>
                    <a-table bordered :data-source="notifications" :columns="columns" size="large" :pagination="false">
    <template #bodyCell="{ column, text, record }">
      <template v-if="column.dataIndex === 'Email'">
         <a-checkbox :checked="text">{{text ? "Yes" : "No" }}</a-checkbox>
      </template>
      <template v-else-if="column.dataIndex === 'Notification'">
         <a-checkbox :checked="text">{{text ? "Yes" : "No" }}</a-checkbox>
      </template>
      <template v-else-if="column.dataIndex === 'App'">
         <a-checkbox :checked="text">{{text ? "Yes" : "No" }}</a-checkbox>
      </template>
      


    </template>
  </a-table>

                 </div>
                <br> 
                <br>    
                <a-space>

                    <a-button
                    type="primary"
                    style="
              height: 40px;
              border-radius: 5px;
              width: 100%;
              max-width: 300px;
              font-size: 16px;"
              >
              Save Changes
            </a-button>
            <a-button
            style="
              height: 40px;
              border-radius: 5px;
              width: 100%;
              max-width: 300px;
              font-size: 16px;"
              >
              Discard
            </a-button>
        </a-space>
        </a-card>
            </a-tab-pane>
<a-tab-pane key="3">
              <template #tab>
                <span>
                 <div style="display:flex;gap:10px">

                        <ion-icon style="font-size: 16px;margin-top:5px;color:blue" name="mail-unread-outline"></ion-icon>
                        <span style="font-size: 14px;"> Verification</span>
                    </div>
                </span>
              </template>
              <div style="display: flex;justify-content: center;flex-direction: column;align-items: center;">
                <img src="../../assets/Images/mail verified.png" alt="" style="width: 200px;"  srcset="">
                <h2 style="font-size: 18px;"><ion-icon style="font-size: 18px;margin-top:15px" name="shield-checkmark-outline"></ion-icon>&nbsp;Verified Mail</h2>
                  <h3 style="max-width:600px;width:100%">&nbsp;<b>Congratulations!</b> Your email associated with this account has been successfully verified. Our system has confirmed the authenticity of your email, granting you Grade 2 security privileges. You can now enjoy enhanced security measures and full access to your account with peace of mind.</h3>
                </div>




              <userProfileDashboard />
            </a-tab-pane>
            
            <a-tab-pane key="4">
              <template #tab>
                <span>
                  <android-outlined />
                  <span>
                 <div style="display:flex;gap:10px">

                        <ion-icon style="font-size: 16px;margin-top:5px;color:#ff3385" name="mail-unread-outline"></ion-icon>
                        <span style="font-size: 14px;"> Terms & Conditions</span>
                    </div>
                </span>
                
                </span>
              </template>
              <h2>CREKAA Reviews Terms & Conditions</h2>
<p>Reviews serve as a valuable medium for sharing your experience with our esteemed customers. By submitting reviews, you acknowledge and agree to the following terms and conditions:</p>
<ol>
  <li><strong>Accuracy and Authenticity:</strong> Reviews should accurately reflect your experience with CREKAA's services. Please ensure that your feedback is genuine and authentic.</li>
  <li><strong>Respectful Conduct:</strong> We encourage constructive criticism and feedback. However, reviews containing offensive language, discriminatory remarks, or personal attacks will not be tolerated.</li>
  <li><strong>Improvement Suggestions:</strong> Your reviews may include suggestions for improvement. We value your input and use it to enhance our services continuously.</li>
  <li><strong>Contact Us Requests:</strong> If you have specific inquiries or require assistance, please utilize the 'Contact Us' feature on our website or reach out to our customer support team directly. Reviews should focus on sharing experiences rather than requesting assistance.</li>
  <li><strong>Testimonials:</strong> By submitting a review, you consent to the use of your feedback as a testimonial on our website or other promotional materials. Your privacy will be respected, and personal information will not be disclosed without your consent.</li>
  <li><strong>Moderation:</strong> We reserve the right to moderate reviews to ensure compliance with our guidelines. Reviews deemed inappropriate or irrelevant may be removed without prior notice.</li>
  <li><strong>Ownership:</strong> You retain ownership of your reviews but grant CREKAA the right to use, reproduce, and display them for promotional purposes.</li>
</ol>
<p>By posting a review, you agree to abide by these terms and conditions. Thank you for contributing to the CREKAA community and helping us improve our services for everyone's benefit.</p>
            </a-tab-pane>
          </a-tabs>

          <!-- </form> -->
        </section>
        </div>
    </a-col>
    
</a-row>
<br />
      <br /><br /><br />
      <br />
</div>
</a-col>
</a-row>
</section>

</template>
<script>
import axios from "axios";
import main_left_pane_menu_user from "@/components/cards/main_left_pane_menu_user.vue";
// import main_left_pane_menu_user from "@/components/cards/main_left_pane_menu_user.vue";
import { notification } from "ant-design-vue";
// import userProfileDashboard from '@/components/user/updateProfile/updateProfileDashboard.vue'

import editProfileCard from "@/components/user/updateProfile/editProfileCard.vue";

export default {
  name: "edit_profile",

  created() {
    document.title = "Edit My Profile - Crekaa";
  },
  head() {
    return {
      title: "Crekaa",
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development",
        },
        {
          hid: "author",
          name: "author",
          content:
            "Cross-Platform Responsive Element Kit for Advanced Applications For Buisness",
        },

        // <!-- Facebook Open Graph tags -->
        { hid: "og:url", property: "og:url", content: "https://crekaa.com/" },
        { hid: "og:type", property: "og:type", content: "website" },
        {
          hid: "og:title",
          property: "og:title",
          content:
            "Crekaa, Cross-Platform Responsive Element Kit for Advanced Applications For Buisness",
        },
        {
          hid: "og:description",
          property: "og:description",
          content:
            "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development",
        },
        {
          hid: "og:image",
          property: "og:image",
          content:
            "../../assets/logo.png",
        },
        // Add more meta tags as needed

        // <!-- Twitter Card tags -->
        {
          hid: "twitter:card",
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          hid: "twitter:title",
          name: "twitter:title",
          content:
            "Crekaa , Cross-Platform Responsive Element Kit for Advanced Applications For Buisness",
        },
        {
          hid: "twitter:description",
          name: "twitter:description",
          content:
            "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development",
        },
        {
          hid: "twitter:image",
          name: "twitter:image",
          content:
            "../../assets/logo.png",
        },
      ],
    };
  },
  data() {
    return {
      activeTabKey: "1",
    //   table notification
     columns : [
  {
    title: 'Type',
    dataIndex: 'Type',
    width: '40%',
  },
  {
    title: 'Email',
    dataIndex: 'Email',
    width: '20%'
  },
  {
    title: 'Notification',
    dataIndex: 'Notification',
    width: '20%'
  },
  {
    title: 'App',
    dataIndex: 'App',
    width: '20%'
  },
],
notifications :[
  {
    key: '0',
    Type: 'New for you',
    Email: true,
    Notification: true,
    App: true,
  },
  {
    key: '1',
    Type: 'Product promiotions',
    Email: true,
    Notification: false,
    App: true,
  },
  {
    key: '2',
    Type: 'Changes in your account',
    Email: false,
    Notification: true,
    App: true,
  },
  {
    key: '3',
    Type: 'Bill & Purchase',
    Email: true,
    Notification: false,
    App: false,
  },
  {
    key: '4',
    Type: 'Credits',
    Email: true,
    Notification: true,
    App: true,
  },
],
      // sectionalised SOlutions:
      // modal Profile Picture & background
      open_profile_bg: false,
      open_profile_picture: false,
      open_edit_profile_Details_Modal: false,
      profile_image_selected: null,
      profile_bg_image_selected: null,

      open_ContactInfo: false,
      loading_Profilewebsites: false,

      url_main_media: this.$store.state.ROOT_BASE_URL,
      // main modifications
      open_EditProfile: false,
      loading_editProfile: false,

      // data
      imagePreview_bg: null,
      imagePreview: null,
      first_name: null,
      last_name: null,
      phone_number: null,
      dob: null,
      description: null,
      city: null,
      state: null,
      country: null,

    //   change password
    current_password:null,
new_password:null,
confirm_password:null,
passwordIsChanging:false
    };
  },
  components: {
    main_left_pane_menu_user,
    // userProfileDashboard
    editProfileCard
  },
  mounted() {
    this.fetchUserData();
  },

  methods: {
    async fetchUserData() {
      this.$store.commit("setIsLoading", true);
      axios
        .post("user-details/")
        .then((response) => {
          // console.log(response);
          // console.log(response.data);

          const discription = response.data["0"]["discription"];
          const city = response.data["0"]["city"];
          const state = response.data["0"]["state"];
          const country = response.data["0"]["country"];
          const profile_picture = response.data["0"]["profile_picture"];
          const profile_background = response.data["0"]["profile_background"];
          const user = response.data["0"]["user"];
          const userData = {
            discription: discription,
            city: city,
            state: state,
            country: country,
            profile_picture: profile_picture,
            profile_background: profile_background,
            user: user,
          };

          // console.log("------------------------------------userData------------------------------------", userData);
          this.$store.commit("addUserDetails", userData);
          this.getStoredData();

          // console.log("--------------------------------------Done---------------------------------");
          // console.log("the stored data : ",this.$store.state.userDetils)
          // console.log("the stored User data : ",this.$store.state.userDetils['0']['user'])
          // console.log("the stored User data : ",this.$store.state.userDetils[0].user.first_name);
        })
        .catch((error) => {
          // console.log("===========error at user details")
          console.log(JSON.stringify(error.response.data));
          console.log(JSON.stringify(error));
        });
      // this.$store.commit('setIsLoading',false)

      this.$store.commit("setIsLoading", false);
    },
    handleprofileUpdated() {
      this.open_profile_bg = false;
      this.open_profile_picture = false;
      this.$emit("profileUpdated", true);
    },
    showModal_profile_bg() {
      this.open_profile_bg = true;
    },
    handleFileChange_profile_bg(event) {
      if (this.profile_bg_image_selected) {
        this.profile_bg_image_selected = null;
      }
      console.log(event);
      const file = event.target.files[0];
      if (file) {
        // Create a URL for the selected file
        const url = URL.createObjectURL(file);
        this.profile_bg_image_selected = [
          {
            uid: file.uid,
            name: file.name,
            status: "done",
            url: url,
            originFileObj: file,
          },
        ];
      } else {
        this.profile_bg_image_selected = null;
      }
    },
    handleOk_profile_bg() {
      //   console.log(e)
      this.open_profile_bg = false;
    },
    handleCancel_edit_profile() {
      //   console.log(e)
      this.open_edit_profile_Details_Modal = false;
    },
    showModal_profile_picture() {
      this.open_profile_picture = true;
    },

    handleFileChange_profile_picture(event) {
      if (this.profile_image_selected) {
        this.profile_image_selected = null;
      }
      console.log(event);
      const file = event.target.files[0];
      if (file) {
        // Create a URL for the selected file
        const url = URL.createObjectURL(file);
        this.profile_image_selected = [
          {
            uid: file.uid,
            name: file.name,
            status: "done",
            url: url,
            originFileObj: file,
          },
        ];
      } else {
        this.profile_image_selected = null;
      }
    },
    handleOk_profile_picture() {
      //   console.log(e)
      this.open_profile_picture = false;
    },
    getStoredData() {
      this.first_name = this.$store.state.userDetils.length
        ? this.$store.state.userDetils[0].user.first_name
        : "";
      this.last_name = this.$store.state.userDetils.length
        ? this.$store.state.userDetils[0].user.last_name
        : "";
      this.phone_number = this.$store.state.userDetils.length
        ? this.$store.state.userDetils[0].user.phone_number
        : "";
      this.dob = this.$store.state.userDetils.length
        ? this.$store.state.userDetils[0].user.dob
        : "";
      this.description = this.$store.state.userDetils.length
        ? this.$store.state.userDetils[0].discription != null
          ? this.$store.state.userDetils[0].discription
          : "Unavailable"
        : "No Description";
      this.city = this.$store.state.userDetils.length
        ? this.$store.state.userDetils[0].city !== null
          ? this.$store.state.userDetils[0].city
          : "Unknown"
        : "Unavailable";
      this.state = this.$store.state.userDetils.length
        ? this.$store.state.userDetils[0].state !== null
          ? this.$store.state.userDetils[0].state
          : "Unknown"
        : "Unavailable";
      this.country = this.$store.state.userDetils.length
        ? this.$store.state.userDetils[0].country !== null
          ? this.$store.state.userDetils[0].country
          : "Unknown"
        : "Unavailable";
    },

    showModal_edit_profile_Details_Modal() {
      this.open_edit_profile_Details_Modal = true;
    },

    // handle here the files uploading for profile picture and profile image
    readURL(event, previewId) {
      const input = event.target;
      if (input.files && input.files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this[previewId] = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    async changePassword() {
        const formData= {current_password:this.current_password,
        new_password:this.new_password,
        confirm_password:this.confirm_password
    }
    if (
        this.new_password === null || this.confirm_password === null || (this.new_password != this.confirm_password)
      ) {
        return notification["info"]({
          message: "New password mismatch !",
          description: `Password that you have entered as new password has been mismatched please put the same password in both.`,
          placement: "bottomRight",
        });
      }
    //   this.$store.commit("setIsLoading", true);
    this.passwordIsChanging=true
      await axios
        .post("change-password-API/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response);
          // console.log(response.data);
          if(response.data['error']=== true){
            notification["error"]({
                message: "Unable to setup New Password !",
                description: `Your New Password Has not been changed Due to `+response.data['status'],
                placement: "bottomRight",
              });
          }
          else{
              notification["success"]({
                message: "New Password Saved !",
                description: `Your New Password Has been changed ...`,
                placement: "bottomRight",
              });
        }
      })
        .catch((error) => {
          // console.log("===========error at user details")
          console.log(JSON.stringify(error.response.data));
          console.log(JSON.stringify(error));
          notification["info"]({
            message: "Profile Not Saved !",
            description: `Your New Profile Modifications Have Not Been Saved, Please fill all the empty fields ...`,
            placement: "bottomRight",
          });
        });
        this.passwordIsChanging=false
    },
    async editProfile() {
      //   if (this.imagePreview_bg) {
      //     return notification["info"]({
      //       message: "Profile background image is not selected !",
      //       description: `Your New Profile Modifications Have Not Been Saved, Please fill all the empty fields ...`,
      //       placement: "bottomRight",
      //     });
      //   }
      //   if (this.imagePreview) {
      //     return notification["info"]({
      //       message: "Profile image is not selected !",
      //       description: `Your New Profile Modifications Have Not Been Saved, Please fill all the empty fields ...`,
      //       placement: "bottomRight",
      //     });
      //   }
      if (
        this.first_name === null ||
        this.first_name === "Unavailable" ||
        this.first_name === "Unknown"
      ) {
        return notification["info"]({
          message: "Please enter your first name !",
          description: `Your New Profile Modifications Have Not Been Saved, Please fill all the empty fields ...`,
          placement: "bottomRight",
        });
      }
      if (
        this.last_name === null ||
        this.last_name === "Unavailable" ||
        this.last_name === "Unknown"
      ) {
        return notification["info"]({
          message: "Please enter your last name !",
          description: `Your New Profile Modifications Have Not Been Saved, Please fill all the empty fields ...`,
          placement: "bottomRight",
        });
      }
      if (
        this.phone_number === null ||
        this.phone_number === "Unavailable" ||
        this.phone_number === "Unknown"
      ) {
        return notification["info"]({
          message: "Please enter your phone number !",
          description: `Your New Profile Modifications Have Not Been Saved, Please fill all the empty fields ...`,
          placement: "bottomRight",
        });
      }
      if (
        this.dob === null ||
        this.dob === "Unavailable" ||
        this.dob === "Unknown"
      ) {
        return notification["info"]({
          message: "Please enter your Date Of Birth !",
          description: `Your New Profile Modifications Have Not Been Saved, Please fill all the empty fields ...`,
          placement: "bottomRight",
        });
      }
      if (
        this.description === null ||
        this.description === "Unavailable" ||
        this.description === "Unknown"
      ) {
        return notification["info"]({
          message: "Please enter your profile description !",
          description: `Your New Profile Modifications Have Not Been Saved, Please fill all the empty fields ...`,
          placement: "bottomRight",
        });
      }
      if (
        this.city === null ||
        this.city === "Unavailable" ||
        this.city === "Unknown"
      ) {
        return notification["info"]({
          message: "Please enter your city name !",
          description: `Your New Profile Modifications Have Not Been Saved, Please fill all the empty fields ...`,
          placement: "bottomRight",
        });
      }
      if (
        this.state === null ||
        this.state === "Unavailable" ||
        this.state === "Unknown"
      ) {
        return notification["info"]({
          message: "Please enter your state name !",
          description: `Your New Profile Modifications Have Not Been Saved, Please fill all the empty fields ...`,
          placement: "bottomRight",
        });
      }
      if (
        this.country === null ||
        this.country === "Unavailable" ||
        this.country === "Unknown"
      ) {
        return notification["info"]({
          message: "Please enter your country name !",
          description: `Your New Profile Modifications Have Not Been Saved, Please fill all the empty fields ...`,
          placement: "bottomRight",
        });
      }
      const formData = {
        // imagePreview_bg: this.imagePreview_bg,
        // imagePreview: this.imagePreview,
        first_name: this.first_name,
        last_name: this.last_name,
        phone_number: this.phone_number,
        dob: this.dob,
        description: this.description,
        city: this.city,
        state: this.state,
        country: this.country,
      };

      this.$store.commit("setIsLoading", true);
      await axios
        .post("edit-my-profile/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response);
          // console.log(response.data);
          notification["success"]({
            message: "Profile Saved !",
            description: `Your New Profile Modifications Have Been Saved ...`,
            placement: "bottomRight",
          });
        })
        .catch((error) => {
          // console.log("===========error at user details")
          console.log(JSON.stringify(error.response.data));
          console.log(JSON.stringify(error));
          notification["info"]({
            message: "Profile Not Saved !",
            description: `Your New Profile Modifications Have Not Been Saved, Please fill all the empty fields ...`,
            placement: "bottomRight",
          });
        });
      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>
<style scoped>
.mp-0 {
  margin: 0;
  padding: 0;
  text-align: start;
  font-size: 14px;
}
main {
  display: flex;
  justify-content: center;
  padding-top: 50px;

  /* background-color: #fff9f9;
        background-color: hsla(0, 100%, 88%, 1);
        backdrop-filter: blur(75px);

        background-image:
            radial-gradient(at 80% 0%, hsla(189, 100%, 56%) 0px, transparent 50%),
            radial-gradient(at 0% 50%, hsla(355, 100%, 93%) 0px, transparent 50%),
            radial-gradient(at 80% 50%, hsla(340, 100%, 76%) 0px, transparent 50%),
            radial-gradient(at 0% 100%, hsla(269, 100%, 77%) 0px, transparent 50%),
            radial-gradient(at 0% 0%, hsla(343, 100%, 76%) 0px, transparent 50%); */
}

.my-profile-card {
  background-color: var(--card-color);
  backdrop-filter: blur(75px);

  max-width: 99%;
  width: 99%;
  /* height: 600px; */
  border-radius: 20px;
  padding: 30px;
}

.flx-resp {
  display: flex;
  gap: 20px;
}

.flx-column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.flx-row {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
}

/* profile-user cardend */

.profile_pict,
.profile-details {
  width: 50%;
}
.profile_pict {
  text-align: center;
}
.image-userprofile {
  width: 200px;
  border-radius: 100%;
  margin-top: -80px;
}
.image-userprofile-background {
  width: 100%;
  border-radius: 10px;
}
/* profile-user cardend */

h1 {
  font-size: 16px;
}
.input {
    width: 100%;
/* border: 1px solid grey; */
border-radius: 5px;
padding: 10px;
outline: none;
font-size: 16px;
background-color: rgba(255, 255, 255, 0.583);
transition: all 0.5s;
}
/* .input:hover {
  width: 99%;
  height: -90%;

  margin-top: 1%;
  margin-left: 1%;
} */
.textarea-description {
  width: 100%;
  height: 200px;
  /* border: 1px solid; */
  /* border-radius: 10px; */
  padding: 20px;
  /* outline: none; */
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.583);
  transition: all 0.5s;
}
.textarea-description:hover {
  width: 99%;
  margin-top: 1%;
  margin-left: 1%;
  height: 180px;
  /* border: none; */
  /* border-radius: 10px; */
  background-color: rgba(255, 255, 255, 0.583);
}
.data-user {
  outline: none;
  border: none;
  background-color: rgba(240, 255, 255, 0.74);
  padding: 10px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.5s;
}
.data-user:hover {
  /* margin:5px; */
  padding: 8px;
}
@media screen and (max-width: 400px) {
  h1 {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}
@media screen and (max-width: 720px) {
  main {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .flx-row {
    flex-direction: column;
  }
  .flx-row h1 {
    margin-bottom: 20px;
  }
  .profile_pict,
  .profile-details {
    width: 100%;
  }
  .image-userprofile {
    width: 100px;
    border-radius: 100%;
    margin-top: -80px;
  }
  .flx-resp {
    display: block;
    flex-direction: column;
  }
}

/* image */

.avatar-bg-upload {
  position: relative;
  width: 100%;
  border-radius: 10px;
}

.avatar-bg-upload .avatar-bg-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
}

.avatar-bg-upload .avatar-bg-edit input {
  display: none;
}

.avatar-bg-upload .avatar-bg-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.avatar-bg-upload .avatar-bg-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.avatar-bg-upload .avatar-bg-edit input + label:after {
  content: "";
  /* font-family: "FontAwesome"; */
  color: #757575;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.avatar-bg-upload .avatar-bg-preview {
  width: 100%;
  height: 200px;
  position: relative;
  /* border-radius: 100%; */
  /* border: 6px solid #f8f8f8; */
  /* box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); */
}

.avatar-bg-upload .avatar-bg-preview > img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

/* profile picture */
.avatar-upload {
  position: relative;
  max-width: 178px;
  /* margin:-50px  10px; */
  margin-top: -70px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.avatar-upload .avatar-edit input + label:after {
  content: "";
  /* font-family: "FontAwesome"; */
  color: #757575;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.avatar-upload .avatar-preview {
  width: 150px;
  height: 150px;
  object-fit: cover;

  position: relative;
  border-radius: 100%;
  /* border: 6px solid #f8f8f8; */
}

.avatar-upload .avatar-preview > img {
  width: 150px;
  height: 150px;
  object-fit: cover;

  border-radius: 100%;
  object-fit: cover;
}
</style>

<style scoped>
.card-img-top-phone-screen {
    width: 90px;
    height: 90px;
    position: absolute;
    left: 20px;
    border-radius: 100%;
    border: 3px solid white;
    /* margin: auto; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.card-img-top {
    width: 150px;
    height: 150px;
    position: absolute;
    left: 20px;
    border-radius: 100%;
    border: 3px solid white;
    /* margin: auto; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.mobile-card{
    background: white;
    border-radius:10px
}
.content-mobile-card{
    padding:10px;
   
}

.btn-2 {
    background:rgb(0,0,0,0.0522);cursor: pointer; padding:5px;border-radius: 10px; margin-top: 10px;
    
}
.btn-2:hover h4 {
   color:black;
   font-weight: bold;
    
}
.btn-2:hover {
  -webkit-animation: stripes-move 1s linear;
          animation: stripes-move 1s linear;
          /* background: rgb(0,55,255); */
        background: linear-gradient(306deg, rgba(0, 55, 255, 0.285) 0%, rgba(255,255,255,0.42629551820728295) 100%);

  /* background: repeating-linear-gradient(45deg, #82f6d8 0, #82f6d8 0.25em, transparent 0.25em, transparent 0.5em); */
  font-weight: 600;
}

.btn-3 {
    background:rgb(0,0,0,0.0522);cursor: pointer; padding:5px;border-radius: 10px; margin-top: 10px;
    
}
.btn-3:hover h4 {
   color:black;
   font-weight: bold;
    
}
.btn-3:hover {
  -webkit-animation: stripes-move 1s linear;
          animation: stripes-move 1s linear;
          /* background: rgb(0,55,255); */
        background: linear-gradient(306deg, rgba(51, 255, 0, 0.285) 0%, rgba(255,255,255,0.42629551820728295) 100%);

  /* background: repeating-linear-gradient(45deg, #82f6d8 0, #82f6d8 0.25em, transparent 0.25em, transparent 0.5em); */
  font-weight: 600;
}

.btn-4 {
    background:rgb(0,0,0,0.0522);cursor: pointer; padding:5px;border-radius: 10px; margin-top: 10px;
    
}
.btn-4:hover h4 {
   color:black;
   font-weight: bold;
    
}
.btn-4:hover {
  -webkit-animation: stripes-move 1s linear;
          animation: stripes-move 1s linear;
          /* background: rgb(0,55,255); */
        background: linear-gradient(306deg, rgba(255, 0, 106, 0.285) 0%, rgba(255,255,255,0.42629551820728295) 100%);

  /* background: repeating-linear-gradient(45deg, #82f6d8 0, #82f6d8 0.25em, transparent 0.25em, transparent 0.5em); */
  font-weight: 600;
}

.btn-5 {
    background:rgb(0,0,0,0.0522);cursor: pointer; padding:5px;border-radius: 10px; margin-top: 10px;
    
}
.btn-5:hover h4 {
   color:black;
   font-weight: bold;
    
}
.btn-5:hover {
  -webkit-animation: stripes-move 1s linear;
          animation: stripes-move 1s linear;
          /* background: rgb(0,55,255); */
        background: linear-gradient(306deg, rgba(183, 0, 255, 0.285) 0%, rgba(255,255,255,0.42629551820728295) 100%);

  /* background: repeating-linear-gradient(45deg, #82f6d8 0, #82f6d8 0.25em, transparent 0.25em, transparent 0.5em); */
  font-weight: 600;
}

@-webkit-keyframes stripes-move {
    10% {
    background-position: 10px 0px;
  }
  20% {
    background-position: 60px 0px;
  }
  40% {
    background-position: 120px 0px;
  }
  60% {
    background-position: 180px 0px;
  }
  80% {
    background-position: 240px 0px;
  }
  100% {
    background-position: 300px 0px;
  }
}

@keyframes stripes-move {   
    10% {
    background-position: 10px 0px;
  }
  20% {
    background-position: 60px 0px;
  }
  40% {
    background-position: 120px 0px;
  }
  60% {
    background-position: 180px 0px;
  }
  80% {
    background-position: 240px 0px;
  }
  100% {
    background-position: 300px 0px;
  }     
}
</style>