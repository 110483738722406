<template>
    <a-row style="text-align: start;">
        <!-- <a-col :sm="24" :xs="24" :md="5" :lg="5"> -->
        <!-- <main_left_pane_menu_user class="sticky-header is-sticky" /> -->
        <!-- </a-col> -->
        <a-col :sm="24" :xs="24" :md="24" :lg="24">
            <main style="min-height: 100vh; background-color: rgba(0, 255, 255, 0.245); padding-bottom:50px;">

                <br><br>
                <div id="headerimage"></div>
                <div class="innovice" style="z-index: 2;text-align: start;border: 1px solid var(--devide-color);">
                    <div class="company-section flx-inovice-inverse justify-content-space-between">
                        <div class="info flx-inovice gap10 mt10">
                            <div class="logo"></div>
                            <div class="content">
                                <h2>Crekaa Technologies</h2>
                                <p> crekaa.technologies@gmail.com <br>
                                    <span>Contract No.: </span>{{ bill ? bill.bill[0].fields.date_created : '' }}
                                </p>
                            </div>
                        </div><!--End Info-->

                        <div class="title">
                            <h1>Invoice </h1>
                            <p>Issued: {{ bill ? bill.bill[0].fields.date_created : '' }}<br>
                                Status: {{ bill ? bill.bill[0].fields.Choose_Month : '' }}<br>
                                Payment Status: <b><span class="label-status-bill "
                                        :class="getLabel(bill ? bill.bill[0].fields.Billing_status : '')">


                                        {{ bill ? bill.bill[0].fields.Billing_status : '' }}</span> </b>
                            </p>
                        </div>
                        <!-- End Title -->

                        <!-- crekaa -->
                    </div>
                    <div class="individual-section flx-inovice-clin justify-content-space-between">
                        <div class="info flx-inovice gap10 mt10">
                            <div class="logo-user">
                                <a-image :preview="false"
                                    :src="this.$store.state.userDetils.length ? this.$store.state.userDetils[0].profile_picture : ''"
                                    width="60px" height="60px" style="width:60px;height:60px;border-radius:60px;" alt="" />
                            </div>
                            <div class="content">
                                <h2>{{ this.$store.state.userDetils.length ?
                                    this.$store.state.userDetils[0].user.first_name + " " +
                                    this.$store.state.userDetils[0].user.last_name : '' }}</h2>
                                <p>{{ this.$store.state.userDetils.length ? this.$store.state.userDetils[0].user.email :
                                    '' }}<br>
                                    {{ bill ? bill.client_det[0].fields.Client_name : "" }}<br>
                                </p>
                            </div>
                        </div><!--End Info-->

                        <div class="title">
                            <h2 class="proj-title">Project Description</h2>
                            <p class="proj-desc">{{ bill ? bill.bill_contract_description : '' }}.</p>
                            <!-- <p class="proj-desc">this os asd this os asd this os asd this os asd this os asd this os asd
                                this os asd this os asd this os asd this os asd this os asd this os asd .</p> -->

                        </div><!--End Title-->
                    </div>

                    <div v-if="bill ? bill.bill[0].fields.is_auto_generated : false" style="width:100%">

                        <table style="width:100%">
                            <tr class="tabletitle">
                                <td class="item">
                                    <h2>Server Upcoming Month </h2>
                                </td>
                                <!-- <td class="Hours"><h2>Hours</h2></td> -->
                                <td class="subtotal">
                                    <h2>Status</h2>
                                </td>
                                <td class="Rate">
                                    <h2>Price</h2>
                                </td>
                            </tr>

                            <tr class="service">
                                <td class="tableitem">
                                    <p class="itemtext">Monthly Server Backup, Maintainance & Retaintation Cost.</p>
                                </td>
                                <!-- <td class="tableitem"><p class="itemtext">{{5}}</p></td> -->
                                <td class="tableitem">
                                    <p class="itemtext">{{ bill ? bill.bill[0].fields.Billing_status : '' }}</p>
                                </td>
                                <td class="tableitem">
                                    <p class="itemtext">₹2500</p>
                                </td>
                            </tr>

                            <tr class="tabletitle">
                                <td class="item">
                                    <h2>Patch Title</h2>
                                </td>
                                <!-- <td class="Hours"><h2>Hours</h2></td> -->
                                <td class="subtotal">
                                    <h2>Status</h2>
                                </td>
                                <td class="Rate">
                                    <h2>Price</h2>
                                </td>
                            </tr>

                            <!-- {% for patch in patches %} -->
                            <tr class="service" v-for="patch in bill.patches">
                                <td class="tableitem">
                                    <p class="itemtext">{{ patch.patch__Patch_title }}</p>
                                </td>
                                <!-- <td class="tableitem"><p class="itemtext">{{5}}</p></td> -->
                                <td class="tableitem">
                                    <p class="itemtext">{{ bill ? bill.bill[0].fields.Billing_status : '' }}</p>
                                </td>
                                <td class="tableitem">
                                    <p class="itemtext">₹{{ patch.patch__patch_payment__payment_amount }}</p>
                                </td>
                            </tr>
                            <!-- {% endfor %} -->


                            <!-- {% if bill.Billing_status == "Completed" %} -->
                            <!-- {{ bill.bill[0].fields.Billing_status  }} -->



                            <!-- <div  v-if="bill.bill[0].fields.Billing_status == 'Completed' "> -->
                            <!-- <div v-if="false"> -->
                            <tr class="tabletitle" v-if="bill.bill[0].fields.Billing_status == 'Completed'">
                                <td></td>

                                <td class="Rate">
                                    <h2>Paid Bill</h2>
                                </td>
                                <td class="payment"></td>

                            </tr>
                            <tr v-if="bill.bill[0].fields.Billing_status == 'Completed'">

                                <td>
                                    <p>1 Credit = ₹7 </p>
                                    <p>Maximum 100 Credits has been applied</p>
                                </td>
                                <td class="Rate">
                                    <h2>Applied Credits</h2>
                                </td>
                                <td class="payment">
                                    <h2>₹{{ bill.bill[0].fields.credits_applied }}</h2>
                                </td>
                            </tr>



                            <tr class="tabletitle" v-if="bill.bill[0].fields.Billing_status == 'Completed'">

                                <td>
                                    <p>INR Credits Applied :</p>
                                </td>

                                <td class="Rate">
                                    <h2>Total Credits Amount Applied</h2>
                                </td>
                                <td class="payment">
                                    <h2>₹{{ bill.bill[0].fields.credits_applied_inr_discount }}</h2>
                                </td>

                            </tr>
                            <tr v-if="bill.bill[0].fields.Billing_status == 'Completed'">

                                <td>
                                    <p>Credits Not Applied :</p>
                                </td>
                                <td class="Rate">
                                    <h2>Total Amount</h2>
                                </td>
                                <td class="payment">
                                    <h2>₹{{ bill.tatal_ammount }}</h2>
                                </td>
                            </tr>

                            <tr v-if="bill.bill[0].fields.Billing_status == 'Completed'">

                                <td>
                                    <p>Amount Paid After Credits Applied :</p>
                                </td>
                                <td class="Rate">
                                    <h2>Final Amount </h2>
                                </td>
                                <td class="payment">
                                    <h2>₹{{ bill.bill[0].fields.Final_Amount_Paid }}</h2>
                                </td>
                            </tr>
                            <!-- </div> -->

                            <!-- {% else  %} -->
                            <!-- <div v-else style="width:100%"> -->
                            <!-- <table  v-else style="width:100%"> -->


                            <tr v-if="bill.bill[0].fields.Billing_status != 'Completed'" class="tabletitle">
                                <td></td>

                                <td class="Rate">
                                    <h2>Active Credits</h2>
                                </td>
                                <td class="payment">
                                    <h2>{{ bill ? (bill.active_credits_ammount ? bill.active_credits_ammount : '0') : '0' }}
                                    </h2>
                                </td>

                            </tr>

                            <tr v-if="bill.bill[0].fields.Billing_status != 'Completed'">

                                <td>
                                    <p>1 Credit = ₹7 </p>
                                    <p>Maximum 100 Credits has been applied</p>
                                </td>
                                <td class="Rate">
                                    <h2>Active Credits Amount In INR</h2>
                                </td>
                                <td class="payment">
                                    <h2>₹{{ bill.active_credits_ammount_to_inr }}</h2>
                                </td>
                            </tr>


                            <tr class="tabletitle" v-if="bill.bill[0].fields.Billing_status != 'Completed'">

                                <td>
                                    <p>Before Credits Applied :</p>
                                </td>

                                <td class="Rate">
                                    <h2>Total</h2>
                                </td>
                                <td class="payment">
                                    <h2>₹{{ bill.tatal_ammount }}</h2>
                                </td>

                            </tr>
                            <tr v-if="bill.bill[0].fields.Billing_status != 'Completed'">

                                <td>
                                    <p>After Credits Applied :</p>
                                </td>
                                <td class="Rate">
                                    <h2>Final Billing Amount</h2>
                                </td>
                                <td class="payment">
                                    <h2>₹{{ bill.remaining_billing_ammount }}</h2>
                                </td>

                            </tr>
                            <!-- </table> -->

                            <!-- </div> -->

                            <!-- {% endif  %} -->

                        </table>
                    </div>
                    <div v-else v-show="bill">

                        <p class="desc-bill">
                            {{ bill ? bill.bill[0].fields.bill_description : "" }}
                        </p>


                        <!-- {% if bill.Billing_status == "Completed" %} -->
                        <div v-if="bill ? bill.bill[0].fields.Billing_status == 'Completed' : null">
                            <table>
                                <tr class="tabletitle">
                                    <td></td>

                                    <td class="Rate">
                                        <h2>Paid Bill</h2>
                                    </td>
                                    <td class="payment"></td>

                                </tr>
                                <tr>

                                    <td>
                                        <p>1 Credit = ₹7 </p>
                                        <p>Maximum 100 Credits has been applied</p>
                                    </td>

                                    <td class="Rate">
                                        <h2>Applied Credits</h2>
                                    </td>
                                    <td class="payment">
                                        <h2>{{ bill ? bill.bill[0].fields.credits_applied : "" }}</h2>
                                    </td>
                                </tr>



                                <tr class="tabletitle">

                                    <td>
                                        <p>INR Credits Applied :</p>
                                    </td>

                                    <td class="Rate">
                                        <h2>Total Credit Amount Applied</h2>
                                    </td>
                                    <td class="payment">
                                        <h2>₹{{ bill ? bill.bill[0].fields.credits_applied_inr_discount : "" }}</h2>
                                    </td>

                                </tr>
                                <tr>

                                    <td>
                                        <p>Credits Not Applied :</p>
                                    </td>
                                    <td class="Rate">
                                        <h2>Total Amount</h2>
                                    </td>
                                    <h2> &nbsp;₹{{ bill.tatal_ammount }}</h2>

                                    <!-- <td class="payment"><h2>₹{{bill.bill[0].fields.bill_payment.payment_amount}}</h2></td> -->
                                </tr>

                                <tr>

                                    <td>
                                        <p>Amount Paid After Credits Applied :</p>
                                    </td>
                                    <td class="Rate">
                                        <h2>Final Amount </h2>
                                    </td>
                                    <td class="payment">
                                        <h2>₹{{ bill ? bill.bill[0].fields.Final_Amount_Paid : "" }}</h2>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <!-- {% else  %} -->
                        <div v-else>
                            <table>
                                <tr class="tabletitle">
                                    <td></td>

                                    <td class="Rate">
                                        <h2>Active Credits</h2>
                                    </td>
                                    <td class="payment">
                                        <h2>{{ bill ? (bill.active_credits_ammount ? bill.active_credits_ammount : '0') :
                                            '0'
                                        }}</h2>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <p>1 Credit = ₹7 </p>
                                        <p>Maximum 100 Credits has been applied</p>
                                    </td>
                                    <td class="Rate">
                                        <h2>Active Credits Amount In INR</h2>
                                    </td>
                                    <td class="payment">
                                        <h2>₹{{ bill ? bill.active_credits_ammount_to_inr : "" }}</h2>
                                    </td>
                                </tr>


                                <tr class="tabletitle">

                                    <td>
                                        <p>Before Credits Applied :</p>
                                    </td>

                                    <td class="Rate">
                                        <h2>Total</h2>
                                    </td>
                                    <!-- <td class="payment"><h2>₹something amount</h2></td> -->
                                    <td class="payment">
                                        <h2>₹{{ bill ? bill.payment_ammount : "" }}</h2>
                                    </td>

                                </tr>
                                <tr>

                                    <td>
                                        <p>After Credits Applied :</p>
                                    </td>
                                    <td class="Rate">
                                        <h2>Final Billing Amount</h2>
                                    </td>
                                    <td class="payment">
                                        <h2>₹{{ bill ? bill.remaining_billing_ammount : "" }}</h2>
                                    </td>

                                </tr>
                            </table>
                        </div>

                        <!-- {% endif  %} -->



                    </div>










                    <!-- <p class="desc-bill">
                        <b>Subject:</b> {{ notification ? notification.fields.subject : '' }} <br><br>
                        <b>Description:</b> {{ notification ? notification.fields.description : '' }}
                    </p> -->
                    <p class="legal"><strong>Thank you for your business!</strong>  Payment is expected within 7-8 days.
                        please process this invoice within this time. There will be a 5% interest charge per month on late
                        invoices.
                    </p>


                    <div
                        v-if="this.$store.state.userDetils.length ? this.$store.state.userDetils[0].user.is_manager : null">
                        <div v-if="bill ? bill.payment_status == 'Completed' : null">
                            <a-button class="completeed-bill" block disabled> Completed Bill</a-button>

                        </div>
                        <div v-else>
                            <a-button @click="completeBill()" block type="primary"> Complete Bill</a-button>
                        </div>


                        <p class="legal"><strong>Thank you for your business!</strong>  Payment is expected within 7-8 days;
                            please process this invoice within that time. There will be a 5% interest charge per month on
                            late invoices.
                        </p>
                        <div v-if=" bill ?  !bill.payment_status == 'Completed' : null ">
                            <p>Note: As this bill is unpaid and currently has the status "", it will deduct credits from
                                your account balance equivalent to the billing amount after the bill is got processed.</p>
                        </div>


                    </div>


                </div>
                <br>

            </main>
        </a-col>
    </a-row>
</template>
<script>
import axios from 'axios';
import main_left_pane_menu_user from "@/components/cards/main_left_pane_menu_user.vue"

export default {
    name: "bill_details",

    created(){
    document.title = "My Bill Details - Crekaa";
  },
  head() {
    return {
      title: "Crekaa",
      meta: [
        { hid: 'description', name: 'description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'author', name: 'author', content: "Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        
        // <!-- Facebook Open Graph tags -->
        { hid: 'og:url', property: 'og:url', content: "https://crekaa.com/" },
        { hid: 'og:type', property: 'og:type', content: "website" },
        { hid: 'og:title', property: 'og:title', content: "Crekaa, Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'og:description', property: 'og:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'og:image', property: 'og:image', content: "../../assets/logo.png" },
        // Add more meta tags as needed

        // <!-- Twitter Card tags -->
        { hid: 'twitter:card', name: 'twitter:card', content: "summary_large_image" },
        { hid: 'twitter:title', name: 'twitter:title', content: "Crekaa , Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'twitter:description', name: 'twitter:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'twitter:image', name: 'twitter:image', content: "../../assets/logo.png" },

      ]
    };
  },
    data() {
        return {
            bill: null
        }
    },
    components:
    {
        main_left_pane_menu_user,
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        getLabel(Billing_status) {
            if (Billing_status == 'Completed') {
                return "text-color-Completed"
            }
            else if (Billing_status == 'Pending') {
                return "text-color-Pending"
            }
            else if (Billing_status == 'Due') {
                return "text-color-Due"
            }
            else if (Billing_status == 'Un-Paid') {
                return "text-color-Un-Paid"
            }
        },

        async completeBill() {
            this.$store.commit('setIsLoading', true)

            const bill_id = this.$route.params.bill_id

            await axios
                .post("complete-bill/" + bill_id)
                .then(response => {
                    // console.log(response);
                    console.log(response.data);
                    // this.bill = response.data
                    // done
                    this.fetchData();
                    // prompt popup here 
                })
                .catch(error => {
                    // console.log("===========error at user details")                        
                    console.log(JSON.stringify(error.response.data))
                    console.log(JSON.stringify(error))
                })
            this.$store.commit('setIsLoading', false)
        },


        async fetchData() {
            this.$store.commit('setIsLoading', true)

            const bill_id = this.$route.params.bill_id

            await axios
                .post("my-bill-details/" + bill_id)
                .then(response => {
                    // console.log(response);
                    console.log(response.data);
                    this.bill = response.data


                })
                .catch(error => {
                    // console.log("===========error at user details")                        
                    console.log(JSON.stringify(error.response.data))
                    console.log(JSON.stringify(error))
                })
            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>
<style scoped>
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,900,700,500,300,100);



h1 {
    /* font-size: 1.5em; */
    /* color: #222; */
}

h2 {
    /* font-size: .9em; */
}

h3 {
    /* font-size: 1.2em; */
    font-weight: 300;
    line-height: 2em;
}

p {
    /* font-size: .7em; */
    /* color: #666; */
    line-height: 1.2em;
}

#headerimage {
    z-index: 1;
    position: relative;
    /* top: -10px; */
    height: 400px;
    background-image: url('http://michaeltruong.ca/images/invoicebg.jpg');
    background-repeat: no-repeat;

    /* -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, .15), inset 0 -2px 4px rgba(0, 0, 0, .15);
    -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, .15), inset 0 -2px 4px rgba(0, 0, 0, .15);
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, .15), inset 0 -2px 4px rgba(0, 0, 0, .15); */
    overflow: hidden;
    background-attachment: fixed;
    background-size: 1920px 80%;
    background-position: 50% -90%;
}

.innovice {
    position: relative;
    margin-top: -290px;
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
    backdrop-filter: blur(10px);
    background: var(--card-color);
    padding: 20px
}

.logo {
    float: left;
    height: 60px;
    width: 60px;
    background: url(../../assets/logo.png) no-repeat;
    background-size: 60px 60px;
    border-radius: 60px;
}

.logo-user {
    float: left;
    height: 60px;
    width: 60px;
    /* background: url({{userprofile.profile_picture.url}}) no-repeat; */
    background-size: 60px 60px;
    border-radius: 60px;
}

.flx-inovice {
    display: flex;
}

.flx-inovice-inverse {
    display: flex;
}

.flx-inovice-clin {
    display: flex;
}

.justify-content-space-between {
    justify-content: space-between;
}

.justify-content-space-arround {
    justify-content: space-around;
}

.justify-content-space-ceevenly {
    justify-content: space-evenly;
}

.gap10 {
    gap: 10px
}

.mt10 {
    margin-top: 10px;
}

.company-section {
    border-bottom: 1px solid var(--devide-color);
    margin-bottom: 10px
}

.proj-desc {
    max-width: 400px;
    font-size: 12px;
}

.desc-bill {
    margin-top: 10px;
    border: 1px solid var(--devide-color);
    padding: 10px;
    border-radius: 5px
}

table {
    width: 100%;
    border-collapse: collapse;
}

td {
    padding: 5px 0 5px 15px;
    border: 1px solid #EEE
}

.tabletitle {
    padding: 5px;
    background: var(--devide-color);
}

@media screen and (max-width: 720px) {
    /* Add your CSS styles for devices with 720p resolution or higher here */
    /* For example: */

    .flx-inovice-clin {
        flex-direction: column;
        display: flex;
    }

    .flx-inovice-inverse {
        flex-direction: column-reverse;
        display: flex;
    }

    .proj-desc {
        max-width: 100%;
        font-size: 12px;
    }

    .proj-title {
        font-size: 15px;
        text-align: center;
    }
}


.text-color-Completed {
    color: rgb(28, 130, 0);
    background: rgba(210, 255, 198, 0.441);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: 4px;
}

.text-color-Pending {
    color: rgb(184, 187, 0);
    background: rgba(184, 187, 0, 0.441);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: 4px;
}

.text-color-Due {
    color: rgba(255, 0, 0);
    background: rgba(255, 0, 0, 0.141);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: 4px;
}

.text-color-Un-Paid {
    color: rgb(255, 0, 85);
    background: rgba(255, 0, 85, 0.141);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    margin-top: 4px;
    padding-bottom: 2px;
}</style>