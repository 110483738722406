<template> <main style="padding:10px">
    <section>
    
    <div class="title-top-section text-center">
      <h1>Front End</h1>
    </div>
    <section class="section-area" id="per_page_developement">
      <!-- Per Page Developement -->

      <center>
        <div class="card-per-page-dev flx-adjustable">
          <div class="left-section-per-page-dev text-start">
            <div class="header-ppd flx-non-adjustable gap10">

              <img src="../../assets/logo.png" style="padding:5px;border:1px solid rgba(0, 0, 0, 0.464);border-radius:5px;width:70px;height:70px" alt="">
              <div class="content-ppd">
                <p style="margin:0px;font-size:14px">Professional Developer</p>
                <h6 style="margin:0px">Per Page Developement</h6>
              </div>
            </div>
            <div class="content-phata-ppd">


              <p style="margin:10px 0px 10px;font-size:14px;padding-right:10px">
                Welcome to our professional Per Page Developement Service! At Crekaa, we believe in offering
                cutting-edge technologies and services that cater to your specific needs. To make the process more
                streamlined and efficient, we have devised a comprehensive plan for our development services. This plan
                ensures convenience in project management and accurate cost estimation, giving you a clear understanding
                of what to expect.</p>
              <div class="flx-non-adjustable">
                <!-- <ion-icon style="margin:0px;font-size:20px;color:rgb(221, 122, 0)" name="star"></ion-icon>&nbsp; -->
                <p style="margin:0px;font-size:12px">⭐ (High Ratings)&nbsp;
                  <span>&nbsp;</span></p><li style="margin:0px;font-size:12px">🎯Beginner-Friendly</li>
                    &nbsp;<li style="margin:0px;font-size:12px">🏆Expert Solutions</li>
                <p></p>

                <br>


              </div>
            </div>

          </div>
          <div class="right-section-per-page-dev text-start">

            <h6 style="margin:0px 0px 5px;font-size:14px;font-weight: 700;"> Average time: 1-2 month(s)</h6>
            <p style="margin:0px 0px 10px ;font-size:14px;font-weight: 500;">Get solution for your own buisness growth</p>

            <h6 style="margin:0px 0px 5px 0px;font-size:14px;font-weight: 700;"> Things we'll build:</h6>

            <p style="margin:0px 0px 10px;font-size:14px;font-weight: 500;">Cascading Style Sheets (CSS), HTML, UI/UX
              design, JavaScript, Web Development Tools, User Interface, Front-End Web &amp; Backend For Your Buisness.</p>


          </div>
        </div>
        <br>

        <div class="card-per-page-dev text-start">
          <img style="width: 100%;" src="https://usa.bootcampcdn.com/wp-content/uploads/sites/106/2020/03/Web-Developer-Bootcamp-San-Francisco.jpeg" alt="">
          <h4 class="elementor-widget-container-title">Customized Website Development</h4>
          <p class="elementor-widget-container">At Crekaa, we take a different approach to website development. We don't
            rely on external web developers or agencies; instead, we have our own team of skilled professionals and
            cutting-edge frontend frameworks. This unique strategy allows us to offer you customized website development
            solutions without inflating the costs.</p>
          <p class="elementor-widget-container">Our pricing packages are designed to be flexible and adaptable, granting
            you the freedom to expand the technology and features based on your specific requirements. The cost of
            development depends on factors such as the number of pages, design complexity, and functionality needed. We
            scale the pricing accordingly, ensuring you get the best value for your investment.</p>
          <div class="elementor-widget-container">
            <p>Unlike some common website pricing models that we do not follow, we believe in transparency and tailored
              solutions. Here are a few pricing models that you won't find at Crekaa:</p>
            <ol>
              <li>
                <p><b>Fixed Project Price:</b> This model provides you with a flat fee for the entire project, based on
                  the agreed-upon scope and deliverables. However, it might lack flexibility, making it less suitable
                  for projects with evolving requirements.</p>
              </li>
              <li>
                <p><b>Hourly Rate:</b> Some web developers and agencies charge an hourly rate for their services,
                  leading to unpredictable costs. At Crekaa, we prefer to offer transparent and straightforward pricing
                  to avoid any surprises.</p>
              </li>
              <li>
                <p><b>Retainer Model:</b> While retainer models involve long-term agreements, we believe in providing
                  specific solutions for each project rather than locking you into a fixed monthly fee. We value your
                  freedom to choose what's best for your website.</p>
              </li>
              <li>
                <p><b>Value-Based Pricing:</b> Although value-based pricing can be subjective, we prefer to base our
                  costs on the specific requirements and features of your project, ensuring you receive the best return
                  on your investment without any hidden costs.</p>
              </li>
            </ol>
            <p>When you choose Crekaa for your website development needs, we ensure full transparency in our pricing. We
              discuss the scope, features, and functionalities with you, so you have a clear understanding of what is
              included in the cost. Our pricing is comprehensive and encompasses all necessary aspects, such as Securing domain, web hosting, maintenance, and future updates, without any additional surprises.</p>
            <p>Comparing quotes from different providers can indeed help you make an informed decision based on your
              budget and project requirements. At Crekaa, our focus is on delivering high-quality, customized websites
              that align perfectly with your business objectives and deliver a seamless user experience.</p>
          </div>
      </div></center>
    </section>
    <section class="section-area" id="clean_css">
      <!-- Clean CSS -->
      <center>
        
        <div class="card-per-page-dev flx-adjustable shadow-purple-neon">
          <div class="left-section-per-page-dev text-start">
            <div class="header-ppd flx-non-adjustable gap10">

              <img src="../../assets/logo.png" style="padding:5px;border:1px solid rgba(0, 0, 0, 0.464);border-radius:5px;width:70px;height:70px" alt="">
              <div class="content-ppd">
                <p style="margin:0px;font-size:14px">Professional Solution</p>
                <h6 style="margin:0px">Clean CSS Product</h6>
              </div>
            </div>
            <div class="content-phata-ppd">
              <p style="margin:10px 0px 10px;font-size:14px;padding-right:10px">Writing clean CSS code brings numerous
                advantages, including improved readability, maintainability, performance, and collaboration. It
                ultimately contributes to the overall success of a full development project.</p>
              <div class="flx-non-adjustable">
                <p style="margin:0px;font-size:12px">⭐ (High Performance)&nbsp;
                  <span>&nbsp;</span></p><li style="margin:0px;font-size:12px">🎯Advanced Solution</li>
                    &nbsp;<li style="margin:0px;font-size:12px">🏆Expert Delivery</li>
                <p></p>

                <br>

              </div>
              <div class="advantages">
                <p style="margin:0px; font-size:12px;font-weight: 700;"> This Will Grant All Of The Above Benifits: </p>
                <ul class="ul">
                  <li class="li">Easier Debugging </li>
                  <li class="li">Improved Performance </li>
                  <li class="li">Better Code Reviews</li>
                  <li class="li">Future-Proofingb</li>
                  <li class="li">Improved SEO </li>
                  <li class="li">Improved Readability </li>
                </ul>
              </div>
            </div>

          </div>
          <div class="right-section-per-page-dev" style="max-width:200px">


            <lottie-player style="background-color:  rgba(255, 255, 255, 0.90);border-radius:20px" src="https://crekaa-2024.s3.amazonaws.com/static/lottiefiles/blob.json" background="transparent" speed="1" loop="" autoplay=""></lottie-player>

          </div>
        </div>
      </center>

    </section>
    <section class="section-area" id="crekaa_UI">
      <!-- Crekaa.UI -->
      <center>
        <div class="card-per-page-dev flx-adjustable shadow-blue-neon">
          <div class="left-section-per-page-dev text-start">
            <lottie-player style="background-color:  rgba(255, 255, 255, 0.90);min-width:200px;border-radius:20px" src="https://crekaa-2024.s3.us-west-2.amazonaws.com/static/lottiefiles/meater-crekaa.jsonmeater-crekaa.json" background="transparent" speed="1" loop="" autoplay=""></lottie-player>
            <h4 style="margin:0px;font-size: 14px;">Crekaa.UI</h4>
            <br>
          </div>
          <div class="right-section-per-page-dev text-start" style="">


            <div class="header-ppd flx-non-adjustable gap10">

              <img src="../../assets/logo.png" style="padding:5px;border:1px solid rgba(0, 0, 0, 0.464);border-radius:5px;width:70px;height:70px" alt="">
              <div class="content-ppd">
                <p style="margin:0px;font-size:14px">Advanced Technologies</p>
                <h6 style="margin:0px">Crekaa.UI Technologies </h6>
              </div>
            </div>
            <div class="content-phata-ppd">
              <p style="margin:10px 0px 10px;font-size:14px;padding-right:10px">Crekaa.UI Will Boost Up to 1,000× faster page load in just a
                few seconds with our groundbreaking caching technology, now in limited beta.This will boost your application responses and application requests
                instantly.</p>
              <div class="flx-non-adjustable">
                <p style="margin:0px;font-size:12px">🚀 (High Boost )&nbsp;
                  <span>&nbsp;</span></p><li style="margin:0px;font-size:12px">🎯Achived 2X Performance</li>
                    &nbsp;<li style="margin:0px;font-size:12px">🏆Advanced Solution</li>
                <p></p>

                <br>

              </div>
              <div class="advantages ">
                <p style="margin:0px; font-size:12px;font-weight: 700;"> This Will Grant All Of The Above Benifits: </p>
                <ul class="ul">
                  <li class="li">Faster Debugging </li>
                  <li class="li">Heigh Performance </li>
                  <li class="li">Better Code Reviews</li>
                  <li class="li">Advanced &amp; Future-Proof</li>
                  <li class="li">Highly Improved SEO </li>
                  <li class="li">Compress the data to minimize the size </li>
                </ul>
              </div>
            </div>



          </div>
        </div>
      </center>
    </section>
    <div class="title-top-section text-center">
      <br><br>
      <hr>

      <h1>Back End</h1>
    </div>

    <section class="section-area" id="django">
      <!-- Python -->
      
      <center>
        <div class="python-django-section">
          <div class="card-per-page-dev text-start shadow-python-neon">
            <img style="width: 100%;" src="https://www.python.org/static/community_logos/python-logo-master-v3-TM-flattened.png" alt="">

            <h4 class="elementor-widget-container-title">Backends</h4>
            <p class="elementor-widget-container">We take pride in constructing the robust backend engines for any
              platform, utilizing the world's most secure language, <b>Python</b>. Through this powerful language, we
              ensure end-to-end encryption and implement the highest levels of security measures, guaranteeing your data
              and systems remain protected and safeguarded at all times.</p>

            <div class="elementor-widget-container">
              <p>Website pricing models can vary depending on the approach and services offered by project development
                with a professional approach. At Crekaa, we believe in providing a pay-as-per-need pricing model,
                ensuring that you only pay for the services you require and use, allowing for scalable usage of any
                platform. Our pricing model includes the following:</p>
              <ol>
                <li>
                  <p><b>Flexible Project Price:</b> With our flexible project price model, we tailor the functionalities
                    according to the unique needs and preferences of each user and customer. This approach grants them
                    the freedom to choose the specific features and services they require, optimizing their experience
                    and ensuring they get exactly what they need.</p>
                  <p>We understand that every project is unique and that one-size-fits-all pricing may not be the most
                    suitable option for everyone. Our pay-as-per-need pricing model empowers our clients to customize
                    their project scope, ensuring cost-effectiveness and efficient resource allocation. At Crekaa, we
                    prioritize client satisfaction and work closely with our clients to deliver tailored solutions that
                    align with their specific requirements and budget.</p>
                </li>

                <li>
                  <p><b>Server Infrastructure:</b> At Crekaa, we go the extra mile to ensure the smooth functioning of
                    your platform. As part of our commitment to providing top-notch services, we offer a free Server
                    Infrastructure for your platform that is best fit for your needs. Our dedicated team of experts will
                    assess your requirements and provide you with the server infrastructure that aligns perfectly with
                    your project's demands.</p>
                  <p>Having a reliable and robust server infrastructure is essential for the optimal performance of any
                    online platform. With our free server infrastructure offering, you can rest assured that your
                    website or application will be hosted on a secure and high-performance server, enabling faster
                    loading times, enhanced data security, and minimal downtime.</p>
                  <p>Our team will handle all the technical aspects of setting up and managing the server
                    infrastructure, allowing you to focus on your core business activities without worrying about
                    server-related issues. With our free server infrastructure, you can scale your platform efficiently
                    as your user base grows, ensuring a seamless experience for your customers.</p>
                  <p>Partner with Crekaa, and let us take care of your server infrastructure needs, so you can
                    concentrate on building and expanding your online presence with confidence. Experience the
                    difference of having a reliable and efficient server infrastructure tailored to your platform's
                    requirements, all at no additional cost.</p>
                </li>
                <li>
                  <p><b>Retainer Model:</b> In a retainer website pricing model, you enter into a long-term agreement
                    with
                    a web development professional. You pay a fixed monthly fee or a set number of days per
                    month for our services. This model is beneficial if you anticipate the need for regular updates,
                    maintenance, and support after the website is built.</p>
                </li>
                <li>
                  <p><b>Value-Based Product:</b> Our platform is designed with a strong focus on your business's core
                    values. We understand that every organization has unique principles and goals that drive its
                    success. With our value-based product, you have the flexibility to define and align the platform
                    according to your specific values.</p>
                  <p>By offering a value-based product, we aim to empower your business with a solution that resonates
                    deeply with your mission and vision. Whether it's sustainability, innovation, customer-centricity,
                    or any other core value, our platform can be tailored to reflect and uphold these principles.</p>
                  <p>With our value-based approach, you can rest assured that the platform will not only meet your
                    functional requirements but also embody the essence of what your business stands for. This alignment
                    creates a strong sense of authenticity and helps you connect better with your target audience.</p>
                </li>
              </ol>
              <p>It’s important to discuss the pricing model with the web development professionals on Crekaa. you are
                considering and ensure you have a clear understanding of what is included in the price. Be sure to ask
                about any additional costs, such as domain registration, web hosting, ongoing maintenance, and future
                updates.</p>
              <p>Comparing quotes from different providers can help you understand the pricing landscape and make an
                informed decision based on your budget and project requirements.</p>
            </div>
          </div>
        </div>
      </center>
    </section>
    <section class="section-area" id="aws">
      <!-- AWS -->
      <center>
        
        <div class="card-per-page-dev flx-adjustable shadow-python-cloud">
          <div class="left-section-per-page-dev text-start">
            <lottie-player style="background-color:  rgba(255, 255, 255, 0.90);max-width:100%;border-radius:20px" src="https://assets-v2.lottiefiles.com/a/9bf62772-1182-11ee-83e7-d3e0022467b7/8WlYb4sU61.json" background="transparent" speed="1" loop="" autoplay=""></lottie-player>
            <h4 style="margin:0px;font-size: 14px;">AWS <span style="font-size: 10px;">(amazon web services)</span>
            </h4>
            <br>
          </div>
          <div class="right-section-per-page-dev text-start" style="">


            <div class="header-ppd flx-non-adjustable gap10">

              <img src="../../assets/logo.png" style="padding:5px;border:1px solid rgba(0, 0, 0, 0.464);border-radius:5px;width:70px;height:70px" alt="">
              <div class="content-ppd">
                <p style="margin:0px;font-size:14px">Professional Server Architectures</p>
                <h6 style="margin:0px">Scalable &amp; Market cloud leader servers </h6>
              </div>
            </div>
            <div class="content-phata-ppd">
              <p style="margin:0px 0px 10px;font-size:14px;padding-right:10px">We grant you server architecture as per
                your buisness need
                these servers are scalable as per trafic and with granting on demand CPU for your web application &amp;
                Databases.</p>
              <div class="flx-non-adjustable">
                <p style="margin:0px;font-size:12px">⭐ (High Availability)&nbsp;
                  <span>&nbsp;</span></p><li style="margin:0px;font-size:12px">🎯Zero Downtime </li>
                    &nbsp;<li style="margin:0px;font-size:12px">🏆Expert Architecture</li>
                <p></p>

                <br>

              </div>
              <div class="advantages ">
                <p style="margin:0px; font-size:12px;font-weight: 700;"> This Will Grant All Of The Above Benifits: </p>
                <ul class="ul">
                  <li class="li">Faster Loading Time </li>
                  <li class="li">Heigh Performance </li>
                  <li class="li">Better Server Reviews</li>
                  <li class="li">Advanced &amp; Future-Proof</li>
                  <li class="li">Highly Scalable </li>
                  <li class="li">24X7 Online &amp; 0 Downtime </li>
                </ul>
              </div>
            </div>



          </div>
        </div>
      </center>

    </section>
    <section class="section-area" id="crekaa_ui_technologies">
      <!-- Crekaa.UI Technologies -->
     
      <center>
        <div class="card-tree" style="background: var(--card-color);">
          <div class="header-tree flx-non-adjustable gap10">

            <img src="../../assets/logo.png" style="padding:5px;border:1px solid rgba(0, 0, 0, 0.464);border-radius:5px;width:70px;height:70px" alt="">
            <div class="content-ppd text-start">
              <p style="margin:0px;font-size:14px">Our Products </p>
              <h6 style="margin:0px">Crekaa.UI Techologies &amp; Services </h6>
            </div>
          </div>

          <ul class="tree horizontal head-pc">
            <li>
              <div>Crekaa</div>
              <ul>
                <li>
                  <div>Server</div>
                  <ul>
                    <li>
                      <div> Custom Architecture</div>
                    </li>
                    <li>
                      <div>Buisness Required Architecture</div>
                    </li>
                  </ul>
                </li>
                <li>
                  <div>Frontend</div>
                  <ul>
                    <li>
                      <div>Crekaa.UI <span class="sub-title"> (Website)</span></div>
                    </li>
                    <li>
                      <div>Crekaa.UI <span class="sub-title">(Android)</span></div>
                    </li>
                    <li>
                      <div>Super WEB</div>
                      <ul>
                        <li>
                          <div>PWA</div>
                          <ul>
                            <li>
                              <div><span class="sub-title">Installable &amp; Remarkable Web App Tchnology</span></div>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <div>SEO</div>
                          <ul>
                            <li>
                              <div>Google</div>
                            </li>
                            <li>
                              <div>Yahoo</div>
                            </li>
                            <li>
                              <div>Bing</div>
                            </li>
                          </ul>
                        </li>
                      </ul>

                    </li>
                  </ul>
                </li>
                <li>
                  <div>Backend</div>
                  <ul>
                    <li>
                      <div>Security</div>
                      <ul>
                        <li>
                          <div>Grade 1 <span class="sub-title">(2 Step Authentication, 2 Step Validation + Authorisation
                              + Cyber Attacks Protection )</span></div>
                        </li>
                        <li>
                          <div>Grade 2 <span class="sub-title">( Authentication + Verification + Authorisation + Cyber
                              Attacks Protection )</span></div>
                        </li>
                        <li>
                          <div>Grade 3 <span class="sub-title">( Authentication + Verification + Authorisation )</span>
                          </div>
                        </li>
                        <li>
                          <div>Grade 4 <span class="sub-title">( Non Authentication )</span></div>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div>Super Caching</div>
                      <ul>
                        <li>
                          <div>Mobile</div>
                        </li>
                        <li>
                          <div>Website</div>
                        </li>
                      </ul>

                    </li>
                    <li>
                      <div>Super Compressor</div>
                      <ul>
                        <li>
                          <div>Website <span class="sub-title">(Highly compressed Responces to Increase the Performance
                              of Web)</span></div>
                        </li>
                      </ul>

                    </li>
                    <li>
                      <div>Security Honeypots</div>
                      <ul>
                        <li>
                          <div>Website</div>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <div>Backup Engines</div>
                      <ul>
                        <li>
                          <div><span class="sub-title">Provides Only Backup For Text data Over Database.</span></div>
                        </li>
                      </ul>
                    </li>

                  </ul>
                </li>
                <li>
                  <div>Dashboard</div>
                  <ul>
                    <li>
                      <div>Mobile</div>
                      <ul>
                        <li>
                          <div>Realtime Buisness Dashboard</div>
                        </li>
                        <li>
                          <div>Crekaa.UI Dashboards</div>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div>WebSite</div>
                      <ul>
                        <li>
                          <div>Realtime Buisness Dashboard</div>
                        </li>

                        <li>
                          <div>Crekaa.UI Dashboards</div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <div>Multi User</div>
                  <ul>
                    <li>
                      <div>User Customer</div>
                    </li>
                    <li>
                      <div>User Admin </div>
                    </li>
                    <li>
                      <div>User Buisness Admin</div>
                    </li>
                    <li>
                      <div>User Staff</div>
                    </li>
                    <li>
                      <div>User Transporter</div>
                    </li>

                    <li>
                      <div>User User Delivery Boy</div>
                    </li>

                    <li>
                      <div>Other Required User</div>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
          <ul class="head-mob">
            <img src="https://crekaa-2024.s3.amazonaws.com/static/images/crekaa-services.jpg" draggable="false" oncontextmenu="return false;" width="100%" alt="">
          </ul>
        </div>
        <br>
      </center>
    </section>

  </section>
  </main>
  </template>
<script>
export default{
    name:"products_page",
    
created(){
    document.title = "Crekaa Products - Crekaa";
  },
  head() {
    return {
      title: "Crekaa Products - Crekaa",
      meta: [
        { hid: 'description', name: 'description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'author', name: 'author', content: "Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        
        // <!-- Facebook Open Graph tags -->
        { hid: 'og:url', property: 'og:url', content: "https://crekaa.com/" },
        { hid: 'og:type', property: 'og:type', content: "website" },
        { hid: 'og:title', property: 'og:title', content: "Crekaa, Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'og:description', property: 'og:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'og:image', property: 'og:image', content: "../../assets/logo.png" },
        // Add more meta tags as needed

        // <!-- Twitter Card tags -->
        { hid: 'twitter:card', name: 'twitter:card', content: "summary_large_image" },
        { hid: 'twitter:title', name: 'twitter:title', content: "Crekaa , Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'twitter:description', name: 'twitter:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'twitter:image', name: 'twitter:image', content: "../../assets/logo.png" },

      ]
    };
  },
}</script>
<style scoped>
.section-area {
    padding-top: 70px;
  }

  .text-center {
    text-align: center;
  }

  .text-start {
    text-align: start;
  }

  .title-top-section h1 {
    padding-top: 70px;
    font-size: 30px;
    margin-bottom:0
  }

  .flx-adjustable {
    display: flex;
  }

  .flx-non-adjustable {
    display: flex;
  }

  .gap10 {
    gap: 10px
  }

  .card-per-page-dev {
    max-width: 800px;
    border-radius: 5px;
    padding: 20px;
    background: var(--card-color);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  }

  .right-section-per-page-dev {
    border-left: 1px solid rgba(0, 0, 0, 0.386);
    padding-left: 10px
  }

  /* For screens up to 480px */
  @media screen and (max-width: 480px) {
    .flx-adjustable {
      flex-direction: column;
    }
  }

  /* For screens between 481px and 768px */
  @media screen and (min-width: 481px) and (max-width: 768px) {
    .flx-adjustable {
      flex-direction: column;
    }
  }

  /* For screens between 769px and 1024px */
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .flx-adjustable {
      flex-direction: row;
    }
  }

  /* For screens larger than 1024px */
  @media screen and (min-width: 1025px) {
    .flx-adjustable {
      flex-direction: row;
    }
  }

  .elementor-widget-container {
    font-size: 14px
  }

  .elementor-widget-container-title {
    font-size: 16px;
    font-weight: 700;
  }
  .li {
    font-size: 12px
  }

  .ul {
    margin: 0px
  }

  .shadow-inset {
    box-shadow: inset 0 2px 10px 1px rgba(0, 0, 0, 0.5);
  }

  .shadow-purple-neon {
    box-shadow: 0 6px 50px -10px #B864FF;
  }

  .shadow-blue-neon {
    box-shadow: 0 6px 50px -10px #64e8ff;
  }
  .shadow-python-neon {
    /* background-image: linear-gradient(135deg, #ffff00, #64e8ff); */
    box-shadow: 10px 20px 50px -10px #ffff00, -20px -20px 50px 0px #9492ff;
  }
  .shadow-python-cloud {
    /* background-image: linear-gradient(135deg, #ffff00, #64e8ff); */
    box-shadow: 0 6px 50px -10px #ffc592;

  }
  .tree {
    margin: 18px;
    padding: 0;
  }

  .tree:not(:empty):before,
  .tree:not(:empty):after,
  .tree ul:not(:empty):before,
  .tree ul:not(:empty):after,
  .tree li:not(:empty):before,
  .tree li:not(:empty):after {
    display: block;
    position: absolute;
    content: "";
  }

  .tree ul,
  .tree li {
    position: relative;
    /* margin: 0; */
    padding: 0;
  }

  .tree li {
    list-style: none;
  }

  .tree li>div {
    background-color: #eee;
    color: #222;
    padding: 5px;
    display: inline-block;
  }

  .tree.horizontal li {
    display: flex;
    align-items: center;
    margin-left: 24px;
  }

  .tree.horizontal li div {
    font-size: 12px;
    margin: 6px 0;
  }

  .tree.horizontal li div .sub-title {
    font-size: 10px;
    margin: 6px 0;
  }

  .tree.horizontal li:before {
    border-left: 2px solid #ddd;
    height: 100%;
    width: 0;
    top: 0;
    left: -12px;
  }

  .tree.horizontal li:first-child:before {
    height: 50%;
    top: 50%;
  }

  .tree.horizontal li:last-child:before {
    height: 50%;
    bottom: 50%;
    top: auto;
  }

  .tree.horizontal li:after,
  .tree.horizontal li ul:after {
    border-top: 2px solid #ddd;
    height: 0;
    width: 12px;
    top: 50%;
    left: -12px;
  }

  .tree.horizontal li:only-child:before {
    content: none;
  }

  .tree.horizontal li ul:after {
    left: 0;
  }

  .tree.horizontal>li:only-child {
    margin-left: 0;
  }

  .tree.horizontal>li:only-child:before,
  .tree.horizontal>li:only-child:after {
    content: none;
  }

  .card-tree {
    max-width: 900px;
    margin: 0 auto;
    /* border:1px solid rgba(0, 0, 0, 0.381); */
    box-shadow: 0 6px 50px -10px #b964ff3e;
  }

  .header-tree {
    padding: 10px
  }
</style>