<template>
    <div style="padding:10px;height:100%">
      <!-- {{ content ? content : "none" }} -->

    <a-row>


        <a-col :sm="24" :xs="24" :md="24" :lg="24">

            <a-row style="text-align:start;">
                <a-col :sm="24" :xs="24" :md="24" :lg="24" style="padding: 10px;">
                    <label>Position Title </label>
                    <a-input v-model:value="job_title"></a-input>
                </a-col>
                
                <a-col :sm="24" :xs="24" :md="12" :lg="12" style="padding: 10px;">
                    <label>Salary Expected</label>
                    <a-input-group compact>
      <a-select v-model:value="salary_currency" style="width: 20%" >
        <a-select-option value="USD $">USD $</a-select-option>
        <a-select-option value="INR ₹">INR ₹</a-select-option>
      </a-select>
      <a-input v-model:value="salary_expected" style="width: 80%" />
    </a-input-group>

                   
                </a-col>
                <a-col :sm="24" :xs="24" :md="12" :lg="12" style="padding: 10px;">
                    <label>Positions Opening</label>
                    <a-input v-model:value="position_openings" placeholder="Please enter the numberof positions available for this job..."></a-input>
                </a-col>
                <br><br>
                <br><br>
                <a-col :sm="24" :xs="24" :md="24" :lg="24" style="padding:10px">
                    
                    <a-label>Active Status </a-label>
                    <a-switch v-model:checked="is_active" />
                    <p style="font-size: 10px;">This Active status will represent the job is availabe or not, if available it is searchable if not then it is not searchable via portal</p>
                </a-col>
            </a-row>
            

        </a-col>
            <a-col :sm="24" :xs="24" :md="16" :lg="16">
                <a-divider></a-divider>
      <!-- <div v-html="convertedHTML" style="text-align:auto"></div> -->
      
    <!-- <a-button @click="show_html()" block type="primary" style="width:90%">
Post Job
      </a-button> -->
      <h3>Job Description</h3>
      <quill-editor theme="snow" toolbar="full"  v-model:content="content" style="background-color: rgb(210, 210, 210);height:500px"></quill-editor>
      
</a-col>
<a-col :sm="24" :xs="24" :md="8" :lg="8" style="padding:10px" >

    <div class="sticky-header is-sticky">
    <h3> Select The Locations</h3>

    <select name="countries" id="countries" v-model.values="countries" multiple style="text-align: start;">
    <!-- <option value="" selected >Contries</option> -->
<option value="Remote">Remote</option>
<option value="India">India</option>

</select>
<br><br>

    <h3> Select The Work Areas</h3>

    <select name="work_areas" id="work_areas" v-model.value="work_areas"  multiple>
    
<option value="1">    Analytics, Data, & Research</option>
<option value="2">Business Operations</option>
<option value="3">Administrative</option>
<option value="4">Finance</option>
<option value="5">Legal/Risk</option>
<option value="6">UI Design</option>
<option value="7">Graphic Design</option>
<option value="8">Management Consulting</option>
<option value="9">Marketing</option>
<option value="10">Product Management & Innovation</option>
<option value="11">Talent & Human Resources</option>
<option value="12">Technology & Engineering</option>
<option value="13">Civil Engineer (What is your expertise)</option>
<option value="14">Computer Engineer (What is your major)</option>

<option value="15">Human Resource</option>
<option value="16">Front Desk Assistant</option>
<option value="17">Secretary</option>
<option value="18">Accounting Secretary</option>
<option value="19">Communication Manager</option>


</select>

<br><br>

    <h3> Select The Team</h3>

    <select name="team" id="team"  v-model="teams" multiple>
     <option value="1">Advanced Analytics Group (AAG)</option>
     <option value="2">Crekaa Marketing</option>
     <option value="3">Executive/Manager Assistant</option>
     <option value="4">Expert Consulting</option>
     <option value="5">Finance</option>
     <option value="6">General Consulting</option>
     <option value="7">Global & Local Operations</option>
     <option value="8">Innovation & Design</option>
     <option value="9">Legal/Risk</option>
     <option value="10">Marketing Consulting by FRWD</option>
     <option value="11">New Ventures</option>
     <option value="12">NextGen Software Solutions</option>
     <option value="13">Product, Practice, and Knowledge (PPK)</option>
     <option value="14">Research & Data Services (RDS)</option>
     <option value="15">Talent Acquisition</option>
     <option value="16">Talent Management</option>
     <option value="17">Technology Solutions Group (TSG)</option>
</select>

<br><br>

    <h3> Select The Employement Type</h3>
    <a-select v-model:value="employement_type" style="width: 100%">
      <a-select-option value="Permanent Full-Time">Permanent Full-Time</a-select-option>
    <a-select-option value="Intern (Full Time)">Intern (Full Time)</a-select-option>
    <a-select-option value="Temporary Full-Time">Temporary Full-Time</a-select-option>
    </a-select>

<br><br>

    <h3> Select The Experience Required</h3>
    <a-select v-model:value="experience_required" style="width: 100%">
        <a-select-option value="0 - 1 Year">0 - 1 Year</a-select-option>
        <a-select-option value="2 - 3 Year">2 - 3 Year</a-select-option>
        <a-select-option value="4 -5 Year">4 -5 Year</a-select-option>
        <a-select-option value="5 - 6 Year">5 - 6 Year</a-select-option>
        <a-select-option value="6+ Year">6+ Year</a-select-option>
    </a-select>


    <br><br>

<h3> Select The Skills</h3>
<!-- {{skills}} -->
<!-- {{ skills }} -->
<a-select
  v-model:value="skills"
  :options="skills_available"
  mode="tags"
  :size="size"
  placeholder="Please select skills"
  style="width: 100%"
></a-select>

<br><br>

<div style="border:1px solid black;border-radius:5px;padding:10px">
    <h3> Create Skill</h3>
    <!-- {{skills}} -->
    <!-- {{ skills }} -->

        <a-input
        v-model:value="create_skill"
        placeholder="Python "
        style="width: 100%;margin-bottom:10px"
        />
        <a-button type="primary" block @click="create_skill_submit()">Submit</a-button>    
    </div>
</div>
</a-col>

        
<a-col>
</a-col>
</a-row>

<br><br>
<br><br>

<a-row>

    <a-col :sm="24" :xs="24" :md="24" :lg="24" style="padding:10px;text-align: start;">

    <h2>Ask Questions To Jobseeker: {{ cardCount }}</h2>
</a-col>



<a-col :sm="24" :xs="24" :md="8" :lg="8" style="padding:10px;text-align: start;"  v-for="(card, index) in askQuestions" :key="index" >
    
     
     <a-card>
     <label> <b> Question {{ index+1 }}:</b></label><br><label>Question English</label>
   <a-input v-model:value="card.question" placeholder="write something that you need to ask to jobseeker" /><br>
   
   <br>
   <br>
   <a-checkbox v-model:checked="card.question_required"><a-label>Question Required:</a-label></a-checkbox><br>

<a-space style="float: right;">

 <a-button type="primary" @click="incrementCardCount"><ion-icon name="add-outline"></ion-icon></a-button>
 <a-button type="primary" @click="decrementCardCount"><ion-icon name="trash-outline"></ion-icon></a-button>
</a-space>
     </a-card>
     
    </a-col>
    
</a-row>
    


    </div>
    <a-button @click="CreatePosition()" block type="primary" style="width:90%">
Post Job
      </a-button>
<br><br>

  </template>
  
  <script>
import { notification } from 'ant-design-vue';

  import { QuillEditor } from '@vueup/vue-quill'
  import '@vueup/vue-quill/dist/vue-quill.bubble.css'
  import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
  import axios from 'axios'
  export default {
    name: "create_job_crekaa_careers",
    data() {
      return {
        displayedJd: '', // Add for decoding purpose 
        
        // this delta , QuillDeltaToHtmlConverter and computed function will work for us   as well use this tag "<div v-html="convertedHTML"></div>"
        delta: '',
        htmlContents:null,

        countries:[],
        skills:[],
        employement_type:'',
        checked:false,

        
        is_active:true,
        content: '',
        experience_required:[],
        salary_currency:'INR ₹',
        salary_expected:'',
        position_openings:'',
        work_areas:[],
        teams:[],
        
        skills_available:[],
        
        job_title:'',
        

        create_skill:'',
        // ask question section
        cardCount: 1,
      askQuestions: [
        {
            question:"why are you best fit for this position?",
            // question_fr:"question_fr why are you best fit for this position?",
            // question_sw:"question_sw why are you best fit for this position?",
            // question_kr:"question_kr why are you best fit for this position?",
            // question_li:"question_li why are you best fit for this position?",
            question_required:false
        },
      ],
      };
    },
    mounted(){
        this.fetchAvailableSkills(),

        MultiSelectTag('countries', {
            rounded: true,    // default true
    shadow: true,      // default false
    placeholder: 'Search',  // default Search...
    tagColor: {
        textColor: '#327b2c',
        borderColor: '#92e681',
        bgColor: '#eaffe6',
    },
    onChange: (values)=> {
        this.countries=values
        console.log(values)
        console.log("countries value ",this.countries)
    }
}),
MultiSelectTag('work_areas', {
    rounded: true,    // default true
    shadow: true,      // default false
    placeholder: 'Search',  // default Search...
    tagColor: {
        textColor: '#327b2c',
        borderColor: '#92e681',
        bgColor: '#eaffe6',
    },
    onChange: (values)=> {
        this.work_areas=values

        console.log(values)
    }
}),
MultiSelectTag('team', {
    rounded: true,    // default true
    shadow: true,      // default false
    placeholder: 'Search',  // default Search...
    tagColor: {
        textColor: '#327b2c',
        borderColor: '#92e681',
        bgColor: '#eaffe6',
    },
    onChange: (values)=> {
        this.teams=values

        console.log(values)
    }
})
    },
    computed: {
    convertedHTML() {
      const converter = new QuillDeltaToHtmlConverter(this.delta.ops, {});
      this.htmlContents=converter.convert();
      return converter.convert();
    },
  },
    methods:{
        
        async create_skill_submit(){
        this.$store.commit('setIsLoading',false)
           const formData={
                skill:this.create_skill
            }
            await axios
            .post("create-skill-post-job-admin/",formData)
            .then(response => {
            // console.log("------------------------------------Got Response------------------------------------");
            // console.log(response.data);
            // this.skills_available=response.data['skill']
            if (response.data['error'] == false){
                notification['success']({
                    message: 'Skill Added Successfully',
                    // description:'Unable To Login With Provided Credentials...',
                    placement: 'bottomRight',
                });
                this.fetchAvailableSkills()
            }else {

                notification['error']({
                        message: 'Unable to fetch the skills',
                        // description:'Unable To Login With Provided Credentials...',
                        placement: 'bottomRight',
                    });
            }
            // console.log("------------------------------------Skills available------------------------------------");
            // console.log(this.skills_available)            
        })
        .catch(error => {
            console.log(error),
                notification['error']({
                    message: 'Unable to fetch the skills',
                    // description:'Unable To Login With Provided Credentials...',
                    placement: 'bottomRight',
                });
        },)
        this.$store.commit('setIsLoading',false)

    },
        async fetchAvailableSkills(){
        this.$store.commit('setIsLoading',false)

            await axios
            .get("create-skill-post-job-admin/")
            .then(response => {
            // console.log("------------------------------------Got Response------------------------------------");
            // console.log(response.data);
            // this.skills_available=response.data['skill']

            // this.skills_available = response.data['skill'].map(skill => skill.skill_name);
            const skillsData = response.data['skill'];
                // Map the skills data to create an array of objects with a 'value' property
            this.skills_available = skillsData.map(skill => ({
                value: skill.skill_name
            }));
            // console.log("------------------------------------Skills available------------------------------------");
            // console.log(this.skills_available)            
        })
        .catch(error => {
            console.log(error),
                notification['error']({
                    message: 'Unable to fetch the skills',
                    // description:'Unable To Login With Provided Credentials...',
                    placement: 'bottomRight',
                });
        },)
        this.$store.commit('setIsLoading',false)

    },
        incrementCardCount() {
      this.cardCount += 1;
      
      this.askQuestions.push({
        question: '',
        question_required: false,
      });
    },
    decrementCardCount() {
      if (this.cardCount > 1) {
        this.cardCount -= 1;
        this.askQuestions.pop();
      }
    },

    show_html(){
        this.delta=this.content
    },
        async CreatePosition(){
            this.$store.commit('setIsLoading',true)
            this.delta=this.content

// console.log("+++++++++++++++++++_")
// console.log(" countries : ", this.countries)
// console.log("skills : ",this.skills)
// console.log("employement_types : ",this.employement_types)
// console.log("checked : ",this.checked)
// console.log("is_active : ",this.is_active)
// console.log("employement_type : ",this.employement_type)
// console.log("job_title : ",this.job_title)
// console.log("content : ",this.content.ops)
// console.log("experience_required : ",this.experience_required)
// console.log("salary_currency : ",this.salary_currency)
// console.log("salary_expected : ",this.salary_expected)
// console.log("position_openings : ",this.position_openings)
// console.log("work_areas : ",this.work_areas)
// console.log("teams : ",this.teams)
// console.log("askQuestions : ",this.askQuestions) //job_career_offer_question
            // this.delta=this.content
            // console.log(this.countries) // here you will get the selected countries 
    const converter = new QuillDeltaToHtmlConverter(this.delta.ops, {});
      this.htmlContents=converter.convert();
// console.log("htmlContents : ",this.htmlContents)



const formData = {
        employement_type:this.employement_type,
        content:this.htmlContents,
        salary_currency:this.salary_currency,
        experience_required:this.experience_required,
        salary_expected:this.salary_expected,
        position_openings:this.position_openings,
        is_active:this.is_active,
        job_career_offer_question:this.askQuestions,
        locations:this.locations,
        work_areas:this.work_areas,
        teams:this.teams,
        skills:this.skills,
        locations: this.countries,

        job_title:this.job_title,
        
        }

   
       await axios
        .post("job-crekaa-career/",formData)
        .then(response => {
            // console.log(response);
            // console.log("------------------------------------Got Response------------------------------------");
            // console.log(response.data);
            if(response.data['error'] == false)
            {notification['success']({
                    message: 'Successsfully posted ',
                    description:'thet new job has been posted on Careers page...',
                    placement: 'bottomRight',
                });}
                else{
                    {notification['info']({
                    message: 'someththing wents wrong ',
                    description:'Please convey developer this message for something wents wrong here...',
                    placement: 'bottomRight',
                });}
                }
            // this.$store.commit('addUserDetails', userData);
            // console.log("--------------------------------------Done---------------------------------");
            // console.log("the stored data : ",this.$store.state.userDetils)
            // console.log("the stored User data : ",this.$store.state.userDetils['0']['user'])
            // console.log("the stored User data : ",this.$store.state.userDetils[0].user.first_name);
            
        })
        .catch(error => {
                notification['error']({
                    message: 'Signup Failed',
                    description:'Unable To Login With Provided Credentials...',
                    placement: 'bottomRight',
                });

                // console.log("===========error at user details")                        
                // console.log(JSON.stringify(error.response.data))
                //     console.log(JSON.stringify(error))
        })


this.$store.commit('setIsLoading',false)
        
    },},
    components: {
      QuillEditor,
    },
  
}

  </script>
  