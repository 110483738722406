<template><br><br>
    <div style="text-align:start;min-height:100vh;padding:10px;line-height: 30px;font-size: 14px;">

<h1 style="font-size: 22px;">Crekaa Software Development Terms and Conditions</h1>
<span>
    <ul>
        1. Introduction
<ul>

    <li>
        1.1. Welcome to Crekaa Web Development! These terms and conditions outline the rules and regulations for the use of our web development services.
    </li>
    
    <li>
        1.2. By accessing our services, you agree to comply with these terms. If you disagree with any part of these terms, you may not use our services.
    </li>
</ul>

2. Project Engagement
<ul>
    <li>
        2.1. Crekaa will provide web development services to the client as outlined in the agreed-upon project proposal.
    </li>
    <li>
        2.2. The client agrees to provide all necessary information, materials, and approvals required for project completion.
    </li>
</ul>



3. Project Timeline and Delivery
<ul>
    <li>

        3.1. Crekaa will provide an estimated timeline for project completion. Delays may occur due to unforeseen circumstances, but Crekaa will make reasonable efforts to meet agreed deadlines.
    </li>
    
    <li>
        
        3.2. The client acknowledges that timely feedback and cooperation are essential for project progress.
    </li>
</ul>

4. Payments
<ul>
    <li>
        4.1. The client agrees to the payment terms outlined in the project proposal.

    </li>
    
    <li>
        4.2. Crekaa retains the right to withhold delivery of the project until full payment is received.
        
    </li>
</ul>

5. Intellectual Property
<ul>
    <li>

        5.1. Upon full payment, the client owns the intellectual property rights to the delivered website. Crekaa retains the right to showcase the project in its portfolio.
    </li>
</ul>

6. Confidentiality

<ul>
    <li>
        6.1. Crekaa and the client agree to keep confidential any proprietary or sensitive information shared during the project.

    </li>
    
</ul>


7. Limitation of Liability
<ul>
    <li>
        7.1. Crekaa is not liable for any loss, damage, or additional costs incurred due to unforeseen events or third-party actions.

    </li>
    
</ul>

8. Termination
<ul>
    <li>
        8.1. Either party may terminate the project with a written notice in the event of a material breach of these terms.

    </li>
    
    <li>
        
        8.2. Upon termination, the client is responsible for payment for services provided up to the termination date.
    </li>
</ul>


9. Legal Compliance
<ul>
    <li>
        9.1. These terms and conditions are governed by the laws of [Your Jurisdiction]. Any legal action arising out of these terms shall be resolved in the courts of [Your Jurisdiction].

    </li>
    
</ul>

10. Amendments
<ul>
    <li>

        10.1. Crekaa reserves the right to update or modify these terms and conditions at any time. Clients will be notified of any changes.
    </li>
    
    <li>
        
    </li>
</ul>




<h6>
By engaging with Crekaa Web Development services & Signuping with crekaa, you/User has acknowledge that you have read, understood, and agree to these terms and conditions.
</h6>

</ul>
</span>
</div>

</template>
<script>
export default{
    name:"terms_and_conditions",
created(){
    document.title = "Terms & Conditions - Crekaa";
  },
  head() {
    return {
      title: "Terms & Conditions",
      meta: [
        { hid: 'description', name: 'description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'author', name: 'author', content: "Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        
        // <!-- Facebook Open Graph tags -->
        { hid: 'og:url', property: 'og:url', content: "https://crekaa.com/" },
        { hid: 'og:type', property: 'og:type', content: "website" },
        { hid: 'og:title', property: 'og:title', content: "Crekaa, Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'og:description', property: 'og:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'og:image', property: 'og:image', content: "../../assets/logo.png" },
        // Add more meta tags as needed

        // <!-- Twitter Card tags -->
        { hid: 'twitter:card', name: 'twitter:card', content: "summary_large_image" },
        { hid: 'twitter:title', name: 'twitter:title', content: "Crekaa , Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'twitter:description', name: 'twitter:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'twitter:image', name: 'twitter:image', content: "../../assets/logo.png" },

      ]
    };
  },
}
</script>
<style scoped>

</style>