<template>
    <br>
    <!-- <h1>this is dashboard page</h1> -->
    <div v-if="this.$store.state.userDetils.length">
        <main style="min-height:100vh" v-if="this.$store.state.userDetils[0].user.is_admin">
        
        <a-row>
            <a-col :sm=24 :xs="24" :md="8" :lg="6">
                <!-- add here card componant -->
                <div style="margin: auto;">
    
                    <user_card_my_dashboard  />
                    <div v-if="this.$store.state.userDetils[0].user">
                        
                        <router-link to="/my-dashboard" v-if="this.$store.state.userDetils[0].user.is_admin" >
    
                            <a-button type="primary" block style="width:90%"> Personal Dashboard</a-button>
                        </router-link>
                    </div>
                </div>
            </a-col>
    
            <a-col :sm=24 :xs="24" :md="16" :lg="18" style="background-color: var(--notification-color-bg); height:100vh;padding: 20px;text-align: start;">
                <a-space>
                 <router-link to="/admin-all-user-profiles" style="color:black" >
                <div class="card-action" >
                    <ion-icon style="font-size: 20px;" name="people-outline"></ion-icon><br>
                    <p> <b> All User Profiles </b></p>
                </div>
                </router-link>
                                
                <router-link to="/crekaa-admin-all-contact-us-requests" style="color:black" >
                <div class="card-action" >
                    <ion-icon style="font-size: 20px;"  name="chatbox-ellipses-outline"></ion-icon><br>
                    <p> <b> Recived Contact Us Requests </b></p>
                </div>
                </router-link>
                
            </a-space>
            <br>
            <br>
            <hr>
            <br>
            <br>
                <h2 style="text-align: start;">Manage Crekaa Creers Section</h2>
                <br>
                <a-space>
                     <router-link to="/crekaa-careers" style="color:var(--text-color)" >
                    <div class="card-action" >
                        <ion-icon style="font-size: 20px;" name="add-circle-outline"></ion-icon><br>
                        <p> <b> All Creers </b></p>
                    </div>
                    </router-link>
                                    
                    <router-link to="/admin-crekaa-careers-recived-all-applications" style="color:var(--text-color)" >
                    <div class="card-action" >
                        
                        <ion-icon style="font-size: 20px;" name="document-text-outline"></ion-icon><br>
                        <p> <b> Recived Forms </b></p>
                    </div>
                    </router-link>
                    <router-link to="/admin-crekaa-create-job" style="color:var(--text-color)" >
                    <div class="card-action" >
                        
                        <ion-icon style="font-size: 20px;" name="person-add-outline"></ion-icon><br>
                         <p> <b> Create position </b></p>
                    </div></router-link>
                    <router-link to="/admin-crekaa-careers-update-jobs" style="color:var(--text-color)" >
                    <div class="card-action" >
                        <ion-icon style="font-size: 20px;" name="create-outline"></ion-icon> <br>
                        <p> <b> Update Position </b></p>
                    </div>
                    </router-link>
                </a-space>
                <br>
                <br>
                <hr>
    
            <!-- add here button to see the my applied carrer Opportunities -->
            </a-col>
        </a-row>
        
    </main>
    </div>
    

</template>
<script>
import axios from 'axios'
// import VueCookies from 'vue-cookies';

// import userProfileCard from '@/components/cards/userProfileCard.vue'
import user_card_my_dashboard from '@/components/dashboard/user_card_my_dashboard.vue'

export default {
    name:"userAdminProfile",
    data(){
        return{
            user_details:[]
        }
    },
    components:{
        user_card_my_dashboard,
    },
    created(){
        document.head.title="Admin Dashboard - crekaa"
    },
    mounted(){
        // this.fetchUserProfileDetails()
    },
    methods: {
            // async fetchUserProfileDetails(){
            // this.$store.commit('setIsLoading', true)

            // axios.defaults.headers.common['Authorization'] = "token "+ VueCookies.get('authToken');

            // await axios
            //     .get("user-details/")
            //     .then((response)=>{
            //         this.user_details=response.data
                    
            //         // console.log("=============-------------============-====-=-===-=-=-=-=-")
            //         console.log(this.user_details)
            //         if (!this.user_details[0].user.is_admin){
            //             this.$store.commit('removeAuthToken')
            //             const toPath = this.$route.query.to || '/signin' // dashboard path
            //             this.$router.push(toPath)
            //         }

            //     }).catch((error)=>{
            //         console.log("error Occured while fetching User Data ")
            //     })
            // this.$store.commit('setIsLoading', false)

            // }
    }
} 

</script>
<style scoped>
.card-action{
    background: rgba(128, 128, 128, 0.458);border-radius: 10px;display: flex;flex-direction: column;padding:10px;justify-content:center;width: 100%;
}
.card-action:hover{
    background: rgba(128, 128, 128, 0.558);
    cursor: pointer;
}
</style>