<template><br> <div style="display: flex;justify-content: center;">
 <a-collapse
    v-model:activeKey="activeKey"
    :bordered="false"
    style="background: var(--card-color); width:590px;border-radius:20px;margin-left:10px;margin-right:10px; box-shadow: rgba(193, 47, 255, 0.1) 0px 2px 8px 0px;"
  >
    <template #expandIcon="{ isActive }">
        <ion-icon name="chevron-down-outline" style="font-size:20px" :rotate="isActive ? 90 : 0"></ion-icon>
   
    </template>
    <a-collapse-panel key=" faq.key " style="font-size:18px;background: var(--card-color);border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden">
       <template #header   >
               <b style="font-size: 14px; font-family:Alkatra;"> {{ faq.que }}</b>
            </template>
                <p style="font-size: 12px; font-family:Alkatra;">{{ faq.answer }}</p>
    </a-collapse-panel>
    

  </a-collapse>
  </div>
  
</template>
<script>
export default {
    name: "faqs_section",
    data(){
        return{
            activeKey:'1',
           
        }
    },
    props:{
        faq:Object
    }
}
</script>
<style scoped>


</style>