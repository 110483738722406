<template>
    <div class="container">

     <section class="wings">
        <div class="wings-svg">
          
          <img style="width:90%;padding:10px;margin-top:30px"
            src='../../assets/svgs/wings_butterfly.svg' alt='Web Platform'>
            

        </div>
    </section>

    
    <video autoplay muted loop style="height:100%;width:100%" class="video">
                  <source src="../../assets/the_assets/videos/cicada_tree.webm" type="video/mp4">
              </video>
             

            </div>
    
</template>
<script>
export default {
    name:"home_how_we_help"
}
</script>
<style scoped>

.container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%; /* Set an appropriate aspect ratio based on your video's dimensions */
  margin-bottom:70px;
  background:
   /* radial-gradient(45% 45% at 8% 50%, rgba(255, 0, 111, 0.277) 1%, #FF000000 99%), */
   /* radial-gradient(33% 33% at -1% 43%, var(--wp--preset--color--gradient-two) 1%, #FF000000 99%), */
    /* radial-gradient(15% 15% at 71% 43%, var(--wp--preset--color--gradient-three) 1%, #FF000000 99%),
     radial-gradient(20% 20% at 82% 77%, var(--wp--preset--color--gradient-four) 1%, #FF000000 99%), */
      radial-gradient(40% 40% at 50% 91%, var(--wp--preset--color--gradient-five) 1%, #FF000000 99%); 
}

.wings {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  height: 100%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom:1px solid var(--devide-color)
}


</style>