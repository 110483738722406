import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// antdesign
import Antd from 'ant-design-vue';
// use css from antjs 
import 'ant-design-vue/dist/reset.css';


//PrimeVue 
// import PrimeVue from 'primevue/config';
// use css from prime vue
// import 'primevue/resources/themes/lara-light-teal/theme.css';

// google login
import vue3GoogleLogin from 'vue3-google-login'
const CLIENT_ID='106022253016-ja7ee672citei4cb8trepmqf7g5247gp.apps.googleusercontent.com'

import axios from 'axios'
// axios.defaults.baseURL="http://127.0.0.1:8000/v1/api/"
axios.defaults.baseURL="https://api.crekaa.com/v1/api/"

// if youare using the wifi & hotsppot & wanted to use this app on mobile 
// add "*" to allowed hosts 
// add "*" to CORS headers hosts 
// python manage.py runserver http://192.168.197.208:8000

// axios.defaults.baseURL="http://192.168.197.208:8000/v1/api/"

import { VueHeadMixin, createHead } from '@unhead/vue'
const head = createHead()

createApp(App).use(store).use(head).mixin(VueHeadMixin).use(router,axios).use(Antd).use(vue3GoogleLogin, {
    clientId: CLIENT_ID
  }).mount('#app')
 