<template>
  <a-row style="text-align: start; min-height:100vh">
         <a-col :sm="24" :xs="24" :md="5" :lg="5" >
             <main_left_pane_menu_user class="sticky-header is-sticky"/>
         </a-col>
         <a-col :sm="24" :xs="24" :md="19" :lg="19">
 
             <section class="" style="margin-left:10px;margin-right:10px">
   <div class="all-billings-section">
     
 
       <div class="billings-list"><br><br>
         <!-- <h3 style="font-size:26px;text-align: start;">Your Billings</h3>
         <my_billing_card v-for="bill in bills" v-bind:bill="bill" class="bill"/> -->
         

         <h3 style="font-size:26px;text-align: start;">My Notifications</h3>
          <div v-if="notificatios">
            <my_notification_card  v-for="noti in notificatios"  v-bind:noti="noti"  style="margin-right: 10px;margin-left: 10px"/>
          </div>

        <div v-if="notificatios.length <= 0" class="body-bills">
 
          <div class="column-row-bills">
            <div class="row-row-bills">
             <a-empty
                image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                :image-style="{
                  height: '200px',
                }"
              >
     <template #description>
       <span>
         You Dont Have Any Notifications ( you may have not iniciated any order )
         
       </span>
     </template>
     <!-- <a-button type="primary">Create Now</a-button> -->
     <a-space>
 
      <router-link to="/plans-web/startup-web" >

<a-button type="primary">🌐 Explore Website Plans</a-button>
</router-link>
<router-link to="/plans-mobile/startup-mobile">
<a-button type="primary">📱 Explore Application Plans</a-button>
</router-link>
     </a-space>
   </a-empty>
           </div>
         </div>
         
        </div>
        <!-- {% endif %} -->
         
 
       </div>
   </div>
   </section>
         </a-col>
 </a-row>
  
 </template>
 
 
 
<script>
import axios from 'axios';
import main_left_pane_menu_user from "@/components/cards/main_left_pane_menu_user.vue"
import my_notification_card from '@/components/cards/my_notification_card.vue'
export default {
    name: "my_notifications",
    components:
    {
        main_left_pane_menu_user,
        my_notification_card
    }
    ,
    data() {
        return {
            notificatios: []
        }
    },
    
    created(){
    document.title = "My Notifications - Crekaa";
  },
  head() {
    return {
      title: "Crekaa",
      meta: [
        { hid: 'description', name: 'description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'author', name: 'author', content: "Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        
        // <!-- Facebook Open Graph tags -->
        { hid: 'og:url', property: 'og:url', content: "https://crekaa.com/" },
        { hid: 'og:type', property: 'og:type', content: "website" },
        { hid: 'og:title', property: 'og:title', content: "Crekaa, Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'og:description', property: 'og:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'og:image', property: 'og:image', content: "../../assets/logo.png" },
        // Add more meta tags as needed

        // <!-- Twitter Card tags -->
        { hid: 'twitter:card', name: 'twitter:card', content: "summary_large_image" },
        { hid: 'twitter:title', name: 'twitter:title', content: "Crekaa , Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'twitter:description', name: 'twitter:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'twitter:image', name: 'twitter:image', content: "../../assets/logo.png" },

      ]
    };
  },
    mounted() {
        this.fetchData()
    },
    methods: {
    
        async fetchData() {
            this.$store.commit('setIsLoading', true)
            await axios
                .post("my-notifications/")
                .then(response => {
                    // console.log(response);
                    // console.log(response.data);
                    this.notificatios = response.data["Notifications"]

                })
                .catch(error => {
                    // console.log("===========error at user details")                        
                    console.log(JSON.stringify(error.response.data))
                    console.log(JSON.stringify(error))
                })
            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>