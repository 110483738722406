<template>
    <div class="Myaccount" style="padding:10px">
        <br><br>
        <a-row>

            <a-col :sm="24" :xs="24" :md="8" :lg="6">
                <div class="sticky-header is-sticky">
                    <user_card_my_dashboard />
                    
          <!-- <a-button type="primary"  size="large" style="height:40px;border-radius:10px" block @click="logout()" >
                <a-row type="flex" justify="center">
                    <a-col :sm="1" :xs="1">
 <ion-icon style="font-size: 18px;padding-top:3px" name="log-out-outline"></ion-icon>
                    </a-col>
                    <a-col :sm="23" :xs="23">
<span style="font-size: 14px;">SignOut</span>

                    </a-col>
                </a-row>
              </a-button> -->
              <router-link to="/maintainnce-payment-plans">
                <a-card>
                    <!--  awsome lottie pleas keep in mind 
                        <lottie-player src="https://lottie.host/a2db6d49-4ce1-4fa3-bec9-87a62486d109/8QIwEfuga7.json"
                  background="transparent" speed="0.5" style="width:100%;height:200px" loop="2" autoplay=""></lottie-player>
         -->
                     <lottie-player src="https://lottie.host/86463611-050b-4496-839f-6bdb39999398/i8svm3bgAp.json"
                  background="transparent" speed="0.5" style="width:100%;height:200px" loop="2" autoplay=""></lottie-player>
        
        
                  <h3>Explore Server Maintainance Plans </h3>
                </a-card>
              </router-link>
                </div>
            </a-col>
            <a-col :sm="24" :xs="24" :md="16" :lg="18">
                <user_grid_my_dashboard 
                
                v-bind:data=data
                />
                <br>
                    <!-- Admin Granted Access  -->
                    <div v-if="user_details.length">
                    <a-card  v-if="user_details[0].user.can_careers_update_job || user_details[0].user.can_careers_post_job || user_details[0].user.can_careers_hire" >

                        <h2 style="text-align:start"
                            v-if="user_details[0].user.can_careers_update_job || user_details[0].user.can_careers_post_job || user_details[0].user.can_careers_hire">
                            Admin Granted Activities </h2>
                        <div v-if="user_details.length"
                            style="display: flex;justify-content: start; gap:10px; flex-wrap: wrap;">
                            <router-link to="/admin-crekaa-careers-recived-all-applications" style="color:var(--text-color)"
                                v-if="user_details[0].user.can_careers_hire">
                                <div class="card-action">
                                    <ion-icon style="font-size: 20px;" name="document-text-outline"></ion-icon><br>
                                    <p> <b> Recived Forms </b></p>
                                </div>
                            </router-link>
                            <router-link to="/admin-crekaa-create-job" style="color:var(--text-color)"
                                v-if="user_details[0].user.can_careers_post_job">
                                <div class="card-action">

                                    <ion-icon style="font-size: 20px;" name="person-add-outline"></ion-icon><br>
                                    <p> <b> Create position </b></p>
                                </div>
                            </router-link>
                            <router-link to="/admin-crekaa-careers-update-jobs" style="color:var(--text-color)"
                                v-if="user_details[0].user.can_careers_update_job">
                                <div class="card-action">
                                    <ion-icon style="font-size: 20px;" name="create-outline"></ion-icon> <br>
                                    <p> <b> Update Position </b></p>
                                </div>
                            </router-link>
                            <router-link to="/all-contact-us-requests" style="color:var(--text-color)"
                                v-if="user_details[0].user.can_see_contact_us_recived_Requests">
                                <div class="card-action">
                                    <ion-icon style="font-size: 20px;" name="chatbox-ellipses-outline"></ion-icon><br>
                                    <p> <b> Recived Contact Us Requests </b></p>
                                </div>
                            </router-link>
                        </div>

                    </a-card>
                </div>

                    <br>
                <br>
                <br>
                    <div v-if="contract && contract.contracts >= [0]">
                <user_project_details_tabbed_pane v-for="contr in contract.contracts" :key="contr.id" v-bind:project="contr" />
                </div>
                <!-- {{ contract ? contract.contracts : 0 }} -->
                <div v-else style="display: flex;justify-content: center;">
                    

                <div class="" style="border:1px solid red;height:auto;width:95%">
<!-- Explore mobile & web site Plans Section Here  -->
                    <img style="width:100%;max-width:400px" src="https://crekaa-2024.s3.amazonaws.com/static/images/nobg_no orders.png" alt="">
                    <h3 class="cb">Oops, We Cannot Found Any Orders From You! </h3>
                    <br>
                    <h1>Explore Our Plans</h1>
                    
                    <div style="background-color: var(--card-color); border-radius:10px;padding-top:20px;padding-bottom:20px;margin-left:5px;margin-right:5px;box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;">
                        

                        <float_cards_web_plans/>
                        <br>
                        
                        <float_cards_mobile_plans/>
                    </div>
                        <br><br><br>

                </div>
              
              <br>

                </div>

                <br>
                <br>
                <br>
        <h2  style="text-align: center;">&nbsp;From Crekaa.UI & Technologies </h2>

       <div class="container-grid-two">
                <a-row >
                    <a-col :sm="24" :xs="24" :md="6" :lg="6">
                            <lottie-player class="img-latest_trends_dept-left"
                                style="background-color:  rgb(239, 193, 255);padding:10px;width:90%;height:auto;margin:auto;margin-bottom:10px"
                                src="https://assets5.lottiefiles.com/packages/lf20_m9zragkd.json" background="transparent"
                                speed="1" loop autoplay></lottie-player>


                    </a-col>
                    <a-col :sm="24" :xs="24" :md="18" :lg="18" >
                        <div class="" style="border:1px solid black">
                            <div class="" id="">

                                <h1 class="cb" style="font-size:18px">Check Out New Technologies </h1>
                                <p style="font-size:15px;text-align: justify;padding:5px">
                                    Our hardworking engineers work continuously to enhance and improve the solutions that we
                                    provide. As a
                                    company with our own technologies, it is essential for us to maintain these technologies
                                    with regular
                                    updates to ensure they perform at their best and provide you with the best solutions
                                    possible.
                                    Through rigorous testing, analysis, and customer feedback, we identify areas for
                                    improvement and
                                    implement innovative solutions. We understand that technology is ever-evolving, and we
                                    strive to stay
                                    at the forefront of advancements in our industry.
                                    <br>
                                    By investing in research and development, we constantly seek to optimize our products
                                    and services.
                                    Our dedicated team of experts stays updated with the latest trends and emerging
                                    technologies to
                                    incorporate them into our solutions, ensuring that you receive cutting-edge and
                                    future-proof
                                    solutions.
                                    <br>
                                    We value the trust you place in us and are committed to delivering reliable, efficient,
                                    and secure
                                    solutions. Our continuous improvement efforts aim to exceed your expectations and
                                    provide you with the
                                    most effective and efficient solutions tailored to your needs.
                                    <br>
                                    At our core, we believe in the power of innovation and the drive to consistently deliver
                                    excellence.
                                    We will continue to push boundaries, explore new possibilities, and evolve our solutions
                                    to meet the
                                    ever-changing demands of the market.
                                    <br>
                                    Thank you for choosing us as your technology partner. We look forward to serving you
                                    with even best
                                    solutions in your projects & contracts.
                                </p>
                            </div>

                        </div>
                    </a-col>
                </a-row>
        </div>
            </a-col>
        </a-row>


        

        <br><br>


      



    </div>  <a-row style="background-color:var(--card-color);padding:20px;box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;">

            <a-col :sm="24" :xs="24" :md="6" :lg="6">
                <lottie-player class="img-latest_trends_dept-left" style="width:100%;height:auto"
                    src="https://assets10.lottiefiles.com/packages/lf20_pdyNqp.json" background="transparent" speed="1"
                    loop="" autoplay=""></lottie-player>

            </a-col>
            <a-col :sm="24" :xs="24" :md="18" :lg="18">
                <h2 class="cb">Welcome &amp; Greetings!</h2>
                <div class="tg-description">
                    <p style="font-size:14px;text-align: justify;">
                        Dear Customer,<br>Greetings from our technology! We are thrilled to connect with you and extend our
                        warmest welcome. As a team of dedicated innovators, we are passionate about leveraging technology to
                        transform the way you work, live, and interact with the world.
                        <br>
                        At our core, we believe that technology has the power to shape a brighter future. It empowers
                        businesses
                        to thrive, individuals to grow, and communities to flourish. With this belief, we strive to create
                        solutions that not only meet your needs but also exceed your expectations.
                        <br>
                        Our commitment to excellence drives us to constantly explore new frontiers, push boundaries, and
                        challenge the status quo. We embrace emerging technologies, adapt to changing trends, and harness
                        the
                        power of digital transformation to unlock opportunities for you.
                        <br>
                        Whether you're seeking to streamline operations, enhance productivity, or foster innovation, our
                        technology is designed to empower you. We understand that each organization and individual is
                        unique,
                        and our solutions are tailored to your specific requirements.
                        <br>
                        With a customer-centric approach, we prioritize your satisfaction above all else. Our team of
                        experts is
                        dedicated to providing exceptional support, ensuring a seamless experience throughout your
                        technology
                        journey. We are here to assist you at every step, from initial implementation to ongoing maintenance
                        and
                        upgrades.
                        <br>
                        As we embark on this technological adventure together, we are excited about the possibilities that
                        lie
                        ahead. We invite you to join us on this transformative journey, where the possibilities are endless,
                        and
                        together, we can shape a brighter future.
                        <br>
                        Once again, thank you for choosing our technology. We are honored to be your trusted partner, and we
                        look forward to embarking on this exciting journey together. Welcome to a world of endless
                        possibilities
                        and boundless innovation!
                    </p>
                    <p></p>
                </div>
                <span class="tg-name">Team Crekaa</span>

            </a-col>
        </a-row>
</template>

<script>
import axios from 'axios';
import user_card_my_dashboard from '@/components/dashboard/user_card_my_dashboard.vue'
import user_grid_my_dashboard from '@/components/dashboard/user_grid_my_dashboard.vue'
import user_project_details_tabbed_pane from '@/components/dashboard/user_project_details_tabbed_pane.vue'
import float_cards_web_plans from '@/components/float_cards_web_plans.vue'
import float_cards_mobile_plans from '@/components/float_cards_mobile_plans.vue'
export default {
    name: "my_dashboard",
    data(){return {
        contract:[],
        user_details: [],
        data:null
    }},
    
    created(){
    document.title = "My Dashboard - Crekaa";
  },
  head() {
    return {
      title: "Crekaa",
      meta: [
        { hid: 'description', name: 'description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'author', name: 'author', content: "Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        
        // <!-- Facebook Open Graph tags -->
        { hid: 'og:url', property: 'og:url', content: "https://crekaa.com/" },
        { hid: 'og:type', property: 'og:type', content: "website" },
        { hid: 'og:title', property: 'og:title', content: "Crekaa, Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'og:description', property: 'og:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'og:image', property: 'og:image', content: "../../assets/logo.png" },
        // Add more meta tags as needed

        // <!-- Twitter Card tags -->
        { hid: 'twitter:card', name: 'twitter:card', content: "summary_large_image" },
        { hid: 'twitter:title', name: 'twitter:title', content: "Crekaa , Cross-Platform Responsive Element Kit for Advanced Applications For Buisness" },
        { hid: 'twitter:description', name: 'twitter:description', content: "Crekaa is a powerful and versatile cross-platform responsive element kit designed specifically for advanced business applications. With Crekaa, businesses can create dynamic and interactive user interfaces that adapt seamlessly across various devices and screen sizes. This comprehensive kit offers a wide range of pre-built components and customizable elements, making it easy to build sophisticated and professional-looking applications. Whether you're developing a web-based platform or a mobile app, Crekaa provides the flexibility and functionality you need to create exceptional user experiences. Boost your business productivity and engage your target audience with Crekaa - the ultimate solution for advanced application development" },
        { hid: 'twitter:image', name: 'twitter:image', content: "../../assets/logo.png" },

      ]
    };
  },
    components: {
        user_card_my_dashboard,
        user_grid_my_dashboard,
        user_project_details_tabbed_pane,
        float_cards_web_plans,
        float_cards_mobile_plans,
    },
    mounted(){
        this.userDetails(),
        this.fetchUserProfileDetails()
    },
    methods: {
        async fetchUserProfileDetails() {
            this.$store.commit('setIsLoading', true)

            await axios
                .post("user-details/")
                .then((response) => {
                    this.user_details = response.data
                    this.$store.commit('addUserDetails', response.data);

                    // console.log("=============-------------============-====-=-===-=-=-=-=-")
                    // console.log(this.user_details)

                }).catch((error) => {
                    console.log("error Occured while fetching User Data ")
                })

            this.$store.commit('setIsLoading', false)

        },
        async userDetails(){
            this.$store.commit('setIsLoading',true)
            await axios
                    .post("user-dashboard-orders/")
                    .then(response => {

                        // console.log(response.data)
                        this.contract=response.data
                    const website_contracts_count= response.data["website_contracts_count"]
                    const app_contracts_count= response.data["app_contracts_count"]
                    const completed_website_contracts_count= response.data["completed_website_contracts_count"]
                    const pending_contracts_count= response.data["pending_contracts_count"]
                    const accepted_contracts_count= response.data["accepted_contracts_count"]
                    const completed_app_contracts_count= response.data["completed_app_contracts_count"]

                this.data={
                                    'website_contracts_count':website_contracts_count,
                'app_contracts_count':app_contracts_count,
                'completed_website_contracts_count':completed_website_contracts_count,
                'pending_contracts_count':pending_contracts_count,
                'accepted_contracts_count':accepted_contracts_count,
                'completed_app_contracts_count':completed_app_contracts_count
                                }
                        // console.log(this.data)
        // patches:null,
        // Contract:null,
        // ContractImages:null,
        // client_details:null,
        // billing:null,
                        // if (response.data['error'] === false) {
                        //     // console.log("Registration success ")

                        //     notification['success']({
                        //         message: 'Registration Successfull ',
                        //         description:
                        //             `we have sent an email on ${this.email} to activate your account please check your email, & verify your account for login.`,
                        //         placement: 'bottomRight',
                        //     });

                        //     this.$router.push('/signin')
                        // } else {
                        //     notification['error']({
                        //         message: 'Registration Un-Successfull ',
                        //         description:
                        //             response.data['statement'],
                        //         placement: 'bottomRight',
                        //     });
                        //     // console.log(response.data['statement'])
                        //     // console.log("error encountered for registration")
                        // }
                    })
                    .catch(error => {
                        
                        // this.errors.push('Something went wrong. Please Try Again ')
                            console.log(JSON.stringify(error))
                         
                            
                    })
              this.$store.commit('setIsLoading',false)
        },
        logout() {
            axios.defaults.headers.common["Authorization"] = ""

            localStorage.removeItem("token")
            localStorage.removeItem("username")
            localStorage.removeItem("userid")

            this.$store.commit('removeToken')

            this.$router.push('/')
            this.$store.commit('removeUserDetails')
            // console.log("removal of userDetails")
            // console.log(this.$store.state.userDetils)
        },
    }
}
</script>
<style scoped>.card-action {
    background: rgba(128, 128, 128, 0.458);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
    width: 100%;
}

.card-action:hover {
    background: rgba(128, 128, 128, 0.558);
    cursor: pointer;
}

.container-grid-two {
    /* background-color: rgba(255, 92, 51,0.3); */
    background-color: var(--card-color);
    /* height: 490px; */
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
    /* color: rgb(255, 255, 255); */
    /* display: flex; */
    text-align: center;
    align-items: space;
    /* justify-content: space-between; */
    padding: 10px;
    /* margin-left: 20px;
    margin-right: 20px; */
    border-radius: 12px;
    /* border: 0.1rem rgb(255, 47, 231); */

    /* background-color: #f3f3f3; */
}

#p1 {
    border: 0.1rem solid black;
    text-align: center;
    align-items: center;

}

#p2 {
    border: 0.1rem solid black;
}

#p3 {
    border: 0.1rem solid black;
}

.img-latest_trends_dept {
    width: 800px;

    object-fit: cover;
}

.img-latest_trends_dept-left {
    height: 200px;
    border-radius: 10px;
    object-fit: contain;

}




/* managing project gallery */
.project-gallery-container {
    max-width: 100%;
    min-width: 250px;
    overflow: hidden;
}

.gallery-project-mob {
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    gap: 10px;
}

.img-latest_trends_dept-left-mob {
    max-width: 100%;
    height: auto;
}

.img-latest_trends_dept-left-mob {
    height: 150px;
    border-radius: 10px;
    object-fit: contain;

}</style>