<template> 
<div class="managed-contents" style="background-color: var(--card-color);box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;">

  <a-row style="">
  <a-col :sm="12" :xs="12" :md="12" :lg="12">
    <div class="managed-contents-one">
        <div class="managed-contents-contents">
        
          <div class="main-managed-contents">

            <lottie-player src="https://assets8.lottiefiles.com/packages/lf20_Vx3h9L.json" background="transparent" speed="1" style="max-width: 200px; height: 200px;" loop="" autoplay=""></lottie-player>

          </div>
          <h6 style="margin-top:-50px"><span>{{ data ? data.website_contracts_count :"0" }}</span>&nbsp;Website Orders </h6>

        </div>
      </div>
</a-col>

<!-- <a-col :sm="24" :xs="24" :md="12" :lg="12">
  <div class="managed-contents-three">
      <a id="image-custom-bg" style="color:white">
          <div class="managed-contents-contents">
            <div class="main-managed-contents">

                <ion-icon name="add-outline"></ion-icon>
            </div>
            <h4>Create Request </h4>

          </div>
        </a>
      </div>
</a-col> -->
  <a-col :sm="12" :xs="12" :md="12" :lg="12">
    <div class="managed-contents-four">
        <div class="managed-contents-contents">
          <div class="main-managed-contents" style="width:200px;color:white;font-size: 100px;margin-top:30px">
            <ion-icon name="logo-android"></ion-icon>
          </div>
          <h6 style="margin-top:0px"><span>{{ data ? data.app_contracts_count :"0" }}</span>&nbsp;Mobile Application Orders</h6>
        </div>
         </div>
</a-col>

  

    

             

<a-col :sm="24" :xs="24" :md="24" :lg="24">

  <div class="managed-contents-two">
    <div class="managed-contents-contents" style="padding:0px">
      <div class="container-2X2" style="">
        <div class="box">{{ data ? data.completed_website_contracts_count :"0" }} <h1>Completed Website</h1>
        </div>
        <div class="box">{{ data ? data.pending_contracts_count :"0" }}<h1>Pending Applications</h1>
        </div>
        <div class="box">{{  data ? data.completed_app_contracts_count :"0"  }} <h1>Completed Applications</h1>
              </div>
              <div class="box">{{ data ? data.accepted_contracts_count :"0" }}<h1>Accepted Applications</h1>
              </div>
              <!-- <div class="box"><a-button style="height:100%;width:100%;font-Size:12px;font-weight: bold;">Create Request</a-button>
              </div> -->
            </div>
            
          </div>
        </div>
      </a-col>
      <a-col>
        
      </a-col>
      <a-col :sm="24" :xs="24" :md="24" :lg="24">
        <br>
        <br>
        <a-alert
      
      closable
    >
    <template #message>
        <div class="credits-blog">
        <router-link class="card-a" to="/credit-terms">
          <div class="credits-blog-card">
            <div class="img">
              <lottie-player class="img-latest_trends_dept-left"
              src="https://crekaa-2024.s3.amazonaws.com/static/lottiefiles/coin.json" hover background="transparent" 
              speed="1" style="width: 100px; height: 100px;" loop autoplay></lottie-player>
              
            </div>
            <div class="content-credit">
              <h2>Discover Your Promotional Credits</h2>
              <p>Exciting news! We are introducing brand new credits that you can now utilize for your projects, contracts, or monthly billings. Take advantage of these credits to enhance your experience and make the most out of our services.</p>
            </div>
          </div>
        </router-link>
      </div></template>
        </a-alert>
    </a-col>
</a-row>
</div>

  </template>
<script>
export default{
    namre:"user_grid_my_dashboard",
    
    props:{
      data:Object
    }
}</script>
<style scoped>

/* credits Blog  */
.credits-blog{
          /* height:100px; */
          border-radius:10px;
          width:98%;
          /* display: flex; */
          /* background-color: rgb(249, 249, 249,0.9); */
          /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
          margin: auto;
          transition: all 1s;
          /* justify-self: center; */
        }
        .credits-blog:hover{
          width:95%;
          /* height:105px; */
          /* background-color: rgb(249, 249, 249,0.9); */
          /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
        }
        .credits-blog-card{
          /* height:100px; */
          border-radius:10px;
          width:95%;
          display: flex;
          justify-content: space-around;

          /* background-color: rgb(255, 0, 208); */
        }
        .content-credit{        
          text-align: start;  
          padding-top:20px;
          padding-bottom:20px;
        }
        .content-credit h2{
          /* color:rgb(77, 77, 77); */
          color:var(--text-color);

          font-size:14px;
          font-weight: 700;
        }
        .content-credit p{
          color:var(--text-color);
          font-size:12px;
          margin:0;
          font-weight: 700;
        }
        .card-a{
          padding:0;
          cursor: pointer;
        }




/* <!-- removal of unused underline from anchor tag --> */
a:link {
   text-decoration: none;
}

a:visited {
   text-decoration: none;
}

a:hover {
   text-decoration: none;
}

a:active {
   text-decoration: none;
}

/* <!-- all containts from bootstrap --> */

.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
  margin-bottom: .5rem;
margin-top: .5rem;
color:white;
font-weight: 500;
line-height: 1.2
}

.h1,h1 {
font-size: 2.5rem
}

.h2,h2 {
font-size: 2rem
}

.h3,h3 {
font-size: 1.75rem
}

.h4,h4 {
font-size: 1.5rem
}

.h5,h5 {
font-size: 1.25rem
}

.h6,h6 {
font-size: 1rem
}
.row {
display: -ms-flexbox;
display: flex;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
margin-right: -15px;
margin-left: -15px
}
.col,.col-1,.col-10,.col-11,.col-12,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-auto,.col-lg,.col-lg-1,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,.col-lg-8,.col-lg-9,.col-lg-auto,.col-md,.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-auto,.col-sm,.col-sm-1,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-sm-auto,.col-xl,.col-xl-1,.col-xl-10,.col-xl-11,.col-xl-12,.col-xl-2,.col-xl-3,.col-xl-4,.col-xl-5,.col-xl-6,.col-xl-7,.col-xl-8,.col-xl-9,.col-xl-auto {
position: relative;
width: 100%;
padding-right: 15px;
padding-left: 15px
}

/* .col-2 {
-ms-flex: 0 0 16.666667%;
flex: 0 0 16.666667%;
max-width: 16.666667%
} */

.col-3 {
-ms-flex: 0 0 25%;
flex: 0 0 25%;
max-width: 25%
}

.col-4 {
-ms-flex: 0 0 33.333333%;
flex: 0 0 33.333333%;
max-width: 33.333333%
}

.col-6 {
-ms-flex: 0 0 50%;
flex: 0 0 50%;
max-width: 50%
}

.col-8 {
-ms-flex: 0 0 66.666667%;
flex: 0 0 66.666667%;
max-width: 66.666667%
}

.col-12 {
-ms-flex: 0 0 100%;
flex: 0 0 100%;
max-width: 100%
}

.btn {
display: inline-block;
font-weight: 400;
color: #212529;
text-align: center;
vertical-align: middle;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
background-color: transparent;
border: 1px solid transparent;
padding: .375rem .75rem;
font-size: 1rem;
line-height: 1.5;
border-radius: .25rem;
transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

.btn:not(:disabled):not(.disabled) {
cursor: pointer
}
/* <!-- end bootstrap --> */
.dashboard{
  height:100%
}



.container-grid{
  /* background-color: rgba(255, 255, 132,0.3); */
  background-color: white;
  /* color: rgb(255, 255, 255); */
  display: flex;
  text-align: center;
  align-items: space;
  justify-content: space-between;
  padding: 10px;
  /* width:100%; */
  margin-left:20px;margin-right:20px;border-radius:12px;
  /* border: 0.1rem rgb(255, 47, 231); */

  /* width: 40px; */
  /* height: 40px; */
  /* border-radius: 50%; */
  /* background-color: #eee; */

  /* box-shadow:
    0 0 60px 30px #fff,  /* inner white */
    /* 0 0 100px 40px #f0f, /* middle magenta */
    /* 0 0 140px 90px #0ff; /* outer cyan */
}

#p1{
  /* border: 0.1rem solid black; */
  text-align: center;
  align-items: center;
  /* padding: 10px; */
}

#p2{
  /* border: 0.1rem solid black; */
}

#p3{
  border: 1px solid black;
}


.profile-card{
  padding:20px;
  margin:20px;
  /* border: 1px solid black; */
  /* background: cyan; */
  background: #009688;
  box-shadow: #009688 0px 5px 15px 0px;

  width:20%;
  color:white;
  border-radius:12px;
}

.profile-card .profile-picture img{
  padding:20px;
  justify-content: center;
  /* border: 1px solid black; */
  /* background: cyan; */

  width:90%;
  border-radius : 200px;
}
.profile-card .card-content p{
font-size:16px
}

.profile-card .card-content p{
 font-size:16px
 }

.head-one{
  display: flex;
  gap:10px
}
/* the side of main card  */
.managed-contents{
  /* background-color: red; */
  width:100%;
  border-radius:20px;
  padding:20px;
  /* margin-top:20px;
  margin-left:20px;
  margin-right:20px; */
  /* margin-borrom:20px; */
  /* max-height:440px; */
}

.section-grid{
  display:flex;
}
.managed-contents-one{
  flex:50%;
  height:222.5px;
  /* background: #ffe6cc; */
  background: #f44336;
  border:1px solid #ff8400;
  color:white;

  border-top-left-radius: 20px
}
.managed-contents-two{
  /* flex:50%; */
  /* height:222.5px; */
  background: #e81e63;
  /* background: #ffcccc; */
  border:1px solid #ff6666;
  color:white;
padding:20px;
/* margin:20px; */
margin-top:20px;
  /* border-top-right-radius: 20px */
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;

}
.managed-contents-three{
  flex:50%;
  height:222.5px;
  /* background: #ffccff; */
background: #9c27b0;
border:1px solid #ff33ff;
color:white;
border-bottom-left-radius: 20px

}
.managed-contents-four{
  flex:50%;
  height:222.5px;
  background: #673ab7;
  /* background: #ccffcc; */
  /* border:1px solid #1aff1a; */
  color:white;


  border-bottom-right-radius: 20px

}

.managed-contents-contents{
  font-size:80px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.managed-contents-contents h4{
  font-size:4 0px;
}
/* head-two */
.head-two{
  display: flex;
  gap:30px;
  margin-left:20px;
  margin-right:20px;
}
.left-side-pane{
  background: #d1e0e0;
  height:700px;
  overflow-y: scroll;

  border-radius:10px;
  padding:10px;
  flex:50%;
}

.right-side-pane{
  background: #f2f2f2;
  height:700px;
  overflow-y: scroll;
  border-radius:10px;
  padding:10px;
  flex:50%;
}

.card-pane{
  background:white;
  border-radius:5px;
  padding:10px;

}
.title-card-pane{
  font-size:16px;
  font-weight:700;
}

.content-card-pane{
  max-width:100%;
  display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
}

.bottom-card-pane{
  display:flex;
  justify-content: space-between;
}
.title-label h2 {
  font-size:25px;
  font-weight:500
}
.card-last-notice img{
  width:500px;
}
a{border: none;
  text-decoration:none;
}




.container-grid-two{
  /* background-color: rgba(255, 92, 51,0.3); */
  background-color: white;
  height:490px;

  /* color: rgb(255, 255, 255); */
  display: flex;
  text-align: center;
  align-items: space;
  justify-content: space-between;
  padding: 10px;
margin-left:20px;margin-right:20px;border-radius:12px;
  /* border: 0.1rem rgb(255, 47, 231); */

  /* background-color: #f3f3f3; */
}

#p1{
  border: 0.1rem solid black;
  text-align: center;
  align-items: center;

}

#p2{
  border: 0.1rem solid black;
}

#p3{
  border: 0.1rem solid black;
}

.img-latest_trends_dept{
  width:800px;

  object-fit: cover;
}
.img-latest_trends_dept-left{
  height:200px;
  border-radius:10px;
  object-fit:  contain;

}


.container-2X2 {
  margin: auto;
  /* width: 100%; */
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 120px); /* Single column for small screens */
  grid-gap: 10px; 
/*   
  display: grid;
  grid-template-columns: 240px 240px ;
  grid-row: auto auto;
  justify-content:center;
  grid-column-gap: 20px;
  grid-row-gap: 20px; */
}
.container-2X2 .box {
  max-height:90px;
  margin:5px;
  border: 1px solid #fff;
  padding: 20px;
  border-radius: 5px;
  color: white;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-family: sans-serif;
}

/* Media query for screens wider than a certain breakpoint */
/* @media screen and (min-width: 768px) {
  .container-2X2  {
    font-size:12px
    grid-template-columns: repeat(2, 50%);
  }
} */

/* Media query for screens wider than another breakpoint */
@media screen and (min-width: 1025px) {
  .container-2X2 {
    grid-template-columns: repeat(4, 1fr); /* Two columns for medium screens */
     /* Three columns for large screens */
  }
}

.container-2X2 .box h1 {
  font-size:18px;

}
.card-users{
  display:flex;
  flex-direction:column;
  align-items:center;
  height:220px;
  padding:20px;
}
.m-40{
  margin:40px
}
.card-users{
  background-color:none;
  border-left:1px solid white;
  color:white;
  decoration:none;
  cursor:pointer;
}
.card-users:hover{

  border-left:1px solid white;
  color:white;
  decoration:none;
  cursor:pointer;
}
 .card-users h2{
   margin-top:0px;
   font-size:23px
 }
 .center-content-contents{
   padding-top:50px
 }
 .cb{color:black}




 /* mobile interfaces */
.head-mob .profile-card-mob{
  padding:20px;
  margin:auto;
  /* border: 1px solid black; */
  /* background: cyan; */
  background: #009688;
  width:95%;
  /* height:100px; */

  color:white;
  border-radius:12px;
}
.head-mob .profile-card-mob .col-0-12{
  /* padding:20px; */
  margin-top:-20px;
  /* border: 1px solid black; */
  /* background: cyan; */
  
}
.head-mob .profile-card-mob .card-content p{
  /* padding:20px; */
  margin-top:10px;
  margin-bottom:10px;
  /* border: 1px solid black; */
  /* background: cyan; */
  
}


.head-mob  .profile-card-mob .profile-picture img{
  
  justify-content: center;

  border-radius : 200px;
}

.head-mob .profile-card-mob .card-content p{
  font-size:16px
}

/* after profile card */
.head-mob .info-profile-dashboard-card{
  margin:auto;
  /* border: 1px solid black; */
  /* background: cyan; */

  width:100%;
  height:250px;

  color:white;
}
.head-mob .info-profile-dashboard-card .managed-contents-mob{
  background-color: red;
  width:95%;
  border-radius:20px;
  margin-top:20px;
  margin-bottom:20px;
  margin-left:12px;
  margin-right:20px;
  max-height:340px;
}


.head-mob .info-profile-dashboard-card .container-2X2 {
  margin: auto;
  width: 100%;
  height: 50px;

  display: grid;
  grid-template-columns: 45% 45% ;
  grid-row: auto auto;
  justify-content:center;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}
.head-mob .info-profile-dashboard-card .container-2X2 .box {
  
  margin:5px;
  border: 1px solid #fff;
  padding: 0;
  border-radius: 5px;
  color: white;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: sans-serif;
}
.head-mob .info-profile-dashboard-card .container-2X2 .box h1 {
  font-size:12px;
  text-align: center;
  margin:auto;
  
}

.head-mob .info-profile-dashboard-card .section-grid{
  display:flex;
}
.head-mob .info-profile-dashboard-card .managed-contents-one{
  flex:50%;
  height:122.5px;
  /* background: #ffe6cc; */
  background: #f44336;
  border:1px solid #ff8400;
  color:white;

  border-top-left-radius: 20px
}
.head-mob .info-profile-dashboard-card .managed-contents-two{
  flex:50%;
  height:122.5px;
  background: #e81e63;
  /* background: #ffcccc; */
  border:1px solid #ff6666;
  color:white;

  border-top-right-radius: 20px

}
.head-mob .info-profile-dashboard-card .managed-contents-three{
  flex:50%;
  height:122.5px;
  /* background: #ffccff; */
background: #9c27b0;
border:1px solid #ff33ff;
color:white;
border-bottom-left-radius: 20px

}
.head-mob .info-profile-dashboard-card .managed-contents-four{
  flex:50%;
  height:122.5px;
  background: #673ab7;
  /* background: #ccffcc; */
  border:1px solid #1aff1a;
  color:white;


  border-bottom-right-radius: 20px

}

.head-mob .info-profile-dashboard-card .managed-contents-contents{
  font-size:30px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.head-mob .info-profile-dashboard-card .managed-contents-contents h4{
  font-size:12px;
}

/* after mobile dashboard started working for my orders */


.container-grid-mob{
  /* background-color: rgba(255, 255, 132,0.3); */
  background-color: white;
  /* color: rgb(255, 255, 255); */
  display: flex;
  text-align: center;
  align-items: space;
  justify-content: space-between;
  margin:5px 5px 5px 5px;
  padding: 5px;
  border-radius:12px;
  /* border: 0.1rem rgb(255, 47, 231); */

  /* width: 40px; */
  /* height: 40px; */
  /* border-radius: 50%; */
  /* background-color: #eee; */

  /* box-shadow:
    0 0 60px 30px #fff,  /* inner white */
    /* 0 0 100px 40px #f0f, /* middle magenta */
    /* 0 0 140px 90px #0ff; /* outer cyan */
}
</style>